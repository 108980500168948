<template>
  <div class="row" v-if="canShowView" id="editShipping">
    <div class="col-12">
      <el-card title="Modifica spedizione">
        <div slot="header">

          <div class="row mb-4">
            <div class="col-12 col-md-9 mt-0">
              <h4 class="title mt-0">Modifica della spedizione <strong>{{shipping_log.track_nr}} <small>(ID: {{shipping_log.id}})</small></strong></h4>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <router-link :to="{path:'/spedizioni/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista Spedizioni
                </span>
                </button>
              </router-link>
            </div>

            <div class="col-12  mt-0">

              <h5 style="font-size: 90%" class="mb-2">ID Ordine:
                <router-link class="dark-link" :to="{path:'/ordini/lista/modifica/' + shipping_log.order_id}">
                  <a data-tooltip="'Vai all\'ordine'">
                    <strong>{{shipping_log.order_id}}</strong>
                  </a>
                </router-link>
              </h5>

              <h5 style="font-size: 90%" class="mb-2">Corriere: <strong>{{shipping_log.carrier_type_details.name}}</strong></h5>
              <h5 style="font-size: 90%" class="mb-2">Stato spedizione: <strong>{{shipping_log.current_state_details.name}}</strong></h5>
              <h5 style="font-size: 90%" class="mb-2">Stato sul sito del corriere: <strong>{{shipping_log.current_carrier_state}}</strong>  <a :href="shipping_log.shipping_track_link" target="_blank"><i class="fa fa-list"></i></a></h5>
              <h5 style="font-size: 90%" class="mb-2">Creata il: <strong>{{dateToItalian(shipping_log.created_at, true)}}</strong></h5>
              <h5 style="font-size: 90%" class="mb-2" v-if="shipping_log.dispatched_at">Partita il: <strong>{{dateToItalian(shipping_log.dispatched_at, true)}}</strong></h5>
              <h5 style="font-size: 90%" class="mb-2" v-if="shipping_log.delivered_at">Consegnata il: <strong>{{dateToItalian(shipping_log.delivered_at, true)}}</strong></h5>
              <h5 style="font-size: 90%" class="mb-2">Ultima modifica <strong>{{dateToItalian(shipping_log.updated_at, true)}}</strong></h5>

              <h5 style="font-size: 90%" class="mb-2" v-if="shipping_log.indabox_code">Notificata ad Indabox con codice <strong>{{shipping_log.indabox_code}}</strong></h5>



              <button type="submit" class="btn btn-warning btn-fill btn-wd mt-3 mb-0" @click.prevent="checkShippingLog(shipping_log)">
                Aggiorna cronologia dal corriere
              </button>


              <button type="submit" class="btn btn-danger btn-fill btn-wd mt-3 mb-0 ml-2" @click.prevent="handleNotifyIndabox" v-if="!shipping_log.indabox_code">
                Invia notifica a Indabox
              </button>


              <button type="submit" class="btn btn-danger btn-fill btn-wd mt-3 mb-0 ml-2" @click.prevent="checkStatusIndabox" v-if="shipping_log.indabox_code">
                Verifica stato su Indabox
              </button>

              <button type="submit" class="btn btn-primary btn-fill btn-wd mt-3 mb-0 ml-2 float-right" @click.prevent="handleSetReceived" v-if="shipping_log.current_state !== 3">
                Imposta come ricevuta
              </button>

            </div>
          </div>

          <div class="row mb-0" v-if="checkResponse">
            <h4 class="col-12 mt-0 mb-2">Risposta da Indabox</h4>
            <p class="col-12 pt-0">
              Data: <strong>{{dateToItalian(checkResponse.data, true)}}</strong><br />
              Stato: <strong>{{checkResponse.stato}}</strong>
            </p>
          </div>

        </div>

        <div class="row mt-2" v-if="shipping_log.chronology && shipping_log.chronology.length > 0">
          <h4 class="col-12 mt-0">Cronologia interna</h4>
          <div class="col-md-12 col-12 mb-5">
            <el-table
              :data="shipping_log.chronology"
              stripe
              border
              style="width: 100%">
              <el-table-column
                width="180" prop="created_at">
                <template #header>
                  <i class="el-icon-date mr-1"></i> Azione del
                </template>
              </el-table-column>

              <el-table-column
                width="180" prop="updated_at">
                <template #header>
                  <i class="el-icon-date mr-1"></i> Fino al
                </template>
              </el-table-column>

              <el-table-column
                prop="carrier_status"
                label="Stato corriere">
              </el-table-column>
              <el-table-column
                prop="status_detail.name"
                label="Stato">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="row mb-3" v-if="shipping_log.carrier_chronology && shipping_log.carrier_type == 2">
          <h4 class="col-12 mt-0">Cronologia sul sito del corriere</h4>
          <div class="col-md-12 col-12">
            <el-table
              :data="shipping_log.carrier_chronology"
              stripe
              border
              style="width: 100%">
              <el-table-column
                width="150" prop="date">
                <template #header>
                  <i class="el-icon-date mr-1"></i> Data
                </template>
              </el-table-column>
              <el-table-column
                prop="branch"
                label="Filiale">
              </el-table-column>
              <el-table-column
                prop="status"
                label="Stato">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="row mb-3" v-if="shipping_log.carrier_chronology && shipping_log.carrier_type == 1">
          <h4 class="col-12 mt-0">Cronologia sul sito del corriere</h4>
          <div class="col-md-12 col-12">
            <el-table
              :data="shipping_log.carrier_chronology"
              stripe
              border
              style="width: 100%">
              <el-table-column
                width="150" prop="StatusDate">
                <template #header>
                  <i class="el-icon-date mr-1"></i> Data
                </template>
              </el-table-column>
              <el-table-column
                label="Filiale">
                <template #default="props">
                  <span v-if="props.row.Depot && typeof(props.row.Depot) !== 'object'">{{props.row.Depot}}</span>
                  <span v-else><i>Non presente</i></span>

                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="Stato">
              </el-table-column>
            </el-table>
          </div>
        </div>


        <shipping-form v-bind:shipping_log="shipping_log" v-loading="loading" @update="updateForm" @delete="deleteElement"></shipping-form>


      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  import ShippingForm from './ShippingForm'

  export default {
    components: {
      ShippingForm,
      LPagination,
      Breadcrumb,
      BreadcrumbItem
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'ShippingEdit',
          minimumPermissionLevel: 200,
          listPath: '/spedizioni/lista'
        },
        loading: false,
        canShowView: false,
        shipping_log: {},
        checkResponse: null
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('shippingLogs/getById', this.$route.params.id).then((response) => {
          this.shipping_log = response.data.shipping_log
          this.canShowView = true
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questa spedizione')
        })
      },
      checkShippingLog (pElement) {
        this.loading = true
        this.$store.dispatch('shippingLogs/check', pElement).then((response) => {
          this.loading = false
          this.shipping_log = response.data.shipping_log
          this.showGenericSuccess('Spedizione', 'controllata con successo')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il controllo di questa spedizione', '', false)
        })
      },
      updateForm (pElement) {
        this.loading = true
        this.$store.dispatch('shippingLogs/edit', pElement).then((response) => {
          this.loading = false
          this.shipping_log.updated_at = response.data.shipping_log.updated_at
          this.showGenericSuccess('Spedizione', 'aggiornata con successo')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questa spedizione', '', false)
        })
      },
      deleteElement (pElement) {
        this.askDeleteMessage(' la spedizione ' + this.shipping_log.id).then((result) => {
          if (!result.value) return
          this.$store.dispatch('shippingLogs/delete', pElement).then(() => {
            this.askGenericSuccess().then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione della spedizione', '', false)
          })
        })
      },
      handleNotifyIndabox () {
        this.askGenericConfirm('Vuoi inviare la notifica ad Indabox?', 'Premendo Si confermerai l\'invio della notifica.<br /><br />Esegui questo comando solo se l\'ordine è stato spedito in un punto di ritiro Indabox.', 'Sì, invia!', 'No, ho sbagliato!').then((result) => {
          if (!result.value) {
            return
          }
          this.notifyIndabox()
        })
      },
      notifyIndabox () {
        this.loading = true
        this.$store.dispatch('shippings/notifyIndabox', this.shipping_log.order_id).then((response) => {
          this.loading = false
          this.showGenericSuccess('Notifica', 'inviata con successo ad Indabox')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'invio di questa notifica', '', false)
        })
      },
      checkStatusIndabox () {
        this.loading = true
        this.$store.dispatch('shippings/checkStatusIndabox', this.shipping_log.order_id).then((response) => {
          this.loading = false
          if (response.data.check_indabox && response.data.check_indabox.length > 0) {
            this.checkResponse = response.data.check_indabox[0]
          } else {
            this.checkResponse = null
            this.showGenericError('Errore', 'nel recupero dei dati da Indabox.')
          }
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'la verifica dello stato', '', false)
        })
      },
      handleSetReceived () {
        this.askGenericConfirm('Vuoi impostare la spedizione come ricevuta?', 'Premendo Sì confermerai l\'operazione che non potrà essere annullata.', 'Sì, conferma!', 'No, ho sbagliato!').then((result) => {
          if (!result.value) {
            return
          }
          this.setReceived()
        })
      },
      setReceived () {
        this.loading = true
        this.$store.dispatch('shippingLogs/setReceived', this.shipping_log.id).then((response) => {
          this.loading = false
          this.showGenericSuccess('Spedizione', 'impostata come ricevuta')
          this.updateData()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'imposta come ricevuta', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
  #editShipping thead tr th {
    background-color: rgba(203, 203, 210, 0.15) !important;
    color: #424242;
  }
  #editShipping .el-table tbody tr td:first-child, #editShipping .el-table thead tr th:first-child {
    padding-left: 5px !important;
  }
</style>
