<template>
    <div>
<!--        <div class="row mb-4">-->
<!--            <div class="col-12">-->
<!--                <router-link :to="{path:'/partner/lista'}">-->
<!--                    <el-button type="text">-->
<!--                <span class="btn-label">-->
<!--                   <i class="fa-solid fa-arrow-left"></i> Lista Partner-->
<!--                </span>-->
<!--                    </el-button>-->
<!--                </router-link>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row">
            <div class="col-12">
                <el-card>
                    <h4 class="mt-0">Modifica partner</h4>
                    <partner-form v-loading="loading" v-model="partner"/>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import PartnerForm from "@/pages/Dashboard/Partner/PartnerForm";

export default {
    name: 'EditPartner',
    components: {
        PartnerForm
    },
    computed: {},
    watch: {
        '$route.params.id': function () {

        }
    },
    data() {
        return {
            loading: false,
            paramId: null,
            partner: {}
        }
    },
    methods: {
        loadData() {
            this.loading = true
            this.paramId = this.$route.params.id
            this.$store.dispatch('partner/get', this.paramId).then((response) => {
                this.partner = response.data
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created: function () {
        if (!this.$route.params.id) {
            this.showErrorMessage(`Impossibile caricare i dati di questo partner.`)
        }
        this.loadData()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
