import Config from '../../globalConfig'
import axios from 'axios'

const statistics = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async ordersStats ({commit, state, rootGetters}, formData) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, formData, rootGetters.apiCommon)
        axios.post(Config.endpoints.statistics.orders, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async activationStats ({commit, state, rootGetters}, formData) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, formData, rootGetters.apiCommon)
        axios.post(Config.endpoints.statistics.activations.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async activationStatsExport ({commit, state, rootGetters}, formData) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, formData, rootGetters.apiCommon)
        axios.post(Config.endpoints.statistics.activations.export, params)
          .then((response) => {
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default statistics
