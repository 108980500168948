<template>
    <div id="order-step-customer">
        <div class="row mt-2">
            <div class="col-12 text-center">
                <h4 class="m-0">Metodo di pagamento</h4>
            </div>
            <div class="col-12 mt-4">
                <el-form ref="form" :model="paymentMethod" label-width="180px">
                    <el-form-item label="Tipologia">
                        <ValidationProvider vid="payment_method">
                            <payment-method-type-select v-model="paymentMethod.type"></payment-method-type-select>
                        </ValidationProvider>
                    </el-form-item>

                    <div v-if="paymentMethod.type === 'sdd'" class="mt-4">
                        <div class="d-flex align-items-center">
                            <h5 class="m-0">Conto bancario</h5>
                            <i class="ml-2 fa-regular fa-copy text-primary" role="button" @click="copyFromCustomer"/>
                        </div>

                        <el-form-item label="Nome  e cognome intestatario">
                            <el-input v-model="paymentMethod.sepa_holder_name"></el-input>
                        </el-form-item>
                        <el-form-item label="Codice fiscale">
                            <el-input v-model="paymentMethod.sepa_cf"></el-input>
                        </el-form-item>
                        <el-form-item label="Indirizzo">
                            <el-input v-model="paymentMethod.sepa_address"></el-input>
                        </el-form-item>
                        <el-form-item label="Numero civico">
                            <el-input v-model="paymentMethod.sepa_address_number"></el-input>
                        </el-form-item>
                        <el-form-item label="Città">
                            <el-input v-model="paymentMethod.sepa_city"></el-input>
                        </el-form-item>
                        <el-form-item label="CAP">
                            <el-input v-model="paymentMethod.sepa_zip_code"></el-input>
                        </el-form-item>
                        <el-form-item label="Provincia">
                            <province-select v-model="paymentMethod.sepa_country"></province-select>
                        </el-form-item>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="label">IBAN</label>
                                <el-input v-model="paymentMethod.iban" name="IBAN" @input="fillIban"></el-input>
                            </div>
                            <div class="col-12 col-md-3">
                                <label class="label">Agenzia</label>
                                <el-input type="text"
                                          name="agency"
                                          v-model="paymentMethod.agency">
                                </el-input>
                            </div>
                            <div class="col-12 col-md-3">
                                <label class="label">Banca</label>
                                <el-input type="text"
                                          name="bank"
                                          v-model="paymentMethod.bank">
                                </el-input>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-2">
                                <label class="label">CIN Int.</label>
                                <el-input type="text"
                                          name="cin"
                                          v-model="paymentMethod.cin_internazionale" disabled="">
                                </el-input>
                            </div>
                            <div class="col-12 col-md-2">
                                <label class="label">CIN</label>
                                <el-input type="text"
                                          name="cin"
                                          v-model="paymentMethod.cin" disabled="">
                                </el-input>
                            </div>
                            <div class="col-12 col-md-2">
                                <label class="label">ABI</label>
                                <el-input type="text"
                                          name="abi"
                                          v-model="paymentMethod.abi" disabled="">
                                </el-input>
                            </div>
                            <div class="col-12 col-md-2">
                                <label class="label">CAB</label>
                                <el-input type="text"
                                          name="cab"
                                          v-model="paymentMethod.cab" disabled="">
                                </el-input>
                            </div>
                            <div class="col-12 col-md-4 mb-5">
                                <label class="label">Conto corrente</label>
                                <el-input type="text"
                                          name="cc"
                                          v-model="paymentMethod.cc" disabled="">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="saveAndBack()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="saveAndNext()">Avanti<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";
import IdentityDocumentSelect from "@/components/Inputs/IdentityDocumentTypeSelect";
import IssuerIdentityDocumentSelect from "@/components/Inputs/IssuerIdentityDocumentSelect";
import PaymentMethodTypeSelect from "@/components/Inputs/PaymentMethodTypeSelect";

export default {
    name: "step-payment-method",
    components: {
        PaymentMethodTypeSelect,
        IssuerIdentityDocumentSelect,
        IdentityDocumentSelect,
        GiuridicFormSelect,
        ProvinceSelect
    },
    data() {
        return {
            paymentMethod: {},
            iban: require('iban')
        }
    },
    mounted() {
        this.paymentMethod = this.modelValue
    },
    props: {
        modelValue: {
            type: Object
        },
    },
    methods: {
        saveAndBack() {
            this.save()
            this.$emit('back')
        },
        saveAndNext() {

            if (this.paymentMethod.type === 'sdd' && !this.validIban()) {
                this.$message.error("Devi inserire un IBAN valido.")
                return;
            }

            this.save()
            this.$emit('next')
        },
        save() {
            this.loading = true
            this.$store.dispatch('orders/paymentMethods/edit', this.paymentMethod).then((response) => {
                this.loading = false
                this.paymentMethod = response.data.payment_method
                this.$emit('input', this.paymentMethod)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati del metodo di pagamento.')
            })
        },
        copyFromCustomer() {
            this.loading = true
            this.$store.dispatch('customers/getById', this.paymentMethod.customer_id).then((response) => {
                this.loading = false

                this.paymentMethod.sepa_holder_name = `${response.data.customer.first_name} ${response.data.customer.last_name}`;
                this.paymentMethod.sepa_cf = response.data.customer.codice_fiscale
                this.paymentMethod.sepa_address = response.data.customer.indirizzo_residenza
                this.paymentMethod.sepa_address_number = response.data.customer.numero_civico
                this.paymentMethod.sepa_city = response.data.customer.comune
                this.paymentMethod.sepa_zip_code = response.data.customer.cap
                this.paymentMethod.sepa_country = response.data.customer.provincia
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'recuperare i dati del cliente.')
            })
        },
        validIban() {
            return this.iban.isValid(this.paymentMethod.iban);
        },
        fillIban() {

            this.paymentMethod.iban = this.paymentMethod.iban.replace(/\s+/g, '')

            if (!this.iban.isValid(this.paymentMethod.iban)) {
                this.paymentMethod.cin_internazionale = null
                this.paymentMethod.cin = null
                this.paymentMethod.abi = null
                this.paymentMethod.cab = null
                this.paymentMethod.cc = null
                return
            }
            if (this.paymentMethod.iban.length === 27) {
                this.paymentMethod.cin_internazionale = this.paymentMethod.iban.substr(2, 2)
                this.paymentMethod.cin = this.paymentMethod.iban.substr(4, 1)
                this.paymentMethod.abi = this.paymentMethod.iban.substr(5, 5)
                this.paymentMethod.cab = this.paymentMethod.iban.substr(10, 5)
                this.paymentMethod.cc = this.paymentMethod.iban.substr(15)
            } else {
                this.showGenericError('Lunghezza non conforme!', 'Questo IBAN ha una lunghezza di ' + this.paymentMethod.iban.length + ' caratteri, ne occorrono 27.')
            }
        },
    }
}
</script>

<style scoped>

</style>
