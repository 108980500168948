import Config from '../../globalConfig'
import axios from 'axios'

const users = {
  namespaced: true,
  state: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pUser) => {
      if (pUser.id === rootGetters.user.id) return false
      if (pUser.role < rootGetters.role && rootGetters.role !== 0) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pUser) => {
      if (pUser.id === rootGetters.user.id || rootGetters.role === 0) return true
      if (pUser.role < rootGetters.role) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async add ({commit, state, rootGetters}, pUser) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, pUser, rootGetters.apiCommon)
        axios.post(Config.endpoints.users.add, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, params) {
      params = Object.assign({}, params, rootGetters.apiCommon)
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.users.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pUser) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.users.base + pUser.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async changePassword ({commit, state, rootGetters}, data) {
      let params = Object.assign({}, data, rootGetters.apiCommon)
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.users.base + data.user_id + '/change-password', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getEmploymentCompanies () {
      return new Promise((resolve, reject) => {
        resolve([{key: 0, name: '3 Store Web Srl'}, {key: 1, name: '3G Web'}, {key: 2, name: 'Hey Code'}, {key: 3, name: 'Just23'}])
      })
    }
  }
}

export default users
