<template>
  <div>
    <el-dialog
      v-loading="loading"
      title="Invia desospensione"
      :visible.sync="visible">
      <div class="row">
        <div class="col-12 mt-2 mt-md-0 col-md-6">
          <label>Data appuntamento (+4gg lavorativi)</label>
          <el-date-picker
            v-model="appointmentDate"
            :picker-options="pickerOptions"
            type="date"
            format="dd/MM/yyyy"
            placeholder="Data dell'appuntamento"/>
        </div>
        <div class="col-12 mt-2 mt-md-0 col-md-6">
          <label>Fascia oraria</label>
          <el-select v-model="appointmentTimeSlot" placeholder="Fascia oraria" class="w-100">
            <el-option label="8:30 - 10:30" value="slot830_1030"/>
            <el-option label="10:30 - 12:30" value="slot1030_1230"/>
            <el-option label="13:00 - 15:00" value="slot1300_1500"/>
            <el-option label="15:00 - 17:00" value="slot1500_1700"/>
          </el-select>
        </div>
        <div class="col-12 mt-2">
          <label>Note desospensione (facoltativo)</label>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="Note"
            v-model="note">
          </el-input>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModal" type="danger">Chiudi</el-button>
        <el-button @click="send" type="primary" :disabled="!isValid">Invia</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import momentBusinessDays from "moment-business-days-it";
export default {
  name: "FastwebWholesaleDesuspendModal",
  props: {
    orderId: {
      required: true
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  created() {
  },
  data: function () {
    return {
      loading: false,
      pickerOptions: {
        disabledDate(date) {
          if (date.getDay() === 6 || date.getDay() === 0) {
            return true
          }
          let momentBusinessDays = require('moment-business-days-it');
          momentBusinessDays.updateLocale('it', {
            workingWeekdays: [1, 2, 3, 4, 5]
          });
          let firstPossibleDate = momentBusinessDays().businessAdd(3);
          return date.getTime() <= firstPossibleDate.unix() * 1000;
        },
      },
      appointmentDate: null,
      appointmentTimeSlot: null,
      note: null
    }
  },
  computed: {
    isValid() {
      return !(!this.appointmentDate || !this.appointmentTimeSlot);
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    send() {
      if (!this.appointmentDate) {
        this.showErrorMessage("La data dell'appuntamento è obbligatoria.")
        return
      }
      if (!this.appointmentTimeSlot) {
        this.showErrorMessage("La fascia oraria è obbligatoria")
        return;
      }

      let dateToSend = this.appointmentDate;

      dateToSend = dateToSend;
      dateToSend.setHours( dateToSend.getHours() + 6 );

      this.loading = true
      let data = {
        order_id: this.orderId,
        appointment_date: dateToSend,
        appointment_timeslot: this.appointmentTimeSlot,
        note: this.note
      }
      this.$store.dispatch('orders/fastwebWholesaleDesuspendOrder', data).then((response) => {
        this.closeModal()
        this.showSuccessMessage("Desospensione inviata correttamente")
      }).catch(e => {
        this.closeModal()
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
