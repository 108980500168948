<template>
    <div>

        <div class="d-flex align-content-center">
            <h4 class="m-0">Opzioni</h4>
            <div class="ml-4">
                <el-button icon="plus" round @click.prevent="showAddOfferOption" size="small">Aggiungi opzione
                </el-button>
            </div>
        </div>


        <div class="row py-2">
            <div class="col-12 col-md-6">
                <ul class="list-group" v-if="order_product.options && order_product.options.length > 0">
                    <li class="list-group-item" v-for="(option) in order_product.options">
                        <div class="d-flex align-items-center">
                            <span><strong>{{
                                    option.internal_name ? option.internal_name : option.name
                                }}</strong></span>
                            <small class=" ml-2 text-muted"> (ID: {{ option.id }})</small>
                            <div class="ml-auto">
                                <!--v-if="canEditOffer(order_product.offer)"!-->
                                <router-link v-if="false"
                                             :to="{path:'/offerte/opzioni/lista/modifica/' + option.id}"
                                             style="color: #616161;"><i class="el-icon-edit"
                                                                        data-tooltip="'Modifica opzione'"/>
                                </router-link>
                                <el-button data-tooltip="'Elimina opzione'" size="small" icon="close"
                                           @click="removeOfferOption(option.id)" circle class="ml-2 text-danger"/>
                            </div>
                        </div>
                        <div>
                            <small>Una tantum: <strong>{{ option.cash_amount + '€' }}</strong></small>
                            -
                            <small>Ricorrente: <strong>{{ option.recurrent_cost + '€' }}</strong></small>
                        </div>
                    </li>
                </ul>
            </div>
        </div><!-- end of row -->

        <!-- SOF DIALOG OPZIONI !-->
        <el-dialog title="Aggiungi opzione" v-model="displayAddOfferOption"
                   v-if="displayAddOfferOption">
            <div class="row">
                <div class="col-12">
                    <h5>Aggiungi in questo ordine a <strong>{{ order_product.offer.internal_name }}</strong></h5>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6 pr-2">
                    <label>Scegli l'opzione</label>
                    <br/>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="selectedOfferOption"
                               placeholder="Seleziona..." filterable size="large">
                        <el-option
                            v-for="item in offerOptions"
                            :key="item.id"
                            :label="item.id + ' - ' + item.internal_name"
                            :value="item.id">
                            <span style="float: left" v-if="item.internal_name">{{ item.internal_name }}</span><span
                            style="float: left" v-else>{{ item.name }}</span>
                            <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button class="mr-2" @click="displayAddOfferOption = false">Annulla</el-button>
                <el-button type="primary" icon="check" @click.prevent="addOfferOption">Aggiungi</el-button>
            </div>
        </el-dialog>
        <!-- EOF DIALOG OPZIONI !-->
    </div>
</template>

<script>
export default {
    name: "OrderProductOptionsSection",
    props: {
        order_product: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            displayAddOfferOption: false,
            selectedOfferOption: null,
            offerOptions: []
        }
    },
    methods: {
        showAddOfferOption() {
            this.loading = true
            this.currentOrderProductForOption = this.order_product
            this.$store.dispatch('offers/getById', this.order_product.offer.id).then((response) => {
                this.loading = false
                this.offerOptions = response.data.data.options
                if (this.offerOptions.length < 1) {
                    this.showErrorMessage('Questa offerta non ha opzioni compatibili da poter aggiungere.')
                } else {
                    this.selectedOfferOption = null
                    this.displayAddOfferOption = true
                }
            }).catch(e => {
                this.loading = false
                this.showErrorMessage('Errore durante il caricamento dei dettagli di questa offerta per ottenere le opzioni.')
            })
        },
        removeOfferOption(pOfferOptionId) {
            this.askDeleteMessage('l\'opzione').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('orders/orderProducts/removeOption', {
                    id: this.order_product.id,
                    option_id: pOfferOptionId
                }).then((response) => {
                    this.loading = false
                    this.showSuccessMessage("Opzione rimossa con successo.")
                    this.order_product.options = response.data.order_product_options;
                    //TODO emit edit
                }).catch(e => {
                    this.loading = false
                    this.showErrorMessage('Errore durante la rimozione di questa opzione.')
                })
            })
        },
        addOfferOption() {
            this.loading = true
            this.$store.dispatch('orders/orderProducts/addOption', {
                id: this.order_product.id,
                option_id: this.selectedOfferOption
            }).then((response) => {
                this.loading = false
                this.showSuccessMessage('Opzione aggiunta con successo')

                this.order_product.options = response.data.order_product_options;
                //TODO emit edit con options response.data.order_product_options
                this.displayAddOfferOption = false
                this.offerOptions = []
                this.selectedOfferOption = null
            }).catch(e => {
                this.loading = false
                this.showErrorMessage('Errore durante l\'aggiunta delle opzioni di questa offerta.')
            })
        },
        canEditOffer(pElement) {
            return this.$store.getters['offers/canEdit'](pElement)
        },
    }
}
</script>

<style scoped>

</style>
