import Config from '../../globalConfig'
import axios from 'axios'

const productAlerts = {
  namespaced: true,
  state: {
  },
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canDuplicate: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {
  },
  actions: {
    async add ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.add, params)
            .then((response) => {
              if (!response.data.result) {
                reject(response)
                return
              }
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    },
    async delete ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.productAlerts.base + pElement.id + '/delete', rootGetters.apiCommon)
            .then((response) => {
              if (!response.data.result) {
                reject(response)
                return
              }
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    },
    async edit ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getById ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.base + params.id, params)
              .then((response) => {
                if (!response.data.result) {
                  reject(response)
                  return
                }
                resolve(response)
              })
              .catch((e) => {
                reject(e)
              })
      })
    },
    async getStatuses ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.statuses, params)
            .then((response) => {
              if (!response.data.result) {
                reject(response)
                return
              }
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    },
    async list ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async loadCustomerTypes ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.customertypes, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async sendNotification ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.productAlerts.sendNotification, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default productAlerts
