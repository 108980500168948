<template>

  <div>
    <form>
      <div class="row">
        <div class="col-md-3 col-12">
          <label>Abilitata</label>
          <br />
          <l-switch v-model="rate.enabled" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Nome al pubblico"
                    v-model="rate.nome"
                    name="nome"
                    v-validate="modelValidations.nome"
                    :error="getError('nome')">
          </el-input>
        </div>

        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Nome interno"
                    v-model="rate.internal_name"
                    name="internal_name"
                    v-validate="modelValidations.internal_name"
                    :error="getError('internal_name')">
          </el-input>
        </div>

        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="ID H3G"
                    v-model="rate.id_h3g"
                    name="id_h3g">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <label>Tipo utenza</label>
          <br />
          <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="rate.tipo_utenza" placeholder="Seleziona..." size="large">
            <el-option
              v-for="item in userTypes"
              class="select-default"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              name="tipo_utenza"
              v-validate="modelValidations.tipo_utenza"
              :error="getError('tipo_utenza')">
            </el-option>
          </el-select>
        </div>
        <div class="col-12 col-md-4">
          <label>Tipologia</label>
          <br />
          <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="rate.type" placeholder="Seleziona..." size="large">
            <el-option
              v-for="item in types"
              class="select-default"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              name="type"
              v-validate="modelValidations.type"
              :error="getError('type')">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <label>Tipo tariffazione</label>
          <br />
          <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="rate.tipo_tariffazione" placeholder="Seleziona..." size="large">
            <el-option
              v-for="item in billingPeriodTypes"
              class="select-default"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              name="tipo_tariffazione"
              v-validate="modelValidations.tipo_tariffazione"
              :error="getError('tipo_tariffazione')">
            </el-option>
          </el-select>
        </div>
        <div class="col-12 col-md-4">
          <label>Tipo fatturazione</label>
          <br />
          <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="rate.tipo_fatturazione" placeholder="Seleziona..." size="large">
            <el-option
              v-for="item in billingPeriodTypes"
              class="select-default"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              name="tipo_fatturazione"
              v-validate="modelValidations.tipo_fatturazione"
              :error="getError('tipo_fatturazione')">
            </el-option>
          </el-select>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Minuti"
                    v-model="rate.minuti_inclusi"
                    name="minuti_inclusi"
                    v-validate="modelValidations.minuti_inclusi"
                    :error="getError('minuti_inclusi')">
          </el-input>
        </div>
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="SMS"
                    v-model="rate.sms_inclusi"
                    name="sms_inclusi"
                    v-validate="modelValidations.sms_inclusi"
                    :error="getError('sms_inclusi')">
          </el-input>
        </div>
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Internet (indicare MB/GB)"
                    v-model="rate.internet_incluso"
                    name="internet_incluso"
                    v-validate="modelValidations.internet_incluso"
                    :error="getError('internet_incluso')">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Canone"
                    v-model="rate.canone_mensile"
                    name="canone_mensile"
                    v-validate="modelValidations.canone_mensile"
                    :error="getError('canone_mensile')"
                    addon-right-icon="fa fa-eur">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 col-12">
          <label>LTE</label>
          <br />
          <l-switch v-model="rate.lte" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
      </div>

      <div class="row" v-if="!definisciCostiConsumo">
        <div class="col-3">
          <button class="btn btn-default btn-outline mb-3 mt-3" @click.prevent="definisciCostiConsumo = true">
            <span class="btn-label">
               <i class="el-icon-edit"></i> Definisci costi a consumo
            </span>
          </button>
        </div>
      </div>

      <div class="row" v-if="definisciCostiConsumo">
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="EXTRA SOGLIA VOCE"
                    v-model="rate.costo_minuto_conversazione"
                    name="costo_minuto_conversazione"
                    addon-right-icon="fa fa-eur">
          </el-input>
        </div>
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="EXTRA SOGLIA SMS"
                    v-model="rate.costo_sms"
                    name="costo_sms"
                    addon-right-icon="fa fa-eur">
          </el-input>
        </div>
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="EXTRA SOGLIA INTERNET"
                    v-model="rate.costo_internet_mb"
                    name="costo_internet_mb"
                    addon-right-icon="fa fa-eur">
          </el-input>
        </div>
      </div>

      <div class="row" v-if="definisciCostiConsumo">
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Internet incluso notte"
                    v-model="rate.internet_incluso_notte"
                    name="internet_incluso_notte">
          </el-input>
        </div>
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="Scatti anticipati"
                    v-model="rate.scatti_anticipati"
                    name="scatti_anticipati"
                    addon-right-icon="fa fa-eur">
          </el-input>
        </div>
        <div class="col-12 col-md-4">
          <el-input type="text"
                    label="EXTRA SOGLIA"
                    v-model="rate.extra_soglia"
                    name="extra_soglia"
                    addon-right-icon="fa fa-eur">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Note</label>
          <br />
          <editor style="height: 200px;" v-model="rate.note" :init="$config.tinyMCEConfig"></editor>
        </div>
      </div>

      <div class="row" style="margin-top: 30px">
        <div class="col-12 text-center">

          <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement" v-if="rate.id">
            Elimina tariffa
          </button>

          <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
            Salva tariffa
          </button>

        </div>
      </div>

      <div class="clearfix"></div>
    </form>
  </div>
</template>
<style>
</style>
<script>
  import {Switch as LSwitch} from 'src/components/index'
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      LSwitch,
      'editor': Editor
    },
    props: {
      rate: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/tariffe/lista'
        },
        content: '',
        loading: false,
        modelValidations: {
          nome: {
            required: true
          },
          internal_name: {
            required: true
          },
          canone_mensile: {
            required: true,
            decimal: true
          },
          minuti_inclusi: {
            required: true
          },
          sms_inclusi: {
            required: true
          },
          internet_incluso: {
            required: true
          },
          tipo_utenza: {
            required: true
          },
          type: {
            required: true
          },
          tipo_tariffazione: {
            required: true
          },
          tipo_fatturazione: {
            required: true
          }
        },
        types: [],
        userTypes: [],
        billingPeriodTypes: [],
        definisciCostiConsumo: false
      }
    },
    watch: {},
    computed: {},
    methods: {
      deleteElement () {
        this.$emit('delete', this.rate)
      },
      validateForm () {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.$emit('update', this.rate)
          }
        })
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      loadTypes () {
        this.loading = true
        this.$store.dispatch('rates/getTypes').then((response) => {
          this.loading = false
          this.types = response.data.rate_types
          this.loadUserTypes()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle tipologie')
        })
      },
      loadUserTypes () {
        this.loading = true
        this.$store.dispatch('rates/getUserTypes').then((response) => {
          this.loading = false
          this.userTypes = response.data.user_types
          this.loadBillingPeriodTypes()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento dei tipi di utenza')
        })
      },
      loadBillingPeriodTypes () {
        this.loading = true
        this.$store.dispatch('rates/getBillingPeriodTypes').then((response) => {
          this.loading = false
          this.billingPeriodTypes = response.data.billing_period_types
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento del tipo tariffazione')
        })
      },
      loadResources () {
        this.loadTypes()
      }
    },
    created: function () {
      this.loadResources()
    },
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
