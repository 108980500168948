<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Lista Peoplesoft">

        <div class="table-layout-container">

          <div class="row">
            <div class="col-12 col-md-4 col-lg-4 col-xl-3">
              <router-link :to="{path:'/offerte/peoplesoft/aggiungi'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-plus"></i> Aggiungi Peoplesoft
                </span>
                </button>
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage" :per-page="currentState.pagination.perPage" :total="currentState.pagination.total" @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 col-12">
              <div class="row">
                <div class="mb-3 col-12 col-sm-12">
                  <el-select style="width: 100%" v-model="currentState.pagination.perPage" placeholder="Per pagina" @change="handlePerPageChange">
                    <el-option v-for="item in componentSettings.pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="col-sm-10 col-12">
              <div class="row">
                <div class="col-sm-3 col-12 mb-3">
                  <el-select multiple style="width: 100%" v-model="currentState.searchFields.selected" placeholder="Dove cerco?" @change="handleSearchFieldsChange">
                    <el-option v-for="(option, index) in searchFields" :value="option.searchFields" :label="option.label" :key="index">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-3 col-12 mb-3">
                  <el-select style="width: 100%" v-model="currentState.searchTypeSelected" placeholder="Come cercare?" @change="handleSearchTypeChange">
                    <el-option v-for="(item, index) in searchTypeOptions" :key="index" :label="item.label" :value="index"></el-option>
                  </el-select>
                </div>

                <div class="col-12 col-sm-4">
                  <el-input style="width: 100%" type="search" placeholder="Cerca" v-model="currentState.searchQuery" @change="handleSearchChange" addon-right-icon="fa fa-search" />
                </div>

                <div class="col-sm-2 col-12 mb-3 no-padding-left-desktop">
                  <button class="btn btn-outline btn-danger btn-outline col-12" style="padding: 8px 0px; width: 100%" @click="resetFilters()" data-tooltip="'Reset dei filtri'">
                          <span class="btn-label">
                             <i class="fa fa-times"></i>
                          </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped" :data="tableData" border style="width: 100%" :default-sort="currentState.currentSorting" @sort-change="handleSortChange" @selection-change="handleSelectionChange">


                <template v-for="column in tableColumns">
                  <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                  <el-table-column v-else-if="column.type === 'selection'" type="selection" :width="calculateTableWidth(column)"></el-table-column>

                  <el-table-column v-else-if="column.custom === 'internal_name'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <span class="td-strong" v-if="props.row">{{props.row.internal_name}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <router-link :to="{path:'/offerte/peoplesoft/lista/modifica/' + props.row.id}">
                          <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link" v-if="canEdit(props.$index, props.row)"><i
                            class="fa fa-edit"></i></a>
                        </router-link>

                        <a data-tooltip="'Duplica'" class="btn-success btn-simple btn-link"
                           @click="handleDuplicate(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-copy"></i></a>

                        <i class="fa fa-clone" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>

                        <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                           @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>

                        <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>
                      </div>
                    </template>
                  </el-table-column>
                </template>

              </el-table>
            </div>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da <strong>{{currentState.pagination.from}}</strong> a <strong>{{currentState.pagination.to}}</strong> su <strong>{{currentState.pagination.total}}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
    },
    computed: {},
    data () {
      return {
        componentSettings: {
          singleFieldSearch: true,
          searchTypeOption: 0,
          tableSort: {prop: 'id', order: 'descending'},
          myComponentName: 'PeoplesoftList',
          minimumPermissionLevel: 100,
          pagination: {
            perPageOptions: [5, 10, 25, 50, 100]
          },
          searchFieldsDefault: ['internal_name']
        },
        currentState: {
          currentSorting: {prop: 'id', order: 'descending'},
          searchFields: {
            selected: ['internal_name'],
            lastSelection: ['internal_name']
          },
          pagination: {
            perPage: 25,
            currentPage: 1,
            lastLoadedPage: 1,
            total: 0,
            from: 0,
            to: 0,
            showedItems: 0
          },
          searchQuery: '',
          searchTypeSelected: 0,
          tableMultipleSelect: null
        },
        searchFields: [
          {searchFields: 'internal_name', label: 'Nome Interno'},
          {searchFields: 'id', label: 'ID'}
        ],
        searchTypeOptions: [
          {searchType: '0', label: 'Contiene'},
          {searchType: '2', label: 'Inizia per'},
          {searchType: '1', label: 'È uguale a'}
        ],
        tableColumns: [
          {
            prop: 'id',
            label: 'ID',
            percentWidth: 8,
            sortable: 'custom',
            showTooltip: true,
            fixed: true,
            type: 'default',
            first: true,
            custom: false
          },
          {
            prop: 'internal_name',
            label: 'Nome Interno',
            percentWidth: 77,
            sortable: 'custom',
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'internal_name'
          },
          {
            label: 'Azioni',
            percentWidth: 15,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'azioni'
          }
        ],
        loading: false,
        tableData: [],
        canShowView: false
      }
    },
    methods: {
      getSaveStateConfig () {
        return {
          'cacheKey': this.componentSettings.myComponentName,
          'saveProperties': ['currentState']
        }
      },
      resetFilters (pReload = true) {
        this.currentState.searchQuery = ''
        this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
        this.currentState.tableMultipleSelect = null
        this.currentState.searchTypeSelected = 0
        this.currentState.pagination.perPage = 25
        this.currentState.pagination.currentPage = 1
        this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
        this.clearSavedState()
        if (pReload) this.updateData()
      },
      handleSelectionChange (pSelection) {
        this.currentState.tableMultipleSelect = pSelection
      },
      handleSortChange (pSortType) {
        let cSort = this.findColumnSort(pSortType)
        if (pSortType.prop === null) {
          this.currentState.currentSorting = this.componentSettings.tableSort
        } else {
          if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
            return
          }
          this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
        }
        this.updateData()
      },
      handleSearchTypeChange (pIndex) {
        if (this.currentState.searchQuery === '') return
        this.updateData()
      },
      handleSearchFieldsChange (pIndex) {
        if (this.currentState.searchFields.selected.length < 1) {
          this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
          return
        }
        if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
          this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
        }
        this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
        this.updateData()
      },
      removeUser (pUser) {
        return this.axios.post(this.$config.endpoints.users.base + pUser.id + '/delete', this.$store.getters.apiCommon)
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          let elementToDelete = this.tableData[indexToDelete]
          Swal.fire(this.deleteMessage(elementToDelete.internal_name)).then((result) => {
            if (!result.value) return
            this.$store.dispatch('peoplesoft/delete', elementToDelete).then(() => {
              Swal.fire('Eliminato!',
                'L\'elemento Peoplesoft è stato eliminato.',
                'success'
              ).then(() => {
                if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                  this.currentState.pagination.currentPage--
                }
                this.updateData()
              })
            })
              .catch(e => {
                this.loading = false
                this.showLoadingError(e)
              })
          })
        }
      },
      handleDuplicate (index, row) {
        let indexToDuplicate = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDuplicate >= 0) {
          let elementToDuplicate = this.tableData[indexToDuplicate]
          this.askDuplicateMessage(elementToDuplicate.internal_name).then((result) => {
            if (!result.value) return
            this.$store.dispatch('peoplesoft/duplicate', elementToDuplicate).then(() => {
              Swal.fire('Duplicato!',
                'L\'elemento Peoplesoft è stato duplicato.',
                'success'
              ).then(() => {
                if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                  this.currentState.pagination.currentPage--
                }
                this.updateData()
              })
            })
              .catch(e => {
                this.loading = false
                this.showLoadingError(e)
              })
          })
        }
      },
      handlePerPageChange (perPage) {
        this.updateData({page: 1, limit: perPage})
      },
      handlePageChange (page) {
        if (page === this.currentState.pagination.lastLoadedPage) return
        this.updateData()
      },
      handleSearchChange () {
        this.updateData()
      },
      updateData (params) {
        this.loading = true
        if (!params) params = {}
        params = Object.assign(params, this.$store.getters.apiCommon, {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage, company: true})

        if (this.currentState.searchQuery !== '') {
          params = Object.assign(params, {search: this.currentState.searchQuery, search_fields: this.currentState.searchFields.selected, search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType})
        }
        if (this.currentState.currentSorting !== null) {
          params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
        } else {
          params = Object.assign(params, {orderby: 'id', order_direction: 'desc'})
        }
        this.$store.dispatch('peoplesoft/list', params).then((response) => {
          this.loading = false
          this.tableData = response.data.peoplesofts.data
          this.currentState.pagination.total = response.data.peoplesofts.total
          this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
          this.currentState.pagination.showedItems = response.data.count
          this.currentState.pagination.from = response.data.peoplesofts.from
          this.currentState.pagination.to = response.data.peoplesofts.to
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e)
        })
      },
      canEdit (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['peoplesoft/canEdit'](elementToCheck)
        }
      },
      canDelete (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['peoplesoft/canDelete'](elementToCheck)
        }
      },
      canDuplicate (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['peoplesoft/canDuplicate'](elementToCheck)
        }
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },

    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
</style>
