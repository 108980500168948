<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Modifica tariffa">
        <div slot="header">
          <h4 class="title mt-0">Modifica di <strong>{{rate.nome}}</strong></h4>

          <h5 v-if="!loading">Creato il: {{dateToItalian(rate.created_at, true)}}</h5>
          <p class="text-muted" v-if="!loading">Ultima modifica: {{dateToItalian(rate.updated_at, true)}}</p>

          <div class="row">
            <div class="col-12 col-md-3">
              <router-link :to="{path:'/tariffe/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista tariffe
                </span>
                </button>
              </router-link>
            </div>
          </div>

        </div>
        <rate-form v-bind:rate="rate" v-loading="loading" @update="updateForm" @delete="deleteElement"></rate-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  import RateForm from './RateForm.vue'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
      RateForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'RateEdit',
          minimumPermissionLevel: 100,
          listPath: '/tariffe/lista'
        },
        loading: false,
        canShowView: false,
        rate: {}
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('rates/getById', this.$route.params.id).then((response) => {
          this.rate = response.data.rate
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questa tariffa')
        })
      },
      updateForm (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('rates/edit', pElement).then((response) => {
          this.loading = false
          this.rate = response.data.rate
          this.showGenericSuccess('Tariffa', 'aggiornata con successo')
          this.$router.push(this.componentSettings.listPath)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questa tariffa', '', false)
        })
      },
      deleteElement (pElement) {
        Swal.fire(this.deleteMessage('questa tariffa')).then((result) => {
          if (!result.value) return
          this.$store.dispatch('rates/delete', pElement).then(() => {
            Swal.fire('Eliminata!',
              'Stata eliminata!',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione della tariffa', '', false)
          })
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
