import Config from '../../../globalConfig'
import notifications from './statuses/notifications'
import axios from 'axios'

const statuses = {
  namespaced: true,
  modules: {
    notifications
  },
  state: {
    flows: [],
    statuses: []
  },
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pStatus) => { // Supporto per lo stato da eliminare come parametro
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pStatus) => { // Supporto per lo stato da modificare come parametro
      if (rootGetters.role > 100) return false
      return true
    },
    getStatusInfoById: (state, getters, rootState, rootGetters) => (pStatusId) => {
      pStatusId = parseInt(pStatusId)
      for (let index = 0; index < getters.statuses.length; ++index) {
        if (getters.statuses[index].id === pStatusId) {
          return getters.statuses[index]
        }
        for (let indexChild = 0; indexChild < getters.statuses[index].child_statuses.length; ++indexChild) {
          if (getters.statuses[index].child_statuses[indexChild].id === pStatusId) {
            return getters.statuses[index].child_statuses[indexChild]
          }
        }
      }
      return {found: false, internal_name: 'Tutti', name: 'Tutti'} // Simula risposta vuota
    },
    flows: state => state.flows,
    statuses: state => state.statuses
  },
  mutations: {
    updateFlows: (state, pFlows) => {
      state.flows = pFlows
    },
    updateStatuses: (state, pStatuses) => {
      state.statuses = pStatuses
    }
  },
  actions: {
    reset ({commit}) {
      commit('updateFlows', [])
      commit('updateStatuses', [])
    },
    async indexEdit ({commit, state, rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({direction: payload.direction}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderStatuses.base + payload.status.id + '/edit-index', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async edit ({commit, state, rootGetters}, pStatus) {
      return new Promise((resolve, reject) => {
        var params = Object.assign(pStatus, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.base + pStatus.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pStatus) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.statuses.base + pStatus.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async add ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.add, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {structure: true}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async listStructured ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderStatuses.listStructured, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getFlows ({commit, state, getters, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        if (getters.flows.length > 0) {
          resolve(getters.flows)
          return
        }
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.flows, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateFlows', response.data.order_status_flows)
            resolve(response.data.order_status_flows)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async loadCachedStatuses ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({structure: true}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(false)
              return
            }
            commit('updateStatuses', response.data.order_statuses)
            resolve(true)
          })
          .catch((e) => {
            reject(false)
          })
      })
    },
    async getById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.statuses.base + pId, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default statuses
