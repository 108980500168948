<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">

        <el-card class="mb-4">
          <h4 class="mt-0 mb-1">Brucia codici</h4>

          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 px-0">
            Brucia i codici associati a una promozione.<br/>

            <label class="mt-4">Seleziona una promozione</label>
            <br/>

            <div class="row">
              <el-select id="selectPromo" v-model="currentPromo" class="col-12 col-xl-9 select-default mb-3" placeholder="Seleziona una promozione..." size="large">

                <el-option
                  v-for="item in promo_list"
                  :key="item.id"
                  :label="item.public_name + parsedLabelText(item.internal_name)"
                  :value="item.id">
                  <span>{{item.public_name}} <span v-if="item.internal_name" class="small n-lightgrey">({{item.internal_name}})</span></span>
                </el-option>

              </el-select>
            </div>

            <div class="row">
              <div class="col-12 col-xl-9">
                <el-input
                  type="text"
                  label="ID ordine"
                  name="ID ordine"
                  id="OrderIdBurnCode"
                  placeholder="ID ordine"
                  v-model="OrderIdBurnCode">
                </el-input>
              </div>
            </div>

            <label v-if="current_order_products">Seleziona un prodotto nell'ordine</label>
            <br/>
            <div class="row" v-if="current_order_products">
              <el-select v-model="currentOrderProduct" id="selectProduct" class="col-12 col-xl-9 select-default mb-3" placeholder="Seleziona un prodotto..." size="large">

                <el-option
                  v-for="item in current_order_products"
                  :key="item.id"
                  :label="item.id + ' | ' + item.offer.internal_name"
                  :value="item.id">
                </el-option>

              </el-select>
            </div>

            <div class="row mb-3 mt-2">
              <div class="col-12">
                <div class="mb-3">
                  <button v-if="isLoadingOrderProducts" :disabled="!currentPromo || !OrderIdBurnCode" type="submit" class="btn btn-primary btn-fill btn-wd" @click="getCurrentOrderProducts">
                    Carica prodotti
                  </button>

                  <button v-if="isAttaching" :disabled="!currentPromo || !OrderIdBurnCode" type="submit" class="btn btn-primary btn-fill btn-wd" @click="attachToOrderProduct">
                    Genera e associa codice
                  </button>

                  <button class="btn btn-warning btn-outline a-link ml-2" @click="resetFieldsCodeBurner">
                    <span>Azzera campi</span><i class="el-icon-refresh ml-1" style="font-size: 0.8em;"></i>
                  </button>

                </div>
              </div>
            </div>

          </div>
        </el-card>

        <el-card class="mb-4">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 px-0">
            <h4 class="mt-0 mb-1">Brucia singolo codice</h4>
            Brucia un codice già ottenuto.<br/>

            <div class="row">
              <div class="col-12 col-xl-9">
                <el-input
                  class="mt-4"
                  type="text"
                  label="Codice da bruciare"
                  name="Codice da bruciare"
                  id="codeBurnSingleCode"
                  placeholder="Codice da bruciare"
                  v-model="codeBurnSingleCode">
                </el-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-xl-9">
                <el-input
                  type="text"
                  label="ID ordine"
                  name="ID ordine"
                  id="OrderIdBurnSingleCode"
                  placeholder="ID ordine"
                  v-model="OrderIDBurnSingleCode">
                </el-input>
              </div>
            </div>

            <div class="row mb-3 mt-2">
              <div class="col-12">
                <div class="mb-3">
                  <button :disabled="!codeBurnSingleCode|| !OrderIDBurnSingleCode" type="submit" class="btn btn-primary btn-fill btn-wd" @click="burnCode">
                    Conferma
                  </button>

                  <button class="btn btn-warning btn-outline a-link ml-2" @click="resetFieldsBurnSingleCode">
                    <span>Azzera campi</span><i class="el-icon-refresh ml-1" style="font-size: 0.8em;"></i>
                  </button>

                </div>
              </div>
            </div>

          </div>
        </el-card>

        <el-card>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 px-0">
            <h4 class="mt-0 mb-1">Resetta codice</h4>
            Resetta un codice, rendendolo nuovamente disponibile.<br/>

            <div class="row">
              <div class="col-12 col-xl-9">
                <el-input
                  class="mt-4"
                  type="text"
                  label="Codice da resettare"
                  name="Codice da resettare"
                  id="codeResetCode"
                  placeholder="Codice da resettare"
                  v-model="codeResetCode">
                </el-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-xl-9">
                <el-input
                  type="text"
                  label="ID ordine"
                  name="ID ordine"
                  id="OrderIdResetCode"
                  placeholder="ID ordine"
                  v-model="OrderIDResetCode">
                </el-input>
              </div>
            </div>

            <div class="row mb-3 mt-2">
              <div class="col-12">
                <div class="mb-3">
                  <button :disabled="!codeResetCode || !OrderIDResetCode" type="submit" class="btn btn-primary btn-fill btn-wd" @click="resetCode">
                    Conferma
                  </button>

                  <button class="btn btn-warning btn-outline a-link ml-2" @click="resetFieldsResetCode">
                    <span>Azzera campi</span><i class="el-icon-refresh ml-1" style="font-size: 0.8em;"></i>
                  </button>

                </div>
              </div>
            </div>

          </div>
        </el-card>

      </div>
    </div>
  </div>
</template>

<script>
  import Swal from "sweetalert2";

  export default {
    components: {
    },
    computed: {},
    data () {
      return {
        componentSettings: {
          myComponentName: 'CodeBurner'
        },
        loading: false,
        promo_list: null,
        currentPromo: null,
        currentOrderProduct: null,
        OrderIdBurnCode: null,
        OrderIDBurnSingleCode: null,
        OrderIDResetCode: null,
        current_order_products: null,
        isLoadingOrderProducts: true,
        isAttaching: false,
        codeBurnCode: null,
        codeBurnSingleCode: null,
        codeResetCode: null
      }
    },
    watch: {
      currentPromo: function (val) {
        this.currentPromo = val
        this.isAttaching = false
        this.isLoadingOrderProducts = true
        this.current_order_products = null
      }
    },
    methods: {
      loadPromos () {
        this.loading = true
        this.$store.dispatch('promoCodes/loadPromoCodes', {limit: '-1'}).then((response) => {
          this.promo_list = response
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei codici promozionali')
        })
      },
      burnCode () {
        this.loading = true
        this.$store.dispatch('promoCodes/burnCode', {code: this.codeBurnSingleCode}).then((response) => {
          this.loading = false
          Swal.fire('Bruciato!',
            'Codice ' + this.codeBurnSingleCode + '<br />' + 'bruciato con successo.',
            'success'
          )
          this.resetFieldsBurnSingleCode()
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il burning del codice inserito')
        })
      },
      getCurrentOrderProducts () {
        this.loading = true
        this.$store.dispatch('promoCodes/getCurrentOrderProducts', {id: this.OrderIdBurnCode, offer: '1'}).then((response) => {
          this.current_order_products = response.data.order_products
          this.loading = false
          this.isAttaching = true
          this.isLoadingOrderProducts = false
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei prodotti per l\'ordine corrente')
        })
      },
      attachToOrderProduct () {
        this.loading = true
        this.$store.dispatch('promoCodes/attachToOrderProduct', {order_product_id: this.currentOrderProduct, promo_id: this.currentPromo}).then((response) => {
          this.loading = false
          Swal.fire('Collegati!',
            'Promozione e prodotto nell\'ordine ' + this.OrderIdBurnCode + '<br />' + 'collegati con successo.' + '<br />' + 'Promo code: ' + response.data.shop_code.code + '.',
            'success'
          )
          this.resetFieldsCodeBurner()
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'collegamento della promozione e del prodotto dell\'ordine corrente')
        })
      },
      resetCode () {
        this.loading = true
        this.$store.dispatch('promoCodes/resetCode', {code: this.codeResetCode}).then((response) => {
          this.loading = false
          Swal.fire('Resettato!',
            'Codice ' + this.codeResetCode + '<br />' + 'resettato con successo con successo.',
            'success'
          )
          this.resetFieldsResetCode()
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'reset del codice')
        })
      },
      resetFieldsCodeBurner () {
        this.OrderIdBurnCode = null
        this.currentPromo = null
        this.currentOrderProduct = null
        this.current_order_products = null
        this.isLoadingOrderProducts = true
        this.isAttaching = false
        this.codeBurnCode = null
      },
      resetFieldsBurnSingleCode () {
        this.OrderIDBurnSingleCode = null
        this.codeBurnSingleCode = null
      },
      resetFieldsResetCode () {
        this.OrderIDResetCode = null
        this.codeResetCode = null
      }
    },
    created: function () {
      this.loadPromos()
    },
    mounted: function () {
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>

<style>
</style>
