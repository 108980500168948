<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">

            <el-dialog
                v-model="this.showRaw"
                width="90%">
                <div>
                    <h4 class="mt-0">Contenuto</h4>
                </div>
                <pre>{{ this.migrationRaw }}</pre>
            </el-dialog>

            <el-card title="Migrazioni in entrata">
                <p>Qui trovi le risposte a tutte le richieste di migrazione effettuate da Dimensione, pertanto dove Dimensione è il recipient e i clienti di altro OLO stanno diventando clienti Dimensione.</p>
                <div class="table-layout-container">

                    <div class="d-flex flex-wrap">

                        <div style="margin-top: 5px" class=".col-">
                            <label>Filtra per status</label>
                            <el-select class="select-default" style="width: 100%; max-width: 300px;"
                                       v-model="currentState.statusFilter"
                                       placeholder="Filtra per stato" @change="handleStatusFilterChange">
                                <el-option class="select-default" v-for="status in statusList" :key=status.key
                                           :label="status.label" :value=status.key>

                                </el-option>
                                <el-option class="select-default" :key=null label="Tipo: Tutti" :value=null></el-option>
                            </el-select>
                        </div>

                        <!--
                        <div style="margin-top: 5px" class=".col-">
                          <olo-code-donating-select class="w-100" v-model="currentState.donatingFilter"
                                                    @change="updateData"></olo-code-donating-select>
                        </div>
                        -->

                        <div style="margin-top: 5px" class=".col- d-flex flex-column justify-content-between">
                            <label>Elementi per pagina</label>
                            <el-select class="select-default" v-model="currentState.pagination.perPage"
                                       placeholder="Per pagina"
                                       @change="handlePerPageChange">
                                <el-option class="select-default"
                                           v-for="item in componentSettings.pagination.perPageOptions"
                                           :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-2">
                                <l-pagination class="pagination-no-border float-right"
                                              v-model="currentState.pagination.currentPage"
                                              :per-page="currentState.pagination.perPage"
                                              :total="currentState.pagination.total"
                                              @input="handlePageChange"></l-pagination>
                            </div>
                        </div>
                    </div>
                    <el-button @click="updateData"><el-icon><RefreshRight /></el-icon></el-button>
                    <div class="row">
                        <div class="col-12" v-loading="loading">

                            <el-table class="table-striped" :data="tableData" border style="width: 100%"
                                      :default-sort="currentState.currentSorting" @sort-change="handleSortChange"
                                      @selection-change="handleSelectionChange">


                                <template v-for="column in tableColumns">
                                    <el-table-column v-if="!column.custom && column.type !== 'selection'"
                                                     :type="column.type"
                                                     :fixed="column.fixed" :key="column.label"
                                                     :width="calculateTableWidth(column)"
                                                     :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label"></el-table-column>

                                    <el-table-column v-else-if="column.type === 'selection'"
                                                     type="selection"></el-table-column>

                                    <el-table-column v-else-if="column.custom === 'id'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="100">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <span v-if="props.row.id">{{ props.row.id }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'codice_sessione'" :type="column.type"
                                                     width="350"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="d-flex flex-column">
                                                    <span>{{props.row.codice_sessione }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'codice_ordine'" :type="column.type"
                                                     :width="240"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <router-link :to="{path:resolveOrderLink(props.$index, props.row)}">
                                                    <span style="cursor: pointer; color: #3f7da4; font-weight: bolder">
                                                        {{props.row.recipient_order_code }}
                                                    </span>
                                            </router-link>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'status'" :type="column.type"
                                                     :width="150"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="d-flex flex-column">
                          <span v-if="props.row.status === 'pending'"
                                style="color:green">In Attesa</span>
                                                    <span v-if="props.row.status === 'handled'"
                                                          style="color:grey">Gestita</span>
                                                    <span v-if="props.row.status === 'ignored'"
                                                          style="color:grey">Ignorata</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'raw'" :type="column.type"
                                                     :width="100"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="text-center">
                                                    <a v-b-tooltip.hover title="Apri il file per vedere il contenuto"
                                                       class="btn-warning btn-simple btn-link mb-3"
                                                       style="color: black"
                                                       @click="openContentFileMigration(props.$index, props.row)">
                                                        <i class="fa fa-file-code-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'arrived_at'" :type="column.type"
                                                     :width="150"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                        <span class="td-strong">
                            <strong>{{ dateToItalian(props.row.arrived_at, false, "DD/MM/YYYY HH:mm") }}</strong>
                        </span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'codice_motivo'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="d-flex flex-column">
                                                    <span style="text-align: left; color:green"
                                                          v-if="props.row.codice_motivo === '' || props.row.codice_motivo === null">Esito positivo</span>

                                                    <span
                                                        v-if="!(props.row.codice_motivo === '' || props.row.codice_motivo === null)"
                                                        style="color:#f36464; text-align: left">Esito negativo</span>
                                                    <span
                                                        v-if="!(props.row.motivo_rifiuto === '' || props.row.motivo_rifiuto === null)"
                                                        style=" text-align:left;color:#f36464; font-size: 10px; font-weight: bolder;">{{
                                                            props.row.motivo_rifiuto+"("+props.row.codice_motivo+")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'motivo_rifiuto'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="d-flex flex-column">
                                                    <span
                                                        v-if="props.row.motivo_rifiuto === '' || props.row.motivo_rifiuto === null">-</span>
                                                    <span
                                                        v-if="!(props.row.motivo_rifiuto === '' || props.row.motivo_rifiuto === null)"
                                                        style="color:red">{{ props.row.motivo_rifiuto }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'azioni'" :width="170"
                                                     :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop"
                                                     :label="column.label">
                                        <template #default="props">

                                            <div
                                                style="text-align: center; display:flex; flex-direction: row; justify-content: space-between"
                                                class="table-actions" v-if="props.row">



                                                <a v-b-tooltip.hover title="Gestisci la migrazione"
                                                   class="btn-success btn-simple btn-link mb-3"
                                                   @click="handleMigration(props.$index, props.row, 'Gestisci')"
                                                   v-if="canEdit(props.$index, props.row) && canMigrationSetToHandledOrIgnoredState(props.$index, props.row)"><i
                                                    class="fa fa-check-circle"></i>
                                                </a>

                                                <a v-b-tooltip.hover title="Ignora la migrazione"
                                                   class="btn-warning btn-simple btn-link mb-3"
                                                   @click="handleMigration(props.$index, props.row, 'Ignora')"
                                                   v-if="canEdit(props.$index, props.row) && canMigrationSetToHandledOrIgnoredState(props.$index, props.row)">
                                                    <i class="fa fa-exclamation-circle"></i>
                                                </a>

                                                <span style="white-space: pre-line;" v-if="isHandled(props.$index, props.row)">{{ "Già gestita\n" + dateToItalian(props.row.updated_at, false, "DD/MM/YYYY HH:mm")}}</span>

                                                <a v-b-tooltip.hover title="Riportala a In Attesa"
                                                   class="btn-warning btn-simple btn-link mb-3"
                                                   @click="revertRToPendingAgain(props.$index, props.row)"
                                                   v-if="isRevertAllowed(props.$index, props.row)">
                                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                                </a>

                                            </div>
                                        </template>
                                    </el-table-column>

                                </template>
                            </el-table>
                        </div>
                    </div>
                    <div slot="footer"
                         class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
                        <div class="">
                            <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da
                                <strong>{{ currentState.pagination.from }}</strong> a
                                <strong>{{ currentState.pagination.to }}</strong>
                                su <strong>{{ currentState.pagination.total }}</strong> totali</p>
                        </div>
                        <l-pagination class="pagination-no-border"
                                      v-model="currentState.pagination.currentPage"
                                      :per-page="currentState.pagination.perPage"
                                      :total="currentState.pagination.total"
                                      @input="handlePageChange">
                        </l-pagination>

                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
import Swal from "sweetalert2";
import OloCodeDonatingSelect from "@/pages/Dashboard/ActiveCustomers/Migrations/OloCodeDonatingSelect";
import axios from "axios";
import Config from "@/globalConfig";
import VueJsonPretty from "vue-json-pretty";
import {Loading} from "@element-plus/icons-vue";

export default {
    components: {
        OloCodeDonatingSelect,
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
        Loading,
        VueJsonPretty
    },
    data() {
        return {
            componentSettings: {
                singleFieldSearch: true,
                searchTypeOption: 0,
                tableSort: {prop: 'id', order: 'descending'},
                myComponentName: 'Migrations',
                minimumPermissionLevel: 100,
                pagination: {
                    perPageOptions: [5, 10, 25, 50, 100]
                },
                searchFieldsDefault: ['internal_name'],
                typeOptions: []
            },
            currentState: {
                donatingFilter: null,
                currentSorting: {prop: 'id', order: 'descending'},
                searchFields: {
                    selected: ['internal_name'],
                    lastSelection: ['internal_name']
                },
                oloCodeFilter: null,
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    lastLoadedPage: 1,
                    total: 0,
                    from: 0,
                    to: 0,
                    showedItems: 0
                },
                statusFilter: null,
                searchQuery: '',
                searchTypeSelected: 0,
                tableMultipleSelect: null
            },
            statusList: [
                {key: 'pending', label: 'In Attesa'},
                {key: 'handled', label: 'Gestita'},
            ],
            searchTypeOptions: [
                {searchType: '0', label: 'Contiene'},
                {searchType: '2', label: 'Inizia per'},
                {searchType: '1', label: 'È uguale a'}
            ],
            tableColumns: [
                {
                    prop: 'id',
                    label: 'ID',
                    percentWidth: 8,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: true,
                    type: 'default',
                    first: true,
                    custom: 'id'
                },
                {
                    prop: 'arrived_at',
                    label: 'Orario di arrivo',
                    percentWidth: 8,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'arrived_at'
                },
                {
                    prop: 'codice_sessione',
                    label: 'Codice sessione',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'codice_sessione'
                },
                {
                    prop: 'recipient_order_code',
                    label: 'Codice ordine',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'codice_ordine'
                },
                {
                    prop: 'codice_motivo',
                    label: 'Esito',
                    percentWidth: 30,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'codice_motivo'
                },
                {
                    prop: 'olo_code_donating',
                    label: 'OLO CODE',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'olo_code_donating'
                },
                {
                    prop: 'status',
                    label: 'Stato',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'status'
                },
                {
                    prop: 'raw',
                    label: 'File RAW',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'raw'
                },
                {
                    label: 'Gestisci',
                    percentWidth: 13,
                    sortable: false,
                    showTooltip: false,
                    fixed: false,
                    type: 'default',
                    custom: 'azioni'
                }
            ],
            loading: false,
            tableData: [],
            canShowView: false,
            showRaw: false,
            migrationRaw: "",
            migrationSwalData: {
                title: 'Vuoi ignorare la seguente migrazione?',
                text:"",
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'No',
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            },

        }
    },
    methods: {

        //TODO: ROLES???
        canEdit(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['assurance/canEdit'](elementToCheck)
            }
        },

        // >>[Open the raw information for operator]<<
        async openContentFileMigration(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            let migration = this.tableData[_indx];
            this.migrationRaw = migration.raw;
            this.showRaw = true;
        },

        // >> [Questa funzione ritorna l'url per modificare l'ordine] <<
        resolveOrderLink(index,row){
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            let recipient_order_code = this.tableData[_indx].recipient_order_code;
            if(recipient_order_code != null){
                const indexOfSeparator = recipient_order_code.lastIndexOf("_");
                const last_code = recipient_order_code.slice(indexOfSeparator+1);
                console.log("/ordini/lista/modifica/"+last_code);
                return "/ordini/lista/modifica/"+last_code;
            }
            return "";
        },

        // >>[ It possible migrate to confirm state? ]<<
        canMigrationSetToHandledOrIgnoredState(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            return _indx >= 0 && (this.tableData[_indx].status === 'pending');
        },

        // >>[ Has it already been handled as a migration? ]<<
        isHandled(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            return _indx >= 0 && (this.tableData[_indx].status === 'handled' || this.tableData[_indx].status === 'ignored');
        },

        //Can R reset to "In Attesa"?
        isRevertAllowed(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
            return _indx >= 0 && this.tableData[_indx].status != 'pending'
        },

        //>> [This function revert R Migration to pending state] <<
        async revertRToPendingAgain(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
            let r_migration = this.tableData[_indx];

            this.migrationSwalData.title = "Vuoi ripristinare lo stato della migrazione?";
            this.migrationSwalData.text = "Confermando questa operazione, la migrazione verrà impostata nuovamente da lavorare. Nota bene: se la risposta è già stata inviata all'operatore, questa non verrà annullata. Ne verrà inviata una nuova se farai qualsiasi tipo di operazione.\n" +
                "Procedi solo se sei sicuro di ciò che stai per fare."
            await Swal.fire(this.migrationSwalData).then((result) => {
                if (result.isConfirmed) {
                    let post_params = {id_migration: this.tableData[_indx].id, new_state: 'pending'}
                    let params = Object.assign({}, this.$store.getters.apiCommon, post_params)
                    let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/response/change-state`;

                    axios.post(url, params)
                        .then(async (response) => {
                            console.log(response);
                            await Swal.fire('Operazione inversa completata', 'La migrazione è stata rimessa in uno stato di In Attesa', 'success');
                            this.updateData();
                        })
                        .catch((e) => {
                            Swal.fire('Operazione fallita', 'La migrazione non è stata processata. Riprova più tardi', 'error');
                            reject(e)
                        })
                }
            });

            return true;
        },

        async handleMigration(index, row, type) {
            let state = null;
            if (type === 'Ignora') {
                this.migrationSwalData.title = "Vuoi impostare la migrazione come ignorata?";
                this.migrationSwalData.text = "Nessuna risposta verrà inviata all'altro OLO. Si tratta di uno stato interno.";
                state = "ignored";
            } else if (type === 'Gestisci') {
                state = "handled";
                this.migrationSwalData.title = "Vuoi rendere gestita la migrazione?";
                this.migrationSwalData.text = "Nessuna risposta verrà inviata all'altro OLO. Si tratta di uno stato interno.";
            }

            await Swal.fire(this.migrationSwalData).then((result) => {
                if (result.isConfirmed) {
                    let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                    let post_params = {id_migration: this.tableData[_indx].id, new_state: state}
                    let params = Object.assign({}, this.$store.getters.apiCommon, post_params)
                    let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/response/change-state`;

                    axios.post(url, params)
                        .then(async (response) => {
                            console.log(response);
                            await Swal.fire('Migrazione processata!', 'La migrazione è stata processata correttamente', 'success');
                            this.updateData();
                        })
                        .catch((e) => {
                            Swal.fire('Migrazione non processata!', 'La migrazione non è stata processata. Riprova più tardi', 'error');
                            reject(e)
                        })
                }
            })
            return true;
        },

        handlePageChange(page) {
            if (page === this.currentState.pagination.lastLoadedPage) return
            this.updateData()
        },
        handlePerPageChange(perPage) {
            this.updateData({page: 1, limit: perPage})
        },
        handleSearchChange() {
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSearchFieldsChange(pIndex) {
            if (this.currentState.searchFields.selected.length < 1) {
                this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
                return
            }
            if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
                this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
            }
            this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSearchTypeChange(pIndex) {
            if (this.currentState.searchQuery === '') return
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },

        handleSelectionChange(pSelection) {
            this.currentState.tableMultipleSelect = pSelection
        },

        handleSortChange(pSortType) {
            let cSort = this.findColumnSort(pSortType)
            console.log(pSortType.order);
            console.log(this.currentState.currentSorting.order);

            if (pSortType.prop === null) {
                this.currentState.currentSorting = this.componentSettings.tableSort
            } else {
                if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
                    return;
                }
                this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
            }
            this.updateData();
        },

        handleStatusFilterChange(status) {
            // this.resetFilters(false)
            console.log(status);
            this.currentState.statusFilter = status
            this.updateData()
        },

        resetFilters(pReload = true) {
            this.currentState.searchQuery = ''
            this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
            this.currentState.tableMultipleSelect = null
            this.currentState.searchTypeSelected = 0
            this.currentState.pagination.perPage = 25
            this.currentState.pagination.currentPage = 1
            this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
            this.clearSavedState()
            if (pReload) this.updateData()
        },

        // >>[This function retrieve all list of migrations]<<
        updateData() {
            this.loading = true;
            let _orderDirection = "";
            if (this.currentState.currentSorting.order && this.currentState.currentSorting.order === 'ascending') {
                _orderDirection = "asc";
            } else if (this.currentState.currentSorting.order && this.currentState.currentSorting.order === 'descending') {
                _orderDirection = "desc";
            }

            let apiParams = {
                migration_status: this.currentState.statusFilter,
                limit: this.currentState.pagination.perPage,
                page: this.currentState.pagination.currentPage,
                orderBy: this.currentState.currentSorting.prop,
                orderDirection: _orderDirection
            }

            let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/response/list-paginated`;
            let params = Object.assign({}, this.$store.getters.apiCommon, apiParams);

            axios.post(url, params)
                .then(async (response) => {
                    console.log(response.data.data.data.length)
                    console.log(response.data.data.data);

                    this.loading = false;
                    this.tableData = response.data.data.data;

                    this.currentState.pagination.total = response.data.data.total;
                    this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
                    this.currentState.pagination.showedItems = response.data.data.data.length
                    this.currentState.pagination.from = response.data.data.data.length
                    this.currentState.pagination.to = response.data.data.to
                })
                .catch((e) => {
                    Swal.fire('Caricamento risposte fallito!', 'Le risposte non sono state caricate correttamente.', 'error');
                    reject(e);
                });
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) {
            return
        }
        this.canShowView = true
        console.log('created', this.currentState.statusFilter)
    },
    mounted: function () {
        console.log('mounted', this.currentState.statusFilter)
        this.updateData()
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style>
</style>
