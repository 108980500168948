import Config from '../../globalConfig'
import axios from 'axios'

const shippings = {
  namespaced: true,
  state: {
    province: [],
    couriers: []
  },
  getters: {
    province: state => state.province,
    couriers: state => state.couriers
  },
  mutations: {
    updateProvince: (state, pProvince) => {
      state.province = pProvince
    },
    updateCouriers: (state, pValue) => {
      state.couriers = pValue
    }
  },
  actions: {
    async checkCF ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/check-cf', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async checkOrderAddressWithTnt ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/shipping/tnt/check-address', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async editAddress ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.shippings.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getCouriers ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        if (getters.couriers.length > 0) {
          resolve(getters.couriers)
          return
        }
        let params = Object.assign({limit: -1}, rootGetters.apiCommon)
        axios.post(Config.endpoints.shippings.couriers, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateCouriers', response.data.couriers)
            resolve(response.data.couriers)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getOrderReceipt ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.base + pOrder.id + '/get-receipt', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getPickPoints ({commit, state, rootGetters}, pAddress) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({address: pAddress, radius: '20'}, rootGetters.apiCommon)
        axios.post(Config.endpoints.shippings.pickPoints.nearest, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async notifyIndabox ({commit, state, rootGetters}, pOrderId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, {order_id: pOrderId}, rootGetters.apiCommon)
        axios.post(Config.endpoints.shippings.pickPoints.indabox.notify, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async checkStatusIndabox ({commit, state, rootGetters}, pOrderId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, {order_id: pOrderId}, rootGetters.apiCommon)
        axios.post(Config.endpoints.shippings.pickPoints.indabox.check, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getProvince ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        if (getters.province.length > 0) {
          resolve(getters.province)
          return
        }
        let params = Object.assign({limit: -1}, rootGetters.apiCommon)
        axios.post(Config.endpoints.shippings.province, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateProvince', response.data.province)
            resolve(response.data.province)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTntLabel ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios({ url: Config.endpoints.orders.base + pOrder.id + '/shipping/tnt/label', method: 'post', responseType: 'arraybuffer', data: params })
          .then((response) => {
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async removeBrtShipping ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/shipping/brt/remove', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async removeOrderReceipt ({commit, state, rootGetters}, pOrderID) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.base + pOrderID + '/remove-receipt', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async removeTntShipping ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/shipping/tnt/remove', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async removeShipping ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/shipping/remove', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    reset ({commit}) {
      commit('updateProvince', [])
      commit('updateCouriers', [])
    },
    async shipWithBrt ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/shipping/brt/ship', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async shipWithTnt ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/shipping/tnt/ship', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default shippings
