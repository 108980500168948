<template>
    <div id="order-step-customer">
        <div class="row mt-2">
            <div class="col-12 text-center">
                <h4 class="m-0">Indirizzo di attivazione</h4>
            </div>
            <div class="col-12 mt-4">
                <el-form ref="form" v-model="order.activation_address" label-width="180px">
                    <el-form-item label="Indirizzo">
                        <el-input ref="addressInput" v-model="order.activation_address.address"
                                  @input="updateValue"></el-input>
                    </el-form-item>
                    <el-form-item label="Numero civico">
                        <el-input ref="addressNumberInput" v-model="order.activation_address.address_number"
                                  @input="updateValue"></el-input>
                    </el-form-item>
                    <el-form-item label="Città">
                        <el-input ref="cityInput" v-model="order.activation_address.city"
                                  @input="updateValue"></el-input>
                    </el-form-item>
                    <el-form-item label="Provincia">
                        <province-select ref="provinceInput" v-model="order.activation_address.province"
                                         @input="updateValue"></province-select>
                    </el-form-item>
                    <el-form-item label="CAP">
                        <el-input ref="postalCodeInput" v-model="order.activation_address.postal_code"
                                  @input="updateValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="saveAndBack()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="saveAndNext()">Avanti<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";
import IdentityDocumentSelect from "@/components/Inputs/IdentityDocumentTypeSelect";
import IssuerIdentityDocumentSelect from "@/components/Inputs/IssuerIdentityDocumentSelect";

export default {
    name: "step-activation-address",
    components: {
        IssuerIdentityDocumentSelect,
        IdentityDocumentSelect,
        GiuridicFormSelect,
        ProvinceSelect
    },
    props: {
        modelValue: {
            type: Object
        },
        customer: {
            type: Object
        },
        orderId: {
            type: Number
        }
    },
    data() {
        return {
            order: this.modelValue
        }
    },
    methods: {
        updateValue() {
            this.$emit('update:modelValue', this.order)
        },
        saveAndNext() {
            if (this.order.activation_address.id) {
                this.updateActivationAddress();
            } else {
                this.createActivationAddress();
            }
            this.$emit('next')
        },
        saveAndBack() {
            if (this.order.id) {
                this.updateActivationAddress();
            } else {
                this.createActivationAddress();
            }
            this.$emit('back')
        },
        createActivationAddress() {
            this.loading = true
            this.$store.dispatch('activationAddress/add', this.order.activation_address).then((response) => {
                this.loading = false
                this.$emit('update:modelValue', this.order)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo cliente')
            })
        },
        updateActivationAddress() {
            this.loading = true
            this.$store.dispatch('activationAddress/edit', this.order.activation_address).then((response) => {
                this.loading = false
                this.$emit('update:modelValue', this.order)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo cliente')
            })
        }
    }
}
</script>

<style scoped>

</style>
