<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model="selected"
                   placeholder="Seleziona la provincia"
                   @change="updateValue($event)"
                   filterable>
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.name"
                :value=item.sigla>
            </el-option>
            <el-option
                class="select-default"
                key='EE'
                label="Estero"
                value='EE'>
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "province-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            selected: this.modelValue
        }
    },
    props: {
        modelValue: {
            type: String
        },
    },
    watch: {
        modelValue(val) {
            this.selected = val
        }
    },
    created() {
        this.selected = this.modelValue
        this.loadItems()
    },
    methods: {
        loadItems() {
            this.loading = true
            this.$store.dispatch('shippings/getProvince').then((response) => {
                this.items = response
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle province')
            }).finally(() => {
                this.loading = false
            })
        },
        updateValue: function (item) {
            this.$emit('update:modelValue', item)
        }
    }
}
</script>

<style scoped>

</style>
