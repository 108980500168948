<template>
    <div style="display: flex; flex-direction: column">
        <label>Ricerca in RAW</label>
        <el-input
            v-model="currentValue"
            class="w-50 m-2"
            size="large"
            placeholder="Please Input"
            :prefix-icon="Search"
        />
    </div>
</template>

<script>
import {Search} from "@element-plus/icons-vue";

export default {
    name: "OloRawInput",
    props: ['rawFilter'],
    data() {
        return {
            currentValue: this.rawFilter
        }
    },
    methods: {
        Search,
        handleChange(newValue) {
            // -> [For change the new value of props] <-
            this.$emit('update:modelValue', newValue)
        }
    }
}
</script>

<style scoped>

</style>
