<template>
  <div>
    <h4>Contratto</h4>

    <div class="row mb-3 mt-4">
      <div class="col-12">
        <p>Il cliente non ha ancora firmato il contratto</p>
        <label><a :href="this.getContractUrl()" target="_blank">APRI LINK CONTRATTO</a> - <strong style="text-transform: initial; cursor: pointer" class="a-link btn-link" @click="copyContractLink()">Copia link contratto <i class="el-icon-edit ml-1"></i></strong></label>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  mixins: [],
  components: {
  },
  props: {
    order: {}
  },
  data () {
    return {
      loading: false,
      padOptions: {
        backgroundColor: 'transparent',
        penColor: '#000000'
      }
    }
  },
  watch: {
    order () {
    }
  },
  computed: {},
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      let signatureData = this.$refs.signaturePad.toData();
      let signatureImage = this.$refs.signaturePad.saveSignature()

      this.loading = true
      this.$store.dispatch('orders/saveSignature', {orderId: this.order.id, signature_data: signatureData, signature_image: signatureImage.data}).then((response) => {
        this.loading = false
        console.log(response)
      }).catch(e => {
        this.loading = false
      })
    },
    copyContractLink () {
      this.$copyText(this.getContractUrl()).then((e) => {
        this.showGenericSuccess('Link', 'copiato negli appunti', '1000')
      }).catch(e => {
        this.showGenericError('Errore!', 'Errore durante la copia negli appunti, verifica il tuo browser.')
      })
    },
    getContractUrl() {
      var getUrl = window.location
      return  getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1] + '#/contratto?hash=' + this.order.hash;
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  destroyed: function () {
    this.$off()
  }
}

</script>
<style></style>
