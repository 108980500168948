<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Lista ricariche">

        <el-dialog title="Modifica ricarica" v-model="dialogEditRecharge" class="dialog-recharge">
          <div class="row">
            <div class="col-12 mb-3">ID: {{currentRecharge.id}}</div>
            <div class="col-12 col-md-6">
              <label>Importo</label>
              <br/>
              <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="currentRecharge.amount" placeholder="..." size="large">
                <el-option
                  v-for="item in rechargeAmount"
                  class="select-default"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key">
                </el-option>
              </el-select>
            </div>
            <div class="col-12 col-md-6">
              <label>Stato</label>
              <el-select value-key="key" style="width: 100%" class="select-default mb-3" v-model="currentRecharge.status" placeholder="Seleziona..." size="large">
                <el-option
                  v-for="item in recharges_statuses"
                  class="select-default"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key">
                </el-option>
              </el-select>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-fill btn-wd" @click="updateSingleRecharge()">
            Salva
          </button>

        </el-dialog>

        <div class="table-layout-container">

          <div class="row mb-0 mt-2" v-if="currentRechargesForState">

            <div class="col-12 col-md-12 col-lg-6">
              <div class="row">
                <div class="col-12 mb-2" v-if="currentRechargesForState.length === 0">
                  <p>Non ci sono ricariche per lo stato <i style="font-weight: bold;">{{currentState.currentStatusLabel}}</i>.</p>
                </div>

                <div class="col-12" v-if="currentRechargesForState.length > 0">
                  <p style="font-weight: bold" class="mb-2">Lo stato <i>{{currentState.currentStatusLabel}}</i> contiene:</p>
                  <ul>
                    <li v-if="item.count !== 1" v-for="item in currentRechargesForState"><strong>{{item.count}}</strong> ricariche da <strong>{{item.amount}}</strong>€</li>
                    <li v-if="item.count === 1" v-for="item in currentRechargesForState"><strong>{{item.count}}</strong> ricarica da <strong>{{item.amount}}</strong>€</li>
                  </ul>
                </div>
                <div class="col-12 mb-2" v-if="available_recharges">
                  <p style="font-weight: bold" class="mb-2" v-if="available_recharges.length > 0">Nel sistema sono disponibili:</p>
                  <p style="font-weight: bold" class="mb-2" v-else>Nel sistema non ci sono ricariche disponibili al momento.</p>
                  <ul>
                    <li v-if="item.count !== 1" v-for="item in available_recharges" style="text-transform: inherit"><strong>{{item.amount}}€</strong>: <strong>{{item.count}}</strong> ricariche</li>
                    <li v-if="item.count === 1" v-for="item in available_recharges" style="text-transform: inherit"><strong>{{item.amount}}€</strong>: <strong>{{item.count}}</strong> ricarica</li>
                  </ul>
                </div>
              </div>
            </div>


            <div class="col-12 col-md-12 col-lg-6 mb-4">
              <el-upload
                ref="csvUpload"
                :on-success="handleCsvSuccess"
                :on-error="handleCsvError"
                :before-upload="handleCsvBefore"
                :action="csvImportAction"
                :show-file-list="false"
                accept="application/csv"
                name="file"
                :limit=1
                :data="csvImportData"
                :auto-upload="true"
                class="float-right ml-3">
                <button class="btn btn-default btn-outline col-12" style="padding: 9px 16px;" slot="trigger" :disabled="!csvImportAmount">
                      <span class="btn-label">
                         <i class="fa fa-plus"></i> Importa CSV
                      </span>
                </button>
              </el-upload>
              <el-select value-key="key" style="width: 100%; max-width: 160px; float: right;" class="select-default mb-3" v-model="csvImportAmount" placeholder="Importo ricariche" size="large">
                <el-option
                  v-for="item in rechargeAmount"
                  class="select-default"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key">
                </el-option>
              </el-select>
            </div>
          </div>


          <div class="row mt-3">
            <div class="mb-3 col-12 col-md-3">
              <el-select style="width: 100%; max-width: 110px;" v-model="currentState.pagination.perPage" placeholder="Per pagina" @change="handlePerPageChange">
                <el-option v-for="item in componentSettings.pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div class="col-12 col-md-9">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage" :per-page="currentState.pagination.perPage" :total="currentState.pagination.total" @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row mt-3">

            <div class="col-sm-4 col-12 mb-3" style="max-width: 180px;">
              <el-select multiple style="width: 100%;" v-model="currentState.searchFields.selected" placeholder="Dove cerco?" @change="handleSearchFieldsChange">
                <el-option v-for="(option, index) in searchFields" :value="option.searchFields" :label="option.label" :key="index">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-3 col-12 mb-3" style="max-width: 180px;">
              <el-select style="width: 100%" v-model="currentState.searchTypeSelected" placeholder="Come cercare?" @change="handleSearchTypeChange">
                <el-option v-for="(item, index) in searchTypeOptions" :key="index" :label="item.label" :value="index"></el-option>
              </el-select>
            </div>

            <div class="col-12 col-sm-3">
              <el-input style="width: 100%" type="search" placeholder="Cerca" v-model="currentState.searchQuery" @change="handleSearchChange" addon-right-icon="fa fa-search" />
            </div>

            <div class="col-12 col-sm-4 col-md-3" style="max-width: 230px;">
              <el-select value-key="key" style="width: 100%" class="select-default mb-3" v-model="currentState.currentStatus" placeholder="Seleziona..." @change="handleStatusChange" size="large">
                <el-option
                  v-for="item in recharges_statuses"
                  class="select-default"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-2 col-12 pr-2">
              <button class="btn btn-outline btn-danger btn-outline col-12" style="padding: 9px 0px; width: 100%; margin-top: 0px;" @click="resetFilters()" data-tooltip="'Reset dei filtri'">
                    <span class="btn-label">
                       <i class="fa fa-times"></i>
                    </span>
              </button>
            </div>

          </div>

          <h4 style="font-weight: 400 !important; text-align: center">Stai visualizzando le ricariche:
            <strong>{{currentState.currentStatusLabel}}</strong>

            <a href="#" data-tooltip="'Ricarica lista ricariche'" @click.prevent="updateAllRecharges()" class="btn-simple btn-link a-link">
              <i class="el-icon-refresh" style="font-size: 19px;"></i>
            </a>
          </h4>


          <div class="row">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped" :data="tableData" border style="width: 100%" :default-sort="currentState.currentSorting" @sort-change="handleSortChange" @selection-change="handleSelectionChange">

                <template v-for="column in tableColumns">
                  <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                  <el-table-column v-else-if="column.type === 'selection'" type="selection" :width="calculateTableWidth(column)"></el-table-column>

                  <el-table-column v-else-if="column.custom === 'id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <span>{{props.row.id}}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'order_id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <router-link class="dark-link" :to="{path:'/ordini/lista/modifica/' + props.row.order_id}">
                          <strong>{{props.row.order_id}}</strong>
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'amount'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <span>{{props.row.amount}}€</span><br />
                        <span>{{props.row.phone_number}}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'result'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <div v-if="props.row.status_details"><strong>{{props.row.status_details.name}}</strong></div>
                        <div><i>{{props.row.result}}</i></div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'status'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row && props.row.status === 0">
                        <div class="esitoRicarica"><div></div><strong>Da eseguire</strong></div>
                      </div>
                      <div v-if="props.row && props.row.status === 1">
                        <div class="esitoRicarica "><div></div><strong>In corso di esecuzione</strong></div>
                      </div>
                      <div v-if="props.row && props.row.status === 2">
                        <div class="esitoRicarica"><div></div><strong>Eseguita</strong></div>
                      </div>
                      <div v-if="props.row && props.row.status === 3">
                        <div class="esitoRicarica"><div></div><strong>Fallita</strong></div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'created_at'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <span v-if="props.row">{{dateToItalian(props.row.created_at, false)}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'updated_at'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <span v-if="props.row" class="n-lightgrey">{{dateToItalian(props.row.updated_at, false)}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link" @click.prevent="dialogRecharge(props.row)" v-if="canEdit(props.$index, props.row)"><i class="fa fa-edit"></i></a>

                        <i class="fa fa-clone" v-if="!canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>

                        <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                           @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>

                        <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>
                      </div>
                    </template>
                  </el-table-column>

                </template>

              </el-table>

            </div>
          </div>


          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da <strong>{{currentState.pagination.from}}</strong> a <strong>{{currentState.pagination.to}}</strong> su <strong>{{currentState.pagination.total}}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
    },
    computed: {
      csvImportData: function () {
        var params = Object.assign({}, {amount: this.csvImportAmount}, this.$store.getters.apiCommon)
        return params
      },
      csvImportAction: function () {
        return this.$config.endpoints.recharges.base + 'upload'
      }
    },
    data () {
      return {
        componentSettings: {
          searchTypeOption: 0,
          singleFieldSearch: true,
          tableSort: {prop: 'id', order: 'descending'},
          myComponentName: 'RechargesList',
          minimumPermissionLevel: 200,
          pagination: {
            perPageOptions: [5, 10, 25, 50, 100]
          },
          searchFieldsDefault: ['id']
        },
        currentState: {
          currentSorting: {prop: 'id', order: 'descending'},
          currentStatus: 0,
          currentStatusLabel: 'Da eseguire',
          searchFields: {
            selected: ['id'],
            lastSelection: ['id']
          },
          pagination: {
            perPage: 25,
            currentPage: 1,
            lastLoadedPage: 1,
            total: 0,
            from: 0,
            to: 0,
            showedItems: 0
          },
          searchQuery: '',
          searchTypeSelected: 0,
          tableMultipleSelect: null
        },
        searchFields: [
          {searchFields: 'id', label: 'ID'},
          {searchFields: 'order_id', label: 'ID Ordine'}
        ],
        searchTypeOptions: [
          {searchType: '0', label: 'Contiene'},
          {searchType: '2', label: 'Inizia per'},
          {searchType: '1', label: 'È uguale a'}
        ],
        tableColumns: [
          {
            prop: 'id',
            label: 'ID',
            percentWidth: 10,
            sortable: 'custom',
            showTooltip: true,
            fixed: true,
            type: 'default',
            first: true,
            custom: 'id'
          },
          {
            prop: 'order_id',
            label: 'ID Ordine',
            percentWidth: 12,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'order_id'
          },
          {
            prop: 'amount',
            label: 'Importo',
            percentWidth: 16,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'amount'
          },
          {
            prop: 'created_at',
            label: 'Inserita in data',
            percentWidth: 17,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'created_at'
          },
          {
            prop: 'updated_at',
            label: 'Ultima modifica',
            percentWidth: 17,
            sortable: 'custom',
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'updated_at'
          },
          {
            prop: 'result',
            label: 'Stato',
            percentWidth: 17,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'result'
          },
          {
            label: 'Azioni',
            percentWidth: 11,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'azioni'
          }
        ],
        loading: false,
        tableData: [],
        defaultValue: null,
        canShowView: false,
        recharges: [],
        recharges_statuses: [
          {key: 0, label: 'Da eseguire'},
          {key: 1, label: 'In corso di esecuzione'},
          {key: 2, label: 'Eseguita'},
          {key: 3, label: 'Fallita'}
        ],
        dialogEditRecharge: false,
        currentRecharge: {},
        rechargeAmount: [
          {key: 10, label: '10€'},
          {key: 20, label: '20€'}
        ],
        csvImportAmount: null,
        recharges_count: null,
        available_recharges: null,
        currentRechargesForState: []
      }
    },
    methods: {
      getSaveStateConfig () {
        return {
          'cacheKey': this.componentSettings.myComponentName,
          'saveProperties': ['currentState']
        }
      },
      canEdit (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['recharges/canEdit'](elementToCheck)
        }
      },
      canDelete (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['recharges/canDelete'](elementToCheck)
        }
      },
      dialogRecharge (pRecharge) {
        this.dialogEditRecharge = true
        this.currentRecharge = pRecharge
      },
      getAvailableRecharges () {
        this.loading = true
        this.$store.dispatch('recharges/getAvailableRecharges').then((response) => {
          this.available_recharges = response.data.recharge_count
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle ricariche disponibili', '', false)
        })
      },
      getRechargesCount () {
        this.loading = true
        this.$store.dispatch('recharges/getRechargesCount').then((response) => {
          this.recharges_count = response.data.recharge_count.count
          this.loading = false
          this.getRechargesFromStatus()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento del conteggio ricariche', '', false)
        })
      },
      getRechargesFromStatus () {
        if (this.recharges_count) {
          for (let index = 0; index < Object.keys(this.recharges_count).length; index++) {
            if (this.recharges_count[this.currentState.currentStatus]) {
              this.currentRechargesForState = this.recharges_count[this.currentState.currentStatus]
            } else {
              this.currentRechargesForState = []
            }
          }
        }
      },
      handleCsvBefore () {
        return new Promise((resolve, reject) => {
          this.askGenericConfirm('Lo vuoi davvero?', '<strong>Se carichi il file più volte avrai ricariche DUPLICATE nel sistema che porteranno ad ERRORI durante l\'esecuzione automatica.</strong><br /><br />Assicurati di aver caricato il file solo UNA VOLTA!<br /><br />Non è possibile individuare i duplicati poiché tutte le ricariche nel sistema sono criptate.<br /><br />Premendo Sì, autorizzerai il caricamento del file CSV contenente le ricariche.').then((result) => {
            if (!result.value) {
              reject(false)
              return false
            }
            if (!this.csvImportAmount) {
              this.showGenericError('Errore', 'È necessario selezionare l\'importo delle ricariche da caricare.')
              reject(false)
              return false
            }
            resolve(true)
            this.loading = true
            return true
          })
        })
      },
      handleCsvError (err, file, fileList) {
        this.loading = false
        this.$refs.csvUpload.clearFiles()
        this.showLoadingErrorWithoutRetry(err, 'il caricamento del file')
      },
      handleCsvSuccess (response, file, fileList) {
        this.$refs.csvUpload.clearFiles()
        this.loading = false
        if (!response.result) {
          this.showLoadingErrorWithoutRetry(response)
        } else {
          this.askGenericSuccess('Fatto!', 'Le ricariche sono state caricate correttamente nel sistema.').then(() => {
            this.updateData()
            this.getAvailableRecharges()
          })
        }
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          let elementToDelete = this.tableData[indexToDelete]
          this.askDeleteMessage(' la ricarica ' + elementToDelete.id).then((result) => {
            if (!result.value) return
            this.$store.dispatch('recharges/delete', elementToDelete).then(() => {
              this.showGenericSuccess('Ricarica', 'eliminata con successo').then(() => {
                if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                  this.currentState.pagination.currentPage--
                }
                this.updateData()
              })
            })
              .catch(e => {
                this.loading = false
                this.showLoadingError(e)
              })
          })
        }
      },
      handlePageChange (page) {
        if (page === this.currentState.pagination.lastLoadedPage) return
        this.updateData()
      },
      handlePerPageChange (perPage) {
        this.updateData({page: 1, limit: perPage})
      },
      handleSearchChange () {
        this.currentState.pagination.currentPage = 1
        this.updateData()
      },
      handleSearchFieldsChange (pIndex) {
        if (this.currentState.searchFields.selected.length < 1) {
          this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
          return
        }
        if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
          this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
        }
        this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
        this.currentState.pagination.currentPage = 1
        this.updateData()
      },
      handleSearchTypeChange (pIndex) {
        if (this.currentState.searchQuery === '') return
        this.currentState.pagination.currentPage = 1
        this.updateData()
      },
      handleSelectionChange (pSelection) {
        this.currentState.tableMultipleSelect = pSelection
      },
      handleSortChange (pSortType) {
        let cSort = this.findColumnSort(pSortType)
        if (pSortType.prop === null) {
          this.currentState.currentSorting = this.componentSettings.tableSort
        } else {
          if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
            return
          }
          this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
        }
        this.updateData()
      },
      handleStatusChange (pStatus) {
        let foundRechargeStatus = null
        for (let i = 0; i < this.recharges_statuses.length; ++i) {
          if (this.recharges_statuses[i].key !== pStatus) continue
          foundRechargeStatus = this.recharges_statuses[i]
          this.currentState.currentStatusLabel = foundRechargeStatus.label
          break
        }
        this.getRechargesFromStatus()
        this.updateData()
      },
      labelValue (pCount) {
        if (pCount !== null) {
          return ' (' + pCount + ')'
        } return ''
      },
      resetFilters (pReload = true) {
        this.currentState.searchQuery = ''
        this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
        this.currentState.tableMultipleSelect = null
        this.currentState.currentStatus = 0
        this.currentState.currentStatusLabel = 'Da eseguire'
        this.currentState.searchTypeSelected = 0
        this.currentState.pagination.perPage = 25
        this.currentState.pagination.currentPage = 1
        this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
        this.recharges_count = null
        this.getRechargesCount()
        this.clearSavedState()
        if (pReload) this.updateData()
      },
      removeUser (pUser) {
        return this.axios.post(this.$config.endpoints.users.base + pUser.id + '/delete', this.$store.getters.apiCommon)
      },
      updateAllRecharges () {
        this.updateData()
        this.getAvailableRecharges()
        this.getRechargesCount()
      },
      updateData (params) {
        this.loading = true
        if (!params) params = {}
        params = Object.assign(params, {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage})
        if (this.currentState.searchQuery !== '') {
          params = Object.assign(params, {search: this.currentState.searchQuery, search_fields: this.currentState.searchFields.selected, search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType})
        }
        if (this.currentState.currentSorting !== null) {
          params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
        }

        if (!(this.currentState.searchFields.selected[0] === 'id' && this.currentState.searchQuery) && this.currentState.currentStatus >= 0) {
          params = Object.assign(params, {status: this.currentState.currentStatus})
        }
        this.$store.dispatch('recharges/list', params).then((response) => {
          this.loading = false
          this.tableData = response.data.recharges.data
          this.currentState.pagination.total = response.data.recharges.total
          this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
          this.currentState.pagination.showedItems = response.data.count
          this.currentState.pagination.from = response.data.recharges.from
          this.currentState.pagination.to = response.data.recharges.to
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle ricariche')
        })
      },
      updateSingleRecharge () {
        this.loading = true
        this.$store.dispatch('recharges/edit', this.currentRecharge).then((response) => {
          this.loading = false
          this.showGenericSuccess('Ricarica', 'modificata con successo')
          this.dialogEditRecharge = false
          this.currentState.currentStatus = this.currentRecharge.status
          this.handleStatusChange(this.currentRecharge.status)
          this.updateData()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questa ricarica', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateAllRecharges()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>

<style>
  .esitoRicarica div {
    width: 13px; height: 13px;
    border-radius: 50%; float: left; margin-top: 5px; margin-right: 8px;
  }
  .esitoRicarica.eseguita div {
    background-color: #4F96E4;
  }
  .esitoRicarica.eseguita strong {
    color: #4F96E4;
  }
  .esitoRicarica.conEccezioni div {
    background-color: #FFAF42;
  }
  .esitoRicarica.conEccezioni strong {
    color: #FFAF42;
  }
  .esitoRicarica.daEseguire div {
    background-color: #E74155;
  }
  .esitoRicarica.daEseguire strong {
    color: #E74155;
  }
  .dialog-recharge .el-dialog__header {
    padding-bottom: 0;
  }
  .dialog-recharge .el-dialog__body {
    padding-top: 10px;
  }
</style>
