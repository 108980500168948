<template>
    <div v-loading="loading">
        <ul v-if="timeline_items && timeline_items.length > 0" class="pl-0 list-unstyled">
            <li v-for="(item, key) in timeline_items" class="my-1">
                <order-timeline-item v-model="timeline_items[key]" @deleted="fetchTimeline"/>
            </li>
        </ul>
        <h5 class="n-thin mt-5" v-else>Non è ancora successo nulla.</h5>
    </div>
</template>

<script>
import OrderNote from "@/pages/Dashboard/Orders/Components/OrderNote";
import OrderTimelineItem from "@/pages/Dashboard/Orders/Components/OrderTimelineItem";

export default {
    name: "OrderTimeline",
    components: {OrderTimelineItem, OrderNote},
    props: {
        orderId: {
            required: true
        }
    },
    watch: {
        'orderId': function (val) {
            if (!val) {
                return
            }
            this.fetchTimeline()
        }
    },
    data: function () {
        return {
            loading: true,
            timeline_items: null
        }
    },
    methods: {
        fetchTimeline(order = null) {

            if (!order || isNaN(order)) {
                order = this.orderId;
            }
            this.loading = true
            this.$store.dispatch('orders/fetchTimeline', order).then((response) => {
                this.timeline_items = response.data.data
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
