<template>
    <div class="row">
        <div class="col-12">
            <el-card>
                <h4 class="my-2">Aggiungi partner</h4>
                <partner-form v-loading="loading" v-model="partner"/>
            </el-card>
        </div>
    </div>
</template>
<script>
import PartnerForm from "@/pages/Dashboard/Partner/PartnerForm";

export default {
    name: 'AddPartner',
    components: {
        PartnerForm
    },
    computed: {},
    watch: {
        '$route.params.id': function () {
        }
    },
    data() {
        return {
            loading: false,
            partner: {}
        }
    },
    methods: {},
    created: function () {
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
