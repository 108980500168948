<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">

            <el-dialog
                v-model="this.showRaw"
                width="90%">
                <div>
                    <h4 class="mt-0">Contenuto</h4>
                </div>
                <pre>{{ this.migrationRaw }}</pre>
            </el-dialog>

            <el-card title="Gestione Migrazioni Donating">
                <p>Qui trovi tutte le richieste di migrazione inviate dagli altri operatori verso Dimensione, pertanto
                    dove Dimensione è il donating, e i clienti stanno richiedendo di andare via da Dimensione.</p>
                <div class="table-layout-container">

                    <div class="d-flex justify-content-between flex-wrap">

                        <div style="margin-top: 5px" class=".col-">
                            <label>Filtra per status</label>
                            <el-select class="select-default" style="width: 100%; max-width: 300px;"
                                       v-model="currentState.statusFilter"
                                       placeholder="Filtra per stato" @change="handleStatusFilterChange">
                                <el-option class="select-default" v-for="status in statusList" :key=status.key
                                           :label="status.label"
                                           :value=status.key></el-option>
                                <el-option class="select-default" :key=null label="Tipo: Tutti" :value=null></el-option>
                            </el-select>
                        </div>

                        <div style="margin-top: 5px" class=".col-">
                            <olo-original-cow-select
                                class="w-100"
                                @change="updateData"
                                :modelValue="currentState.cowFilter"
                                @update:modelValue="currentState.cowFilter = $event"
                            />
                        </div>

                        <div style="margin-top: 5px" class=".col- d-flex flex-column justify-content-between">
                            <label>Elementi per pagina</label>
                            <el-select class="select-default" v-model="currentState.pagination.perPage"
                                       placeholder="Per pagina"
                                       @change="handlePerPageChange">
                                <el-option class="select-default"
                                           v-for="item in componentSettings.pagination.perPageOptions"
                                           :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-2">
                                <l-pagination class="pagination-no-border float-right"
                                              v-model="currentState.pagination.currentPage"
                                              :per-page="currentState.pagination.perPage"
                                              :total="currentState.pagination.total"
                                              @input="handlePageChange"></l-pagination>
                            </div>
                        </div>
                    </div>
                    <el-button @click="updateData">
                        <el-icon>
                            <RefreshRight/>
                        </el-icon>
                    </el-button>
                    <div class="row">
                        <div class="col-12" v-loading="loading">
                            <el-table class="table-striped" :data="tableData" border style="width: 100%"
                                      :default-sort="currentState.currentSorting" @sort-change="handleSortChange"
                                      @selection-change="handleSelectionChange">


                                <template v-for="column in tableColumns">
                                    <el-table-column v-if="!column.custom && column.type !== 'selection'"
                                                     :type="column.type"
                                                     :fixed="column.fixed" :key="column.label"
                                                     :width="calculateTableWidth(column)"
                                                     :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label"></el-table-column>

                                    <el-table-column v-else-if="column.type === 'selection'"
                                                     type="selection"></el-table-column>

                                    <el-table-column v-else-if="column.custom === 'id'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="100">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <span v-if="props.row.id">{{ props.row.id }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>


                                    <el-table-column v-else-if="column.custom === 'arrived_at'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                        <span class="td-strong">
                            <strong>{{ dateToItalian(props.row.arrived_at, false, "DD/MM/YYYY HH:mm") }}</strong>
                        </span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'raw'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                        <span class="td-strong">
                            <a
                                v-if="props && props.row && props.row.raw && props.row.raw"
                                v-bind:href="generateCorLink(props.$index, props.row)"
                                target="_blank"
                                style="cursor: pointer; color: #3f7da4; font-weight: bolder">{{
                                    showCor(props.$index, props.row)
                                }}</a>
                        </span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'raw_operatore'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row && props.row.operator && props.row.raw">
                                                <strong>
                                                    {{
                                                        showCodiceOperatoreRecipient(props.$index, props.row)
                                                    }}
                                                </strong>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'status'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="d-flex flex-column">
                                                    <span v-if="props.row.status_formatted === 'Accettato'"
                                                          style="color:green">{{ props.row.status_formatted }}</span>
                                                    <span v-if="props.row.status_formatted === 'Accettato'"
                                                          style="color:green">{{ props.row.codice_sessione != null ? props.row.codice_sessione : "Codice non specificato" }}</span>

                                                    <span v-if="props.row.status_formatted === 'Ignorato'"
                                                          style="color:orange">{{ props.row.status_formatted }}</span>
                                                    <span v-if="props.row.status_formatted === 'In Attesa'"
                                                          style="color:grey">{{ props.row.status_formatted }}</span>
                                                    <span v-if="props.row.status_formatted === 'Rifiutato'"
                                                          style="color:red">{{ props.row.status_formatted }}</span>
                                                    <span v-if="props.row.status_formatted === 'Rifiutato'"
                                                          style="color:red;font-size:10px ">{{
                                                            props.row.motivo_rifiuto || "Motivo non specificato"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'file'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label"
                                                     :width="100"
                                    >
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="text-center">
                                                    <a v-b-tooltip.hover title="Apri il file per vedere il contenuto"
                                                       class="btn-warning btn-simple btn-link mb-3"
                                                       style="color: black"
                                                       @click="openContentFileMigration(props.$index, props.row)">
                                                        <i class="fa fa-file-code-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'azioni'" :width="280"
                                                     :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">

                                            <div
                                                style="text-align: center; display:flex; flex-direction: row; justify-content: space-between"
                                                class="table-actions" v-if="props.row">

                                                <el-button

                                                    @click="handleMigration(props.$index, props.row, 'Accept')"
                                                    v-if="canEdit(props.$index, props.row) && canMigrationSetToState(props.$index, props.row, 'confirmed')"
                                                    type="success">Accetta
                                                </el-button>

                                                <el-button @click="handleMigration(props.$index, props.row, 'Ignore')"
                                                           v-if="canEdit(props.$index, props.row) && canMigrationSetToState(props.$index, props.row, 'ignored')"
                                                           type="info">Ignora
                                                </el-button>

                                                <el-button @click="handleMigration(props.$index, props.row, 'Reject')"
                                                           v-if="canEdit(props.$index, props.row) && canMigrationSetToState(props.$index, props.row, 'rejected')"
                                                           type="danger">Respingi
                                                </el-button>

                                            </div>

                                            <span style="white-space: pre-line;"
                                                  v-if="isMigrationAlreadyHandled(props.$index, props.row)">{{
                                                    "Già gestita\n" + dateToItalian(props.row.updated_at, false, "DD/MM/YYYY HH:mm")
                                                }}</span>

                                        </template>
                                    </el-table-column>
                                </template>

                            </el-table>
                        </div>
                    </div>

                    <el-dialog title="Determina il motivo della migrazione" v-model="this.isRejectModalOpen"
                               v-if="isRejectModalOpen"
                               class="dialog-options">
                        <p>Seleziona il motivo del rifiuto</p>
                        <el-select
                            v-loading="isRejectReasonLoading" @change="handleChangeRejectReason"
                            v-model="this.choosedReason" class="w-50 mb-2"
                            placeholder="Tipo rifiuto" size="large"
                        >
                            <el-option
                                v-for="item in rejectedReason"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key"
                            />
                        </el-select>

                        <div slot="footer" class="dialog-footer">
                            <button @click="isRejectModalOpen=false; choosedReason=null"
                                    class="btn btn-default btn-outline btn-fill">
                                Annulla l'operazione
                            </button>
                            <button @click="rejectMigrationWithReason" :disabled="!choosedReason" type="submit"
                                    class=" ml-2 btn btn-primary btn-fill">
                                Conferma motivo e procedi
                            </button>
                        </div>
                    </el-dialog>

                    <div slot="footer"
                         class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
                        <div class="">
                            <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da
                                <strong>{{ currentState.pagination.from }}</strong> a
                                <strong>{{ currentState.pagination.to }}</strong>
                                su <strong>{{ currentState.pagination.total }}</strong> totali</p>
                        </div>
                        <l-pagination class="pagination-no-border"
                                      v-model="currentState.pagination.currentPage"
                                      :per-page="currentState.pagination.perPage"
                                      :total="currentState.pagination.total"
                                      @input="handlePageChange">
                        </l-pagination>

                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'

import Swal from "sweetalert2";
import OloCodeDonatingSelect from "@/pages/Dashboard/ActiveCustomers/Migrations/OloCodeDonatingSelect";
import axios from "axios";
import Config from "@/globalConfig";
import VueJsonPretty from "vue-json-pretty";
import {Edit, Loading} from "@element-plus/icons-vue";
import OloOriginalCowSelect from "@/pages/Dashboard/ActiveCustomers/Migrations/OloOriginalCowSelect.vue";
import OloRawInput from "@/pages/Dashboard/ActiveCustomers/Migrations/OloRawInput.vue";

export default {
    components: {
        OloRawInput,
        OloOriginalCowSelect,
        OloCodeDonatingSelect,
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
        Loading,
        VueJsonPretty,
    },
    data() {
        return {
            componentSettings: {
                singleFieldSearch: true,
                searchTypeOption: 0,
                tableSort: {prop: 'id', order: 'descending'},
                myComponentName: 'Migrations',
                minimumPermissionLevel: 100,
                pagination: {
                    perPageOptions: [5, 10, 25, 50, 100]
                },
                searchFieldsDefault: ['internal_name'],
                typeOptions: []
            },
            currentState: {
                donatingFilter: null,
                cowFilter: null,
                rawFilter: null,
                currentSorting: {prop: 'id', order: 'descending'},
                searchFields: {
                    selected: ['internal_name'],
                    lastSelection: ['internal_name']
                },
                oloCodeFilter: null,
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    lastLoadedPage: 1,
                    total: 0,
                    from: 0,
                    to: 0,
                    showedItems: 0
                },
                statusFilter: null,
                searchQuery: '',
                searchTypeSelected: 0,
                tableMultipleSelect: null
            },
            statusList: [
                {key: 'pending', label: 'In Attesa'},
                {key: 'accepted', label: 'Accettato'},
                {key: 'ignored', label: 'Ignorato'},
                {key: 'rejected', label: 'Rifiutato'}
            ],
            searchTypeOptions: [
                {searchType: '0', label: 'Contiene'},
                {searchType: '2', label: 'Inizia per'},
                {searchType: '1', label: 'È uguale a'}
            ],
            tableColumns: [
                {
                    prop: 'id',
                    label: 'ID',
                    percentWidth: 8,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: true,
                    type: 'default',
                    first: true,
                    custom: 'id'
                },
                {
                    prop: 'arrived_at',
                    label: 'Orario di arrivo',
                    percentWidth: 30,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'arrived_at'
                },
                {
                    prop: 'raw',
                    label: 'COR',
                    percentWidth: 30,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'raw'
                },
                {
                    prop: 'raw',
                    label: 'Codice Operatore',
                    percentWidth: 30,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'raw_operatore'
                },
                {
                    prop: 'status',
                    label: 'Stato',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'status'
                },
                {
                    prop: 'file',
                    label: 'File RAW',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'file'
                },
                {
                    label: 'Gestisci',
                    percentWidth: 13,
                    sortable: false,
                    showTooltip: false,
                    fixed: false,
                    type: 'default',
                    custom: 'azioni'
                }
            ],
            loading: false,
            tableData: [],
            canShowView: false,
            showRaw: false,
            migrationRaw: "",
            isRejectModalOpen: false,
            isRejectReasonLoading: false,
            rejectedRequest: null,        //Request who want reject (single full request obj)
            rejectedReason: null,         //Array of possible rejected reason (from API)
            choosedReason: null,          //User choose reason of rejected process.
            migrationSwalData: {
                title: 'Confermi migrazione?',
                text: "-",
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'No',
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            }
        }
    },
    methods: {
        showCor(_indx, row){
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
            const mig_raw = this.tableData[_indx].raw;

            //Singolo file!
            if(mig_raw.Request.Parametri){
                if(mig_raw.Request.Parametri.CodiceRisorsa1)
                    return  mig_raw.Request.Parametri.CodiceRisorsa1
                else
                    return "-";
            }

            //File multiplo!
            if(mig_raw.Request[0]){
                if(mig_raw.Request[0].Parametri.CodiceRisorsa1){
                    return mig_raw.Request[0].Parametri.CodiceRisorsa1
                }
                else{
                    return "-"
                }
            }
            return "?";
        },

        showCodiceOperatoreRecipient(_indx, row){
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
            const mig = this.tableData[_indx];
            const mig_raw = this.tableData[_indx].raw;

            let CodiceOperatoreRecipient = "?";
            let operator = "?";


            //Singolo file!
            if(mig_raw.Request.Parametri){
                //console.log(mig)
                if(mig_raw.Request.Parametri.CodiceOperatoreRecipient)
                    CodiceOperatoreRecipient = mig_raw.Request.Parametri.CodiceOperatoreRecipient
            }

            //File multiplo!
            if(mig_raw.Request[0]){
                if(mig_raw.Request[0].Parametri.CodiceOperatoreRecipient)
                    CodiceOperatoreRecipient = mig_raw.Request[0].Parametri.CodiceOperatoreRecipient
            }

            if(mig.operator){
                operator = mig.operator;
            }

            return operator+" ("+CodiceOperatoreRecipient+")";

        },

        generateCorLink(_indx, row){
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
            const mig_raw = this.tableData[_indx].raw;

            //Singolo file!
            if(mig_raw.Request.Parametri){
                if(mig_raw.Request.Parametri.CodiceRisorsa1)
                    return  "https://www.dimensione.com/private_tools/utenti.php?cor="+mig_raw.Request.Parametri.CodiceRisorsa1;
                else
                    return "";
            }

            //File multiplo!
            if(mig_raw.Request[0]){
                if(mig_raw.Request[0].Parametri.CodiceRisorsa1){
                    return "https://www.dimensione.com/private_tools/utenti.php?cor="+mig_raw.Request[0].Parametri.CodiceRisorsa1;
                }
                else{
                    return ""
                }
            }
        },

        //Change reasons for rejection selected by User
        handleChangeRejectReason: function (value) {
            this.choosedReason = value;
        },

        //TODO: ROLES???
        canEdit(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['assurance/canEdit'](elementToCheck)
            }
        },

        convertRejectReasonToArray(data) {
            let k = Object.keys(data).length;
            let rejected = [];
            for (let i = 0; i < k; i++) {
                rejected.push({
                    key: Object.keys(data)[i],
                    value: Object.values(data)[i]
                })
            }
            return rejected;
        },


        //Afer choose reason this function can manipulate process of reject
        async rejectMigrationWithReason() {
            let reason = this.choosedReason; //Reason of reject
            let migration = this.rejectedRequest; //Migration to reject

            let post_params = {
                id_migration: migration.id,
                reject_reason: reason
            }

            this.isRejectModalOpen = false;

            this.migrationSwalData.title = "Rifiutare la migrazione?";
            //TODO: Inserire dei valori reali
            this.migrationSwalData.text = "La migrazione verrà rifiutata con esito" + this.reject_reason + " (descrizione dell\'esito KO). Sei sicuro di voler procedere? L\'operazione non può essere annullata.";
            await Swal.fire(this.migrationSwalData).then((result) => {
                if (result.isConfirmed) {
                    //TODO: Loading start
                    this.loading = true;
                    let params = Object.assign({}, this.$store.getters.apiCommon, post_params)
                    let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/reject`;
                    axios.post(url, params)
                        .then(async (response) => {
                            Swal.fire('Migrazione Respinta.', 'La migrazione è stata respinta con valido KO.', 'success');
                            this.loading = false;
                            this.updateData();
                        })
                        .catch((e) => {
                            Swal.fire('Migrazione non confermata!', 'La migrazione non è stata respinta', 'error');
                            this.loading = false;
                            reject(e)
                        })
                }
            })
        },

        //Get the list of all possible rejection states from the backend (for Migration)
        getAllRejectedStatus() {
            this.isRejectReasonLoading = true;
            let params = Object.assign({}, this.$store.getters.apiCommon)
            let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/get-migration-rejected-reason`;
            axios.post(url, params)
                .then(async (response) => {
                    this.isRejectReasonLoading = false;
                    this.rejectedReason = this.convertRejectReasonToArray(response['data']['data']);
                })
                .catch((e) => {
                    this.isRejectReasonLoading = false;
                    //Swal.fire('Migrazione non confermata!', 'La migrazione non è stata confermata', 'error');
                    reject(e)
                })
        },

        //Can a migration be set to a certain state??
        canMigrationSetToState(index, row, state) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
            let result = false;
            if (state === "rejected" || state === "confirmed") {
                //console.log(this.tableData[_indx].status_formatted);
                return _indx >= 0 && (this.tableData[_indx].status_formatted === 'In Attesa');
            } else if (state === 'ignored') {
                return _indx >= 0 && this.tableData[_indx].status_formatted === 'In Attesa';
            }
            return result;
        },

        //Has the migration already been handled?
        isMigrationAlreadyHandled(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            return _indx >= 0 && (this.tableData[_indx].status_formatted !== 'In Attesa' && this.tableData[_indx].status_formatted !== 'Ignorato');
        },

        // >>[Change Migration state to Accept]<<
        async confirmAccept(migration_to_accept) {
            this.migrationSwalData.title = "Confermi migrazione?";
            this.migrationSwalData.text = "Confermando la migrazione, procederai ad inoltrare un file XML all'operatore, confermando l'OK ad acquisire il nostro cliente. Verrà generato un codice sessione casuale che sarà salvato sui nostri sistemi.\n" +
                "L'operazione non può essere annullata."
            await Swal.fire(this.migrationSwalData).then((result) => {
                if (result.isConfirmed) {
                    //TODO: Da ritestare
                    let post_params = {id_migration: migration_to_accept.id}
                    let params = Object.assign({}, this.$store.getters.apiCommon, post_params)
                    let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/${migration_to_accept.id}/accept`;
                    axios.post(url, params)
                        .then(async (response) => {
                            //console.log(response);
                            await Swal.fire('Migrazione confermata!', 'La migrazione è stata confermata correttamente', 'success');
                            this.updateData();
                        })
                        .catch((e) => {
                            Swal.fire('Migrazione non confermata!', 'La migrazione non è stata confermata', 'error');
                            reject(e)
                        })
                }
            })
        },

        // >>[Change Migration state to Reject]<<
        async openModalReject(migration_to_reject) {
            this.getAllRejectedStatus(); //Ottenimento dei codici di rifiuto
            this.rejectedRequest = migration_to_reject;
            this.isRejectModalOpen = true;
        },

        // >>[Change Migration state to Ignore]<<
        async confirmIgnored(migration_to_reject) {
            this.migrationSwalData.title = "Ignora la migrazione";
            this.migrationSwalData.text = "Descrizione: Ignorando la migrazione, non verrà eseguita nessuna operazione e sarà impostato come ignorato sui nostri sistemi. Non verrà inviata nessuna risposta all'operatore."
            await Swal.fire(this.migrationSwalData).then((result) => {
                if (result.isConfirmed) {

                    //TODO: Chiamata API per ignored
                    let post_params = {id_migration: migration_to_reject.id}
                    let params = Object.assign({}, this.$store.getters.apiCommon, post_params)
                    let url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + `/olo2olo/migration/ignore`;
                    axios.post(url, params)
                        .then(async (response) => {
                            //console.log(response);
                            await Swal.fire('Migrazione Ignorata!', 'La migrazione è stata ignorata correttamente', 'success');
                        })
                        .catch((e) => {
                            Swal.fire('Migrazione non ignorata!', 'La migrazione non è stata ignorata correttamente', 'error');
                            reject(e)
                        })
                }
            })
            this.updateData();
        },

        // >>[Open the raw information for operator]<<
        async openContentFileMigration(index, row) {
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            let migration = this.tableData[_indx];
            //console.log(typeof migration.raw);
            //console.log(migration.raw)
            this.migrationRaw = migration.raw;
            this.showRaw = true;
        },

        // >>[Handle all type of migration changing (Reject, Confirm and Accept]<<
        async handleMigration(index, row, type) {
            this.rejectedRequest = null;
            // Retrive migration data for decore dialog information
            let _indx = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            let migration = this.tableData[_indx];
            let canDoMigration = _indx >= 0 && migration.status_formatted === 'In Attesa';
            let isValidType = (type === 'Accept' || type === 'Reject' || type === 'Ignore');

            if (canDoMigration && isValidType) {
                switch (type) {
                    case 'Accept':
                        await this.confirmAccept(migration);
                        break;
                    case 'Reject':
                        await this.openModalReject(migration);
                        break;
                    case 'Ignore':
                        await this.confirmIgnored(migration);
                        break;
                }
            } else {
                Swal.fire('Errore', 'Attenzione, non si può gestire la migrazione (INVALID MIGRATION TYPE)', 'error');
            }

        },

        handlePageChange(page) {
            if (page === this.currentState.pagination.lastLoadedPage) return
            this.updateData()
        },
        handlePerPageChange(perPage) {
            this.updateData({page: 1, limit: perPage})
        },
        handleSearchChange() {
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSearchFieldsChange(pIndex) {
            if (this.currentState.searchFields.selected.length < 1) {
                this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
                return
            }
            if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
                this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
            }
            this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSearchTypeChange(pIndex) {
            if (this.currentState.searchQuery === '') return
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },

        handleSelectionChange(pSelection) {
            this.currentState.tableMultipleSelect = pSelection
        },

        handleSortChange(pSortType) {
            let cSort = this.findColumnSort(pSortType)
            //console.log(pSortType.order);
            //console.log(this.currentState.currentSorting.order);

            if (pSortType.prop === null) {
                this.currentState.currentSorting = this.componentSettings.tableSort
            } else {
                if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
                    return;
                }
                this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
            }
            this.updateData();
        },

        handleStatusFilterChange(status) {
            // this.resetFilters(false)
            this.currentState.statusFilter = status
            this.updateData()
        },

        resetFilters(pReload = true) {
            this.currentState.searchQuery = ''
            this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
            this.currentState.tableMultipleSelect = null
            this.currentState.searchTypeSelected = 0
            this.currentState.pagination.perPage = 25
            this.currentState.pagination.currentPage = 1
            this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
            this.clearSavedState()
            if (pReload) this.updateData()
        },

        // >>[This function retrieve all list of migrations]<<
        updateData() {
            this.loading = true
            let _orderDirection = "";
            if (this.currentState.currentSorting.order && this.currentState.currentSorting.order === 'ascending') {
                _orderDirection = "asc";
            } else if (this.currentState.currentSorting.order && this.currentState.currentSorting.order === 'descending') {
                _orderDirection = "desc";
            }

            //console.log("this.currentState.cowFilter =>" + this.currentState.cowFilter);

            let apiParams = {
                migration_status: this.currentState.statusFilter,
                limit: this.currentState.pagination.perPage,
                page: this.currentState.pagination.currentPage,
                olo_code_donating: this.currentState.donatingFilter,
                olo_cow: this.currentState.cowFilter,
                raw_filter: this.currentState.rawFilter,
                orderBy: this.currentState.currentSorting.prop,
                orderDirection: _orderDirection
            }

            //console.log(this.currentState)
            this.$store.dispatch('olo2olo/paginatedList', apiParams).then((response) => {
                this.loading = false;
                this.tableData = response.data.data.map(el => ({...el, "raw": JSON.parse(el.raw)}));

                this.currentState.pagination.total = response.data.total;
                this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
                this.currentState.pagination.showedItems = response.data.data.length
                this.currentState.pagination.from = response.data.from
                this.currentState.pagination.to = response.data.to

            }).catch(e => {
                this.loading = false
                this.showLoadingError(e)
            })

        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) {
            return
        }
        this.canShowView = true
        console.log('created', this.currentState.statusFilter)
    },
    mounted: function () {
        console.log('mounted', this.currentState.statusFilter)
        this.updateData()
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style>
</style>
