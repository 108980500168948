import Config from '../../globalConfig'
import axios from 'axios'

const promoCodes = {
  namespaced: true,
  state: {
    promoCodes: []
  },
  getters: {
    promoCodes: state => state.promoCodes,
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {
    updatePromoCodes: (state, pValue) => {
      state.promoCodes = pValue
    }
  },
  actions: {
    reset ({commit}) {
      commit('updatePromoCodes', [])
    },
    async editType ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.types + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async deleteType ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.promoCodes.types + pElement.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async attachToOrderProduct ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(rootGetters.apiCommon, params)
        axios.post(Config.endpoints.promoCodes.attach, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async burnCode ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(rootGetters.apiCommon, params)
        axios.post(Config.endpoints.promoCodes.burnCode, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getCurrentOrderProducts ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(rootGetters.apiCommon, params)
        axios.post(Config.endpoints.orders.base + params.id + '/products', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async loadPromoCodes ({commit, state, getters, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        if (getters.promoCodes.length > 0) {
          resolve(getters.promoCodes)
          return
        }
        params = Object.assign({}, {enabled: true}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.getPromos, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updatePromoCodes', response.data.promo_code_types)
            resolve(response.data.promo_code_types)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async resetCode ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.resetCode, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async promoCodeTypesList ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, {enabled: true}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.getPromos, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async addPromoType ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.addPromoType, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async editPromoType ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.types + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async generate ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        params = Object.assign({}, params, {timeout: 120000})
        axios.post(Config.endpoints.promoCodes.types + pElement.promo_id + '/generate', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getById ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.types + params.id, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async exportCsv ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        let parameters = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.promoCodes.types + params.promo_id + '/export', parameters)
          .then((response) => {
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async attendanceLogsExportCsv ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        let parameters = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.utility.timePanel.attendanceLogs.export.csv, parameters)
            .then((response) => {
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    }
  }
}

export default promoCodes
