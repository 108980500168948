<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model=selected
                   placeholder="Seleziona ente di rilascio"
                   size="large"
                   @change="updateValue($event)"
                   value-key="name">
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.name"
                :value=item.code>
            </el-option>
        </el-select>
    </div>
</template>

<script>


export default {
    name: "issuer-identity-document-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [
                {code: 1, name: 'Comune'},
                {code: 2, name: 'Motorizzazione civile'},
                {code: 3, name: 'Altro'}
            ],
            selected: this.modelValue
        }
    },
    props: {
        modelValue: {
            type: Number
        },
    },
    methods: {
        updateValue: function (item) {
            this.selected = item
            this.$emit('update:modelValue', item)
        }
    },
    watch: {
        modelValue(val) {
            this.selected = val
        }
    }
}
</script>

<style scoped>

</style>
