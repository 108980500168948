<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model="selected"
                   placeholder="..."
                   @change="updateValue($event)"
                   value-key="code">
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.name"
                :value=item.code>
            </el-option>
        </el-select>
    </div>
</template>

<script>


export default {
    name: "np-line-type-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [
                {
                    name: 'Simplex',
                    code: 'simplex'
                },
                {
                    name: 'GNR',
                    code: 'gnr'
                }
            ],
            selected: this.modelValue
        }
    },
    props: {
        modelValue: {
            type: String
        },
    },
    watch: {
        value(val) {
            this.selected = val
        }
    },
    methods: {
        updateValue: function (val) {
            this.$emit('update:modelValue', val)
        }
    }
}
</script>

<style scoped>

</style>
