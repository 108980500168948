<template>
    <div id="order-step-customer">
        <div class="row mt-2">
            <div class="col-12 text-center">
                <h4 class="m-0">Documento d'identità</h4>
            </div>
            <div class="col-12 mt-4">
                <el-form ref="form" :model="customer" label-width="180px">
                    <el-form-item label="Tipo documento">
                        <identity-document-select v-model="customer.tipo_documento"></identity-document-select>
                    </el-form-item>
                    <el-form-item label="Numero documento">
                        <el-input v-model="customer.numero_documento"></el-input>
                    </el-form-item>
                    <el-form-item label="Data rilascio">
                        <el-date-picker
                            v-model="customer.data_rilascio_documento"
                            type="date"
                            placeholder="Seleziona..."
                            format="DD-MM-YYYY"
                            value-format="YYYY-MM-DD"
                            language="it">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Data scadenza">
                        <el-date-picker
                            v-model="customer.data_scadenza_documento"
                            type="date"
                            placeholder="Seleziona..."
                            format="DD-MM-YYYY"
                            value-format="YYYY-MM-DD"
                            :picker-options="pickerOptions"
                            language="it">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Ente di rilascio">
                        <issuer-identity-document-select
                            v-model="customer.rilascio_documento" />
                    </el-form-item>
                    <el-form-item label="Comune di rilascio">
                        <el-input v-model="customer.comune_rilascio_documento"></el-input>
                    </el-form-item>
                    <el-form-item label="Provincia di rilascio">
                        <province-select v-model="customer.provincia_documento"></province-select>
                    </el-form-item>
                </el-form>
            </div>
            <hr>
            <div class="col-12 text-center">
                <label class="label">Foto del documento</label>
                <document-upload :order-id="orderId" @upload="loadOrderIdentityDocumentMedias"/>
            </div>
            <div class="col-12 d-flex justify-content-center" v-if="documentLoading">
                <h4 class="text-center pt-5 pb-5 m-0">Caricamento documenti <i class="el-icon-loading"></i></h4>
            </div>
            <div class="col-12 mt-4" v-if="identityDocumentMedias.length > 0">
                <order-media-list v-model="identityDocumentMedias"
                                  @deleted="loadOrderIdentityDocumentMedias" class="shadow"></order-media-list>
            </div>
            <div class="col-12 mt-4 justify-content-center"
                 v-if="identityDocumentMedias.length <= 0 && !documentLoading">
                <h4 class="text-center pt-5 pb-5 m-0 shadow bg-light-gray">Nessun documento caricato</h4>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="saveAndBack()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="saveAndNext()">Avanti<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";
import IdentityDocumentSelect from "@/components/Inputs/IdentityDocumentTypeSelect";
import IssuerIdentityDocumentSelect from "@/components/Inputs/IssuerIdentityDocumentSelect";
import DocumentUpload from "@/components/Inputs/DocumentUpload";
import OrderMediaList from "@/components/Order/OrderMediaList";

export default {
    name: "step-customer-document",
    components: {
        OrderMediaList,
        DocumentUpload,
        IssuerIdentityDocumentSelect,
        IdentityDocumentSelect,
        GiuridicFormSelect,
        ProvinceSelect
    },
    data() {
        return {
            customer: {},
            identityDocumentMedias: [],
            documentLoading: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() <= Date.now();
                }
            }
        }
    },
    mounted() {
        this.customer = this.modelValue
        this.loadOrderIdentityDocumentMedias()
    },
    props: {
        modelValue: {
            type: Object
        },
        orderId: {
            type: Number
        }
    },
    methods: {
        saveAndNext() {
            if (this.validate()) {
                this.$emit('next')
            }
        },
        saveAndBack() {
            this.saveCustomer();
            this.$emit('back')
        },
        saveCustomer() {
            this.loading = true
            this.$store.dispatch('customers/edit', this.customer).then((response) => {
                this.loading = false
                this.customer = response.data.customer
                this.$emit('input', this.customer)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo cliente')
            })
        },
        loadOrderIdentityDocumentMedias() {
            this.documentLoading = true;
            this.$store.dispatch('orders/medias/getIdentityDocument', {orderId: this.orderId}).then((response) => {
                this.identityDocumentMedias = response.data.data
                this.documentLoading = false;
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei documenti.', true)
                this.documentLoading = false;
            })
        },
        validate() {
            if (!this.customer.numero_documento) {
                this.$message.error("Inserire il numero del documento.")
                return false;
            }

            if (!this.customer.data_rilascio_documento) {
                this.$message.error("Inserire una data di rilascio del documento.")
                return false;
            }
            if (!this.customer.data_scadenza_documento) {
                this.$message.error("Inserire una data di scadenza del documento.")
                return false;
            }

            if (!this.customer.rilascio_documento) {
                this.$message.error("Inserire l'ente di rilascio del documento.")
                return false;
            }

            if (!this.customer.comune_rilascio_documento) {
                this.$message.error("Inserire il comune di rilascio del documento.")
                return false;
            }
            if (!this.customer.provincia_documento) {
                this.$message.error("Inserire la provincia di rilascio del documento.")
                return false;
            }

            this.saveCustomer();

            return true;
        }
    }
}
</script>

<style scoped>

</style>
