<template>
    <div v-loading="loading" v-if="order">
        <h4 class="mb-3">Dettagli pagamento ordine</h4>


        <div class="row" v-if="order.total_to_pay">
            <div class="col-12">

                <p style="font-size: 110%;">Totale da pagare: <span class="font-weight-bold"
                                                                    style="font-size: 120%;">{{ order.total_to_pay.total }}€</span>
                </p>
                <!--<p class="mb-0">Totale anticipi: <span class="font-weight-bold">{{order.total_to_pay.total_upfront}}€</span></p>

                <p>Totale offerta integrata: <span class="font-weight-bold">{{order.total_to_pay.integrated_offers.total}}€</span></p> -->

                <p class="font-weight-bold mb-2" style="font-size: 120%;">Riepilogo offerte</p>
                <!-- <p class="mb-2">Totale offerte: <strong>{{order.total_to_pay.offers.total}}</strong></p> -->
                <ol>
                    <li v-for="(item) in order.total_to_pay.offers.offers" class="mb-2">
                        <small class="font-weight-bold">{{ item.name }}<br/></small>
                        <small class="n-thin" v-if="item.options">Opzioni: {{ item.options }}€<br/></small>
                        <small class="n-thin">Importo da pagare: <strong
                            style="font-size: 110%;">{{ item.total }}€</strong></small>
                    </li>
                </ol>

            </div>

        </div>

        <hr/>

        <div class="row mb-2">

            <div class="col-12 col-md-6 orderPaid">
                <div class="orderPaid float-left" v-if="order.paid">
                    <div class="circle"></div>
                    Pagato
                </div>
                <div class="ordernotPaid float-left" v-if="!order.paid">
                    <div class="circle"></div>
                    Non pagato
                </div>

                <a class="a-link btn-link float-left mb-3 ml-3"
                   style="font-size: 85%; padding-top: 3px; color: #3c4044 !important;" @click.prevent="toggleOrderPaid"
                   @update="toggleOrderPaid">
                    <span style="text-decoration: underline">Imposta come <span v-if="!order.paid">pagato</span><span
                        v-if="order.paid">non pagato</span></span> <i class="el-icon-edit-outline"></i>
                </a>

            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                <label>Metodo di pagamento</label>
                <br/>

                <div class="row">
                    <el-select v-model="payment_gateway" class="col-12 select-default mb-3" placeholder="Seleziona..."
                               size="large" :disabled="order.paid" @change="updateOrder">

                        <el-option
                            v-for="item in paying_methods"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>

                    </el-select>
                </div>
            </div>

            <div id="paymentLinkSection">

                <div class="row mb-0">
                    <div class="col-12" v-if="order.payment_link">
                        <label class="d-none d-md-block">&nbsp;</label>

                        <div class="ml-3 ml-md-3 mb-2">
                            <a class="btn btn-primary btn-outline a-link" style="height: 40px;"
                               @click="copyPaymentLink">
                                <div style="float: left;">Copia link <span
                                    v-if="payment_gateway === 1">DocData</span><span v-if="payment_gateway === 2">Braintree</span>
                                    negli appunti
                                </div>
                                <div style="float: left; padding-top: 2px"><i style="font-weight: bold;"
                                                                              class="nc-icon nc-single-copy-04 ml-2"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="refundSection" class="col-12 col-md-12 col-lg-4 ml-0 ml-xl-3">
                <label class="d-none d-xl-block">&nbsp;</label>
                <a class="btn btn-primary btn-outline a-link" style="height: 40px;" @click="refundOrder">
                    <div style="float: left; ">Rimborsa ordine</div>
                    <div style="float: left; padding-top: 2px"><i style="font-weight: bold;"
                                                                  class="nc-icon nc-money-coins ml-2"></i></div>
                </a>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <el-collapse @change="handleLoadPaymentDetails" v-model="collapseStatus" accordion>
                    <el-collapse-item title="Mostra dettagli pagamento" name="0" class="pt-0">
                        <div class="col-12 pt-3">
                            <pre v-if="payment_details">{{ payment_details }}</pre>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div class="row" v-if="payment_gateway === 2">

            <div class="col-12">
                <h4>Diagnosi Braintree</h4>
            </div>

            <div class="col-12">
                <a class="btn btn-primary btn-outline a-link mr-4" @click.prevent="loadBraintreeTransactions">Mostra
                    transazioni su Braintree<i class="fa fa-list ml-1"></i></a>
                <a class="btn btn-danger btn-outline a-link" v-if="!order.paid"
                   @click.prevent="handleUpdateBraintreeManual">Aggiorna manualmente il pagamento<i
                    class="fa fa-edit ml-1"></i></a>
            </div>
        </div>

        <div class="row mt-4" v-if="braintreeTransactions && braintreeTransactions.length > 0">
            <h5 class="col-12 mt-3">Lista transazioni</h5>
            <div class="col-8" style="max-width: 890px;">
                <el-table class="table-striped" :data="braintreeTransactions" border style="width: 100%;">

                    <template v-for="column in tableColumns">
                        <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type"
                                         :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)"
                                         :prop="column.prop" :sortable="column.sortable"
                                         :label="column.label"></el-table-column>

                        <el-table-column v-else-if="column.custom === 'created_at'" :type="column.type"
                                         :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)"
                                         :prop="column.prop" :sortable="column.sortable" :label="column.label">
                            <template #default="props">
                                <span v-if="props.row">{{ dateToItalian(props.row.created_at, false) }}</span>
                            </template>
                        </el-table-column>

                    </template>

                </el-table>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <div class="text-center">

                    <button type="submit" class="btn btn-primary btn-fill btn-wd mb-3" @click.prevent="updateOrder"
                            @update="updateOrder">
                        Salva
                    </button>

                </div>
            </div>
            <div class="col-12">
                <hr/>
            </div>
        </div>

    </div>
</template>

<style>
.orderPaid {
    color: #4F96E4;
    font-weight: bold;
    font-size: 20px;
}

.orderPaid .circle {
    background-color: #4F96E4;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    float: left;
    margin-top: 9px;
    margin-right: 8px;
    /* box-shadow: 0px 0px 2px #ababab, -0px -0px 2px #ababab, 0px -0px 2px #ababab, -0px 0px 2px #ababab; */
}

.ordernotPaid {
    color: #E74155;
    font-weight: bold;
    font-size: 20px;
}

.ordernotPaid .circle {
    background-color: #E74155;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    float: left;
    margin-top: 9px;
    margin-right: 8px;
    /* box-shadow: 0px 0px 2px #ababab, -0px -0px 2px #ababab, 0px -0px 2px #ababab, -0px 0px 2px #ababab; */
}
</style>

<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'

export default {
    mixins: [],
    components: {
        LSwitch,
        LCheckbox
    },
    props: {
        order: {}
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderPaymentDetails'
            },
            paying_methods: [{code: 1, name: 'DocData'}, {code: 2, name: 'Braintree'}, {code: 3, name: 'Bonifico'}],
            loading: false,
            payment_details: null,
            payment_gateway: 2,
            collapseStatus: [],
            braintreeTransactions: [],
            braintreeManualTransaction: null,
            tableColumns: [
                {
                    label: 'ID',
                    prop: 'id',
                    percentWidth: 14,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    first: true,
                    custom: false
                },
                {
                    label: 'Stato',
                    prop: 'status',
                    percentWidth: 20,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: false
                },
                {
                    label: 'Metodo',
                    prop: 'payment_instrument_type',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: false
                },
                {
                    label: 'ID rimborso',
                    prop: 'refunded_transaction_id',
                    percentWidth: 16,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: false
                },
                {
                    label: 'Data',
                    percentWidth: 20,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'created_at'
                }
            ]
        }
    },
    watch: {
        'order': function () {
            if (this.order.payment_gateway !== null) {
                this.payment_gateway = this.order.payment_gateway
            }
        }
    },
    computed: {},
    methods: {
        copyPaymentLink() {
            if (this.order.payment_link != null) {
                this.$copyText(this.order.payment_link).then((e) => {
                    this.showGenericSuccess('Link', 'copiato negli appunti', '1000')
                }).catch(e => {
                    this.showGenericError('Errore!', 'Errore durante la copia negli appunti, verifica il tuo browser.')
                })
            } else {
                this.showGenericError('Errore!', 'Link non presente!')
            }
        },
        handleLoadPaymentDetails(param) {
            if (param.length > 0) {
                this.loadPaymentDetails()
            }
        },
        loadPaymentDetails() {
            this.loading = true
            if (!this.payment_details) {
                this.$store.dispatch('orders/orderPayment/loadPaymentDetails', {
                    payment_gateway: this.payment_gateway,
                    order: this.order
                }).then((response) => {
                    this.loading = false
                    if (response.data.braintree_transaction) this.payment_details = JSON.stringify(response.data.braintree_transaction, null, '\t')
                    if (response.data.docdata_payment_detail) this.payment_details = JSON.stringify(response.data.docdata_payment_detail, null, '\t')
                }).catch(e => {
                    this.collapseStatus = []
                    this.loading = false
                    this.showLoadingErrorWithoutRetry(e, 'il caricamento dei dettagli del pagamento ordine')
                })
            } else {
                this.loading = false
            }
        },
        loadBraintreeTransactions() {
            this.loading = true
            this.$store.dispatch('orders/orderPayment/loadBraintreeTransactions', {order_id: this.order.id}).then((response) => {
                this.loading = false
                this.braintreeTransactions = response.data.transactions
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle transazioni su Braintree')
            })
        },
        handleUpdateBraintreeManual() {
            this.askGenericConfirm('Sei sicuro?', 'Questa operazione verificherà su Braintree i pagamenti registrati, ed imposterà l\'ordine come pagato se risulta almeno una transazione pagata dal cliente che non è stata registrata prima.', 'Sì, aggiorna!', 'No, ho sbagliato!').then((result) => {
                if (!result.value) {
                    return
                }
                this.updateBraintreeManual()
            })
        },
        updateBraintreeManual() {
            this.loading = true
            this.$store.dispatch('orders/orderPayment/updateBraintreeManual', {order_id: this.order.id}).then((response) => {
                this.loading = false
                this.braintreeManualTransaction = response.data.transaction
                if (this.braintreeManualTransaction) {
                    this.order.paid = true
                    this.showGenericSuccess('Pagamento', 'aggiornato con successo')
                } else {
                    this.showGenericError('Fatto', 'non sono state trovate transazioni')
                }
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento del pagamento')
            })
        },
        updateOrder() {
            this.$emit('update', {
                id: this.order.id,
                payment_gateway: this.payment_gateway
            }, false)
        },
        toggleOrderPaid() {

            this.order.paid = !this.order.paid
            this.$emit('update', this.order)
        },
        handleAddMnp(pOrderProduct) {
            this.askGenericConfirm('Vuoi creare una MNP?', 'Premendo Sì confermerai la creazione della MNP.', 'Sì, crea!', 'No, ho sbagliato!').then((result) => {
                if (!result.value) {
                    return
                }
                this.createMnpForProduct(pOrderProduct)
            })
        },
        refundOrder() {
            this.askGenericConfirm('Vuoi rimborsare l\'ordine?', 'Premendo Sì confermerai l\'operazione di rimborso.', 'Sì, rimborsa!', 'No, ho sbagliato!').then((result) => {
                if (!result.value) {
                    return
                }
                this.loading = true
                this.$store.dispatch('orders/orderPayment/refundOrder', this.order).then((response) => {
                    this.loading = false
                    this.showGenericSuccess('Pagamento', 'rimborsato con successo', '1000')
                }).catch(e => {
                    this.loading = false
                    this.showLoadingErrorWithoutRetry(e, 'il rimborso dell\'ordine')
                })
            })
        }
    },
    created: function () {
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
