<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Lista avvisi prodotti">

        <div class="table-layout-container">

          <div class="row mt-3 mb-0">
            <div class="col-12 col-md-5 col-lg-5 col-xl-3">
              <router-link :to="{path:'/productAlerts/lista/aggiungi'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-plus"></i> Aggiungi avviso prodotto
                </span>
                </button>
              </router-link>
            </div>
          </div>

          <div class="row mt-4" v-if="tableMultipleSelect && tableMultipleSelect.length > 0">
            <div class="col-12 col-sm-6 col-md-4">
              <label>Invia notifica agli avvisi selezionati</label>
              <br/>
              <el-select v-loading="loading" style="width: 100%" class="select-default mb-0" placeholder="Seleziona notifica..." size="large" v-model="currentTemplate">
                <el-option
                  v-for="item in templates"
                  class="select-default"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div class="col-12 col-sm-12 col-md-4">
              <button type="button" @click.prevent="handleSendNotification" :disabled="!currentTemplate" class="btn btn-primary btn-fill btn-wd" style="margin-top: 31px; background-color: #FFA534 !important; border: 1px solid #FFA534 !important;">Invia</button>
            </div>
          </div>

          <div class="row mt-5">
            <div class="mb-3 col-12 col-md-3" style="max-width: 140px;">
              <el-select class="select-default" style="width: 100%; max-width: 110px;" v-model="currentState.pagination.perPage" placeholder="Per pagina" @change="handlePerPageChange">
                <el-option class="select-default" v-for="item in componentSettings.pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>

            <div class="col">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage" :per-page="currentState.pagination.perPage" :total="currentState.pagination.total" @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row mt-3">

            <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-3 mb-3">
                  <el-select multiple class="select-default" style="width: 100%" v-model="currentState.searchFields.selected" placeholder="Dove cerco?" @change="handleSearchFieldsChange">
                    <el-option v-for="(option, index) in searchFields" class="select-default" :value="option.searchFields" :label="option.label" :key="index">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-12 col-sm-3 mb-3">
                  <el-select class="select-default" style="width: 100%" v-model="currentState.searchTypeSelected" placeholder="Come cercare?" @change="handleSearchTypeChange">
                    <el-option class="select-default" v-for="(item, index) in searchTypeOptions" :key="index" :label="item.label" :value="index"></el-option>
                  </el-select>
                </div>

                <div class="col-12 col-sm-6">
                  <el-input style="width: 100%" type="search" placeholder="Cerca" v-model="currentState.searchQuery" @change="handleSearchChange" addon-right-icon="fa fa-search" />
                </div>

              </div>

              <div class="row">
                <div class="col-12 col-sm">
                  <el-select v-loading="loading" value-key="code" style="width: 100%" class="select-default mb-3" v-model="currentState.currentStatus" placeholder="Seleziona..." @change="handleStatusChange" size="large">
                    <el-option class="select-default" :key=-1 label="Tutti gli stati" :value=-1></el-option>

                    <el-option
                      v-for="item in productAlerts_statuses"
                      class="select-default"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-12 col-sm">
                  <el-select v-loading="loading" value-key="code" style="width: 100%" class="select-default mb-3" v-model="currentState.currentCustomerType" placeholder="Tipo cliente" @change="handleCustomerTypeChange" size="large">
                    <el-option class="select-default" :key=-1 label="Tutti i clienti" :value=-1></el-option>

                    <el-option
                      v-for="item in productAlerts_customerTypes"
                      class="select-default"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-2 col-12 pr-2">
                  <button class="btn btn-outline btn-danger btn-outline col-12" style="padding: 9px 0px; width: 100%; margin-top: 0px;" @click="resetFilters()" data-tooltip="'Reset dei filtri'">
                <span class="btn-label">
                   <i class="fa fa-times"></i>
                </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <h4 style="font-weight: 400 !important; text-align: center" v-if="currentState.searchFields.selected[0] !== 'id'">Stai visualizzando: <strong>{{currentState.currentStatusLabel}}</strong></h4>
          <h4 style="font-weight: 400 !important; text-align: center" v-if="currentState.searchFields.selected[0] == 'id'">Stai filtrando solo per ID: <strong>{{currentState.searchQuery}}</strong></h4>


          <div class="row">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped td-strong" :data="tableData" border style="width: 100%" :default-sort="currentState.currentSorting" @sort-change="handleSortChange" @selection-change="handleSelectionChange">


                <template v-for="column in tableColumns">
                  <el-table-column v-if="column.custom && column.type == 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                  <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                  <el-table-column v-else-if="column.custom === 'id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                  <template #default="props">
                    <div v-if="props.row">
                      <router-link  class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">
                        <strong>{{props.row.id}}</strong><small style="font-size: 90%" v-if="props.row.origin"><br />{{props.row.origin}}</small>
                      </router-link>
                    </div>
                  </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'created_at'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div class="table-actions" v-if="props.row">

                        <span v-if="props.row.created_at"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">{{dateToItalian(props.row.created_at, true)}}</router-link></span>

                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'full_name'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row.full_name"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}"><span class="td-strong">{{props.row.full_name}}</span><br /></router-link></div>
                      <div v-if="props.row"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">{{props.row.email}}</router-link></div>
                      <div v-if="props.row"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">{{props.row.phone_number}} <br /></router-link></div>
                    </template>
                  </el-table-column>


                  <el-table-column v-else-if="column.custom === 'landing_tag'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">

                      <div style="font-size: 13px;">
                        <div v-if="props.row.landing_tag"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}"><span>Tag: </span> {{props.row.landing_tag}}</router-link></div>
                        <div v-if="props.row.color"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}"><span>Colore: </span> {{props.row.color}}</router-link></div>
                        <div v-if="props.row.product"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}"><span>Prodotto: </span> {{props.row.product}}</router-link></div>
                      </div>

                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'status_details'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">

                      <div style="font-size: 13px;">
                        <div v-if="props.row && props.row.status_details"><router-link class="dark-link" style="font-weight: bold;" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}"> {{props.row.status_details.name}}</router-link></div>
                        <span v-if="props.row && props.row.customer_type === 0"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">Privato <br /></router-link></span>
                        <span v-if="props.row && props.row.customer_type === 1"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">Professionista<br /></router-link></span>
                        <span v-if="props.row && props.row.customer_type === 2"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">Business <br /></router-link></span>
                        <span v-if="props.row && props.row.customer_type === 3"><router-link class="dark-link" :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">Già cliente <br /></router-link></span>
                      </div>

                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <router-link :to="{path:'/productAlerts/lista/modifica/' + props.row.id}">
                          <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link" v-if="canEdit(props.$index, props.row)"><i
                            class="fa fa-edit"></i></a>
                        </router-link>

                        <i class="fa fa-clone" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>

                        <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                           @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>

                        <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>
                      </div>
                    </template>
                  </el-table-column>

                </template>

              </el-table>
            </div>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da <strong>{{currentState.pagination.from}}</strong> a <strong>{{currentState.pagination.to}}</strong> su <strong>{{currentState.pagination.total}}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination } from '../../../components'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination
    },
    computed: {},
    data () {
      return {
        componentSettings: {
          singleFieldSearch: true,
          searchTypeOption: 0,
          tableSort: {prop: 'id', order: 'descending'},
          myComponentName: 'ProductAlerts',
          minimumPermissionLevel: 200,
          pagination: {
            perPageOptions: [5, 10, 25, 50, 100]
          },
          searchFieldsDefault: ['full_name']
        },
        currentState: {
          currentSorting: {prop: 'id', order: 'descending'},
          currentStatus: 0,
          currentStatusLabel: 'Creata',
          currentCustomerType: -1,
          searchFields: {
            selected: ['full_name'],
            lastSelection: ['full_name']
          },
          pagination: {
            perPage: 25,
            currentPage: 1,
            lastLoadedPage: 1,
            total: 0,
            from: 0,
            to: 0,
            showedItems: 0
          },
          searchQuery: '',
          searchTypeSelected: 0
        },
        searchFields: [
          {searchFields: 'id', label: 'ID'},
          {searchFields: 'full_name', label: 'Nome'},
          {searchFields: 'phone_number', label: 'Telefono'},
          {searchFields: 'email', label: 'E-mail'},
          {searchFields: 'landing_tag', label: 'Landing tag'},
          {searchFields: 'product', label: 'Prodotto'}
        ],
        searchTypeOptions: [
          {searchType: '0', label: 'Contiene'},
          {searchType: '2', label: 'Inizia per'},
          {searchType: '1', label: 'È uguale a'}
        ],
        templates: [],
        currentTemplate: null,
        tableColumns: [
          {
            percentWidth: 7,
            sortable: 'false',
            showTooltip: true,
            fixed: true,
            type: 'selection',
            first: true,
            custom: 'selection'
          },
          {
            prop: 'id',
            label: 'ID',
            percentWidth: 11,
            sortable: 'custom',
            showTooltip: true,
            fixed: true,
            type: 'default',
            custom: 'id'
          },
          {
            prop: 'created_at',
            label: 'Creato il',
            percentWidth: 17,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'created_at'
          },
          {
            prop: 'full_name',
            label: 'Cliente',
            percentWidth: 21,
            sortable: 'custom',
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'full_name'
          },
          {
            prop: 'landing_tag',
            label: 'Info prodotto',
            percentWidth: 20,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'landing_tag'
          },
          {
            prop: 'status_details',
            label: 'Stato',
            percentWidth: 15,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'status_details'
          },
          {
            prop: 'azioni',
            label: 'Azioni',
            percentWidth: 9,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'azioni'
          }
        ],
        loading: false,
        tableData: [],
        canShowView: false,
        productAlerts_statuses: [],
        productAlerts_customerTypes: [],
        selectedAlerts: [],
        tableMultipleSelect: null
      }
    },
    methods: {
      getSaveStateConfig () {
        return {
          'cacheKey': this.componentSettings.myComponentName,
          'saveProperties': ['currentState']
        }
      },
      canDelete (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['productAlerts/canDelete'](elementToCheck)
        }
      },
      canEdit (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['productAlerts/canEdit'](elementToCheck)
        }
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          let elementToDelete = this.tableData[indexToDelete]
          Swal.fire(this.deleteMessage(elementToDelete.name)).then((result) => {
            if (!result.value) return
            this.$store.dispatch('productAlerts/delete', elementToDelete).then(() => {
              Swal.fire('Eliminato!',
                'Questo elemento è stato eliminato.',
                'success'
              ).then(() => {
                if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                  this.currentState.pagination.currentPage--
                }
                this.updateData()
              })
            })
              .catch(e => {
                this.loading = false
                this.showLoadingError(e)
              })
          })
        }
      },
      handlePageChange (page) {
        if (page === this.currentState.pagination.lastLoadedPage) return
        this.updateData()
      },
      handlePerPageChange (perPage) {
        this.updateData({page: 1, limit: perPage})
      },
      handleSearchChange () {
        this.updateData()
      },
      handleSearchFieldsChange (pIndex) {
        if (this.currentState.searchFields.selected.length < 1) {
          this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
          return
        }
        if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
          this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
        }
        this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
        this.updateData()
      },
      handleSearchTypeChange (pIndex) {
        if (this.currentState.searchQuery === '') return
        this.updateData()
      },
      handleCustomerTypeChange (pCustomerType) {
        this.updateData()
      },
      handleSelectionChange (pSelectedAlerts) {
        this.tableMultipleSelect = pSelectedAlerts
        if (pSelectedAlerts.length > 0) {
          this.selectedAlerts = []
          for (let i = 0; i < pSelectedAlerts.length; ++i) {
            this.selectedAlerts.push(pSelectedAlerts[i].id)
          }
        }
      },
      handleSendNotification () {
        this.askGenericConfirm('Vuoi confermare l\'invio?', 'Premendo Sì confermerai l\'invio delle notifiche <strong>(' + this.selectedAlerts.length + ')</strong>.', 'Sì, invia!', 'No, ho sbagliato!').then((result) => {
          if (!result.value) {
            return
          }
          this.sendNotification()
        })
      },
      handleSortChange (pSortType) {
        let cSort = this.findColumnSort(pSortType)
        if (pSortType.prop === null) {
          this.currentState.currentSorting = this.componentSettings.tableSort
        } else {
          if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
            return
          }
          this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
        }
        this.updateData()
      },
      handleStatusChange (pStatus) {
        if (this.currentState.currentStatus === -1) {
          this.currentState.currentStatusLabel = 'Tutti gli stati'
        }
        let foundProductAlertStatus = null
        for (let i = 0; i < this.productAlerts_statuses.length; ++i) {
          if (this.productAlerts_statuses[i].code !== pStatus) continue
          foundProductAlertStatus = this.productAlerts_statuses[i]
          this.currentState.currentStatusLabel = foundProductAlertStatus.name
          break
        }
        this.updateData()
      },
      loadCustomerTypes () {
        this.loading = true
        this.$store.dispatch('productAlerts/loadCustomerTypes').then((response) => {
          this.productAlerts_customerTypes = response.data.customer_types
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei tipi cliente')
        })
      },
      loadStatuses () {
        this.loading = true
        this.$store.dispatch('productAlerts/getStatuses', {}).then((response) => {
          this.loading = false
          this.productAlerts_statuses = response.data.statuses
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento degli stati')
        })
      },
      loadTemplates () {
        this.loading = true
        let params = {}
        params = Object.assign({}, params, {type_filter: 5, force_reload: true, order_direction: 'asc', orderby: 'name'})
        this.$store.dispatch('notifications/emails/getTemplatesForProductAlerts', params).then((response) => {
          this.loading = false
          this.templates = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei template')
        })
      },
      resetFilters (pReload = true) {
        this.currentState.searchQuery = ''
        this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
        this.currentState.currentStatus = 0
        this.currentState.currentStatusLabel = 'Creata'
        this.currentState.currentCustomerType = -1
        this.currentState.searchTypeSelected = 0
        this.currentState.pagination.perPage = 25
        this.currentState.pagination.currentPage = 1
        this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
        this.clearSavedState()
        if (pReload) this.updateData()
      },
      sendNotification () {
        this.loading = true
        let params = {}
        params = Object.assign({}, params, {notification_id: this.currentTemplate, product_alert_ids: this.selectedAlerts})
        this.$store.dispatch('productAlerts/sendNotification', params).then((response) => {
          this.loading = false
          this.currentTemplate = null
          this.askGenericSuccess('Notifiche', 'Inviate con successo')
          this.updateData()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'invio delle notifiche')
        })
      },
      updateData (params) {
        this.loading = true
        if (!params) params = {}
        params = Object.assign(params, this.$store.getters.apiCommon, {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage, company: true})
        if (this.currentState.searchQuery !== '') {
          params = Object.assign(params, {search: this.currentState.searchQuery, search_fields: this.currentState.searchFields.selected, search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType})
        }
        if (this.currentState.currentSorting !== null) {
          params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
        }
        if (this.currentState.currentStatus !== -1 && !(this.currentState.searchFields.selected === 'id' && this.currentState.searchQuery !== '')) {
          params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, status: this.currentState.currentStatus, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
        }
        if (this.currentState.currentCustomerType >= 0 && !(this.currentState.searchFields.selected === 'id' && this.currentState.searchQuery !== '')) {
          params = Object.assign(params, {customer_type: this.currentState.currentCustomerType})
        }
        this.$store.dispatch('productAlerts/list', params).then((response) => {
          this.loading = false
          this.tableData = response.data.product_alerts.data
          this.currentState.pagination.total = response.data.product_alerts.total
          this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
          this.currentState.pagination.showedItems = response.data.count
          this.currentState.pagination.from = response.data.product_alerts.from
          this.currentState.pagination.to = response.data.product_alerts.to
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
      this.loadStatuses()
      this.loadTemplates()
      this.loadCustomerTypes()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
</style>
