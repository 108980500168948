import Config from '../../../globalConfig'
import axios from 'axios'

const paymentMethods = {
  namespaced: true,
  state: {
    types: [],
    cardCircuits: []
  },
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    types: state => state.types,
    cardCircuits: state => state.cardCircuits
  },
  mutations: {
    updateTypes: (state, pValue) => {
      state.types = pValue
    },
    updateCardCircuits: (state, pValue) => {
      state.cardCircuits = pValue
    }
  },
  actions: {
    reset ({commit}) {
      commit('updateTypes', [])
      commit('updateCardCircuits', [])
    },
    async edit ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.paymentMethods.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.paymentMethods.base + pId, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async decrypt ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        var orderId = params.order_id
        var pId = params.id
        var parameters = Object.assign({order_id: orderId}, rootGetters.apiCommon)
        axios.post(Config.endpoints.paymentMethods.base + pId + '/decrypt', parameters)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTypes ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        if (getters.types.length > 0) {
          resolve(getters.types)
          return
        }
        axios.post(Config.endpoints.paymentMethods.types, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateTypes', response.data.payment_method_types)
            resolve(response.data.payment_method_types)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getCardCircuits ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        if (getters.cardCircuits.length > 0) {
          resolve(getters.cardCircuits)
          return
        }
        axios.post(Config.endpoints.paymentMethods.cardCircuits, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateCardCircuits', response.data.card_circuits)
            resolve(response.data.card_circuits)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default paymentMethods
