<template>
    <div id="order-step-customer" v-loading="loading">
        <div class="row">
            <div class="col-12 text-center">
                <h4 class="m-0">Dettagli attivazione</h4>
            </div>
        </div>
        <el-form ref="form" v-model="model">

            <div class="row">
                <div class="col-12">
                    <el-form-item label="ID utente radius" v-loading="checkingUserIdentifier">
                        <el-input v-model="model.user_identifier" @blur="checkUserIdentifier"></el-input>
                    </el-form-item>
                </div>
                <div class="col-12">
                    <el-form-item label="MAC Address">
                        <el-input v-model="modelValue.router_mac_address"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <el-form-item label="">
                        <el-button icon="el-icon-plus" round @click.prevent="showAddOfferOption">Aggiungi opzione
                        </el-button>
                    </el-form-item>
                    <el-form-item label="Opzioni aggiunte">
                        <order-product-options-list v-if="modelValue.options" v-model="modelValue.options"
                                                    @deleted="reload"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Tipologia di attivazione">
                        <activation-type-select v-model="modelValue.operation_type"></activation-type-select>
                    </el-form-item>
                </div>
            </div>

            <div class="row" v-if="modelValue.operation_type === 2 || modelValue.operation_type === 4">
                <div class="col-12">
                    <el-form-item label="Codice migrazione">
                        <el-input v-model="modelValue.migration_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Operatore di provenienza">
                        <telephone-operator-select v-model="modelValue.original_operator"></telephone-operator-select>
                    </el-form-item>
                </div>
            </div>

            <div v-if="modelValue.operation_type === 3 || modelValue.operation_type === 4">

                <div v-if="!modelValue.np">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-center mt-4">
                            <h4 class="m-0 mr-2">Crea Portabilità</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <new-np-form :order-product-id="modelValue.id" :customer="customer" @created="npCreated"/>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-center mt-4">
                            <h4 class="m-0 mr-2">Modifica Portabilità</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <edit-np-form :np="modelValue.np" :customer="customer" @edit="npEdited"/>
                        </div>
                    </div>
                </div>


                <div class="col-12">
                    <hr>
                    <div class="col-12 text-center">
                        <label class="label">Foto della bolletta del vecchio operatore</label>
                        <old-bill-upload :order-id="modelValue.order_id" @upload="loadMedia"></old-bill-upload>
                    </div>
                    <div class="col-12 d-flex justify-content-center" v-if="documentLoading">
                        <h4 class="text-center pt-5 pb-5 m-0">Caricamento documenti <i class="el-icon-loading"></i></h4>
                    </div>
                    <div class="col-12 mt-4" v-if="oldBIlls.length > 0">
                        <order-media-list v-model="oldBIlls"
                                          @deleted="loadMedia" class="shadow"></order-media-list>
                    </div>
                    <div class="col-12 mt-4 justify-content-center" v-if="oldBIlls.length <= 0 && !documentLoading">
                        <h4 class="text-center pt-5 pb-5 m-0 shadow bg-light-gray">Nessun documento caricato</h4>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="saveAndBack()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="saveAndNext()">Avanti<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

        <!-- SOF DIALOG OPZIONI !-->
        <el-dialog title="Aggiungi opzione" v-model="displayAddOfferOption"
                   v-if="displayAddOfferOption && model" class="dialog-options">
            <div class="row">
                <div class="col-12"><h5>Aggiungi in questo ordine a
                    <strong>{{ modelValue.offer.internal_name }}</strong></h5></div>
            </div>

            <div class="row mt-4">
                <div class="col-12 pr-2">
                    <label>Scegli l'opzione</label>
                    <br/>
                    <el-select class="select-default mb-3 w-100" v-model="selectedOfferOption"
                               placeholder="Seleziona..." filterable size="large">
                        <el-option
                            v-for="item in offerOptions"
                            :key="item.id"
                            :label="item.id + ' - ' + item.internal_name"
                            :value="item.id">
                            <span style="float: left" v-if="item.internal_name">{{ item.internal_name }}</span><span
                            style="float: left" v-else>{{ item.name }}</span>
                            <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="text" class="mr-2" @click="displayAddOfferOption = false">Annulla</el-button>
                <el-button type="primary" @click.prevent="addOfferOption()">Aggiungi</el-button>
            </div>
        </el-dialog>
        <!-- EOF DIALOG OPZIONI !-->

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";
import IdentityDocumentSelect from "@/components/Inputs/IdentityDocumentTypeSelect";
import IssuerIdentityDocumentSelect from "@/components/Inputs/IssuerIdentityDocumentSelect";
import ActivationTypeSelect from "@/components/Inputs/ActivationTypeSelect";
import TelephoneOperatorSelect from "@/components/Inputs/TelephoneOperatorSelect";
import NpLineTypeSelect from "@/components/Inputs/NpLineTypeSelect";
import DocumentUpload from "@/components/Inputs/DocumentUpload";
import OrderMediaList from "@/components/Order/OrderMediaList";
import OldBillUpload from "@/components/Inputs/OldBillUpload";
import OrderProductOptionsList from "@/components/OrderProduct/OrderProductOptionList";
import Swal from "sweetalert2";
import NewNpForm from "@/components/Np/NewNpForm";
import EditNpForm from "@/components/Np/EditNpForm";

export default {
    name: "step-offer-details",
    components: {
        EditNpForm,
        NewNpForm,
        OrderProductOptionsList,
        OldBillUpload,
        NpLineTypeSelect,
        TelephoneOperatorSelect,
        ActivationTypeSelect,
        IssuerIdentityDocumentSelect,
        IdentityDocumentSelect,
        GiuridicFormSelect,
        ProvinceSelect,
        DocumentUpload,
        OrderMediaList
    },
    data() {
        return {
            loading: false,
            documentLoading: false,
            oldBIlls: [],
            model: {},
            offerOptions: null,
            displayAddOfferOption: false,
            selectedOfferOption: null,
            checkingUserIdentifier: false,
            validUserIdentifier: false
        }
    },
    props: {
        modelValue: {
            type: Object
        },
        customer: {
            type: Object
        }
    },
    mounted() {
        this.model = this.modelValue

        this.loadMedia()

        if (this.modelValue.user_identifier) {
            this.validUserIdentifier = true;
        }
    },
    methods: {
        saveAndNext() {

            if (!this.validUserIdentifier) {
                this.showErrorMessage("L'utente radius non è valido.")
                return;
            }
            this.$emit("input", this.model)
            if (this.modelValue.operation_type === 3 || this.modelValue.operation_type === 4) {
                if (!this.modelValue.np || !this.modelValue.np.id) {
                    this.showErrorMessage("Non puoi proseguire. Devi compilare tutti i dati per la portabilità. Se non lo hai già fatto, devi confermare premendo Inserisci o Salva dati portabilità.")
                    return;
                }
                this.updateOrderProduct().then(() => {
                    this.$emit('next')
                })
                return
            }
            this.updateOrderProduct().then(() => {
                this.$emit('next')
            })
        },
        saveAndBack() {
            if (this.modelValue.operation_type === 3 || this.modelValue.operation_type === 4) {

                if (!this.modelValue.np || !this.modelValue.np.id) {
                    this.showErrorMessage("Non puoi proseguire. Devi compilare tutti i dati per la portabilità. Se non lo hai già fatto, devi confermare premendo Inserisci o Salva dati portabilità.")
                    return;
                }

                this.updateOrderProduct().then(() => {
                    this.$emit('back')
                })
                return
            }
            this.updateOrderProduct().then(() => {
                this.$emit('back')
            })
        },
        updateOrderProduct() {
            this.loading = true

            return this.$store.dispatch('orders/orderProducts/edit', this.model).then((response) => {
                // this.$notify({
                //   title: 'Success',
                //   message: 'This is a success message',
                //   type: 'success',
                //   icon: 'fa fa-check'
                // });
                this.loading = false
            }).catch(e => {
                this.$notify({
                    title: 'Error',
                    message: 'This is a success message',
                    type: 'error',
                    duration: 0
                });
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la modifica dell\'offerta per questo prodotto')
            })
        },
        createMnpForProduct() {
            this.$store.dispatch('orders/mnp/add', this.model).then((response) => {
                this.modelValue.mnp = response.data.mnp
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiunta della MNP')
            })
        },
        loadMedia() {
            this.documentLoading = true;
            this.$store.dispatch('orders/medias/getOldBillDocuments', {orderId: this.modelValue.order_id}).then((response) => {
                this.oldBIlls = response.data.data
                this.documentLoading = false;
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei documenti.', true)
                this.documentLoading = false;
            })
        },
        reload() {
            this.$store.dispatch('orders/orderProducts/getById', this.modelValue.id).then((response) => {
                this.model = response.data.order_product
                this.$emit('input', this.model)
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei dati.', true)
            })
        },
        showAddOfferOption() {
            this.$store.dispatch('offers/getById', this.modelValue.offer.id).then((response) => {
                this.offerOptions = response.data.data.options
                if (this.offerOptions.length < 1) {
                    this.showErrorMessage('Questa offerta non ha opzioni compatibili da poter aggiungere.')
                } else {
                    this.selectedOfferOption = null
                    this.displayAddOfferOption = true
                }
            }).catch(e => {
                this.showResponseErrorMessage(e)
            })
        },
        addOfferOption() {
            this.$store.dispatch('orders/orderProducts/addOption', {
                id: this.modelValue.id,
                option_id: this.selectedOfferOption
            }).then((response) => {
                this.showGenericSuccess('Opzione', 'aggiunta con successo', '1000')
                this.displayAddOfferOption = false
                this.offerOptions = []
                this.selectedOfferOption = null
                this.modelValue.options = response.data.order_product_options;
                this.$emit('input', this.model)
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiunta delle opzioni di questa offerta')
            })
        },
        checkUserIdentifier() {
            this.checkingUserIdentifier = true;
            this.validUserIdentifier = false;

            if (this.modelValue.user_identifier.length < 3) {
                this.showErrorMessage("L'utente radius deve essere di almeno 3 caratteri.")
                return;
            }
            this.$store.dispatch('utility/checkUserIdentifier', this.modelValue.user_identifier).then((response) => {
                this.checkingUserIdentifier = false;

                if (response.data.data.error) {
                    if (response.data.data.reason === 'utente_esistente_già_abbinato_ad_altro_cliente') {
                        this.modelValue.user_identifier = null;
                        this.showErrorMessage("L'utente radius inserito è già abbinato ad un altro utente.")
                        this.validUserIdentifier = false;
                    } else {
                        return Swal.fire(
                            {
                                title: "Attenzione!",
                                html: "Sei sicuro che l'utente radius <code>" + this.modelValue.user_identifier + "</code> sia corretto?<br><small>Reason: " + response.data.data.reason + "</small>",
                                icon: 'warning',
                                showCancelButton: true,
                                reverseButtons: true,
                                confirmButtonText: 'SI',
                                cancelButtonText: 'NO'
                            }).then(confirmation => {
                            if (confirmation.isConfirmed) {
                                this.validUserIdentifier = true;
                            } else {
                                this.validUserIdentifier = false;
                            }
                        })
                    }
                } else {
                    this.validUserIdentifier = true;
                }
            }).catch(e => {
            })
        },
        npCreated(np) {
            this.modelValue.np = np;
        },
        npEdited(np) {
            this.modelValue.np = np;
        }
    }
}
</script>

<style scoped>

</style>
