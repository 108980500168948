<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model=selectedIndex
                   placeholder="Seleziona un tipo offerta"
                   @change="updateValue($event)"
                   :multiple="multiple"
                   filterable
                   reserve-keyword
                   value-key="name">
            <el-option
                v-for="(item, key) in items"
                :key=key
                :label="item.name"
                :value=key>
                <span class="float-left">{{ item.name }}</span>
                <span class="small n-lightgrey float-right">{{ item.code }}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "OfferTypeSelect",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            selectedIndex: null,
            selected: null
        }
    },
    props: {
        value: {
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.loadItems()
        if (this.multiple) {
            this.selected = []
        }
    },
    methods: {
        loadItems() {
            this.loading = true
            let params = {};

            this.$store.dispatch('offers/getTypes').then((response) => {
                this.loading = false
                this.items = response
            }).catch(e => {
                this.loading = false
                this.showResponseErrorMessage(e)
            })
        },
        updateValue: function (item) {
            if (Array.isArray(item)) {
                item.forEach(index => {
                    this.selected.push(this.items[index])
                });
            } else {
                this.selected = this.items[item];
            }
            this.$emit('update:modelValue', this.selected)
        }

    }
}
</script>

<style scoped>

</style>
