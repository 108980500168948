<template>
  <div v-loading="loading">
    <el-upload
      :action="attachmentAddAction"
      :on-success="handleAttachmentSuccess"
      :on-error="handleAttachmentError"
      :before-upload="handleBeforeUpload"
      :data="uploadFileData"
      :on-progress="handleProgress"
      :show-file-list="false"
      drag>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Trascina qui il file da caricare <em>o clicca per sceglierlo</em></div>
    </el-upload>
  </div>
</template>

<script>

export default {
  name: "old-bill-upload",
  components: {
  },
  data() {
    return {
      loading: false,
      attachmentType: 4, //Documento
      attachment: null,
      fileList: []
    }
  },
  props: {

    orderId: {
      type: Number
    }
  },
  computed: {
    attachmentAddAction: function () {
      if (!this.orderId) {
        return false
      }
      return this.$config.endpoints.orders.base + this.orderId + '/medias/add-old-operator-bill'
    },
    uploadFileData: function () {
      return Object.assign({media_type: this.attachmentType}, this.$store.getters.apiCommon)
    }
  },
  methods: {
    handleBeforeUpload(file) {
      this.loading = true;
    },
    handleProgress(event, file, fileList){
    },
    handleAttachmentError(err, file, fileList) {
      this.showErrorMessage("Errore durante il caricamento del file.")
      this.loading = false;
    },
    handleAttachmentSuccess(response, file, fileList) {
      if (!response.result) {
        this.showErrorMessage("Errore durante il caricamento del file.")
        this.loading = false;
        return false
      }
      this.$message.success("File caricato correttamente.")
      this.attachment = response.data;
      this.fileList = [{
        name: this.attachment.original_name,
        url: this.attachment.url
      }]
      this.$emit('upload', this.attachment)
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>
