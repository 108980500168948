<template>
    <div v-loading="loading">
        <h4>Dettagli Ordine</h4>

        <div class="row">
            <div class="col-12 col-md-3">
                <el-input type="number"
                          label="ID Ordine"
                          placeholder="#Ordine"
                          name="id"
                          disabled
                          v-model="order.id">
                </el-input>
            </div>
            <div class="col-12 col-md-3">
                <label>Stato</label>
                <br/>
                <el-select filterable v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="order.status"
                           placeholder="..." size="large">
                    <el-option-group v-for="mother in statuses" :key="mother.id + 'group'" :label="mother.internal_name">
                        <el-option
                            class="select-default"
                            :key="mother.id"
                            :label="mother.internal_name"
                            :value="mother.id">
                        </el-option>
                        <el-option
                            v-for="item in mother.child_statuses"
                            class="select-default"
                            :key="item.id"
                            :label="item.internal_name"
                            :value="item.id">
                        </el-option>
                    </el-option-group>
                </el-select>
            </div>
            <div class="col-12 col-md-3">
                <label>Tipo</label>
                <br/>
                <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="order.type"
                           placeholder="..." size="large">
                    <el-option
                        v-for="item in types"
                        class="select-default"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code">
                    </el-option>
                </el-select>
            </div>
            <div class="col-12 col-md-3">
                <label>Data Attivazione</label>
                <br/>
                <el-date-picker
                    v-model="order.activation_date"
                    type="datetime"
                    placeholder="Datetime picker here"
                    label="Data Attivazione"
                    :picker-options="datetimePickerOptions"
                    format="DD-MM-YYYY HH:mm:ss"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    language="it">
                </el-date-picker>
            </div>
        </div>
        <hr>
        <div class="row">

        </div>


        <div class="row mb-3 mt-4">
            <div class="col-12">
                <div class="text-center">

                    <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteOrder" v-if="order.id && canDeleteOrder">
                        Elimina ordine
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>
<style>
</style>
<script>
import { Switch as LSwitch, Checkbox as LCheckbox } from 'src/components/index'
export default {
    mixins: [],
    components: {
        LSwitch,
        LCheckbox
    },
    props: {
        order: {}
    },
    data () {
        return {
            componentSettings: {
                myComponentName: 'OrderGeneralDetails'
            },
            loading: false,
            statuses: [],
            types: [],
            datetimePickerOptions: {}
        }
    },
    watch: {
        order () {
        }
    },
    computed: {},
    methods: {
        deleteOrder () {
            this.$emit('delete', this.order)
        },
        canDeleteOrder () {
            return this.$store.getters['orders/canDelete'](this.order)
        }
    },
    created: function () {
        this.statuses = this.$store.getters['orders/statuses/statuses']
        this.types = this.$store.getters['orders/types']
    },
    mounted: function () {},
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
