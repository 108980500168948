<template>
  <div>
    <ValidationObserver v-slot="{handleSubmit}">

      <form @submit.prevent="handleSubmit(validateForm)">
        <div class="row mt-3">
          <div class="col-6 col-md-2">
            <el-input type="number"
                      label="Priorità"
                      name="prority"
                      v-model="product.priority"
                      style="width: 80px;">
            </el-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <ValidationProvider v-slot="{errors}" rules="required">
              <el-input type="text"
                        label="Nome al pubblico"
                        placeholder="Nome al pubblico"
                        v-model="product.product_name"
                        name="product_name">
              </el-input>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-12 col-md-4">
            <ValidationProvider v-slot="{errors}" rules="required">
              <el-input type="text"
                        label="Nome interno"
                        placeholder="Nome interno"
                        v-model="product.internal_name"
                        name="internal_name">
              </el-input>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-12 col-md-4">
            <el-input type="text"
                      label="ID H3G"
                      placeholder="ID H3G"
                      v-model="product.id_h3g">
            </el-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-4">
            <label>Tipologia</label>
            <br/>
            <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="product.type"
                       placeholder="..." size="large">
              <el-option
                v-for="item in types"
                class="select-default"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>

          <div class="col-12 col-md-4">
            <label>Marca</label>
            <br/>
            <el-autocomplete
              style="width: 100%"
              v-loading="loading"
              v-model="product.marca"
              :debounce="zeroDebounce"
              :fetch-suggestions="getBrands"
              placeholder="Scrivi qui dentro"
              name="marca"
            ></el-autocomplete>
          </div>

          <div class="col-12 col-md-4">
            <label>Categoria</label>
            <br/>
            <el-autocomplete
              style="width: 100%"
              v-loading="loading"
              v-model="product.category"
              :fetch-suggestions="getCategories"
              :debounce="zeroDebounce"
              placeholder="Scrivi qui dentro"
              name="category"
            ></el-autocomplete>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-4">
            <el-input type="text"
                      label="Capacità"
                      placeholder="Capacità"
                      v-model="product.capacity">
            </el-input>
          </div>

          <div class="col-12 col-md-4">
            <ValidationProvider v-slot="{errors}" rules="required">
              <el-input type="text"
                        label="Quantità disponibile"
                        placeholder="Quantità disponibile"
                        v-model="product.quantita_disponibile"
                        name="quantita_disponibile">
              </el-input>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-4 col-12">
            <label>SIM Sciolta</label>
            <br/>
            <l-switch v-model="product.sim_sciolta" type="primary" on-text="SÌ" off-text="NO"></l-switch>
          </div>

        </div>

        <div class="row">
          <div class="col-12 col-md-4">
            <el-input type="text"
                      label="MPN"
                      placeholder="MPN"
                      v-model="product.mpn">
            </el-input>
          </div>
          <div class="col-12 col-md-4">
            <el-input type="text"
                      label="EAN"
                      placeholder="EAN"
                      v-model="product.ean">
            </el-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <el-input type="text"
                      label="URL su Tre.it"
                      placeholder="samsung-galaxy-s10-plus-128gb"
                      v-model="product.treit_url">
            </el-input>
          </div>
        </div>


        <div class="row" v-if="product.id" id="productImagesSection">
          <div class="col-12 mb-3">
            <label>Immagini prodotto</label>
            <el-upload
              :action="productMediaAction"
              :on-preview="handleImagePreview"
              :on-success="handleProductImageSuccess"
              :on-error="handleProductImageError"
              :file-list="productMedia"
              :before-remove="handleProductImageRemove"
              :data="productImageData"
              accept="image/*"
              name="image"
              drag
              list-type="picture-card">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>


        <div class="row" v-if="product.id">
          <div class="col-12 mb-3">
            <label>Colori prodotto <a data-tooltip="'Aggiungi colore'" class="btn-simple btn-link"
                                      @click="addColor"><i class="fa fa-plus"></i></a></label>
            <br/>
            <div class="color-list" v-for="color in product.colors">

              <div class="mb-1">
                <div class="color-detail" v-bind:style="{'background-color':  color.color_detail.hex}"></div>
                <div class="color-name">{{color.color_detail.name}}</div>
                <span v-if="color.media_id"><a data-tooltip="'Mostra immagine'" class="btn-simple btn-link"
                                               @click="handleImagePreview(color.media)"><i
                  class="fa fa-image"></i></a></span>
                <a data-tooltip="'Elimina colore'" class="btn-danger btn-simple btn-link"
                   @click="deleteProductColor(color.color_detail.id)"><i class="fa fa-times"></i></a>
              </div>
            </div>
          </div>
        </div>

        <el-dialog title="Colori" v-model="newColorFormVisibile">

          <div class="row justify-content-center">
            <div class="col-6 col-md-6">
              <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="newColor"
                         filterable
                         placeholder="..." size="large" @change="changedColor">

                <el-option
                  class="select-default"
                  :key=0
                  label="Seleziona un colore esistente..."
                  :value=0>
                </el-option>

                <el-option
                  v-for="item in colors"
                  class="select-default"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div class="col-3 col-md-3 pl-0" style="max-width: 70px;" v-if="newColor !== 0">
              <el-button type="default" style="margin-bottom: 20px; width: 100%;" class="pl-0 pr-0"
                         @click="editColor = true" data-tooltip="'Modifica colore corrente'"><i
                class="el-icon-edit"></i></el-button>
            </div>

            <div class="col-3 col-md-3 pl-0" style="max-width: 70px;">
              <el-button type="primary" style="margin-bottom: 20px; width: 100%;" class="pl-0 pr-0"
                         @click="createNewColor()" data-tooltip="'Crea nuovo colore'"><i
                class="el-icon-plus"></i>
              </el-button>
            </div>

          </div>


          <div class="row justify-content-center pt-4 pb-4" v-if="showColorForm">
            <div class="col-12 col-md-9 pt-3 pb-3" style="border-radius: 6px; background-color: #f8f8f8">
              <div class="row justify-content-center">
                <div class="col-9">
                  <el-input type="text"
                            label="Nome del colore"
                            placeholder="Nome"
                            v-model="newColorCreation.name">
                  </el-input>
                </div>
                <div class="col-3" style="max-width: 50px;">
                  <label>Colore</label>
                  <br/>
                  <el-color-picker v-model="newColorCreation.color" style="margin: 0 auto;"></el-color-picker>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12 col-md-9 p-0 text-center">
                  <button class="btn btn-primary" style="margin-bottom: 20px" @click.prevent="saveColor()">Salva colore
                  </button>
                  <button class="btn btn-danger" style="margin-bottom: 20px" @click.prevent="deleteColor()"
                          v-if="newColorCreation.id">Elimina colore
                  </button>
                  <button class="btn btn-default" style="margin-bottom: 20px" @click.prevent="closeColor">Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div class="row justify-content-center" v-if="!editColor && !showColorForm && newColor !== 0">
            <div class="col-12 col-md-9 upload-product-media">
              <el-upload
                :action="productColorAction"
                :on-preview="handleImagePreview"
                :on-success="handleColorImageSuccess"
                :on-error="handleColorImageError"
                :before-upload="checkColorImage"
                :file-list="colorMedia"
                :data="colorImageData"
                accept="image/*"
                name="color_image"
                :limit=1
                drag
                style="text-align: center;"
                class="upload-product-media">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text col-12 pl-5 pr-5">Trascina qui il file con l'immagine di <b>{{product.internal_name}}</b>
                  per questo colore <em>o clicca per sceglierlo</em></div>
              </el-upload>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
          <el-button @click="newColorFormVisibile = false">Chiudi</el-button>
          <el-button type="primary" @click="addColorConfirm()" v-if="canAddColor && !showColorForm && newColor !== 0">Aggiungi al prodotto</el-button>
        </span>

        </el-dialog>


        <div class="row">
          <div class="col-12">
            <label>Abilitazioni di questo prodotto</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 col-12">
            <label>Partner</label>
            <br/>
            <l-switch v-model="product.abilitato_partner" type="primary" on-text="SÌ" off-text="NO"></l-switch>
          </div>

          <div class="col-md-3 col-12">
            <label>Sito 3</label>
            <br/>
            <l-switch v-model="product.abilitato_h3g" type="primary" on-text="SÌ" off-text="NO"></l-switch>
          </div>

          <div class="col-md-3 col-12">
            <label>3Store</label>
            <br/>
            <l-switch v-model="product.abilitato_3store" type="primary" on-text="SÌ" off-text="NO"></l-switch>
          </div>


          <div class="col-md-3 col-12">
            <label>Carrelli</label>
            <br/>
            <l-switch v-model="product.abilitato" type="primary" on-text="SÌ" off-text="NO"></l-switch>
          </div>

        </div>


        <div class="row">
          <div class="col-12">
            <label>Descrizione</label>
            <br/>
            <editor style="height: 200px;" v-model="product.descrizione" :init="$config.tinyMCEConfig"></editor>
          </div>
        </div>


        <div class="row" style="margin-top: 30px">
          <div class="col-12 text-center">

            <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement"
                    v-if="product.id">
              Elimina prodotto
            </button>

            <button type="submit" class="btn btn-primary btn-fill btn-wd">
              Salva prodotto
            </button>

          </div>
        </div>

        <div class="clearfix"></div>
      </form>

    </ValidationObserver>
  </div>
</template>
<style>
  .upload-product-media .el-upload-dragger {
    height: auto !important;
  }

  #productImagesSection .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: auto;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
</style>
<script>
  import {Switch as LSwitch} from 'src/components/index'
  import Editor from '@tinymce/tinymce-vue'
  import Swal from "sweetalert2";

  export default {
    components: {
      LSwitch,
      'editor': Editor
    },
    props: {
      product: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/prodotti/lista'
        },
        newColorCreation: {
          name: '',
          color: null,
          id: null
        },
        addNewColor: false,
        editColor: false,
        canAddColor: true,
        newColor: 0,
        newColorFormVisibile: false,
        formLabelWidth: '120px',
        zeroDebounce: 0,
        content: '',
        loading: false,
        categories: [],
        brands: [],
        types: [],
        colors: [],
        productMedia: [],
        colorMedia: []
      }
    },
    watch: {
      description: function () {
        this.$refs.productDescription.quill.root.blur()
      },
      product: function () {
        this.productMedia = []
        if (!this.product.medias || this.product.medias.length < 1) return
        for (let element of this.product.medias) {
          if (element.original_name) {
            this.productMedia.push({url: element.url, name: element.original_name, media_id: element.id})
          } else {
            this.productMedia.push({url: element.url, name: 'Immagine ' + element.id, media_id: element.id})
          }
        }
      }
    },
    computed: {
      productColorAction: function () {
        if (!this.product.id) return false
        return this.$config.endpoints.products.base + this.product.id + '/colors/add'
      },
      productMediaAction: function () {
        if (!this.product.id) return false
        return this.$config.endpoints.products.base + this.product.id + '/images/add'
      },
      productImageData: function () {
        return this.$store.getters.apiCommon
      },
      colorImageData: function (pNewColor) {
        if (!pNewColor.newColor) return {}
        var params = Object.assign({color_id: pNewColor.newColor}, this.$store.getters.apiCommon)
        return params
      },
      showColorForm: function () {
        return (((this.newColorCreation.id || this.addNewColor) && this.newColor === 0) || this.editColor)
      }
    },
    methods: {
      createNewColor () {
        this.addNewColor = true
        this.newColorCreation.name = ''
        this.newColorCreation.hex = null
        this.newColorCreation.id = null
        this.newColor = 0
      },
      addColorToList () {
        this.loading = true
        this.$store.dispatch('colors/add', this.newColorCreation).then((response) => {
          this.loading = false
          this.loadColors(true)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiunta del colore', '', '', false)
        })
      },
      closeColor () {
        this.addNewColor = false
        this.newColor = 0
        this.editColor = false
        this.newColorCreation.id = 0
      },
      saveColor () {
        if (!this.newColorCreation.id) {
          this.addColorToList()
          return
        }
        this.loading = true
        this.$store.dispatch('colors/edit', this.newColorCreation).then((response) => {
          this.loading = false
          this.editColor = false
          this.loadColors(true)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'la modifica del colore', '', '', false)
        })
      },
      changedColor () {
        this.addNewColor = false
        this.newColorCreation.name = ''
        this.newColorCreation.hex = null
        this.newColorCreation.id = null
        for (let element of this.colors) {
          if (this.newColor === element.id) {
            this.newColorCreation.color = element.hex
            this.newColorCreation.name = element.name
            this.newColorCreation.id = element.id
            break
          }
        }
      },
      checkColorImage () {
        if (this.newColor < 1) {
          this.showLoadingError('Devi prima selezionare un colore.', 'l\'aggiunta della foto', '', '', false)
          return false
        } else {
          this.canAddColor = false
        }
      },
      addColor () {
        this.loadColors()
        this.canAddColor = true
        this.newColor = 0
        this.newColorFormVisibile = true
        this.colorMedia = []
        this.newColorCreation.name = ''
        this.newColorCreation.hex = null
        this.newColorCreation.id = null
        this.addNewColor = false
      },
      deleteColor () {
        Swal.fire(this.deleteMessage('il colore ' + this.newColorCreation.name)).then((result) => {
          if (!result.value) {
            return
          }
          this.loading = true
          this.$store.dispatch('colors/delete', this.newColorCreation).then((response) => {
            this.loading = false
            this.closeColor()
            this.loadColors(true)
            this.showGenericSuccess('Colore', 'eliminato con successo')
          }).catch(e => {
            this.loading = false
            this.showLoadingError(e, 'l\'eliminazione del colore', '', '', false)
          })
        })
      },
      deleteProductColor (pColorId) {
        Swal.fire(this.deleteMessage('questo colore')).then((result) => {
          if (!result.value) {
            return
          }
          this.loading = true
          this.$store.dispatch('products/deleteColor', {
            colorId: pColorId,
            productId: this.product.id
          }).then((response) => {
            this.loading = false
            this.$emit('updateColors', response.data.colors)
          }).catch(e => {
            this.loading = false
            this.showLoadingError(e, 'l\'eliminazione del colore', '', '', false)
          })
        })
      },
      addColorConfirm () {
        if (this.newColor < 1) {
          this.showLoadingError('Devi selezionare un colore.', 'l\'aggiunta del colore', '', '', false)
          return false
        }
        this.loading = true
        this.$store.dispatch('products/addColor', {
          colorId: this.newColor,
          productId: this.product.id
        }).then((response) => {
          this.loading = false
          this.newColorFormVisibile = false
          this.$emit('updateColors', response.data.colors)
        }).catch(e => {
          this.canAddColor = true
          this.loading = false
          this.showLoadingError(e, 'l\'aggiunta del colore', '', '', false)
        })
      },
      handleColorImageError (err, file, fileList) {
        this.showLoadingError(err, 'il caricamento del file', '', '', false)
      },
      handleProductImageError (err, file, fileList) {
        this.showLoadingError(err, 'il caricamento del file', '', '', false)
      },
      handleColorImageSuccess (response, file, fileList) {
        var index = -1
        if (!response.result) {
          this.canAddColor = true
          index = fileList.indexOf(file)
          fileList.splice(index, 1)
          if (this.newColor < 1) {
            this.showLoadingError('Devi selezionare un colore.', 'l\'aggiunta del colore', '', '', false)
            return false
          }
          this.showLoadingError(response, 'il caricamento del file', '', '', false)
          return false
        }
        index = fileList.indexOf(file)
        if (index === -1) {
          this.newColorFormVisibile = false
          this.colorMedia = []
          this.$emit('updateColors', [])
          return
        }
        if (typeof response.colors[index].media !== 'undefined') file.media_id = response.colors[index].media.id
        this.newColorFormVisibile = false
        this.colorMedia = []
        this.$emit('updateColors', response.colors)
      },
      handleProductImageSuccess (response, file, fileList) {
        if (!response.result || !response.media.id) {
          var index = fileList.indexOf(file)
          fileList.splice(index, 1)
          this.showLoadingError(response, 'il caricamento del file', '', '', false)
          return false
        }
        file.media_id = response.media.id
      },
      getCategories (queryString, cb) {
        cb(this.categories)
      },
      getBrands (queryString, cb) {
        let results = queryString ? this.brands.filter(this.filterBrands(queryString)) : this.brands
        cb(results)
      },
      filterBrands (queryString) {
        return (brand) => {
          return (brand.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      deleteElement () {
        this.$emit('delete', this.product)
      },
      validateForm () {
        this.$emit('update', this.product)
      },
      loadColors (pForce = false) {
        this.newColorCreation.name = ''
        this.newColorCreation.hex = null
        this.newColorCreation.id = null
        this.addNewColor = false
        if (this.colors.length > 0 && !pForce) return
        this.loading = true
        this.$store.dispatch('colors/list').then((response) => {
          this.loading = false
          this.newColor = 0
          this.colors = response.data.colors
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento dei colori')
        })
      },
      loadCategories () {
        this.loading = true
        this.$store.dispatch('products/getCategories').then((response) => {
          this.loading = false
          this.categories = response.data.product_categories
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle categorie')
        })
      },
      loadBrands () {
        this.loading = true
        this.$store.dispatch('products/getBrands').then((response) => {
          this.loading = false
          this.brands = response.data.brands
          this.loadCategories()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle marche')
        })
      },
      loadTypes () {
        this.loading = true
        this.$store.dispatch('products/getTypes').then((response) => {
          this.loading = false
          this.types = response.data.product_types
          this.loadBrands()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle tipologie')
        })
      },
      loadResources () {
        this.loadTypes()
      },
      handleProductImageRemove (pFile, pFileList) {
        return new Promise((resolve, reject) => {
          Swal.fire(this.deleteMessage('questa immagine')).then((result) => {
            if (!result.value) {
              reject(false)
              return
            }
            this.$store.dispatch('medias/delete', pFile).then(() => {
              resolve(true)
            }).catch((e) => {
              reject(false)
              this.showLoadingError(e, 'l\'eliminazione dell\'immagine', '', false)
            })
          })
        })
      }
    },
    created: function () {
      this.loadResources()
    },
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
