<template>
    <div id="order-step-offer" v-loading="loading">

        <div class="row">
            <div class="col-12 col-md-6">
                <label>Seleziona la tipologia</label>
                <OfferTypeSelect v-model="offerTypeObject"/>
            </div>
        </div>

        <div class="row" style="padding-top: 20px;">
            <div class="col-12 col-md-6">
                <label>Seleziona l'offerta</label>
                <OffersSelect v-model="offer" v-bind:offertype="offertype" :show_type="false"
                              :show_customer_name="false"/>
            </div>
        </div>

        <div v-if="offer">
            <div class="row mt-4" v-if="offer">
                <OfferDetails v-bind:offer="offer"/>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6">
                    <label>Seleziona il metodo di pagamento</label>
                    <PaymentMethodTypeSelect v-model="selectedPaymentMethod"></PaymentMethodTypeSelect>
                </div>
                <div class="col-12 col-md-6" v-if="selectedPaymentMethod">
                    <label>Seleziona la periodicità della fatturazione</label>
                    <billing-period-select v-model="selectedBillingPeriod"/>
                    <small>(per wholesale è sempre mensile)</small>
                </div>
            </div>

        </div>

        <div v-if="selectedPaymentMethod">
            <div class="row mt-4">
                <div class="col-6">
                    <label>Partner</label>
                    <partner-select v-model="origin"/>
                </div>
                <div class="col-6">
                    <label>Codice amico</label>
                    <el-input v-model="referer_code"/>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-end">
            <div class="col-auto">
                <el-button type="primary" icon="arrow-right" @click="createEmptyOrder"
                           :disabled="!selectedBillingPeriod || loading">Avanti
                </el-button>
            </div>
        </div>

    </div>

</template>

<script>
import OffersSelect from "@/components/Inputs/OffersSelect";
import OfferTypeSelect from "@/components/Inputs/OfferTypeSelect";
import OfferDetails from "@/components/Offer/OfferDetails";
import PaymentMethodTypeSelect from "@/components/Inputs/PaymentMethodTypeSelect";
import BillingPeriodSelect from "@/components/Inputs/BillingPeriodSelect";
import PartnerSelect from "@/components/Inputs/PartnerSelect";

export default {
    name: "StepOffer",
    components: {
        PartnerSelect,
        OfferDetails,
        OfferTypeSelect,
        BillingPeriodSelect,
        PaymentMethodTypeSelect,
        OffersSelect,
    },
    data() {
        return {
            offerTypeObject: {code: 1, name: 'FWA Dimensione'},
            loading: false,
            offer: null,
            selectedPaymentMethod: null,
            selectedBillingPeriod: null,
            pay_upfront_cash: false,
            referer_code: null,
            show_all_offers: false,
            origin: 'console'
        }
    },
    computed: {
        offertype() {
            if (!this.offerTypeObject) return '1';
            return this.offerTypeObject.code.toString();
        }
    },
    created() {
    },
    methods: {
        createEmptyOrder() {
            this.$store.dispatch('orders/addEmpty', {
                offer_id: this.offer.id,
                payment_method: this.selectedPaymentMethod,
                billing_period: this.selectedBillingPeriod,
                pay_upfront_cash: this.pay_upfront_cash,
                referer_code: this.referer_code,
                origin: this.origin
            }).then((response) => {
                this.loading = false
                this.$emit('orderCreated', response.data.order)
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'la creazione di questo ordine', '', '', false)
            })
        }
    }
}
</script>

<style scoped>

</style>
