<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Monitoraggio Inbound">


        <div class="table-layout-container">


          <div class="row mt-4">
            <div class="col-12 col-md-3">
              <el-input style="width: 100%" type="search" placeholder="Numero interno" v-model="currentState.internal" addon-right-icon="fa fa-search" />
            </div>

            <div class="col-12">
              <h4 class="text-center mt-2">Stai visualizzando le chiamate: {{internalLabel}}</h4>
              <el-table class="table-striped" :data="tableData" border style="width: 100%">

                <template v-for="column in tableColumns">
                  <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                  <el-table-column v-else-if="column.type === 'selection'" type="selection" :width="calculateTableWidth(column)"></el-table-column>

                  <el-table-column v-else-if="column.custom === 'numero'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <router-link class="dark-link font-weight-bold" style="text-decoration: underline" :to="{path:'/ordini/lista/telefono/' + props.row.chiamante}"><span v-if="props.row">{{props.row.chiamante}}<br /></span></router-link>
                      <span v-if="props.row.ordine">ID ordine: </span><router-link class="dark-link font-weight-bold" style="text-decoration: underline" :to="{path:'/ordini/lista/modifica/' + props.row.ordine}"><span v-if="props.row && props.row.ordine">{{props.row.ordine}}<br /></span></router-link>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'centralino'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <span v-if="props.row">{{props.row.interno}}<br /></span>
                      <span v-if="props.row">{{props.row.nome_interno}}<br /></span>
                      <span v-if="props.row">{{props.row.durata_chiamata}}</span>
                    </template>
                  </el-table-column>
                </template>

              </el-table>
            </div>
          </div>



        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
    },
    computed: {
      internalLabel () {
        if (!this.currentState.internal) return 'Tutte'
        return this.currentState.internal
      }
    },
    data () {
      return {
        intervalID: null,
        componentSettings: {
          myComponentName: 'InboundList',
          minimumPermissionLevel: 200
        },
        currentState: {
          internal: null
        },
        tableColumns: [
          {
            label: 'Numero',
            percentWidth: 33,
            sortable: false,
            showTooltip: true,
            fixed: true,
            type: 'default',
            first: true,
            custom: 'numero'
          },
          {
            prop: 'nome_chiamante',
            label: 'Nome',
            percentWidth: 33,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: false
          },
          {
            label: 'Centralino',
            percentWidth: 34,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'centralino'
          }
        ],
        tableData: [],
        defaultValue: null,
        canShowView: false
      }
    },
    methods: {
      getSaveStateConfig () {
        return {
          'cacheKey': this.componentSettings.myComponentName,
          'saveProperties': ['currentState']
        }
      },
      updateData () {
        let params = {}
        if (this.currentState.internal) {
          params = Object.assign({}, {interno: this.currentState.internal})
        }
        this.$store.dispatch('inbound/list', params).then((response) => {
          this.tableData = response.data.active_calls
        }).catch(e => {
          this.showLoadingError(e, 'il caricamento delle chiamate')
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
      this.intervalID = setInterval(() => this.updateData(), 3000)
    },
    destroyed: function () {
      this.$off()
      clearInterval(this.intervalID)
    }
  }
</script>
<style></style>
