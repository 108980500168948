<template>
    <div v-loading="loading" v-if="shipping_address">

        <h4>Indirizzo di spedizione</h4>

        <el-form label-position="top">
            <div class="row">
                <div class="col-12" v-if="!shipping_address.verified">
                    <el-button @click="copyAddressFromCustomer">Copia indirizzo dalla residenza
                    </el-button>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Destinatario">

                        <el-input type="text"
                                  label="Destinatario"
                                  name="destinatario"
                                  v-model="shipping_address.destinatario"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-6">
                    <el-form-item label="Presso">

                        <el-input type="text"
                                  label="Presso"
                                  name="presso"
                                  v-model="shipping_address.presso"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-6">
                    <el-form-item label="Telefono">

                        <el-input type="text"
                                  label="Telefono"
                                  name="telefono"
                                  v-model="shipping_address.telefono"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-6">
                    <el-form-item label="Email">
                        <el-input type="text"
                                  label="Email"
                                  name="email"
                                  v-model="order.customer.email" disabled="true">
                        </el-input>
                    </el-form-item>
                </div>

            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <el-form-item label="spedizione">

                        <el-input type="text"
                                  label="Indirizzo di spedizione"
                                  name="indirizzo"
                                  v-model="shipping_address.indirizzo"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-2">
                    <el-form-item label="Civico">

                        <el-input type="text"
                                  label="Civico"
                                  name="numero_civico"
                                  v-model="shipping_address.numero_civico"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item label="CAP">

                        <el-input type="text"
                                  label="CAP"
                                  name="cap"
                                  v-model="shipping_address.cap"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                    <div class="mb-3"
                         v-if="addressSuggestions.cap.original !== addressSuggestions.cap.suggestion && addressSuggestions.cap.suggestion !== shipping_address.cap">
                        <i class="el-icon-info mr-2" style="color: #FFAF42; font-size: 0.9em; font-weight: 600"></i><strong>CAP
                        suggerito</strong><br/>
                        <i>{{ addressSuggestions.cap.suggestion }}</i> <a href="#" class="a-link"
                                                                          @click.prevent="editModel('cap', addressSuggestions.cap.suggestion)">Inserisci
                        <i class="el-icon-edit"></i></a>
                    </div>

                </div>
            </div>

            <div class="row"
                 v-if="addressSuggestions.address.original !== addressSuggestions.address.suggestion && addressSuggestions.address.suggestion !== shipping_address.indirizzo">
                <div class="col-12 mb-3">
        <span>
          <i class="el-icon-info mr-2" style="color: #FFAF42; font-size: 0.9em; font-weight: 600"></i>
          <strong>Indirizzo suggerito:</strong> <i>{{ addressSuggestions.address.suggestion }}</i> <a href="#"
                                                                                                      class="a-link"
                                                                                                      @click.prevent="editModel('indirizzo', addressSuggestions.address.suggestion)">Inserisci <i
            class="el-icon-edit"></i></a>
        </span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <el-form-item label="Comune">
                        <el-input type="text"
                                  label="Comune"
                                  name="comune"
                                  v-model="shipping_address.comune"
                                  @change="changedAddress"
                                  :disabled="shipping_address.verified">
                        </el-input>
                    </el-form-item>
                    <div class="mb-3"
                         v-if="addressSuggestions.town.original !== addressSuggestions.town.suggestion && addressSuggestions.town.suggestion !== shipping_address.comune">
          <span><i class="el-icon-info mr-2" style="color: #FFAF42; font-size: 0.9em; font-weight: 600"></i><strong>Comune suggerito</strong> <i>{{
                  addressSuggestions.town.suggestion
              }}</i>
            <a href="#" class="a-link" @click.prevent="editModel('comune', addressSuggestions.town.suggestion)">Inserisci <i
                class="el-icon-edit"></i></a>
          </span>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <el-form-item label="Provincia">

                        <el-select v-loading="loading" style="width: 100%" filterable class="select-default mb-3"
                                   v-model="shipping_address.provincia" :disabled="shipping_address.verified"
                                   placeholder="Seleziona..." @change="changedAddress">
                            <el-option
                                v-for="item in province"
                                class="select-default"
                                name="provincia"
                                :key="item.sigla"
                                :label="item.name"
                                :value="item.sigla">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span
                        v-if="addressSuggestions.province.original !== addressSuggestions.province.suggestion && addressSuggestions.province.suggestion !== shipping_address.provincia">
          <i class="el-icon-info mr-2" style="color: #FFAF42; font-size: 0.9em; font-weight: 600"></i><strong>Provincia suggerita</strong><br/>
          <i>{{ addressSuggestions.province.suggestion }}</i> <a href="#" class="a-link"
                                                                 @click.prevent="editModel('provincia',addressSuggestions.province.suggestion)">Inserisci <i
                        class="el-icon-edit"></i></a>
        </span>
                </div>
            </div>
        </el-form>

        <div class="row" v-if="citiesSuggestion.length > 0">
            <div class="col-12">
                <span><i class="el-icon-info mr-2"
                         style="color: #FFAF42; font-size: 0.9em; font-weight: 600"></i><strong>Comuni suggeriti</strong></span>
                <ul style="padding-left: 30px;">
                    <li v-for="(item) in citiesSuggestion">
                        {{ item.ValidTown }} - {{ item.ValidProvinceState }} - {{ item.ValidPostcode }} <a href="#"
                                                                                                           class="a-link"
                                                                                                           @click.prevent="editModel('comune',item.ValidTown); editModel('provincia',item.ValidProvinceState);  editModel('cap',item.ValidPostcode); ">Inserisci
                        <i class="el-icon-edit"></i></a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row mb-3 mt-2">
            <div class="col-12">
                <div class="mb-3">
                    <el-button type="primary" @click="validateFormShippingAddress">Salva
                    </el-button>
                    <button v-if="shipping_address.verified" class="btn btn-danger btn-outline a-link ml-2 pull-right"
                            style="padding: 9px 16px" :disabled="loading" @click.prevent="editCheckAddress">
                        <span>Modifica indirizzo</span><i class="el-icon-edit-outline ml-1"
                                                          style="font-size: 0.8em;"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="row" v-if="currentLocation.lat">
            <div class="col-12 mb-3">
                <p class="text-center"><strong>Scegli un punto di ritiro tra quelli indicati sulla mappa.</strong></p>
                <gmap-map :center="initialMapCenter" :zoom="currentLocationZoom" style="width: 100%; height: 500px">
                    <gmap-info-window :options="infoOptions"
                                      :position="{lat: parseFloat(infoWindowPos.lat), lng: parseFloat(infoWindowPos.lng)}"
                                      :opened="infoWinOpen" @closeclick="infoWinOpen=false">

                        <div>
                            <strong
                                v-if="currentMarker.point_type_text">{{ currentMarker.point_type_text }}<br/></strong>
                            <span v-if="currentMarker.name">{{ currentMarker.name }}<br/><br/></span>
                            <span
                                v-if="currentMarkerIndex !== -1"><strong>Indirizzo:</strong><br/>{{
                                    currentMarker.address
                                }}</span>
                            <span v-if="currentMarkerIndex === -1"><strong>Casa</strong><br/></span>
                            <strong v-if="currentMarker.working_hours"><br/><br/>Orari:<br/></strong>
                            <span v-if="currentMarker.working_hours" v-html="currentMarker.working_hours"></span>
                        </div>

                        <button v-if="currentMarkerIndex !== -1" class="btn btn-primary btn-fill mt-3"
                                @click.prevent="setPickPoint(currentMarker)">
                            Scegli questo punto
                        </button>
                    </gmap-info-window>


                    <gmap-marker :icon="iconObject({icon: 'pointer-home@3x.png', iconsize: 50})"
                                 :position="{lat: parseFloat(currentLocation.lat), lng: parseFloat(currentLocation.lng)}"
                                 :clickable="true" :draggable="false" @click="toggleInfoWindowHome()"></gmap-marker>
                    <gmap-marker :key="index" v-for="(m,index) in pickPoints" :icon="iconObject(m)"
                                 :position="{lat: parseFloat(m.lat), lng: parseFloat(m.lng)}" :clickable="true"
                                 :draggable="false" @click="toggleInfoWindow(m,index)"></gmap-marker>
                </gmap-map>

            </div>
        </div>

        <div class="row mt-3" v-if="order.pick_point_id && chosenPickPoint.id">
            <div class="col-12"><h4 class="mt-0">Punto di ritiro scelto</h4></div>
            <div class="col-12">
                <div>
                    <div>
                        <div v-if="chosenPickPoint.icon" class="pick-point-icon-style"><img
                            :src="'static/img/ritiri/' + chosenPickPoint.icon" style="width: 100%;"/></div>
                        <strong>{{ chosenPickPoint.point_type_text }}</strong>
                        <span v-if="chosenPickPoint.name">- {{ chosenPickPoint.name }}</span>
                        <br/>{{ chosenPickPoint.address }}<span>, {{ chosenPickPoint.city }}, {{
                            chosenPickPoint.cap
                        }}</span>
                    </div>
                    <div style="clear: both"></div>
                    <div><strong>Orari di apertura:<br/></strong><small><span
                        v-html="chosenPickPoint.working_hours"></span></small></div>
                </div>
                <button v-if="order.pick_point_id" class="btn btn-danger btn-outline btn-fill mt-4 mb-3"
                        @click.prevent="deletePickPoint">
                    Elimina punto di ritiro<i class="fa fa-times"></i>
                </button>
            </div>

        </div>

        <hr/>

        <!--    <h4>Dettagli spedizione</h4>-->

        <!--    <p class="text-muted mb-4" v-if="!loading">Spedito il: <span v-if="!order.shipping_at">Non ancora spedito</span><span v-else>{{dateToItalian(order.shipping_at, true)}}</span> <a href="#" @click.prevent="cancelShipping" v-if="order.shipping_at"><span class="mb-4" style="color: #E74155; font-weight: bold; font-size: 18px;">- Annulla la spedizione <i class="el-icon-error"></i></span></a></p>-->

        <!--    <div class="row" v-if="order.shipping_at">-->
        <!--      <div class="col-12 col-md-6">-->
        <!--        <el-input type="text"-->
        <!--                  label="Track numero spedizione"-->
        <!--                  name="track_nr_spedizione"-->
        <!--                  v-model="order.track_nr_spedizione" disabled>-->
        <!--        </el-input>-->
        <!--      </div>-->
        <!--      <div class="col-12 col-md-6" v-if="order.carrier_details && order.carrier_details.name">-->
        <!--        <el-input type="text"-->
        <!--                  label="Corriere"-->
        <!--                  name="name"-->
        <!--                  v-model="order.carrier_details.name" disabled>-->
        <!--        </el-input>-->
        <!--      </div>-->

        <!--      <div class="col-12 mb-3">-->
        <!--        <router-link :to="{path:'/spedizioni/lista/' + this.order.id}"><span class="mt-4" style="color: #2EC4B6; font-weight: bold; font-size: 18px;">Controlla lo stato attuale della spedizione <i class="el-icon-circle-check"></i></span></router-link>-->
        <!--      </div>-->
        <!--    </div>-->

        <!--    <div class="row mb-3 mt-3 mb-4" v-if="order.shipping_at && order.carrier_details && order.carrier_details.name === 'TNT'">-->
        <!--      <div class="col-12">-->
        <!--        <div class="text-center">-->
        <!--          <button type="submit" class="btn btn-primary btn-fill btn-wd"  @click.prevent="getTntLabel">-->
        <!--            Stampa bolla TNT-->
        <!--          </button>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->

        <!--    <hr />-->

        <!--    <h4>Ricevuta ordine</h4>-->
        <!--    <p class="text-muted mb-3" v-if="!loading && receipt_details">Creata il: {{dateToItalian(receipt_details.created_at, true)}}<span class="mb-4" style="color: #E74155; font-weight: bold; font-size: 18px;"></span></p>-->
        <!--    <p class="text-muted mb-3" v-if="!loading && order.receipt">Creata il: {{dateToItalian(order.receipt.created_at, true)}}<span class="mb-4" style="color: #E74155; font-weight: bold; font-size: 18px;"></span></p>-->

        <!--    <div class="row mb-3 mt-2">-->
        <!--      <div class="col-12">-->
        <!--        <div class="mb-3">-->

        <!--          <span v-if="receipt_details || order.receipt">-->
        <!--            <a class="btn btn-primary btn-outline a-link" @click.prevent="getShippingReceipt()">Download ricevuta<i class="el-icon-download ml-2"></i></a>-->
        <!--          </span>-->

        <!--          <span v-if="!receipt_details && !order.receipt">-->
        <!--            <a class="btn btn-warning btn-outline a-link btn-outline a-link" @click.prevent="getShippingReceipt()">Crea ricevuta<i class="el-icon-plus ml-2"></i></a>-->
        <!--          </span>-->

        <!--          <span>-->
        <!--            <a href="#" class="a-link" @click.prevent="sendOrderReceipt()">-->
        <!--              <button class="btn btn-primary btn-outline a-link ml-2" style="padding: 9px 16px;">Invia ricevuta al cliente<i class="nc-icon nc-delivery-fast ml-1" style="font-size: 0.8em; font-weight: 800; !important;"></i></button>-->
        <!--            </a>-->
        <!--          </span>-->

        <!--          <span v-if="receipt_details || order.receipt">-->
        <!--            <a href="#" class="a-link" @click.prevent="removeOrderReceipt()">-->
        <!--              <button class="btn btn-danger btn-outline a-link ml-2" style="padding: 9px 16px;">Elimina ricevuta<i class="nc-icon nc-simple-remove ml-1" style="font-size: 0.8em; font-weight: 800; !important;"></i></button>-->
        <!--            </a>-->
        <!--          </span>-->

        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->

        <!--    <hr />-->

        <!--    <div class="row mt-4 mb-4 pb-3">-->
        <!--      <div class="col-12">-->
        <!--        <label>Note spedizione</label>-->
        <!--        <textarea class="form-control" rows="3"-->
        <!--                  label="Note spedizione"-->
        <!--                  name="shipping_notes"-->
        <!--                  v-model="order.shipping_notes">-->
        <!--          </textarea>-->
        <!--      </div>-->
        <!--    </div>-->

        <!--    <div class="row mb-3 mt-4" v-if="order.shipping_at">-->
        <!--      <div class="col-12 mb-3">-->
        <!--        <div>-->

        <!--          <button type="submit" class="btn btn-primary btn-fill btn-wd"  @click.prevent="saveShippingInfo">-->
        <!--            Salva dati-->
        <!--          </button>-->

        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->

        <!--    <div id="newShipping"  v-if="!order.shipping_at">-->
        <!--      <hr/>-->

        <!--      <h4>Crea una nuova spedizione</h4>-->

        <!--      <div class="row">-->
        <!--        <div class="col-12 col-md-6">-->
        <!--          <label>Corriere</label>-->
        <!--          <br/>-->
        <!--          <el-select v-loading="loading" v-model="newShipping.courier" style="width: 100%" class="select-default mb-3" placeholder="Seleziona..." size="large">-->

        <!--            <el-option-->
        <!--              v-for="item in couriers"-->
        <!--              class="select-default"-->
        <!--              :key="item.code"-->
        <!--              :label="item.name"-->
        <!--              :value="item.code">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </div>-->
        <!--      </div>-->

        <!--      <div class="row mb-3 mt-2">-->
        <!--        <div class="col-12 mb-2">-->
        <!--          <div>-->
        <!--            <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="createShipping" :disabled="!shipping_address.verified">-->
        <!--              Crea spedizione-->
        <!--            </button>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->

        <div v-if="!order.shipping_at">

            <h4>Informazioni sulla spedizione</h4>

            <el-form class="row" label-position="top">
                <div class="col-12 col-md-6">
                    <el-form-item label="corriere">
                        <el-select v-loading="loading" v-model="order.track_id_corriere" class="w-100 mb-3"
                                   placeholder="Seleziona...">

                            <el-option
                                v-for="item in couriers"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Track numero spedizione">
                        <el-input type="text"
                                  label="Track numero spedizione"
                                  name="track_nr_spedizione"
                                  v-model="order.track_nr_spedizione">
                        </el-input>
                    </el-form-item>
                </div>

            </el-form>

            <div class="row mb-3 mt-2">
                <div class="col-12 mb-2">
                    <div>
                        <el-button type="primary" @click="saveShippingInfo">Salva</el-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
</style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'

export default {
    mixins: [],
    components: {
        LSwitch,
        LCheckbox
    },
    props: {
        order: {},
        shipping_address: {}
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderShippingDetails'
            },
            modelValidations: {
                destinatario: {
                    required: true
                },
                indirizzo: {
                    required: true
                },
                comune: {
                    required: true
                },
                cap: {
                    required: true
                },
                provincia: {
                    required: true
                }
            },
            newShipping: {
                courier: 2
            },
            loading: false,
            couriers: [],
            province: [],
            checkedAddress: false,
            isAddressChanged: false,
            addressSuggestions: {address: {}, town: {}, cap: {}, province: {}},
            citiesSuggestion: [],
            currentAddress: '',
            currentLocation: {},
            currentLocationAddress: '',
            pickPoints: [],
            infoContent: '',
            infoWindowPos: {},
            infoWinOpen: false,
            currentMarkerIndex: null,
            currentMarkertype: null,
            currentMarker: {},
            currentMarkeropeningHours: '',
            initialMapCenter: {},
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            chosenPickPoint: {},
            pick_point_id: null,
            pick_point_type: null,
            receipt_details: '',
            forcedVerify: false
        }
    },
    watch: {
        order() {
            if (this.order.id) {
                this.loadResources()
            }
        },
        shipping_address: {
            handler: function () {
                this.updateAddress()
            },
            deep: true
        }
    },
    computed: {},
    methods: {
        cancelShipping() {
            this.askGenericConfirm('Sei davvero sicuro sicuro?', 'Premendo Sì, <strong>annullerai definitivamente questa spedizione</strong>. Fai attenzione ad eliminarla solo se sai realmente cosa stai facendo.').then((result) => {
                if (!result.value) return
                this.removeShipping()
            })
        },
        changedAddress() {
            this.checkedAddress = false
            this.isAddressChanged = true
        },
        checkAddress() {
            this.loading = true
            this.checkedAddress = false
            this.addressSuggestions = {address: {}, town: {}, cap: {}, province: {}}
            this.citiesSuggestion = []
            this.$store.dispatch('shippings/checkOrderAddressWithTnt', this.order).then((response) => {
                this.loading = false
                this.checkedAddress = true
                if (response.data.check_address.validation && !response.data.check_address.adjustment) {
                    this.shipping_address.verified = true
                    this.showGenericSuccess('Indirizzo', 'validato con successo')
                }
                if (response.data.check_address.address) {
                    this.addressSuggestions = response.data.check_address.address
                }
                if (response.data.check_address.suggested_cities) {
                    this.citiesSuggestion = response.data.check_address.suggested_cities
                }
            }).catch(e => {
                this.loading = false
                this.checkedAddress = false
                this.showLoadingError(e, 'la verifica dell\'indirizzo di spedizione.')
            })
        },
        copyAddress(pAddressToCopy) {
            this.$copyText(pAddressToCopy).then((e) => {
                // this.showGenericSuccess('Variabile', 'copiata con successo')
            })
        },
        copyAddressFromCustomer() {
            this.shipping_address.destinatario = this.order.customer.first_name + ' ' + this.order.customer.last_name
            this.shipping_address.telefono = this.order.customer.mobile_phone_number
            if (this.order.customer.mobile_phone_number === '' || !this.order.customer.mobile_phone_number) {
                this.shipping_address.phone_number = this.order.customer.phone_number
            }
            this.shipping_address.indirizzo = this.order.customer.indirizzo_residenza
            this.shipping_address.numero_civico = this.order.customer.numero_civico
            this.shipping_address.comune = this.order.customer.comune
            this.shipping_address.cap = this.order.customer.cap
            this.shipping_address.provincia = this.order.customer.provincia
            this.changedAddress()
        },
        createShipping() {
            this.askGenericConfirm('Vuoi creare la spedizione?', 'Premendo Sì confermerai la creazione della spedizione', 'Sì, crea!', 'No, ho sbagliato!').then((result) => {
                if (!result.value) {
                    return
                }
                if (this.newShipping.courier === 1) {
                    this.createShippingTnt()
                } else if (this.newShipping.courier === 2) {
                    this.createShippingBrt()
                } else {
                    this.showGenericError('Errore!', 'Questo corriere non è implementato.')
                }
            })
        },
        createShippingBrt() {
            this.loading = true
            this.$store.dispatch('shippings/shipWithBrt', this.order).then((response) => {
                this.loading = false
                this.$store.commit('orders/updateChronologyEvents')
                this.order.track_nr_spedizione = response.data.shipping.order.track_nr_spedizione
                this.order.shipping_at = response.data.shipping.order.shipping_at
                this.order.track_id_corriere = response.data.shipping.order.track_id_corriere
                this.order.carrier_details = response.data.shipping.order.carrier_details
                this.order.status = response.data.shipping.order.status
                this.showGenericSuccess('Spedizione BRT', 'la spedizione con BRT è stata richiesta con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la spedizione con BRT')
            })
        },
        createShippingTnt() {
            this.loading = true
            this.$store.dispatch('shippings/shipWithTnt', this.order).then((response) => {
                this.loading = false
                this.$store.commit('orders/updateChronologyEvents')
                this.order.track_nr_spedizione = response.data.shipping.order.track_nr_spedizione
                this.order.shipping_at = response.data.shipping.order.shipping_at
                this.order.track_id_corriere = response.data.shipping.order.track_id_corriere
                this.order.carrier_details = response.data.shipping.order.carrier_details
                this.order.status = response.data.shipping.order.status
                this.showGenericSuccess('Spedizione TNT', 'la spedizione con TNT è avvenuta con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la spedizione con TNT')
            })
        },
        deletePickPoint() {
            this.chosenPickPoint = {}
            this.infoWinOpen = false
            this.chosenPickPoint.id = null
            this.chosenPickPoint.type = null
            this.$store.dispatch('orders/edit', {
                id: this.order.id,
                pick_point_id: this.chosenPickPoint.id,
                pick_point_type: this.chosenPickPoint.tipo
            }).then((response) => {
                this.loading = false
                this.showGenericSuccess('Punto di ritiro ', 'eliminato')
            }).catch(e => {
                this.loading = false
                this.showGenericError('Errore', 'durante l\'eliminazione del punto di ritiro.')
            })
        },
        downloadAttachment(pAttachment) {
            if (!pAttachment.cloud) {
                this.showGenericError('Errore', 'Prova a ricaricare la pagina.')
                return
            }
            var win = window.open(pAttachment.cloud, '_blank')
            win.focus()
        },
        editModel(pModel, pValue) {
            this.changedAddress()
            this.shipping_address[pModel] = pValue
        },
        sendOrderReceipt() {
            this.askGenericConfirm('Invio la ricevuta?', 'La ricevuta verrà inviata al cliente. Se non esiste, verrà creata e poi inviata!').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('orders/sendOrderReceipt', this.order).then((response) => {
                    this.receipt_details = response.data.receipt_file
                    this.loading = false
                    this.showGenericSuccess('Fatto!', 'Ricevuta inviata al cliente!')
                    this.$store.commit('orders/updateChronologyEvents')
                }).catch(e => {
                    this.showLoadingErrorWithoutRetry(e, 'l\'invio della ricevuta dell\'ordine al cliente')
                    this.loading = false
                })
            })
        },
        getShippingReceipt() {
            if (this.receipt_details) {
                this.downloadAttachment(this.receipt_details.media)
            } else {
                this.loading = true
                this.$store.dispatch('orders/getOrderReceipt', this.order).then((response) => {
                    this.receipt_details = response.data.receipt_file
                    this.downloadAttachment(this.receipt_details.media)
                    this.loading = false
                }).catch(e => {
                    this.showLoadingErrorWithoutRetry(e, 'il caricamento della ricevuta dell\'ordine')
                    this.loading = false
                })
            }
        },
        getPickPoints() {
            if (this.shipping_address.indirizzo === '' || !this.shipping_address.indirizzo || this.shipping_address.numero_civico === '' || !this.shipping_address.numero_civico || this.shipping_address.comune === '' || !this.shipping_address.comune || this.shipping_address.cap === '' || !this.shipping_address.cap || this.shipping_address.provincia === '' || !this.shipping_address.provincia) {
                this.showGenericError('Errore', 'I campi dell\'indirizzo sono obbligatori.')
                return
            }
            this.loading = true
            this.$store.dispatch('shippings/getPickPoints', this.currentAddress).then((response) => {
                this.loading = false
                this.pickPoints = response.data.pick_points.pick_points
                this.currentLocation.lat = response.data.pick_points.location.lat
                this.currentLocation.lng = response.data.pick_points.location.lng
                this.currentLocationAddress = response.data.pick_points.location.formatted_address
                this.currentLocationZoom = response.data.pick_points.location.zoom_level
                this.initialMapCenter = {
                    lat: parseFloat(this.currentLocation.lat),
                    lng: parseFloat(this.currentLocation.lng)
                }
            }).catch(e => {
                this.loading = false
                this.showGenericError('Errore', 'durante il caricamento dei punti di ritiro.')
            })
        },
        getTntLabel() {
            this.loading = true
            this.$store.dispatch('shippings/getTntLabel', this.order).then((file) => {
                this.loading = false
                var fileDownload = require('js-file-download')
                fileDownload(file, 'Bolla' + this.order.id + '.pdf', 'application/pdf')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il recupero della bolla per TNT')
            })
        },
        handleCheckAddress() {
            if (this.isAddressChanged) {
                this.askGenericConfirm('Indirizzo modificato', 'Vuoi aggiornare l\'indirizzo nel sistema prima della verifica?').then((result) => {
                    if (!result.value) {
                        this.checkAddress()
                    } else {
                        this.saveShippingAddress(false, true)
                    }
                })
            } else {
                this.checkAddress()
            }
        },
        iconObject(pPoint) {
            let iconSize = 42
            if (pPoint.iconsize) iconSize = Number(pPoint.iconsize)
            return {
                url: 'static/img/ritiri/' + pPoint.icon,
                size: {width: iconSize, height: iconSize, f: 'px', b: 'px'},
                scaledSize: {width: iconSize, height: iconSize, f: 'px', b: 'px'}
            }
        },
        loadCouriers() {
            this.loading = true
            this.$store.dispatch('shippings/getCouriers').then((response) => {
                this.loading = false
                this.couriers = response  // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei corrieri')
            })
        },
        loadProvince() {
            this.loading = true
            this.$store.dispatch('shippings/getProvince').then((response) => {
                this.loading = false
                this.province = response
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle province')
            })
        },
        loadResources() {
            if (this.order.pick_point) this.chosenPickPoint = this.order.pick_point
            this.loadCouriers()
        },
        removeBrtShipping() {
            this.loading = true
            this.$store.dispatch('shippings/removeBrtShipping', this.order).then((response) => {
                this.loading = false
                this.$store.commit('orders/updateChronologyEvents')
                this.order.track_nr_spedizione = response.data.shipping.order.track_nr_spedizione
                this.order.shipping_at = response.data.shipping.order.shipping_at
                this.order.track_id_corriere = response.data.shipping.order.track_id_corriere
                this.order.carrier_details = response.data.shipping.order.carrier_details
                this.order.status = response.data.shipping.order.status
                this.showGenericSuccess('Spedizione BRT', 'la spedizione con BRT è stata annullata con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la rimozione della spedizione con BRT')
            })
        },
        removeShipping() {
            this.loading = true
            this.$store.dispatch('shippings/removeShipping', this.order).then((response) => {
                this.loading = false
                this.$store.commit('orders/updateChronologyEvents')
                this.order.track_nr_spedizione = response.data.shipping.order.track_nr_spedizione
                this.order.shipping_at = response.data.shipping.order.shipping_at
                this.order.track_id_corriere = response.data.shipping.order.track_id_corriere
                this.order.carrier_details = response.data.shipping.order.carrier_details
                this.order.status = response.data.shipping.order.status
                this.showGenericSuccess('Spedizione', 'la spedizione è stata annullata con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la rimozione della spedizione')
            })
        },
        removeTntShipping() {
            this.loading = true
            this.$store.dispatch('shippings/removeTntShipping', this.order).then((response) => {
                this.loading = false
                this.$store.commit('orders/updateChronologyEvents')
                this.order.track_nr_spedizione = response.data.shipping.order.track_nr_spedizione
                this.order.shipping_at = response.data.shipping.order.shipping_at
                this.order.track_id_corriere = response.data.shipping.order.track_id_corriere
                this.order.carrier_details = response.data.shipping.order.carrier_details
                this.order.status = response.data.shipping.order.status
                this.showGenericSuccess('Spedizione TNT', 'la spedizione con TNT è stata annullata con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la rimozione della spedizione con TNT')
            })
        },
        saveShippingAddress(pShowConfirm = false, pCheckAddress = false) {
            this.isAddressChanged = false
            this.loading = true
            if (this.forcedVerify) {
                this.shipping_address.verified = true
            }
            this.$store.dispatch('shippings/editAddress', this.shipping_address).then((response) => {
                this.loading = false
                this.order.shipping_address = response.data.shipping_address
                if (pShowConfirm) this.showGenericSuccess('Indirizzo')
                if (pCheckAddress) this.checkAddress()
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dell\'indirizzo di questo cliente')
            })
        },
        setPickPoint(pMarker) {
            this.chosenPickPoint = pMarker
            this.loading = true
            this.$store.dispatch('orders/edit', {
                id: this.order.id,
                pick_point_id: this.chosenPickPoint.code,
                pick_point_type: this.chosenPickPoint.type
            }).then((response) => {
                this.loading = false
                this.order.pick_point_id = response.data.order.pick_point_id
                this.order.pick_point_type = response.data.order.pick_point_type
                this.showGenericSuccess('Punto di ritiro', 'impostato')
            }).catch(e => {
                this.loading = false
                this.showGenericError('Errore', 'durante l\'aggiunta del punto di ritiro.')
            })
        },
        toggleInfoWindow: function (marker, idx) {
            this.infoWindowPos.lat = marker.lat
            this.infoWindowPos.lng = marker.lng
            this.infoContent = marker.address
            this.currentMarkertype = marker.point_type_text
            this.currentMarker = marker
            // check if its the same marker that was selected if yes toggle
            if (this.currentMarkerIndex === idx) {
                this.infoWinOpen = !this.infoWinOpen
            } else {
                // if different marker set infowindow to open and reset current marker index
                this.infoWinOpen = true
                this.currentMarkerIndex = idx
            }
        },
        toggleInfoWindowHome: function () {
            this.infoWindowPos.lat = this.currentLocation.lat
            this.infoWindowPos.lng = this.currentLocation.lng
            this.infoContent = this.currentLocationAddress
            this.currentMarker = {}
            if (this.currentMarkerIndex === -1) {
                this.infoWinOpen = !this.infoWinOpen
            } else {
                this.infoWinOpen = true
                this.currentMarkerIndex = -1
            }
        },
        removeOrderReceipt() {
            this.loading = true
            this.askDeleteMessage('questa ricevuta').then((result) => {
                if (!result.value) return
                this.$store.dispatch('shippings/removeOrderReceipt', this.order.id).then((response) => {
                    if (response.data.deleted !== 0) {
                        this.showGenericSuccess('Ricevuta', 'eliminata con successo')
                        this.order.receipt = null
                    }
                    this.receipt_details = null
                    this.loading = false
                }).catch(e => {
                    this.loading = false
                    this.showLoadingError(e, 'l\'eliminazione della ricevuta', false)
                })
            })
        },
        saveShippingInfo() {
            this.$emit('update', {
                id: this.order.id,
                track_id_corriere: this.order.track_id_corriere,
                track_nr_spedizione: this.order.track_nr_spedizione,
                shipping_notes: this.order.shipping_notes
            }, false)
        },
        updateAddress() {
            if (this.shipping_address) {
                this.currentAddress = this.shipping_address.indirizzo + ', ' + this.shipping_address.numero_civico + ', ' + this.shipping_address.comune + ', ' + this.shipping_address.cap + ' (' + this.shipping_address.provincia + ')'
                this.changedAddress()
            }
        },
        validateCheckAddress() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    if (this.shipping_address.provincia === null) {
                        this.showGenericError('Errore', 'il campo provincia è richiesto.')
                        return
                    }
                    this.handleCheckAddress()
                }
            })
        },
        editCheckAddress() {
            this.shipping_address.verified = false
            this.forcedVerify = false
            this.saveShippingAddress()
        },
        validateFormShippingAddress() {
            this.saveShippingAddress(true)
        }
    },
    created: function () {
        this.loadProvince()
        this.loadResources()
        this.updateAddress()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style>
.pick-point-icon-style {
    border-radius: 50%;
    background-color: rgba(168, 168, 168, 0.2);
    width: 50px;
    height: 50px;
    padding: 8px;
    margin-bottom: 10px;
    margin-right: 15px;
    float: left;
}
</style>
