<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model="value"
                   placeholder="Seleziona il metodo di pagamento"
                   @change="updateValue">
            <el-option
                v-for="item in payment_method_types"
                class="select-default"
                :key="item.code"
                :label="item.name"
                :value="item.code">
            </el-option>
        </el-select>
    </div>
</template>

<script>


export default {
    name: "PaymentMethodTypeSelect",
    components: {},
    data() {
        return {
            loading: false,
            payment_method_types: []
        }
    },
    props: {
        modelValue: String
    },
    mounted() {
        this.loadPaymentMethodsTypes()
    },
    computed: {
        value() {
            return this.modelValue
        }
    },
    methods: {
        loadPaymentMethodsTypes() {
            this.loading = true
            this.$store.dispatch('orders/paymentMethods/getTypes').then((response) => {
                this.loading = false
                this.payment_method_types = response  // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle tipologie di metodo di pagamento')
            })
        },
        updateValue: function (value) {
            this.$emit('update:modelValue', value)
        }
    }
}
</script>

<style scoped>

</style>
