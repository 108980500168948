<template>
  <div>
    <label>Filtra per donating</label>
    <el-select class="select-default w-100"
               v-model="currentValue"
               placeholder="Filtra per codice olo" @change="handleChange">
      <el-option class="select-default" :key=null label="---" :value=null></el-option>
      <el-option class="select-default" v-for="(code, index) in codes" :key=index :label="code"
                 :value=code></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "OloCodeDonatingSelect",
  data() {
    return {
      codes: null,
      loading: false,
      currentValue: this.value
    }
  },
  props: {
    value: {
      required: true
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store.dispatch('olo2olo/getOloCodeDonating').then((response) => {

        console.log(response.data)
        this.codes = response.data;

      }).catch(e => {
        this.loading = false
        this.showLoadingError(e)
      })
    },
    handleChange(newValue) {
      this.$emit('input', newValue)
      this.$emit('change')
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
