<template>
  <div>
    <ul v-if="notes.length > 0" class="pl-0 list-unstyled">
      <li v-for="(item) in notes" class="my-2">
        <order-note :order-note="item" @deleted="loadNotes"/>
      </li>
    </ul>
    <h5 class="n-thin mt-5" v-else>Nessuna nota da mostrare</h5>
  </div>
</template>

<script>
import OrderNote from "@/pages/Dashboard/Orders/Components/OrderNote";

export default {
  name: "OrderNotesList",
  components: {OrderNote},
  props: {
    orderId: {
      required: true
    }
  },
  data: function(){
    return {
      notes: []
    }
  },
  created() {
    this.loadNotes()
  },
  methods: {
    loadNotes() {
      this.notesLoading = true
      this.$store.dispatch('orders/getNotes', {id: this.orderId}).then((response) => {
        this.notes = response.data.order_notes
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.notesLoading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
