<template>
    <div v-loading="loading" v-on:keydown.tab='tabKey'>
        <div class="row">
            <div class="col-12">
                <label>Aggiungi nota</label>
                <rich-text-editor v-model="content_text" style="height: 137px;" class="w-100"/>
            </div>
            <div class="col-3 d-none">
                <label>Allegati della nota</label>
                <el-upload
                    action="addNote"
                    :multiple="false"
                    name="media_file"
                    ref="upload"
                    :file-list="uploaded_file_list"
                    class="w-100">
                    <el-button size="small" type="primary" icon="paperclip">Aggiungi allegato</el-button>
                </el-upload>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 d-flex justify-content-start">
                <el-button icon="plus" type="primary" @click.prevent="addNote" :disabled="!content_text" ref="addNote">
                    Aggiungi
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {QuillEditor} from "@vueup/vue-quill";
import RichTextEditor from "@/components/RichTextEditor.vue";

export default {
    name: "AddOrderNoteForm",
    components: {RichTextEditor, QuillEditor},
    props: {
        orderId: {
            required: true
        }
    },
    data: function () {
        return {
            loading: false,
            content_text: null,
            attachment: null,
            uploaded_file_list: []
        }
    },
    methods: {
        tabKey() {
            this.$refs.addNote.$el.focus();
        },
        asdasd() {
            this.content_text = this.$refs.editor.getText()
        },
        addNote() {
            this.content_text = this.content_text.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br />');
            this.content_text = this.content_text.replace('<br /><br><br />', '<br /><br />');

            this.loading = true
            //this.$refs.upload.submit();

            this.$store.dispatch('orders/addNotes', {
                content_text: this.content_text,
                type: 1,
                id: this.orderId
            }).then((response) => {
                this.content_text = ''
                this.$emit('added')
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
