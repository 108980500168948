<template>

  <div>
    <form :model="useful_link_form">
      <div class="row mt-4 mb-2">
        <div class="col-12 col-sm-4 col-md-3">
          <label>Tipo link</label>
          <el-select v-loading="loading" v-model="useful_link_form.type" style="width: 100%" class="select-default mb-0" filterable placeholder="Tipo link..." size="large">
            <el-option
                    v-for="type in linkTypes"
                    class="select-default"
                    :key="type.id"
                    :label="type.label"
                    :value="type.id"
                    name="name">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Nome"
                    v-model="useful_link_form.name"
                    name="name"
                    v-validate="modelValidations.name"
                    :error="getError('name')">
          </el-input>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Link"
                    v-model="useful_link_form.link"
                    name="link"
                    v-validate="modelValidations.link"
                    :error="getError('link')">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-input type="text"
                    label="Descrizione"
                    v-model="useful_link_form.description"
                    name="descrizione"
                    v-validate="modelValidations.description"
                    :error="getError('description')">
          </el-input>
        </div>

      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Nome utente"
                    v-model="useful_link_form.username"
                    name="username"
                    :error="getError('username')">
          </el-input>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Password"
                    v-model="useful_link_form.password"
                    name="password"
                    :error="getError('password')">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 pb-0">

          <div class="row" style="margin-top: 20px">
            <div class="col-12 text-center">

              <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteLink" v-if="useful_link_form.id">
                Elimina
              </button>

              <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
                Salva
              </button>

            </div>
          </div>

          <div class="clearfix"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<style>
  .el-transfer-panel {
    width: 300px;
  }

  .el-transfer-panel__body {
    width: 300px;
  }
  .el-transfer-panel__list.is-filterable {
    width: 100% !important;
  }
</style>
<script>
  import { Switch as LSwitch, Checkbox as LCheckbox } from '../../../../components'
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      LSwitch,
      LCheckbox,
      'editor': Editor
    },
    props: {
      useful_link_form: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/offerte/opzioni/lista'
        },
        content: '',
        loading: false,
        statuses: [],
        customer_types: null,
        modelValidations: {
          link: {
            required: true
          },
          name: {
            required: true
          }
        },
        linkTypes: [
          {id: 0, label: 'Link utili'},
          {id: 1, label: 'Link operativi'}
        ],
        currentType: 1
      }
    },
    watch: {},
    computed: {},
    methods: {
      deleteLink () {
        this.$emit('delete', this.useful_link_form)
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validateForm () {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.$emit('update', this.useful_link_form)
          }
        })
      }
    },
    created: function () {
      if (!this.useful_link_form.id) {
        this.useful_link_form.type = 1
      }
    },
    mounted: function () {
    },
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
