<template>
    <div v-if="modelValue.id">
        <div class="d-flex align-items-center" v-loading="loading">
            <h3 class="my-0 mr-2">Fastweb Wholesale (integrazione B2B)</h3>
            <el-button circle size="small" icon="refresh" @click="refreshChronology"
                       data-tooltip="'Ricarica cronologia'" class="mr-2"/>
            <el-dropdown @command="handleAction" class="ml-auto">
                <el-button type="primary">Azioni <i class="arrow-down -right"></i>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-if="!modelValue.wholesaled_at" command="sendToFastweb">Invia a Fastweb
                        </el-dropdown-item>
                        <el-dropdown-item v-if="modelValue.wholesaled_at && modelValue.status_id === 125"
                                          command="desuspend">Invia desospensione
                        </el-dropdown-item>
                        <el-dropdown-item v-if="modelValue.wholesaled_at" command="unwholesale">Annulla flag ordine
                            inviato (interno)
                        </el-dropdown-item>
                        <el-dropdown-item v-if="modelValue.wholesaled_at" command="inflightCancelOrder">Annulla ordine
                            su Fastweb
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>

            </el-dropdown>
        </div>

        <div class="row my-4">
            <div class="col-12 col-md-6" v-if="modelValue.wholesaled_at">
                <p class="my-2">Ordine inviato a Fastweb il: <strong>{{
                        dateToItalian(modelValue.wholesaled_at)
                    }}</strong></p>
                <p class="my-2">Codice ordine olo: <strong><a
                    :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + modelValue.order_product.codice_ordine_olo + '&search='"
                    target="_blank">{{ modelValue.order_product.codice_ordine_olo }}</a></strong></p>
                <p class="my-2">Codice ordine wholesale: <strong><a
                    :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + modelValue.order_product.codice_ordine_wholesale + '&search='"
                    target="_blank">{{ modelValue.order_product.codice_ordine_wholesale }}</a></strong></p>
                <p class="my-2">Id circuito end2end: <strong><a
                    :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + modelValue.order_product.id_wholesale_circuito_endtoend + '&search='"
                    target="_blank">{{ modelValue.order_product.id_wholesale_circuito_endtoend }}</a></strong></p>
            </div>
        </div>
        <div class="my-4">
            <fastweb-wholesale-chronology ref="fastwebWholesaleChronology" :order-id="modelValue.id"/>
        </div>

        <fastweb-wholesale-desuspend-modal :order-id="modelValue.id" :visible="showDesuspendModal"
                                           @close="showDesuspendModal = false"/>
    </div>
</template>

<script>
import FastwebWholesaleChronology from "@/pages/Dashboard/Orders/Components/FastwebWholesaleChronology";
import FastwebWholesaleDesuspendModal from "@/pages/Dashboard/Orders/Components/FastwebWholesaleDesuspendModal";

export default {
    name: "FastwebWholesaleDetails",
    components: {FastwebWholesaleDesuspendModal, FastwebWholesaleChronology},
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            showDesuspendModal: false,
            loading: false
        }
    },
    methods: {
        startLoading() {
            this.loading = true
        },
        stopLoading() {
            this.loading = false
        },
        refreshChronology() {
            this.$refs.fastwebWholesaleChronology.fetchChronology();
            this.$emit('reloaded')
        },
        sendToFastweb() {
            this.askGenericConfirm("Vuoi inviare l'ordine a Fastweb?").then((value) => {
                if (!value) {
                    return
                }
                this.startLoading();
                this.$store.dispatch('orders/fastwebWholesaleActivationOrder', this.modelValue.id).then((response) => {
                    this.$emit('update:modelValue', response.data.data)
                    this.stopLoading();
                    this.refreshChronology()
                }).catch(e => {
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        unWholesale() {
            this.askGenericConfirm("Annulla flag ordine inviato?", 'Stai per rimuovere il flag di "ordine inviato a Fastweb" senza che venga annullato. Sei sicuro?', 'Sì', 'No, annulla l\'operazione!').then((value) => {
                if (!modelValue.isConfirmed) {
                    return
                }
                this.startLoading();
                this.$store.dispatch('orders/unWholesale', this.modelValue.id).then((response) => {
                    this.$emit('update:modelValue', response.data.data)
                    this.refreshChronology()
                    this.stopLoading();
                }).catch(e => {
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        inflightCancelOrder() {
            this.askGenericConfirm("Annullare l'ordine da Fastweb?", 'Stai per chiedere l\'annullamento sui sistemi Fastweb. Sei sicuro?', 'Si, conferma annullamento', 'No, annulla l\'operazione!').then((value) => {
                if (!modelValue.isConfirmed) {
                    return
                }
                this.startLoading();
                this.$store.dispatch('orders/fastwebWholesaleInflightCancelOrder', this.modelValue.id).then((response) => {
                    this.$emit('update:modelValue', response.data.data)
                    this.stopLoading();
                    this.refreshChronology()
                }).catch(e => {
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        desuspendOrder() {
            this.showDesuspendModal = true
        },
        handleAction(command) {
            switch (command) {
                case 'sendToFastweb':
                    this.sendToFastweb()
                    break;
                case 'unwholesale':
                    this.unWholesale()
                    break;
                case 'inflightCancelOrder':
                    this.inflightCancelOrder()
                    break
                case 'desuspend':
                    this.desuspendOrder()
                    break
            }
        }
    }
}
</script>

<style scoped>

</style>
