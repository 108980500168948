<template>

    <div>
        <el-form ref="email-template-form" :rules="modelValidations" :model="mail_template" label-position="top">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <el-form-item label="Tipo template" prop="type">
                        <el-select v-loading="loading" class="w-100" v-model="mail_template.type"
                                   placeholder="Seleziona...">
                            <el-option
                                v-for="item in tipoTemplate"
                                class="select-default"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-12 col-sm-6">
                    <el-form-item label="Nome" prop="name">
                        <el-input type="text"
                                  v-model="mail_template.name"
                                  name="name">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row" v-if="isDeveloper">
                <div class="col-12">
                    <el-form-item label="Eliminazione bloccata? (dev only)">
                        <el-switch v-model="mail_template.locked" active-text="SÌ" inactive-text="NO" inline-prompt/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Oggetto" prop="subject">
                        <el-input type="text"
                                  v-model="mail_template.subject"
                                  name="subject">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-5 pt-0">
                    <l-checkbox class="pt-0 mt-0" v-model="copyVariable">Copia variabile</l-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <el-form-item label="Vuoi utilizzare un template vuoto?">
                        <el-switch type="primary" v-model="mail_template.empty_template" active-text="SÌ"
                                   inactive-text="NO"></el-switch>
                    </el-form-item>
                </div>
                <div class="col-12 col-sm-6">
                    <el-form-item label="Vuoi usare un template custom?">
                        <el-input type="text"
                                  v-model="mail_template.custom_template"
                                  name="custom_template">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Indirizzo mittente (vuoto: email di default)">
                        <el-input type="text"
                                  label="Indirizzo mittente (vuoto: email di default)"
                                  v-model="mail_template.sender_email"
                                  name="sender_email">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Variabili">
                        <div class="d-flex">
                            <el-select v-loading="loading" v-model="currentVariable" placeholder="Seleziona..."
                                       @change="doCopyVariable" style="min-width: 360px;">
                                <el-option-group
                                    v-for="(value, key) in template_variables"
                                    v-if="(key === 'product_alert' && mail_template.type === 5) || (mail_template.type !== 5 && key !== 'product_alert')"
                                    :key="key"
                                    :label="key"
                                    style="text-transform: uppercase">
                                    <el-option
                                        v-for="item in value"
                                        :key="item.key"
                                        :label="item.description"
                                        :value="item.key"
                                        style="text-transform: initial">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                            <el-button v-if="currentVariable" @click.prevent="appendToEditor(currentVariable)"
                                       class="ml-2"
                                       type="primary">Inserisci nel template
                            </el-button>
                        </div>
                    </el-form-item>
                </div>
            </div>

            <div class="row" v-if="!is_html_code">
                <div class="col-12">
                    <label>Corpo mail
                        <l-checkbox class="pt-0 mt-0 mb-0" v-model="is_html_code">Abilita codice HTML</l-checkbox>
                    </label>
                    <div v-if="!is_html_code">
                        <el-form-item label="Contenuto" prop="content" class="mb-0">
                            <rich-text-editor v-model="mail_template.content"></rich-text-editor>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <div class="row pb-3 mb-4" v-if="is_html_code">
                <div class="col-12">
                    <label>Codice HTML del template</label>
                    <textarea class="form-control" rows="20"
                              name="shipping_notes"
                              v-model="mail_template.content">
          </textarea>

                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <label>Allegati da inviare con questo template</label>
                    <el-checkbox-group v-model="mail_template.default_attachments">
                        <el-checkbox border label="contract">Contratto</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>


            <hr/>

            <div class="row mt-4">
                <div class="col-12">
                    <label>Note interne sull'uso di questa notifica</label>
                    <br/>
                    <rich-text-editor v-model="mail_template.note"/>
                </div>
            </div>


            <div class="row py-4">
                <div class="col-12">

                    <el-button type="text" @click="deleteElement" class="mr-2 text-danger"
                               v-if="mail_template.id && !mail_template.locked">Elimina
                    </el-button>
                    <el-button type="primary" @click="validateForm">Salva</el-button>
                </div>
            </div>

        </el-form>
    </div>
</template>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from '../../../../components'
import Editor from '@tinymce/tinymce-vue'
import RichTextEditor from "@/components/RichTextEditor.vue";

export default {
    components: {
        RichTextEditor,
        LSwitch,
        LCheckbox,
        'editor': Editor
    },
    props: {
        mail_template: {
            default_attachments: []
        }
    },
    data() {
        return {
            componentSettings: {
                listPath: '/notifications/email/lista'
            },
            content: '',
            loading: false,
            modelValidations: {
                name: [
                    {required: true, message: 'Questo campo è obbigatorio', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: 'Questo campo è obbigatorio', trigger: 'blur'}
                ],
                subject: [
                    {required: true, message: 'Questo campo è obbigatorio', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: 'Questo campo è obbigatorio', trigger: 'blur'}
                ]
            },
            tipoTemplate: [],
            currentVariable: '',
            copyVariable: true,
            template_variables: {},
            is_html_code: false
        }
    },
    watch: {
        mail_template() {
            if (this.mail_template.id) {
                if (this.mail_template.content.includes('<head>')) {
                    this.is_html_code = true
                }
                this.loadVariables(this.mail_template.id)
            }
        }
    },
    computed: {},
    methods: {
        deleteElement() {
            this.$emit('delete', this.mail_template)
        },
        appendToEditor(pVariableToAppend) {
            this.mail_template.content = this.mail_template.content + ' ' + pVariableToAppend
        },
        validateForm() {
            this.$refs['email-template-form'].validate((valid) => {
                if (valid) {
                    this.$emit('update', this.mail_template)
                    return;
                }

                this.showErrorMessage("Ci sono degli errori nella compilazione del form. Ricontrolla i dati inseriti e riprova.")
            })
        },
        doCopyVariable: function () {
            if (!this.copyVariable) return
            this.$copyText(this.currentVariable).then((e) => {
            }).catch(e => {
            })
        },
        loadTypes() {
            this.loading = true
            this.$store.dispatch('notifications/emails/getTypes').then((response) => {
                this.loading = false
                this.tipoTemplate = response.data.mail_template_types
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento dei tipi template')
            })
        },
        loadVariables(pTemplateId = null) {
            this.loading = true
            this.$store.dispatch('notifications/getVariables', {
                id: pTemplateId,
                notification_type: 1
            }).then((response) => {
                this.loading = false
                this.template_variables = response.data.template_variables
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle variabili')
            })
        }
    },
    created: function () {
        this.loadTypes()
        if (this.mail_template.is_new) this.loadVariables()
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
