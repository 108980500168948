<template>
    <div v-loading="loading">
        <el-upload
            :action="attachmentAddAction"
            :on-success="handleAttachmentSuccess"
            :on-error="handleAttachmentError"
            :before-upload="handleBeforeUpload"
            :data="uploadFileData"
            :on-progress="handleProgress"
            :show-file-list="false"
            name="file"
            drag>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Trascina qui il file da caricare <em>o clicca per sceglierlo</em></div>
        </el-upload>
    </div>
</template>

<script>

export default {
    name: "partner-media-upload",
    components: {},
    data() {
        return {
            loading: false,
            attachmentType: 4, //Documento
            attachment: null,
            fileList: []
        }
    },
    props: {
        partner: {
            required: true
        }
    },
    computed: {
        attachmentAddAction: function () {
            return this.$config.endpoints.partner.base + this.partner.id + '/add-attachment'
        },
        uploadFileData: function () {
            return Object.assign({media_type: 'generic'}, this.$store.getters.apiCommon)
        }
    },
    methods: {
        handleBeforeUpload(file) {
            this.loading = true;
        },
        handleProgress(event, file, fileList) {
        },
        handleAttachmentError(err, file, fileList) {
            this.showErrorMessage(`Errore durante l'upload del file. ${err}`)
            this.loading = false;
        },
        handleAttachmentSuccess(response, file, fileList) {
            if (!response.result) {
                this.showErrorMessage(`Errore durante l'upload del file. ${err}`)
                this.loading = false;
                return false
            }

            this.attachment = response.data;
            this.fileList = [{
                name: this.attachment.original_name,
                url: this.attachment.url
            }]
            this.$emit('upload', this.attachment)
            this.loading = false;
        }
    }
}
</script>

<style scoped>

</style>
