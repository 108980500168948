import Vue from 'vue'
import {createApp} from "vue";
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'
import Vuex from 'vuex'
import napalmPlugin from './napalmPlugin.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// Custom imports
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import VueObserveVisibility from 'vue-observe-visibility'
import ElementPlus from 'element-plus'
import it from 'element-plus/dist/locale/it.mjs'
import 'element-plus/dist/index.css'
import VueSignaturePad from 'vue-signature-pad';
import Config from './globalConfig'
import router from "@/routes/router";

import axios from 'axios'
import {store} from "@/store";

import VueClipboard from 'vue-clipboard2'

require('intersection-observer')

const app = createApp(App)

app.use(store)
app.use(router)

app.use(ElementPlus, {
    locale: it
})


app.use(VueSignaturePad);


// plugin setup
app.use(LightBootstrap)
// Custom
app.use(Vuex)
app.use(napalmPlugin)
app.use(VueObserveVisibility)
app.use('vue-moment')
app.use(VueClipboard)

QuillEditor.props.globalOptions.default = () => (
    {
        theme: 'snow',
        placeholder: '',
        formats: [
            'bold', 'underline', 'italic'
        ],
        matchVisual: false,
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                ['link'],
                ['clean']
            ]
        }
    }
)

app.component('QuillEditor', QuillEditor)

app.config.globalProperties.$config = Config
app.config.globalProperties.axios = axios

// ICONS
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')
