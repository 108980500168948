import Config from '../../../globalConfig'
import axios from 'axios'

const orderPayment = {
  namespaced: true,
  state: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async loadBraintreeTransactions ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pElement.order_id + '/payments/braintree/find', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async updateBraintreeManual ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pElement.order_id + '/payments/braintree/find-and-check', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async loadPaymentDetails ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var paymentGateway = pElement.payment_gateway
        var params = Object.assign({payment_gateway: paymentGateway}, pElement, rootGetters.apiCommon)
        if (pElement.payment_gateway === 1) {
          axios.post(Config.endpoints.orders.base + pElement.order.id + '/payments/docdata/details', params)
            .then((response) => {
              if (!response.data.result) {
                reject(response)
                return
              }
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
        } else {
          axios.post(Config.endpoints.orders.base + pElement.order.id + '/payments/braintree/check', params)
            .then((response) => {
              if (!response.data.result) {
                reject(response)
                return
              }
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
        }
      })
    },
    async refundOrder ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pElement.id + '/payments/refund', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default orderPayment
