import Config from '../../globalConfig'
import axios from 'axios'

const timePanel = {
  namespaced: true,
  state: {},
  getters: {
    canEditOthers: (state, getters, rootState, rootGetters) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async attendanceLogsRegister ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.utility.timePanel.attendanceLogs.register, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async attendanceLogsExportPdf ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        let parameters = Object.assign({}, params, rootGetters.apiCommon)
        axios({ url: Config.endpoints.utility.timePanel.attendanceLogs.export.pdf, method: 'post', responseType: 'arraybuffer', data: parameters })
            .then((response) => {
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    },
    async attendanceLogsExportCsv ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        let parameters = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.utility.timePanel.attendanceLogs.export.csv, parameters)
            .then((response) => {
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    }
  }
}

export default timePanel
