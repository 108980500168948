<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi prodotto">
        <product-form v-bind:product="product" v-loading="loading" @update="updateForm"></product-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import ProductForm from './ProductForm.vue'

  export default {
    components: {
      ProductForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {}
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'ProductAdd',
          minimumPermissionLevel: 100,
          listPath: '/prodotti/lista'
        },
        loading: false,
        canShowView: false,
        product: {
          abilitato: true,
          abilitato_3store: true,
          abilitato_h3g: true,
          abilitato_partner: true,
          quantita_disponibile: 999999,
          type: 0,
          media: [],
          colors: [],
          sim_sciolta: false,
          priority: 0
        }
      }
    },
    methods: {
      updateForm (pElement) {
        this.loading = true
        this.$store.dispatch('products/add', pElement).then((response) => {
          this.loading = false
          this.$router.push('/prodotti/lista/modifica/' + response.data.product.id)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questo prodotto', '', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
