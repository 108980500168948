<template>
  <div class="row" v-if="canShowView" v-loading="loading">
    <div class="col-12">

      <el-card title="Carica regolamenti">
        <div id="uploadTerms" class="row justify-content-center mt-3 mb-4 mx-1">
          <div class="col-12 p-0 px-0" style="max-width: 400px">
            <label>Tipo</label>
            <br />
            <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="termsType" placeholder="Seleziona..." size="large" @change="handleUploadTerms">
              <el-option :key=0 label="Generico" :value=0></el-option>
              <el-option
                v-for="item in types"
                class="select-default"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>

          <div class="col-12">
            <el-upload
              :action="termsAction"
              :on-success="handleTermsSuccess"
              :on-error="handleTermsError"
              :data="termsData"
              :before-upload="handleBeforeUpload"
              :show-file-list="false"
              accept=" .pdf"
              name="file"
              drag
              :disabled="disabledUpload"
              v-bind:class="{'disabled-terms': !canUploadTerms}"
              style="text-align: center;">
              <i class="el-icon-upload"></i>
              <div id="elUploadText" class="el-upload__text col-12 pl-5 pr-5 pb-2">Trascina qui il file da caricare</div>
            </el-upload>
          </div>
        </div>
      </el-card>

      <el-card title="Visualizza regolamenti caricati" class="mt-2">
        <p class="mt-2 pb-0 mb-0" style="font-size:17px;">Aggiorna regolamenti: <a href="#" data-tooltip="'Ricarica regolamenti'" @click.prevent="loadTerms()" class="btn-simple btn-link a-link mr-1" v-if="!loading">
          <i class="el-icon-refresh"></i>
        </a></p>
        <div class="row mt-2 mb-0" v-if="termsList && termsList.length !== 0">
          <div class="col-12 p-0">
            <div class="pl-3">

              <div v-for="item in termsList" v-if="!item.type">
                <a class="a-link" v-if="!item.type" :href="item.url" target="_blank"><strong>Regolamento generico</strong> </a>
                <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link mr-1" @click="deleteSingleTerm(item)"><i class="fa fa-times"></i></a>
                <br/>
              </div>

              <div v-for="item in termsList" v-if="item.type">
              <a class="a-link" v-if="item.type" :href="item.url" target="_blank"><strong>{{item.type.name}}</strong> </a>
                <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link mr-1" @click="deleteSingleTerm(item)"><i class="fa fa-times"></i></a>
                <br/>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-3 mb-0" v-if="termsList && termsList.length == 0">
          <div class="col-12 p-0" style="max-width: 800px">
            <p class="ml-3 font-weight-bold">Ahimé, non ci sono regolamenti!</p>
          </div>
        </div>

      </el-card>

    </div>
  </div>
</template>
<script>
  import { Breadcrumb, BreadcrumbItem, Switch as LSwitch } from 'src/components'

  export default {
    components: {
      LSwitch,
      Breadcrumb,
      BreadcrumbItem
    },
    data () {
      return {
        componentSettings: {
          minimumPermissionLevel: 100
        },
        currentState: {},
        loading: false,
        canShowView: false,
        termsMedia: [],
        termsType: null,
        types: [],
        disabledUpload: true,
        canUploadTerms: false,
        termsList: null
      }
    },
    computed: {
      termsData: function () {
        let params = Object.assign({order_type: this.termsType}, this.$store.getters.apiCommon)
        if (!this.termsType) {
          params = this.$store.getters.apiCommon
        }
        return params
      },
      termsAction: function () {
        return this.$config.endpoints.upload.terms
      }
    },
    methods: {
      deleteSingleTerm (pTermToDelete) {
        return new Promise((resolve, reject) => {
          this.askGenericConfirm('Sei sicuro di voler eliminare questo regolamento? ', 'Il file verrà eliminato e non sarà più recuperabile.', 'Sì, elimina!', 'No, ho sbagliato!').then((result) => {
            if (!result.value) {
              return
            }
            this.loading = true
            this.$store.dispatch('terms/delete', {term_url: pTermToDelete.url}).then((response) => {
              this.loading = false
              this.loadTerms()
              this.showGenericSuccess('', 'Regolamento eliminato con successo', 3000, 'Eliminato!')
            }).catch(e => {
              this.loading = false
              this.showLoadingError(e)
            })
          })
        })
      },
      handleTermsError (err, file, fileList) {
        this.loading = false
        this.showLoadingError(err, 'il caricamento del file', '', '', false)
      },
      handleTermsSuccess (response, file, fileList) {
        if (!response.result) {
          this.loading = false
          this.showLoadingError(response, 'il caricamento del file', '', '', false)
          return false
        }
        this.loadTerms()
        this.loading = false
        this.showGenericSuccess('', 'Regolamento caricato con successo', 3000, 'Caricato!')
      },
      handleBeforeUpload () {
        return new Promise((resolve, reject) => {
          this.askGenericConfirm('Sei sicuro di voler caricare questo regolamento? ', 'Il file precedente verrà eliminato e verrà sostituito con il file caricato.', 'Sì, sostituisci!', 'No, ho sbagliato!').then((result) => {
            if (!result.value) {
              reject(false)
              return false
            }
            this.loading = true
            resolve(true)
            return true
          })
        })
      },
      handleUploadTerms () {
        if (this.termsType >= 0) {
          this.disabledUpload = false
          this.canUploadTerms = true
        }
      },
      loadTerms () {
        this.loading = true
        this.$store.dispatch('terms/list').then((response) => {
          this.loading = false
          this.termsList = response.data.terms
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.types = this.$store.getters['orders/types']
      this.loadTerms()
    },
    mounted: function () {
    },
    destroyed: function () {}
  }
</script>

<style>
  @media (min-width: 992px) {
    #uploadTerms .el-upload.el-upload--text {
      min-width: 400px !important;
      width: 400px !important;
    }
  }

  @media (max-width: 576px) {
    #elUploadText .el-upload__text {
      font-size: 12px;
    }
    .el-upload-dragger {
      height: 172px !important;
    }
  }

  @media (min-width: 576px) {
    #elUploadText .el-upload__text {
      color: #606266;
      font-size: 14px;
      text-align: center;
    }
  }

  .disabled-terms .el-upload .el-upload-dragger {
    cursor: not-allowed !important;
  }
</style>
