<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model=selected
                   placeholder="Seleziona il tipo di documento"
                   size="large"
                   @change="updateValue($event)"
                   value-key="name">
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.name"
                :value=item.code>
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "identity-document-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [
                {code: '0', name: 'Carta di identià'},
                {code: '1', name: 'Patente'},
                {code: '2', name: 'Passaporto'}
            ],
            selected: this.modelValue
        }
    },
    props: {
        modelValue: {
            type: String
        },
    },
    methods: {
        updateValue: function (item) {
            this.$emit('input', this.selected)
        }
    },
    watch: {
        modelValue(val) {
            this.selected = val
        }
    }
}
</script>

<style scoped>

</style>
