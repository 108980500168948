<template>
  <div>
    <el-form label-position="top">

      <div class="row my-2">
        <div class="col-12 d-flex align-items-end">
          <el-form-item label="Tipo di attivazione" prop="operation_type" class="mb-0">
            <el-select v-loading="loading" v-model="order_product.operation_type"
                       placeholder="..." size="large">
              <el-option
                v-for="item in operationTypes"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>

            </el-select>
          </el-form-item>
          <div>
            <el-button icon="edit" class="ml-3" round @click.prevent="showNpDialog"
                       v-if="(order_product.operation_type === 3 || order_product.operation_type === 4) && order_product.np">
              Visualizza dati NP
            </el-button>
            <el-button icon="plus" class="ml-3" round @click.prevent="showNpDialog"
                       v-if="(order_product.operation_type === 3 || order_product.operation_type === 4) && !order_product.np">
              Crea NP
            </el-button>
            <el-button icon="edit" class="ml-3" round @click.prevent="migrationPreview"
                       v-if="(order_product.operation_type === 2 || order_product.operation_type === 4)">
              Richiedi codice sessione
            </el-button>
            <el-button icon="edit" class="ml-3" round @click.prevent="downloadPreview"
                       v-if="(order_product.operation_type === 2 || order_product.operation_type === 4)">
              Download preview
            </el-button>
          </div>
        </div>
      </div>


      <div class="row my-2">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4"
             v-if="order_product.operation_type === 2 || order_product.operation_type === 4">
          <el-form-item label="Codice migrazione">
            <el-input v-model="order_product.migration_number"/>
          </el-form-item>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-4"
             v-if="order_product.operation_type === 2 || order_product.operation_type === 4">
          <el-form-item label="Operatore di provenienza">
            <telephone-operator-select v-model="order_product.original_operator"/>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <el-dialog title="" v-model="isMigrationVisible" width="80%" top="5vh">
      <div class="row">
        <div class="col-12">
          <h3>Attenzione</h3>
          <p>Stai per inviare la richiesta di migrazione con i seguenti dati:</p>
          <el-button v-loading="isMigrationLoading" v-tooltip.top-center="'Scaricherà il file che stai visionando.'"
                     class="ml-3" type="info" round @click.prevent="downloadPreview"
                     v-if="(order_product.operation_type === 2 || order_product.operation_type === 4)">
              <i class="fa-regular fa-arrow-down-to-line mr-2"></i>
              Scarica la preview
          </el-button>
          <el-button v-loading="isMigrationLoading"
                     v-tooltip.top-center="'Gestirà in automatico i processi necessari alla migrazione'"
                     class="ml-3" type="primary" round
                     @click.prevent="handleMigrationAutomatically"
                     v-if="(order_product.operation_type === 2 || order_product.operation_type === 4)">
              <i class="fa-regular fa-arrow-up-from-bracket mr-2"></i>
              Invia la migrazione
          </el-button>
          <vue-json-pretty :data="raw_migration" :showLength="true">
          </vue-json-pretty>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="Dettagli NP" v-model="npDialogVisible" width="80%" top="5vh">
      <div class="row">
        <div class="col-12">
          <new-np-form v-if="!this.order_product.np || !this.order_product.np.id"
                       :order-product-id="this.order_product.id"
                       :customer="customer" @created="handleNpForm"/>
          <edit-np-form v-else :np="this.order_product.np" @edit="handleNpForm"/>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import TelephoneOperatorSelect from "@/components/Inputs/TelephoneOperatorSelect";
import NewNpForm from "@/components/Np/NewNpForm";
import EditNpForm from "@/components/Np/EditNpForm";
import axios from "axios";
import GlobalConfig from "@/globalConfig";
import Swal from "sweetalert2";
import VueJsonPretty from "vue-json-pretty";
import fileDownload from "js-file-download";
import LoadingMainPanel from "@/pages/Dashboard/Layout/LoadingMainPanel";

export default {
  name: "OrderProductActivationSection",
  components: {
    LoadingMainPanel,
    EditNpForm,
    NewNpForm,
    TelephoneOperatorSelect,
    VueJsonPretty,
  },
  props: {
    order_product: {
      required: true,
      type: Object
    },
    customer: {
      type: Object
    },
    order: {
      type: Object
    }
  },
  created() {
    this.loadOperationTypes()
  },
  data() {
    return {
      loading: false,
      isMigrationLoading: false,
      operationTypes: [],
      npDialogVisible: false,
      isMigrationVisible: false,
      raw_migration: ""
    }
  },
  methods: {
    loadOperationTypes() {
      this.loading = true
      this.$store.dispatch('orders/orderProducts/getOperationTypes').then((response) => {
        this.loading = false
        this.operationTypes = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'il caricamento dei tipi di operazione')
      })
    },
    showNpDialog() {
      this.npDialogVisible = true;
    },

    // >>[Preview del file di migrazione] <<
    async downloadPreview() {
      //TODO: Call endpoint  createXMLFilerequest for generate XML file to download at operatore
      this.isMigrationLoading = true;
      let crrOrderProduct = this.$props.order_product;

      // >>[Check if orderProduct exist]<<
      if (!crrOrderProduct) {
        await Swal.fire('Attenzione', "L'order_product non è presente. DEV:[ORDERPRODUCTACTIVATIONSECTION.vue]", "error");
        return;
      }

      let params = {...this.$store.getters.apiCommon, order_product_id: crrOrderProduct.id}
      let endpoint = GlobalConfig.endpoints.olo.downloadMigrationFile;

      axios.post(endpoint, params).then((response) => {
        //TODO: Use real name of file from endpoint
        let headerLine = response.headers['content-disposition'];
        fileDownload(response.data, response.headers['file-name'])
        //this.forceFileDownload(response, "test");
        this.isMigrationLoading = false;
      }).catch(e => {
        this.isMigrationLoading = false;
        this.showLoadingError(e)
      })
    },


    async handleMigrationAutomatically() {
      //TODO: Call endpoint  createXMLFilerequest for generate XML file to download at operatore
      this.isMigrationLoading = true;
      let crrOrderProduct = this.$props.order_product;

      // >>[Check if orderProduct exist]<<
      if (!crrOrderProduct) {
        await this.showGenericError('Attenzione', "Si è verificato un errore durante l'elaborazione dei dati relativi all'ordine. Prova a ricaricare la pagina e riprovare.");
        return;
      }

      //TODO: Auth + crrOrderProduct
      let params = {...this.$store.getters.apiCommon, order_product_id: crrOrderProduct.id}
      let endpoint = GlobalConfig.endpoints.olo.sentMigrationAutomatically;

      axios.post(endpoint, params).then((response) => {
        this.showGenericSuccess('File di migrazione', 'generato e inviato al server OLO2OLO interno con successo', 3000);
        this.$emit('refresh');
        this.isMigrationLoading = false;
        this.isMigrationVisible = false;
      }).catch(e => {
        this.isMigrationLoading = false;
        this.isMigrationVisible = false;
        this.showLoadingError(e);
      })
    },

    // >>[Preview del file di migrazione] <<
    async migrationPreview() {
      //TODO: Call endpoint  createXMLRequest for generate XML file to show at operatore

      const STATUS_RICHIEDERE_CODICE_SESSIONE = 180;
      if (this.order.status_id !== STATUS_RICHIEDERE_CODICE_SESSIONE) {
        await this.showGenericError('Stato non corretto!', 'Puoi richiedere un codice sessione solo nello stato "Richiedere codice sessione".');
        return;
      }
      let crrOrderProduct = this.$props.order_product;

      // >>[Check if orderProduct exist]<<
      if (!crrOrderProduct) {
        await this.showGenericError('Attenzione', "Si è verificato un errore durante l'elaborazione dei dati relativi all'ordine. Prova a ricaricare la pagina e riprovare.");
        return;
      }

      this.isMigrationLoading = true;
      //TODO: Auth + crrOrderProduct
      let params = {...this.$store.getters.apiCommon, order_product_id: crrOrderProduct.id}
      let endpoint = GlobalConfig.endpoints.olo.previewFile;

      //TODO: Real endpoint must be use orderproduct id for resolve some information, so we need to edit and decore it with additional parameter
      axios.post(endpoint, params).then((response) => {
        this.raw_migration = response.data.data;
        this.isMigrationVisible = true;
        this.isMigrationLoading = false;
      }).catch(e => {
        this.isMigrationLoading = false
        this.showLoadingError(e)
      })
    },
    handleNpForm(np) {
      this.npDialogVisible = false;
      this.order_product.np = np;
      //TODO emit event
    }
  }
}
</script>

<style scoped>

</style>
