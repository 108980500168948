<template>
    <div class="row contract-form" :loadig="loading">
        <div class="col-12 d-flex align-items-center">
            <p class="text-muted mr-2">ID</p>
            <p class="mb-0">#{{ modelValue.id }}</p>
        </div>
        <div class="col-12 d-flex align-items-center">
            <p class="text-muted mr-2">Data creazione</p>
            <p class="mb-0">{{ dateToItalian(modelValue.created_at) }}</p>
        </div>
        <div class="col-12 d-flex align-items-center">
            <p class="text-muted mr-2">Indirizzo IP</p>
            <p class="mb-0">{{ modelValue.ip_address ? modelValue.ip_address : 'Non registrato' }}</p>
        </div>
        <div class="col-12 d-flex align-items-center">
            <p class="text-muted mr-2">Codice OTP</p>
            <p class="mb-0">{{ modelValue.opt_code ? modelValue.opt_code : '----' }}</p>
        </div>
        <div class="col-12 d-flex align-items-center">
            <p class="text-muted mr-2">Firma OTP</p>
            <p>{{ modelValue.otp_sign ? modelValue.otp_sign : '----' }}</p>
        </div>
        <div class="col-12 d-flex align-items-center">
            <p class="text-muted mr-2">Link contratto</p>
            <el-button icon="el-icon-view" type="text" @click="openContractPage">Apri contratto</el-button>
            <el-button class="ml-4" type="text" @click="copyContractLink" icon="el-icon-copy-document">Copia link
            </el-button>
            <el-button class="ml-4" type="text" @click="downloadContract" icon="el-icon-download">Download</el-button>
            <el-button class="ml-4" type="text" @click="tsFtpUpload" icon="el-icon-upload">TS Upload</el-button>
        </div>
        <div class="col-12 d-flex align-items-center">
            <el-button icon="el-icon-message" @click="sendEmail">Invia contratto via e-mail</el-button>
        </div>
        <div class="col-12 mt-4">
            <el-button icon="el-icon-delete" type="text" class="text-danger" @click="handleDelete">Elimina contratto
            </el-button>
        </div>
    </div>
</template>

<script>
import fileDownload from "js-file-download";

export default {
    name: "contract-details",
    components: {
        fileDownload
    },
    props: {
        modelValue: {
            type: Object
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        copyContractLink() {
            this.$copyText(this.modelValue.public_signed_contract_url).then((e) => {
                this.showGenericSuccess('Link', 'copiato negli appunti', '1000')
            }).catch(e => {
                this.showGenericError('Errore!', 'Errore durante la copia negli appunti, verifica il tuo browser.')
            })
        },
        openContractPage() {
            window.open(this.modelValue.public_signed_contract_url)
        },
        handleDelete() {
            this.$confirm("Sei sicuro di voler eliminare questo contratto?", "Attanzione!", {
                confirmButtonText: 'Si, elimina!',
                cancelButtonText: 'No, ho sbagliato.',
                type: 'warning',
                center: true
            }).then(() => {
                this.loading = true;
                this.$store.dispatch('orders/deleteContract', this.modelValue.order_id).then((response) => {
                    this.loading = false;
                    if (!response.data.result) {
                        this.$message.error("Errore durante l'eliminazione del contratto.")
                    } else {
                        this.$message.success("Contratto eliminato correttamente.")
                        this.$emit('deleted');
                    }
                })
            })
        },
        downloadContract() {
            this.$store.dispatch('orders/downloadContract', this.modelValue.order_id).then((response) => {
                fileDownload(response.data, response.headers['file-name'])
            }).catch(e => {
                this.$message.error("Errore durante il download del contratto.");
            })
        },
        tsFtpUpload() {
            this.$store.dispatch('orders/tsFtpUpload', this.modelValue.order_id).then((response) => {
                this.showSuccessMessage("Contratto caricato su FTP Team System.", 4000)
            }).catch(e => {
                this.showResponseErrorMessage(e);
            })
        },
        sendEmail() {
            this.$confirm("Sei sicuro di voler inviare il contratto via e-mail?", "Attanzione!", {
                confirmButtonText: 'Si, invia!',
                cancelButtonText: 'No, ho sbagliato.',
                type: 'warning',
                center: true
            }).then(() => {
                this.loading = true;
                this.$store.dispatch('orders/sendContractByEmail', this.modelValue.order_id).then((response) => {
                    this.loading = false;
                    if (!response.data.result) {
                        this.showErrorMessage("Errore durante l'invio del contratto.")
                    } else {
                        this.showSuccessMessage("Contratto inviato correttamente.")
                    }
                })
            })
        },

    }
}
</script>

<style scoped>

.contract-form > .col-12 {
    height: 50px;
}

.contract-form p.text-muted {
    margin-bottom: 0px;
    min-width: 200px;
}
</style>
