<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model="value"
                   placeholder="Seleziona la periodicità"
                   @change="updateValue($event)"
                   value-key="value">
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.value"
                :value=item.key>
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "billing-period-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            value: this.modelValue
        }
    },
    props: {
        modelValue: null
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems() {
            this.loading = true

            this.$store.dispatch('offers/getBullingPeriods').then((response) => {
                this.loading = false
                this.items = response.data.billing_periods
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei tipi di fatturazione.')
            })
        },
        updateValue: function (item) {
            console.log(item)
            this.$emit('update:modelValue', item)
            this.$emit('change')
        }
    }
}
</script>

<style scoped>

</style>
