<template>
  <div class="py-1" v-if="modelValue">
    <div class="d-flex">
      <div>
        <div v-bind:style="avatarCSS(modelValue.user_image)"></div>
      </div>
      <div class="w-100">
        <h5 class="mb-1">{{modelValue.username}}</h5>
        <div class="border rounded py-1 px-2 bg-light">
          <div v-for="(message, index) in modelValue.messages" :key="index" class="py-1" :class="index !== Object.keys(modelValue.messages).length - 1 ? 'border-bottom' : ''" style="border-color: #a5a5a5 !important;">
            <div :class="message.type === 'note' ? 'timeline-note' : ''" class="position-relative font-italic">
              <p class="mb-0" v-html="message.content"/>
              <p class="mb-0" v-html="message.description" />
              <div class="clearfix"/>
              <div class="d-flex mt-1 align-items-center">
                <el-button class="ml-0" icon="view"
                           v-if="message.notification_content"
                           @click="notificationContent = message.notification_content; notificationContentVisible = true">Vedi contentuto notifica</el-button>
                <p class="timeline-date text-right ml-auto mt-0 mb-0 text-muted">{{dateToItalian(message.happened_at)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Contenuto notifica"
      v-model="notificationContentVisible"
      width="90%">
      <span v-html="notificationContent"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="notificationContentVisible = false">Chiudi</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "OrderTimelineItem",
  props: {
    modelValue: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      loading: false,
      notificationContentVisible: false,
      notificationContent: null
    }
  },
  methods: {
    avatarCSS(imageUrl) {
      if (imageUrl) {
        return 'background-image: url(\'' + imageUrl + '\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 35px; width: 35px; margin-right: 10px;'
      } else {
        return 'background-image: url(\'/static/img/avatar.jpg\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 35px; width: 35px; margin-right: 10px;'
      }
    },
    canDeleteNote(item) {
      return this.$store.getters['orders/canDeleteNote'](item)
    },
    handleDeleteNote(item) {
      this.askDeleteMessage('Sei sicuro di voler eliminare la nota?').then((result) => {
        if (!result.value) return
        this.loading = true
        this.$store.dispatch('orders/deleteNotes', item).then((response) => {
          this.showSuccessMessage("Nota eliminata con successo.")
          this.$emit('deleted')
        })
      }).catch(e => {
        if (e === 'cancel') {
          return
        }
        this.showErrorMessage("Errore durante l'eliminazione di questa nota.")
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.balloon {
  background: #409eff;
  color: #fff !important;
  border-radius: 10px 10px 10px 3px;
  padding: 4px 10px 4px 10px;
}

.balloon-end {
  background: #409eff;
  color: #fff !important;
  border-radius: 10px 10px 10px 3px;
  padding: 4px 10px 4px 10px;
}
.timeline-date {
  font-size: 11px;
  margin-top: 3px;
}
</style>
