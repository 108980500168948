<template>
    <div>
        <div>
            <el-button v-if="customer" @click.prevent="copyFromCustomer()" size="small">
                <i class="fa-regular fa-copy mr-2"></i>Copia informazioni dal cliente
            </el-button>
        </div>
        <el-form :model="np" ref="newNpForm" :rules="rules" v-loading="loading" label-position="top">
            <el-form-item label="Nome" prop="name">
                <el-input v-model="np.name"></el-input>
            </el-form-item>
            <el-form-item label="Cognome" prop="last_name">
                <el-input v-model="np.last_name"></el-input>
            </el-form-item>
            <el-form-item label="Operatore di provenienza" prop="original_operator">
                <telephone-operator-select v-model="np.original_operator"/>
            </el-form-item>
            <el-form-item label="Numero da portare" prop="original_number">
                <el-input v-model="np.original_number"></el-input>
            </el-form-item>
            <el-form-item label="Codice migrazione" prop="migration_code">
                <el-input v-model="np.migration_code"></el-input>
            </el-form-item>
            <el-form-item label="Tipo linea" prop="line_type">
                <np-line-type-select v-model="np.line_type"></np-line-type-select>
            </el-form-item>
            <el-form-item label="Soggetto" prop="customer_type">
                <el-radio v-model="np.customer_type" label="privato" border>Privato</el-radio>
                <el-radio v-model="np.customer_type" label="ditta_individuale" border>Ditta Individuale</el-radio>
                <el-radio v-model="np.customer_type" label="azienda" border>Azienda</el-radio>
            </el-form-item>
            <el-form-item label="Codice fiscale" prop="cf">
                <el-input v-model="np.cf"></el-input>
            </el-form-item>
            <el-form-item v-if="np.customer_type === 'ditta_individuale' || np.customer_type === 'azienda'"
                          label="Nome azienda" prop="company_name">
                <el-input v-model="np.company_name"></el-input>
            </el-form-item>
            <el-form-item v-if="np.customer_type === 'ditta_individuale' || np.customer_type === 'azienda'"
                          label="Partita Iva" prop="vat_id">
                <el-input v-model="np.vat_id"></el-input>
            </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end mt-4">
            <el-button drag type="primary" @click="validate">Inserisci Portabilità</el-button>
        </div>
    </div>

</template>

<script>

import TelephoneOperatorSelect from "@/components/Inputs/TelephoneOperatorSelect";
import NpLineTypeSelect from "@/components/Inputs/NpLineTypeSelect";

export default {
    name: "NewNpForm",
    components: {
        TelephoneOperatorSelect, NpLineTypeSelect
    },
    props: {
        orderProductId: {
            required: true,
            type: Number
        },
        customer: {
            required: false,
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            np: {
                order_product_id: this.orderProductId,
                cf: null,
                customer_type: null,
                last_name: null,
                name: null,
                vat_id: null,
                migration_code: null,
                line_type: null,
                original_number: null,
                original_operator: null,
                company_name: null
            },
            rules: {
                name: [
                    {required: true, message: 'Il nome è obbligatorio', trigger: 'blur'}
                ],
                last_name: [
                    {required: true, message: 'Il cognome è obbligatorio', trigger: 'blur'}
                ],
                migration_code: [
                    {required: true, message: 'Il codice migrazione è obbligatorio', trigger: 'blur'}
                ],
                original_operator: [
                    {required: true, message: 'Selezionare un operatore di provenienza', trigger: 'blur'}
                ],
                line_type: [
                    {required: true, message: 'Selezionare un tipo linea', trigger: 'blur'}
                ],
                customer_type: [
                    {required: true, message: 'Selezionare il tipo di soggetto', trigger: 'blur'}
                ],
                original_number: [
                    {required: true, message: 'Il numero da portare è obbligatorio', trigger: 'blur'},
                    {min: 3, message: 'Il numero è troppo corto', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        copyFromCustomer() {
            this.np.name = this.customer.first_name
            this.np.last_name = this.customer.last_name
            this.np.cf = this.customer.codice_fiscale
            this.np.customer_type = this.customer.type
            this.np.vat_id = this.customer.partita_iva
        },
        validate() {
            this.$refs['newNpForm'].validate((valid) => {
                if (valid) {
                    this.createNp();
                } else {
                    this.$message.error("Il form di creazione della NP non è compilato correttamente.")
                }
            });
        },
        createNp() {
            this.loading = true;
            this.$store.dispatch('mnp/add', this.np).then((response) => {
                this.np = response.data.mnp
                this.loading = false;
                this.$emit('created', this.np)
                this.showSuccessMessage("Np creata correttamente")
            }).catch(e => {
                this.loading = false;
                this.showErrorMessage("Errore nella creazione della NP")
            })
        }
    }
}
</script>

<style scoped>

</style>
