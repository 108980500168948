<template>
    <div class="order-media-list" v-loading="loading">
        <div class="list-group">
            <div class="list-group-item flex-column align-items-start" v-for="(media, key) in medias">
                <div class="row">
                    <div class="col-2 align-self-center">
                        <el-image :src="media.url" fit="fill" lazy style="max-width: 200px; max-height: 100px"
                                  :previewSrcList="[media.url]"></el-image>
                    </div>
                    <div class="col-10">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ media.type_formatted }}</h5>
                            <small>{{ dateToItalian(media.created_at, true) }}</small>
                        </div>
                        <div class="w-100">
                            <p class="mb-1">{{ media.original_name }}</p>
                            <small>{{ media.formatted_file_size }}</small>
                            <el-button type="text" icon="el-icon-delete" class="text-danger float-right"
                                       @click="deleteMedia(media)"></el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "order-media-list",
    components: {},
    props: {
        modelValue: {
            type: Array
        }
    },
    data() {
        return {
            loading: false,
            medias: this.modelValue,
            deleteLoading: false
        }
    },
    watch: {
        modelValue(val) {
            this.medias = val
        }
    },
    methods: {
        deleteMedia(media) {
            this.loading = true
            this.$store.dispatch('medias/delete', media).then((response) => {
                this.deleteLoading = false
                this.$emit('deleted', media)
                this.loading = false
            }).catch(e => {
                this.loading = false
                if (pConfirm) this.showLoadingError(e, 'l\'eliminazione dell\'allegato', '', false)
            })
        }
    }
}

</script>

<style scoped>

</style>
