import Config from '../../../../globalConfig'
import axios from 'axios'

const notifications = {
  namespaced: true,
  state: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pStatus) => { // Supporto per lo stato da eliminare come parametro
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pStatus) => { // Supporto per lo stato da modificare come parametro
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async edit ({commit, state, rootGetters}, pStatus) {
      return new Promise((resolve, reject) => {
        var params = Object.assign(pStatus, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.notifications.base + pStatus.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pStatus) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.statuses.notifications.base + pStatus.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async add ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.notifications.add, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getNotificationsType ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.notifications.types, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.statuses.notifications.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.statuses.notifications.base + pId, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default notifications
