import Config from '../../globalConfig'
import axios from 'axios'

const compass = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async logs ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, rootGetters.apiCommon, params)
        axios.post(Config.endpoints.compass.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default compass
