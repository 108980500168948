import Config from '../../../globalConfig'
import axios from 'axios'

import statuses from './statuses.js'
import medias from './medias.js'
import orderPayment from './orderPayment.js'
import orderProducts from './orderProducts.js'
import paymentMethods from './paymentMethods.js'
import mnp from './mnp.js'

const orders = {
    namespaced: true,
    modules: {
        medias,
        mnp,
        orderProducts,
        orderPayment,
        paymentMethods,
        statuses
    },
    state: {
        types: [],
        chronologyUpdate: 0
    },
    getters: {
        canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        canDeleteNote: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        getTypeInfoById: (state, getters, rootState, rootGetters) => (pTypeId) => {
            pTypeId = parseInt(pTypeId)
            for (let index = 0; index < getters.types.length; ++index) {
                if (getters.types[index].code === pTypeId) {
                    return getters.types[index]
                }
            }
            return {found: false, code: 0, name: 'Tutti'}
        },
        canResetActivationDate: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        types: state => state.types
    },
    mutations: {
        updateTypes: (state, pTypes) => {
            state.types = pTypes
        },
        updateChronologyEvents: (state) => {
            state.chronologyUpdate++
        },
        updateNoteEvents: (state) => {
            state.noteUpdate++
        }
    },
    actions: {
        async add({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign(params, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.add, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async addEmpty({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                var parameters = Object.assign(params, rootGetters.apiCommon)
                parameters = Object.assign({with_customer: true, with_shipping_address: true}, parameters);
                console.log(parameters)
                axios.post(Config.endpoints.orders.addEmpty, parameters)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async addNotes({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                var orderId = params.id
                var parameters = Object.assign(params, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/notes/add', parameters)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async addOrderProduct({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                var orderId = params.id
                var offerId = params.offer_id
                var relatedId = null
                var operationType = null
                var colorId = null
                if (params.related_order_product) relatedId = params.related_order_product
                if (params.operation_type) operationType = params.operation_type
                if (params.color_id) colorId = params.color_id
                var parameters = Object.assign({
                    offer_id: offerId,
                    related_order_product: relatedId,
                    operation_type: operationType,
                    color_id: colorId
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/products/add', parameters)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async delete({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + pElement.id + '/delete', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async deleteNotes({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                var noteId = params.id
                axios.post(Config.endpoints.orderNotes.base + noteId + '/delete', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async edit({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, pElement, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + pElement.id + '/edit', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async changeStatus({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign({}, params, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + params.order_id + '/change-status', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async changeType({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign({}, params, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + params.order_id + '/change-type', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getActiveUsers({commit, state, rootGetters}, groupByOrder) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, params, groupByOrder, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.activeUsers, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getActiveUsersForSingleOrder({commit, state, rootGetters}, pOrderID) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + pOrderID + '/active-users', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getById({commit, state, rootGetters}, pOrderID) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon,
                    {
                        status_details: 1,
                        customer: 1,
                        shipping_address: 1,
                        payment_method: 1,
                        order_products: 1,
                        color: true,
                        product: 1,
                        offer: 1,
                        mnp: 1,
                        options: 1,
                        receipt: 1,
                        pick_point: true,
                        active_users: true,
                        active_peoplesoft_payment_methods: 1
                    })
                axios.post(Config.endpoints.orders.base + pOrderID, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getByHash({commit, state, rootGetters}, pOrderHash) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon,
                    {
                        status_details: 1,
                        customer: 1,
                        shipping_address: 1,
                        payment_method: 1,
                        order_products: 1,
                        color: true,
                        product: 1,
                        offer: 1,
                        mnp: 1,
                        options: 1,
                        receipt: 1,
                        pick_point: true,
                        active_users: true,
                        active_peoplesoft_payment_methods: 1
                    })
                axios.post(Config.endpoints.orders.base + pOrderHash, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response.data)
                            return
                        }
                        resolve(response.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getChronology({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + pElement.id + '/chronology', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getCover({commit, state, rootGetters}, pOrder) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, rootGetters.apiCommon)
                axios({
                    url: Config.endpoints.orders.base + pOrder.id + '/print-cover',
                    method: 'post',
                    responseType: 'arraybuffer',
                    data: params
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async activateVoucherImpreseInfratel({commit, state, rootGetters}, pOrderId) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({order_id: pOrderId}, rootGetters.apiCommon)
                axios.post(Config.endpoints.infratel.activateVoucherImprese, params)
                    .then((response) => {
                        if (!response.data) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async generateVoucherReceipt({commit, state, rootGetters}, pOrderId) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, rootGetters.apiCommon)
                axios({
                    url: Config.endpoints.orders.base + pOrderId + '/delivery-voucher',
                    method: 'post',
                    responseType: 'arraybuffer',
                    data: params
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getNotes({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({users: true, types: [1], offers: true}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + pElement.id + '/notes', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getNotesAndChronology({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({
                    users: true,
                    types: [0, 1, 2, 3, 4, 5, 6, 7],
                    offers: true
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + pElement.id + '/notes', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getOrderOnlyById({commit, state, rootGetters}, pOrderID) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + pOrderID, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getOrderReceipt({commit, state, rootGetters}, pOrder) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + pOrder.id + '/get-receipt', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getTypes({commit, state, rootGetters}) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.types, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async list({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign({}, params, {
                    customer: true,
                    status: true,
                    type: true,
                    offer_short: true,
                    activation_address: true,
                    options: true
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async loadCachedTypes({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.types, rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(false)
                            return
                        }
                        commit('updateTypes', response.data.order_types)
                        resolve(true)
                    })
                    .catch((e) => {
                        reject(false)
                    })
            })
        },
        reset({commit}) {
            commit('updateChronologyEvents', 0)
            commit('updateTypes', [])
        },
        async syncOrdersCount({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, pElement, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + 'counts/sync', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async sendOrderReceipt({commit, state, rootGetters}, pOrder) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + pOrder.id + '/send-receipt', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async sendVerification({commit, state, rootGetters}, parameters) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, parameters, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + params.id + '/notifications/send-verification', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async saveSignature({commit, state, rootGetters}, parameters) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, parameters, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + parameters.orderId + '/sign', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async signWithOtp({commit, state, rootGetters}, parameters) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, parameters, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + parameters.orderId + '/sign/otp', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async forceSignWithOtp({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + orderId + '/sign/force-otp', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async contractPreview({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/contracts/show', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async deleteContract({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/contract/delete', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getOpenFiberChronologyUpdates({commit, state, rootGetters}, pOrder) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + pOrder.id + '/wholesale/openfiber/get-updates-chronology', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getOpenFiberChronologyUpdatesNew({commit, state, rootGetters}, pOrder) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + pOrder.id + '/wholesale/open-fiber/get-chronology-updates', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getOpenFiberGiacenza({commit, state, rootGetters}, pParameters) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, pParameters, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + 'wholesale/openfiber/get-giacenza', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async downloadContract({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/contract/download', params, {
                    responseType: 'arraybuffer'
                })
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async sendContractByEmail({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/send-contract', params)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async tsFtpUpload({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/ts/ftp-upload', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async fastwebWholesaleActivationOrder({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                params.order_id = orderId
                axios.post(Config.endpoints.orders.base + orderId + '/wholesale/fastweb/activationOrder', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async fastwebWholesaleInflightCancelOrder({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                params.order_id = orderId
                axios.post(Config.endpoints.orders.base + orderId + '/wholesale/fastweb/inflightCancelOrder', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async fastwebWholesaleDesuspendOrder({commit, state, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                let params = Object.assign(data, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + params.order_id + '/wholesale/fastweb/desuspendOrder', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async fetchFastwebWholesaleChronology({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                params.order_id = orderId
                axios.post(Config.endpoints.orders.base + orderId + '/wholesale/fastweb/get-chronology-updates', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async fetchTimeline({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, rootGetters.apiCommon)
                params.order_id = orderId
                axios.post(Config.endpoints.orders.base + orderId + '/timeline', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async unWholesale({commit, state, rootGetters}, id) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + id + '/un-wholesale', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async forceWholesale({commit, state, rootGetters}, data) {
            var params = Object.assign({}, data, rootGetters.apiCommon)
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + data.order_id + '/set-wholesale', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async desuspend({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                const requestParams = Object.assign({}, {note: params.note}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + params.id + '/wholesale/open-fiber/desuspension', requestParams)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async deactivation({commit, state, rootGetters}, id) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.orders.base + id + '/wholesale/open-fiber/deactivation', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async openfiberWholesaleActivationOrder({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                const requestParams = Object.assign({}, {note: params.note}, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + params.id + '/wholesale/open-fiber/activationSetup', requestParams)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async openfiberWholesaleReschedule({commit, state, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                let orderId = data.order_id
                let params = Object.assign({}, data, rootGetters.apiCommon)
                axios.post(Config.endpoints.orders.base + orderId + '/wholesale/open-fiber/reschedule', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async openfiberWholesaleCancelOrder({commit, state, rootGetters}, orderId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, rootGetters.apiCommon)
                params.order_id = orderId
                axios.post(Config.endpoints.orders.base + orderId + '/wholesale/open-fiber/cancel', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
    }
}

export default orders
