<template>
    <div id="order-step-customer">
        <div class="row mt-2">
            <div class="col-12 text-center">
                <h4 class="m-0">Dati di contatto</h4>
            </div>
            <div class="col-12 mt-4">
                <el-form ref="form" :model="customer" label-width="180px" :status-icon="true">
                    <el-form-item label="Nome">
                        <el-input v-model="customer.first_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Cognome">
                        <el-input v-model="customer.last_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="customer.email"></el-input>
                    </el-form-item>
                    <el-form-item label="Cellulare">
                        <el-input v-model="customer.mobile_phone_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Recapito alternativo">
                        <el-input v-model="customer.phone_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Soggetto">
                        <el-radio v-model="customer.type" label="privato" border>Privato</el-radio>
                        <el-radio v-model="customer.type" label="ditta_individuale" border>Ditta Individuale</el-radio>
                        <el-radio v-model="customer.type" label="azienda" border>Azienda</el-radio>
                    </el-form-item>

                    <div v-if="customer.type === 'privato'">
                        <el-form-item label="Codice fiscale" error="Il codice fiscale non sembra valido."
                                      :validate-status="cfValid"
                                      :show-message="cfChecked">
                            <el-input v-model="customer.codice_fiscale" @blur="cfLookUp"></el-input>
                        </el-form-item>
                    </div>
                    <div v-loading="checkingCf">
                        <el-form-item label="Sesso">
                            <el-select v-model="customer.sex">
                                <el-option label="Uomo" value="m"></el-option>
                                <el-option label="Donna" value="f"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Nazionalità">
                            <el-select v-model="customer.nazionalita">
                                <el-option label="Italiana" :value="1"></el-option>
                                <el-option label="Altro" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Data nascita">
                            <el-date-picker
                                v-model="customer.data_nascita"
                                type="date"
                                placeholder="Seleziona..."
                                format="DD-MM-YYYY"
                                value-format="YYYY-MM-DD"
                                language="it">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="Luogo di nascita">
                            <el-input v-model="customer.luogo_nascita"></el-input>
                        </el-form-item>
                        <el-form-item label="Provincia di nascita">
                            <province-select v-model="customer.provincia_nascita"/>
                        </el-form-item>
                    </div>

                    <div v-if="customer.type === 'ditta_individuale' || customer.type === 'azienda'">
                        <h4 class="text-center">Dati azienda</h4>
                        <el-form-item label="Partita IVA">
                            <el-input v-model="customer.partita_iva"></el-input>
                        </el-form-item>
                        <el-form-item label="Ragione sociale">
                            <el-input v-model="customer.ragione_sociale"></el-input>
                        </el-form-item>
                        <el-form-item label="PEC">
                            <el-input v-model="customer.pec"></el-input>
                        </el-form-item>
                        <el-form-item label="SDI">
                            <el-input v-model="customer.sdi"></el-input>
                        </el-form-item>
                        <el-form-item label="Codice fiscale">
                            <el-input v-model="customer.codice_fiscale"></el-input>
                        </el-form-item>
                        <div v-if="customer.type === 'azienda'">
                            <el-form-item label="Codice fiscale società">
                                <el-input v-model="customer.codice_fiscale_societa"></el-input>
                            </el-form-item>
                            <el-form-item label="Indirizzo sede legale">
                                <el-input v-model="customer.indirizzo_sede_legale"></el-input>
                            </el-form-item>
                            <el-form-item label="Civico sede legale">
                                <el-input v-model="customer.civico_sede_legale"></el-input>
                            </el-form-item>
                            <el-form-item label="Comune sede legale">
                                <el-input v-model="customer.comune_sede_legale"></el-input>
                            </el-form-item>
                            <el-form-item label="CAP sede legale">
                                <el-input v-model="customer.cap_sede_legale"></el-input>
                            </el-form-item>
                            <el-form-item label="Provincia sede legale">
                                <province-select v-model="customer.provincia_sede_legale"></province-select>
                            </el-form-item>
                            <el-form-item label="Provincia REA">
                                <province-select v-model="customer.provincia_rea"></province-select>
                            </el-form-item>
                            <el-form-item label="Anno REA">
                                <el-input v-model="customer.anno_rea"></el-input>
                            </el-form-item>
                            <el-form-item label="Numero REA">
                                <el-input v-model="customer.numero_rea"></el-input>
                            </el-form-item>
                            <el-form-item label="Forma giuridica">
                                <giuridic-form-select v-model="customer.forma_giuridica"></giuridic-form-select>
                            </el-form-item>
                        </div>
                    </div>

                </el-form>
            </div>
        </div>

        <div class="row mt-4 justify-content-end">
            <div class="col-auto">
                <el-button type="primary" @click="saveCustomerInfo">Avanti<i class="ml-2 fa-regular fa-forward-step"></i></el-button>
            </div>
        </div>

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";

export default {
    name: "StepCustomer",
    components: {
        GiuridicFormSelect,
        ProvinceSelect
    },
    data() {
        return {
            customer: {},
            cfValid: null,
            cfChecked: false,
            checkingCf: false
        }
    },
    mounted() {
        this.customer = this.modelValue

        if (this.customer && this.customer.codice_fiscale && this.customer.type === 'privato') {
            this.cfLookUp()
        }
    },
    props: {
        modelValue: {
            type: Object
        },
    },
    methods: {
        saveCustomerInfo() {
            this.loading = true
            this.$store.dispatch('customers/edit', this.customer).then((response) => {
                this.loading = false
                this.customer = response.data.customer
                this.$emit('input', this.customer)
                this.$emit('nextStep')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo cliente')
            })
        },
        cfLookUp() {

            if (!this.customer.codice_fiscale) return;

            this.checkingCf = true;
            this.$store.dispatch('utility/cfLookUp', this.customer.codice_fiscale).then((response) => {

                if (response.data.data.is_valid) {
                    this.cfValid = 'success';

                    if (!this.customer.data_nascita) {
                        this.customer.data_nascita = response.data.data.birth_date;
                    }
                    if (!this.customer.luogo_nascita) {
                        this.customer.luogo_nascita = response.data.data.city_details.comune;
                    }
                    if (!this.customer.provincia_nascita) {
                        this.customer.provincia_nascita = response.data.data.city_details.provincia;
                    }
                    if (!this.customer.sex) {
                        this.customer.sex = response.data.data.sex;
                    }

                } else {
                    this.cfValid = 'error'
                }

            }).catch(e => {
            }).finally(() => {
                this.checkingCf = false
                this.cfChecked = true
            })
        }
    }
}
</script>

<style scoped>

</style>
