<template>
    <div v-loading="loading" v-if="order && order.id">

        <div v-if="isLoading"
             style="position: fixed; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.9); z-index: 1000; top: 0; left: 0;">
            <h3 style="text-align: center; font-weight: 200 !important;">Verifico allegato...</h3>
        </div>

        <div class="row mt-4">

            <div class="col-12">

                <div class="row">
                    <div class="col-12 d-flex justify-content-between">
                        <h4 v-observe-visibility="visibilityChanged" class="m-0">Allegati
                            <a href="#" data-tooltip="'Ricarica allegati'" @click.prevent="loadAttachments()"
                               class="btn-simple btn-link a-link" v-if="!loading"><i class="el-icon-refresh"
                                                                                     style="font-size: 19px;"></i></a>
                        </h4>
                        <el-button-group>
                            <el-button @click="activateVoucher">Attiva voucher Infratel</el-button>
                            <el-button @click="generateVoucher">Genera verbale voucher</el-button>
                            <el-button icon="plus" @click="openNewAttachmentForm">Aggiungi allegato</el-button>
                        </el-button-group>
                    </div>
                </div>

                <div class="row mt-5" v-if="attachments.length <= 0">
                    <div class="col-12">
                        <span>Non ci sono allegati per questo ordine.</span>
                    </div>
                </div>

                <div v-if="attachments.length > 0" class="row mt-5">
                    <div class="col-12">
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                v-for="attachment in attachments">
                                <div>
                                    <p class="mb-0"><b>{{ attachment.type_formatted }}</b>
                                        <br/>
                                        <i>{{ attachment.original_name }} ({{ attachment.formatted_file_size }})</i>
                                        <br/>
                                        <i>Caricato il: {{ dateToItalian(attachment.created_at, true) }}</i>
                                    </p>
                                </div>
                                <div>

                                    <el-button-group>
                                        <el-button data-tooltip="'Apri'" type="primary" icon="view"
                                                   @click="openAttachment(attachment)"></el-button>
                                        <el-button data-tooltip="'Download'" type="primary" icon="download"
                                                   @click="downloadAttachment(attachment)"></el-button>
                                        <el-button data-tooltip="'Rinomina'" type="primary" icon="edit"
                                                   @click="openEditAttachmentForm(attachment)"></el-button>
                                        <el-button v-if="isDeveloper" target="_blank" data-tooltip="'Debug link'"
                                                   type="primary"
                                                   icon="link" @click="openDebugLink(attachment)"></el-button>
                                        <el-button data-tooltip="'Elimina'" type="danger" icon="delete"
                                                   @click="confirmDeleteAttachment(attachment)"></el-button>
                                    </el-button-group>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h4>Contratto</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" v-if="order.contract">
                        <contract-details v-model="order.contract" @deleted="contractDeleted"/>
                    </div>
                    <div class="col-12" v-else>
                        <h5 class="text-warning"><i class="warning-outline"></i> Non è presente nessun contratto per
                            questo
                            ordine</h5>
                        <el-button type="primary" @click="signContract">Genera contratto</el-button>
                        <hr>
                        <el-button type="primary" plain @click="forceSignContract">Genera e firma con OTP</el-button>
                    </div>
                </div>
            </div>

        </div>

        <el-dialog title="Nuovo allegato" v-model="newAttachment.formVisible">
            <el-select v-loading="loading" style="width: 100%" class="select-default mb-3" v-model="newAttachment.type"
                       filterable placeholder="..." size="large">

                <el-option
                    class="select-default"
                    :key=0
                    label="Scegli un tipo..."
                    :value=0>
                </el-option>

                <el-option
                    v-for="item in types"
                    class="select-default"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
            </el-select>

            <el-upload
                :action="attachmentAddAction"
                :on-success="handleAttachmentSuccess"
                :on-error="handleAttachmentError"
                :before-upload="checkAttachmentBeforeUpload"
                :data="uploadFileData"
                :file-list="uploadsFileList"
                :limit=10
                :on-remove="handleAttachmentRemoval"
                v-if="newAttachment.type"
                name="media_file"
                drag
                multiple
                style="text-align: center;"
                class="mt-4">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Trascina qui il file da caricare <em>o clicca per sceglierlo</em></div>
            </el-upload>

            <span slot="footer" class="dialog-footer">
            <el-button @click="newAttachment.formVisible = false">Chiudi</el-button>
          </span>
        </el-dialog>


        <el-dialog title="Rinomina allegato" v-model="editAttachment.formVisible">
            <div class="row mb-4">
                <div class="col-12">
                    <label>Modifica il tipo</label>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="currentAttachment.type" filterable placeholder="..." size="large">

                        <el-option
                            class="select-default"
                            :key=0
                            label="Scegli un tipo..."
                            :value=0>
                        </el-option>

                        <el-option
                            v-for="item in types"
                            class="select-default"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </div>

                <div class="col-12">
                    <el-input type="text"
                              label="Rinomina"
                              name="original_name"
                              v-model="currentAttachment.name">
                    </el-input>
                </div>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button class="mr-2" @click="editAttachment.formVisible = false">Annulla</el-button>
                <el-button type="primary" icon="check" @click="renameAttachment">Salva</el-button>
            </div>

        </el-dialog>

    </div>
</template>
<style>
ul.attachments-list li {
    margin-bottom: 10px;
}

.check-optimo {
    margin-bottom: 0;
}

.check-optimo label {
    margin-top: 0;
    line-height: 12px !important;
}

.body-overflow-hidden {
    overflow: hidden !important;
}

.video-js {
    margin: 0 auto;
    max-width: 400px;
    max-height: 600px;
}
</style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'
import {videoPlayer} from 'vue-video-player'
import 'video.js/dist/video-js.css'
import ContractForm from "@/components/Contract/ContractDetails";
import ContractDetails from "@/components/Contract/ContractDetails";
import fileDownload from "js-file-download";

export default {
    mixins: [],
    components: {
        ContractDetails,
        ContractForm,
        LSwitch,
        LCheckbox,
        videoPlayer
    },
    props: {
        order: {},
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderAttachments'
            },
            signCheckboxes: [],
            uploadsFileList: [],
            attachments: [],
            optimo: [],
            types: [],
            loading: false,
            isLoading: false,
            contentLoaded: false,
            currentOrder_id: null,
            newAttachment: {
                formVisible: false,
                type: 0
            },
            editAttachment: {
                formVisible: false,
                type: 0
            },
            currentAttachment: {
                type: {},
                name: '',
                id: 0
            }
        }
    },
    watch: {
        order() {
            if (!this.order.id) {
                return;
            }
            this.loadAttachments()
        }
    },
    computed: {
        attachmentAddAction: function () {
            if (!this.order.id) return false
            return this.$config.endpoints.orders.base + this.order.id + '/medias/add'
        },
        uploadFileData: function () {
            let params = Object.assign({media_type: this.newAttachment.type}, this.$store.getters.apiCommon)
            return params
        }
    },
    methods: {
        checkAttachmentBeforeUpload() {
            if (this.newAttachment.type < 1) {
                this.showLoadingError('Devi selezionare un tipo.', 'l\'aggiunta dell\'allegato', '', '', false)
                return false
            }
            return true
        },
        checkAttachmentAfterUpload(pAttachment) {
            this.isLoading = true
            this.$store.dispatch('orders/medias/check', pAttachment).then((response) => {
                this.isLoading = false
                if (response.data.media_check.result === false) {
                    this.showGenericError('Allegato non valido', 'Verifica avvenuta con errore "' + response.data.media_check.errorMessage + '"', 3000, 'Allegato valido')
                } else {
                    this.showGenericSuccess('', 'Verifica avvenuta con successo', '3000', 'Allegato valido.')
                }
            }).catch(e => {
                this.isLoading = false
                this.showLoadingErrorWithoutRetry(e, 'la verifica della validità dell\'allegato', true)
            })
        },
        activateVoucher() {
            this.askGenericConfirm('Sei sicuro?', 'Premendo Sì, <strong>attiverai il voucher su Infratel</strong>.').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('orders/activateVoucherImpreseInfratel', this.order.id).then((response) => {
                    //Reload order
                    this.$emit('refresh')
                    this.showSuccessMessage("Il voucher è stato attivato sul portale Infratel", 2000);
                }).catch(e => {
                    this.showResponseErrorMessage(e)
                }).finally(() => {
                    this.loading = false
                })
            });
        },
        generateVoucher() {
            this.askGenericConfirm('Sei sicuro?', 'Premendo Sì, <strong>genererai il verbale di consegna</strong>.').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('orders/generateVoucherReceipt', this.order.id).then((response) => {
                    this.loadAttachments()
                }).catch(e => {
                    this.showResponseErrorMessage(e)
                }).finally(() => {
                    this.loading = false
                })
            });
        },
        compressMedia(pAttachment) {
            this.askGenericConfirm('Sei davvero sicuro sicuro?', 'Premendo Sì, <strong>comprimerai questo allegato</strong>.').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('orders/medias/compress', pAttachment).then((response) => {
                    this.loadAttachments()
                    this.loading = false
                }).catch(e => {
                    this.loading = false
                    this.showLoadingErrorWithoutRetry(e, 'la compressione dell\'allegato', true)
                })
            })
        },
        confirmDeleteAttachment(pAttachment) {
            var attachmentName = pAttachment.original_name
            this.$confirm('Stai per eliminare definitivamente l\'allegato ' + attachmentName + '. Sei sicuro?', 'Attenzione!', {
                confirmButtonText: 'Si, elimina!',
                cancelButtonText: 'No, ho sbagliato.',
                type: 'warning',
                center: true
            }).then(() => {
                this.deleteAttachment(pAttachment, true)
            })
        },
        copyPdaLink() {
            this.$copyText(this.order.pda_url).then((e) => {
                this.showGenericSuccess('Link', 'copiato negli appunti', '1000')
            }).catch(e => {
                this.showGenericError('Errore!', 'Errore durante la copia negli appunti, verifica il tuo browser.')
            })
        },
        deleteAttachment(pAttachment, pConfirm = true) {
            this.loading = true
            this.$store.dispatch('medias/delete', pAttachment).then((response) => {
                this.loading = false
                var index = -1
                for (let element of this.attachments) {
                    if (pAttachment.id !== element.id) continue
                    index = this.attachments.indexOf(element)
                    if (index !== -1) {
                        this.attachments.splice(index, 1)
                        break
                    }
                }
                this.$message({
                    type: 'success',
                    message: 'Allegato eliminato'
                });
            }).catch(e => {
                this.loading = false
                this.$message({
                    type: 'error',
                    message: "Errore durante l'eliminazione dell'allegato."
                });
            })
        },
        openAttachment(attachment) {
            if (!attachment.url) {
                this.showErrorMessage('Prova a ricaricare la pagina.')
                return;
            }
            var win = window.open(attachment.url, '_blank')
            win.focus()
        },
        downloadAttachment(attachment) {
            this.$store.dispatch('orders/medias/download', attachment.id).then((response) => {
                fileDownload(response.data, response.headers['file-name'])
            }).catch(e => {
                this.errorMessage("Errore durante il download del contratto.");
            })
        },
        handleAttachmentError(err, file, fileList) {
            this.showLoadingError(err, 'il caricamento del file', '', '', false)
        },
        handleAttachmentRemoval(file, fileList) {
            for (let i = this.attachments.length - 1; i >= 0; --i) {
                let cFile = this.attachments[i]
                let cName = cFile.original_name
                if (cName === file.name) {
                    this.deleteAttachment(cFile, true)
                    break
                }
            }
        },
        handleAttachmentSuccess(response, file, fileList) {
            if (!response.result) {
                if (this.newAttachment.type < 1) {
                    this.showLoadingError('Devi selezionare un tipo.', 'l\'aggiunta dell\'allegato', '', '', false)
                    return false
                }
                this.showLoadingError(response, 'il caricamento del file', '', '', false)
                return false
            }
            this.attachments.push(response.media)
        },
        forceSignContract() {
            this.askGenericConfirm("Generare e firmare il contratto?", 'Premendo sì genererai il contratto e lo firmerai con OTP al posto del cliente. Sei sicuro?', 'Sì', 'No, annulla l\'operazione!').then((value) => {
                if (!value.isConfirmed) {
                    return
                }
                this.$store.dispatch('orders/forceSignWithOtp', this.order.id).then((response) => {
                    this.$emit('refresh')
                }).catch(e => {
                    this.showResponseErrorMessage(e)
                })
            })
        },
        loadAttachments() {
            if (!this.order.id) {
                return;
            }
            this.loading = true
            this.$store.dispatch('orders/medias/list', {id: this.order.id}).then((response) => {
                this.loading = false
                this.attachments = response.data.data
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento degli allegati', true)
            })
        },
        loadTypes() {
            this.loading = true
            this.$store.dispatch('medias/getMediaTypes').then((response) => {
                this.types = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showResponseErrorMessage(e)
            })
        },
        openEditAttachmentForm(attachment) {
            this.currentAttachment.id = attachment.id
            this.currentAttachment.type = attachment.type.code
            this.currentAttachment.name = attachment.original_name
            this.editAttachment.formVisible = true
            this.editAttachment.type = 0
        },
        openNewAttachmentForm() {
            this.newAttachment.formVisible = true
            this.newAttachment.type = 0
        },
        renameAttachment() {
            this.loading = true
            this.$store.dispatch('orders/medias/edit', this.currentAttachment).then((response) => {
                this.showGenericSuccess('Il nome di questo allegato', 'è stato aggiornato con successo')
                this.editAttachment.formVisible = false
                this.loadAttachments()
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il rinomina dell\'allegato')
            })
        },
        resetOptimo() {
            for (let element of this.attachments) {
                if (!element.optimoSend) continue
                element.optimoSend = false
            }
            this.optimo = []
        },
        visibilityChanged(isVisible, entry) {
            if (this.order && this.order.id && this.order.id !== this.currentOrder_id) {
                this.contentLoaded = false
            }
            if (isVisible && !this.contentLoaded) {
                this.contentLoaded = true
                this.currentOrder_id = this.order.id
                this.loadAttachments()
                this.loadTypes()
            }
        },
        openDebugLink(attachment) {
            window.open(attachment.google_cloud_storage_url, '_blank')
        },
        signContract() {
            let link = this.$router.resolve({path: '/contratto', query: {hash: this.order.hash}});
            window.open(link.href, '_blank');
        },
        contractDeleted() {
            this.order.contract = null;
        }
    },
    created: function () {
    },
    mounted: function () {
        this.loadAttachments()
        this.loadTypes()
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
