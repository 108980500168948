<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Modifica opzione">
        <div slot="header">
          <h4 class="title mt-0">Modifica di <strong>{{product_alert.name}}</strong> <small>(ID: {{product_alert.id}})</small></h4>

          <h5 v-if="!loading">Creato il: {{dateToItalian(product_alert.created_at, true)}}</h5>
          <p class="text-muted" v-if="!loading">Ultima modifica: {{dateToItalian(product_alert.updated_at, true)}}</p>

          <div class="row">
            <div class="col-12 col-lg-4 col-xl-3">
              <router-link :to="{path:'/productAlerts/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista avvisi prodotto
                </span>
                </button>
              </router-link>
            </div>
          </div>

        </div>
        <product-alert-form v-bind:product_alert_form="product_alert" v-loading="loading" @update="updateProductAlert" @delete="deleteElement"></product-alert-form>

      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from '../../../components'
  import ProductAlertForm from './ProductAlertForm'
  import Swal from "sweetalert2";

  export default {
    components: {
      ProductAlertForm: ProductAlertForm,
      LPagination,
      Breadcrumb,
      BreadcrumbItem
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'EditProductAlert',
          minimumPermissionLevel: 200,
          listPath: '/productAlerts/lista/'
        },
        loading: false,
        canShowView: false,
        product_alert: {}
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('productAlerts/getById', {id: this.$route.params.id}).then((response) => {
          this.product_alert = response.data.product_alert
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questo avviso prodotto')
        })
      },
      updateProductAlert (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('productAlerts/edit', pElement).then((response) => {
          this.loading = false
          this.product_alert = response.data.product_alert
          this.showGenericSuccess('Avviso prodotto')
          // this.$router.push(this.componentSettings.listPath)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questo avviso prodotto', '', false)
        })
      },
      deleteElement (pElement) {
        Swal.fire(this.deleteMessage(this.product_alert.name)).then((result) => {
          if (!result.value) return
          this.$store.dispatch('productAlerts/delete', pElement).then(() => {
            Swal.fire('Eliminato!',
              'Stato eliminato!',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione del template', '', false)
          })
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
