<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Giacenza Open Fiber" v-loading="loading">
        <div>
          <div class="col-12">
            <p>Puoi caricare il file delle giacenze Open Fiber aggiornato.</p>
            <el-upload
              ref="csvUpload"
              :on-success="handleCsvSuccess"
              :on-error="handleCsvError"
              :before-upload="handleCsvBefore"
              :action="csvImportAction"
              :show-file-list="false"
              accept="application/csv"
              name="file"
              :limit=1
              :data="csvImportData"
              :auto-upload="true">
              <el-button slot="trigger">
                      <span class="btn-label">
                         <i class="fa fa-plus"></i> Importa XLS
                      </span>
              </el-button>
            </el-upload>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    computed: {
      csvImportData: function () {
        let params = Object.assign({}, this.$store.getters.apiCommon)
        return params
      },
      csvImportAction: function () {
        return this.$config.endpoints.otherupdates.openfiber.loadgiacenza;
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'OF-GiacenzaLoad',
          minimumPermissionLevel: 100,
        },
        loading: false,
        defaultValue: null,
        canShowView: false,
      }
    },
    methods: {
      handleCsvBefore () {
        return new Promise((resolve, reject) => {
          this.askGenericConfirm('Lo vuoi davvero?', 'Vuoi procedere al caricamento di un nuovo file di giacenza?').then((result) => {
            if (!result.value) {
              reject(false)
              return false
            }
            resolve(true)
            this.loading = true
            return true
          })
        })
      },
      handleCsvError (err, file, fileList) {
        this.loading = false
        this.$refs.csvUpload.clearFiles()
        this.showLoadingErrorWithoutRetry(err, 'il caricamento del file')
      },
      handleCsvSuccess (response, file, fileList) {
        this.$refs.csvUpload.clearFiles()
        this.loading = false
        if (!response.result) {
          this.showLoadingErrorWithoutRetry(response)
        } else {
          this.askGenericSuccess('Fatto!', 'La giacenza aggiornata è stata caricata correttamente.').then(() => {
          })
        }
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>

<style></style>
