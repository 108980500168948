<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model="selected"
                   placeholder="Seleziona la fascia"
                   @change="updateValue($event)"
                   filterable>
            <el-option
                v-for="(item) in items"
                class="select-default"
                :key="item.label"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "fascia-velocita-agcom-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            selected: this.modelValue
        }
    },
    props: {
        modelValue: {
            type: String
        },
    },
    watch: {
        modelValue(val) {
            this.selected = val
        }
    },
    mounted() {
        this.loadItems().then(() => {
            this.selected = this.modelValue
        })
    },
    methods: {
        async loadItems() {
            this.loading = true
            this.$store.dispatch('utility/getFasceVelocitaAgcom').then((response) => {
                this.loading = false
                this.items = response
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle fasce di velocità AGCOM')
            })
        },
        updateValue: function (item) {
            this.$emit('update:modelValue', this.selected)
        }
    }
}
</script>

<style scoped>

</style>
