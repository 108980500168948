import Config from '../../../globalConfig'
import axios from 'axios'

const orderProducts = {
  namespaced: true,
  state: {
    operationTypes: []
  },
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canResetDate: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    operationTypes: state => state.operationTypes
  },
  mutations: {
    updateOperationTypes: (state, pValue) => {
      state.operationTypes = pValue
    }
  },
  actions: {
    reset ({commit}) {
      commit('updateOperationTypes', [])
    },
    async deactivate ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/deactivate', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async activate ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/activate', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async edit ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async editBillingPeriod ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/edit-billing-period', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {

        console.log('refresh order product 10');
        var params = Object.assign({product: true, offer: true, mnp: true, color: true, options: true}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pId, params)
          .then((response) => {

            console.log('refresh order product 11');
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            console.log(e);
            console.log('refresh order product 12');
            reject(e)
          })
      })
    },

    async generateOloOrderCode({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pId + '/generate-new-olo-order-code', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    async getOperationTypes ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        if (getters.operationTypes.length > 0) {
          resolve(getters.operationTypes)
          return
        }
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.operationTypes, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateOperationTypes', response.data.operation_types)
            resolve(response.data.operation_types)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async addOption ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/add-option', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async removeOption ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/remove-option', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async updateOptions ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/update-options', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orderProducts.base + pElement.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async editOffer ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.orderProducts.base + params.order_product_id + '/edit-offer', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default orderProducts
