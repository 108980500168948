import Config from '../../globalConfig'
import axios from 'axios'

const preorderForms = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAttachmentTemplatePaths ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.preorderForms.templatePaths, params)
            .then((response) => {
              if (!response.data.result) {
                reject(response)
                return
              }
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    }
  }
}

export default preorderForms

