<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Modifica opzione">
                <div slot="header">
                    <h4 class="title mt-0">Modifica di <strong>{{ offer_option.name }}</strong> <small>(ID:
                        {{ offer_option.id }})</small></h4>

                    <h5 v-if="!loading">Creato il: {{ dateToItalian(offer_option.created_at, true) }}</h5>
                    <p class="text-muted" v-if="!loading">Ultima modifica:
                        {{ dateToItalian(offer_option.updated_at, true) }}</p>


                    <div class="row">
                        <div class="col-12 col-md-3">
                            <router-link :to="{path:'/offerte/opzioni/lista'}">
                                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista Opzioni
                </span>
                                </button>
                            </router-link>
                        </div>
                    </div>

                </div>
                <offer-option-form v-bind:offer_option="offer_option" v-loading="loading"></offer-option-form>
            </el-card>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
import OfferOptionForm from './OfferOptionForm'

export default {
    components: {
        OfferOptionForm,
        LPagination,
        Breadcrumb,
        BreadcrumbItem
    },
    computed: {},
    watch: {
        '$route.params.id': function (val) {
            if (val) {
                this.updateData()
            }
        }
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OfferOptionEdit',
                minimumPermissionLevel: 100,
                listPath: '/offerte/opzioni/lista'
            },
            loading: false,
            canShowView: false,
            offer_option: {}
        }
    },
    methods: {
        updateData() {
            this.loading = true
            this.$store.dispatch('offerOption/getById', this.$route.params.id).then((response) => {
                this.offer_option = response.data.offer_option
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il recupero dei dati di questa opzione')
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.updateData()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
