<template>

    <div>
        <el-form v-model="offerOption" label-position="top">
            <div class="row">
                <div class="col-12 col-md-4">
                    <el-form-item label="Nome mostrato nel gestionale" prop="internal_name" class="mb-0">
                        <el-input v-model="offerOption.internal_name"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item label="Nome mostrato al cliente" prop="name" class="mb-0">
                        <el-input v-model="offerOption.name"/>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-4">
                    <el-form-item label="Codice variabile contratto" prop="contract_code" class="mb-0">
                        <el-input v-model="offerOption.contract_code"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4">
                    <el-form-item label="Importo da pagare subito" prop="cash_amount" class="mb-0">
                        <el-input-number class="w-100" :precision="2" :step="0.1" v-model="offerOption.cash_amount"
                                         controls-position="right"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item label="Costo ricorrente" prop="recurrent_cost" class="mb-0">
                        <el-input-number class="w-100" :precision="2" :step="0.1" v-model="offerOption.recurrent_cost"
                                         controls-position="right"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item label="Periodo (es. al mese)" prop="cycle_text" class="mb-0">
                        <el-input v-model="offerOption.cycle_text"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Costi IVA Inclusa</label>
                    <br/>
                    <l-switch v-model="offerOption.vat_included" type="primary" on-text="SÌ" off-text="NO"></l-switch>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4">
                    <el-form-item label="Categoria" prop="category" class="mb-0">
                        <el-select v-model="offerOption.category" class="w-100">
                            <el-option label="VOIP" value="voip"/>
                            <el-option label="ROUTER" value="router"/>
                            <el-option label="SPEED_UPGRADE" value="speed_upgrade"/>
                            <el-option label="REPEATER" value="repeater"/>
                            <el-option label="SMART_HOME" value="smart_home"/>
                            <el-option label="ALTRO" value="other"/>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-4">
                    <el-form-item label="Percorso Icona su checkout" prop="public_icon_name" class="mb-0">
                        <el-input v-model="offerOption.public_icon_name"/>
                    </el-form-item>
                </div>

                <div class="col-md-4 col-12">
                    <el-form-item class="mb-0">
                        <slot name="label">
                            <label for="hidden_public" class="el-form-item__label w-100" style="text-align: left">Nascosto
                                al pubblico</label>
                        </slot>
                        <el-switch v-model="offerOption.hidden_public" type="primary" active-text="Nascosto"
                                   inactive-text="Visibile"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6" v-if="offerOption.category === 'router'">
                    <el-form-item label="Tag di compatibilità (inserire il tag che verrà usato su altri prodotti)"
                                  prop="compatibility_tag" class="mb-0">
                        <el-input v-model="offerOption.compatibility_tag"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6" v-if="offerOption.category !== 'router'">
                    <el-form-item label="Compatibile con (inserire il tag usato sui router)" prop="compatible_with_tag"
                                  class="mb-0">
                        <el-input v-model="offerOption.compatible_with_tag"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Velocità massima download WAN o profilo (es.: 1000)" prop="max_download_speed"
                                  class="mb-0">
                        <el-input v-model="offerOption.max_download_speed"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Descrizione HTML sulla procedura online" prop="public_description"
                                  class="mb-0">
                        <el-input type="textarea" :rows="4" v-model="offerOption.public_description"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Descrizione HTML LUNGA sulla procedura online" prop="public_description"
                                  class="mb-0">
                        <el-input type="textarea" :rows="4" v-model="offerOption.public_long_description"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Note</label>
                    <br/>
                    <rich-text-editor v-model="offerOption.description" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">

                    <el-button class="mr-2" type="danger" icon="delete" @click.prevent="deleteElement"
                               v-if="offerOption.id">Elimina
                    </el-button>
                    <el-button type="primary" icon="check" @click.prevent="validateForm">
                        Salva
                    </el-button>

                </div>
            </div>
        </el-form>
        <!--    <OfferOptionMassiveAssignForm v-bind:offerOption="offerOption"></OfferOptionMassiveAssignForm>-->
    </div>
</template>
<script>
import {Switch as LSwitch} from 'src/components/index'
import Editor from '@tinymce/tinymce-vue'
import OfferOptionMassiveAssignForm from './OfferOptionMassiveAssignForm'
import RichTextEditor from "@/components/RichTextEditor.vue";

export default {
    components: {
        RichTextEditor,
        OfferOptionMassiveAssignForm,
        LSwitch,
        'editor': Editor
    },
    props: {
        offer_option: {}
    },
    data() {
        return {
            componentSettings: {
                listPath: '/offerte/opzioni/lista'
            },
            content: '',
            loading: false,
            modelValidations: {
                name: {
                    required: true
                }
            }
        }
    },
    watch: {},
    computed: {
        offerOption() {
            return this.offer_option
        }
    },
    methods: {
        deleteElement() {
            this.askDeleteMessage().then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('offerOption/delete', this.offerOption).then(() => {
                    this.$message.success("Opzione eliinata.")
                    this.$router.push(this.componentSettings.listPath)
                }).catch((e) => {
                    this.$message.error(e.data.exception.message)
                }).finally(() => {
                    this.loading = false
                })
            })
        },
        validateForm() {
            if (this.offerOption.id) {
                this.editOption()
            } else {
                this.addOption()
            }
        },
        editOption() {
            this.loading = true

            this.$store.dispatch('offerOption/edit', this.offerOption).then(() => {
                this.$router.push(this.componentSettings.listPath)
                this.showSuccessMessage("Opzione salvata correttamente.")
            }).catch((e) => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        },
        addOption() {
            this.loading = true

            this.$store.dispatch('offerOption/add', this.offerOption).then(() => {
                this.$router.push(this.componentSettings.listPath)
                this.showSuccessMessage("Opzione creata")
            }).catch((e) => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        }
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
