<template>
  <div v-loading="loading">
    <h4>Azioni</h4>

    <div class="row mb-3 mt-4">
      <div class="col-12">
        <el-button class="mr-2 text-danger" icon="delete" @click.prevent="deleteOrder"
                   v-if="order.id && canDeleteOrder">Elimina ordine
        </el-button>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'

export default {
  mixins: [],
  components: {
    LSwitch,
    LCheckbox
  },
  props: {
    order: {}
  },
  data() {
    return {
      componentSettings: {
        myComponentName: 'OrderActions'
      },
      loading: false,
      statuses: [],
      types: [],
      datetimePickerOptions: {}
    }
  },
  watch: {
    order() {
    }
  },
  computed: {},
  methods: {
    deleteOrder() {
      this.askDeleteMessage('l\'ordine #' + this.order.id).then((result) => {
        if (!result.value) return
        this.$store.dispatch('orders/delete', this.order).then(() => {
          this.$message.success("Ordine eliminato!");
          this.$router.push('/ordini/lista')
        }).catch((e) => {
          this.showResponseErrorMessage(e)
        })
      })
    },
    canDeleteOrder() {
      return this.$store.getters['orders/canDelete'](this.order)
    }
  },
  created: function () {
    this.statuses = this.$store.getters['orders/statuses/statuses']
    this.types = this.$store.getters['orders/types']
  },
  mounted: function () {
  },
  destroyed: function () {
    this.$off()
  }
}

</script>
<style></style>
