<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model=selected
                   placeholder="Seleziona il partner"
                   filterable
                   @change="updateValue($event)"
                   value-key="value">
            <el-option
                key="console"
                label="Interno dimensione"
                value="console">
            </el-option>
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.origin + ' | ' + item.name"
                :value=item.origin>
            </el-option>
            <el-option
                :key=null
                label="----"
                :value=null>
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "PartnerSelect",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            selected: this.value
        }
    },
    props: {
        value: null
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems() {
            this.loading = true

            this.$store.dispatch('partner/list').then((response) => {
                this.items = response.data
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        },
        updateValue: function (origin) {
            this.$emit('input', origin)
            this.$emit('change')
        }
    }
}
</script>

<style scoped>

</style>
