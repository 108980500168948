<template>
    <div>
        <el-form :model="partner" :rules="rules" ref="partnerForm" v-loading="loading" label-position="top">
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Codice provenienza" prop="origin">
                        <el-input v-model="partner.origin"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Nome" prop="name">
                        <el-input v-model="partner.name"/>
                    </el-form-item>
                </div>
                <div class="col-6">
                    <el-form-item label="Nome titolare">
                        <el-input v-model="partner.owner_name"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Numero di telefono">
                        <el-input v-model="partner.phone_number"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Codice fiscale">
                        <el-input v-model="partner.personal_vat_number"/>
                    </el-form-item>
                </div>
                <div class="col-6">
                    <el-form-item label="Partita iva">
                        <el-input v-model="partner.vat_number"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Email" prop="email">
                        <el-input v-model="partner.email"/>
                    </el-form-item>
                </div>
                <div class="col-6">
                    <el-form-item label="Pec" prop="pec">
                        <el-input v-model="partner.pec"/>
                    </el-form-item>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-9">
                    <el-form-item label="Indirizzo" prop="address" class="mt-0">
                        <el-input v-model="partner.address"/>
                    </el-form-item>
                </div>
                <div class="col-3">
                    <el-form-item label="Civico" prop="address_number" class="mt-0">
                        <el-input v-model="partner.address_number"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Città" prop="city">
                        <el-input v-model="partner.city"/>
                    </el-form-item>
                </div>
                <div class="col-6">
                    <el-form-item label="Cap" prop="zip_code">
                        <el-input v-model="partner.zip_code"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-form-item label="Regione" prop="region">
                        <el-input v-model="partner.region"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <el-form-item label="Note" prop="note">
                        <rich-text-editor v-model="partner.note" class="w-100"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row mb-4" v-if="partner.id">
                <div class="col-12">
                    <el-form-item label="Allegati">
                        <partner-media-upload :partner="partner" class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 mt-4">
                    <ul class="list-group">
                        <li v-for="attachment in partner.attachments" class="list-group-item">
                            <div class="d-flex justify-content-between">
                                <p class="m-0">
                                    <code>{{ attachment.id }}</code> {{ attachment.original_name }}
                                </p>
                                <a :href="attachment.url" target="_blank">
                                    <i class="fa-regular fa-arrow-up-right-from-square"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-auto">
                    <el-button type="primary" @click="save" :disabled="loading">Salva</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import RichTextEditor from "@/components/RichTextEditor";
import PartnerMediaUpload from "@/components/Inputs/PartnerMediaUpload.vue";

export default {
    name: "PartnerForm",
    components: {PartnerMediaUpload, RichTextEditor},
    props: {
        modelValue: {
            default: {}
        },
        redirect: true
    },
    created() {
    },
    computed: {
        partner() {
            return this.modelValue
        }
    },
    data() {
        return {
            loading: false,
            rules: {
                name: [
                    {required: true, message: 'Il nome è obbligatorio'}
                ],
                email: [
                    {type: 'email', message: 'Inserire un indirizzo email valido'}
                ],
                pec: [
                    {type: 'email', message: 'Inserire un indirizzo email valido'}
                ],
            }
        }
    },
    methods: {
        validateForm() {
            this.$refs['partnerForm'].validate((valid) => {
                if (valid) {
                    this.save()
                } else {
                    return false;
                }
            });
        },
        save() {
            this.loading = true
            if (this.partner.id) {
                this.$store.dispatch('partner/edit', this.partner).then((response) => {
                    this.partner = response.data
                    this.$emit('input', this.partner)
                    this.showSuccessMessage('Partner salvato')
                }).catch(e => {
                    this.showResponseErrorMessage(e)
                }).finally(() => {
                    this.loading = false
                })
            } else {
                this.$store.dispatch('partner/create', this.partner).then((response) => {
                    this.showSuccessMessage('Partner creato')
                    if (this.redirect) {
                        this.$router.push('/partner/' + response.data.id + '/modifica')
                    }
                    this.$emit('created', response.data)
                }).catch(e => {
                    this.showResponseErrorMessage(e)
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
