<template>
    <div id="create-order-stepper">
        <div class="row">
            <div class="col-12">
                <el-steps :active="stepActive" align-center finish-status="success">
                    <el-step title="Offerta"></el-step>
                    <el-step title="Cliente" description="Dati di contatto"></el-step>
                    <el-step title="Attivazione" description="Indirizzo"></el-step>
                    <el-step title="Cliente" description="Residenza"></el-step>
                    <el-step title="Cliente" description="Documento"></el-step>
                    <el-step title="Pagamento"></el-step>
                    <el-step title="Attivazione" description="Dettagli"></el-step>
                    <el-step title="Spedizione" description="Indirizzo"></el-step>
                    <el-step title="Riepilogo"></el-step>
                </el-steps>
            </div>
        </div>


        <div class="row mt-4" v-if="!loading">
            <div class="col-12">
                <el-card v-if="stepActive===0">
                    <StepOffer @orderCreated="getOrder" />
                </el-card>
                <el-card v-if="stepActive===1">
                    <StepCustomer v-model="order.customer" @nextStep="nextStep" />
                </el-card>

                <el-card v-if="stepActive===2">
                    <step-activation-address v-model="order" :order-id="order.id"
                                             :customer="order.customer" @next="nextStep"
                                             @back="previousStep" />
                </el-card>

                <el-card v-if="stepActive===3">
                    <step-customer-residence v-model="order.customer" :activation-address="order.activation_address"
                                             :order-id="order.id" @next="nextStep"
                                             @back="previousStep" />
                </el-card>
                <el-card v-if="stepActive===4">
                    <step-customer-document v-model="order.customer" @next="nextStep" @back="previousStep"
                                            :order-id="order.id" />
                </el-card>
                <el-card v-if="stepActive===5">
                    <step-payment-method v-model="order.payment_method" @next="nextStep"
                                         @back="previousStep" />
                </el-card>
                <el-card v-if="stepActive===6">
                    <step-offer-details v-model="order.order_product" :customer="order.customer" @next="nextStep"
                                        @back="previousStep" />
                </el-card>
                <el-card v-if="stepActive===7">
                    <step-shipping-address v-model="order.shipping_address" :customer="order.customer" @next="nextStep"
                                           @back="previousStep" />
                </el-card>
                <el-card v-if="stepActive===8">
                    <step-recap v-model="order" @next="signContract" @back="previousStep" />
                </el-card>
            </div>
        </div>

    </div>
</template>

<script>

import StepOffer from "@/pages/Dashboard/Orders/CreateOrderStepper/StepOffer";
import StepCustomer from "@/pages/Dashboard/Orders/CreateOrderStepper/StepCustomer";
import StepRecap from "@/pages/Dashboard/Orders/CreateOrderStepper/StepRecap";
import StepCustomerResidence from "@/pages/Dashboard/Orders/CreateOrderStepper/StepCustomerResidence";
import StepCustomerDocument from "@/pages/Dashboard/Orders/CreateOrderStepper/StepCustomerDocument";
import StepActivationAddress from "@/pages/Dashboard/Orders/CreateOrderStepper/StepActivationAddress";
import StepPaymentMethod from "@/pages/Dashboard/Orders/CreateOrderStepper/StepPaymentMethod";
import StepShippingAddress from "@/pages/Dashboard/Orders/CreateOrderStepper/StepShippingAddress";
import StepOfferDetails from "@/pages/Dashboard/Orders/CreateOrderStepper/StepOfferDetails";

export default {
    name: "CreateOrderStepper",
    components: {
        StepOfferDetails,
        StepShippingAddress,
        StepPaymentMethod,
        StepActivationAddress,
        StepCustomerDocument,
        StepCustomerResidence,
        StepRecap,
        StepCustomer,
        StepOffer
    },
    data() {
        return {
            loading: false,
            stepActive: 0,
            order: null
        }
    },
    created() {
        let paramOrderId = this.$route.query.id
        if (!paramOrderId) {
            return
        }
        this.loadOrder(paramOrderId).then(() => {
            if (this.$route.query.step) {
                this.stepActive = parseInt(this.$route.query.step)
            } else {
                console.log('before next step')
                this.nextStep()
            }
        })
    },
    methods: {
        async loadOrder(orderId) {
            this.loading = true
            await this.$store.dispatch('orders/getById', orderId).then((response) => {
                this.order = response.data.order
                if (!this.order.activation_address) {
                    this.order.activation_address = {
                        order_id: this.order.id,
                        order_product_id: this.order.order_products[0].id,
                        customer_id: this.order.customer_id,
                        address: null,
                        address_number: null,
                        city: null,
                        province: null,
                        postal_code: null
                    }
                }
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il recupero dei dati di questo ordine')
            }).finally(() => {
                this.loading = false
            })
        },
        getOrder(order) {
            this.loadOrder(order.id).then(() => {
                this.nextStep()
            })
        },
        nextStep() {
            this.stepActive++;
            this.$router.replace({query: {id: this.order.id, step: this.stepActive}})
        },
        previousStep() {
            this.stepActive--;
            this.$router.replace({query: {id: this.order.id, step: this.stepActive}})
        },
        updateOrder(activationAddress) {
            this.activationAddress = activationAddress
            this.order.activation_address = activationAddress
        },
        signContract() {
            this.$router.push({path: '/contratto', query: {hash: this.order.hash}});
        },
        createMnpForProduct() {
            this.$store.dispatch('orders/mnp/add', this.model).then((response) => {
                this.model.mnp = response.data.mnp
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiunta della MNP')
            })
        },
    }
}
</script>

<style scoped>

</style>
