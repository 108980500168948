<template>
  <div v-loading="reload">
    <div class="row mb-4">
      <div class="col-12" v-loading="typeChanging">
        <label>Tipologia</label>
        <div class="d-flex align-items-center">
          <el-select v-model="order.type"
                     style="width: 100%"
                     placeholder="..." size="large" @change="typeChanged = true">
            <el-option
              v-for="item in types"
              class="select-default"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
          <div class="ml-2" v-if="typeChanged">
            <el-button icon="check" circle type="success" @click="saveType" size="small" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="order.status" v-loading="statusChanging">
        <label>Stato ordine
          <el-checkbox v-model="sendStatusChangeNotification" label="Notifica" class="mb-0 ml-2"/>
        </label>
        <div class="d-flex align-items-center">
          <el-select filterable
                     style="width: 100%"
                     v-model="order.status_id"
                     @change="statusChanged = true"
                     placeholder="..." size="large">
            <el-option-group v-for="mother in statuses" :key="mother.id + 'group'" :label="mother.internal_name">
              <el-option
                class="select-default"
                :key="mother.id"
                :label="mother.internal_name"
                :value="mother.id"
                v-if="!mother.child_statuses || mother.child_statuses.length < 1">
              </el-option>
              <el-option
                v-for="item in mother.child_statuses"
                class="select-default"
                :key="item.id"
                :label="item.internal_name"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
          <div class="ml-2 d-flex align-items-center" v-if="statusChanged">
            <el-button icon="check" type="success" circle @click="saveStatus" size="small"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "OrderEditPageTypeAndStatusForm",
  data() {
    return {
      statuses: [],
      types: [],
      typeChanged: false,
      typeChanging: false,
      statusChanged: false,
      statusChanging: false,
      sendStatusChangeNotification: true
    }
  },
  props: {
    order: null,
    reload: false
  },
  created() {
    this.statuses = this.$store.getters['orders/statuses/statuses']
    this.types = this.$store.getters['orders/types']
  },
  methods: {
    askChangeStatus() {
      Swal.fire({
        title: "Vuoi cambiare stato all'ordine?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Cambia stato ed invia notifica',
        denyButtonText: `Cambia stato SENZA inviare la notifica`,
        cancelButtonText: 'Annulla',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.saveStatus(true)
        } else if (result.isDenied) {
          this.saveStatus(false)
        }
      })
    },
    saveStatus(sendNotification) {
      let $this = this;

      this.statusChanging = true;

      let params = {
        order_id: this.order.id,
        status_id: this.order.status_id,
        current_status_id: this.order.originalStatusId,
        send_notification: sendNotification
      }
      this.$store.dispatch('orders/changeStatus', params).then(response => {
        this.$store.commit('orders/updateChronologyEvents')
        this.order.originalStatusId = this.order.status_id;
        this.statusChanged = false;
        this.showSuccessMessage("Stato aggiornato correttamente.")
        this.$emit('status-changed')
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.statusChanging = false;
      })
    },
    saveType() {
      this.typeChanging = true;

      let params = {
        order_id: this.order.id,
        type: this.order.type,
        current_type: this.order.originalType
      }
      this.$store.dispatch('orders/changeType', params).then(response => {
        this.$store.commit('orders/updateChronologyEvents')
        this.typeChanged = false;
        this.showSuccessMessage("Tipo aggiornato correttamente.")
      }).catch(e => {
        this.showResponseErrorMessage(e)
        this.$emit('type-changed')
      }).finally(() => {
        this.typeChanging = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
