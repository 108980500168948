<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Aggiungi opzione">
                <h4 class="my-2">Aggiungi opzione</h4>
                <offer-option-form v-bind:offer_option="offer_option" v-loading="loading" @update="updateForm"/>
            </el-card>
        </div>
    </div>
</template>
<script>
import OfferOptionForm from './OfferOptionForm.vue'

export default {
    components: {
        OfferOptionForm
    },
    computed: {},
    watch: {
        '$route.params.id': function () {
        }
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OfferOptionAdd',
                minimumPermissionLevel: 100,
                listPath: '/offerte/opzioni/lista'
            },
            loading: false,
            canShowView: false,
            offer_option: {
                hidden_public: false,
                print_pda: true,
                vat_included: true
            }
        }
    },
    methods: {
        updateForm(pElement) {
            this.loading = true
            this.$store.dispatch('offerOption/add', pElement).then((response) => {
                this.loading = false
                this.$router.push('/offerte/opzioni/lista/modifica/' + response.data.offer_option.id)
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'inserimento di questa opzione', '', '', false)
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
