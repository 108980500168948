<template>
    <div v-loading="loading" v-if="paymentMethod">
        <h4 class="mb-0">Dettagli metodo di pagamento <small>(ID: {{ paymentMethod.id }})</small></h4>
        <h4 class="mt-0" style="font-size: 1.2em"><small>Ultima modifica:
            {{ dateToItalian(paymentMethod.updated_at, true) }}</small></h4>

        <div class="row">
            <div class="col-12 col-md-8">
                <label>Tipologia</label>
                <PaymentMethodTypeSelect v-model="paymentMethod.type" class="w-100"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <el-button type="primary" icon="check" @click.prevent="updateData">Salva</el-button>
            </div>
        </div>
        <div v-if="paymentMethod.type === 'sdd'">
            <h4>Conto bancario <a href="#" data-tooltip="'Copia dati dal cliente'" @click.prevent="copyFromCustomer()"
                                  class="btn-simple btn-link a-link"><i class="el-icon-copy-document"
                                                                        style="">&nbsp;</i></a>
            </h4>
            <el-form label-position="top">
                <div class="row">
                    <div class="col-12">
                        <el-form-item label="Nome e cognome intestatario">
                            <el-input type="text"
                                      name="iban"
                                      v-model="paymentMethod.sepa_holder_name">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <el-form-item label="Codice Fiscale">
                            <el-input type="text"
                                      name="iban"
                                      v-model="paymentMethod.sepa_cf">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <el-form-item label="Indirizzo">
                            <el-input type="text"
                                      name="sepa_address"
                                      v-model="paymentMethod.sepa_address">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="col-12 col-md-4" style="max-width: 120px;">
                        <el-form-item label="Civico">
                            <el-input type="text"
                                      name="sepa_address_number"
                                      v-model="paymentMethod.sepa_address_number">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4">
                        <el-form-item label="Città">
                            <el-input type="text"
                                      label="Città"
                                      name="comune"
                                      v-model="paymentMethod.sepa_city">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="col-12 col-md-4">
                        <el-form-item label="CAP">
                            <el-input type="text"
                                      name="cap"
                                      v-model="paymentMethod.sepa_zip_code">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="col-12 col-md-4">
                        <el-form-item label="Provincia">
                            <el-select v-loading="loading" style="width: 100%" filterable class="select-default mb-3"
                                       v-model="paymentMethod.sepa_country" placeholder="Seleziona...">
                                <el-option
                                    v-for="item in province"
                                    class="select-default"
                                    :key="item.sigla"
                                    :label="item.name"
                                    :value="item.sigla"
                                    name="sepa_country">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <el-form-item label="IBAN">
                            <div class="d-flex w-100">
                                <el-input type="text"
                                          name="iban"
                                          v-model="paymentMethod.iban">
                                </el-input>
                                <a href="#" data-tooltip="'Scomponi IBAN'" @click.prevent="fillIban()"><i class="fa-regular fa-file-dashed-line fa-xl ml-2"></i></a>
                            </div>
                            </el-form-item>
                    </div>
                    <div class="col-12 col-md-3">
                        <el-form-item label="Agenzia">
                            <el-input type="text"
                                      label="Agenzia"
                                      name="agency"
                                      v-model="paymentMethod.agency">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-3">
                        <el-form-item label="Banca">
                            <el-input type="text"
                                      label="Banca"
                                      name="bank"
                                      v-model="paymentMethod.bank">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-12 col-md-2">
                        <el-form-item label="CIN Int.">
                            <el-input type="text"
                                      label="CIN Int."
                                      name="cin"
                                      v-model="paymentMethod.cin_internazionale">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-2">
                        <el-form-item label="CIN">
                            <el-input type="text"
                                      label="CIN"
                                      name="cin"
                                      v-model="paymentMethod.cin">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-2">
                        <el-form-item label="ABI">
                            <el-input type="text"
                                      label="ABI"
                                      name="abi"
                                      v-model="paymentMethod.abi">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-2">
                        <el-form-item label="CAB">
                            <el-input type="text"
                                      label="CAB"
                                      name="cab"
                                      v-model="paymentMethod.cab">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-4">
                        <el-form-item label="Conto corrente">
                            <el-input type="text"
                                      label="Conto corrente"
                                      name="cc"
                                      v-model="paymentMethod.cc">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="row">
                <div class="col-12">
                    <el-button type="primary" icon="check" @click.prevent="updateData">Salva</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.crypted-input input {
    font-family: dotsfont;
    letter-spacing: 2px;
    font-size: 7px;
}

.fixed-button {
    position: fixed;
    float: right;
    z-index: 3;
    width: 100%;
    bottom: 112px;
    right: 60px;
}
</style>

<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'
import PaymentMethodTypeSelect from "@/components/Inputs/PaymentMethodTypeSelect";

export default {
    mixins: [],
    components: {
        PaymentMethodTypeSelect,
        LSwitch,
        LCheckbox
    },
    props: {
        order: {}
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderPaymentMethodDetails'
            },
            loading: false,
            decrypted: false,
            card_circuits: [],
            province: []
        }
    },
    watch: {
        order() {
            if (this.order.id) {
                this.loadResources()
                this.decrypted = false
            }
        }
    },
    computed: {
        inputClass: function () {
            if (this.decrypted) return 'decrypted-input'
            return 'crypted-input'
        },
        paymentMethod() {
            if (!this.order.id) {
                return null
            }
            return this.order.payment_method
        }
    },
    methods: {
        loadProvince() {
            this.loading = true
            this.$store.dispatch('shippings/getProvince').then((response) => {
                this.loading = false
                this.province = response
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle province')
            })
        },
        fillIban() {
            if (!this.paymentMethod.iban) {
                this.showGenericError('IBAN non inserito!', 'Inserire prima un IVAN valido.')
                return
            }
            this.paymentMethod.iban = this.paymentMethod.iban.replace(/\s+/g, '')

            var IBAN = require('iban')
            if (!IBAN.isValid(this.paymentMethod.iban)) {
                this.showGenericError('IBAN non valido!', 'Questo IBAN non sembra essere formalmente corretto.<br /><br />Verificane la correttezza e riprova!')
                return
            }
            if (this.paymentMethod.iban.length === 27) {
                this.paymentMethod.cin_internazionale = this.paymentMethod.iban.substr(2, 2)
                this.paymentMethod.cin = this.paymentMethod.iban.substr(4, 1)
                this.paymentMethod.abi = this.paymentMethod.iban.substr(5, 5)
                this.paymentMethod.cab = this.paymentMethod.iban.substr(10, 5)
                this.paymentMethod.cc = this.paymentMethod.iban.substr(15)
                this.updateData(true)
            } else {
                this.showGenericError('Lunghezza non conforme!', 'Questo IBAN ha una lunghezza di ' + this.paymentMethod.iban.length + ' caratteri, ne occorrono 27.')
            }
        },
        async updateOrderProducts(pSilenced = false) {
            this.loading = true
            let thereIsError = false
            for (let index = 0; index < this.order.order_products.length; ++index) {
                await this.$store.dispatch('orders/orderProducts/edit', this.order.order_products[index]).then((response) => {
                }).catch(e => {
                    this.loading = false
                    thereIsError = true
                    this.showLoadingError(e, 'l\'aggiornamento del prodotto #' + (index + 1).toString() + ' legato all\'offerta ' + this.order.order_products[index].offer.internal_name, '', false)
                })
                if (thereIsError) break
            }
            this.loading = false
            this.showGenericSuccess()
        },
        updateData(pSilenced = false) {
            this.loading = true
            this.$store.dispatch('orders/paymentMethods/edit', this.paymentMethod).then((response) => {
                this.loading = false
                this.order.payment_method = response.data.payment_method
                this.decrypted = false
                if (this.order.order_products && this.order.order_products.length > 0) {
                    this.updateOrderProducts(pSilenced)
                } else {
                    if (!pSilenced) {
                        this.showGenericSuccess()
                    }
                }
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il salvataggio del metodo di pagamento')
            })
        },
        loadDecryptedPaymentMethod() {
            this.loading = true
            this.$store.dispatch('orders/paymentMethods/decrypt', {
                id: this.payment_method.id,
                order_id: this.order.id
            }).then((response) => {
                this.loading = false
                this.order.payment_method = response.data.payment_method
                this.decrypted = true
                this.$store.commit('orders/updateChronologyEvents')
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento del metodo di pagamento decriptato')
            })
        },
        loadCardCircuits() {
            this.loading = true
            this.$store.dispatch('orders/paymentMethods/getCardCircuits').then((response) => {
                this.loading = false
                this.card_circuits = response  // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle tipologie di metodo di pagamento')
            })
        },
        loadResources() {
            this.loadProvince()
        },
        copyFromCustomer() {
            this.paymentMethod.sepa_first_name = this.order.customer.first_name
            this.paymentMethod.sepa_last_name = this.order.customer.last_name
            this.paymentMethod.sepa_cf = this.order.customer.codice_fiscale
            this.paymentMethod.sepa_address = this.order.customer.indirizzo_residenza
            this.paymentMethod.sepa_address_number = this.order.customer.numero_civico
            this.paymentMethod.sepa_city = this.order.customer.comune
            this.paymentMethod.sepa_zip_code = this.order.customer.cap
            this.paymentMethod.sepa_country = this.order.customer.provincia
        }
    },
    created: function () {
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
