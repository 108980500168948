import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'

// Pages
import Login from '@/pages/Dashboard/Login/LoginPage.vue'

let loginPage = {
    path: '/login',
    name: 'Login',
    component: Login
}

import ContractPage from "@/pages/Dashboard/Pages/ContractPage";

let contractPage = {
    path: '/contratto',
    name: 'ContractPage',
    component: ContractPage
}

// Users
import UsersList from 'src/pages/Dashboard/Users/UsersList'
import AddUser from 'src/pages/Dashboard/Users/AddUser'
import EditUser from 'src/pages/Dashboard/Users/EditUser'

let usersSection = {
    path: '/utenti',
    component: DashboardLayout,
    children: [
        {
            path: 'aggiungi',
            name: 'Aggiungi utente',
            component: AddUser
        },
        {
            path: 'lista',
            name: 'Lista utenti',
            component: UsersList
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica utente',
            component: EditUser
        }
    ]
}

// Orders
import StatusesList from 'src/pages/Dashboard/Orders/Statuses/StatusesList'
import StatusesAdd from 'src/pages/Dashboard/Orders/Statuses/StatusesAdd'
import StatusesEdit from 'src/pages/Dashboard/Orders/Statuses/StatusesEdit'
import GiacenzaLoad from 'src/pages/Dashboard/OpenFiber/GiacenzaLoad'
import ExportTSTrackPage from "src/pages/Dashboard/Users/ExportTSTrackPage"
import Exports from "src/pages/Dashboard/Exports/Exports";

let statusesSection = {
    path: '/stati',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Mostra stati ordine',
            component: StatusesList
        },
        {
            path: 'aggiungi',
            name: 'Aggiungi stato ordine',
            component: StatusesAdd
        },
        {
            path: 'aggiungi/:id',
            name: 'Aggiungi stato ordine in altro stato', // Con madre preselezionata
            component: StatusesAdd
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica stato ordine',
            component: StatusesEdit
        }
    ]
}

// Products
import AddProduct from 'src/pages/Dashboard/Products/AddProduct'
import ProductsList from 'src/pages/Dashboard/Products/ProductsList'
import EditProduct from 'src/pages/Dashboard/Products/EditProduct'

let productSection = {
    path: '/prodotti',
    component: DashboardLayout,
    children: [
        {
            path: 'aggiungi',
            name: 'Aggiungi prodotto',
            component: AddProduct
        },
        {
            path: 'lista',
            name: 'Lista prodotti',
            component: ProductsList
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica prodotto',
            component: EditProduct
        }
    ]
}

// Rates
import RatesList from 'src/pages/Dashboard/Rates/RatesList'
import AddRate from 'src/pages/Dashboard/Rates/AddRate'
import EditRate from 'src/pages/Dashboard/Rates/EditRate'

let rateSection = {
    path: '/tariffe',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista tariffe',
            component: RatesList
        },
        {
            path: 'aggiungi',
            name: 'Aggiungi tariffa',
            component: AddRate
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica tariffa',
            component: EditRate
        }
    ]
}

// Offers
import OffersList from 'src/pages/Dashboard/Offers/OffersList'
import AddOffer from 'src/pages/Dashboard/Offers/AddOffer'
import EditOffer from 'src/pages/Dashboard/Offers/EditOffer'
import PeoplesoftList from 'src/pages/Dashboard/Offers/PeoplesoftList'
import AddPeoplesoft from 'src/pages/Dashboard/Offers/AddPeoplesoft'
import EditPeoplesoft from 'src/pages/Dashboard/Offers/EditPeoplesoft'
import OfferOptionList from 'src/pages/Dashboard/Offers/OfferOptionList'
import AddOfferOption from 'src/pages/Dashboard/Offers/AddOfferOption'
import EditOfferOption from 'src/pages/Dashboard/Offers/EditOfferOption'

let offerSection = {
    path: '/offerte',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista offerte',
            component: OffersList
        },
        {
            path: 'aggiungi',
            name: 'Aggiungi offerta',
            component: AddOffer
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica offerta',
            component: EditOffer
        },
        {
            path: 'peoplesoft/lista',
            name: 'Lista Peoplesoft',
            component: PeoplesoftList
        },
        {
            path: 'peoplesoft/aggiungi',
            name: 'Aggiungi Peoplesoft',
            component: AddPeoplesoft
        },
        {
            path: 'peoplesoft/lista/modifica/:id',
            name: 'Modifica Peoplesoft',
            component: EditPeoplesoft
        },
        {
            path: 'opzioni/lista',
            name: 'Lista opzioni',
            component: OfferOptionList
        },
        {
            path: 'opzioni/aggiungi',
            name: 'Aggiungi opzione',
            component: AddOfferOption
        },
        {
            path: 'opzioni/lista/modifica/:id',
            name: 'Modifica opzione',
            component: EditOfferOption
        }
    ]
}

// Orders
import OrdersList from 'src/pages/Dashboard/Orders/OrdersList'
import EditOrder from '@/pages/Dashboard/OrderEditPage/EditOrder'
import OrdersStatusesList from 'src/pages/Dashboard/Orders/OrdersStatusesList'
import AddOrder from 'src/pages/Dashboard/Orders/AddOrder'
import CreateOrderStepper from "@/pages/Dashboard/Orders/CreateOrderStepper/CreateOrderStepper";

let ordersSection = {
    path: '/ordini',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista di tutti gli ordini',
            component: OrdersList
        },
        {
            path: 'lista/ordine/:ordine',
            name: 'Lista ordini per ID',
            component: OrdersList
        },
        {
            path: 'lista/telefono/:telefono',
            name: 'Lista ordini per numero di telefono',
            component: OrdersList
        },
        {
            path: 'lista/codicefiscale/:codicefiscale',
            name: 'Lista ordini per codice fiscale',
            component: OrdersList
        },
        {
            path: 'lista/email/:email',
            name: 'Lista ordini per email',
            component: OrdersList
        },
        {
            path: 'lista/nome/:nome',
            name: 'Lista ordini per nome',
            component: OrdersList
        },
        {
            path: 'lista/stato/:stato/:pagamento',
            name: 'Lista ordini per stato e pagamento',
            component: OrdersList
        },
        {
            path: 'lista/stato/:stato',
            name: 'Lista ordini per stato',
            component: OrdersList
        },
        {
            path: 'lista/tipo/:tipo',
            name: 'Lista ordini per tipo',
            component: OrdersList
        },
        {
            path: 'lista/codicecliente/:codicecliente',
            name: 'Lista ordini per codice cliente',
            component: OrdersList
        },
        {
            path: 'lista/tipo-stato/:tipo/:stato/:pagamento',
            name: 'Lista ordini per tipo, stato e pagamento',
            component: OrdersList
        },
        {
            path: 'lista/tipo-stato/:tipo/:stato',
            name: 'Lista ordini per tipo e stato',
            component: OrdersList
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica ordine',
            component: EditOrder
        },
        {
            path: 'stati',
            name: 'Lista stato ordini',
            component: OrdersStatusesList
        },
        {
            path: 'aggiungi',
            name: 'Crea nuovo ordine',
            component: CreateOrderStepper
        },
        {
            path: 'esporta-tracciato-ts',
            name: 'Esportazione Team System',
            component: ExportTSTrackPage
        },
        {
            path: 'esportazioni',
            name: 'Esportazioni',
            component: Exports
        },
        {
            path: 'giacenza-openfiber-load',
            name: 'Carica Giacenza OF',
            component: GiacenzaLoad
        },
        {
            path: 'log-risposte',
            name: 'Migrazione in entrata',
            component: LogRisposte
        }
    ]
}

// Emails
import EmailsList from 'src/pages/Dashboard/Notifications/Emails/EmailsList'
import EditEmail from 'src/pages/Dashboard/Notifications/Emails/EditEmail'
import AddEmail from 'src/pages/Dashboard/Notifications/Emails/AddEmail'

let emailSection = {
    path: '/notifications',
    component: DashboardLayout,
    children: [
        {
            path: 'email/lista',
            name: 'Lista email',
            component: EmailsList
        },
        {
            path: 'email/aggiungi',
            name: 'Aggiungi e-mail',
            component: AddEmail
        },
        {
            path: 'email/lista/modifica/:id',
            name: 'Modifica e-mail',
            component: EditEmail
        }
    ]
}

// Notifications - SMS
import NotificationsList from 'src/pages/Dashboard/Notifications/SMS/SMSList'
import AddSMS from 'src/pages/Dashboard/Notifications/SMS/AddSMS'
import EditSMS from 'src/pages/Dashboard/Notifications/SMS/EditSMS'

let notificationsSection = {
    path: '/notifications',
    component: DashboardLayout,
    children: [
        {
            path: 'sms/lista',
            name: 'Lista template SMS',
            component: NotificationsList
        },
        {
            path: 'sms/aggiungi',
            name: 'Aggiungi SMS',
            component: AddSMS
        },
        {
            path: 'sms/lista/modifica/:id',
            name: 'Modifica SMS',
            component: EditSMS
        }
    ]
}

// Shippings
import ShippingsList from 'src/pages/Dashboard/Shippings/ShippingsList'
import EditShipping from 'src/pages/Dashboard/Shippings/EditShipping'

let shippingsSection = {
    path: '/spedizioni',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista spedizioni',
            component: ShippingsList
        },
        {
            path: 'lista/:id',
            name: 'Lista spedizioni per ordini',
            component: ShippingsList
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica spedizione',
            component: EditShipping
        }
    ]
}

// Promocode
import CodeBurner from 'src/pages/Dashboard/PromoCode/CodeBurner'
import PromocodesList from 'src/pages/Dashboard/PromoCode/PromocodesList'
import AddPromoCodeType from 'src/pages/Dashboard/PromoCode/AddPromoCodeType'
import EditPromoCodeType from 'src/pages/Dashboard/PromoCode/EditPromoCodeType'

let promoCodeSection = {
    path: '/promocode',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista promocode',
            component: PromocodesList
        },
        {
            path: 'lista/aggiungi',
            name: 'Aggiungi tipo promocode',
            component: AddPromoCodeType
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica tipo promocode',
            component: EditPromoCodeType
        },
        {
            path: 'brucia',
            name: 'Bruciacodici',
            component: CodeBurner
        }
    ]
}

// Recharges
import RechargesList from 'src/pages/Dashboard/Recharges/RechargesList'

let rechargesSection = {
    path: '/ricariche',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista ricariche',
            component: RechargesList
        }
    ]
}

// Inbound
import InboundList from 'src/pages/Dashboard/Inbound/InboundList'

let inboundSection = {
    path: '/inbound',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista Inbound',
            component: InboundList
        }
    ]
}

// MNP
import MNPList from 'src/pages/Dashboard/MNP/MNPList'
import EditMNP from 'src/pages/Dashboard/MNP/EditMNP'

let MNPSection = {
    path: '/np',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista MNP',
            component: MNPList
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica MNP',
            component: EditMNP
        }
    ]
}

// PRODUCT ALERTS - AVVISI PRODOTTI
import ProductAlerts from 'src/pages/Dashboard/ProductAlerts/ProductAlerts'
import AddProductAlert from 'src/pages/Dashboard/ProductAlerts/AddProductAlert'
import EditProductAlert from 'src/pages/Dashboard/ProductAlerts/EditProductAlert'

let productAlertsSection = {
    path: '/productAlerts',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista avvisi prodotti',
            component: ProductAlerts
        },
        {
            path: 'lista/aggiungi',
            name: 'Aggiungi avviso prodotto',
            component: AddProductAlert
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica avviso prodotto',
            component: EditProductAlert
        }
    ]
}

// RICEVUTE
import Receipts from 'src/pages/Dashboard/Receipts/Receipts'

let receiptsSection = {
    path: '/ricevute',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Ricevute',
            component: Receipts
        }
    ]
}

// UTILITY
import TimePanel from 'src/pages/Dashboard/Utility/TimePanel/TimePanel'
import UsefulLinks from 'src/pages/Dashboard/Utility/UsefulLinks/UsefulLinks'
import AddUsefulLink from 'src/pages/Dashboard/Utility/UsefulLinks/AddUsefulLink'
// eslint-disable-next-line no-duplicate-imports
import EditUsefulLink from 'src/pages/Dashboard/Utility/UsefulLinks/EditUsefulLink'

let utilitySection = {
    path: '/utility',
    component: DashboardLayout,
    children: [
        {
            path: 'inserimento-orario',
            name: 'Inserimento orario',
            component: TimePanel
        },
        {
            path: 'link-utili',
            name: 'Link utili',
            component: UsefulLinks
        },
        {
            path: 'link-utili/modifica/:id',
            name: 'Modifica link',
            component: EditUsefulLink
        },
        {
            path: 'link-utili/aggiungi',
            name: 'Aggiungi link',
            component: AddUsefulLink
        }
    ]
}

// IMPOSTAZIONI
import Settings from 'src/pages/Dashboard/Settings/Settings'
import FlowDescription from 'src/pages/Dashboard/Settings/FlowDescription'
import Maintenance from 'src/pages/Dashboard/Settings/Maintenance'

let settingsSection = {
    path: '/impostazioni',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Impostazioni',
            component: Settings
        },
        {
            path: 'flusso',
            name: 'Riepilogo flusso',
            component: FlowDescription
        },
        {
            path: 'manutenzione',
            name: 'Manutenzione',
            component: Maintenance
        }
    ]
}

// LOG
import Log from 'src/pages/Dashboard/Settings/Log'

let logSection = {
    path: '/log',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Log',
            component: Log
        }
    ]
}

// REGOLAMENTI
import Terms from 'src/pages/Dashboard/Terms/Terms'

let termsSection = {
    path: '/carica-regolamenti',
    component: DashboardLayout,
    children: [
        {
            path: 'carica',
            name: 'Regolamenti',
            component: Terms
        }
    ]
}

// COMPASS
import Compass from 'src/pages/Dashboard/Compass/Compass'

let compassSection = {
    path: '/compass',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Compass',
            component: Compass
        }
    ]
}

// STATISTICHE
import OrderStatistics from 'src/pages/Dashboard/Statistics/OrderStatistics'
import ActivationStatistics from 'src/pages/Dashboard/Statistics/Activations/ActivationStatistics'

let statisticsSection = {
    path: '/statistiche',
    component: DashboardLayout,
    children: [
        {
            path: 'orders',
            name: 'Statistiche ordini',
            component: OrderStatistics
        },
        {
            path: 'activations',
            name: 'Statistiche attivazioni',
            component: ActivationStatistics
        }
    ]
}

// EMAIL CAMPAIGNS
import EmailCampaignsList from 'src/pages/Dashboard/EmailCampaigns/EmailCampaignsList'
import EditEmailCampaign from 'src/pages/Dashboard/EmailCampaigns/EditEmailCampaign'
import AddEmailCampaign from 'src/pages/Dashboard/EmailCampaigns/AddEmailCampaign'

let emailCampaignSection = {
    path: '/campagne-email',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Campagne Email',
            component: EmailCampaignsList
        },
        {
            path: 'lista/modifica/:id',
            name: 'Modifica Campagna Email',
            component: EditEmailCampaign
        },
        {
            path: 'aggiungi',
            name: 'Aggiungi Campagna Email',
            component: AddEmailCampaign
        }
    ]
}

import AddPartner from "@/pages/Dashboard/Partner/AddPartner";
import PartnerList from "@/pages/Dashboard/Partner/PartnerList";
import EditPartner from "@/pages/Dashboard/Partner/EditPartner";

let partnerRoutes = {
    path: '/partner',
    component: DashboardLayout,
    children: [
        {
            path: 'lista',
            name: 'Lista partner',
            component: PartnerList
        },
        {
            path: 'aggiungi',
            name: 'Aggiungi Partner',
            component: AddPartner
        },
        {
            path: ':id/modifica',
            name: 'Modifica Partner',
            component: EditPartner
        }
    ]
}


// Clienti attivi
import Assurance from 'src/pages/Dashboard/ActiveCustomers/Assurance/Assurance'
import AddAssurance from 'src/pages/Dashboard/ActiveCustomers/Assurance/AddAssurance'
import EditAssurance from 'src/pages/Dashboard/ActiveCustomers/Assurance/EditAssurance'
import Migrations from 'src/pages/Dashboard/ActiveCustomers/Migrations/Migrations'
import LogRisposte from "src/pages/Dashboard/ActiveCustomers/LogRisposte.vue";

let activeCustomersSection = {
    path: '/clienti-attivi/',
    component: DashboardLayout,
    children: [
        {
            path: 'guasti',
            name: 'Guasti',
            component: Assurance
        },
        {
            path: 'guasti/aggiungi',
            name: 'Aggiungi Guasti',
            component: AddAssurance
        },
        {
            path: 'guasti/modifica/:id',
            name: 'Modifica guasto',
            component: EditAssurance
        },
        {
            path: 'migrazioni-donating',
            name: 'Migrazioni in uscita',
            component: Migrations
        }
    ]
}

const routes = [
    {
        path: '/',
        redirect: '/ordini/stati'
    },
    {
        path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
        component: NotFound
    },
    loginPage,
    contractPage,
    productSection,
    rateSection,
    offerSection,
    usersSection,
    statusesSection,
    ordersSection,
    emailSection,
    notificationsSection,
    shippingsSection,
    promoCodeSection,
    rechargesSection,
    inboundSection,
    MNPSection,
    productAlertsSection,
    receiptsSection,
    termsSection,
    statisticsSection,
    compassSection,
    utilitySection,
    settingsSection,
    logSection,
    emailCampaignSection,
    partnerRoutes,
    activeCustomersSection
]

export default routes
