<template>
    <div id="order-step-customer">
        <div class="row mt-2">
            <div class="col-12 text-center">
                <h4 class="m-0">Residenza</h4>
                <el-button v-if="customer" @click="copyFromActivationAddress()"><i class="fa-regular fa-copy mr-2"></i>Copia da
                    indirizzo attivazione
                </el-button>
            </div>
            <div class="col-12 mt-4">
                <el-form ref="form" :model="customer" label-width="180px">
                    <el-form-item label="Indirizzo">
                        <el-input v-model="customer.indirizzo_residenza"></el-input>
                    </el-form-item>
                    <el-form-item label="Civico">
                        <el-input v-model="customer.numero_civico"></el-input>
                    </el-form-item>
                    <el-form-item label="Comune">
                        <el-input v-model="customer.comune"></el-input>
                    </el-form-item>
                    <el-form-item label="Provincia">
                        <province-select v-model="customer.provincia"></province-select>
                    </el-form-item>
                    <el-form-item label="CAP">
                        <el-input v-model="customer.cap"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <span>Apri <a :href="contract_link">contratto</a></span>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="saveAndBack()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="saveAndNext()">Avanti<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";

export default {
    name: "step-customer-residence",
    components: {
        GiuridicFormSelect,
        ProvinceSelect
    },
    data() {
        return {
            customer: {}
        }
    },
    mounted() {
        this.customer = this.modelValue
    },
    props: {
        modelValue: {
            type: Object
        },
        orderId: {
            type: Number
        },
        activationAddress: {
            type: Object
        }
    },
    computed: {
        contract_link: function () {
            return '/#/ordini/lista/modifica/' + this.orderId;
        }
    },
    methods: {
        saveAndBack() {
            this.saveCustomerInfo()
            this.$emit('back')
        },
        saveAndNext() {
            this.saveCustomerInfo()
            this.$emit('next')
        },
        copyFromActivationAddress() {
            this.customer.indirizzo_residenza = this.activationAddress.address;
            this.customer.numero_civico = this.activationAddress.address_number;
            this.customer.comune = this.activationAddress.city;
            this.customer.provincia = this.activationAddress.province;
            this.customer.cap = this.activationAddress.postal_code;
        },
        saveCustomerInfo() {
            this.loading = true
            this.$store.dispatch('customers/edit', this.customer).then((response) => {
                this.loading = false
                this.customer = response.data.customer
                this.$emit('input', this.customer)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo cliente')
            })
        }
    }
}
</script>

<style scoped>

</style>
