<template>
    <div class="chronology-section mt-4" v-loading="loading">

        <el-table :data="chronology" v-if="chronology.length > 0" :row-class-name="tableRowClassName">
            <el-table-column prop="happened_at" width="250" label="Data">
                <template #default="scope">
                    <div class="d-flex align-items-center" style="margin-left: -13px">
                        <div>
                            <p class="m-0"><i v-if="scope.row.direction === 'outbound'"
                                              class="el-icon-upload2 mr-2 text-success"/>
                                <i v-else class="el-icon-download mr-2 text-danger"/>
                            </p>
                        </div>
                        <div>
                            <p class="m-0">{{ dateToItalian(scope.row.happened_at, false) }}</p>
                            <p class="text-small m-0">{{ timePassedFromDate(scope.row.happened_at) }}</p>
                            <p class="text-small m-0">{{ scope.row.action_friendly_description }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="codice_olo" label="Codice OLO" :width="155">
                <template #default="scope">
                    <code>{{ scope.row.codice_olo }}</code>
                </template>
            </el-table-column>
            <el-table-column prop="provisioning_state_formatted" label="Stato">
                <template #default="scope">
                    <div v-if="scope.row.raw.ESITO === '1'">
                        <p class="m-0 text-medium"><strong>Chiamata in errore</strong></p>
                        <p class="m-0 text-medium" v-if="scope.row.provisioning_state_formatted">
                            <strong>{{ scope.row.provisioning_state_formatted }}</strong></p>
                        <p class="m-0 text-medium" v-else-if="scope.row.action_friendly_description">
                            <strong>{{ scope.row.action_friendly_description }}</strong></p>
                        <p class="m-0 text-medium" v-if="scope.row.raw.CODICE_MOTIVAZIONE">
                            {{ scope.row.raw.MOTIVAZIONE }} ({{ scope.row.raw.CODICE_MOTIVAZIONE }})</p>
                        <p class="m-0 text-medium" v-if="scope.row.raw.NOTE"><strong>Note:</strong>
                            <i>{{ scope.row.raw.NOTE }}</i></p>
                        <p class="m-0 text-medium" v-if="scope.row.note"><strong>Note Aggiuntive:</strong>
                            <i>{{ scope.row.note }}</i></p>
                    </div>
                    <div v-else>
                        <p class="m-0 text-medium" v-if="scope.row.provisioning_state_formatted">
                            <strong>{{ scope.row.provisioning_state_formatted }}</strong></p>
                        <p class="m-0 text-medium" v-else-if="scope.row.action_friendly_description">
                            <strong>{{ scope.row.action_friendly_description }}</strong></p>
                        <p class="m-0 text-medium" v-if="scope.row.raw.CODICE_MOTIVAZIONE">
                            {{ scope.row.raw.MOTIVAZIONE }} ({{ scope.row.raw.CODICE_MOTIVAZIONE }})</p>
                        <p class="m-0 text-medium" v-if="scope.row.appointment_date"><strong>Appuntamento:</strong>
                            {{ dateToItalian(scope.row.appointment_date) }}</p>
                        <p class="m-0 text-medium" v-if="scope.row.raw.NOTE"><strong>Note:</strong>
                            <i>{{ scope.row.raw.NOTE }}</i></p>
                        <p class="m-0 text-medium" v-if="scope.row.note"><strong>Note Aggiuntive:</strong>
                            <i>{{ scope.row.note }}</i></p>

                        <p class="m-0 text-medium" v-if="scope.row.raw.FLAG_DESOSPENSIONE"><strong>Flag
                            Desospensione:</strong> <i>{{ scope.row.raw.FLAG_DESOSPENSIONE }}</i></p>


                        <div
                            v-if="scope.row.request_params_raw && scope.row.request_params_raw.SERVIZIO_FTTH && scope.row.request_params_raw.SERVIZIO_FTTH.VLAN">
                            <p class="m-0 text-medium">
                                {{ scope.row.request_params_raw.SERVIZIO_FTTH.VLAN.KIT_CONSEGNA_VLAN }}<br/><strong>S-VLAN:</strong>
                                {{ scope.row.request_params_raw.SERVIZIO_FTTH.VLAN.SERVICE_VLAN }},
                                <strong>C-VLAN:</strong>
                                {{ scope.row.request_params_raw.SERVIZIO_FTTH.VLAN.CUSTOMER_VLAN }}</p>
                            <p class="m-0 text-medium"><strong>Profilo: </strong>
                                <i>{{ scope.row.request_params_raw.SERVIZIO_FTTH.PROFILO }}</i></p>
                        </div>
                        <div v-else>
                            <p class="m-0 text-medium"
                               v-if="scope.row.request_params_raw && scope.row.request_params_raw.VLAN">
                                {{ scope.row.request_params_raw.VLAN.KIT_CONSEGNA_VLAN }}<br/><strong>S-VLAN:</strong>
                                {{ scope.row.request_params_raw.VLAN.SERVICE_VLAN }}, <strong>C-VLAN:</strong>
                                {{ scope.row.request_params_raw.VLAN.CUSTOMER_VLAN }}</p>
                            <p class="m-0 text-medium"
                               v-if="scope.row.request_params_raw && scope.row.request_params_raw.PROFILO"><strong>Profilo: </strong>
                                <i>{{ scope.row.request_params_raw.PROFILO }}</i></p>
                            <p class="m-0 text-medium" v-if="scope.row.raw.VLAN && scope.row.raw.VLAN.item"><strong>S-VLAN:</strong>
                                {{ scope.row.raw.VLAN.item.SERVICE_VLAN }}, <strong>C-VLAN:</strong>
                                {{ scope.row.raw.VLAN.item.CUSTOMER_VLAN }}</p>
                        </div>

                        <div v-if="scope.row.request_params_raw && scope.row.request_params_raw.SERVIZIO_AGGIUNTIVO">
                            <p class="m-0 text-medium">
                                <strong>Codice Servizio Aggiuntivo: </strong>
                                <i>{{ scope.row.request_params_raw.SERVIZIO_AGGIUNTIVO.NOME_SERVIZIO }}</i>
                                (<i>{{ scope.row.request_params_raw.SERVIZIO_AGGIUNTIVO.INFORMAZIONI_SERVIZIO }}</i>)
                            </p>
                        </div>

                        <p class="m-0 text-medium" v-if="scope.row.raw.ID_RISORSA"><strong>COR:</strong>
                            {{ scope.row.raw.ID_RISORSA }} ({{ scope.row.raw.COS }})</p>
                        <p class="m-0 text-medium" v-if="scope.row.raw.COR"><strong>COR:</strong>
                            {{ scope.row.raw.COR }} ({{ scope.row.raw.COS }})</p>
                        <p class="m-0 text-medium" v-if="scope.row.raw.COSTO_EXTRA"><strong>Costi Extra:</strong>
                            {{ scope.row.raw.COSTO_EXTRA }}&euro;</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="" label="Dettagli" width="75" fixed="right">
                <template #default="scope">
                    <el-button @click="showDetails = true; detailItem = scope.row" size="small" type="primary"
                               class="ml-auto"
                               icon="view" circle/>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            title="Dettagli"
            v-model="showDetails"
            v-if="detailItem"
            width="90%">
            <div class="border rounded py-4 px-4 mb-4">
                <h4 class="mt-0">Dettagli tecnici</h4>
                <p class="m-0">Tipo: <strong>{{ detailItem.type }}</strong></p>
                <p class="m-0">Endpoint: <strong>{{ detailItem.endpoint_name }}</strong></p>
                <p class="m-0">Direction: <strong>{{ detailItem.direction }}</strong></p>
                <p class="m-0">IP: <strong>{{ detailItem.ip_address }}</strong></p>
            </div>

            <div v-if="detailItem.direction === 'inbound'">
                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.raw">
                    <h4 class="mt-0">
                        <span>Richiesta da Provider vs Dimensione</span>
                    </h4>
                    <vue-json-pretty :data="detailItem.raw" :showLength="true"/>
                </div>

                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.original_message">
                    <h4 class="mt-0">
                        <span>Richiesta da Provider a Dimensione (XML)</span>
                    </h4>
                    <vue-json-pretty :data="detailItem.original_message" :showLength="false"/>
                </div>

                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.original_message">
                    <h4 class="mt-0">
                        <span>Risposta da Dimensione a Provider (JSON)</span>
                    </h4>
                    <vue-json-pretty :data="detailItem.request_params_raw" :showLength="false"/>
                </div>
            </div>


            <div v-if="detailItem.direction === 'outbound'">
                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.raw">
                    <h4 class="mt-0">
                        <span>Risposta da Provider vs Dimensione</span>
                    </h4>
                    <vue-json-pretty :data="detailItem.raw" :showLength="true"/>
                </div>

                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.request_params">
                    <h4 class="mt-0">
                        <span>Richiesta da Dimensione vs provider</span>
                    </h4>
                    <vue-json-pretty :data="JSON.parse(detailItem.request_params)" :showLength="true"/>
                </div>

                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.original_xml">
                    <h4 class="mt-0">
                        <span>Risposta da Provider a Dimensione (XML)</span>
                    </h4>
                    <vue-json-pretty :data="detailItem.original_xml" :showLength="false"/>
                </div>

                <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.original_message">
                    <h4 class="mt-0">
                        <span>Richiesta da Dimensione a Provider (XML)</span>
                    </h4>
                    <vue-json-pretty :data="detailItem.original_message" :showLength="false"/>
                </div>

            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="showDetails = false" type="primary">Chiudi</el-button>
            </div>
        </el-dialog>

    </div>

</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
    name: "OpenFiberChronology",
    components: {VueJsonPretty},
    data: function () {
        return {
            loading: false,
            chronology: [],
            giacenza: [],
            showDetails: false,
            detailItem: null
        }
    },
    props: {
        orderId: {
            required: true
        }
    },
    created() {
        this.loadChronology()
    },
    watch: {
        'orderId': function () {
            this.loadChronology()
        }
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if (row.raw.ESITO === '1') {
                return 'error-row';
            }
            if (row.direction === 'inbound') {
                return 'inbound-row'
            }
            return 'outbound-row'
        },
        startLoading() {
            this.loading = true
        },
        stopLoading() {
            this.loading = false
        },
        loadChronology() {
            this.loading = true
            this.$store.dispatch('orders/getOpenFiberChronologyUpdatesNew', {id: this.orderId}).then((response) => {
                this.chronology = response.data.data;
                //this.loadGiacenza();
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.$emit('reloaded')
                this.loading = false;
            })
        }

    }
}
</script>

<style>
.text-medium {
    font-size: 14px;
}

.el-table .error-row {
    background: #ffefef;
}

.el-table .inbound-row {
    background: #FFFFFF;
}

.el-table .outbound-row {
    background: #f9fcff;
}
</style>
