<template>
    <div class="chronology-section mt-4" v-loading="loading">
        <div class="d-flex align-items-center mb-3">
            <h3 class="m-0 mr-2">Cronologia Ordine Open Fiber (B2B)</h3>
            <el-button circle size="small" icon="refresh"
                       data-tooltip="'Ricarica cronologia'" @click="reloadChronology"/>
            <el-dropdown @command="handleAction" class="ml-auto" trigger="click">
                <el-button type="primary">Azioni<i class="ml-2 fa-solid fa-chevron-down"></i>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-if="!modelValue.wholesaled_at" command="send">Invia a OpenFiber</el-dropdown-item>
                        <el-dropdown-item
                            v-if="modelValue.wholesaled_at && (modelValue.status_id === 123 || modelValue.status_id === 125 || modelValue.status_id !== 128)"
                            command="desuspend">Invia desospensione
                        </el-dropdown-item>
                        <el-dropdown-item v-if="modelValue.wholesaled_at && modelValue.status_id !== 125 && modelValue.status_id !== 128"
                                          command="reschedule">Modifica RDAC
                        </el-dropdown-item>
                        <el-dropdown-item v-if="modelValue.wholesaled_at && modelValue.status_id !== 128" command="cancel">Annulla
                            ordine su
                            OpenFiber
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-dropdown @command="handleAction" class="ml-auto" trigger="click">
                <el-button type="primary">Azioni avanzate<i class="ml-2 fa-solid fa-chevron-down"></i>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-if="modelValue.wholesaled_at" command="unwholesale">Annulla flag ordine inviato
                            (interno)
                        </el-dropdown-item>
                        <el-dropdown-item v-if="!modelValue.wholesaled_at" command="wholesale">Imposta flag ordine inviato
                            (interno)
                        </el-dropdown-item>
                        <el-dropdown-item v-if="modelValue.wholesaled_at && modelValue.status_id !== 128" command="deactivation">
                            Cessazione
                            linea attiva
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>

        <open-fiber-chronology :order-id="modelValue.id" ref="openfiberChronology" @reloaded=""/>

        <open-fiber-wholesale-reschedule-modal :order-id="modelValue.id" :visible="showRescheduleModal"
                                               @close="showRescheduleModal = false"/>

        <open-fiber-force-wholesale-modal :order-id="modelValue.id" :visible="showForceWholesale"
                                          @close="showForceWholesale = false; reloadChronology"/>
    </div>

</template>
<script>
import OpenFiberChronology from "@/pages/Dashboard/Orders/Components/OpenFiberChronology";
import OpenFiberWholesaleRescheduleModal from "@/pages/Dashboard/Orders/Components/OpenFiberWholesaleRescheduleModal";
import Swal from "sweetalert2";
import OpenFiberForceWholesaleModal from "@/pages/Dashboard/Orders/Components/OpenFiberForceWholesaleModal";

export default {
    name: "OpenFiberChronologyDetails",
    components: {OpenFiberForceWholesaleModal, OpenFiberWholesaleRescheduleModal, OpenFiberChronology},
    data: function () {
        return {
            loading: false,
            showRescheduleModal: false,
            showForceWholesale: false
        }
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleAction(command) {
            switch (command) {
                case 'send':
                    this.sendToOpenFiber()
                    break;
                case 'unwholesale':
                    this.unWholesale()
                    break;
                case 'wholesale':
                    this.wholesale()
                    break;
                case 'cancel':
                    this.cancelOrder()
                    break
                case 'desuspend':
                    this.desuspend()
                    break
                case 'reschedule':
                    this.showRescheduleModal = true;
                    break
                case 'deactivation':
                    this.deactivation()
                    break
            }
        },
        sendToOpenFiber() {
            let note = null;

            Swal.fire({
                title: 'Inviare ordine a OpenFiber?',
                input: 'text',
                inputLabel: `Aggiungi una nota per l'attivazione`,
                inputValue: note,
                confirmButtonText: 'Sì, invia!',
                cancelButtonText: 'No, ho sbagliato!',
                showCancelButton: true,
                reverseButtons: false
            }).then(result => {
                if (!result.isConfirmed) {
                    return;
                }
                this.startLoading();
                const note = result.value;
                this.$store.dispatch('orders/openfiberWholesaleActivationOrder', {
                    id: this.modelValue.id,
                    note: note
                }).then((response) => {
                    this.$emit('input', response.data.data)
                    this.stopLoading();
                    this.reloadChronology();
                }).catch(e => {
                    console.log(e)
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        cancelOrder() {
            this.askGenericConfirm("Vuoi annullare l'ordine a OpenFiber?").then((value) => {
                if (!value.isConfirmed) {
                    return
                }
                this.startLoading();
                this.$store.dispatch('orders/openfiberWholesaleCancelOrder', this.modelValue.id).then((response) => {
                    this.stopLoading();
                    this.reloadChronology();
                    this.$emit('input', response.data.data)
                }).catch(e => {
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        unWholesale() {
            this.askGenericConfirm("Annulla flag ordine inviato?", 'Stai per rimuovere il flag di "ordine inviato a OpenFiber" senza che venga annullato. Sei sicuro?', 'Sì', 'No, annulla l\'operazione!').then((value) => {
                if (!value.isConfirmed) {
                    return
                }
                this.startLoading();
                this.$store.dispatch('orders/unWholesale', this.modelValue.id).then((response) => {
                    this.stopLoading();
                    this.reloadChronology();
                    this.$emit('input', response.data.data)
                }).catch(e => {
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        wholesale() {
            this.showForceWholesale = true
        },
        desuspend() {
            let note = null;

            Swal.fire({
                title: 'Inviare desospensione?',
                input: 'text',
                inputLabel: 'Aggiungi note per la desospensione.',
                inputValue: note,
                confirmButtonText: 'Sì, invia!',
                cancelButtonText: 'No, ho sbagliato!',
                showCancelButton: true,
                reverseButtons: false
            }).then(result => {
                if (!result.isConfirmed) {
                    return;
                }
                this.startLoading();
                const note = result.value;
                this.$store.dispatch('orders/desuspend', {id: this.modelValue.id, note: note}).then((response) => {
                    this.$emit('input', response.data.data)
                    this.stopLoading();
                    this.reloadChronology();
                }).catch(e => {
                    console.log(e)
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        deactivation() {
            this.askGenericConfirm("Disattivare la linea?", 'Stai per chiedere la disattivazione della linea. Sei sicuro?', 'Sì', 'No, annulla l\'operazione!').then((value) => {
                if (!value.isConfirmed) {
                    return
                }
                this.startLoading();
                this.$store.dispatch('orders/deactivation', this.modelValue.id).then((response) => {
                    this.$emit('input', response.data.data)
                    this.stopLoading();
                    this.reloadChronology()
                }).catch(e => {
                    this.stopLoading();
                    this.showResponseErrorMessage(e)
                })
            })
        },
        startLoading() {
            this.$refs.openfiberChronology.startLoading();
        },
        stopLoading() {
            this.$refs.openfiberChronology.stopLoading();
        },
        reloadChronology() {
            this.$refs.openfiberChronology.loadChronology()
            this.$emit('reloaded')
        }
    }
}
</script>

<style scoped>

</style>
