<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi Peoplesoft">
        <peoplesoft-form v-bind:peoplesoft="peoplesoft" v-loading="loading" @update="updateForm"></peoplesoft-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import PeoplesoftForm from './PeoplesoftForm.vue'

  export default {
    components: {
      PeoplesoftForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {}
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'PeoplesoftAdd',
          minimumPermissionLevel: 100,
          listPath: '/offerte/peoplesoft/lista'
        },
        loading: false,
        canShowView: false,
        peoplesoft: {
          offertadedicata: 'STND',
          offertadedicata_ca3: 'Offerta standard',
          bundle: 'PS000128_PKG',
          offerta_terminale: 'PS000573_PRD',
          categoria_offerta: 'PS000737_PRD',
          terminaleabbinato: 'PS000063_PRD',
          dealer: true,
          accesso_portale: true,
          mnp_fittizia: true,
          promocode_richiesto: false
        },
        checkbox_attivazione_ca3: []
      }
    },
    methods: {
      updateForm (pElement) {
        this.loading = true
        this.$store.dispatch('peoplesoft/add', pElement).then((response) => {
          this.loading = false
          this.$router.push('/offerte/peoplesoft/lista/modifica/' + response.data.peoplesoft.id)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questo elemento peoplesoft', '', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
