<template>
  <div>

    <el-card v-loading="loading">
      <div slot="header">
        <h4 class="mt-0 mb-4">Esporta Censimenti Open Fiber</h4>
      </div>
      <div class="row">
        <div class="col-12">
          <h5>Questo file contiene tutti i censimenti attualmente "in coda" lato Open Fiber. Esportarlo non comporta nessuna variazione sugli ordini.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <el-button type="primary" @click="downloadCensimenti">SCARICA CENSIMENTI</el-button>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>

import fileDownload from 'js-file-download'

export default {
  name: "Exports",
  components: {
    fileDownload
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
  },
  methods: {
    downloadCensimenti() {
      this.loading = true
      let params = {};
      params = Object.assign(params, this.$store.getters.apiCommon)
      this.axios.post(this.$config.endpoints.orders.wholesale.openfiber.exportCensimenti, params,
        {
          responseType: 'blob'
        }
      )
        .then((response) => {
          this.loading = false
          fileDownload(response.data, 'censimenti_' + new Date().toLocaleDateString() + '_' + new Date().getTime() + '.xlsx')
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          this.showResponseErrorMessage(e)
        })
    }
  }
}
</script>

<style scoped>

</style>
