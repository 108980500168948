<template>
    <div v-loading.fullscreen.lock="loading">

        <h4>Generale</h4>

        <el-form label-position="top">
            <div class="row align-items-end">
                <div class="col-12 col-md-6">
                    <el-form-item label="Provenienza">
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <el-button @click="dialogPartner = true"><i class="fa-solid fa-plus"></i></el-button>
                        </div>
                        <partner-select v-model="order.origin" ref="partner-select"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Provenienza (cliente)">
                        <el-input v-model="order.customer_origin"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Codice amico">
                        <el-input v-model="order.referer_code"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Codice OTP">
                        <el-input v-model="order.sms_otp" :disabled="true"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-right mt-3">
                    <el-button type="primary" icon="check" @click.prevent="validateForm">Salva</el-button>
                </div>
            </div>
        </el-form>


        <p style="text-align: left;">
            <a href="#" v-on:click.prevent="copyContractLink()" v-if="this.order.status_id === 195">Copia link per
                completare
                contratto online</a>
        </p>

        <el-divider/>

        <div class="row">
            <div class="col-12">
                <order-product-details-section v-if="order && order.order_product"
                                               v-bind:order_product.sync="order.order_product"
                                               v-bind:customer.sync="order.customer" v-bind:order.sync="order"
                                               @refresh="$emit('refresh')"/>

                <div class="row mt-2">
                    <div class="col-12 mt-2 mb-3 text-right">
                        <el-button type="primary" @click="updateOrderProduct(order.order_product)" icon="check">Salva
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-divider/>

        <div class="row" v-if="order.id">
            <div class="col-12">
                <order-product-activation-address-section :order="order" @input="setActivationAddress"/>
            </div>
        </div>

        <div class="row" v-if="order.id">
            <div class="col-12">
                <order-product-wholesale-info-section v-if="order && order.customer" v-bind:order.sync="order"/>
            </div>
        </div>

        <!-- SOF DIALOGS !-->

        <!-- SOF DIALOG OPZIONI !-->
        <el-dialog title="Aggiungi opzione" v-model="displayAddOfferOption"
                   v-if="displayAddOfferOption && currentOrderProductForOption" class="dialog-options">
            <div class="row">
                <div class="col-12"><h5>Aggiungi in questo ordine a
                    <strong>{{ currentOrderProductForOption.offer.internal_name }}</strong></h5></div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6 pr-2">
                    <label>Scegli l'opzione</label>
                    <br/>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="selectedOfferOption"
                               placeholder="Seleziona..." filterable size="large">
                        <el-option
                            v-for="item in offerOptions"
                            :key="item.id"
                            :label="item.id + ' - ' + item.internal_name"
                            :value="item.id">
                            <span style="float: left" v-if="item.internal_name">{{ item.internal_name }}</span><span
                            style="float: left" v-else>{{ item.name }}</span>
                            <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button class="mr-2" @click="displayAddOfferOption = false">Annulla</el-button>
                <el-button type="primary" icon="el-icon-check" @click.prevent="addOfferOption()">Aggiungi</el-button>
            </div>
        </el-dialog>
        <!-- EOF DIALOG OPZIONI !-->


        <!-- SOF DIALOG OFFERTE INTEGRATE !-->
        <el-dialog title="Aggiungi offerta integrata" v-model="displayAddIntegratedOffer"
                   v-if="displayAddIntegratedOffer && currentOrderProductForIntegratedOffer" class="dialog-options">
            <div class="row">
                <div class="col-12"><h5>Aggiungi in questo ordine a
                    <strong>{{ currentOrderProductForIntegratedOffer.offer.internal_name }}</strong></h5></div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6 pr-2">
                    <label>Scegli l'offerta integrata</label>
                    <br/>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="selectedIntegratedOffer" placeholder="Seleziona..." filterable size="large">
                        <el-option
                            v-for="item in integratedOffers"
                            :key="item.id"
                            :label="item.id + ' - ' + item.internal_name"
                            :value="item.id">
                            <span style="float: left">{{ item.internal_name }}</span>
                            <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">

                <button class="btn btn-default btn-outline btn-fill" @click="displayAddIntegratedOffer = false">
                    Annulla
                </button>

                <button type="submit" class="btn btn-primary btn-fill" @click.prevent="addIntegratedOffer()"
                        v-if="selectedIntegratedOffer">
                    Aggiungi
                </button>
            </div>
        </el-dialog>
        <!-- EOF DIALOG OFFERTE INTEGRATE !-->


        <!-- SOF DIALOG MODIFICA OFFERTA !-->
        <el-dialog title="Modifica offerta" v-model="dialogChangeOffer" v-if="dialogChangeOffer">
            <div class="row">
                <div class="col-12">
                    <label>Seleziona un'offerta per questo prodotto</label>
                    <el-select value-key="id" v-model="editOrderProductDetails.offer" v-loading="loading"
                               style="width: 100%"
                               class="select-default mb-3" filterable placeholder="..." size="large">

                        <el-option
                            v-for="item in editOrderProductDetails.offersList"
                            class="select-default"
                            :key="item.id"
                            :label="item.internal_name"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <h5 class="mt-3">Dettagli offerta: <strong>{{ editOrderProductDetails.offer.internal_name }}</strong>
                <small>(ID:
                    {{ editOrderProductDetails.offer.id }})</small>
                <router-link :to="{path:'/offerte/lista/modifica/' + editOrderProductDetails.offer.id}"
                             v-if="canEdit(editOrderProductDetails.offer)"><small class="a-link btn-link ml-1"
                                                                                  style="color: #E74155 !important;">Modifica
                    nel sistema <i class="el-icon-edit"></i></small></router-link>
            </h5>

            <div class="row" style="font-size: 14px;">
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.anticipo">Anticipo:
                    {{ editOrderProductDetails.offer.anticipo }}€
                </div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.costo_attivazione">Costo attivazione:
                    {{ editOrderProductDetails.offer.costo_attivazione }}€
                </div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.costo_attivazione_gia3">Costo attivazione
                    già 3:
                    {{ editOrderProductDetails.offer.costo_attivazione_gia3 }}€
                </div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.costo_attivazione_nuova_linea">Costo
                    attivazione
                    nuova linea: {{ editOrderProductDetails.offer.costo_attivazione_nuova_linea }}€
                </div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.costo_attivazione_passaggio">Costo
                    attivazione
                    passaggio: {{ editOrderProductDetails.offer.costo_attivazione_passaggio }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.canone_mensile">Canone mensile:
                    {{ editOrderProductDetails.offer.canone_mensile }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.canone_mensile_scontato">Canone mensile
                    scontato:
                    {{ editOrderProductDetails.offer.canone_mensile_scontato }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.obbligo_contrattuale">Obbligo
                    contrattuale: <span
                        v-if="editOrderProductDetails.offer.obbligo_contrattuale === '0'">Assente</span><span
                        v-else>{{ editOrderProductDetails.offer.obbligo_contrattuale }} mesi</span><br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.ricarica_inclusa">Ricarica inclusa:
                    {{ editOrderProductDetails.offer.ricarica_inclusa }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.costo_prodotto">Costo prodotto:
                    {{ editOrderProductDetails.offer.costo_prodotto }}€
                </div>

                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.costo_tieni">Costo tieni:
                    {{ editOrderProductDetails.offer.costo_tieni }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.importo_rateizzato">Importo rateizzato:
                    {{ editOrderProductDetails.offer.importo_rateizzato }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.rata_mensile">Rata mensile:
                    {{ editOrderProductDetails.offer.rata_mensile }}€<br/></div>
                <div class="n-thin col-12" v-if="editOrderProductDetails.offer.rata_finale">Rata finale:
                    {{ editOrderProductDetails.offer.rata_finale }}€
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 col-md-4 col-lg-4 col-xl-3">
                    <label>CDC</label>
                    <br/>
                    <l-switch v-model="editOrderProductDetails.offer.cdc" disabled type="primary" on-text="SÌ"
                              off-text="NO"></l-switch>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-3">
                    <label>SDD</label>
                    <br/>
                    <l-switch v-model="editOrderProductDetails.offer.sdd" disabled type="primary" on-text="SÌ"
                              off-text="NO"></l-switch>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-3">
                    <label>Credito residuo</label>
                    <br/>
                    <l-switch v-model="editOrderProductDetails.offer.credito_residuo" disabled type="primary"
                              on-text="SÌ"
                              off-text="NO"></l-switch>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
        <button class="btn btn-default btn-outline btn-fill" @click="dialogChangeOffer = false">
          Annulla
        </button>

        <el-button type="submit"
                   @click="handleSaveOffer(currentOrderProduct, editOrderProductDetails.offer)">
          Salva
        </el-button>
      </span>
        </el-dialog>
        <!-- EOF DIALOG MODIFICA OFFERTA !-->


        <!-- SOF DIALOG AGGIUNGI/MODIFICA ORDER PRODUCT !-->
        <el-dialog v-model="dialogAddOrderProduct" v-if="dialogAddOrderProduct" id="dialog_add_edit_order_product">
            <h5 v-if="!isFromEditProduct" class="mt-0 font-weight-normal">Aggiungi un prodotto a questo ordine</h5>
            <h5 v-else>Modifica il prodotto di questo ordine</h5>

            <div class="row">
                <div class="col-12">
                    <label>Scegli il prodotto</label>
                    <el-select value-key="id" v-model="addOrderProductDetails.product" v-loading="loading"
                               style="width: 100%"
                               class="select-default mb-3" filterable placeholder="..." size="large"
                               @change="changedProduct()">

                        <el-option
                            v-for="item in addOrderProductDetails.productsList"
                            class="select-default"
                            :key="item.id"
                            :label="item.product_name"
                            :value="item">
              <span style="float: left">{{ item.product_name }} <span class="small n-lightgrey"
                                                                      v-if="item.internal_name">({{
                      item.internal_name
                  }})</span></span>
                            <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="row" v-if="addOrderProductDetails.colorsList && addOrderProductDetails.colorsList.length > 0">
                <div class="col-12">
                    <label>Scegli il colore</label>
                    <el-select value-key="color_id" v-model="addOrderProductDetails.color" v-loading="loading"
                               style="width: 100%"
                               class="select-default mb-3" filterable placeholder="..." size="large"
                               @change="checkIfValidOrderProduct">

                        <el-option
                            v-for="item in addOrderProductDetails.colorsList"
                            class="select-default"
                            :key="item.color_id"
                            :label="item.color_detail.name"
                            :value="item">
                            <span style="float: left">{{ item.color_detail.name }}</span>
                            <span style="float: right; width: 16px; height: 16px; margin-top: 8px; border-radius: 60px;"
                                  v-bind:style="{background: item.color_detail.hex}"></span>
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="row" v-if="addOrderProductDetails.product">
                <div class="col-12">
                    <label>Tipologia</label>
                    <br/>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="addOrderProductDetails.type" placeholder="Tutte" size="large"
                               @change="loadOffersByProduct(addOrderProductDetails.product, addOrderProductDetails.type)">
                        <el-option
                            class="select-default"
                            :key=0
                            label="Tutte"
                            :value=0>
                        </el-option>
                        <el-option
                            v-for="item in addOrderProductDetails.typesList"
                            class="select-default"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="row mt-3"
                 v-if="addOrderProductDetails.offersList.length <= 0 && addOrderProductDetails.product">
                <div class="col-12">
                    <h5 class="text-center"><strong>Non ci sono offerte per questo prodotto!</strong><br/>Prova a
                        selezionare un
                        altro prodotto.</h5>
                </div>
            </div>

            <div class="row" v-if="addOrderProductDetails.offersList.length > 0">
                <div class="col-12">
                    <label>Seleziona l'offerta</label>
                    <el-select value-key="id" v-model="addOrderProductDetails.selectedOffer" v-loading="loading"
                               style="width: 100%" class="select-default mb-3" filterable placeholder="..." size="large"
                               @change="checkIfValidOrderProduct()">

                        <el-option
                            v-for="item in addOrderProductDetails.offersList"
                            class="select-default"
                            :key="item.id"
                            :label="item.internal_name"
                            :value="item">
                            <span style="float: left">{{ item.internal_name }}</span>
                            <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>


            <span slot="footer" class="dialog-footer">
        <button class="btn btn-default btn-outline btn-fill" @click="dialogAddOrderProduct = false">
          Annulla
        </button>
        <button type="submit" class="btn btn-primary btn-fill" @click.prevent="handleAddOrderProduct()"
                :disabled="!canAddOrderProduct">
          <span v-if="!isFromEditProduct">Aggiungi</span>
          <span v-else>Modifica</span>
        </button>
      </span>

        </el-dialog>

        <!-- EOF DIALOG AGGIUNGI/MODIFICA ORDER PRODUCT !-->


        <!-- SOF DIALOG MODIFICA COLORE !-->
        <el-dialog title="Modifica colore" v-model="dialogChangeColor" v-if="dialogChangeColor">
            <div class="row">
                <div class="col-12">
                    <label>Seleziona un colore</label>
                    <el-select value-key="color_id" v-model="editOrderProductColorDetails.color" v-loading="loading"
                               style="width: 100%" class="select-default mb-3" filterable placeholder="..."
                               size="large">

                        <el-option
                            v-for="item in editOrderProductColorDetails.colorsList"
                            class="select-default"
                            :key="item.color_id"
                            :label="item.color_detail.name"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>


            <span slot="footer" class="dialog-footer">
        <button class="btn btn-default btn-outline btn-fill" @click="dialogChangeColor = false">
          Annulla
        </button>

        <button type="submit" class="btn btn-primary btn-fill"
                @click="handleChangeColor(currentOrderProduct, editOrderProductColorDetails.color)">
          Salva
        </button>
      </span>
        </el-dialog>

        <el-dialog title="Aggiungi partner" v-model="dialogPartner">
            <partner-form v-loading="loading" :redirect="false" @created="partnerCreated"/>
        </el-dialog>

    </div>
</template>

<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from '@/components'
import NpLineTypeSelect from "@/components/Inputs/NpLineTypeSelect";
import NewNpForm from "@/components/Np/NewNpForm";
import EditNpForm from "@/components/Np/EditNpForm";
import OrderProductDetailsSection from "../../Orders/OrderOfferDetails/OrderProductDetailsSection";
import OrderProductActivationAddressSection
    from "../../Orders/OrderOfferDetails/OrderProductActivationAddressSection";
import OrderProductWholesaleInfoSection
    from "../../Orders/OrderOfferDetails/OrderProductWholesaleInfoSection";
import PartnerSelect from "@/components/Inputs/PartnerSelect";
import PartnerForm from "@/pages/Dashboard/Partner/PartnerForm";

export default {
    mixins: [],
    components: {
        PartnerForm,
        PartnerSelect,
        OrderProductActivationAddressSection,
        OrderProductWholesaleInfoSection,
        OrderProductDetailsSection,
        EditNpForm,
        NewNpForm,
        LSwitch,
        LCheckbox,
        NpLineTypeSelect
    },
    props: {
        order: {}
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderOfferDetails'
            },
            loading: false,
            dialogChangeOffer: false,
            dialogChangeColor: false,
            dialogAddOrderProduct: false,
            dialogPartner: false,
            npDialogVisible: false,
            addOrderProductDetails: {
                product: null,
                color: null,
                colorsList: [],
                productsList: [],
                offersList: [],
                selectedOffer: null,
                type: null,
                typesList: []
            },
            editOrderProductDetails: {
                offer: null,
                offersList: []
            },
            editOrderProductColorDetails: {
                colorsList: [],
                color: null
            },
            isFromEditProduct: false,
            currentOrderProduct: null,
            currentOrderProductForOption: null,
            currentOrderProductIndex: null,
            orderProductToEdit: null,
            selectedOfferOption: null,
            currentNp: {
                id: null,
                order_product_id: null
            },
            currentOrderProductForIntegratedOffer: null,
            integratedOffers: [],
            selectedIntegratedOffer: null,
            displayAddIntegratedOffer: false,
            canAddOrderProduct: false,
            displayAddOfferOption: false,
            operationTypes: [],
            offerOptions: [],
            customerTypes: [
                {key: 'privato', label: 'Privato'},
                {key: 'ditta_individuale', label: 'Ditta'},
                {key: 'azienda', label: 'Società'}
            ],
            clusterTypes: [
                {key: 'A_B', label: 'A_B'},
                {key: 'C_D', label: 'C_D'}
            ],
            operators: [],
            simType: [
                {key: 0, label: 'Abbonamento'},
                {key: 1, label: 'Prepagato'}
            ]
        }
    },
    watch: {},
    computed: {},
    methods: {
        changedProduct() {
            this.loadOffersByProduct(this.addOrderProductDetails.product, this.addOrderProductDetails.type)
            this.addOrderProductDetails.color = null
        },
        getOrderProductIndexFromId(pOrderProductId) {
            for (let index = 0; index < this.order.order_products.length; ++index) {
                if (this.order.order_products[index].id === pOrderProductId) {
                    return index
                }
            }
            return null
        },
        addIntegratedOffer() {
            return this.addOrderProduct(this.selectedIntegratedOffer, this.currentOrderProductForIntegratedOffer.id)
        },
        addOfferOption() {
            this.loading = true
            this.$store.dispatch('orders/orderProducts/addOption', {
                id: this.currentOrderProductForOption.id,
                option_id: this.selectedOfferOption
            }).then((response) => {
                this.showGenericSuccess('Opzione', 'aggiunta con successo', '1000')
                this.loading = false
                let pOrderProductIndex = this.getOrderProductIndexFromId(this.currentOrderProductForOption.id)
                if (pOrderProductIndex !== null) {
                    this.order.order_products[pOrderProductIndex].options = response.data.order_product_options
                } else {
                    this.showGenericError('Errore!', 'Impossibile trovare questo order product nei dati salvati. Aggiorna la pagina.')
                }
                this.displayAddOfferOption = false
                this.offerOptions = []
                this.currentOrderProductForOption = null
                this.selectedOfferOption = null
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiunta delle opzioni di questa offerta')
            })
        },
        loadOperators() {
            this.loading = true
            this.$store.dispatch('operators/list').then((response) => {
                this.loading = false
                this.operators = response
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento degli operatori')
            })
        },
        canEdit(pElement) {
            return this.$store.getters['offers/canEdit'](pElement)
        },
        checkIfValidOrderProduct() {
            if (this.addOrderProductDetails.colorsList && this.addOrderProductDetails.colorsList.length > 0 && !this.addOrderProductDetails.color) {
                this.canAddOrderProduct = false
            } else if (this.addOrderProductDetails.selectedOffer && this.addOrderProductDetails.product) {
                this.canAddOrderProduct = true
            } else {
                this.canAddOrderProduct = false
            }
        },
        async updateAllOrderProducts() {
            this.loading = true
            let thereIsError = false
            for (let index = 0; index < this.order.order_products.length; ++index) {
                await this.$store.dispatch('orders/orderProducts/edit', this.order.order_products[index]).then((response) => {
                    // this.order.order_products[index] = response.data.order_product
                }).catch(e => {
                    this.loading = false
                    thereIsError = true
                    this.showLoadingError(e, 'l\'aggiornamento del prodotto #' + (index + 1).toString() + ' legato all\'offerta ' + this.order.order_products[index].offer.internal_name, '', false)
                })
                if (thereIsError) break
            }
            this.loading = false
            if (!thereIsError) {
                this.showGenericSuccess('I prodotti di questo ordine', 'sono stati aggiornati con successo', '')
            }
        },
        handleSaveOffer(pCurrentOrderProduct, pCurrentOffer) {
            this.updateOrderProduct({id: pCurrentOrderProduct.id, offer_id: pCurrentOffer.id}, true)
        },
        handleChangeColor(pCurrentOrderProduct, pCurrentColor) {
            this.updateOrderProduct({id: pCurrentOrderProduct.id, color_id: pCurrentColor.color_id}, true)
        },
        updateOrderProduct(pElement, pFromDialog = false) {
            this.loading = true
            this.$store.dispatch('orders/orderProducts/edit', pElement).then((response) => {
                this.loading = false
                this.showGenericSuccess('Prodotto')
                if (pFromDialog) {
                    this.dialogChangeOffer = false
                    this.dialogChangeColor = false
                    this.dialogAddOrderProduct = false
                }
                this.refreshOrderProduct(pElement.id)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la modifica dell\'offerta per questo prodotto')
            })
        },
        refreshOrderProduct(pId) {
            this.loading = true
            this.$store.dispatch('orders/orderProducts/getById', pId).then((response) => {
                this.loading = false
                this.order.order_product = response.data.order_product;
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento di questo prodotto nell\'ordine')
            })
        },
        loadOffersByProduct(pProduct, pNewProductTypeId) {
            this.loading = true
            this.$store.dispatch('products/getOffersForProductFilteredByOrderType', {
                id: pProduct.id,
                offers_filter_id: pNewProductTypeId
            }).then((response) => {
                this.loading = false
                this.addOrderProductDetails.offersList = response.data.product.offerte
                this.addOrderProductDetails.colorsList = response.data.product.colors
                this.addOrderProductDetails.selectedOffer = null
                this.canAddOrderProduct = false
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle offerte disponibili per questo prodotto')
            })
        },
        loadProducts(pIsFromEditProduct = false) {
            if (pIsFromEditProduct) {
                this.isFromEditProduct = true
            } else {
                this.isFromEditProduct = false
            }
            this.addOrderProductDetails.productsList = []
            this.addOrderProductDetails.offersList = []
            this.addOrderProductDetails.colorsList = []
            this.addOrderProductDetails.product = null
            this.addOrderProductDetails.type = null
            this.addOrderProductDetails.color = null
            this.loading = true
            this.addOrderProductDetails.selectedOffer = null
            this.$store.dispatch('products/list', {limit: -1}).then((response) => {
                this.loading = false
                this.addOrderProductDetails.productsList = response.data.products
                this.dialogAddOrderProduct = true
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei prodotti')
            })
        },
        handleAddOrderProduct() {
            if (!this.isFromEditProduct) {
                return this.addOrderProduct(this.addOrderProductDetails.selectedOffer.id)
            } else {
                let colorId = null
                if (this.addOrderProductDetails.color && this.addOrderProductDetails.color.color_id) {
                    colorId = this.addOrderProductDetails.color.color_id
                }
                return this.updateOrderProduct({
                    id: this.orderProductToEdit.id,
                    color_id: colorId,
                    offer_id: this.addOrderProductDetails.selectedOffer.id
                }, true)
            }
        },
        addOrderProduct(pOfferId, pRelatedOrderProductId = null) {
            if (this.addOrderProductDetails.color) {
                this.colorId = this.addOrderProductDetails.color.color_id
            } else {
                this.colorId = null
            }
            this.$store.dispatch('orders/addOrderProduct', {
                id: this.order.id,
                offer_id: pOfferId,
                related_order_product: pRelatedOrderProductId,
                operation_type: 1,
                color_id: this.colorId
            }).then((response) => {
                this.loading = false
                this.dialogAddOrderProduct = false
                this.dialogChangeColor = false
                this.order.order_products.push(response.data.order_product)
                this.addOrderProductDetails.color = {}
                this.addOrderProductDetails.colorsList = []
                if (this.selectedIntegratedOffer) {
                    this.selectedIntegratedOffer = null
                    this.displayAddIntegratedOffer = false
                }
                this.showGenericSuccess('Prodotto', 'aggiunto con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiunta del prodotto')
            })
        },
        loadOperationTypes() {
            this.loading = true
            this.$store.dispatch('orders/orderProducts/getOperationTypes').then((response) => {
                this.loading = false
                this.operationTypes = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei tipi di operazione')
            })
        },
        validateForm() {
            this.$emit('update', {
                id: this.order.id,
                origin: this.order.origin,
                referer_code: this.order.referer_code
            }, false)
        },
        copyContractLink() {
            this.$copyText(this.order.fill_order_link).then((e) => {
                this.showGenericSuccess('Link', 'copiato negli appunti', '1000')
            }).catch(e => {
                this.showGenericError('Errore!', 'Errore durante la copia negli appunti, verifica il tuo browser.')
            })
        },
        getContractUrl() {
            var getUrl = window.location
            return getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1] + '#/contratto?hash=' + this.order.hash;
        },
        setActivationAddress(value) {
            this.order.activation_address = value
        },
        partnerCreated() {
            this.dialogPartner = false
            this.$refs["partner-select"].loadItems()
        }
    },
    created: function () {
        this.loadOperationTypes()
        this.addOrderProductDetails.typesList = this.$store.getters['orders/types']
        this.loadOperators()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style>
.dialog-mnp .el-dialog__header {
    padding-bottom: 0;
}

.dialog-mnp .el-dialog__body {
    padding-top: 10px;
}

#dialog_add_edit_order_product .el-dialog__body {
    padding-top: 0 !important;
}

.totals-order-product {
    color: #E74155 !important;
    font-weight: bold !important;
}
</style>
