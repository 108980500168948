<template>
  <div class="row" v-if="canShowView" v-loading="loading">

    <div v-if="isLoading" style="position: fixed; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.9); z-index: 1000; top: 0; left: 0;">
      <half-circle-spinner
        :animation-duration="1700"
        :size="50"
        :color="'#4F96E4'"
        style="margin-left: auto; margin-right: auto; margin-top: 38vh;"
      />
      <h3 style="text-align: center; font-weight: 200 !important;">Sto generando i codici...</h3>
    </div>


    <div class="col-12">
      <el-card title="Modifica tipo promocode">
        <div slot="header">

          <h4 class="title mt-0">Modifica di <strong>{{promo_code_type.internal_name}}</strong><small> (ID: {{promo_code_type.id}})</small></h4>

          <h5 v-if="!loading && promo_code_type.created_at">Creato il: {{dateToItalian(promo_code_type.created_at, true)}}</h5>
          <p class="text-muted" v-if="!loading && promo_code_type.updated_at">Ultima modifica: {{dateToItalian(promo_code_type.updated_at, true)}}</p>

          <div class="row">
            <div class="col-12 col-md-3">
              <router-link :to="{path:'/promocode/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista tipi promocode
                </span>
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <promo-code-form v-bind:promo_code_type="promo_code_type" v-loading="loading" @update="updateForm" @delete="deleteElement"></promo-code-form>

      </el-card>

      <el-card class="mt-4 pb-2">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row">
              <h4 class="col-12 mt-0">Genera nuovi codici</h4>
              <div class="col-12 col-md-6">
                <el-input type="text"
                          label="Quantità"
                          v-model="currentQuantity">
                </el-input>
              </div>
              <div class="col-12 mt-4">
                <button type="submit" class="btn btn-warning btn-fill btn-wd" @click.prevent="handleGeneratePromoCodes" :disabled="currentQuantity === ''">
                  Genera nuovi codici
                </button>
              </div>
            </div>
          </div>


          <div class="col-12 col-md-6" style="border-left: 1px solid #ccc;" v-if="promo_code_type.statistics">
            <div class="row">
              <h4 class="col mt-0 float-left">Codici disponibili</h4>
              <div class="col mb-3">
                <button type="submit" class="btn btn-warning btn-fill float-right" @click.prevent="exportCSV">
                Esporta CSV
                </button>
              </div>
              <div class="col-12" style="font-size: 16px;">
                <div class="w-100 justify-content-between">
                  Percentuale codici disponibili
                  <span class="badge badge-pill badge-primary float-right" style="font-size: 13px;">{{promo_code_type.statistics.available_shop_codes_percentage}}%</span>
                </div>
                <hr style="margin: 8px 0 10px 0" />
                <div class="w-100 justify-content-between">
                  Disponibili
                  <span class="badge badge-pill badge-primary float-right" style="font-size: 13px;">{{promo_code_type.statistics.available_shop_codes}}</span>
                </div>
                <hr style="margin: 8px 0 10px 0" />
                <div class="w-100 justify-content-between">
                  Usati
                  <span class="badge badge-pill float-right" style="background-color: #e6e6e6; font-size: 13px;">{{promo_code_type.statistics.used_shop_codes}}</span>
                </div>
                <hr style="margin: 8px 0 10px 0" />
                <div class="w-100 justify-content-between">
                  <strong>
                    Totali
                    <span class="badge badge-pill float-right" style="background-color: #e6e6e6; font-size: 13px;">{{promo_code_type.statistics.total_shop_codes}}</span>
                  </strong>
                </div>
              </div>
            </div>
          </div>

        </div>

      </el-card>

    </div>

  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  import PromoCodeForm from './PromoCodeForm.vue'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
      PromoCodeForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'PromoCodeEdit',
          minimumPermissionLevel: 100,
          listPath: '/promocode/lista'
        },
        loading: false,
        isLoading: false,
        canShowView: false,
        promo_code_type: {},
        currentQuantity: ''
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('promoCodes/getById', {id: this.$route.params.id, stats: true}).then((response) => {
          this.promo_code_type = response.data.promo_code_type
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questo tipo di promocode')
        })
      },
      updateForm (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('promoCodes/editPromoType', pElement).then((response) => {
          this.loading = false
          // this.rate = response.data.rate
          this.showGenericSuccess('Elemento', 'aggiornato con successo')
          this.$router.push(this.componentSettings.listPath)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questo tipo di promocode', '', false)
        })
      },
      handleGeneratePromoCodes () {
        this.askGenericConfirm('Sei sicuro di voler generare ' + this.currentQuantity + ' codici per il tipo ' + this.promo_code_type.internal_name + '?', 'Premendo Sì confermerai la creazione.', 'Sì, genera!', 'No, ho sbagliato!').then((result) => {
          if (!result.value) {
            return
          }
          this.generatePromoCodes()
        })
      },
      generatePromoCodes () {
        this.isLoading = true
        this.$store.dispatch('promoCodes/generate', {quantity: this.currentQuantity, promo_id: this.promo_code_type.id}).then(() => {
          this.isLoading = false
          this.askGenericSuccess()
          this.updateData()
        }).catch(e => {
          this.isLoading = false
          this.showLoadingError(e, 'la creazione di questi promocode', '', false)
        })
      },
      deleteElement (pElement) {
        Swal.fire(this.deleteMessage('questo elemento')).then((result) => {
          if (!result.value) return
          this.$store.dispatch('promoCodes/deleteType', pElement).then(() => {
            Swal.fire('Eliminato!',
              'Tipo promocode eliminato!',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione del tipo promocode', '', false)
          })
        })
      },
      exportCSV () {
        this.isLoading = true
        this.$store.dispatch('promoCodes/exportCsv', {promo_id: this.promo_code_type.id}).then((file) => {
          this.isLoading = false
          let fileDownload = require('js-file-download')
          fileDownload(file.data, 'codici_promo_' + this.promo_code_type.id + '.csv', 'application/csv')
        }).catch((e) => {
          this.isLoading = false
          this.showLoadingErrorWithoutRetry(e, 'il download dei promocode in CSV')
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
