<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Aggiungi link">
                <useful-link-form v-bind:useful_link_form="useful_link_form" @update="addLink" v-loading="loading"></useful-link-form>
            </el-card>
        </div>
    </div>
</template>

<script>
    import UsefulLinkForm from './UsefulLinkForm.vue'

    export default {
      components: {
        UsefulLinkForm: UsefulLinkForm
      },
      computed: {},
      data () {
        return {
          componentSettings: {
            myComponentName: 'UsefulLinkForm',
            minimumPermissionLevel: 200,
            listPath: '/utility/link-utili/aggiungi'
          },
          loading: false,
          canShowView: false,
          useful_link_form: {}
        }
      },
      methods: {
        addLink (pElement) {
          this.loading = true
          this.$store.dispatch('usefulLinks/add', pElement).then((response) => {
            this.loading = false
            this.$router.push('/utility/link-utili/')
          }).catch(e => {
            this.loading = false
            this.showLoadingError(e, 'l\'inserimento di questo link', '', '', false)
          })
        }
      },
      created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
      },
      mounted: function () {},
      destroyed: function () {
        this.$off()
      }
    }
</script>

<style></style>
