<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">

            <el-card v-for="(group, index) in settings" :key="index" class="my-4">

                <h4 class="mt-2 mb-4">{{index}}</h4>

                <div v-for="setting in group" :key="setting.id">
                    <div class="row">

                        <div class="col-12" v-if="setting.type === 'text'">
                            <label>{{ setting.name }}<span style="font-size: 12px; text-transform: none"
                                                           v-if="setting.description"> ({{ setting.description }})</span><a
                                v-if="setting.tooltip" data-tooltip="setting.tooltip" class="btn-simple btn-link"> <i
                                class="fa fa-question"></i></a></label>
                            <el-input type="text" v-model="setting.value">
                            </el-input>
                            <div style="margin-bottom: 30px; margin-top: 40px;">
                                <hr style="margin-bottom: 2px; border-top: 2px solid rgba(0, 0, 0, 0.3)"/>
                            </div>
                        </div>

                        <div class="col-12" v-if="setting.type === 'boolean'">
                            <label>{{ setting.name }}<span style="font-size: 12px; text-transform: none"
                                                           v-if="setting.description"> ({{ setting.description }})</span><a
                                v-if="setting.tooltip" data-tooltip="setting.tooltip" class="btn-simple btn-link"> <i
                                class="fa fa-question"></i></a></label>
                            <br/>
                            <l-switch v-model="setting.value" type="primary" on-text="SÌ" off-text="NO"></l-switch>
                            <div style="margin-bottom: 30px; margin-top: 30px;">
                                <hr style="margin-bottom: 2px; border-top: 2px solid rgba(0, 0, 0, 0.3)"/>
                            </div>
                        </div>

                        <div class="col-12" v-if="setting.type === 'datetime'">
                            <label>{{ setting.name }}<span style="font-size: 12px; text-transform: none"
                                                           v-if="setting.description"> ({{ setting.description }})</span><a
                                v-if="setting.tooltip" data-tooltip="setting.tooltip" class="btn-simple btn-link"> <i
                                class="fa fa-question"></i></a></label>

                            <el-date-picker
                                v-model="setting.value"
                                type="datetime"
                                format="DD-MM-YYYY"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                language="it">
                            </el-date-picker>

                            <div style="margin-bottom: 30px; margin-top: 40px;">
                                <hr style="margin-bottom: 2px; border-top: 2px solid rgba(0, 0, 0, 0.3)"/>
                            </div>
                        </div>

                    </div>
                </div>

            </el-card>

            <el-card title="Impostazioni">
                <p v-if="!settings && !loading">Non ci sono al momento impostazioni da poter personalizzare.</p>

                <div class="row mb-3 mt-4" v-if="settings">
                    <div class="col-12">
                        <div class="text-center">

                            <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="saveAll">
                                Salva tutto
                            </button>

                        </div>
                    </div>
                </div>

            </el-card>


        </div>
    </div>
</template>
<script>
import {Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'

export default {
    components: {
        LSwitch,
        Breadcrumb,
        BreadcrumbItem
    },
    computed: {},
    data() {
        return {
            componentSettings: {
                minimumPermissionLevel: 100
            },
            currentState: {},
            loading: false,
            canShowView: false,
            settings: []
        }
    },
    methods: {
        updateData() {
            this.loading = true
            this.$store.dispatch('settings/list', {grouped: true}).then((response) => {
                this.settings = response.data.settings
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle impostazioni')
            })
        },
        saveAll() {
            let toBeSaved = []

            for (let key in this.settings) {
                let group = this.settings[key]
                for (let i = 0; i < group.length; ++i) {
                    toBeSaved.push(group[i])
                }
            }
            if (toBeSaved.length < 1) {
                this.showGenericError('Errore!', 'Non sembra ci sia nulla da salvare.')
                return
            }
            this.askGenericConfirm('Sei sicuro?', 'Premendo sì, aggiornerai collettivamente TUTTE le impostazioni presenti in questa pagina.').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('settings/editAll', {settings: toBeSaved}).then(() => {
                    this.showGenericSuccess('Impostazioni', 'aggiornate con successo')
                    this.loading = false
                }).catch((e) => {
                    this.loading = false
                    this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento delle impostazioni')
                })
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.updateData()
    },
    mounted: function () {
    },
    destroyed: function () {
    }
}
</script>
<style></style>
