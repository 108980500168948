<template>
    <div class="order-product-option-list">
        <ul class="list-group">
            <div class="list-group-item flex-column align-items-start" v-for="(option, key) in modelValue">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{ option.name }}</h5>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        <!--            <span>Costo una tantum: {{option.cash_amount}}€</span>-->
                        <span>Costo ricorrente: {{ option.recurrent_cost }}€</span>
                    </div>
                    <el-button type="text" icon="el-icon-delete" class="text-danger float-right"
                               @click="deleteItem(option)"></el-button>
                </div>
            </div>
            <div v-if="modelValue.length <= 0">
                Nessuna opzione aggiunta
            </div>
        </ul>
    </div>
</template>

<script>
export default {
    name: "order-product-options-list",
    props: {
        modelValue: {
            type: Array
        }
    },
    methods: {
        deleteItem(option) {
            this.loading = true
            this.$store.dispatch('orders/orderProducts/removeOption', {
                id: option.pivot.order_product_id,
                option_id: option.id
            }).then((response) => {
                this.$emit('deleted', option)
                this.showGenericSuccess('Opzione', 'rimossa con successo')
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'la rimozione di questa opzione')
            })
        },
        reloadOptions() {

        }
    }
}
</script>

<style scoped>

</style>
