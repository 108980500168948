import {createStore} from "vuex";
import VuexPersistence from 'vuex-persist'

import activationAddress from "@/vuex/modules/activationAddress";
import colors from "@/vuex/modules/colors";
import compass from "@/vuex/modules/compass";
import customers from "@/vuex/modules/customers";
import inbound from "@/vuex/modules/inbound";
import medias from "@/vuex/modules/medias";
import mnp from "@/vuex/modules/mnp";
import notifications from "@/vuex/modules/notifications/notifications";
import offers from "@/vuex/modules/offers";
import offerOption from "@/vuex/modules/offerOption";
import operators from "@/vuex/modules/operators";
import orders from "@/vuex/modules/orders/orders";
import olo2olo from "@/vuex/modules/olo2olo";
import peoplesoft from "@/vuex/modules/peoplesoft";
import productAlerts from "@/vuex/modules/productAlerts";
import products from "@/vuex/modules/products";
import promoCodes from "@/vuex/modules/promoCodes";
import rates from "@/vuex/modules/rates";
import receipts from "@/vuex/modules/receipts";
import recharges from "@/vuex/modules/recharges";
import settings from "@/vuex/modules/settings";
import shippingLogs from "@/vuex/modules/shippingLogs";
import shippings from "@/vuex/modules/shippings";
import statistics from "@/vuex/modules/statistics";
import terms from "@/vuex/modules/terms";
import users from "@/vuex/modules/users";
import warehouse from "@/vuex/modules/warehouse";
import timePanel from "@/vuex/modules/timePanel";
import usefulLinks from "@/vuex/modules/usefulLinks";
import utility from "@/vuex/modules/utility";
import preorderForms from "@/vuex/modules/preorderForms";
import emailCampaigns from "@/vuex/modules/emailCampaigns";
import partner from "@/vuex/modules/partner";
import assurance from "@/vuex/modules/assurance";
import migrationDonating from "@/vuex/modules/migrationDonating";
import axios from "axios";
import Config from "@/globalConfig";
import router from "@/routes/router";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export const store = createStore({
    state() {
        return {
            userId: null,
            sessionToken: null,
            two_fa_session: null,
            user: [],
            role: null,
            apiCommon: {},
            shownSessionReminder: false,
            lastExpiryDate: null,
            commonElements: {},
            loading: false
        }
    },
    modules: {
        activationAddress,
        colors,
        compass,
        customers,
        inbound,
        medias,
        mnp,
        notifications,
        offers,
        offerOption,
        operators,
        orders,
        olo2olo,
        peoplesoft,
        productAlerts,
        products,
        promoCodes,
        rates,
        receipts,
        recharges,
        settings,
        shippingLogs,
        shippings,
        statistics,
        terms,
        users,
        warehouse,
        timePanel,
        usefulLinks,
        utility,
        preorderForms,
        emailCampaigns,
        partner,
        assurance,
        migrationDonating
    },
    actions: {
        async refreshInitialData({dispatch}) {
            return await dispatch('loadInitialData')
        },
        async loadInitialData({dispatch}) {
            var companiesStatus = await dispatch('loadCompanies')
            var rolesStatus = await dispatch('loadRoles')
            var statusesStatus = await dispatch('orders/statuses/loadCachedStatuses')
            var typesStatus = await dispatch('orders/loadCachedTypes')
            if (!companiesStatus || !rolesStatus || !statusesStatus || !typesStatus) {
                return false
            }
        },
        async loadCompanies({commit, state}) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.companies.base, state.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(false)
                            return
                        }
                        commit('updateCompanies', response.data.companies)
                        resolve(true)
                    })
                    .catch(() => {
                        commit('updateCompanies', {})
                        reject(false)
                    })
            })
        },
        async loadRoles({commit, state}) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.users.roles, state.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(false)
                            return
                        }
                        commit('updateRoles', response.data.roles)
                        resolve(true)
                    })
                    .catch(() => {
                        commit('updateRoles', {})
                        reject(false)
                    })
            })
        },
        resetAll: ({state, dispatch}) => {
            dispatch('orders/reset')
            dispatch('orders/statuses/reset')
            dispatch('orders/orderProducts/reset')
            dispatch('orders/paymentMethods/reset')
            dispatch('customers/reset')
            dispatch('notifications/emails/reset')
            dispatch('medias/reset')
            dispatch('offers/reset')
            dispatch('shippings/reset')
            dispatch('promoCodes/reset')

        },
        logout: ({state, dispatch, commit}, pRedirect = true) => {
            dispatch('resetAll')
            commit('logout')
            if (pRedirect) router.push('/login')
        },
        login: ({state, dispatch, commit}, payload) => {
            console.log('store login', payload)
            commit('login', payload)
        }
    },
    mutations: {
        updateCompanies: (state, companies) => {
            state.commonElements.companies = companies
        },
        updateRoles: (state, roles) => {
            state.commonElements.roles = roles
        },
        shownSessionReminder: (state, payload) => {
            state.shownSessionReminder = payload
        },
        setLastExpiryDate: (state, payload) => {
            state.lastExpiryDate = payload
        },
        setRequestedPath: (state, payload) => {
            state.requestedPath = payload
        },
        login: (state, payload) => {
            state.userId = payload.user_id
            state.sessionToken = payload.session_token
            state.role = payload.role
            state.apiCommon = {
                api_key: Config.apiKey,
                session_token: payload.session_token,
                user_id: payload.user_id
            }
            state.shownSessionReminder = false
        },
        logout: (state) => {
            state.userId = null
            state.user = null
            state.sessionToken = null
            state.role = null
            state.apiCommon = null
            state.shownSessionReminder = false
            state.lastExpiryDate = null
            state.loading = false
            state.commonElements = {}
        },
        redirToDashboard: () => {
            router.push(Config.homepage)
        },
        saveUser: (state, payload) => {
            state.user = payload
        },
        saveTwoFactorAuth: (state, payload) => {
            state.two_fa_session = payload
        }
    },
    getters: {
        userId: state => state.userId,
        sessionToken: state => state.sessionToken,
        user: state => state.user,
        twoFactorAuth: state => state.two_fa_session,
        role: state => state.role,
        apiCommon: state => state.apiCommon,
        shownSessionReminder: state => state.shownSessionReminder,
        lastExpiryDate: state => state.lastExpiryDate,
        commonElements: state => state.commonElements,
        companies: state => state.commonElements.companies,
        roles: state => state.commonElements.roles,
        loading: state => state.loading,
        requestedPath: state => state.requestedPath
    },
    plugins: [vuexLocal.plugin]
})
