<template>

    <div v-loading="loading" v-if="customer">

        <div>
            <h4 v-observe-visibility="visibilityChanged" class="mt-2">Dati di contatto
                <span v-if="customer.type === 1 || customer.type === 2"
                      style="color: #4F96E4; float: left; font-weight: bold; font-size: 80%; margin-left: 7px;">- Cliente con Partita IVA<i
                    class="fa fa-briefcase ml-2"></i></span>
            </h4>
            <el-form label-position="top">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label>Nome</label>
                        <el-input type="text"
                                  label="Nome"
                                  name="first_name"
                                  v-model="customer.first_name">
                        </el-input>
                    </div>
                    <div class="col-12 col-md-6">
                        <label>Cognome</label>
                        <el-input type="text"
                                  label="Cognome"
                                  name="last_name"
                                  v-model="customer.last_name">
                        </el-input>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <label>Email</label>
                        <el-input type="email"
                                  label="Email"
                                  name="email"
                                  v-model="customer.email">
                        </el-input>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 col-md-6">
                        <label>Cellulare</label>
                        <el-input type="text"
                                  label="Cellulare"
                                  name="cellulare"
                                  v-model="customer.mobile_phone_number">
                        </el-input>
                    </div>
                    <div class="col-12 col-md-6">
                        <label>Recapito alternativo</label>
                        <el-input type="text"
                                  label="Recapito alternativo"
                                  name="telefono"
                                  v-model="customer.phone_number">
                        </el-input>
                    </div>
                </div>
            </el-form>
        </div>

        <hr/>

        <div>
            <h4>Dettagli cliente</h4>

            <el-form label-position="top">
                <div class="row" v-if="customer.checked_cf">
                    <div class="col-12">
                        <div class="mb-3">
                            <h5><span class="mb-4" style="color: rgb(46, 196, 182); font-weight: bold;">Il codice fiscale è verificato <i
                                class="el-icon-success"></i></span></h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4">
                        <label>ID Cliente</label>
                        <el-input type="number"
                                  label="ID cliente"
                                  name="id"
                                  disabled
                                  v-model="customer.id">
                        </el-input>
                    </div>

                    <div class="col-12 col-md-4">
                        <label>Codice Cliente TS</label>
                        <div class="d-flex align-items-center" v-loading="tsCodeLoading">
                            <el-input type="text"
                                      label="Codice Cliente TS"
                                      name="ts_code"
                                      v-model="customer.ts_code">
                            </el-input>
                            <div class="ml-2">
                                <el-button icon="refresh" circle type="primary" @click="checkTsCode"></el-button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <label>Tipo cliente</label>
                        <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                                   v-model="customer.type"
                                   placeholder="Seleziona...">
                            <el-option
                                v-for="item in types"
                                class="select-default"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="type">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4">
                        <label>Sesso</label>
                        <br/>
                        <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                                   v-model="customer.sex"
                                   placeholder="Seleziona...">
                            <el-option
                                v-for="item in sesso"
                                class="select-default"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="sesso">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="col-12 col-md-4">
                        <label>Codice fiscale</label>
                        <el-input type="text"
                                  label="Codice fiscale"
                                  name="codice_fiscale"
                                  v-model="customer.codice_fiscale">
                        </el-input>
                    </div>
                    <div class="col-12 col-md-4">
                        <label>Nazionalità</label>
                        <br/>
                        <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                                   v-model="customer.nazionalita"
                                   placeholder="Seleziona...">
                            <el-option
                                v-for="item in nazionalita"
                                class="select-default"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="nazionalita">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4">
                        <label>Luogo nascita</label>
                        <el-input type="text"
                                  label="Luogo Nascita"
                                  name="luogo_nascita"
                                  v-model="customer.luogo_nascita">
                        </el-input>
                    </div>

                    <div class="col-12 col-md-4">
                        <label>Provincia nascita</label>
                        <br/>
                        <province-select v-model="customer.provincia_nascita"></province-select>
                    </div>

                    <div class="col-12 col-md-4">
                        <label>Data di nascita</label>
                        <el-date-picker
                            v-model="customer.data_nascita"
                            type="date"
                            placeholder="Seleziona..."
                            label="Data di nascita"
                            :picker-options="datePickerOptions"
                            format="DD-MM-YYYY"
                            value-format="YYYY-MM-DD"
                            language="it">
                        </el-date-picker>
                    </div>
                </div>


                <div v-if="customer.type !== 'privato'">
                    <hr/>
                    <h4 v-if="customer.type === 'ditta_individuale'">Dati ditta individuale</h4>
                    <h4 v-else>Dati azienda</h4>

                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label>Partita IVA</label>
                            <el-input type="text"
                                      label="Partita IVA"
                                      name="partita_iva"
                                      v-model="customer.partita_iva">
                            </el-input>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>Codice fiscale società</label>
                            <el-input type="text"
                                      label="Codice fiscale società"
                                      name="codice_fiscale_societa"
                                      v-model="customer.codice_fiscale_societa">
                            </el-input>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-12 col-md-8">
                            <label>Indirizzo sede legale</label>
                            <el-input type="text"
                                      label="Indirizzo sede legale"
                                      name="indirizzo_sede_legale"
                                      v-model="customer.indirizzo_sede_legale">
                            </el-input>
                        </div>

                        <div class="col-12 col-md-2">
                            <label>Civico</label>
                            <el-input type="text"
                                      label="Civico"
                                      name="civico_sede_legale"
                                      v-model="customer.civico_sede_legale">
                            </el-input>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 col-md-4">
                            <label>Comune sede legale</label>
                            <el-input type="text"
                                      label="Comune sede legale"
                                      name="comune_sede_legale"
                                      v-model="customer.comune_sede_legale">
                            </el-input>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>CAP sede legale</label>
                            <el-input type="text"
                                      label="CAP Sede Legale"
                                      name="cap_sede_legale"
                                      v-model="customer.cap_sede_legale">
                            </el-input>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>Provincia sede legale</label>
                            <province-select v-model="customer.provincia_sede_legale"/>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12 col-md-4">
                            <label>Provincia REA</label>
                            <province-select v-model="customer.provincia_rea"/>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>Anno REA</label>
                            <el-input type="text"
                                      label="Anno REA"
                                      name="anno_rea"
                                      v-model="customer.anno_rea">
                            </el-input>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>Numero REA</label>
                            <el-input type="text"
                                      label="Numero REA"
                                      name="numero_rea"
                                      v-model="customer.numero_rea">
                            </el-input>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12 col-md-4">
                            <label>Tipo impresa</label>
                            <el-select v-loading="loading" filterable style="width: 100%"
                                       v-model="customer.tipo_impresa" placeholder="Seleziona...">
                                <el-option
                                    v-for="item in tipoImpresa"
                                    class="select-default"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                    name="tipo_impresa">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>Forma Giuridica</label>
                            <el-select v-loading="loading" filterable style="width: 100%" class="select-default mb-3"
                                       v-model="customer.forma_giuridica" placeholder="Seleziona...">
                                <el-option
                                    v-for="item in formaGiuridica"
                                    class="select-default"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                    name="forma_giuridica">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="col-12 col-md-4">
                            <label>Ragione Sociale</label>
                            <el-input type="text"
                                      label="Ragione Sociale"
                                      name="ragione_sociale"
                                      v-model="customer.ragione_sociale">
                            </el-input>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12 col-md-4">
                            <label>Codice ateco</label>
                            <el-input type="text"
                                      label="Codice ateco"
                                      name="codice_ateco"
                                      v-model="customer.codice_ateco">
                            </el-input>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>PEC</label>
                            <el-input type="text"
                                      label="PEC"
                                      name="pec"
                                      v-model="customer.pec">
                            </el-input>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>SDI</label>
                            <el-input type="text"
                                      label="SDI"
                                      name="sdi"
                                      v-model="customer.sdi">
                            </el-input>
                        </div>
                    </div>
                </div>

            </el-form>
            <div class="row mt-4">
                <div class="col-12">
                    <el-button type="primary" class="float-right" @click.prevent="validateForm">
                        Salva
                    </el-button>
                </div>
            </div>
        </div>


        <hr/>

        <div>
            <h4>Residenza</h4>

            <div class="row">
                <div class="col-12 col-md-8">
                    <label>Indirizzo residenza</label>
                    <el-input type="text"
                              label="Indirizzo residenza"
                              name="indirizzo_residenza"
                              v-model="customer.indirizzo_residenza">
                    </el-input>
                </div>

                <div class="col-12 col-md-2">
                    <label>Civico</label>
                    <el-input type="text"
                              label="Civico"
                              name="numero_civico"
                              v-model="customer.numero_civico">
                    </el-input>
                </div>

                <div class="col-12 col-md-2">
                    <label>CAP</label>
                    <el-input type="text"
                              label="CAP"
                              name="cap"
                              v-model="customer.cap">
                    </el-input>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 col-md-4">
                    <label>Comune</label>
                    <el-input type="text"
                              label="Comune"
                              name="comune"
                              v-model="customer.comune">
                    </el-input>
                </div>

                <div class="col-12 col-md-4">
                    <label>Provincia</label>
                    <province-select v-model="customer.provincia"></province-select>
                </div>

                <div class="col-12 col-md-4">
                    <label>Nazione</label>
                    <el-input type="text"
                              label="Nazione"
                              name="nazione"
                              v-model="customer.nazione">
                    </el-input>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <el-button type="primary" class="float-right" @click.prevent="validateForm">
                        Salva
                    </el-button>
                </div>
            </div>
        </div>


        <hr/>

        <div>
            <h4>Documento</h4>
            <div class="row">
                <div class="col-12 col-md-3">
                    <label>Tipo documento</label>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="customer.tipo_documento"
                               placeholder="Seleziona...">
                        <el-option
                            v-for="item in tipoDocumento"
                            class="select-default"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                            name="tipo_documento">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-12 col-md-3">
                    <label>Numero documento</label>
                    <el-input type="text"
                              label="Numero documento"
                              name="numero_documento"
                              v-model="customer.numero_documento">
                    </el-input>
                </div>

                <div class="col-12 col-md-3 mb-3">
                    <label>Rilasciato il</label>
                    <br/>
                    <el-date-picker
                        v-model="customer.data_rilascio_documento"
                        type="date"
                        placeholder="Seleziona..."
                        label="Rilasciato il"
                        :picker-options="datePickerOptions"
                        format="DD-MM-YYYY"
                        value-format="YYYY-MM-DD"
                        language="it">
                    </el-date-picker>
                </div>

                <div class="col-12 col-md-3 mb-3">
                    <label>Scadenza</label>
                    <br/>
                    <el-date-picker
                        v-model="customer.data_scadenza_documento"
                        type="date"
                        placeholder="Seleziona..."
                        label="Scadenza"
                        :picker-options="datePickerOptions"
                        format="DD-MM-YYYY"
                        value-format="YYYY-MM-DD"
                        language="it">
                    </el-date-picker>
                </div>
            </div>
            <div class="row mt-2">

                <div class="col-12 col-md-3">
                    <label>Ente di rilascio</label>
                    <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                               v-model="customer.rilascio_documento" placeholder="Seleziona...">
                        <el-option
                            v-for="item in rilascioDocumento"
                            class="select-default"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                            name="rilascio_documento">
                        </el-option>
                    </el-select>
                </div>

                <div class="col-12 col-md-3">
                    <label>Comune di rilascio</label>
                    <el-input type="text"
                              label="Comune di rilascio"
                              name="comune_rilascio_documento"
                              v-model="customer.comune_rilascio_documento">
                    </el-input>
                </div>

                <div class="col-12 col-md-3">
                    <label>Provincia</label>
                    <province-select v-model="customer.provincia_documento"></province-select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <el-button type="primary" class="float-right" @click.prevent="validateForm">
                        Salva
                    </el-button>
                </div>
            </div>
        </div>

        <hr/>

        <div v-if="order">
            <h4>Ordini in comune</h4>
            <div class="row mb-5">
                <div class="col-12">
                    <p v-if="commonOrders.length < 1">Non ci sono ordini in comune.</p>
                    <p v-else-if="commonOrders.length === 1 && commonOrders[0].id === order.id">Non ci sono ordini in
                        comune.</p>
                    <ul v-else>
                        <li v-for="commonOrder in commonOrders">
                            <div v-if="commonOrder.id !== order.id">
                                <router-link :to="{path:'/ordini/lista/modifica/' + commonOrder.id}"><strong>{{ commonOrder.id }}</strong>
                                </router-link>
                                di <span
                                v-if="commonOrder.customer"><strong>{{ commonOrder.customer.first_name }} {{
                                    commonOrder.customer.last_name
                                }}</strong></span>
                                in
                                <span v-if="commonOrder.status"><strong>{{ commonOrder.status.internal_name }}</strong></span>
                                (<span
                                v-if="commonOrder.customer.email === order.customer.email && order.customer.email !== ''">eMail</span><span
                                v-if="commonOrder.customer.codice_fiscale === order.customer.codice_fiscale && order.customer.codice_fiscale !== ''">, CF</span>)
                                <span v-if="commonOrder.type_details"> - (<strong>{{ commonOrder.type_details.name }}</strong>)</span>
                            </div>
                            <div v-else>
                                <strong>{{ commonOrder.id }}</strong> - Ordine attuale
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from '@/components'
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";

export default {
    components: {
        ProvinceSelect,
        LSwitch,
        LCheckbox
    },
    props: {
        order: {
            default: null
        }
    },
    data() {
        return {
            customer: {},
            componentSettings: {
                myComponentName: 'OrderCustomerDetails'
            },
            currentOrder_id: null,
            loading: false,
            isVisible: false,
            smsAlternativo: false,
            types: [],
            modelValidations: {
                type: {
                    required: true
                },
                email: {
                    required: true
                }
            },
            sesso: [
                {code: 'm', name: 'Maschile'},
                {code: 'f', name: 'Femminile'}
            ],
            nazionalita: [
                {code: 1, name: 'Italiana'},
                {code: 0, name: 'Altro'}
            ],
            datePickerOptions: {},
            tipoDocumento: [
                {code: '0', name: 'Carta di identià'},
                {code: '1', name: 'Patente'},
                {code: '2', name: 'Passaporto'}
            ],
            rilascioDocumento: [
                {code: 1, name: 'Comune'},
                {code: 2, name: 'Motorizzazione civile'},
                {code: 4, name: 'Prefettura'},
                {code: 3, name: 'Altro'}
            ],
            formaGiuridica: [
                {code: 0, name: 'S.r.l.'},
                {code: 1, name: 'S.n.c.'},
                {code: 2, name: 'S.a.s.'},
                {code: 3, name: 'S.s.'},
                {code: 4, name: 'S.p.a.'},
                {code: 5, name: 'S.a.p.a.'},
                {code: 6, name: 'Società cooperativa'},
                {code: 7, name: 'S.r.l. Unipersonale'},
                {code: 8, name: 'S.r.l.s'},
                {code: 9, name: 'Altro'},
                {code: 10, name: 'Associazione'},
                {code: 11, name: 'Consorzio'},
            ],
            tipoImpresa: [
                {code: 'microimpresa', name: 'Microimpresa'},
                {code: 'piccola_impresa', name: 'Piccola impresa'},
                {code: 'media_impresa', name: 'Media impresa'},
            ],
            commonOrders: [],
            commonOrdersChecked: false,
            tsCodeLoading: false
        }
    },
    watch: {
        'order': function () {
            if (!this.order.id) {
                return
            }
            this.customer = this.order.customer
            this.visibilityChanged(true);
        }
    },
    computed: {},
    methods: {
        visibilityChanged(isVisible, entry) {
            if (this.order && this.order.id && this.order.id !== this.currentOrder_id) {
                this.commonOrdersChecked = false
                this.commonOrders = [];
            }
            this.isVisible = isVisible
            if (isVisible && !this.commonOrdersChecked) {
                this.currentOrder_id = this.order.id
                this.loadCommonOrders()
            }
        },
        checkCF() {
            this.loading = true
            this.$store.dispatch('shippings/checkCF', this.order).then((response) => {
                this.loading = false
                this.showGenericSuccess('Codice fiscale', 'valido')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il controllo sul codice fiscale')
            })
        },
        loadResources() {
            this.loadTypes()
        },
        loadTypes() {
            this.loading = true
            this.$store.dispatch('customers/getTypes').then((response) => {
                this.loading = false
                this.types = response
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle tipologie cliente')
            })
        },
        checkTsCode() {
            this.tsCodeLoading = true
            this.$store.dispatch('customers/checkTsCode', this.customer).then((response) => {
                this.tsCodeLoading = false
                //this.$emit('update', response.data.data)
                this.customer = response.data.data;
                this.showSuccessMessage("Codice TS aggiornato correttamente.")
            }).catch(e => {
                this.tsCodeLoading = false
                this.showErrorMessage("Errore durante il controllo del codice TS.<br><code>" + e.response.data.error_message + "</code>")
            })
        },
        validateForm() {
            let modifiedOrder = {
                id: this.order.id,
                tipo_riconoscimento: this.order.tipo_riconoscimento,
                riconoscimento_cliente: this.order.riconoscimento_cliente
            }
            this.$emit('update', this.customer, modifiedOrder)
        },
        loadCommonOrders() {
            let params = {}
            if (this.order.customer.email) {
                params = Object.assign({}, {
                    search: this.order.customer.email,
                    search_fields: ['email'],
                    search_type: 1
                })
            } else if (this.order.customer.codice_fiscale) {
                params = Object.assign({}, {
                    search: this.order.customer.codice_fiscale,
                    search_fields: ['codice_fiscale'],
                    search_type: 1
                })
            }
            if (params.search) {
                this.$store.dispatch('orders/list', params).then((response) => {
                    this.loading = false
                    this.commonOrders = response.data.orders.data
                    //console.log(this.commonOrders);
                    this.commonOrdersChecked = true
                }).catch(e => {
                    this.loading = false
                    this.showLoadingError(e)
                })
            }
        }
    },
    created: function () {
        this.currentOrder_id = this.order.id
        this.customer = this.order.customer
        this.loadResources()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style>
.fixed-button {
    position: fixed;
    float: right;
    z-index: 3;
    width: 100%;
    bottom: 112px;
    right: 60px;
}
</style>
