<template>
  <div>
    <el-select v-loading="loading"
               class="select-default w-100"
               v-model=selected
               placeholder="Seleziona la forma giuridica"
               size="large"
               @change="updateValue($event)"
               value-key="name">
      <el-option
        v-for="(item, key) in items"
        class="select-default"
        :key=key
        :label="item.name"
        :value=item.code>
      </el-option>
    </el-select>
  </div>
</template>

<script>


export default {
  name: "giuridic-form-select",
  components: {
  },
  data() {
    return {
      loading: false,
      index: null,
      items: [
        {code: '9', name: 'Altro'},
        {code: '10', name: 'Associazione'},
        {code: '11', name: 'Consorzio'},
        {code: '0', name: 'S.r.l.'},
        {code: '1', name: 'S.n.c.'},
        {code: '2', name: 'S.a.s.'},
        {code: '3', name: 'S.s.'},
        {code: '4', name: 'S.p.a.'},
        {code: '5', name: 'S.a.p.a.'},
        {code: '6', name: 'Società cooperativa'},
        {code: '7', name: 'S.r.l. Unipersonale'},
        {code: '8', name: 'S.r.l.s'}
      ],
      selected: this.value
    }
  },
  props: {
    value: {
      type: String
    },
  },
  methods: {
    updateValue: function (item) {
      this.selected = item
      this.$emit('input', item)
    }
  },
  watch: {
    value (val){
      this.selected = val
    }
  }
}
</script>

<style scoped>

</style>
