<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Gestione Guasti">

        <div class="table-layout-container">

          <div class="row mb-4">
            <div class="col-12 col-md-12 col-lg-12 col-xl-3 mx-0 mb-3">
              <router-link :to="{path:'/clienti-attivi/assurance/nuovo-guasto'}">
                <button class="btn btn-default btn-outline col-12" style="max-width: 300px;">
                <span class="btn-label">
                   <i class="fa fa-plus"></i> Nuovo guasto
                </span>
                </button>
              </router-link>
            </div>

            <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
              <el-select style="width: 100%; max-width: 300px;" v-model="currentState.typeFilter"
                         placeholder="Tipologia" @change="handleTypeChange">
                <el-option :key=0 label="Tipo: Tutti" :value=0></el-option>
                <el-option class="select-default" v-for="item in componentSettings.typeOptions" :key="item.code"
                           :label="item.name" :value="item.code"></el-option>
              </el-select>
            </div>

          </div>

          <div class="row">
            <div class="col-12">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage"
                            :per-page="currentState.pagination.perPage" :total="currentState.pagination.total"
                            @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 col-12">
              <div class="row">
                <div class="mb-3 col-12 col-sm-12">
                  <el-select class="select-default" style="width: 100%" v-model="currentState.pagination.perPage"
                             placeholder="Per pagina" @change="handlePerPageChange">
                    <el-option class="select-default" v-for="item in componentSettings.pagination.perPageOptions"
                               :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="col-sm-10 col-12">
              <div class="row">
                <div class="col-sm-3 col-12 mb-3">
                  <el-select multiple class="select-default" style="width: 100%"
                             v-model="currentState.searchFields.selected" placeholder="Dove cerco?"
                             @change="handleSearchFieldsChange">
                    <el-option v-for="(option, index) in searchFields" class="select-default"
                               :value="option.searchFields" :label="option.label" :key="index">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-3 col-12 mb-3">
                  <el-select class="select-default" style="width: 100%" v-model="currentState.searchTypeSelected"
                             placeholder="Come cercare?" @change="handleSearchTypeChange">
                    <el-option class="select-default" v-for="(item, index) in searchTypeOptions" :key="index"
                               :label="item.label" :value="index"></el-option>
                  </el-select>
                </div>

                <div class="col-12 col-sm-4">
                  <el-input style="width: 100%" type="search" placeholder="Cerca" v-model="currentState.searchQuery"
                            @change="handleSearchChange" addon-right-icon="fa fa-search"/>
                </div>

                <div class="col-sm-2 col-12 mb-3 no-padding-left-desktop">
                  <button class="btn btn-outline btn-danger btn-outline col-12" style="padding: 8px 0px; width: 100%"
                          @click="resetFilters()" >
                          <span class="btn-label">
                             <i class="fa fa-times"></i>
                          </span>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div class="row">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped" :data="tableData" border style="width: 100%"
                        :default-sort="currentState.currentSorting" @sort-change="handleSortChange"
                        @selection-change="handleSelectionChange">


                <template v-for="column in tableColumns">
                  <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type"
                                   :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)"
                                   :prop="column.prop" :sortable="column.sortable"
                                   :label="column.label"></el-table-column>

                  <el-table-column v-else-if="column.type === 'selection'" type="selection"></el-table-column>

                  <el-table-column v-else-if="column.custom === 'id'" :type="column.type" :fixed="column.fixed"
                                   :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                   :label="column.label" :width="100">
                    <template #default="props">
                      <div v-if="props.row">
                        <span v-if="props.row.id">{{ props.row.id }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'infrastructure_type'" :type="column.type" :fixed="column.fixed"
                                   :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                   :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <div class="d-flex flex-column">
                          <span>{{ props.row.order_type_formatted }}</span>
                          <span class="td-small">{{ props.row.customer_type_formatted }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>


                  <el-table-column v-else-if="column.custom === 'name'" :type="column.type" :fixed="column.fixed"
                                   :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                   :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <span class="td-strong">
                          <router-link class="dark-link" :to="{path:'/offerte/lista/modifica/' + props.row.id}">
                            <strong>{{props.row.internal_name}}</strong>
                          </router-link>
                        </span>
                        <small class="td-small"><br/>Test: <strong>TEST</strong></small>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'azioni'" :width="150" :type="column.type" :fixed="column.fixed"
                                   :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                   :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">
                        <router-link :to="{path:'/clienti-attivi/guasti/modifica/' + props.row.id}">
                          <a class="btn-warning btn-simple btn-link mb-3"
                             v-if="canEdit(props.$index, props.row)"><i class="fa fa-edit"></i></a>
                        </router-link>

                        <a class="btn-danger btn-simple btn-link mb-3"
                           @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i
                          class="fa fa-times"></i></a>

                        <i class="fa fa-ban"
                           v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)"></i>

                      </div>
                    </template>
                  </el-table-column>
                </template>

              </el-table>
            </div>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da
                <strong>{{ currentState.pagination.from }}</strong> a <strong>{{ currentState.pagination.to }}</strong>
                su <strong>{{ currentState.pagination.total }}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'

export default {
  components: {
    LPagination,
    Breadcrumb,
    BreadcrumbItem,
  },
  computed: {
    csvImportData: function () {
      return this.$store.getters.apiCommon
    },
    csvImportAction: function () {
      return this.$config.endpoints.offers.base + 'import'
    }
  },
  data() {
    return {
      componentSettings: {
        singleFieldSearch: true,
        searchTypeOption: 0,
        tableSort: {prop: 'id', order: 'descending'},
        myComponentName: 'Assurance',
        minimumPermissionLevel: 100,
        pagination: {
          perPageOptions: [5, 10, 25, 50, 100]
        },
        searchFieldsDefault: ['internal_name'],
        typeOptions: []
      },
      currentState: {
        currentSorting: {prop: 'id', order: 'descending'},
        searchFields: {
          selected: ['internal_name'],
          lastSelection: ['internal_name']
        },
        pagination: {
          perPage: 25,
          currentPage: 1,
          lastLoadedPage: 1,
          total: 0,
          from: 0,
          to: 0,
          showedItems: 0
        },
        typeFilter: 0,
        searchQuery: '',
        searchTypeSelected: 0,
        tableMultipleSelect: null
      },
      searchFields: [
        {searchFields: 'internal_name', label: 'Nome'},
        {searchFields: 'id', label: 'ID'}
      ],
      searchTypeOptions: [
        {searchType: '0', label: 'Contiene'},
        {searchType: '2', label: 'Inizia per'},
        {searchType: '1', label: 'È uguale a'}
      ],
      tableColumns: [
        {
          prop: 'id',
          label: 'ID',
          percentWidth: 8,
          sortable: 'custom',
          showTooltip: true,
          fixed: true,
          type: 'default',
          first: true,
          custom: 'id'
        },
        {
          prop: 'name',
          label: 'Nome',
          percentWidth: 30,
          sortable: 'custom',
          showTooltip: true,
          fixed: false,
          type: 'default',
          custom: 'name'
        },
        {
          prop: 'infrastructure_type',
          label: 'Tipo Infrastruttura',
          percentWidth: 16,
          sortable: 'custom',
          showTooltip: true,
          fixed: false,
          type: 'default',
          custom: 'infrastructure_type'
        },
        {
          label: 'Azioni',
          percentWidth: 13,
          sortable: false,
          showTooltip: false,
          fixed: false,
          type: 'default',
          custom: 'azioni'
        }
      ],
      loading: false,
      tableData: [],
      canShowView: false
    }
  },
  methods: {
    getSaveStateConfig() {
      return {
        'cacheKey': this.componentSettings.myComponentName,
        'saveProperties': ['currentState']
      }
    },
    canDelete(index, row) {
      let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
      if (indexToFind >= 0) {
        let elementToCheck = this.tableData[indexToFind]
        return this.$store.getters['assurance/canDelete'](elementToCheck)
      }
    },
    canEdit(index, row) {
      let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
      if (indexToFind >= 0) {
        let elementToCheck = this.tableData[indexToFind]
        return this.$store.getters['assurance/canEdit'](elementToCheck)
      }
    },
    handleDelete(index, row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
      if (indexToDelete >= 0) {
        let elementToDelete = this.tableData[indexToDelete]
        this.askDeleteMessage('questo guasto ').then((result) => {
          if (!result.value) return
          this.$store.dispatch('assurance/delete', elementToDelete).then(() => {
            this.showGenericSuccess('Guasto', 'eliminato con successo').then(() => {
              if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                this.currentState.pagination.currentPage--
              }
              this.updateData()
            })
          })
            .catch(e => {
              this.loading = false
              this.showLoadingError(e)
            })
        }).catch(() => {
          //todo if not confirm
        })
      }
    },
    handlePageChange(page) {
      if (page === this.currentState.pagination.lastLoadedPage) return
      this.updateData()
    },
    handlePerPageChange(perPage) {
      this.updateData({page: 1, limit: perPage})
    },
    handleSearchChange() {
      this.currentState.pagination.currentPage = 1
      this.updateData()
    },
    handleSearchFieldsChange(pIndex) {
      if (this.currentState.searchFields.selected.length < 1) {
        this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
        return
      }
      if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
        this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
      }
      this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
      this.currentState.pagination.currentPage = 1
      this.updateData()
    },
    handleSearchTypeChange(pIndex) {
      if (this.currentState.searchQuery === '') return
      this.currentState.pagination.currentPage = 1
      this.updateData()
    },
    handleSelectionChange(pSelection) {
      this.currentState.tableMultipleSelect = pSelection
    },
    handleSortChange(pSortType) {
      let cSort = this.findColumnSort(pSortType)
      if (pSortType.prop === null) {
        this.currentState.currentSorting = this.componentSettings.tableSort
      } else {
        if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
          return
        }
        this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
      }
      this.updateData()
    },
    handleTypeChange(newType) {
      this.resetFilters(false)
      this.currentState.typeFilter = newType
      this.updateData()
    },
    resetFilters(pReload = true) {
      this.currentState.searchQuery = ''
      this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
      this.currentState.tableMultipleSelect = null
      this.currentState.searchTypeSelected = 0
      this.currentState.pagination.perPage = 25
      this.currentState.pagination.currentPage = 1
      this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
      if (pReload) this.updateData()
    },
    updateData(params) {
      this.loading = true
      if (!params) params = {}
      params = Object.assign(params, this.$store.getters.apiCommon, {
        page: this.currentState.pagination.currentPage,
        limit: this.currentState.pagination.perPage,
        company: true
      })

      if (this.currentState.searchQuery !== '') {
        params = Object.assign(params, {
          search: this.currentState.searchQuery,
          search_fields: this.currentState.searchFields.selected,
          search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType
        })
      }
      if (this.currentState.currentSorting !== null) {
        params = Object.assign(params, {
          orderby: this.currentState.currentSorting.prop,
          order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)
        })
      }
      if (this.currentState.typeFilter > 0) {
        params = Object.assign(params, {order_type: this.currentState.typeFilter})
      }
      this.$store.dispatch('assurance/listPaginated', params).then((response) => {
        this.loading = false
        this.tableData = response.data.data.data
        this.currentState.pagination.total = response.data.data.total
        this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
        this.currentState.pagination.showedItems = response.data.data.data.length
        this.currentState.pagination.from = response.data.data.from
        this.currentState.pagination.to = response.data.data.to
      }).catch(e => {
        this.loading = false
        this.showLoadingError(e)
      })
    }
  },
  created: function () {
    this.componentSettings.typeOptions = this.$store.getters['orders/types']
    if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
    this.canShowView = true
    this.updateData()
  },
  mounted: function () {
  },
  destroyed: function () {
    this.$off()
  }
}
</script>
<style>
</style>
