<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">

      <el-card title="Link utili">

        <div class="table-layout-container">
          <div class="row" v-loading="loading">

            <div class="col-12">
              <div class="row pb-0">
                <div class="col-12 col-md-4 col-lg-3 col-xl-3" style="padding-right: 6px !important; padding-left: 12px !important;">
                  <div class="row">
                    <div class="col-12">
                      <el-select v-loading="loading" v-model="currentType" style="width: 100%" class="select-default mb-0" filterable placeholder="Tipo link..." size="large" @change="handleLinkTypeChange">
                        <el-option
                              v-for="type in linkTypes"
                              class="select-default"
                              :key="type.id"
                              :label="type.label"
                              :value="type.id"
                              name="name">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>

                <div class="col-12 col-md-4 col-lg-3 col-xl-3 mt-1 mt-md-0 pb-0" style="padding-right: 6px !important; padding-left: 12px !important;">
                  <div class="row">
                    <div class="col-12">
                      <el-input v-model="search" placeholder="Il nome contiene..."></el-input>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row pb-0 mt-0 mb-0" v-if="this.myPermissionLevel <= 100">

                <div class="col-12 col-md-4 col-lg-3 col-xl-3" style="padding-right: 6px !important; padding-left: 12px !important;">
                  <div class="row">
                    <div class="col-12">
                      <router-link :to="{path:'/utility/link-utili/aggiungi'}">
                        <button class="btn btn-default btn-outline" style="width:100%;">
                          <span class="btn-label">
                            <i class="fa fa-plus"></i> Aggiungi link
                          </span>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">

                <div class="col-sm-12 col-12 pb-0 mb-0 pt-3">

                  <div class="row">
                    <div class="col-12">
                      <h4 class="text-center mt-2" style="font-weight: 400 !important;">
                        <strong class="text-center" v-if="currentType === 1">Link operativi</strong>
                        <strong class="text-center" v-if="currentType === 0">Link utili</strong>
                        <a href="#" data-tooltip="'Ricarica link'" @click.prevent="loadUsefulLinks" class="btn-simple btn-link a-link">
                          <i class="el-icon-refresh" style="font-size: 19px;"></i>
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-0 pt-0">
                <div class="col-12" v-loading="loading">
                  <el-table class="table-striped" :data="filteredLinks.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))" border style="width: 100%">

                    <template v-for="column in tableColumns">

                      <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                      <el-table-column v-else-if="column.custom === 'name'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                        <template #default="props">
                          <div v-if="props.row">
                            <a v-bind:href="props.row.link" target="_blank" style="color: #4F96E4;"><strong>{{props.row.name}}</strong></a>
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column v-else-if="column.custom === 'description'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                        <template #default="props">
                          <div v-if="props.row">
                            {{props.row.description}}
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column v-else-if="column.custom === 'username'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                        <template #default="props">
                          <div v-if="props.row">
                            {{props.row.username}}
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column v-else-if="column.custom === 'password'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                        <template #default="props">
                          <div v-if="props.row">
                            <strong>{{props.row.password}}</strong>
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column v-else-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                        <template #default="props">
                          <div style="text-align: center" class="table-actions" v-if="props.row && myPermissionLevel <= 100">
                            <router-link :to="{path:'/utility/link-utili/modifica/' + props.row.id}">
                              <a data-tooltip="'Modifica link'" class="btn-warning btn-simple btn-link" v-if="canEdit(props.$index, props.row)">
                                <i class="fa fa-edit"></i>
                              </a>
                            </router-link>

                            <a data-tooltip="'Elimina link'" class="btn-danger btn-simple btn-link" v-if="canDelete(props.$index, props.row)" @click.prevent="deleteLink(props.$index, props.row)">
                              <i class="fa fa-times"></i>
                            </a>
                            <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row) && !myPermissionLevel <= 100" data-tooltip="'Nessun permesso'"></i>
                          </div>

                        </template>
                      </el-table-column>
                    </template>
                  </el-table>
                </div>
              </div>

              <div class="clearfix"></div>
            </div>

          </div>
        </div>
      </el-card>

    </div>
  </div>
</template>

<script>
  import { Breadcrumb, BreadcrumbItem, Switch as LSwitch } from '../../../../components'
  import Swal from "sweetalert2";

  export default {
    components: {
      LSwitch,
      Breadcrumb,
      BreadcrumbItem
    },
    computed: {
    },
    data () {
      return {
        componentSettings: {
          minimumPermissionLevel: 200
        },
        loading: false,
        isLoading: false,
        canShowView: false,
        linkTypes: [{id: 0, label: 'Link utili'}, {id: 1, label: 'Link operativi'}],
        currentType: 1,
        useful_links: [],
        filteredLinks: [],
        tableColumns: [
          {
            prop: 'name',
            label: 'Link',
            first: true,
            percentWidth: 22,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'name'
          },
          {
            prop: 'description',
            label: 'Descrizione',
            percentWidth: 28,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'description'
          },
          {
            prop: 'username',
            label: 'Nome utente',
            percentWidth: 20,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'username'
          },
          {
            prop: 'password',
            label: 'Password',
            percentWidth: 20,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'password'
          },
          {
            label: 'Azioni',
            percentWidth: 10,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'azioni'
          }
        ],
        search: ''
      }
    },
    methods: {
      canDelete (index, row) {
        let indexToFind = this.useful_links.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.useful_links[indexToFind]
          return this.$store.getters['usefulLinks/canDelete'](elementToCheck)
        }
      },
      canEdit (index, row) {
        let indexToFind = this.useful_links.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.useful_links[indexToFind]
          return this.$store.getters['usefulLinks/canEdit'](elementToCheck)
        }
      },
      deleteLink (index, row) {
        let indexToDelete = this.filteredLinks.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          let linkToDelete = this.filteredLinks[indexToDelete]
          Swal.fire(this.deleteMessage(linkToDelete.name)).then((result) => {
            if (!result.value) return
            this.$store.dispatch('usefulLinks/delete', linkToDelete).then(() => {
              Swal.fire('Eliminato!',
                              'Il link è stato eliminato.',
                              'success'
                      ).then(() => {
                        this.loadUsefulLinks()
                      })
            })
                    .catch(e => {
                      this.loading = false
                      this.showLoadingError(e)
                    })
          })
        }
      },
      handleLinkTypeChange (newType) {
        this.currentType = newType
        this.filteredLinks = this.useful_links.filter(function (link) {
          return link.type === newType
        })
      },
      loadUsefulLinks () {
        this.loading = true
        this.$store.dispatch('usefulLinks/getUsefulLinks').then((response) => {
          this.useful_links = response.data.useful_links
          this.handleLinkTypeChange(this.currentType)
          this.loading = false
        }).catch((e) => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei link utili')
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.loadUsefulLinks()
    },
    mounted: function () {},
    destroyed: function () {}
  }
</script>

<style>

</style>
