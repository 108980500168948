<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Modifica Link">
                <div slot="header">
                    <h4 class="title mt-0">Modifica di <strong>{{useful_link_form.name}}</strong></h4>

                    <div class="row">
                        <div class="col-12 col-md-3">
                            <router-link :to="{path:'/utility/link-utili/'}">
                                <button class="btn btn-default btn-outline col-12">
                                    <span class="btn-label">
                                        <i class="fa fa-list"></i> Lista link utili </span>
                                </button>
                            </router-link>
                        </div>
                    </div>

                </div>
                <useful-link-form v-bind:useful_link_form="useful_link_form" @update="updateLink" @delete="deleteLink" v-loading="loading"></useful-link-form>
            </el-card>
        </div>
    </div>
</template>
<script>
import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
import UsefulLinkForm from './UsefulLinkForm.vue'
import Swal from "sweetalert2";

export default {
  components: {
    LPagination,
    Breadcrumb,
    BreadcrumbItem,
    UsefulLinkForm
  },
  computed: {},
  watch: {
    '$route.params.id': function () {
      this.updateData()
    }
  },
  data () {
    return {
      componentSettings: {
        myComponentName: 'EditUsefulLink',
        minimumPermissionLevel: 100,
        listPath: '/utility/link-utili/'
      },
      loading: false,
      canShowView: false,
      useful_link_form: {}
    }
  },
  methods: {
    deleteLink (pLink) {
      Swal.fire(this.deleteMessage(this.useful_link_form.name)).then((result) => {
        if (!result.value) return
        this.$store.dispatch('usefulLinks/delete', pLink).then(() => {
          Swal.fire('Eliminato!',
                    'Link eliminato!',
                    'success'
                ).then(() => {
                  this.$router.push(this.componentSettings.listPath)
                })
        }).catch((e) => {
          this.showLoadingError(e, 'l\'eliminazione del link', '', false)
        })
      })
    },
    updateLink (pElement, pRedirect = true) {
      this.loading = true
      this.$store.dispatch('usefulLinks/edit', pElement).then((response) => {
        this.loading = false
        this.useful_link_form = response.data.useful_link
        this.showGenericSuccess('Link')
        this.$router.push(this.componentSettings.listPath)
      }).catch(e => {
        this.loading = false
        this.showLoadingError(e, 'l\'aggiornamento dei dati di questo link', '', false)
      })
    },
    updateData () {
      this.loading = true
      this.$store.dispatch('usefulLinks/getUsefulLinkByID', this.$route.params.id).then((response) => {
        this.useful_link_form = response.data.useful_link
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.showLoadingError(e, 'il recupero dei dati di questo link')
      })
    }
  },
  created: function () {
    if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
    this.canShowView = true
    this.updateData()
  },
  destroyed: function () {
    this.$off()
  }
}
</script>
<style></style>
