<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Lista spedizioni">

        <div class="table-layout-container">

          <div class="row">
            <div class="col-12">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage" :per-page="currentState.pagination.perPage" :total="currentState.pagination.total" @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 col-12">
              <div class="row">
                <div class="mb-3 col-12 col-sm-12">
                  <el-select style="width: 100%" v-model="currentState.pagination.perPage" placeholder="Per pagina" @change="handlePerPageChange">
                    <el-option v-for="item in componentSettings.pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="col-sm-10 col-12">
              <div class="row float-right">
                <div class="col-sm-4 col-12 mb-3">
                  <el-select multiple style="width: 100%" v-model="currentState.searchFields.selected" placeholder="Dove cerco?" @change="handleSearchFieldsChange">
                    <el-option v-for="(option, index) in searchFields" :value="option.searchFields" :label="option.label" :key="index">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-4 col-12 mb-3">
                  <el-select style="width: 100%" v-model="currentState.searchTypeSelected" placeholder="Come cercare?" @change="handleSearchTypeChange">
                    <el-option v-for="(item, index) in searchTypeOptions" :key="index" :label="item.label" :value="index"></el-option>
                  </el-select>
                </div>

                <div class="col-12 col-sm-4">
                  <el-input style="width: 100%" type="search" placeholder="Cerca" v-model="currentState.searchQuery" @change="handleSearchChange" addon-right-icon="fa fa-search" />
                </div>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-5">
              <label>Filtra per corriere</label>
              <el-select style="width: 100%" class="select-default mb-3" v-model="currentState.currentCarrier" placeholder="Filtra per corriere" @change="handleCarrierChange" size="large">
                <el-option
                  class="select-default"
                  :key="defaultValue"
                  label="Tutti"
                  :value="defaultValue">
                </el-option>
                <el-option
                  v-for="item in shipping_carriers"
                  class="select-default"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select>
            </div>

            <div class="col-12 col-sm-5">
              <label>Filtra per stato</label>
              <el-select style="width: 100%" class="select-default mb-3" v-model="currentState.currentStatus" placeholder="Filtra per stato" @change="handleStatusChange" size="large">
                <el-option
                  class="select-default"
                  :key="defaultValue"
                  label="Tutti"
                  :value="defaultValue">
                </el-option>
                <el-option
                  v-for="item in shipping_statuses"
                  class="select-default"
                  :key="item.code"
                  :label="item.name + labelValue(item.count)"
                  :value="item.code">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-2 col-12 mb-sm-3 mb-5 no-padding-left-desktop">
              <label class=""></label>
              <button class="btn btn-outline btn-danger btn-outline col-12" style="padding: 8px 0px; width: 100%; margin-top: 6px;" @click="resetFilters()" data-tooltip="'Reset dei filtri'">
                <span class="btn-label">
                   <i class="fa fa-times"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="row">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped" :data="tableData" border style="width: 100%" :default-sort="currentState.currentSorting" @sort-change="handleSortChange" @selection-change="handleSelectionChange">


                <template v-for="column in tableColumns">
                  <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                  <el-table-column v-else-if="column.type === 'selection'" type="selection" :width="calculateTableWidth(column)"></el-table-column>

                  <el-table-column v-else-if="column.custom === 'id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <router-link class="dark-link" :to="{path:'/spedizioni/lista/modifica/' + props.row.id}">
                          <strong>{{props.row.id}}</strong>
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'order_id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <router-link :to="{path:'/ordini/lista/modifica/' + props.row.order_id}" data-tooltip="'Vai all\'ordine'"  class="font-weight-bold a-link">
                          <strong>{{props.row.order_id}}</strong>
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'track'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <router-link class="dark-link" :to="{path:'/spedizioni/lista/modifica/' + props.row.id}">
                          <strong v-if="props.row.track_nr">{{props.row.track_nr}}</strong>
                          <strong v-else>{{props.row.order_id}}</strong>
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'stato'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <span>{{props.row.current_state_details.name}}</span><br />
                        <span class="td-small" style="line-height: 1.1"><strong>{{props.row.current_carrier_state}}</strong></span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'last_update'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <span v-if="props.row">{{dateToItalian(props.row.last_update, true)}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column v-else-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">
                        <router-link :to="{path:'/spedizioni/lista/modifica/' + props.row.id}">
                          <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link" v-if="canEdit(props.$index, props.row)"><i
                            class="fa fa-edit"></i></a>
                        </router-link>

                        <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                           @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>

                        <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>
                      </div>
                    </template>
                  </el-table-column>
                </template>


              </el-table>
            </div>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da <strong>{{currentState.pagination.from}}</strong> a <strong>{{currentState.pagination.to}}</strong> su <strong>{{currentState.pagination.total}}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'

  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        if (!this.$route.params.id) return
        this.resetFilters(false)
        this.currentState.searchQuery = this.$route.params.id
        this.currentState.searchFields.selected = ['order_id']
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          searchTypeOption: 0,
          singleFieldSearch: true,
          tableSort: {prop: 'id', order: 'descending'},
          myComponentName: 'ShippingsList',
          minimumPermissionLevel: 200,
          pagination: {
            perPageOptions: [5, 10, 25, 50, 100]
          },
          searchFieldsDefault: ['id']
        },
        currentState: {
          currentSorting: {prop: 'id', order: 'descending'},
          currentCarrier: -1,
          currentStatus: 1,
          searchFields: {
            selected: ['id'],
            lastSelection: ['id']
          },
          pagination: {
            perPage: 25,
            currentPage: 1,
            lastLoadedPage: 1,
            total: 0,
            from: 0,
            to: 0,
            showedItems: 0
          },
          searchQuery: '',
          searchTypeSelected: 0,
          tableMultipleSelect: null
        },
        searchFields: [
          {searchFields: 'id', label: 'ID'},
          {searchFields: 'track_nr', label: 'Track'},
          {searchFields: 'order_id', label: 'ID Ordine'}
        ],
        searchTypeOptions: [
          {searchType: '0', label: 'Contiene'},
          {searchType: '2', label: 'Inizia per'},
          {searchType: '1', label: 'È uguale a'}
        ],
        tableColumns: [
          {
            prop: 'id',
            label: 'ID',
            percentWidth: 10,
            sortable: 'custom',
            showTooltip: true,
            fixed: true,
            type: 'default',
            first: true,
            custom: 'id'
          },
          {
            prop: 'order_id',
            label: 'Ordine',
            percentWidth: 12,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'order_id'
          },
          {
            prop: 'track_nr',
            label: 'Track',
            percentWidth: 12,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'track'
          },
          {
            prop: 'current_carrier_state',
            label: 'Stato',
            percentWidth: 30,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'stato'
          },
          {
            prop: 'last_update',
            label: 'Ultima modifica',
            percentWidth: 17,
            sortable: 'custom',
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'last_update'
          },
          {
            prop: 'carrier_type_details.name',
            sortby: 'carrier_type',
            label: 'Corriere',
            percentWidth: 10,
            sortable: 'custom',
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: false
          },
          {
            label: 'Azioni',
            percentWidth: 9,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'azioni'
          }
        ],
        loading: false,
        tableData: [],
        defaultValue: -1,
        shipping_carriers: [],
        shipping_statuses: [],
        canShowView: false
      }
    },
    methods: {
      getSaveStateConfig () {
        return {
          'cacheKey': this.componentSettings.myComponentName,
          'saveProperties': ['currentState']
        }
      },
      resetFilters (pReload = true) {
        this.currentState.searchQuery = ''
        this.currentState.currentCarrier = -1
        this.currentState.currentStatus = 1
        this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
        this.currentState.tableMultipleSelect = null
        this.currentState.searchTypeSelected = 0
        this.currentState.pagination.perPage = 25
        this.currentState.pagination.currentPage = 1
        this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
        this.clearSavedState()
        if (pReload) this.updateData()
      },
      handleSelectionChange (pSelection) {
        this.currentState.tableMultipleSelect = pSelection
      },
      handleSortChange (pSortType) {
        let cSort = this.findColumnSort(pSortType)
        if (pSortType.prop === null) {
          this.currentState.currentSorting = this.componentSettings.tableSort
        } else {
          if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
            return
          }
          this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
        }
        this.updateData()
      },
      handleSearchTypeChange (pIndex) {
        if (this.currentState.searchQuery === '') return
        this.currentState.pagination.currentPage = 1
        this.updateData()
      },
      handleSearchFieldsChange (pIndex) {
        if (this.currentState.searchFields.selected.length < 1) {
          this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
          return
        }
        if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
          this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
        }
        this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
        this.currentState.pagination.currentPage = 1
        this.updateData()
      },
      handleCarrierChange () {
        this.updateData()
      },
      handleStatusChange () {
        this.updateData()
      },
      removeUser (pUser) {
        return this.axios.post(this.$config.endpoints.users.base + pUser.id + '/delete', this.$store.getters.apiCommon)
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          let elementToDelete = this.tableData[indexToDelete]
          this.askDeleteMessage(' la spedizione ' + elementToDelete.track_nr).then((result) => {
            if (!result.value) return
            this.$store.dispatch('shippingLogs/delete', elementToDelete).then(() => {
              this.askGenericSuccess().then(() => {
                if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                  this.currentState.pagination.currentPage--
                }
                this.loadShippingStatuses()
                this.updateData()
              })
            })
              .catch(e => {
                this.loading = false
                this.showLoadingError(e)
              })
          })
        }
      },
      handlePerPageChange (perPage) {
        this.updateData({page: 1, limit: perPage})
      },
      handlePageChange (page) {
        if (page === this.currentState.pagination.lastLoadedPage) return
        this.updateData()
      },
      handleSearchChange () {
        this.currentState.pagination.currentPage = 1
        this.updateData()
      },
      labelValue (pCount) {
        if (pCount !== null) {
          return ' (' + pCount + ')'
        } return ''
      },
      updateData (params) {
        this.loading = true
        if (!params) params = {}
        params = Object.assign(params, {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage})

        if (this.currentState.currentSorting !== null) {
          params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
        }
        if (this.currentState.searchQuery !== '') {
          params = Object.assign(params, {search: this.currentState.searchQuery, search_fields: this.currentState.searchFields.selected, search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType})
        } else {
          if (this.currentState.currentCarrier >= 0) {
            params = Object.assign(params, {carrier_filter: this.currentState.currentCarrier})
          }
          if (this.currentState.currentStatus >= 0) {
            params = Object.assign(params, {status_filter: this.currentState.currentStatus})
          }
        }
        this.$store.dispatch('shippingLogs/list', params).then((response) => {
          this.loading = false
          this.tableData = response.data.shipping_logs.data
          this.currentState.pagination.total = response.data.shipping_logs.total
          this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
          this.currentState.pagination.showedItems = response.data.count
          this.currentState.pagination.from = response.data.shipping_logs.from
          this.currentState.pagination.to = response.data.shipping_logs.to
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e)
        })
      },
      loadShippingCarriers () {
        this.loading = true
        this.$store.dispatch('shippingLogs/getShippingCarriers').then((response) => {
          this.loading = false
          this.shipping_carriers = response.data.shipping_carriers
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento dei corrieri')
        })
      },
      loadShippingStatuses () {
        this.loading = true
        this.$store.dispatch('shippingLogs/getShippingStatuses').then((response) => {
          this.loading = false
          this.shipping_statuses = response.data.shipping_statuses
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento degli stati')
        })
      },
      canEdit (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['shippingLogs/canEdit'](elementToCheck)
        }
      },
      canDelete (index, row) {
        let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.tableData[indexToFind]
          return this.$store.getters['shippingLogs/canDelete'](elementToCheck)
        }
      },
      loadResources () {
        this.loadShippingCarriers()
        this.loadShippingStatuses()
      }
    },
    created: function () {
      this.loadResources()
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      if (this.$route.params.id) {
        this.resetFilters(false)
        this.currentState.searchQuery = this.$route.params.id
        this.currentState.searchFields.selected = ['order_id']
      }
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
</style>
