<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h4>Dettagli per attivazione</h4>
      </div>
    </div>

      <div class="row">
        <div class="col-12">
          <p v-if="!this.order.customer.ts_code">
            <strong>NB.: Codice cliente TS assente</strong>
          </p>
          <p v-if="this.order.type === 10 || this.order.type === 20">

            <span v-if="this.order.order_product.operation_type === 1">Tipo: <strong>Nuova attivazione</strong></span>
            <span v-else-if="this.order.order_product.operation_type === 2">Tipo: <strong>Migrazione dati: Inserire come migrazione con Codice Sessione (vedi sopra)</strong></span>
            <span v-else-if="this.order.order_product.operation_type === 3">Tipo: <strong>Migrazione voce: inserire come Nuova Attivazione</strong></span>
            <span v-else-if="this.order.order_product.operation_type === 4">Tipo: <strong>Migrazione voce+dati: Inserire come migrazione con Codice Sessione (vedi sopra)</strong></span>
            <span v-else>Tipo: <strong>Verifica sopra</strong></span>

            <br /><br />

            <span v-if="!this.order.customer.ts_code">Codice ordine operatore: <strong>DM_OR_{{this.order.id}}</strong></span>
            <span v-else>Codice ordine operatore: <strong>DM_{{this.order.customer.ts_code}}_{{this.order.id}}</strong></span>

            <br />
            Città: <strong>{{this.order.activation_address.city}} ({{this.order.activation_address.province}})</strong><br />
            Indirizzo: <strong>{{this.order.activation_address.address}}, {{this.order.activation_address.address_number}}</strong><br />

            Nome cliente: <strong>{{this.order.customer.first_name}}</strong><br />
            Cognome cliente: <strong>{{this.order.customer.last_name}}</strong><br />
            Nome+cognome cliente: <strong>{{this.order.customer.first_name}} {{this.order.customer.last_name}}</strong><br />
            Recapito 1: <strong>{{this.order.customer.mobile_phone_number}}</strong><br />
            Recapito 2: <strong>{{this.order.customer.telefono}}</strong><br />


            Data prevista attivazione:
            <span v-if="this.momentBusinessDays() && this.order.order_product.cluster === 'C_D'">
              <strong>{{this.momentBusinessDays().businessAdd(29).format('DD-MMM-YYYY')}}</strong> (+29gg lavorativi C&D)<br />
            </span>
            <span v-else-if="this.momentBusinessDays() && this.order.order_product.cluster === 'A_B'">
              <strong>{{this.momentBusinessDays().businessAdd(10).format('DD-MMM-YYYY')}}</strong> (+10gg lavorativi A&B)<br />
            </span>
            <span v-else>
              <strong>Errore durante il calcolo (cluster sconosciuto o libreria non inizializzata)</strong><br />
            </span>


            ID Building: <strong>{{this.order.order_product.building_id}}</strong><br />
            Cluster: <strong>{{this.order.order_product.cluster}}</strong><br />
            POP: <strong>{{this.order.order_product.pop}}</strong><br />
            Tipo ordine: <strong v-if="this.order.type === 20">FWA</strong> <strong v-else>FTTH</strong><br />

            Profilo: <strong v-if="this.order.type === 20">FWA: 210 (100/50) o 209 (30/15)</strong>
            <strong v-else>
              <span v-if="this.order.order_product.cluster === 'C_D'">FTTH C&D: 202 (1000/300) o 216 (1000/500)</span>
              <span v-else>FTTH A&B: 52 (1000/300) o 50 (1000/500 con BMG 77/77) o 77 (1000/500 con BMG inferiore)</span>
            </strong>
            <br />
            KIT: <strong>DIM_MI_001</strong><br/>

            C-VLAN: <strong v-if="this.order.type === 20"><a :href="'https://www.dimensione.com/private_tools/crea_utente_openfiber_cvlan.php?fwa&pop=' + this.order.order_product.pop" target="_blank">Apri gestione C-VLAN FWA di {{this.order.order_product.pop}}</a></strong>
            <strong v-else><a :href="'https://www.dimensione.com/private_tools/crea_utente_openfiber_cvlan.php?pop=' + this.order.order_product.pop" target="_blank">Apri gestione C-VLAN FTTH di {{this.order.order_product.pop}}</a></strong>
            <br />
          </p>

          <p v-else-if="this.order.type === 30 || this.order.type === 40">
            Comune: <strong>{{this.order.activation_address.city}} ({{this.order.activation_address.province}})</strong><br />
            Indirizzo: <strong>{{this.order.activation_address.address}}</strong><br />
            Numero Civico: <strong>{{this.order.activation_address.address_number}}</strong><br /><br />

            Banda: <strong>Profilo 200/30 per FTTC, 1000/300 per FTTH</strong><br />
            Tecnologia: <strong>VDSL per FTTC, GPON per FTTH</strong><br />
            Interfaccia: <strong>1cp (G993.2) per FTTC</strong><br />
            Durata: <strong>3 mesi</strong><br />

            <span v-if="this.order.order_product.operation_type === 1">Tipo: <strong>Nuova attivazione:</strong> Linea Naked non attiva</span>
            <span v-else-if="this.order.order_product.operation_type === 2">Tipo: <strong>Migrazione dati:</strong> Linea Naked attiva senza NP se diversa da TIM - altrimenti Linea Naked NON attiva per TIM</span>
            <span v-else-if="this.order.order_product.operation_type === 3">Tipo: <strong>Migrazione voce:</strong> Linea Naked NON attiva per TIM</span>
            <span v-else-if="this.order.order_product.operation_type === 4">Tipo: <strong>Migrazione voce+dati:</strong> Linea Naked NON attiva</span>
            <span v-else>Tipo: <strong>Verifica sopra</strong></span>
            <br /><br />

            <span v-if="!this.order.customer.ts_code">Protocollo cliente: <strong>DM_OR_{{this.order.id}}</strong></span>
            <span v-else>Protocollo cliente: <strong>DM_{{this.order.customer.ts_code}}_{{this.order.id}}</strong></span><br />

            Nome cliente: <strong>{{this.order.customer.first_name}}</strong><br />
            Cognome cliente: <strong>{{this.order.customer.last_name}}</strong><br />
            Nome+cognome cliente: <strong>{{this.order.customer.first_name}} {{this.order.customer.last_name}}</strong><br />

            Recapito 1: <strong>{{this.order.customer.mobile_phone_number}}</strong><br />
            Recapito 2: <strong>{{this.order.customer.telefono}}</strong><br />
            Email: <strong>fastweb_portal@dimensione.com</strong><br />

            Codice EGON: <strong>{{this.order.order_product.id_egon_civico}}</strong><br />
          </p>
          </div>
        </div>

  </div>
</template>

<script>

export default {
  name: "OrderProductWholesaleInfoSection",
  components: {},
  props: {
    order: {
      required: true
    }
  },
  created() {
    this.momentBusinessDays = require('moment-business-days-it');
    this.momentBusinessDays.updateLocale('it', {
      workingWeekdays: [1, 2, 3, 4, 5]
    });
  },
  data() {
    return {
      momentBusinessDays: null,
      loading: false
    }
  },
  methods: {
    copyThis(string) {
      this.$copyText(string).then((e) => {
        this.showGenericSuccess('Testo', 'copiato negli appunti', '500', 'Copiato!')
      }).catch(e => {
        this.showGenericError('Errore!', 'Errore durante la copia negli appunti, verifica il tuo browser.')
      })
    }
  }
}
</script>

<style scoped>

</style>
