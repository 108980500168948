<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model="selected"
                   placeholder="..."
                   @change="updateValue($event)"
                   value-key="id">
            <el-option
                v-for="(item, key) in items"
                class="select-default"
                :key=key
                :label="item.name"
                :value=item.id>
            </el-option>
        </el-select>
    </div>
</template>

<script>

export default {
    name: "telephone-operator-select",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            selected: this.modelValue
        }
    },
    props: {
        modelValue: {
            type: Number
        },
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems() {
            this.loading = true
            this.$store.dispatch('operators/list').then((response) => {
                this.loading = false
                this.items = response
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento degli operatori')
            })
        },
        updateValue: function (val) {
            this.$emit('update:modelValue', val)
        }
    }
}
</script>

<style scoped>

</style>
