<template>
  <div>
    <el-select v-loading="loading"
               class="select-default w-100"
               v-model="selected"
               placeholder="..."
               size="large"
               @change="updateValue($event)"
               value-key="code">
      <el-option
        v-for="(item, key) in items"
        class="select-default"
        :key=key
        :label="item.name"
        :value=item.code>
      </el-option>
    </el-select>
  </div>
</template>

<script>


export default {
  name: "activation-type-select",
  components: {
  },
  data() {
    return {
      loading: false,
      index: null,
      items: [],
      selected: this.value
    }
  },
  props: {
    value: {
      type: Number
    },
  },
  watch: {
    value(val) {
      this.selected = val
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      this.loading = true
      this.$store.dispatch('orders/orderProducts/getOperationTypes').then((response) => {
        this.loading = false
        this.items = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'il caricamento dei tipi di operazione')
      })
    },
    updateValue: function (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>
