<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h4 class="m-0 mr-2">Note</h4>
      <el-button-group>
        <el-button size="small" type="primary" icon="refresh" @click="refreshList"
                   data-tooltip="'Ricarica note'"/>
        <el-button size="small" type="primary" :icon="showForm ? 'minus' : 'plus'" @click="showForm = !showForm"/>
      </el-button-group>
    </div>

    <add-order-note-form :order-id="orderId" @added="refreshList(); showForm = false" v-if="showForm"/>
    <div class="mt-4">
      <order-notes-list :order-id="orderId" ref="orderNoteList"/>
    </div>
  </div>
</template>

<script>
import AddOrderNoteForm from "@/pages/Dashboard/Orders/Components/AddOrderNoteForm";
import OrderNotesList from "@/pages/Dashboard/Orders/Components/OrderNotesList";
export default {
  name: "OrderNoteSection",
  components: {OrderNotesList, AddOrderNoteForm},
  props: {
    orderId: {
      required: true
    }
  },
  data: function (){
    return {
      showForm: false
    }
  },
  methods: {
    refreshList() {
      this.$refs.orderNoteList.loadNotes()
    }
  }
}
</script>

<style scoped>

</style>
