<template>
    <div>
        <label>Filtra per olo code</label>
        <el-select class="select-default w-100"
                   v-model="currentValue"
                   filterable placeholder="Filtra per codice olo" @change="handleChange">
            <el-option class="select-default" :key=null label="---" :value=null></el-option>
            <el-option class="select-default"
                       v-for="(code, index) in codes"
                       :key=index
                       :label="getLabel(code,index)"
                       :value=code.original_cow>
            </el-option>
        </el-select>
    </div>
</template>

<script>
import axios from "axios";
import GlobalConfig from "@/globalConfig";

export default {
    name: "OloCodeDonatingSelect",
    data() {
        return {
            codes: null,
            loading: false,
            currentValue: this.value
        }
    },
    props: {
        value: {
            required: true
        }
    },
    methods: {
        getLabel(code,index){
            return code.operator+" - ("+code.original_cow+")"
        },
        fetchData() {
            this.loading = true

            let params = Object.assign({}, this.$store.getters.apiCommon)
            let url = GlobalConfig.endpoints.olo.getOloCow;
            axios.post(url, params)
                .then(async (response) => {
                    console.log(response.data.data)
                    this.codes = response.data.data;
                })
                .catch((e) => {
                    this.loading = false
                    this.showLoadingError(e)
                })
        },
        handleChange(newValue) {
            this.$emit('update:modelValue', newValue)
            this.$emit('change')
        },
    },
    created() {
        this.fetchData()
    }
}
</script>

<style scoped>

</style>
