<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi offerta">
        <offer-form v-bind:offer="offer" v-loading="loading"></offer-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import OfferForm from './OfferForm.vue'

  export default {
    components: {
      OfferForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {}
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'OfferAdd',
          minimumPermissionLevel: 100,
          listPath: '/offerte/lista'
        },
        loading: false,
        canShowView: false,
        offer: {
          tipo_servizio: 0,
          canone_mensile: '',
          obbligo_contrattuale: '0',
          sdd: false,
          cdc: false,
          enabled: true,
          options: []
        }
      }
    },
    methods: {
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
