import Config from '../../../globalConfig'
import axios from 'axios'

import emails from './emails.js'
import sms from './sms.js'

const notifications = {
  modules: {
    emails,
    sms
  },
  namespaced: true,
  state: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async getEmailById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.base + pId, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getSMSById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.sms.base + pId, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getVariables ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        var parameters = Object.assign({}, rootGetters.apiCommon)
        if (params.id) {
          parameters = Object.assign(parameters, {template_id: params.id})
        }
        if (params.notification_type) {
          parameters = Object.assign(parameters, {notification_type: params.notification_type})
        }
        axios.post(Config.endpoints.notifications.variables, parameters)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default notifications
