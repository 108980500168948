import Config from '../../globalConfig'
import axios from 'axios'

const products = {
  namespaced: true,
  state: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canDuplicate: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async toggleProperty ({commit, state, rootGetters}, {property, element}) {
      return new Promise((resolve, reject) => {
        var newValue = true
        if (element[property]) {
          newValue = false
        }
        var params = Object.assign({[property]: newValue}, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base + element.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async edit ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.products.base + pElement.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async duplicate ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.products.base + pElement.id + '/duplicate', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async addColor ({commit, state, rootGetters}, {colorId, productId}) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({color_id: colorId}, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base + productId + '/colors/add', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async deleteColor ({commit, state, rootGetters}, {colorId, productId}) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({color_id: colorId}, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base + productId + '/colors/delete', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async add ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.add, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getById ({commit, state, rootGetters}, pId) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({medias: true, colors: true, offers: true}, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base + pId, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getOffersForProductFilteredByOrderType ({commit, state, rootGetters}, pObject) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({media: true, colors: true, offers: true, offers_filter_id: pObject.offers_filter_id}, rootGetters.apiCommon)
        axios.post(Config.endpoints.products.base + pObject.id, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getCategories ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.products.categories, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getBrands ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.products.brands, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTypes ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.products.types, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default products
