<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Modifica tariffa">
                <div slot="header" class="mb-4">
                    <h4 class="title mb-0 mt-0">Modifica di <strong>{{ offer.internal_name }}</strong> <small>(ID:
                        {{ offer.id }})</small></h4>
                    <h4 v-if="offer.offerta_integrata" class="mt-0 mb-0" style="color: #E74155;">Offerta integrata</h4>

                    <h5 class="mt-3" v-if="!loading">Creato il: {{ dateToItalian(offer.created_at, true) }}</h5>
                    <p class="text-muted" v-if="!loading">Ultima modifica:
                        {{ dateToItalian(offer.updated_at, true) }}</p>

                </div>
                <offer-form v-bind:offer="offer" v-loading="loading"/>
            </el-card>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
import OfferForm from './OfferForm.vue'

export default {
    components: {
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
        OfferForm
    },
    computed: {},
    watch: {
        '$route.params.id': function (val) {
            if (!val) {
                return
            }
            this.updateData()
        }
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OfferEdit',
                minimumPermissionLevel: 100,
                listPath: '/offerte/lista'
            },
            loading: false,
            canShowView: false,
            offer: {}
        }
    },
    methods: {
        updateData() {
            this.loading = true
            this.$store.dispatch('offers/getById', this.$route.params.id).then((response) => {
                this.offer = response.data.data
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il recupero dei dati di questa offerta')
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.updateData()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
