<template>
    <div>
        <form>
            <div class="row" v-if="emailCampaign.executed">
                <div class="col-auto">
                    <div class="alert napalm-alert-danger m-0" role="alert">
                        <strong>La campagna non è più modificabile, è già entrata in coda di esecuzione il
                            {{ dateToItalian(emailCampaign.executed_at) }}, per essere inviata il
                            {{ dateToItalian(emailCampaign.start_date, true) }}</strong>
                    </div>
                </div>
            </div>
            <div class="row" v-if="scheduled">
                <div class="col-auto mr-auto">
                    <div class="alert napalm-alert-primary m-0" role="alert">
                        <strong>Questa campagna è programmata per essere inviata il
                            {{ dateToItalian(emailCampaign.start_date, true) }}</strong>
                    </div>
                </div>
                <div class="col-auto align-self-center" v-if="false">
                    <button class="btn btn-primary btn-outline mb-0">
                <span class="btn-label">
                   <i class="fa fa-play"></i> Invia Ora
                </span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <el-input type="text"
                              label="Nome"
                              v-model="emailCampaign.name"
                              name="name"
                              v-validate="modelValidations.name"
                              :error="getError('name')" :disabled="emailCampaign.executed">
                    </el-input>
                </div>
                <div class="col-12 col-md-6">
                    <label>Data di invio campagna</label>
                    <el-date-picker
                        label="Data Lancio"
                        v-model="emailCampaign.start_date"
                        type="datetime"
                        format="DD-MM-YYYY"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        language="it"
                        :disabled="emailCampaign.executed">
                    </el-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-5">
                    <label>Tipologia</label>
                    <br/>
                    <el-select filterable v-loading="loading" v-model="emailCampaign.template_id" style="width: 100%"
                               class="select-default mb-3" placeholder="Seleziona un template..." size="large">

                        <el-option
                            v-for="template in emailTemplates"
                            class="select-default"
                            :key="template.id"
                            :label="template.name"
                            :value="template.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Condizioni</label>
                    <el-table-draggable>
                        <el-table class="table-striped" style="width: 100%" :data="emailCampaign.query_conditions"
                                  border>
                            <el-table-column prop="condition" label="Condizione" width="180" align="center">
                                <template #default="scope">
                                    <el-select style="width: 100%" class="select-default mb-0"
                                               v-model="scope.row.condition"
                                               placeholder="..." :disabled="emailCampaign.executed">
                                        <el-option label="AND" value="and"></el-option>
                                        <el-option label="OR" value="or"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column prop="query" label="Query">
                                <template #default="scope">
                                    <el-input type="text"
                                              label=""
                                              v-model="scope.row.query"
                                              name="name"
                                              v-validate="modelValidations.name"
                                              :error="getError('name')" class="mb-0" :disabled="emailCampaign.executed">
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label=""
                                width="150"
                                align="center"
                                v-if="!emailCampaign.executed">
                                <template #default="scope">
                                    <el-button @click.prevent="removeCondition(scope.$index)" type="text" size="small">
                                        Cancella
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-table-draggable>
                </div>
            </div>
            <div class="row" v-if="!emailCampaign.executed">
                <div class="col-5">
                    <button class="btn btn-default btn-outline mb-3 mt-3" @click.prevent="addCondition">
            <span class="btn-label">
               <i class="fa fa-plus"></i> Aggiungi condizione
            </span>
                    </button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 text-center">

                    <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement"
                            v-if="emailCampaign.id">
                        Elimina Campagna
                    </button>

                    <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
                        Salva Campagna
                    </button>

                </div>
            </div>

            <div class="clearfix"></div>
        </form>

        <div class="card mt-4" v-if="emailCampaign.id">
            <div class="card-header">
                <h4 class="card-title" style="display: inline-block; margin-top: 3px; margin-bottom: 16px">Simulazione
                    <a
                        @click.prevent="closeSimulation" href="#"><i class="fa fa-times"
                                                                     v-if="simulationResult"></i></a></h4>
                <button class="btn btn-primary btn-outline float-right" @click.prevent="simulateCampaign">
                <span class="btn-label">
                   <i class="fa fa-check"></i> Simula
                </span>
                </button>
            </div>
            <div class="card-body" v-if="simulationResult">
                <div class="row">
                    <div class="col-12" style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)">
                        <h5>Email Template</h5>
                        <p>Verrà inviato il template {{ simulationResult.email_template.id }} |
                            {{ simulationResult.email_template.name }}</p>
                    </div>
                    <hr>
                    <div class="col-12 p-3" style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)">
                        <h5>Query</h5>
                        <code>{{ simulationResult.campaign_query }}</code>
                    </div>
                    <div class="col-12 p-3">
                        <h5>Clienti Notificati (primi 10 risultati)</h5>
                        <el-table
                            :data="simulationResult.notified_customers"
                            style="width: 100%">
                            <el-table-column
                                prop="id"
                                label="ID"
                                width="120">
                                <template #default="scope">
                                    <router-link class="dark-link"
                                                 :to="{path:'/productAlerts/lista/modifica/' + scope.row.id}"
                                                 target="_blank">
                                        {{ scope.row.id }}
                                    </router-link>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="email"
                                label="Email">
                            </el-table-column>
                            <el-table-column
                                prop="full_name"
                                label="Email">
                            </el-table-column>
                            <el-table-column
                                prop="landing_tag"
                                label="Landing Tag">
                            </el-table-column>
                            <el-table-column
                                prop="status_details.name"
                                label="Stato">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    props: {
        emailCampaign: {}
    },
    components: {
        'editor': Editor,
    },
    data() {
        return {
            loading: false,
            content: '',
            componentSettings: {
                listPath: '/email-campaign/lista'
            },
            simulationResult: null,
            simulationLoading: false,
            modelValidations: {
                name: {
                    required: true
                },
                start_date: {
                    required: true
                },
                query_conditions: {
                    required: true
                }
            },
            emailTemplates: []
        }
    },
    watch: {},
    computed: {
        scheduled: function () {
            if (!this.emailCampaign.executed && this.emailCampaign.start_date) {
                return true
            }
            return false
        }
    },
    methods: {
        deleteElement() {
            this.$emit('delete', this.emailCampaign)
        },
        validateForm() {
            this.$validator.validateAll().then(isValid => {
                this.$emit('update', this.emailCampaign)
            })
        },
        getError(fieldName) {
            return this.errors.first(fieldName)
        },
        addCondition() {
            this.emailCampaign.query_conditions.push({condition: 'and', query: ''})
        },
        removeCondition(index) {
            this.$delete(this.emailCampaign.query_conditions, index)
        },
        simulateCampaign() {
            this.simulationLoading = true
            this.$store.dispatch('emailCampaigns/simulate', this.emailCampaign).then((response) => {
                this.simulationResult = response.data.simulation_result
                this.simulationLoading = false
            }).catch(e => {
                this.simulationLoading = false
                this.showLoadingError(e, 'il recupero dei dati della simulazione')
            })
        },
        closeSimulation() {
            this.simulationResult = null
        },
        loadTemplates() {
            this.loading = true
            let params = {type_filter: 5, force_reload: true, order_direction: 'asc', orderby: 'name'}
            this.$store.dispatch('notifications/emails/getTemplates', params).then((response) => {
                this.loading = false
                this.emailTemplates = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei template')
            })
        }
    },
    created() {
        this.loadTemplates()
    },
    destroyed() {
        this.$off()
    }
}
</script>
