<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi tariffa">
        <rate-form v-bind:rate="rate" v-loading="loading" @update="addOffer"></rate-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import RateForm from './RateForm.vue'

  export default {
    components: {
      RateForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {}
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'RateAdd',
          minimumPermissionLevel: 100,
          listPath: '/tariffe/lista'
        },
        loading: false,
        canShowView: false,
        rate: {
          nome: '',
          id_h3g: '',
          tipo_utenza: 0,
          type: 1,
          tipo_tariffazione: 2,
          tipo_fatturazione: 2,
          canone_mensile: '',
          minuti_inclusi: '',
          sms_inclusi: '',
          internet_incluso: '',
          lte: true,
          enabled: true
        }
      }
    },
    methods: {
      addOffer (pElement) {
        this.loading = true
        this.$store.dispatch('rates/add', pElement).then((response) => {
          this.loading = false
          this.$router.push('/tariffe/lista/modifica/' + response.data.rate.id)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questa tariffa', '', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
