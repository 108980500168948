<template>
    <div>
        <div>
            <el-button v-if="customer" @click.prevent="copyFromCustomer()" size="small">
                <i class="fa-regular fa-copy mr-2"></i>Copia informazioni dal cliente
            </el-button>
        </div>
        <el-form :model="npModel" ref="editNpForm" :rules="rules" v-loading="loading">
            <el-form-item label="ID" prop="id">
                <el-input v-model="npModel.id" disabled/>
            </el-form-item>
            <el-form-item label="Nome" prop="name">
                <el-input v-model="npModel.name"></el-input>
            </el-form-item>
            <el-form-item label="Cognome" prop="last_name">
                <el-input v-model="npModel.last_name"></el-input>
            </el-form-item>
            <el-form-item label="Operatore di provenienza" prop="original_operator">
                <telephone-operator-select v-model="npModel.original_operator" />
            </el-form-item>
            <el-form-item label="Numero da portare" prop="original_number">
                <el-input v-model="npModel.original_number"></el-input>
            </el-form-item>
            <el-form-item label="Codice migrazione" prop="migration_code">
                <el-input v-model="npModel.migration_code"></el-input>
            </el-form-item>
            <el-form-item label="Tipo linea" prop="line_type">
                <np-line-type-select v-model="npModel.line_type"></np-line-type-select>
            </el-form-item>
            <el-form-item label="Soggetto" prop="customer_type">
                <el-radio v-model="npModel.customer_type" label="privato" border>Privato</el-radio>
                <el-radio v-model="npModel.customer_type" label="ditta_individuale" border>Ditta Individuale</el-radio>
                <el-radio v-model="npModel.customer_type" label="azienda" border>Azienda</el-radio>
            </el-form-item>
            <el-form-item label="Codice fiscale" prop="cf">
                <el-input v-model="npModel.cf"></el-input>
            </el-form-item>
            <el-form-item v-if="npModel.customer_type === 'ditta_individuale' || npModel.customer_type === 'azienda'"
                          label="Nome azienda" prop="company_name">
                <el-input v-model="npModel.company_name"></el-input>
            </el-form-item>
            <el-form-item v-if="npModel.customer_type === 'ditta_individuale' || npModel.customer_type === 'azienda'"
                          label="Partita Iva" prop="vat_id">
                <el-input v-model="npModel.vat_id"></el-input>
            </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end">
            <el-button drag type="primary" @click="validate">Salva Portabilità</el-button>
        </div>
    </div>

</template>

<script>

import TelephoneOperatorSelect from "@/components/Inputs/TelephoneOperatorSelect";
import NpLineTypeSelect from "@/components/Inputs/NpLineTypeSelect";

export default {
    name: "EditNpForm",
    components: {
        TelephoneOperatorSelect, NpLineTypeSelect
    },
    props: {
        np: {
            required: true,
            type: Object
        },
        customer: {
            required: false,
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            npModel: this.np,
            rules: {
                name: [
                    {required: true, message: 'Il nome è obbligatorio', trigger: 'blur'}
                ],
                last_name: [
                    {required: true, message: 'Il cognome è obbligatorio', trigger: 'blur'}
                ],
                migration_code: [
                    {required: true, message: 'Il codice migrazione è obbligatorio', trigger: 'blur'}
                ],
                original_operator: [
                    {required: true, message: 'Selezionare un operatore di provenienza', trigger: 'blur'}
                ],
                original_number: [
                    {required: true, message: 'Il numero da portare è obbligatorio', trigger: 'blur'},
                    {min: 3, message: 'Il numero è troppo corto', trigger: 'blur'}
                ],
                customer_type: [
                    {required: true, message: 'Selezionare il tipo di soggetto', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        copyFromCustomer() {
            this.npModel.name = this.customer.first_name
            this.npModel.last_name = this.customer.last_name
            this.npModel.cf = this.customer.codice_fiscale
            this.npModel.customer_type = this.customer.type
            this.npModel.vat_id = this.customer.partita_iva
        },
        validate() {
            this.$refs['editNpForm'].validate((valid) => {
                if (valid) {
                    this.editNp();
                } else {
                    this.$message.error("Il form di creazione della NP non è compilato correttamente.")
                }
            });
        },
        editNp() {
            this.loading = true;
            this.$store.dispatch('mnp/edit', this.npModel).then((response) => {
                this.loading = false;
                this.npModel = response.data.mnp
                this.$emit('edit', this.npModel)
                this.showSuccessMessage("NP modificata correttamente")
            }).catch(e => {
                this.loading = false;
                this.showErrorMessage("Errore nella modifica della NP")
            })
        }
    }
}
</script>

<style scoped>

</style>
