import Config from '../../globalConfig'
import axios from 'axios'

const inbound = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async list ({commit, state, rootGetters}, params) {
      params = Object.assign({}, params, rootGetters.apiCommon)
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.inbound.base + 'calls', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default inbound
