<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Modifica MNP">
        <div slot="header">

          <div class="row mb-3">
            <div class="col-12 col-md-9">
              <h4 class="title mt-1 mb-0">Modifica NP <small>(ID: {{ mnp.id }})</small><span v-if="mnp.order_product"> - Ordine <router-link
                :to="{path:'/ordini/lista/modifica/' + mnp.order_product.order_id}"
                class="font-weight-bold a-link">#{{ mnp.order_product.order_id }} <i
                class="edit"></i></router-link></span></h4>
              <h5 v-if="!loading">Creata il: {{ dateToItalian(mnp.created_at, true) }}</h5>
              <p class="text-muted" v-if="!loading && mnp">
                Ultima modifica: {{ dateToItalian(mnp.updated_at, true) }}
              </p>
            </div>

            <div class="col-12 col-md-3 mt-0 mb-3 pt-0">
              <router-link :to="{path:'/np/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista NP
                </span>
                </button>
              </router-link>
            </div>
          </div>

          <div class="row mt-0 mb-0 mt-0 mb-2 mt-4">
            <div class="col-12 col-md-4 mb-0 mt-0">
              <label>MODIFICA STATO</label>
              <el-select id="selectMNPStatus" value-key="key" v-model="mnp.status"
                         class="col-12 select-default py-0 px-0" placeholder="Seleziona uno stato..." size="large">

                <el-option
                  v-for="item in mnp_statuses"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>

              </el-select>
            </div>
          </div>
        </div>

        <div>
          <form class="mt-0">

            <div class="row">
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Nome"
                          v-model="mnp.name"
                          name="name">
                </el-input>
              </div>
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Cognome"
                          v-model="mnp.last_name"
                          name="last_name">
                </el-input>
              </div>
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Codice fiscale"
                          v-model="mnp.cf"
                          name="cf"
                          class="fg-input-uppercase">
                </el-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <label>Tipo cliente</label>
                <el-select value-key="key" @change="" v-model="mnp.customer_type" v-loading="loading"
                           style="width: 100%" class="select-default mb-3" filterable placeholder="Seleziona"
                           size="large">

                  <el-option
                    v-for="item in customerTypes"
                    class="select-default"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key">
                  </el-option>
                </el-select>
              </div>
              <div class="col-12 col-md-4"
                   v-if="mnp.customer_type === 'azienda' || mnp.customer_type === 'ditta_individuale'">
                <el-input type="text"
                          label="Denominazione"
                          v-model="mnp.company_name"
                          name="company_name">
                </el-input>
              </div>
              <div class="col-12 col-md-4"
                   v-if="mnp.customer_type === 'azienda' || mnp.customer_type === 'ditta_individuale'">
                <el-input type="text"
                          label="Partita IVA"
                          v-model="mnp.vat_id"
                          name="vat_id">
                </el-input>
              </div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Numero da portare"
                          v-model="mnp.original_number"
                          name="name">
                </el-input>
              </div>
              <div class="col-12 col-md-4">
                <label>Seleziona un operatore</label>
                <telephone-operator-select v-model="mnp.original_operator"></telephone-operator-select>
              </div>
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Codice migrazione"
                          v-model="mnp.migration_code">
                </el-input>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 d-flex justify-content-end">
                <el-button icon="delete" @click="deleteElement" class="mr-2 text-danger">Elimina
                </el-button>
                <el-button type="primary" icon="check" @click="editNp">Salva</el-button>
              </div>
            </div>

            <div class="clearfix"></div>
          </form>
        </div>


      </el-card>
    </div>
  </div>
</template>
<style></style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'
import TelephoneOperatorSelect from "@/components/Inputs/TelephoneOperatorSelect";

export default {
  components: {
    TelephoneOperatorSelect,
    LSwitch,
    LCheckbox,
  },
  data() {
    return {
      componentSettings: {
        myComponentName: 'MNPEdit',
        minimumPermissionLevel: 200,
        listPath: '/np/lista'
      },
      content: '',
      loading: false,
      canShowView: false,
      mnp: {},
      mnp_statuses: null,
      simType: [
        {key: 0, label: 'Abbonamento'},
        {key: 1, label: 'Prepagato'}
      ],
      customerTypes: [
        {key: 'privato', label: 'Privato'},
        {key: 'ditta_individuale', label: 'Ditta individuale'},
        {key: 'azienda', label: 'Azienda'}
      ]
    }
  },
  watch: {
    '$route.params.id': function () {
      this.loadNPStatuses()
    }
  },
  computed: {},
  methods: {
    updateData() {
      this.loading = true
      this.$store.dispatch('mnp/getById', this.$route.params.id).then((response) => {
        this.mnp = response.data.data
        this.canShowView = true
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.showLoadingError(e, 'il recupero dei dati di questa MNP')
      })
    },
    editNp() {
      this.loading = true
      this.$store.dispatch('mnp/edit', this.mnp).then((response) => {
        this.loading = false
        this.showGenericSuccess('MNP', 'aggiornata con successo')
      }).catch(e => {
        this.loading = false
        this.showLoadingError(e, 'l\'aggiornamento dei dati di questa NP', '', false)
      })
    },
    deleteElement() {
      this.askDeleteMessage(' la NP ' + this.mnp.id).then((result) => {
        if (!result.value) return
        this.$store.dispatch('mnp/delete', this.mnp).then(() => {
          this.askGenericSuccess().then(() => {
            this.$router.push(this.componentSettings.listPath)
          })
        }).catch((e) => {
          this.showLoadingError(e, 'l\'eliminazione della NP', '', false)
        })
      })
    },
    loadNPStatuses() {
      this.loading = true
      this.$store.dispatch('mnp/loadNPStatuses').then((response) => {
        this.mnp_statuses = response.data.mnp_statuses
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'il caricamento degli stati np')
      })
    }
  },
  created: function () {
    if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
    this.loadNPStatuses()
    this.updateData()
  },
  mounted: function () {
  },
  destroyed: function () {
    this.$off()
  }
}

</script>
<style></style>
