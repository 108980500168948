<template>
    <div v-loading="loading">

        <div class="notes-section">

            <div v-if="order.type === 10 || order.type === 20" class="chronology-section mt-4">
                <open-fiber-chronology-details v-model="order" @reloaded="chronologyReloaded"/>
                <hr class="my-5">
                <open-fiber-chronology-old :order-id="orderid"/>
            </div>
            <div v-else-if="order.type === 70" class="chronology-section mt-4">
                <div class="d-flex align-items-center" v-loading="loading">
                    <h3 class="my-0 mr-2">FWA Eolo (in arrivo)</h3>
                </div>
            </div>
            <div v-else class="chronology-section mt-4">
                <fastweb-wholesale-details v-model="order" @reloaded="chronologyReloaded"/>
            </div>

        </div>
    </div>
</template>

<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'
import FastwebWholesaleDetails from "@/pages/Dashboard/Orders/Components/FastwebWholesaleDetails.vue";
import OpenFiberChronologyOld from "@/pages/Dashboard/Orders/Components/OpenFiberChronologyOld.vue";
import OpenFiberChronology from "@/pages/Dashboard/Orders/Components/OpenFiberChronology.vue";
import OpenFiberChronologyDetails from "@/pages/Dashboard/Orders/Components/OpenFiberChronologyDetails.vue";

export default {
    mixins: [],
    components: {
        OpenFiberChronologyDetails,
        OpenFiberChronologyOld,
        OpenFiberChronology,
        FastwebWholesaleDetails,
        LSwitch,
        LCheckbox
    },
    props: {
        modelValue: {},
        orderid: null
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderWholesaleDetails'
            },
            loading: false,
            notesLoading: false,
            loadedChronology: false
        }
    },
    computed: {
        order(){
            return this.modelValue
        }
    },
    methods: {
        chronologyReloaded() {
            this.$emit('reloaded')
        }
    },
    created: function () {
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
