<template>
  <div class="row" v-if="canShowView">

    <div v-if="isLoading" style="position: fixed; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.9); z-index: 1000; top: 0; left: 0;">
      <h3 style="text-align: center; font-weight: 200 !important;">Sto sincronizzando...</h3>
    </div>


    <div class="col-12">
      <el-card title="Manutenzione">

        <div class="row" v-loading="loading">
          <div class="col-12 mt-2 pl-4 pr-4">
            <div class="row">

              <h4 class="col-12 mt-0">
                Sincronizza conteggio ordini
                <a href="#" data-tooltip="'Sincronizza conteggio ordini'" @click.prevent="syncOrdersCount" class="btn-simple btn-link a-link"><i class="fa-regular fa-arrows-rotate"></i></a>
              </h4>

              <p class="col-12">Premendo il pulsante di refresh sincronizzerai il conteggio ordini risolvendo eventuali disallineamenti.</p>

              <div class="col-12 pt-3"><pre v-if="sync_counts_report">Risposta dell'operazione: <br/> {{sync_counts_report}}</pre></div>

            </div><!-- end of row -->
          </div>
        </div>

      </el-card>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Maintenance',
    components: {
    },
    computed: {},
    data () {
      return {
        componentMaintenance: {
          minimumPermissionLevel: 100
        },
        loading: false,
        sync_counts_report: null,
        canShowView: false,
        isLoading: false
      }
    },
    methods: {
      syncOrdersCount () {
        this.isLoading = true
        this.$store.dispatch('orders/syncOrdersCount').then((response) => {
          this.sync_counts_report = response.data.sync_counts_report
          this.isLoading = false
        }).catch((e) => {
          this.isLoading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei log')
        })
      }
    },
    created: function () {
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {}
  }
</script>

<style>

</style>
