<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Lista ordini">


                <div class="table-layout-container">

                    <div class="row">
                        <div class="col-12 col-md-4 col-lg-4 col-xl-3">
                            <router-link :to="{path:'/ordini/stati'}">
                                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Torna agli stati
                </span>
                                </button>
                            </router-link>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4 col-xl-3 float-right ml-auto">
                            <router-link :to="{path:'/ordini/aggiungi'}">
                                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="el-icon-plus"></i> Crea nuovo ordine
                </span>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="row">
                                <div class="mb-3 col-12 col-md-2 col-sm-12" style="max-width: 110px">
                                    <el-select style="width: 100%"
                                               v-model="currentState.pagination.perPage"
                                               placeholder="Per pagina" @change="handlePerPageChange">
                                        <el-option class="select-default"
                                                   v-for="item in componentSettings.pagination.perPageOptions"
                                                   :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </div>

                                <div class="mb-3 col-12 col-md-4 col-sm-12">
                                    <el-select style="width: 100%"
                                               v-model="currentState.typeFilter"
                                               placeholder="Tipologia" @change="handleTypeChange">
                                        <el-option :key=0 label="Tipo: Tutti"
                                                   :value=0></el-option>
                                        <el-option v-for="item in componentSettings.typeOptions"
                                                   :key="item.code"
                                                   :label="item.name" :value="item.code"></el-option>
                                    </el-select>
                                </div>

                                <div class="mb-0 mt-0 col-md-12 col-12 pr-0">
                                    <div></div>
                                    <l-pagination class="pagination-no-border float-right"
                                                  v-model="currentState.pagination.currentPage"
                                                  :per-page="currentState.pagination.perPage"
                                                  :total="currentState.pagination.total"
                                                  @input="handlePageChange"></l-pagination>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="text-center mb-1 mt-1">
                                        <strong>{{ getTypeNameById(this.currentState.typeFilter) }}</strong></h4>
                                    <div v-if="this.currentState.statusFilter" class="d-flex w-100 align-items-center justify-content-center">
                                        <h4 class="text-center m-0"
                                            style="font-weight: 400 !important;">
                                            Stato: {{
                                                getStatusInternalNameById(this.currentState.statusFilter)
                                            }}
                                        </h4>
                                        <a href="#" @click.prevent="updateData"
                                           class="btn-link a-link ml-2">
                                            <i class="fa-regular fa-arrows-rotate"></i>
                                        </a>

                                        <a href="#" v-if="currentState.statusFilter"
                                           @click.prevent="goToAllOrders" class="btn-link a-link ml-2">
                                            <i class="fa-regular fa-x"></i>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-3 col-12 mb-3">
                                    <el-select multiple style="width: 100%"
                                               v-model="currentState.searchFields.selected" placeholder="Dove cerco?"
                                               @change="handleSearchFieldsChange">
                                        <el-option v-for="(option, index) in searchFields" class="select-default"
                                                   :value="option.searchFields" :label="option.label" :key="index">
                                        </el-option>
                                    </el-select>
                                </div>

                                <div class="col-sm-3 col-12 mb-3">
                                    <el-select style="width: 100%"
                                               v-model="currentState.searchTypeSelected"
                                               placeholder="Come cercare?" @change="handleSearchTypeChange">
                                        <el-option v-for="(item, index) in searchTypeOptions"
                                                   :key="index"
                                                   :label="item.label" :value="index"></el-option>
                                    </el-select>
                                </div>

                                <div class="col-12 col-sm-4">
                                    <el-input style="width: 100%" type="search" placeholder="Cerca"
                                              v-model="currentState.searchQuery"
                                              @change="handleSearchChange"/>
                                </div>

                                <div class="col-12 col-sm-2 col-md-2 mb-3 no-padding-left-desktop">
                                    <button class="btn btn-outline btn-danger btn-outline col-12"
                                            style="padding: 8px 0px; width: 100%"
                                            @click="resetFilters">
                          <span class="btn-label">
                             <i class="fa fa-times"></i>
                          </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-12" v-loading="loading">
                            <el-table class="table-striped" :row-key="getRowKey" :data="tableData" border
                                      style="width: 100%"
                                      :default-sort="currentState.currentSorting" @sort-change="handleSortChange"
                                      @selection-change="handleSelectionChange">

                                <template v-for="column in tableColumns" style="width: 100%">
                                    <el-table-column v-if="!column.custom && column.type !== 'selection'"
                                                     :type="column.type"
                                                     :fixed="column.fixed" :key="column.label" :prop="column.prop"
                                                     :sortable="column.sortable" :label="column.label"
                                                     :width="calculateTableWidth(column)"></el-table-column>

                                    <el-table-column v-else-if="column.type === 'selection'"
                                                     type="selection"></el-table-column>

                                    <el-table-column v-else-if="column.custom === 'id'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="170">
                                        <template #default="props">
                                            <router-link class="dark-link"
                                                         :to="{path:'/ordini/lista/modifica/' + props.row.id}">
                                                <div v-if="props.row.id in orders_active_users"
                                                     class="activeUsers float-left">
                                                    <div class="circle"></div>
                                                </div>
                                            </router-link>
                                            <router-link class="dark-link"
                                                         :to="{path:'/ordini/lista/modifica/' + props.row.id}">
                                                <div style="line-height: 1.2;" v-if="props.row.id">
                                                    <strong>#{{ props.row.id }}</strong>
                                                </div>
                                            </router-link>

                                            <div style="line-height: 1.2;" v-if="props.row.origin" class="mt-1">
                                                <small style="font-size: 85%">{{ props.row.origin }}</small>
                                            </div>

                                            <div style="line-height: 1.2;" v-if="props.row.type_details">
                                                <small style="font-size: 90%"><i>{{
                                                        props.row.type_details.name
                                                    }}</i></small>
                                            </div>

                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'type'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="calculateTableWidth(column)">
                                        <template #default="props">
                                            <span v-if="props.row">{{ props.row.type_name }}</span>
                                        </template>
                                    </el-table-column>


                                    <el-table-column v-else-if="column.custom === 'data'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="120">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <router-link class="dark-link" style="font-size: 90%"
                                                             :to="{path:'/ordini/lista/modifica/' + props.row.id}"
                                                             v-html="dateToItalian(props.row.created_at, false, 'DD/MM/YYYY<br />HH:mm:ss')"></router-link>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'data_updated'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="120">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <router-link class="dark-link" style="font-size: 90%"
                                                             :to="{path:'/ordini/lista/modifica/' + props.row.id}"
                                                             v-html="dateToItalian(props.row.updated_at, false, 'DD/MM/YYYY<br />HH:mm:ss')"></router-link>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'nome'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="230">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <router-link class="dark-link"
                                                             :to="{path:'/ordini/lista/modifica/' + props.row.id}">
                                                    <strong style="text-transform: capitalize;">{{
                                                            props.row.customer.first_name
                                                        }}
                                                        {{ props.row.customer.last_name }}</strong>
                                                </router-link>
                                                <div v-if="props.row.customer.codice_fiscale" class="">
                                                    <span>{{ props.row.customer.codice_fiscale }}</span>
                                                </div>
                                                <div v-if="props.row.customer.ts_code" class="">
                                                    <span>CODICE TS: {{ props.row.customer.ts_code }}</span>
                                                </div>
                                                <div v-if="props.row && props.row.activation_address" class="">
                                                    <span>Città: {{ props.row.activation_address.city }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'status'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="300">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <router-link class="dark-link"
                                                             :to="{path:'/ordini/lista/modifica/' + props.row.id}">
                                                    {{ props.row.status.internal_name }}
                                                </router-link>
                                            </div>
                                        </template>
                                    </el-table-column>


                                    <el-table-column v-else-if="column.custom === 'prodotto'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                {{ props.row.order_product.offer.internal_name }}<br/>
                                                {{ props.row.order_product.operation_type_details.name }}<br/>

                                                <span
                                                    v-if="props.row && props.row.order_product && props.row.order_product.options">
                          <hr style="margin-top: 2px; margin-bottom: 2px;"/>
                          <ul style="margin-bottom: 2px;">
                            <li v-for="item in props.row.order_product.options" :key="item.id"
                                style="size: 8px;">{{ item.internal_name }}</li>
                          </ul>
                          <hr style="margin-top: 2px; margin-bottom: 2px;"/>
                        </span>
                                                <span v-if="props.row && props.row.order_product.pop">{{
                                                        props.row.order_product.pop
                                                    }}</span><br/>
                                            </div>
                                        </template>
                                    </el-table-column>

                                </template>

                            </el-table>
                        </div>
                    </div>

                    <div slot="footer"
                         class="row float-right d-flex justify-content-center justify-content-sm-between flex-wrap mt-3 pr-0">
                        <l-pagination class="pagination-no-border"
                                      v-model="currentState.pagination.currentPage"
                                      :per-page="currentState.pagination.perPage"
                                      :total="currentState.pagination.total"
                                      @input="handlePageChange">
                        </l-pagination>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'

export default {
    components: {
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
    },
    computed: {},
    watch: {
        '$route.params.tipo': function () {
            if (!this.$route.params.tipo || parseInt(this.$route.params.tipo) < 1) return
            this.resetFilters(true, parseInt(this.$route.params.tipo))
        },
        '$route.params.stato': function () {
            if (!this.$route.params.stato || parseInt(this.$route.params.stato) < 1) return
            this.resetFilters(true, null, parseInt(this.$route.params.stato))
        },
        '$route.params.ordine': function () {
            if (!this.$route.params.ordine || parseInt(this.$route.params.ordine) < 1) return
            this.resetFilters(true, null, null, parseInt(this.$route.params.ordine))
        },
        '$route.params.telefono': function () {
            if (!this.$route.params.telefono || this.$route.params.telefono === '') return
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.telefono
            this.currentState.searchFields.selected = ['mobile_phone_number']
            this.partiallyResetFilters()
            this.updateData()
        },
        '$route.params.email': function () {
            if (!this.$route.params.email || this.$route.params.email === '') return
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.email
            this.currentState.searchFields.selected = ['email']
            this.partiallyResetFilters()
            this.updateData()
        },
        '$route.params.nome': function () {
            if (!this.$route.params.nome || this.$route.params.nome === '') return
            this.currentState.searchTypeSelected = 0
            this.currentState.searchQuery = this.$route.params.nome
            this.currentState.searchFields.selected = ['full_name']
            this.partiallyResetFilters()
            this.updateData()
        },
        '$route.params.codicefiscale': function () {
            if (!this.$route.params.codicefiscale || this.$route.params.codicefiscale === '') return
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.codicefiscale
            this.currentState.searchFields.selected = ['codice_fiscale']
            this.partiallyResetFilters()
            this.updateData()
        },
        '$route.params.codicecliente': function () {
            if (!this.$route.params.codicecliente || this.$route.params.codicecliente === '') return
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.codicecliente
            this.currentState.searchFields.selected = ['ts_code']
            this.partiallyResetFilters()
            this.updateData()
        }
    },
    data() {
        return {
            componentSettings: {
                singleFieldSearch: true,
                searchTypeOption: 2,
                tableSort: {prop: 'created_at', order: 'descending'},
                myComponentName: 'OrdersList',
                minimumPermissionLevel: 200,
                pagination: {
                    perPageOptions: [5, 10, 25, 50, 100]
                },
                typeOptions: []
            },
            currentState: {
                currentSorting: {prop: 'created_at', order: 'descending'},
                searchFields: {
                    selected: ['id'],
                    lastSelection: ['id']
                },
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    lastLoadedPage: 1,
                    total: 0,
                    from: 0,
                    to: 0,
                    showedItems: 0
                },
                searchQuery: '',
                searchTypeSelected: 1,
                tableMultipleSelect: null,
                typeFilter: 0,
                statusFilter: 0
            },
            searchFields: [
                {searchFields: 'id', label: 'ID'},
                {searchFields: 'full_name', label: 'Cliente'},
                {searchFields: 'ts_code', label: 'Codice TS'},
                {searchFields: 'origin', label: 'Provenienza'},
                // {searchFields: 'nome', label: 'Nome'},
                // {searchFields: 'cognome', label: 'Cognome'},
                {searchFields: 'email', label: 'Email'},
                {searchFields: 'mobile_phone_number', label: 'Cellulare cliente'},
                {searchFields: 'partita_iva', label: 'Partita IVA'},
                {searchFields: 'codice_fiscale', label: 'Codice fiscale'},
                {searchFields: 'sim_number', label: 'Numero attivato'},
                {searchFields: 'note', label: 'Note Ordine'},
                {searchFields: 'address', label: 'Indirizzo attivazione'},
                {searchFields: 'city', label: 'Città attivazione'},
                {searchFields: 'pop', label: 'POP'},
                {searchFields: 'building_id', label: 'ID Building'},
                {searchFields: 'id_egon_civico', label: 'Egon ID'},
                {searchFields: 'user_identifier', label: 'Utente radius'}
            ],
            searchTypeOptions: [
                {searchType: '0', label: 'Contiene'},
                {searchType: '2', label: 'Inizia per'},
                {searchType: '1', label: 'È uguale a'}
            ],
            tableColumns: [
                {
                    prop: 'id',
                    label: 'ID',
                    percentWidth: 20,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: true,
                    type: 'default',
                    first: true,
                    custom: 'id',
                },
                {
                    prop: 'created_at',
                    label: 'Data',
                    percentWidth: 20,
                    sortable: true,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    first: false,
                    custom: 'data'
                },
                {
                    prop: 'updated_at',
                    label: 'Aggiornato',
                    percentWidth: 20,
                    sortable: true,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    first: false,
                    custom: 'data_updated'
                },
                {
                    prop: 'customer.nome',
                    label: 'Nome',
                    percentWidth: 20,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    first: false,
                    custom: 'nome'
                },
                {
                    prop: 'status.name',
                    label: 'Stato',
                    percentWidth: 20,
                    sortable: false,
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    first: false,
                    custom: 'status'
                },
                {
                    label: 'Prodotto',
                    percentWidth: 10,
                    sortable: false,
                    showTooltip: false,
                    fixed: false,
                    type: 'default',
                    first: false,
                    custom: 'prodotto'
                }
            ],
            loading: false,
            tableData: [],
            canShowView: false,
            orders_active_users: []
        }
    },
    methods: {
        calculateTableWidth(pColumn, pTableWidth = 1000) { /* Modificare anche nel CSS custom */
            var pPercent = pColumn
            if (typeof pColumn === 'object') pPercent = pColumn.percentWidth
            var risultato = (pTableWidth / 100) * pPercent
            if (pColumn.first === true) risultato-- // Risolve bug su Firefox eliminando 1px dalla prima colonna
            return risultato
        },
        getSaveStateConfig() {
            return {
                'cacheKey': this.componentSettings.myComponentName,
                'saveProperties': ['currentState']
            }
        },
        getRowKey(row) {
            if (row.id) return row.id
            return -1
        },
        goToAllOrders() {
            this.currentState.statusFilter = null
            this.resetFilters()
            this.$router.push('/ordini/lista')
        },
        resetFilters(pReload = true, pType = null, pStatus = null, pOrderSearch = null) {
            this.currentState.searchQuery = ''
            this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
            this.currentState.tableMultipleSelect = null
            this.currentState.searchTypeSelected = 1
            // this.currentState.pagination.perPage = 25
            this.currentState.pagination.currentPage = 1
            this.currentState.searchFields.selected = ['id']
            // this.currentState.typeFilter = 0
            /* if (!parseInt(this.$route.params.tipo)) {
              this.currentState.typeFilter = 0
            } */
            if (!parseInt(this.$route.params.stato)) {
                this.currentState.statusFilter = 0
            }
            if (pType != null) this.currentState.typeFilter = pType
            if (pStatus != null) this.currentState.statusFilter = pStatus
            if (pOrderSearch != null) this.currentState.searchQuery = pOrderSearch
            if (pReload) {
                this.updateData()
            }
        },
        handleSelectionChange(pSelection) {
            this.currentState.tableMultipleSelect = pSelection
        },
        handleSortChange(pSortType) {
            let cSort = this.findColumnSort(pSortType)
            if (pSortType.prop === null) {
                this.currentState.currentSorting = this.componentSettings.tableSort
            } else {
                if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
                    return
                }
                this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
            }
            this.updateData()
        },
        handleSearchTypeChange(pIndex) {
            if (this.currentState.searchQuery === '') return
            this.updateData()
        },
        handleSearchFieldsChange(pIndex) {
            if (this.currentState.searchFields.selected.length < 1) {
                this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
                return
            }
            if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
                this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
            }
            this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
            this.updateData()
        },
        removeUser(pUser) {
            return this.axios.post(this.$config.endpoints.users.base + pUser.id + '/delete', this.$store.getters.apiCommon)
        },
        handleTypeChange(newType) {
            this.resetFilters(false)
            this.currentState.typeFilter = newType
            this.updateData()
        },
        handlePerPageChange(perPage) {
            this.currentState.pagination.currentPage = 1
            this.updateData({page: 1, limit: perPage})
        },
        handlePageChange(page) {
            if (page === this.currentState.pagination.lastLoadedPage) return
            this.updateData()
        },
        handleSearchChange() {
            this.updateData()
        },
        updateData(params) {
            if (this.loading) return
            this.loading = true
            if (!params) params = {}
            params = Object.assign(params, this.$store.getters.apiCommon, {
                page: this.currentState.pagination.currentPage,
                limit: this.currentState.pagination.perPage
            })

            if (this.currentState.searchQuery !== '') {
                params = Object.assign(params, {
                    search: this.currentState.searchQuery,
                    search_fields: this.currentState.searchFields.selected,
                    search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType
                })
            }
            if (this.currentState.currentSorting !== null) {
                params = Object.assign(params, {
                    orderby: this.currentState.currentSorting.prop,
                    order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)
                })
            }
            if (this.currentState.typeFilter > 0) {
                params = Object.assign(params, {type_filter: this.currentState.typeFilter})
            }
            if (this.currentState.statusFilter > 0) {
                params = Object.assign(params, {statuses: [this.currentState.statusFilter]})
            }
            this.$store.dispatch('orders/list', params).then((response) => {
                this.loading = false
                this.tableData = response.data.orders.data
                this.currentState.pagination.total = 1
                if (response.data.orders.data.length >= this.currentState.pagination.perPage || this.currentState.pagination.currentPage > 1) {
                    this.currentState.pagination.total = 99999
                }// response.data.orders.total
                this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
                this.currentState.pagination.showedItems = response.data.orders.data.length
                this.currentState.pagination.from = response.data.orders.from
                this.currentState.pagination.to = response.data.orders.to
                this.getActiveUsers()
                clearInterval(this.interval)
                this.interval = setInterval(() => this.getActiveUsers(), 10000)
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e)
            })
        },
        canEdit(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['orders/canEdit'](elementToCheck)
            }
        },
        canDelete(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['orders/canDelete'](elementToCheck)
            }
        },
        getActiveUsers() {
            this.currentOrderId = this.$route.params.id
            if (this.currentOrderId !== this.currentState.lastOrderId) {
                this.currentState.elTabs = '0';
            }
            this.currentState.lastOrderId = this.currentOrderId
            this.$store.dispatch('orders/getActiveUsers', {group_by_order: true}).then((response) => {
                this.orders_active_users = response.data.data
            }).catch((e) => {
            });
        },
        partiallyResetFilters() {
            this.currentState.typeFilter = 0
            this.currentState.statusFilter = 0
        },
        toggleProperty(propertyName, pElement) {
            this.askToggleMessage(pElement.internal_name).then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('orders/toggleProperty', {
                    property: propertyName,
                    element: pElement
                }).then((response) => {
                    this.loading = false
                    this.updateData()
                }).catch(e => {
                    this.loading = false
                    this.showLoadingError(e, '', '', false)
                })
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.componentSettings.typeOptions = this.$store.getters['orders/types']
        this.canShowView = true
        if (!this.currentState || !this.currentState.pagination || !this.currentState.pagination.perPage) {
            this.currentState.pagination.perPage = 25
        }
        if (parseInt(this.$route.params.stato) && parseInt(this.$route.params.tipo)) {
            this.resetFilters(true, parseInt(this.$route.params.tipo), parseInt(this.$route.params.stato))
        } else if (parseInt(this.$route.params.stato)) {
            this.resetFilters(true, 0, parseInt(this.$route.params.stato))
        } else if (parseInt(this.$route.params.tipo)) {
            this.resetFilters(true, parseInt(this.$route.params.tipo))
        } else if (this.$route.params.ordine) {
            this.resetFilters(true, null, null, this.$route.params.ordine)
        } else if (this.$route.params.telefono) {
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.telefono
            this.currentState.searchFields.selected = ['mobile_phone_number']
            this.partiallyResetFilters()
            this.handleSearchFieldsChange()
        } else if (this.$route.params.email) {
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.email
            this.currentState.searchFields.selected = ['email']
            this.partiallyResetFilters()
            this.handleSearchFieldsChange()
        } else if (this.$route.params.nome) {
            this.currentState.searchTypeSelected = 0
            this.currentState.searchQuery = this.$route.params.nome
            this.currentState.searchFields.selected = ['full_name']
            this.partiallyResetFilters()
            this.handleSearchFieldsChange()
        } else if (this.$route.params.codicefiscale) {
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.codicefiscale
            this.currentState.searchFields.selected = ['codice_fiscale']
            this.partiallyResetFilters()
            this.handleSearchFieldsChange()
        } else if (this.$route.params.codicecliente) {
            this.currentState.searchTypeSelected = 1
            this.currentState.searchQuery = this.$route.params.codicecliente
            this.currentState.searchFields.selected = ['ts_code']
            this.partiallyResetFilters()
            this.handleSearchFieldsChange()
        } else {
            this.updateData()
        }
    },
    mounted: function () {
        this.updateData()
    },
    destroyed: function () {
        clearInterval(this.interval)
        this.$off()
    }
}
</script>
<style>
.products-enable .svg-inline--fa {
    margin-left: 2px;
    margin-right: 2px;
}

.products-enable a:first-child .svg-inline--fa {
    margin-left: 0px;
}

.products-enable a:last-child .svg-inline--fa {
    margin-right: 0px;
}

.orderProducts-td {
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 7px;
    padding-bottom: 7px;
}

.orderProducts-td:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0 solid transparent;
}

#activeUsersList .activeUsers {
    color: limegreen;
    font-weight: bold;
    font-size: 20px;
}

#activeUsersList .activeUsers .circle {
    background-color: limegreen;
    width: 12px;
    height: 12px;
    border-radius: 60%;
    float: left;
    margin-top: 2px;
    margin-right: 2px;
}

td.el-table_1_column_1.el-table-fixed-column--left.is-last-column.el-table__cell {
    background-color: unset;
}

</style>
