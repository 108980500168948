<template>
  <div v-if="offer_option.id">
    <hr>
    <h4 class="title">Assegnazione Massiva</h4>
    <form>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <label>Tipo Ordine</label>
        </div>
        <div class="col-sm-12 col-md-8">
          <el-checkbox-group v-model="formData.offer_types">
            <el-checkbox v-for="checkbox in checkboxes.offerTypes" :label="checkbox.value" :key="checkbox.key">
              {{checkbox.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <label>Marca</label>
        </div>
        <div class="col-sm-12 col-md-8">
          <el-autocomplete
            style="width: 100%"
            v-loading="loading"
            :debounce="0"
            v-model="formData.product_brands"
            :fetch-suggestions="getBrands"
            placeholder="Scrivi qui dentro"
            name="marca"></el-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <label>Prodotto</label>
        </div>
        <div class="col-sm-12 col-md-8">
          <el-checkbox-group v-model="formData.product_types">
            <el-checkbox v-for="checkbox in checkboxes.products" :label="checkbox.value" :key="checkbox.key">
              {{checkbox.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <label>Contratto</label>
        </div>
        <div class="col-sm-12 col-md-8">
          <el-checkbox-group v-model="formData.contract_types">
            <el-checkbox v-for="checkbox in checkboxes.contracts" :label="checkbox.value" :key="checkbox.key">
              {{checkbox.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <label>Utenza</label>
        </div>
        <div class="col-sm-12 col-md-8">
          <el-checkbox-group v-model="formData.customer_types">
            <el-checkbox v-for="checkbox in checkboxes.customers" :label="checkbox.value" :key="checkbox.key">
              {{checkbox.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <label>Altro</label>
        </div>
        <div class="col-sm-12 col-md-8">
          <el-checkbox :inline="true" v-model="formData.exclude_integrated_offers" key="exclude_offers">Escludi offerte integrate
          </el-checkbox>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
            Assegna alle offerte
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import Swal from "sweetalert2";

  export default {
    name: 'OfferOptionMassiveAssignForm',
    components: {
    },
    props: {
      offer_option: {}
    },
    data () {
      return {
        loading: false,
        formData: {
          option_id: null,
          offer_types: [],
          product_brands: null,
          product_types: [],
          contract_types: [],
          customer_types: [],
          exclude_integrated_offers: false
        },
        checkboxes: {
          offerTypes: [
            {
              key: 'voice',
              name: 'Voce',
              value: '0'
            },
            {
              key: 'data',
              name: 'Dati',
              value: '1'
            }
          ],
          products: [
            {
              key: 'sim_only',
              name: 'Solo Sim',
              value: '0'
            },
            {
              key: 'sim_device',
              name: 'Sim + Device',
              value: '1'
            }
          ],
          contracts: [
            {
              key: 'prepaid',
              name: 'Ricaricabile',
              value: '0'
            },
            {
              key: 'postpaid',
              name: 'Abbonamento',
              value: '1'
            }
          ],
          customers: [
            {
              key: 'consumer',
              name: 'Privati',
              value: '0'
            },
            {
              key: 'pro',
              name: 'Pro',
              value: '1'
            },
            {
              key: 'business',
              name: 'Biz',
              value: '2'
            }
          ]
        },
        selects: {
          brands: []
        }
      }
    },
    methods: {
      loadBrands () {
        this.$store.dispatch('products/getBrands').then((response) => {
          this.selects.brands = response.data.brands
        }).catch(e => {
          this.showLoadingError(e, 'il caricamento delle marche')
        })
      },
      getBrands (queryString, cb) {
        let results = queryString ? this.selects.brands.filter(this.filterBrands(queryString)) : this.selects.brands
        cb(results)
      },
      filterBrands (queryString) {
        return (brand) => {
          return (brand.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      validateForm () {
        this.formData.option_id = this.offer_option.id
        Swal.fire({
          title: 'Sei sicuro?',
          html: 'Vuoi collegare questa opzione a tutte le offerte che rispettano i criteri?',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-primary btn-simple',
          type: 'question',
          confirmButtonText: 'SI'
        }).then((result) => {
          if (!result.value) return
          this.$store.dispatch('offerOption/massiveAttach', this.formData).then(() => {
            Swal.fire('Fatto!',
              'Opzione collegata a tutte le offerte!',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
          })
        })
      }
    },
    created: function () {
      this.loadBrands()
    }
  }
</script>

<style scoped>

</style>
