<template>
    <div>
        <div class="row mb-4" v-if="order.id && order.customer">
            <div class="col-12">
                <el-card>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="mt-2">
                            <div class="d-flex">
                                <h3 class="m-0">#{{ order.id }} <span class="text-muted h4">di</span>
                                    {{ order.customer.first_name }}
                                    {{ order.customer.last_name }}</h3>
                                <el-button class="ml-2" icon="refresh" circle type="primary"
                                           @click="updateData"/>
                            </div>
                            <p class="m-0" style="font-size: 14px">Creato il:
                                <code>{{ dateToItalian(order.created_at, true) }}</code>
                            </p>
                            <p class="m-0" style="font-size: 14px">Ultima modifica:
                                <code>{{ dateToItalian(order.created_at, true) }}</code></p>
                            <p class="m-0" style="font-size: 14px">Codice TS: <a
                                :href="'https://www.dimensione.com/private_tools/clienti.php?codicecliente=' + order.customer.ts_code"
                                target="_blank"><code>{{ order.customer.ts_code }}</code></a> | <a
                                :href="'https://www.dimensione.com/private_tools/crea_utente_openfiber.php?codicecliente=' + order.customer.ts_code"
                                target="_blank">Crea utenza PPPoE</a></p>
                        </div>
                        <current-active-users :active_users="active_users"/>
                    </div>
                </el-card>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-lg-7 order-md-2 order-sm-2 order-lg-1 order-2">
                <el-card>
                    <el-tabs v-model="currentState.elTabs">
                        <el-tab-pane label="Cliente">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-user mr-2"/>Cliente</span>
                            </template>
                            <order-customer-details :order="order" @update="updateCustomer" @updateOrder="updateOrder"/>
                        </el-tab-pane>

                        <el-tab-pane label="Offerta">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-box-dollar mr-2"/>Offerta</span>
                            </template>
                            <order-offer-details v-bind:order.sync="order" @update="updateOrder" @refresh="updateData"/>
                        </el-tab-pane>
                        <el-tab-pane label="Wholesale">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-notebook mr-2"/>Wholesale</span>
                            </template>
                            <order-wholesale-details v-model="order"
                                                     v-bind:orderid.sync="currentOrderId" @reloaded="updateData"/>
                        </el-tab-pane>
                        <el-tab-pane label="Notifiche">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-comments mr-2"></i>Notifiche</span>
                            </template>
                            <order-notification-details v-bind:order.sync="order"></order-notification-details>
                        </el-tab-pane>

                        <el-tab-pane label="Metodo di pagamento">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-credit-card mr-2"/>Metodo pagamento</span>
                            </template>
                            <order-payment-method-details :order="order"/>
                        </el-tab-pane>
                        <el-tab-pane label="Spedizione">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-truck-fast mr-2"/>Spedizione</span>
                            </template>
                            <order-shipping-details v-bind:order.sync="order"
                                                    v-bind:shipping_address.sync="order.shipping_address"
                                                    @update="updateOrder"/>
                        </el-tab-pane>
                        <!--                        <el-tab-pane label="Pagamento ordine">-->
                        <!--                            <template #label>-->
                        <!--                                <span slot="label"><i class="fa-solid fa-user mr-2" />Pagamento ordine</span>-->
                        <!--                            </template>-->
                        <!--                            <order-payment-details :order="order"-->
                        <!--                                                   @update="updateOrder"/>-->
                        <!--                        </el-tab-pane>-->
                        <el-tab-pane label="Allegati">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-paperclip mr-2"></i>Allegati</span>
                            </template>
                            <order-attachments :order="order" @refresh="updateData"/>
                        </el-tab-pane>
                        <el-tab-pane label="Azioni" v-if="isDeveloper">
                            <template #label>
                                <span slot="label"><i class="fa-regular fa-gears mr-2"></i>Azioni</span>
                            </template>
                            <order-actions v-bind:order.sync="order"></order-actions>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>

            </div>

            <div class="col-lg-5 col-12 order-md-1 order-sm-1 order-lg-2 order-1">
                <el-card class="mb-4">
                    <order-edit-page-type-and-status-form v-bind:order.sync="order" :reload="loading"
                                                          @status-changed=""/>
                </el-card>
                <el-card class="overflow-auto">
                    <order-timeline-section ref="orderTimelineSection" :order-id="currentOrderId"/>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from '@/components'
import OrderAttachments from '../Orders/OrderAttachments.vue'
import OrderGeneralDetails from '../Orders/OrderGeneralDetails'
import OrderNotificationDetails from '../Orders/OrderNotificationDetails'
import OrderCustomerDetails from "../OrderEditPage/EditCustomerSection/OrderCustomerDetails";
import OrderWholesaleDetails from '../Orders/OrderWholesaleDetails'
import OrderNotesChronologyDetails from '../Orders/OrderNotesChronologyDetails'
import OrderOfferDetails from "../Orders/OrderOfferDetails/OrderOfferDetails";
import OrderPaymentDetails from '../Orders/OrderPaymentDetails'
import OrderPaymentMethodDetails from '../Orders/OrderPaymentMethodDetails'
import OrderShippingDetails from '../Orders/OrderShippingDetails'
import OrderActions from '../Orders/OrderActions'
import OrderContractDetails from "../Orders/OrderContractDetails";
import OrderEditPageHeader from "../OrderEditPage/PageComponents/OrderEditPageHeader";
import OrderEditPageTypeAndStatusForm
    from "../OrderEditPage/PageComponents/OrderEditPageTypeAndStatusForm";
import CurrentActiveUsers from "@/pages/Dashboard/OrderEditPage/PageComponents/CurrentActiveUsers";
import OrderNoteSection from "@/pages/Dashboard/Orders/Components/OrderNoteSection";
import OrderTimelineSection from "@/pages/Dashboard/Orders/Components/OrderTimelineSection";

export default {
    components: {
        OrderTimelineSection,
        OrderNoteSection,
        CurrentActiveUsers,
        OrderEditPageTypeAndStatusForm,
        OrderEditPageHeader,
        OrderContractDetails,
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
        LSwitch,
        OrderAttachments,
        OrderGeneralDetails,
        OrderNotificationDetails,
        OrderCustomerDetails,
        OrderNotesChronologyDetails,
        OrderOfferDetails,
        OrderPaymentDetails,
        OrderPaymentMethodDetails,
        OrderShippingDetails,
        OrderActions,
        OrderWholesaleDetails
    },
    computed: {},
    watch: {
        '$route.params.id': function (val) {
            if (val) {
                this.updateData()
            }
        }
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'EditOrder',
                minimumPermissionLevel: 200,
                listPath: '/ordini/lista'
            },
            loading: false,
            canShowView: false,
            statuses: [],
            types: [],
            order: {},
            currentOrderId: null,
            currentState: {
                elTabs: null,
                lastOrderId: null
            },
            modelValidations: {},
            originalStatus: null,
            originalType: null,
            interval: null,
            active_users: []
        }
    },
    methods: {
        getSaveStateConfig() {
            return {
                'cacheKey': this.componentSettings.myComponentName,
                'saveProperties': ['currentState']
            }
        },
        changeCommonProperties() {
            this.updateOrder({
                id: this.order.id,
                type: this.order.type,
                status: this.order.status,
                complete: this.order.complete
            }, false)
        },
        canResetActivationDate(item) {
            return this.$store.getters['orders/canResetActivationDate'](item)
        },
        handleResetActivationDate() {
            this.askGenericConfirm('Vuoi resettare la data di attivazione?', 'Premendo Sì confermerai l\'operazione e non sarà più possibile recuperare la data di attivazione precedente.', 'Sì, resetta!', 'No, ho sbagliato!').then((result) => {
                if (!result.value) {
                    return
                }
                this.updateOrder({id: this.order.id, activation_date: null}, false, false, false)
            })
        },
        deleteOrder(pElement) {
            this.askDeleteMessage('l\'ordine #' + this.order.id).then((result) => {
                if (!result.value) return
                this.$store.dispatch('orders/delete', pElement).then(() => {
                    this.askGenericSuccess('Eliminato!', 'Ordine eliminato!', 'success').then(() => {
                        this.$router.push(this.componentSettings.listPath)
                    })
                }).catch((e) => {
                    this.showLoadingErrorWithoutRetry(e, 'l\'eliminazione dell\'ordine')
                })
            })
        },
        getActiveUsersForSingleOrder() {
            this.currentOrderId = this.$route.params.id;
            if (!this.currentOrderId) {
                return;
            }
            this.$store.dispatch('orders/getActiveUsersForSingleOrder', this.currentOrderId).then((response) => {
                this.active_users = response.data.active_users
            })
        },
        printPdf() {
            this.loading = true
            this.$store.dispatch('orders/getCover', this.order).then((file) => {
                this.loading = false
                var fileDownload = require('js-file-download')
                fileDownload(file, 'Copertina_' + this.order.id + '.pdf', 'application/pdf')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il recupero della copertina')
            })
        },
        updateCustomer(pCustomer, pOrder = null) {
            this.loading = true
            this.$store.dispatch('customers/edit', pCustomer).then((response) => {
                this.loading = false
                this.order.customer = response.data.customer
                if (!pOrder) {
                    this.showGenericSuccess('Cliente')
                } else {
                    this.updateOrder(pOrder, false, false, false)
                }
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo cliente')
            })
        },
        updateData() {
            this.currentOrderId = this.$route.params.id
            if (this.currentOrderId !== this.currentState.lastOrderId) this.currentState.elTabs = '0'
            this.currentState.lastOrderId = this.currentOrderId
            //this.currentState.elTabs = '0'
            this.loading = true
            this.$store.dispatch('orders/getById', this.currentOrderId).then((response) => {
                this.order = response.data.order
                this.order.originalStatus = this.order.status;
                this.order.originalStatusId = this.order.status.id;
                this.order.originalType = this.order.type;
                this.originalStatus = this.order.status
                this.originalType = this.order.type
                if (!this.order.status) {
                    this.$message.error('Proprietà status non definita.')
                    return
                } else if (!this.order.customer) {
                    this.$message.error('Proprietà customer non definita.')
                    return
                }
                this.getActiveUsersForSingleOrder()
                clearInterval(this.interval)
                this.$refs.orderTimelineSection.refreshList()
                this.interval = setInterval(() => this.getActiveUsersForSingleOrder(), 10000)
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il recupero dei dati di questo ordine').then(() => {
                    this.$router.push(this.componentSettings.listPath)
                })
            })
        },
        updateOrder(pOrderObjectModifiedProperties, pRedirect = false, pSilent = false, pReloadNotes = true) {
            this.loading = true
            this.$store.dispatch('orders/edit', pOrderObjectModifiedProperties).then((response) => {
                this.loading = false
                for (var propertyName in pOrderObjectModifiedProperties) {
                    if (this.order.hasOwnProperty(propertyName) && response.data.order.hasOwnProperty(propertyName)) {
                        this.order[propertyName] = response.data.order[propertyName]
                    }
                }
                if (response.data.order && response.data.order.shipping_at && response.data.order.track_id_corriere) {
                    if (!this.order.shipping_at && !this.order.track_id_corriere) {
                        this.order.shipping_at = response.data.order.shipping_at
                        this.order.track_id_corriere = response.data.order.track_id_corriere
                    }
                }
                this.originalStatus = null
                this.originalType = null
                if (!pSilent) this.showGenericSuccess('Ordine')
                if (pRedirect) {
                    this.$router.push(this.componentSettings.listPath)
                } else if (pReloadNotes) {
                    this.$store.commit('orders/updateChronologyEvents')
                }
            }).catch(e => {
                this.loading = false
                if (this.originalStatus) this.order.status = this.originalStatus
                if (this.originalType) this.order.type = this.originalType
                if (!pSilent) this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dei dati di questo ordine')
            })
        }
    },
    created: function () {
        this.statuses = this.$store.getters['orders/statuses/statuses']
        this.types = this.$store.getters['orders/types']
    },
    mounted: function () {
        this.updateData()
        this.canShowView = true
    },
    destroyed: function () {
        this.$off()
        clearInterval(this.interval)
    }
}
</script>
<style>
#orderTabsContainer .el-tabs__item {
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}

#orderTabsContainer .el-tabs__item span i {
    font-size: 17px !important;
}

#orderTabsContainer .el-tabs__item span i.nc-icon {
    font-size: 17px !important;
    font-weight: bold !important;
    margin-right: 4px;
    position: relative;
    top: 3px;
}

.active-user {
    margin-left: -4px;
    float: right;
}

.active-user-image {
    /* border-style: solid;
    border-color: #60626669; */
}

.active-users-enter {
    opacity: 0;
}

.active-users-enter-active {
    animation: fadein 0.5s;
}

.active-users-leave {
    opacity: 1;
}

.active-users-leave-active {
    animation: fadein 0.5s reverse;
}


@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

</style>
