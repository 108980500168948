<template>
  <div class="chronology-section mt-4" v-loading="loading">
    <div class="d-flex align-items-center mb-3">
      <h3 class="m-0 mr-2">Cronologia Dati Open Fiber</h3>
      <el-button circle size="small" icon="refresh" @click="loadChronology()"
                 data-tooltip="'Ricarica cronologia'"/>
    </div>

    <!-- Ordine -->
    <h4><strong>Ordine</strong></h4>
    <h5 class="n-thin" v-if="!chronology.ordine || chronology.ordine.length < 1">Nessun elemento da mostrare</h5>
    <el-table :data="chronology.ordine" v-if="chronology.ordine && chronology.ordine.length > 0" :row-class-name="tableRowClassName">
      <el-table-column prop="created_at" width="200" label="Data">
        <template #default="scope">
          <div class="d-flex align-items-center">
            <div>
              <p class="m-0">{{ dateToItalian(scope.row.created_at, false)  }}</p>
              <p class="text-small m-0">{{timePassedFromDate(scope.row.created_at)}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="codice_olo" label="Codice OLO" :width="225">
        <template #default="scope">
          <code>{{ scope.row.codice_olo }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="request_kind" label="Motivo">
        <template #default="scope">
          {{ scope.row.request_kind }}<br /><i>{{scope.row.nota_sospensione}}</i>
        </template>
      </el-table-column>
      <el-table-column prop="" label="" width="75" fixed="left">
        <template #default="scope">
          <el-button @click="showDetails = true; detailItem = scope.row" size="small" type="primary" class="ml-auto"
                     icon="view" circle/>
        </template>
      </el-table-column>
    </el-table>







    <!-- Provisioning -->
    <h4><strong>Provisioning</strong></h4>

    <h5 class="n-thin" v-if="!chronology.provisioning || chronology.provisioning.length < 1">Nessun elemento da
      mostrare</h5>

    <el-table :data="chronology.provisioning" v-if="chronology.provisioning && chronology.provisioning.length > 0" :row-class-name="tableRowClassName">
      <el-table-column prop="created_at" width="200" label="Data">
        <template #default="scope">
          <div class="d-flex align-items-center">
            <div>
              <p class="m-0">{{ dateToItalian(scope.row.created_at, false)  }}</p>
              <p class="text-small m-0">{{timePassedFromDate(scope.row.created_at)}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="codice_olo" label="Codice OLO" :width="155">
        <template #default="scope">
          <code>{{ scope.row.codice_olo }}</code>
          <br />
          <code>ID: {{ scope.row.original_subject }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="nota" label="Nota">
        <template #default="scope">
          <span v-if="scope.row.provisioning_data && scope.row.provisioning_data.Status">
              Stato Provisioning: <strong>{{scope.row.provisioning_data.Status}}</strong>
            <hr style="margin-bottom: 1px; margin-top: 1px;" />
            </span>
            <p class="m-0 text-medium">{{scope.row.nota}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="" label="" width="75" fixed="left">
        <template #default="scope">
          <el-button @click="showDetails = true; detailItem = scope.row" size="small" type="primary" class="ml-auto"
                     icon="view" circle/>
        </template>
      </el-table-column>
    </el-table>

    <!-- Guasti -->
    <h4><strong>Guasti</strong></h4>
    <h5 class="n-thin" v-if="!chronology.guasto || chronology.guasto.length < 1">Nessun elemento da mostrare</h5>
    <el-table :data="chronology.guasto" v-if="chronology.guasto && chronology.guasto.length > 0" :row-class-name="tableRowClassName">
      <el-table-column prop="created_at" width="200" label="Data">
        <template #default="scope">
          <div class="d-flex align-items-center">
            <div>
              <p class="m-0">{{ dateToItalian(scope.row.created_at, false)  }}</p>
              <p class="text-small m-0">{{timePassedFromDate(scope.row.created_at)}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="codice_olo" label="Codice OLO" :width="225">
        <template #default="scope">
          <code>{{ scope.row.codice_olo }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="request_kind" label="Motivo">
        <template #default="scope">
          {{ scope.row.request_kind }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="" width="75" fixed="left">
        <template #default="scope">
          <el-button @click="showDetails = true; detailItem = scope.row" size="small" type="primary" class="ml-auto"
                     icon="view" circle/>
        </template>
      </el-table-column>
    </el-table>


    <!-- Giacenza -->
    <h4><strong>Giacenza</strong></h4>
    <h5 class="n-thin" v-if="!giacenza || giacenza.length < 1">Nessun elemento da mostrare</h5>

    <el-table :data="giacenza" v-if="giacenza && giacenza.length > 0" :row-class-name="tableRowClassName">
      <el-table-column prop="created_at" width="200" label="Data">
        <template #default="scope">
          <div class="d-flex align-items-center">
            <div>
              <p class="m-0">{{ dateToItalian(scope.row.created_at, false)  }}</p>
              <p class="text-small m-0">{{timePassedFromDate(scope.row.created_at)}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="codice_ordine_olo" label="Codice OLO" :width="155">
        <template #default="scope">
          <code>{{ scope.row.codice_ordine_olo }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="stato_ordine" label="Stato" :width="240">
        <template #default="scope">
          {{ scope.row.stato_ordine }}
          <br />
          <strong>{{ scope.row.riscontro }}</strong>
        </template>
      </el-table-column>
      <el-table-column prop="nota" label="Note">
        <template #default="scope">
          <p class="m-0 text-medium">{{scope.row.note}}</p>
          <p class="m-0 text-medium">
            <span v-if="scope.row.ente_protocollo && scope.row.ente_protocollo.length > 1"><strong>Ente: </strong>{{ scope.row.ente_protocollo }}<br /></span>
            <span v-if="scope.row.numero_protocollo && scope.row.numero_protocollo.length > 1"><strong>Numero Protocollo</strong>: {{ scope.row.numero_protocollo }}<br /></span>
            <span v-if="scope.row.ente_protocollo_2 && scope.row.ente_protocollo_2.length > 1"><strong>Ente 2:</strong> {{ scope.row.ente_protocollo_2 }}<br /></span>
            <span v-if="scope.row.numero_protocollo_2 && scope.row.numero_protocollo_2.length > 1"><strong>Numero Protocollo 2:</strong> {{ scope.row.numero_protocollo_2 }}<br /></span>
            <span v-if="scope.row.ente_protocollo_3 && scope.row.ente_protocollo_3.length > 1"><strong>Ente 3:</strong> {{ scope.row.ente_protocollo_3 }}<br /></span>
            <span v-if="scope.row.numero_protocollo_3 && scope.row.numero_protocollo_3.length > 1"><strong>Numero Protocollo 3:</strong> {{ scope.row.numero_protocollo_3 }}<br /></span>
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="" label="" width="75" fixed="left">
        <template #default="scope">
          <el-button @click="showDetails = true; detailItem = scope.row" size="small" type="primary" class="ml-auto"
                     icon="view" circle/>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="Dettagli"
      v-model="showDetails"
      v-if="detailItem"
      width="90%">
      <div>
        <div class="border rounded py-4 px-4 mb-4">
          <h4 class="mt-0">
            <span>Dettagli completi</span>
          </h4>
          <vue-json-pretty :data="detailItem" :showLength="true"/>
        </div>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetails = false" type="primary">Chiudi</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<style>
.notes-section .lista li, .chronology-section .lista li {
  list-style-type: none;
  margin-bottom: 18px;
  min-height: 45px;
}

.notes-section .info, .chronology-section .info {
  padding-top: 0px;
  width: calc(100% - 55px);
}

.notes-section .info div.n-thin, .chronology-section .info div.n-thin {
  font-size: 80%;
}

.notes-section .info span, .chronology-section .info span {
  font-size: 95%;
}
</style>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: "OpenFiberChronologyOld",
  components: {VueJsonPretty},
  data: function () {
    return {
      loading: false,
      chronology: [],
      giacenza: [],
      showDetails: false,
      detailItem: null
    }
  },
  props: {
    orderId: {
      required: true
    }
  },
  created() {
    this.loadChronology()
  },
  watch: {
    'orderId': function () {
      this.loadChronology()
    }
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      return 'outbound-row'
    },
    loadChronology() {
      this.loading = true
      this.$store.dispatch('orders/getOpenFiberChronologyUpdates', {id: this.orderId}).then((response) => {
        this.chronology = response.data.data;
        this.loadGiacenza();
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false;
      })
    },
    loadGiacenza() {
      this.loading = true
      this.$store.dispatch('orders/getOpenFiberGiacenza', {numero_ordine_console: this.orderId}).then((response) => {
        this.giacenza = response.data.data.results;
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false
      })
    },

  }
}
</script>

<style scoped>

</style>
<style>
.text-medium {
  font-size: 14px;
}

.el-table .outbound-row {
  background: #f9fcff;
}
</style>
