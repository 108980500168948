<template>
    <div v-loading="loading">

        <div class="notes-section">
            <h4>Notifiche</h4>

            <el-form label-position="top">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-5">
                        <el-form-item label="Tipologia">
                            <el-select filterable v-loading="loading" v-model="defaultTemplate" style="width: 100%"
                                       class="select-default mb-3" placeholder="Seleziona un template..."
                                       @change="handleTemplateChange">

                                <el-option
                                    v-for="template in templates"
                                    class="select-default"
                                    :key="template.id"
                                    :label="template.name"
                                    :value="template.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-5">
                        <el-form-item label="Oggetto">
                            <el-input label="Oggetto"
                                      name="subject"
                                      v-model="templateSubject">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-5 mb-3">
                        <el-form-item label="Mittente">
                            <el-select filterable v-loading="loading" v-model="currentSender"
                                       class="select-default mb-3 w-100" placeholder="Seleziona un mittente..."
                                       size="large">
                                <el-option
                                    v-for="email in sendersEmail"
                                    class="select-default"
                                    :key="email"
                                    :label="email"
                                    :value="email">
                                </el-option>

                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <div class="row" v-if="isDeveloper">
                    <div class="col-12 col-sm-6 col-md-5 pt-0">
                        <el-checkbox class="pt-0 mt-0" v-model="debugEnabled">Abilita modalità debug (developers only)
                        </el-checkbox>
                    </div>
                </div>

                <div class="row" v-if="debugEnabled">
                    <div class="col-12 col-sm-6 col-md-5">
                        <el-form-item label="ID Ordine Custom">
                            <el-input label="ID Ordine Custom"
                                      name="order_id"
                                      v-model="debugOrderId">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row" v-if="debugEnabled">
                    <div class="col-12 col-sm-6 col-md-5">
                        <label>ID Order Product Custom</label>
                        <el-form-item label="ID Order Product Custom">
                            <el-input label="ID Order Product Custom"
                                      name="order_product_id"
                                      v-model="debugOrderProductId">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row" style="margin-bottom: 40px; margin-top: 30px;" v-if="debugEnabled">
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-fill btn-wd" @click="handleTemplateChange">
                            Aggiorna
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <el-form-item label="Contenuto template">
                            <rich-text-editor v-model="templateContent" class="w-100"/>
                        </el-form-item>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <el-form-item label="Allegati che verranno inviati con questa email">
                                    <el-checkbox-group v-model="currentAttachments">
                                        <el-checkbox v-for="(attachment, index) in availableAttachments" border
                                                     :label="attachment" :key="index">{{
                                                getFormattedAttachment(attachment)
                                            }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>

            <div class="row mt-4">
                <div class="col-12 d-flex justify-content-end">
                    <el-button type="primary" @click="handleSendButton" :disabled=idDisabledSend>
                        <i class="fa-regular fa-paper-plane mr-2"></i>
                        Invia
                    </el-button>
                </div>
            </div>

        </div>
    </div>
</template>
<style></style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'
import RichTextEditor from "@/components/RichTextEditor";

export default {
    mixins: [],
    components: {
        RichTextEditor,
        LSwitch,
        LCheckbox
    },
    props: {
        order: {}
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'OrderNotificationDetails'
            },
            loading: false,
            templates: [],
            modelValidations: {},
            defaultTemplate: '',
            templateContent: '',
            templateSubject: '',
            variables: [],
            debugEnabled: false,
            debugOrderId: null,
            debugOrderProductId: null,
            currentOrderProductId: null,
            sendersEmail: [
                'assistenza@dimensione.com'
            ],
            currentSender: 'assistenza@dimensione.com',
            currentAttachments: [],
            availableAttachments: [
                'contract'
            ]
        }
    },
    watch: {
        debugEnabled() {
            this.loadResources()
        },
        'order': function () {
            if (!this.order.id) return
            this.defaultTemplate = ''
            this.templateSubject = ''
            this.templateContent = ''
        }
    },
    computed: {
        idDisabledSend: function () {
            if (this.templateContent !== '') return false
            return true
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName)
        },
        loadTemplates() {
            this.loading = true
            let params = {}
            if (this.isDeveloper && this.debugEnabled) {
                params = Object.assign({}, params, {
                    type_filter: null,
                    force_reload: true,
                    order_direction: 'asc',
                    orderby: 'name'
                })
            }
            this.$store.dispatch('notifications/emails/getTemplates', params).then((response) => {
                this.loading = false
                this.templates = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showResponseErrorMessage(e)
            })
        },
        handleTemplateChange() {
            let pTemplateId = this.defaultTemplate
            let pOrderProductId = this.currentOrderProductId
            let pOrderId = this.order.id
            if (this.isDeveloper && this.debugEnabled && this.debugOrderProductId) {
                pOrderProductId = this.debugOrderProductId
            }
            if (this.isDeveloper && this.debugEnabled && this.debugOrderId) {
                pOrderId = this.debugOrderId
            }
            this.loadSelectedTemplate(pTemplateId, pOrderId, pOrderProductId)
        },
        loadSelectedTemplate(pTemplateId, pOrderId, pOrderProductId = null) {
            this.loading = true
            this.$store.dispatch('notifications/emails/getTemplateContentForOrder', {
                id: pTemplateId,
                order_id: pOrderId,
                order_product_id: pOrderProductId
            }).then((response) => {
                this.loading = false
                this.templateContent = response.data.data.content
                this.templateSubject = response.data.data.subject
                this.currentAttachments = response.data.data.default_attachments
            }).catch(e => {
                this.loading = false
                this.showResponseErrorMessage(e)
            })
        },
        handleSendButton() {
            this.askGenericConfirm('Vuoi inviare questa notifica?', 'Premendo Sì confermerai l\'invio della notifica', 'Sì, invia!', 'No, ho sbagliato!').then((result) => {
                if (!result.value) {
                    return
                }
                this.sendNotification(this.defaultTemplate, this.order.id, this.templateContent, this.templateSubject, this.currentSender, this.currentAttachments)
            })
        },
        sendNotification(pTemplateId, pOrderId, pTemplateContent, pSubject, pSender, attachments) {
            this.loading = true
            this.$store.dispatch('notifications/emails/sendMail', {
                id: pTemplateId,
                orderId: pOrderId,
                templateContent: pTemplateContent,
                customerId: this.order.customer.id,
                subject: pSubject,
                sender_email: pSender,
                attachments: attachments
            }).then((response) => {
                this.loading = false
                this.defaultTemplate = ''
                this.templateSubject = ''
                this.templateContent = ''
                this.$store.commit('orders/updateChronologyEvents')
                this.showGenericSuccess('Notifica', 'inviata con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'invio del template')
            })
        },
        loadResources() {
            this.loadTemplates()
        },
        getFormattedAttachment(attachment) {
            switch (attachment) {
                case 'contract':
                    return 'Contratto'
                default:
                    return 'Allegato non riconosciuto'
            }
        }
    },
    created: function () {
        this.loadResources()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
