<template>
  <div id="activationStatisticsResultRow" class="row pb-0">
    <div class="col-12">

      <div class="row">
        <div class="col-12">
          <el-collapse @change="handleHasChanged">
            <el-collapse-item title="Statistiche giornaliere" name="totalStatistics">

              <div class="col-12 mt-2 px-0">
                <el-card title="Statistiche giornaliere" subTitle="Attivazioni totali suddivise per giorno">
                  <div id="dailyActivationsChart" class="ct-chart"></div>
                </el-card>
              </div>

              <div class="col-12 px-0">
                <el-table id="dailyActivationsTable" class="table-striped myDailyStatisticTable mt-2" :data="statistics.daily" border>
                  <el-table-column
                    label="Data"
                    width="120%">
                    <template #default="scope">
                      {{ dateToItalian(scope.row.date, false, 'DD/MM/YYYY') }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="count"
                    label="Totali">
                  </el-table-column>
                </el-table>
              </div>

            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div id="generalActivationsStatisticsRow" class="row">
        <div class="col-12">
          <el-collapse style="font-size:30px;">
            <el-collapse-item title="Statistiche generali" name="statusStatistics">
              <el-card class="col-12 mt-2 px-0" title="Statistiche generali" subTitle="Attivazioni totali e attivazioni suddivise per tipologia">

                <ul class="list-group list-group-flush">
                  <li id="generalActivationsTable" class="list-group-item flex-column justify-content-between align-items-center myGeneralStatisticTable"
                      v-for="(orderType, index) in statistics.totals">
                    <div class="w-100 justify-content-between">
                      {{orderType.name}}
                      <small class="text-muted" v-if="index !== '0'">ID:{{index}}</small>
                      <span class="badge badge-pill badge-primary float-right">{{orderType.count}}</span>
                    </div>
                  </li>
                </ul>

              </el-card>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

    </div><!-- end of col -->
  </div><!-- end of row -->

</template>

<style>
  .badge-pill {
    padding-left: 1em;
    padding-right: 1em;
  }
  .badge-grey {
    background-color: #e6e6e6;
  }

  @media (min-width: 1200px) {
    #dailyActivationsTable.myDailyStatisticTable {
      width: 80% !important;
    }
  }

  @media (max-width: 992px) {
    #dailyActivationsTable.myDailyStatisticTable {
      width: 100% !important;
    }
  }

  @media (min-width: 992px) {
    #generalActivationsTable.myGeneralStatisticTable {
      width: 52% !important;
    }
  }

  @media (max-width: 992px) {
    #generalActivationsTable.myGeneralStatisticTable {
      width: 100% !important;
    }
  }

  #generalActivationsStatisticsRow .el-collapse-item__content {
    font-size: 16px;
  }
</style>

<script>
  import Chartist from 'chartist'
  import ChartistTooltip from 'chartist-plugin-tooltips-updated'

  export default {
    components: {
      Chartist,
      ChartistTooltip
    },
    props: ['statistics'],
    data () {
      return {
        dataStock: null,
        optionsStock: null,
        activationsNumber: [],
        dates: [],
        isOpened: false
      }
    },
    watch: {},
    computed: {},
    methods: {
      initActivationsForDayChart () {
        for (let i = 0; i < this.statistics.daily.length; i++) {
          let dateToIta = this.dateToItalian(this.statistics.daily[i].date, false, 'DD/MM/YYYY')
          this.dates[i] = dateToIta
          this.activationsNumber[i] = {meta: dateToIta, value:this.statistics.daily[i].count}
        }

        this.dataStock = {
          labels: this.dates,
          series: [this.activationsNumber]
        }
        this.optionsStock = {
          lineSmooth: true,
          axisY: {
            offset: 40,
            labelInterpolationFnc (value) {
              return `${value}`
            }
          },
          axisX: {
            showGrid: true,
            showLabel: false
          },
          low: 0,
          high: Math.max(this.activationsNumber),
          height: '250px',
          fullWidth: true,
          classNames: {
            point: 'ct-point ct-blue',
            line: 'ct-line ct-blue'
          },
          plugins: [
            ChartistTooltip({
              anchorToPoint: true,
              appendToBody: false
            })
          ]
        }
        Chartist.Line('#dailyActivationsChart', this.dataStock, this.optionsStock)
      },
      handleHasChanged () {
        if (!this.isOpened) {
          this.isOpened = true
        } else {
          this.isOpened = false
        }
        if (this.isOpened) {
          this.initActivationsForDayChart()
        }
      }
    },
    created: function () {
      if (this.isOpened) {
        this.initActivationsForDayChart()
      }
    },
    mounted: function () {
    },
    destroyed: function () {}
  }
</script>
