<template>
    <div>
        <el-select v-loading="loading"
                   class="select-default w-100"
                   v-model=selectedOfferIndex
                   placeholder="Seleziona un'offerta"
                   @change="updateValue($event)"
                   :multiple="multiple"
                   filterable
                   reserve-keyword
                   value-key="name">
            <el-option
                v-for="(item, key) in items"
                :key=key
                :label="item.internal_name"
                :value=key>
        <span class="float-left">{{ item.internal_name }} <span
            v-if="item.name && show_customer_name">({{ item.name }})</span></span>
                <span class="small n-lightgrey float-right" v-if="show_type">ID: {{
                        item.id
                    }} ({{ item.order_type_formatted }})</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>


export default {
    name: "OffersSelect",
    components: {},
    data() {
        return {
            loading: false,
            index: null,
            items: [],
            selectedOfferIndex: null,
            selectedOffer: null
        }
    },
    watch: {
        'offertype': function () {
            this.loadItems();
        }
    },
    props: {
        value: {
            default: null
        },
        offertype: {
            type: String,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        show_type: {
            type: Boolean,
            default: true
        },
        show_customer_name: {
            type: Boolean,
            default: true
        },
        only_id: false
    },
    mounted() {
        this.loadItems()
        if (this.multiple) {
            this.selectedOffer = []
        }
    },
    methods: {
        loadItems() {
            this.loading = true
            let params = {limit: -1};
            if (this.offertype) {
                params = {order_type: this.offertype, limit: -1, orderby: 'internal_name'};
            }
            this.$store.dispatch('offers/list', params).then((response) => {
                this.loading = false
                this.items = response.data.data
            }).catch(e => {
                this.loading = false
                console.log(e)
                this.showResponseErrorMessage(e)
            })
        },
        updateValue: function (item) {

            if (Array.isArray(item)) {
                item.forEach(index => {
                    if (this.only_id) {
                        this.selectedOffer.push(this.items[index].id)
                    } else {
                        this.selectedOffer.push(this.items[index])
                    }
                });
            } else {
                if (this.only_id) {
                    this.selectedOffer = this.items[item].id;
                } else {
                    this.selectedOffer = this.items[item];
                }
            }
            this.$emit('update:modelValue', this.selectedOffer)
        }

    }
}
</script>

<style scoped>

</style>
