<template>
    <div>
        <el-form label-position="top">
            <div class="row">
                <div class="col-12">
                    <el-form-item label="Titolo SMS" prop="name">
                        <el-input v-model="sms_template.name"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row" v-if="isDeveloper">
                <div class="col-12 col-md-6">
                    <el-form-item label="Eliminazione bloccata? (Dev only)">
                        <el-switch v-model="sms_template.locked"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Variabili" class="mb-0">
                        <div class="d-flex">
                            <el-select v-loading="loading" v-model="currentVariable" placeholder="Seleziona..."
                                       @change="doCopyVariable">
                                <el-option-group
                                    v-for="(value, key) in template_variables"
                                    :key="key"
                                    :label="key"
                                    style="text-transform: uppercase">
                                    <el-option
                                        v-for="item in value"
                                        :key="item.key"
                                        :label="item.description"
                                        :value="item.key"
                                        style="text-transform: initial">
                                    </el-option>
                                </el-option-group>
                            </el-select>

                            <div v-if="currentVariable" class="ml-2">
                                <el-button @click.prevent="appendToEditor(currentVariable)" type="primary">
                                    Inserisci nel template
                                </el-button>
                            </div>
                        </div>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-checkbox v-model="copyVariable">Copia variabile</el-checkbox>
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <el-form-item label="Corpo SMS" prop="content">
            <textarea name="content"
                      style="height: 200px"
                      class="form-control"
                      v-model.trim="sms_template.content"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Note interne sull'uso di questa notifica" prop="note">
                        <rich-text-editor v-model="sms_template.note" class="w-100"/>
                    </el-form-item>
                </div>
            </div>


            <div class="row mt-4">
                <div class="col-12">
                    <el-button type="danger" @click.prevent="deleteElement"
                               v-if="sms_template.id && !sms_template.locked">
                        Elimina
                    </el-button>
                    <el-button type="primary" @click.prevent="validateForm">
                        Salva
                    </el-button>
                </div>
            </div>

            <div class="clearfix"></div>
        </el-form>
    </div>
</template>

<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from '../../../../components'
import Editor from '@tinymce/tinymce-vue'
import RichTextEditor from "@/components/RichTextEditor.vue";

export default {
    components: {
        RichTextEditor,
        LSwitch,
        LCheckbox,
        'editor': Editor
    },
    props: {
        sms_template: {}
    },
    data() {
        return {
            componentSettings: {
                listPath: '/notifications/sms/lista'
            },
            content: '',
            loading: false,
            modelValidations: {
                name: {
                    required: true
                },
                content: {
                    required: true
                }
            },
            currentVariable: '',
            copyVariable: true,
            template_variables: {}
        }
    },
    watch: {
        sms_template() {
            if (this.sms_template.id) {
                this.loadVariables(this.sms_template.id)
            }
        }
    },
    computed: {},
    methods: {
        deleteElement() {
            this.$emit('delete', this.sms_template)
        },
        doCopyVariable: function () {
            if (!this.copyVariable) return
            this.$copyText(this.currentVariable).then((e) => {
            }).catch(e => {
            })
        },
        appendToEditor(pVariableToAppend) {
            this.sms_template.content = this.sms_template.content + ' ' + pVariableToAppend
        },
        validateForm() {
            this.$emit('update', this.sms_template)
        },
        loadVariables(pTemplateId = null) {
            this.loading = true
            this.$store.dispatch('notifications/getVariables', {
                id: pTemplateId,
                notification_type: 2
            }).then((response) => {
                this.loading = false
                this.template_variables = response.data.template_variables
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle variabili')
            })
        }
    },
    created: function () {
        if (this.sms_template.is_new) {
            this.loadVariables()
        }
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>

<style></style>
