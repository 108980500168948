<template>
<div>
  <ul class="active-users m-0">
    <transition-group name="active-users" mode="out-in">
      <div v-for="user in active_users" :key="user.id" class="photo active-user">
        <div v-bind:style="avatarCSS(user)" class="active-user-image"></div>
      </div>
    </transition-group>
  </ul>
</div>
</template>

<script>
export default {
  name: "CurrentActiveUsers",
  props: {
    active_users: {
      default: null,
      require: true
    }
  },
  methods: {
    avatarCSS(pUser) {
      let urlAvatar = pUser.profile_pic ? pUser.profile_pic : '/static/img/avatar.jpg'
      return 'background-image: url(\'' + urlAvatar + '\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 36px; width: 36px;'
    },
  }
}
</script>

<style scoped>

</style>
