<template>
    <div>
        <el-form :model="offer" ref="offerForm" label-position="top">
            <div class="row">
                <div class="col-12 col-md-6">
                    <el-form-item prop="name" label="Nome">
                        <el-input type="text"
                                  label="Nome"
                                  v-model="offer.name"
                                  name="name">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item prop="internal_name" label="Nome Interno">
                        <el-input type="text"
                                  label="Nome Interno"
                                  v-model="offer.internal_name"
                                  name="internal_name">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">

                    <el-form-item prop="order_type" label="Tipologia">
                        <el-select v-loading="loading"
                                   v-model="offer.order_type"
                                   placeholder="Seleziona..." style="width: 100%">
                            <el-option
                                v-for="item in types"
                                class="select-default"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="order_type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <small v-if="!offer.order_type" class="text-danger">Il campo è richiesto.</small>
                </div>


                <div class="col-12 col-md-3">
                    <el-form-item prop="tipo_clientela" label="Tipo utenza">
                        <el-select v-loading="loading" style="width: 100%" v-model="offer.tipo_clientela"
                                   placeholder="Seleziona...">
                            <el-option
                                v-for="item in userTypes"
                                class="select-default"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="tipo_clientela">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-3">
                    <el-form-item prop="vat_included" label="Costi IVA Inclusa">
                        <l-switch v-model="offer.vat_included" type="primary" on-text="SÌ" off-text="NO"/>
                    </el-form-item>
                </div>
            </div>

            <hr>

            <div class="row" v-if="offer.id">
                <div class="col-12 col-md-6">
                    <el-form-item prop="name" label="Aggiungi opzioni compatibili">
                        <el-select v-loading="loading" style="width: 100%"
                                   v-model="currentOfferOption"
                                   placeholder="Seleziona..." filterable>
                            <el-option
                                v-for="item in offerOptions"
                                :key="item.id"
                                :label="item.id + ' - ' + item.internal_name"
                                :value="item.id">
                                <span style="float: left">{{ item.internal_name }}</span>
                                <span style="float: right;" class="small n-lightgrey">{{ item.id }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="col-12 col-md-6" v-if="currentOfferOption">
                    <label>&nbsp;</label><br/>
                    <button type="submit" class="btn btn-primary btn-outline btn-fill"
                            @click.prevent="addOfferOption(offer.id, currentOfferOption)">
                        Aggiungi opzione <i class="plus"></i>
                    </button>
                </div>
            </div>

            <div class="row" v-if="offer.options && offer.options.length > 0 && offer.id">
                <div class="col-12">
                    <h4 class="">Opzioni aggiunte</h4>
                    <ul class="list-group">
                        <li class="list-group-item" v-for="(item) in offer.options">
                            <div class="d-flex align-items-center">
                                <small class="text-muted mr-2"> (ID: {{ item.id }})</small>
                                <div>
                                    {{ item.name }}<small v-if="item.internal_name"> - {{ item.internal_name }}</small>
                                </div>
                                <div class="d-flex align-items-center ml-auto">
                                    <el-switch @change="setIncluded(item)" v-model="item.included" active-text="Inclusa"
                                               class="mr-4"/>
                                    <el-button size="small" type="danger" icon="close"
                                               @click="deleteOfferOption(offer.id, item.id)">Rimuovi
                                    </el-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4">
                    <el-form-item prop="canone_mensile" label="Canone mensile listino">
                        <el-input-number controls-position="right" v-model="offer.canone_mensile" class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item prop="canone_mensile_scontato" label="Canone mensile scontato">
                        <el-input-number controls-position="right" v-model="offer.canone_mensile_scontato"
                                         class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item label="Periodo sconto" prop="periodo_sconto">
                        <el-input v-model="offer.periodo_sconto"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Canone mensile (stringa PDA)" prop="canone_mensile_pda">
                        <el-input v-model="offer.canone_mensile_pda"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <el-form-item label="Costo installazione (stringa PDA)" prop="costo_installazione_pda">
                        <el-input v-model="offer.costo_installazione_pda"/>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <el-form-item label="Costo attivazione (stringa PDA)" prop="costo_attivazione_pda">
                        <el-input v-model="offer.costo_attivazione_pda"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Banda minima garantita (stringa PDA)" prop="banda_minima_garantita_pda">
                        <el-input v-model="offer.banda_minima_garantita_pda"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-3">
                    <el-form-item prop="installation_amount" label="Costo installazione listino">
                        <el-input-number controls-position="right" v-model="offer.installation_amount" class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-3">
                    <el-form-item prop="installation_amount_discounted" label="Costo installazione scontato">
                        <el-input-number controls-position="right" v-model="offer.installation_amount_discounted"
                                         class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-3">
                    <el-form-item prop="activation_amount" label="Costo attivazione">
                        <el-input-number controls-position="right" v-model="offer.activation_amount" class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-3">
                    <el-form-item prop="activation_amount_discounted" label="Costo attivazione scontato">
                        <el-input-number controls-position="right" v-model="offer.activation_amount_discounted"
                                         class="w-100"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4">
                    <el-form-item label="Obbligo contrattuale" prop="obbligo_contrattuale">
                        <el-select v-loading="loading"
                                   v-model="offer.obbligo_contrattuale" placeholder="Seleziona..." filterable
                                   class="w-100">
                            <el-option
                                v-for="item in obbligoContrattuale"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                name="obbligo_contrattuale">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item prop="costo_attivazione_nuova_linea" label="Costo attivazione nuova linea">
                        <el-input-number controls-position="right" v-model="offer.costo_attivazione_nuova_linea"
                                         class="w-100"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                    <el-form-item prop="costo_attivazione_passaggio" label="Costo attivazione passaggio">
                        <el-input-number controls-position="right" v-model="offer.costo_attivazione_passaggio"
                                         class="w-100"/>
                    </el-form-item>
                </div>
            </div>

            <hr/>

            <div class="row">
                <div class="col-12">
                    <label>Sistemi di pagamento accettati</label>
                </div>
            </div>

            <div class="row">

                <div class="col-md-3 col-6">
                    <label>Mostra "a partire da" nel prezzo</label>
                    <br/>
                    <el-switch v-model="offer.price_starting_from" size="large" active-text="SÌ" inactive-text="NO"
                               inline-prompt></el-switch>
                </div>
            </div>

            <div class="row">

                <div class="col-md-3 col-6">
                    <label>SDD</label>
                    <br>
                    <el-switch v-model="offer.sdd" size="large" active-text="SÌ" inactive-text="NO"
                               inline-prompt></el-switch>
                </div>

                <div class="col-md-3 col-6 d-none">
                    <label>CDC</label>
                    <br/>
                    <el-switch v-model="offer.cdc" size="large" active-text="SÌ" inactive-text="NO"
                               inline-prompt></el-switch>
                </div>

                <div class="col-md-3 col-6">
                    <label>Bollettino postale</label>
                    <br/>
                    <el-switch v-model="offer.bollettino_postale" size="large" active-text="SÌ" inactive-text="NO"
                               inline-prompt></el-switch>
                </div>

                <div class="col-md-3 col-6">
                    <label>Bonifico bancario</label>
                    <br/>
                    <el-switch v-model="offer.bank_transfer" size="large" active-text="SÌ" inactive-text="NO"
                               inline-prompt></el-switch>
                </div>
            </div>

            <hr/>

            <div class="row" v-if="offer.id">
                <div class="col-12">
                    <label style="margin-bottom: 0;">Gestisci allegati <a class="btn-simple btn-link"
                                                                          @click="dialogEmailAttachment"><i
                        class="fa fa-edit"
                        style="font-size: 14px;"></i></a></label>
                </div>
            </div>

            <el-dialog title="Allegati associati all'offerta" v-model="modalAttachmentsVisibile"
                       id="dialogAttachment">
                <div v-if="email_attachments.length == 0" class="mb-5">Ancora non ci sono allegati associati a questa
                    offerta.
                </div>
                <ul class="mb-5">
                    <li v-for="(item) in email_attachments" class="mb-3">
                        <span>Percorso:</span> <strong style="font-size: 110%">{{ item.form_template_path }}</strong> <a
                        data-tooltip="'Elimina allegato'" class="btn-danger btn-simple btn-link"
                        @click="deleteEmailAttachment(item)"><i class="fa fa-times"></i></a>
                        <br/>
                        <span>Notifica abbinata:</span> <strong>{{ item.email_template.name }}</strong> <small
                        class="n-lightgrey">
                        (ID template: {{ item.template_id }})</small>
                    </li>
                </ul>


                <h5>Aggiungi nuovo allegato</h5>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label>Percorso dell'allegato</label>
                        <br/>
                        <el-select v-loading="loading" style="width: 100%" filterable class="select-default mb-2"
                                   v-model="newAttachment.template_path" placeholder="Seleziona..." size="large">
                            <el-option
                                v-for="item in attachment_template_paths"
                                class="select-default"
                                :key="item"
                                :label="item"
                                :value="item"
                                name="template_path">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <label>Template email a cui collegare</label>
                        <br/>
                        <el-select v-loading="loading" style="width: 100%" filterable class="select-default mb-4"
                                   v-model="newAttachment.email_template_id" placeholder="Seleziona..." size="large">
                            <el-option
                                v-for="item in email_templates"
                                class="select-default"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                name="tipo_clientela">
                            </el-option>
                        </el-select>
                    </div>
                </div>


                <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="addAttachment">
                    Aggiungi allegato
                </button>
            </el-dialog>

            <div class="row">
                <div class="col-12">
                    <label>Note offerta riportate al cliente</label>
                    <br/>
                    <quill-editor class="d-inline-block w-100" style="height: 200px;" v-model="offer.note"/>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 text-right">
                    <el-button class="mr-2 text-danger" icon="delete" @click.prevent="deleteElement"
                               v-if="offer.id">Elimina
                    </el-button>
                    <el-button type="primary" icon="check" @click.prevent="validateForm">
                        Salva
                    </el-button>

                </div>
            </div>
        </el-form>
    </div>
</template>
<style>
</style>
<script>
import {Switch as LSwitch} from 'src/components/index'
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        LSwitch,
        'editor': Editor
    },
    props: {
        offer: {}
    },
    data() {
        return {
            componentSettings: {
                listPath: '/offerte/lista'
            },
            content: '',
            loading: false,
            modelValidations: {
                canone_mensile: {
                    required: true,
                    decimal: true
                },
                id_tariffa: {
                    required: true
                },
                obbligo_contrattuale: {
                    required: true
                }
            },
            rules: {
                id_prodotto: [
                    {required: true, message: 'Il campo è richiesto.', trigger: 'change'}
                ],
                id_tariffa: [
                    {required: true, message: 'Il campo è richiesto.', trigger: 'change'}
                ]
            },
            types: [],
            currentOfferOption: [],
            offerOptions: [],
            userTypes: [],
            integratedOffersTypes: [],
            integratedOffers: [],
            offerteRivincolo: [],
            currentIntegratedOffer: null,
            currentOffertaRivincoloConnected: null,
            obbligoContrattuale: [
                {code: '0', name: 'Nessuno'},
                {code: '12', name: '12 mesi'},
                {code: '24', name: '24 mesi'},
                {code: '36', name: '36 mesi'},
                {code: '48', name: '48 mesi'}
            ],
            modalAttachmentsVisibile: false,
            email_attachments: [],
            email_templates: [],
            newAttachment: {
                email_template_id: null,
                template_path: null
            },
            attachment_template_paths: []
        }
    },
    computed: {},
    methods: {
        addIntegratedOffer(pId, pIntegratedOfferId) {
            this.loading = true
            this.$store.dispatch('offers/addIntegratedOffer', {
                id: this.offer.id,
                integratedOfferId: pIntegratedOfferId
            }).then((response) => {
                this.loading = false
                this.offer.integrated_offers = response.data.integrated_offers
                this.showGenericSuccess('Offerta integrata', 'aggiunta con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'aggiunta dell\'offerta integrata', '', false)
            })
        },
        addOfferOption(pId, pOfferOptionId) {
            this.loading = true
            this.$store.dispatch('offers/addOfferOption', {
                id: this.offer.id,
                offerOptionId: pOfferOptionId
            }).then((response) => {
                this.loading = false
                this.offer.options = response.data.offer_options
                this.showGenericSuccess('Opzione', 'aggiunta con successo')
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'aggiunta dell\'opzione', '', false)
            })
        },
        deleteElement() {
            this.askDeleteMessage().then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('offers/delete', this.offer).then(() => {
                    this.$message.success("Offerta eliinata.")
                    this.$router.push(this.componentSettings.listPath)
                }).catch((e) => {
                    this.$message.error("Errore durante l'eliminazione dell'offerta")
                }).finally(() => {
                    this.loading = false
                })
            })
        },
        deleteIntegratedOffer(pId, pIntegratedOfferId) {
            this.askDeleteMessage('l\'offerta integrata selezionata').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('offers/deleteIntegratedOffer', {
                    id: this.offer.id,
                    integratedOfferId: pIntegratedOfferId
                }).then((response) => {
                    this.loading = false
                    this.offer.integrated_offers = response.data.integrated_offers
                    this.showGenericSuccess('Offerta integrata', 'eliminata con successo')
                }).catch(e => {
                    this.loading = false
                    this.showLoadingError(e, 'l\'eliminazione dell\'offerta integrata', '', false)
                })
            })
        },
        deleteOfferOption(pId, pOfferOptionId) {
            this.askDeleteMessage('l\'opzione selezionata').then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('offers/deleteOfferOption', {
                    id: this.offer.id,
                    offerOptionId: pOfferOptionId
                }).then((response) => {
                    this.loading = false
                    this.offer.options = response.data.offer_options
                    this.showGenericSuccess('Opzione', 'eliminata con successo')
                }).catch(e => {
                    this.loading = false
                    this.showLoadingError(e, 'l\'eliminazione dell\'opzione', '', false)
                })
            })
        },
        loadOtherResources() {
            this.loadOptions()
            this.loadTypes()
            this.loadUserTypes()
        },
        loadOptions() {
            this.loading = true
            this.$store.dispatch('offerOption/list', {
                limit: -1,
                orderby: 'id',
                order_direction: 'desc'
            }).then((response) => {
                this.loading = false
                this.offerOptions = response.data.offer_options
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle opzioni')
            })
        },
        loadTypes() {
            this.loading = true
            this.$store.dispatch('offers/getTypes').then((response) => {
                this.loading = false
                this.types = response // in cache
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle tipologie')
            })
        },
        loadResources() {
            this.loadOtherResources();
        },
        loadUserTypes() {
            this.loading = true
            this.$store.dispatch('offers/getUserTypes').then((response) => {
                this.loading = false
                this.userTypes = response // in cache
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento dei tipi di utenza')
            })
        },
        dialogEmailAttachment() {
            this.modalAttachmentsVisibile = true
            console.log(this.email_attachments)
            this.loadEmailAttachments()
            this.loadEmailTemplates()
            this.loadAttachmentTemplatePaths()
        },
        loadEmailAttachments() {
            this.loading = true
            this.$store.dispatch('offers/getEmailAttachments', this.offer.id).then((response) => {
                this.email_attachments = response.data.email_attachments
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento degli allegati')
            })
        },
        deleteEmailAttachment(pAttachment) {
            console.log(pAttachment)
            this.loading = true
            this.$store.dispatch('offers/deleteEmailAttachments', {
                attachment: pAttachment,
                offerId: this.offer.id
            }).then((response) => {
                this.loading = false
                this.loadEmailAttachments()
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'eliminazione dell\'allegato')
            })
        },
        loadAttachmentTemplatePaths() {
            this.loading = true
            let params = {}
            this.$store.dispatch('preorderForms/getAttachmentTemplatePaths', params).then((response) => {
                this.loading = false
                this.attachment_template_paths = response.data.template_paths
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei template email')
            })
        },
        loadEmailTemplates() {
            this.loading = true
            let params = {}
            this.$store.dispatch('notifications/emails/getTemplates', params).then((response) => {
                this.loading = false
                this.email_templates = response // In questo caso il risultato è sotto cache e quindi è solo "response" - vedi vuex module
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento dei template email')
            })
        },
        addAttachment() {
            this.loading = true
            this.$store.dispatch('offers/addEmailAttachments', {
                attachment_template_path: this.newAttachment.template_path,
                template_id: this.newAttachment.email_template_id,
                offerId: this.offer.id
            }).then((response) => {
                this.loading = false
                this.showGenericSuccess('Allegato', 'aggiunto con successo')
                this.loadEmailAttachments()
                this.newAttachment.email_template_id = null
                this.newAttachment.template_path = null
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiunta dell\'allegato')
            })
        },
        validateForm() {
            this.loading = true
            if (this.offer.id) {

                this.$store.dispatch('offers/edit', this.offer).then((response) => {
                    this.loading = false
                    this.$emit('input', response.data.data)
                    this.showSuccessMessage('Offerta aggiornata con successo')
                }).catch(e => {
                    this.loading = false
                    this.showResponseErrorMessage(e)
                })
            } else {
                this.$store.dispatch('offers/add', this.offer).then((response) => {
                    this.loading = false
                    this.offer = response.data.offer
                    this.showSuccessMessage('Offerta create con successo')
                    this.$router.push('/offerte/lista/modifica/' + response.data.data.id)
                }).catch(e => {
                    this.loading = false
                    this.showResponseErrorMessage(e)
                })
            }
        },
        setIncluded(item) {
            this.$store.dispatch('offers/setOfferOptionRelationIncluded', {
                offer_id: this.offer.id,
                option_id: item.id,
                included: item.included
            }).then((response) => {
                this.$message.success("Aggiornato.")
            }).catch(e => {
                this.$message.error("Errore durante l'aggiornamento.")
            })
        }
    },
    created: function () {
        this.loadResources()
        if (!this.offer.id) {
            this.offer.sdd = true;
            this.offer.price_starting_from = false;
            this.offer.bollettino_postale = true;
            this.offer.bank_transfer = true;
            this.offer.vat_included = true;
        }
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style>
#dialogAttachment .el-dialog__body {
    padding-top: 20px;
}
</style>
