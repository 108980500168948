<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Ottieni statistiche ordini">
                <el-form v-loading="loading" label-position="top">

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <el-form-item prop="order_types" label="Tipologia ordini">
                                <el-select multiple style="width: 100%"
                                           v-model="formData.order_types"
                                           placeholder="Tutte le tipologie">
                                    <el-option v-for="(type, index) in orderTypes" class="select-default"
                                               :value="type.code"
                                               :label="type.name" :key="index">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <el-form-item prop="origin" label="La provenienza è uguale a">
                                <partner-select v-model="formData.origin"/>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <el-form-item label="Inseriti">
                                <el-date-picker
                                    v-model="orderDateFromTo"
                                    type="daterange"
                                    start-placeholder="Dal giorno"
                                    end-placeholder="al giorno"
                                    format="DD-MM-YYYY"
                                    value-format="YYYY-MM-DD HH:mm:ss"
                                    :shortcuts="pickerOptions.shortcuts"
                                    @change="changedInsertedOrderDate"
                                    language="it">
                                </el-date-picker>
                            </el-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <el-form-item label="Attivati">
                                <el-date-picker
                                    v-model="orderActivatedFromTo"
                                    type="daterange"
                                    start-placeholder="Dal giorno"
                                    end-placeholder="al giorno"
                                    format="DD-MM-YYYY"
                                    value-format="YYYY-MM-DD HH:mm:ss"
                                    :shortcuts="pickerOptions.shortcuts"
                                    @change="changedActivationDate"
                                    language="it">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <el-form-item label="Filtra per">
                                <el-select v-loading="loading" style="width: 100%" class="select-default"
                                           v-model="currentState.sortBy" placeholder="Seleziona...">
                                    <el-option
                                        v-for="item in componentSettings.sortByItems"
                                        class="select-default"
                                        :key="item.key"
                                        :label="item.value"
                                        :value="item.key"
                                        name="sortBy">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="col-12 col-md-6" v-if="currentState.sortBy === 'offers'">
                            <el-form-item label="Seleziona offerta">
                                <offers-select v-model="formData.offers" :multiple="true" :only_id="true"/>
                            </el-form-item>
                        </div>

                    </div>

                    <div class="row mt-2">

                        <div class="col-12 col-md-4 pt-0">
                            <el-form-item prop="order_activated" label="Carica solo gli ordini attivati">
                                <el-switch v-model="formData.order_activated" active-text="SÌ" inactive-text="NO"
                                           inline-prompt/>
                            </el-form-item>
                        </div>

                        <div class="col-12 col-md-4 pt-0">
                            <el-form-item prop="order_in_gestione" label="Carica solo gli ordini in gestione">
                                <el-switch v-model="formData.order_in_gestione" active-text="SÌ" inactive-text="NO"
                                           inline-prompt/>
                            </el-form-item>
                        </div>

                        <div class="col-12 col-md-4 pt-0">
                            <el-form-item prop="customer_info" label="Carica informazioni sui clienti">
                                <el-switch v-model="formData.customer_info" active-text="SÌ" inactive-text="NO"
                                           inline-prompt/>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-md-6 pt-0">
                            <el-form-item prop="order_completed" label="Escludi gli ordini incompleti">
                                <el-switch v-model="formData.order_completed" active-text="SÌ" inactive-text="NO"
                                           inline-prompt/>
                            </el-form-item>
                        </div>
                        <div class="col-12 col-md-6 pt-0">
                            <el-form-item prop="exclude_canceled" label="Escludi gli ordini annullati">
                                <el-switch v-model="formData.exclude_canceled" active-text="SÌ" inactive-text="NO"
                                           inline-prompt/>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row" v-if="orderActivatedFromTo && orderActivatedFromTo.length !== 0">
                        <div class="col-12">
                            <h4>Attenzione! Selezionando un filtro per ordini attivati non sarà possibile visualizzare
                                le statistiche giornaliere.</h4>
                        </div>
                    </div>

                    <div class="row mb-3 mt-4">
                        <div class="col-12">
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="sendForm">
                                    Calcola
                                </button>
                            </div>
                        </div>
                    </div>

                </el-form>
            </el-card>

            <el-card class="mt-2" title="Risultato statistiche ordini" v-if="statisticsResult">
                <h5 v-if="statisticsResult.totals.count === 0">Non sono disponibili statistiche basate su questi dati.
                    Prova a fare una ricerca diversa.</h5>
                <order-statistics-result v-bind:statistics="statisticsResult"
                                         v-bind:orderActivatedFromTo="orderActivatedFromTo"
                                         v-if="statisticsResult.totals.count !== 0" @filterOrigin="handleOriginClick"/>
            </el-card>
        </div>
    </div>
</template>

<script>
import {Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
import OrderStatisticsResult from './OrderStatisticsResult'
import OffersSelect from "@/components/Inputs/OffersSelect";
import PartnerSelect from "@/components/Inputs/PartnerSelect";

export default {
    name: "OrderStatistics",
    components: {
        PartnerSelect,
        OffersSelect,
        OrderStatisticsResult,
        LSwitch,
        Breadcrumb,
        BreadcrumbItem
    },
    computed: {},
    data() {
        return {
            componentSettings: {
                minimumPermissionLevel: 200,
                sortByItems: [
                    {
                        key: 'offers',
                        value: 'Offerte'
                    }
                ]
            },
            datePickerOptions: {},
            currentState: {
                sortBy: null
            },
            loading: false,
            timer: null,
            canShowView: false,
            statisticsResult: null,
            formData: {
                offers: [],
                exclude_canceled: true,
                order_completed: true,
                order_in_gestione: false,
                order_activated: false,
                origin: null
            },
            orderTypes: {},
            offers: [],
            orderDateFromTo: [],
            orderActivatedFromTo: [],
            pickerOptions: {
                shortcuts: [{
                    text: 'Ultime 24 ore',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimi 7 gg',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimo mese',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimi 2 mesi',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimi 6 mesi',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
                        return [start, end]
                    }
                }]
            }
        }
    },
    watch: {
        'currentState.sortBy': function (val) {
            this.formData.offers = []
        }
    },
    methods: {
        remoteMethodOffers(query) {
            if (query !== '' && query.length >= 3) {
                this.loading = true
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    this.loadOffers(query)
                }, 200)
            }
        },
        changedActivationDate(pActivationDate) {
            if (pActivationDate !== null || pActivationDate) {
                let interval = this.daysBetweenDates(pActivationDate[0], pActivationDate[1])
                if (interval > 180) {
                    this.showGenericError('Errore!', 'Hai selezionato un intervallo troppo lungo.<br />Puoi selezionare massimo 180 giorni.')
                    this.orderActivatedFromTo = ['00:00:00', '23:59:59']
                }
            } else {
                this.orderActivatedFromTo = []
            }
        },
        changedInsertedOrderDate(pInsertedOrderDate) {
            if (pInsertedOrderDate !== null || pInsertedOrderDate) {
                let interval = this.daysBetweenDates(pInsertedOrderDate[0], pInsertedOrderDate[1])
                if (interval > 180) {
                    this.showGenericError('Errore!', 'Hai selezionato un intervallo troppo lungo.<br />Puoi selezionare massimo 180 giorni.')
                    this.orderDateFromTo = []
                }
            }
        },
        loadOffers(pQuery) {
            this.loading = true
            let params = {limit: -1, order_direction: 'asc', orderby: 'id', enabled: true}
            if (pQuery) {
                params = Object.assign(params, {search: pQuery, search_fields: ['internal_name'], search_type: '0'})
            }
            this.$store.dispatch('offers/list', params).then((response) => {
                this.offers = response.data.offers
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingError(e)
            })
        },
        loadOrderTypes() {
            this.loading = true
            this.$store.dispatch('orders/getTypes').then((response) => {
                this.orderTypes = response.data.order_types
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingError(e)
            })
        },
        sendForm() {
            this.statisticsResult = null
            this.loading = true
            if (!this.orderDateFromTo || this.orderDateFromTo.length < 2) {
                this.formData.order_date_from = null
                this.formData.order_date_to = null
            } else {
                this.formData.order_date_from = this.orderDateFromTo[0]
                this.formData.order_date_to = this.orderDateFromTo[1]
            }

            if (!this.orderActivatedFromTo || this.orderActivatedFromTo.length < 2) {
                this.formData.order_activated_date_from = null
                this.formData.order_activated_date_to = null
            } else {
                this.formData.order_activated_date_from = this.orderActivatedFromTo[0]
                this.formData.order_activated_date_to = this.orderActivatedFromTo[1]
            }
            this.$store.dispatch('statistics/ordersStats', this.formData).then((response) => {
                this.statisticsResult = response.data.statistics
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle statistiche sugli ordini')
            })
        },
        handleOriginClick(value) {
            this.formData.origin = value
            this.sendForm()
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.loadOrderTypes()
        this.canShowView = true
        this.loading = false
    },
    mounted: function () {
    },
    destroyed: function () {
    }
}
</script>
<style></style>
