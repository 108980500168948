<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Modifica Campaga Email">
        <div slot="header">
          <h4 class="title mt-0">Modifica della campagna <strong>{{emailCampaign.name}}</strong></h4>
          <h5 v-if="!loading">Creata il: {{dateToItalian(emailCampaign.created_at)}}</h5>
          <p class="text-muted" v-if="!loading">Ultima modifica: {{dateToItalian(emailCampaign.updated_at, true)}}</p>

          <div class="row">
            <div class="col-12 col-md-3">
              <router-link :to="{path:'/campagne-email/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista Campagne Email
                </span>
                </button>
              </router-link>
            </div>
          </div>

        </div>

        <campaign-email-form v-bind:emailCampaign="emailCampaign" v-loading="loading" @update="updateForm"
                             @delete="deleteElement"></campaign-email-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import CampaignEmailForm from './CampaignEmailForm'
  import Swal from "sweetalert2";

  export default {
    components: {
      CampaignEmailForm
    },
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'CampaignEmailEdit',
          minimumPermissionLevel: 100,
          listPath: '/campagne-email/lista'
        },
        loading: false,
        canShowView: false,
        emailCampaign: {}
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('emailCampaigns/getById', this.$route.params.id).then((response) => {
          this.emailCampaign = response.data.email_campaign
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questa tariffa')
        })
      },
      updateForm (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('emailCampaigns/edit', pElement).then((response) => {
          this.loading = false
          this.emailCampaign = response.data.email_campaign
          this.showGenericSuccess('Campagna Email', 'aggiornata con successo')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questa campagna email', '', false)
        })
      },
      deleteElement (pElement) {
        Swal.fire(this.deleteMessage('questa campagna email')).then((result) => {
          if (!result.value) return
          this.$store.dispatch('emailCampaigns/delete', pElement).then(() => {
            Swal.fire('Eliminata!',
              'Stata eliminata!',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione della campagna email', '', false)
          })
        })
      }
    }
  }
</script>
