<template>
    <div id="order-step-customer">
        <div class="row mt-2">
            <div class="col-12 text-center">
                <h4 class="m-0">Indirizzo di spedizione</h4>
                <el-button v-if="customer" @click="copyFromCustomer()"><i class="fa-regular fa-copy mr-2"></i>Copia dal
                    cliente
                </el-button>
            </div>
            <div class="col-12 mt-4">
                <el-form ref="form" :model="model" label-width="180px">
                    <el-form-item label="Telefono">
                        <el-input v-model="model.telefono"></el-input>
                    </el-form-item>
                    <el-form-item label="Destinatario">
                        <el-input v-model="model.destinatario"></el-input>
                    </el-form-item>
                    <el-form-item label="Indirizzo">
                        <el-input v-model="model.indirizzo"></el-input>
                    </el-form-item>
                    <el-form-item label="Numero civico">
                        <el-input v-model="model.numero_civico"></el-input>
                    </el-form-item>
                    <el-form-item label="Presso">
                        <el-input v-model="model.presso"></el-input>
                    </el-form-item>
                    <el-form-item label="Comune">
                        <el-input v-model="model.comune"></el-input>
                    </el-form-item>
                    <el-form-item label="Provincia">
                        <province-select v-model="model.provincia"></province-select>
                    </el-form-item>
                    <el-form-item label="CAP">
                        <el-input v-model="model.cap"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="saveAndBack()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="saveAndNext()">Avanti<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import GiuridicFormSelect from "@/components/Inputs/GiuridicFormSelect";
import IdentityDocumentSelect from "@/components/Inputs/IdentityDocumentTypeSelect";
import IssuerIdentityDocumentSelect from "@/components/Inputs/IssuerIdentityDocumentSelect";

export default {
    name: "step-shipping-address",
    components: {
        IssuerIdentityDocumentSelect,
        IdentityDocumentSelect,
        GiuridicFormSelect,
        ProvinceSelect
    },
    data() {
        return {
            model: {}
        }
    },
    mounted() {
        this.model = this.modelValue
    },
    props: {
        modelValue: {
            type: Object
        },
        customer: {
            type: Object
        }
    },
    methods: {
        copyFromCustomer() {
            this.model.telefono = this.customer.mobile_phone_number
            this.model.destinatario = this.customer.first_name + ' ' + this.customer.last_name
            this.model.indirizzo = this.customer.indirizzo_residenza
            this.model.numero_civico = this.customer.numero_civico
            this.model.comune = this.customer.comune
            this.model.provincia = this.customer.provincia
            this.model.cap = this.customer.cap
        },
        saveAndNext() {
            if (!this.model.indirizzo) {
                this.showErrorMessage("Non puoi continuare senza aver inserito un indirizzo.")
                return;
            }
            this.saveShippingAddress()
            this.$emit('next')
        },
        saveAndBack() {
            if (this.model.indirizzo) {
                this.saveShippingAddress()
            }
            this.$emit('back')
        },
        saveShippingAddress() {
            this.loading = true
            this.$store.dispatch('shippings/editAddress', this.model).then((response) => {
                this.loading = false
                this.model = response.data.shipping_address
                this.$emit('input', this.model)
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento dell\'indirizzo di questo cliente')
            })
        },
    }
}
</script>

<style scoped>

</style>
