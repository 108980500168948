<template>
    <div class="d-flex flex-column border rounded-lg" v-if="editor">
        <div>
            <el-button-group class="px-2">
                <el-button @click="editor.chain().focus().toggleBold().run()" :text="!editor.isActive('bold')"
                           type="info"><i class="fa-solid fa-bold"></i></el-button>
                <el-button @click="editor.chain().focus().toggleItalic().run()" :text="!editor.isActive('italic')"
                           type="info"><i class="fa-solid fa-italic"></i></el-button>
            </el-button-group>
            <el-button-group class="ml-2 border-left px-2 d-none">
                <el-button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                           :text="!editor.isActive('heading', { level: 1 })" type="info"><i class="fa-solid fa-h1"></i>
                </el-button>
                <el-button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                           :text="!editor.isActive('heading', { level: 2 })" type="info"><i class="fa-solid fa-h2"></i>
                </el-button>
                <el-button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                           :text="!editor.isActive('heading', { level: 3 })" type="info"><i class="fa-solid fa-h3"></i>
                </el-button>
            </el-button-group>
        </div>
        <el-divider class="mt-0"/>
        <editor-content :editor="editor" class="px-2"/>
    </div>
</template>

<script>
import {Editor, EditorContent} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
    components: {
        EditorContent,
    },

    data() {
        return {
            editor: null,
        }
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML())

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>

<style>
.ProseMirror:focus {
    outline: none;
}
</style>
