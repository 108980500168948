<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Visualizzazione ordini">
                <div class="table-layout-container">

                    <div class="row mb-2">
                        <div class="col-12 col-md-4">
                            <label>Tipologia ordini</label>
                            <br/>
                            <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                                       v-model="currentState.typeFilter"
                                       placeholder="..." size="large" @change="findOrderType">
                                <el-option :key=0 label="Tutti" :value=0></el-option>
                                <el-option
                                    v-for="item in types"
                                    class="select-default"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <h4 style="font-weight: 400 !important; text-align: center">Stai visualizzando gli ordini con
                        tipologia:<br/><strong>{{ currentState.currentOrderType }}</strong></h4>
                    <hr/>
                    <div class="row">
                        <div class="col-12" v-loading="loading">
                            <ul v-for="cStatus in statusesData">
                                <li v-if="cStatus.child_statuses && cStatus.child_statuses.length > 0 && !cStatus.hidden">
                                    <strong>{{ cStatus.internal_name }}</strong><span v-if="cStatus.countable"> (<span
                                    v-html="colorNumbers(getTotalOrdersInStatusByType(cStatus, currentState.typeFilter, currentState.paidFilter, true))"></span>)</span>
                                    <br/>
                                    <ul>
                                        <li v-for="(childStatus, index) in cStatus.child_statuses">
                                            <router-link :to="getLink(childStatus.id)" class="dark-link">{{
                                                    childStatus.internal_name
                                                }}
                                            </router-link>
                                            <span v-if="childStatus.countable"> (<router-link
                                                :to="getLink(childStatus.id)" class="dark-link">
                        <span
                            v-html="colorNumbers(getTotalOrdersInStatusByType(childStatus, currentState.typeFilter, currentState.paidFilter, false))"></span></router-link>)</span>
                                        </li>
                                    </ul>
                                </li>
                                <li v-if="(!cStatus.child_statuses || cStatus.child_statuses.length < 1) && !cStatus.hidden">
                                    <router-link :to="getLink(cStatus.id)" class="dark-link">
                                        <strong>{{ cStatus.internal_name }}</strong>
                                    </router-link>
                                    <span v-if="cStatus.countable"> (<span
                                        v-html="colorNumbers(getTotalOrdersInStatusByType(cStatus, currentState.typeFilter, currentState.paidFilter, false))"></span>)</span>
                                </li>
                                <hr/>
                            </ul>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
import Card from "@/components/Cards/Card";

export default {
    components: {
        Card,
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
    },
    computed: {},
    data() {
        return {
            componentSettings: {
                enableMultipleSelect: false,
                myComponentName: 'OrdersStatusesList',
                minimumPermissionLevel: 200
            },
            loading: false,
            statusesData: [],
            canShowView: false,
            currentState: {
                typeFilter: 0,
                currentOrderType: 'Tutti',
                paidFilter: -1
            },
            types: []
        }
    },
    methods: {
        getSaveStateConfig() {
            return {
                'cacheKey': this.componentSettings.myComponentName,
                'saveProperties': ['currentState']
            }
        },
        getLink(pStatus) {
            if (pStatus && this.currentState.typeFilter) {
                return {path: 'lista/tipo-stato/' + this.currentState.typeFilter + '/' + pStatus + '/' + this.currentState.paidFilter}
            }
            return {path: 'lista/stato/' + pStatus + '/' + this.currentState.paidFilter}
        },
        expandedRow(row, expanded) {
            var index = this.currentState.expandedRows.indexOf(row.id)
            if (index === -1) this.currentState.expandedRows.push(row.id)
            else this.currentState.expandedRows.splice(index, 1)

            /* if (document.getElementsByClassName('el-table__expand-icon--expanded').length > 0) { // Per bloccare ad una selezione alla volta
              if (expanded) document.getElementsByClassName('el-table__expand-icon--expanded')[0].click()
            } */
        },
        findOrderType(item) {
            let foundOrderType = null
            for (let i = 0; i < this.types.length; ++i) {
                if (this.types[i].code !== item) continue
                foundOrderType = this.types[i]
                break
            }
            if (foundOrderType !== null) {
                this.currentState.currentOrderType = foundOrderType.name
            } else {
                this.currentState.currentOrderType = 'Tutti'
            }
        },
        handleTypeChange(newType) {
            // this.updateData()
            this.currentState.currentOrderType = this.currentState.typeFilter
        },
        updateData(params) {
            // this.currentState.expandedRows = [] // --> TEST
            this.loading = true
            if (!params) params = {hidden: false}
            this.$store.dispatch('orders/statuses/listStructured', params).then((response) => {
                console.log(response)
                this.loading = false
                this.statusesData = response.data.data
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e)
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.types = this.$store.getters['orders/types']
        this.updateData()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style>
.el-table__expand-column {
    padding-left: 6px !important;
}

.el-table__expanded-cell {
    /* background-color: #fafafa !important; */
}
</style>
