import Config from '../../globalConfig'
import axios from 'axios'

const warehouse = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async restore ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, {order_product_id: pElement}, rootGetters.apiCommon)
        axios.post(Config.endpoints.warehouse.genialstore.restore, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default warehouse
