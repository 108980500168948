<template>

  <div>
    <el-table :data="chronology" v-if="chronology.length > 0" v-loading="loading" :row-class-name="tableRowClassName">
      <el-table-column prop="happened_at" width="250" label="Data">
        <template #default="scope">
          <div class="d-flex align-items-center" style="margin-left: -13px">
            <div>
              <p class="m-0"><i v-if="scope.row.type === 'outbound'" class="upload2 mr-2 text-success"/>
                <i v-else class="download mr-2 text-danger"/>
              </p>
            </div>
            <div>
              <p class="m-0">{{ dateToItalian(scope.row.happened_at, false)  }}</p>
              <p class="text-small m-0">{{timePassedFromDate(scope.row.happened_at)}}</p>
              <p class="text-small m-0">{{ scope.row.endpoint }}</p>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="request_params" label="Codice OLO" width="155">
        <template #default="scope">
          <code v-if="scope.row.request_params.OloRequestId"><a :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + scope.row.request_params.OloRequestId + '&search='" target="_blank">{{ scope.row.request_params.OloRequestId }}</a><br /></code>
          <code v-if="scope.row.request_params.OLORequestId"><a :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + scope.row.request_params.OLORequestId + '&search='" target="_blank">{{ scope.row.request_params.OLORequestId }}</a><br /></code>
          <code v-if="scope.row.request_params.OrderNumber"><a :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + scope.row.request_params.OrderNumber + '&search='" target="_blank">{{ scope.row.request_params.OrderNumber }}</a><br /></code>
          <code v-if="scope.row.request_params.IdE2E"><a :href="'https://fastwebwholesale.force.com/search/SearchResults?searchType=2&setLast=1&sbstr=' + scope.row.request_params.IdE2E + '&search='" target="_blank">{{ scope.row.request_params.IdE2E }}</a><br /></code>
        </template>
      </el-table-column>

      <el-table-column prop="state_provisioning" label="Stato" width="700">
        <template #default="scope">

          <p class="m-0 text-medium" v-if="scope.row.action_friendly_description"><strong>{{scope.row.action_friendly_description}}</strong></p>

          <!-- Nuova linea !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.ActivationType"><strong>Tipo:</strong> {{scope.row.request_params.ActivationType}} - {{scope.row.request_params.ConnectionType}}</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.BandWidth"><strong>Profilo:</strong> {{scope.row.request_params.BandWidth}} - {{scope.row.request_params.Interface}}</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.StateProvisioningCode"><strong>Stato:</strong> {{scope.row.request_params.StateProvisioning}} ({{scope.row.request_params.StateProvisioningCode}})</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.ReasonDescription"><strong>Motivo:</strong> {{scope.row.request_params.ReasonDescription}}</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.TIMReasonDescription"><strong>Motivo TIM:</strong> {{scope.row.request_params.TIMReasonDescription}}</p>

          <!-- Appuntamento !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.AppointmentDate"><strong>Appuntamento:</strong> {{dateToItalian(scope.row.request_params.AppointmentDate, false, 'DD/MM/YYYY')}}<span v-if="scope.row.request_params.TimeSlot"> - Fascia: {{scope.row.request_params.TimeSlot}}</span></p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.DateDac"><strong>DAC:</strong> {{dateToItalian(scope.row.request_params.DateDac, false, 'DD/MM/YYYY')}}<span v-if="scope.row.request_params.TimeSlot"> - Fascia: {{scope.row.request_params.TimeSlot}}</span></p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.RemodulatedExpectedDacDate"><strong>Appuntamento Rimod.:</strong> {{dateToItalian(scope.row.request_params.RemodulatedExpectedDacDate, false, 'DD/MM/YYYY')}}<span v-if="scope.row.request_params.TimeSlot"> - Fascia: {{scope.row.request_params.TimeSlot}}</span></p>

          <!-- Accodamento !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.ExpectedReleaseDate"><strong>Accodamento atteso:</strong> {{dateToItalian(scope.row.request_params.ExpectedReleaseDate, false, 'DD/MM/YYYY')}}</p>


          <!-- Attivazione !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.Cor"><strong>COR:</strong> {{scope.row.request_params.Cor}}</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.Cos"><strong>COS:</strong> {{scope.row.request_params.Cos}}</p>

          <!-- Parametri espletamento !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.raw && scope.row.request_params.raw.OvlanDataDelivery"><strong>S-VLAN:</strong> {{scope.row.request_params.raw.OvlanDataDelivery}}</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.raw && scope.row.request_params.raw.IvlanDataDelivery"><strong>C-VLAN:</strong> {{scope.row.request_params.raw.IvlanDataDelivery}}</p>
          <p class="m-0 text-medium" v-if="scope.row.request_params.raw && scope.row.request_params.raw.ONTSerialNumber"><strong>Seriale ONT:</strong> {{scope.row.request_params.raw.ONTSerialNumber}}</p>

          <!-- Errore !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.ErrorCode"><strong>Errore:</strong> {{scope.row.request_params.ErrorDescription}} ({{scope.row.request_params.ErrorCode}})</p>

          <!-- Note !-->
          <p class="m-0 text-medium" v-if="scope.row.request_params.Note"><strong>Note:</strong> {{scope.row.request_params.Note}}</p>

        </template>
      </el-table-column>


      <el-table-column width="80" fixed="right">
        <template #default="scope">
          <el-button @click="showDetails = true; detailItem = scope.row" size="small" type="primary" class="ml-auto"
                     icon="view" circle/>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="Dettagli"
      v-model="showDetails"
      v-if="detailItem"
      width="90%">
      <div class="border rounded py-4 px-4 mb-4">
        <h4 class="mt-0">Dettagli tecnici</h4>
        Tipo: <strong>{{ detailItem.type }}</strong><br />
        Endpoint: <strong>{{ detailItem.endpoint }}</strong><br />
        <span v-if="detailItem.state_provisioning_code">Provisioning Code: <strong>{{ detailItem.state_provisioning_code }}</strong></span>

      </div>
      <div class="border rounded py-4 px-4 mb-4" v-if="detailItem.request_params">
        <h4 class="mt-0"><span v-if="detailItem.type === 'inbound'">Parametri ricevuti da provider</span><span v-else>Richiesta da Dimensione vs provider</span> </h4>
        <vue-json-pretty :data="detailItem.request_params" :showLength="true" :collapsed="true"/>
      </div>
      <div class="border rounder py-4 px-4" v-if="detailItem.original_response">
        <h4 class="mt-0">Risposta ricevuta da provider</h4>
        <vue-json-pretty :data="detailItem.original_response" :showLength="true"/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetails = false" type="primary">Chiudi</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: "FastwebWholesaleChronology",
  components: {VueJsonPretty},
  props: {
    orderId: {
      required: true
    }
  },
  data: function () {
    return {
      loading: false,
      chronology: [],
      reverse: false,
      showDetails: false,
      detailItem: null
    }
  },
  created() {
    this.fetchChronology()
  },
  methods: {
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    tableRowClassName({row, rowIndex}) {
      if (row.request_params.Result === 'KO') {
        return 'error-row';
      }
      if (row.direction === 'inbound') {
        return 'inbound-row'
      }
      return 'outbound-row'
    },
    fetchChronology() {
      this.startLoading()
      this.$store.dispatch('orders/fetchFastwebWholesaleChronology', this.orderId).then((response) => {
        this.chronology = response.data.data
        this.stopLoading();
      }).catch(e => {
        console.log(e)
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.el-table .error-row {
  background: #ffefef;
}
.el-table .inbound-row {
  background: #FFFFFF;
}

.el-table .outbound-row {
  background: #f9fcff;
}
</style>
