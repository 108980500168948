<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card>
        <div class="table-layout-container">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-4 col-xl-3">
              <router-link :to="{path:'/partner/aggiungi'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-plus"></i> Aggiungi partner
                </span>
                </button>
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage"
                            :per-page="currentState.pagination.perPage" :total="currentState.pagination.total"
                            @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 col-12">
              <div class="row">
                <div class="mb-3 col-12 col-sm-12">
                  <el-select style="width: 100%" v-model="currentState.pagination.perPage"
                             placeholder="Per pagina" @change="handlePerPageChange">
                    <el-option v-for="item in componentSettings.pagination.perPageOptions"
                               :key="item" :label="item" :value="item"/>
                  </el-select>
                </div>
              </div>
            </div>
          </div>


          <div class="row">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped" :data="tableData" border :default-sort="currentState.currentSorting">
                <el-table-column v-for="column in tableColumns" :type="column.type" :fixed="column.fixed"
                                 :key="column.label" :prop="column.prop" :label="column.label" :width="column.width"/>
                <el-table-column label="Azioni" :width="80">
                  <template #default="props">
                    <div style="text-align: center" class="table-actions" v-if="props.row">
                      <router-link :to="{path:'/partner/'+ props.row.id +'/modifica'}">
                        <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link">
                          <i class="fa fa-edit"></i>
                        </a>
                      </router-link>
                      <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                         @click="handleDelete(props.row.id)">
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading">Mostro da <strong>{{ currentState.pagination.from }}</strong> a
                <strong>{{ currentState.pagination.to }}</strong> su
                <strong>{{ currentState.pagination.total }}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
import Swal from "sweetalert2";

export default {
  components: {
    LPagination,
    Breadcrumb,
    BreadcrumbItem
  },
  computed: {},
  data() {
    return {
      componentSettings: {
        searchTypeOption: 0,
        tableSort: {prop: 'id', order: 'descending'},
        enableMultipleSelect: false,
        myComponentName: 'UsersList',
        minimumPermissionLevel: 100,
        actionTablePercentWidth: 10,
        selectionTablePercentWidth: 0,
        pagination: {
          perPageOptions: [5, 10, 25, 50, 100]
        }
      },
      currentState: {
        currentSorting: {prop: 'id', order: 'descending'},
        pagination: {
          perPage: 25,
          currentPage: 1,
          lastLoadedPage: 1,
          total: 0,
          from: 0,
          to: 0,
          showedItems: 0
        },
        tableMultipleSelect: null
      },
      searchFields: [
        {searchFields: 'name', label: 'Nome'},
        {searchFields: 'email', label: 'Email'},
      ],
      searchTypeOptions: [
        {searchType: '0', label: 'Contiene'},
        {searchType: '2', label: 'Inizia per'},
        {searchType: '1', label: 'È uguale a'}
      ],
      tableColumns: [
        {
          prop: 'id',
          label: 'ID',
          percentWidth: 10,
          showTooltip: true,
          fixed: true,
          type: 'default',
          first: true,
          width: 80
        },
        {
          prop: 'name',
          label: 'Nome',
          percentWidth: 27,
          showTooltip: true,
          fixed: false,
          type: 'default'
        },
        {
          prop: 'owner_name',
          label: 'Nome Proprietario',
          percentWidth: 26,
          showTooltip: true,
          fixed: false,
          type: 'default'
        },
        {
          prop: 'origin',
          label: 'Provenienza',
          percentWidth: 26,
          showTooltip: true,
          fixed: false,
          type: 'default'
        }
      ],
      loading: false,
      tableData: [],
      canShowView: false
    }
  },
  methods: {
    getSaveStateConfig() {
      return {
        'cacheKey': this.componentSettings.myComponentName,
        'saveProperties': ['currentState']
      }
    },
    handlePerPageChange(perPage) {
      this.currentState.pagination.currentPage = 1
      this.currentState.pagination.perPage = perPage
      this.updateData()
    },
    handlePageChange(page) {
      if (page === this.currentState.pagination.lastLoadedPage) {
        return
      }

      this.currentState.pagination.currentPage = page
      this.updateData()
    },
    updateData() {
      this.loading = true
      this.$store.dispatch('partner/paginatedList', {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage}).then((response) => {
        this.tableData = response.data.data
        this.currentState.pagination.currentPage = response.data.current_page
        this.currentState.pagination.perPage = response.data.per_page
        this.currentState.pagination.from = response.data.from
        this.currentState.pagination.to = response.data.to
        this.currentState.pagination.total = response.data.total
        this.currentState.pagination.last_page = response.data.last_page
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false
      })
    },
    handleDelete(id) {
      Swal.fire(this.deleteMessage()).then((result) => {
        if (!result.value) {
          return
        }
        this.loading = true
        this.$store.dispatch('partner/delete', id).then((response) => {
          this.showSuccessMessage(`Partner eliminato con successo.`)
          this.updateData()
        }).catch(e => {
          this.showResponseErrorMessage(e)
        }).finally(() => {
          this.loading = false
        })
      });
    }
  },
  mounted: function () {
    if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
    this.canShowView = true
    this.updateData()
  },
  destroyed: function () {
    this.$off()
  }
}
</script>
<style>
</style>
