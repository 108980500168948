<template>
  <div class="col-12">
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Nome offerta</div>
      <div class="col-6">{{ offer.name }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Nome interno offerta</div>
      <div class="col-6"><strong>{{ offer.internal_name }}</strong></div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Tipologia offerta</div>
      <div class="col-6">{{ offer.order_type_formatted }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">IVA Inclusa</div>
      <div class="col-6"><span v-if="offer.vat_included">Sì</span>
        <span v-else>No</span></div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Attivazione</div>
      <div class="col-6">€ {{ offer.activation_amount }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Attivazione scontata</div>
      <div class="col-6">€ {{ offer.activation_amount_discounted }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Canone mensile</div>
      <div class="col-6">€ {{ offer.canone_mensile }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Canone mensile scontato</div>
      <div class="col-6">€ {{ offer.canone_mensile_scontato }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom" v-if="offer.periodo_sconto">
      <div class="col-4">Periodo sconto</div>
      <div class="col-6">{{ offer.periodo_sconto }} mesi</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Canone mensile (PDA)</div>
      <div class="col-6">{{ offer.canone_mensile_pda }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom">
      <div class="col-4">Installazione (PDA)</div>
      <div class="col-6">{{ offer.costo_installazione_pda }}</div>
    </div>
    <div class="row pt-1 pb-1">
      <div class="col-4">Obbligo contrattuale</div>
      <div class="col-6">{{ offer.obbligo_contrattuale }} MESI</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OfferDetails",
  data() {
    return {}
  },
  watch: {},
  props: {
    offer: {
      type: Object,
      default: null
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
