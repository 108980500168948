<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi tipo promocode">
        <promo-code-form v-bind:promo_code_type="promo_code_type" v-loading="loading" @update="updateForm"></promo-code-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import PromoCodeForm from './PromoCodeForm.vue'

  export default {
    components: {
      PromoCodeForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {}
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'PromoCodeAdd',
          minimumPermissionLevel: 100,
          listPath: '/promocode/lista'
        },
        loading: false,
        canShowView: false,
        promo_code_type: {
          show_stats: true,
          enabled: true
        }
      }
    },
    methods: {
      updateForm (pElement) {
        this.loading = true
        this.$store.dispatch('promoCodes/addPromoType', pElement).then((response) => {
          this.loading = false
          this.$router.push('/promocode/lista/modifica/' + response.data.promo_code_type.id)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questa tipologia di promocode', '', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
