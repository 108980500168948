import Config from '../../../globalConfig'
import axios from 'axios'

const emails = {
  namespaced: true,
  state: {
    mailTemplates: []
  },
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canDuplicate: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    mailTemplates: state => state.mailTemplates
  },
  mutations: {
    updateMailTemplates: (state, pValue) => {
      state.mailTemplates = pValue
    }
  },
  actions: {
    reset ({commit}) {
      commit('updateMailTemplates', [])
    },
    async edit ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateMailTemplates', [])
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.notifications.emails.base + pElement.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateMailTemplates', [])
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async duplicate ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.notifications.emails.base + pElement.id + '/duplicate', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateMailTemplates', [])
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async add ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.add, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateMailTemplates', [])
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTypes ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.notifications.emails.types, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTemplates ({commit, state, getters, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        if (getters.mailTemplates.length > 0 && !params.force_reload) {
          resolve(getters.mailTemplates)
          return
        }
        if (!params.force_reload) {
          params = {type_filter: 0, limit: -1, order_direction: 'asc', orderby: 'name'}
        } else {
          params = Object.assign({}, {limit: -1}, params)
        }
        params = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.list, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateMailTemplates', response.data.data)
            resolve(response.data.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTemplatesPaginated ({commit, state, getters, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, {type_filter: 0, order_direction: 'asc', orderby: 'name'}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.list_paginated, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateMailTemplates', response.data.data)
            resolve(response.data.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTemplatesForProductAlerts ({commit, state, getters, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign({}, {type_filter: 5, limit: -1, order_direction: 'asc', orderby: 'name'}, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data.mail_templates)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getTemplateContentForOrder ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        var parametersToSend = Object.assign({}, params, rootGetters.apiCommon)
        axios.post(Config.endpoints.notifications.emails.base + params.id, parametersToSend)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async sendMail ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        var parametersToSend = Object.assign({template_id: params.id, order_id: params.orderId, content: params.templateContent, subject: params.subject, sender_email: params.sender_email, attachments: params.attachments}, rootGetters.apiCommon)
        axios.post(Config.endpoints.emailSend.send, parametersToSend)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default emails
