  <template>

    <div class="row" v-if="canShowView">
      <div class="col-12">
        <el-card title="Lista NP">

          <div class="table-layout-container">

            <div class="row">
              <div class="col-12">
                <div></div>
                <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage" :per-page="currentState.pagination.perPage" :total="currentState.pagination.total" @input="handlePageChange"></l-pagination>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-2 col-12">
                <div class="row">
                  <div class="mb-3 col-12 col-sm-12">
                    <el-select class="select-default w-100" v-model="currentState.pagination.perPage" placeholder="Per pagina" @change="handlePerPageChange">
                      <el-option v-for="item in componentSettings.pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <div class="col-sm-10 col-12">
                <div class="row">
                  <div class="col-sm-3 col-12 mb-3">
                    <el-select multiple style="width: 100%" v-model="currentState.searchFields.selected" placeholder="Dove cerco?" @change="handleSearchFieldsChange">
                      <el-option v-for="(option, index) in searchFields" :value="option.searchFields" :label="option.label" :key="index">
                      </el-option>
                    </el-select>
                  </div>

                  <div class="col-sm-3 col-12 mb-3">
                    <el-select style="width: 100%" v-model="currentState.searchTypeSelected" placeholder="Come cercare?" @change="handleSearchTypeChange">
                      <el-option v-for="(item, index) in searchTypeOptions" :key="index" :label="item.label" :value="index"></el-option>
                    </el-select>
                  </div>

                  <div class="col-12 col-sm-4">
                    <el-input style="width: 100%" type="search" placeholder="Cerca" v-model="currentState.searchQuery" @change="handleSearchChange" addon-right-icon="fa fa-search" />
                  </div>

                  <div class="col-sm-2 col-12 mb-3 no-padding-left-desktop">
                    <button class="btn btn-outline btn-danger btn-outline col-12" style="padding: 8px 0px; width: 100%" @click="resetFilters()" >
                            <span class="btn-label">
                               <i class="fa fa-times"></i>
                            </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label>Filtra per stato</label>
                <el-select v-loading="loading" value-key="key" style="width: 100%" class="select-default mb-3" v-model="currentState.currentStatusID" placeholder="Seleziona..." @change="handleStatusChange" size="large">
                  <el-option
                    v-for="item in statuses_count"
                    class="select-default"
                    :key="item.status"
                    :label="item.name + parsedLabelText(item.count)"
                    :value="item.status">
                  </el-option>
                </el-select>
              </div>
            </div>

            <h4 v-if="currentState.currentStatusID && !currentState.searchQuery" style="font-weight: 400 !important; text-align: center">Stai visualizzando le NP in: <strong>{{currentState.currentStatusName}}</strong><a href="#" @click.prevent="handleReload" class="btn-simple btn-link a-link ml-2"><i class="refresh" style="font-size: 19px;"></i></a></h4>

            <div class="row">

              <div class="col-12" v-loading="loading">
                <el-table class="table-striped w-100" :data="tableData" border :default-sort="currentState.currentSorting" @sort-change="handleSortChange" @selection-change="handleSelectionChange">

                  <template v-for="column in tableColumns">
                    <el-table-column v-if="!column.custom && column.type !== 'selection'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label"></el-table-column>

                    <el-table-column v-else-if="column.type === 'selection'" type="selection" :width="calculateTableWidth(column)"></el-table-column>

                    <el-table-column v-else-if="column.custom === 'id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <div v-if="props.row">
                          <router-link class="dark-link" :to="{path:'/np/lista/modifica/' + props.row.id}">
                            <span class="td-strong">{{props.row.id}}</span>
                          </router-link>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column v-else-if="column.custom === 'ordine'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <div v-if="props.row && props.row.order_product">
                          <router-link class="dark-link" :to="{path:'/ordini/lista/modifica/' + props.row.order_product.order_id}">
                            <span class="td-strong" v-if="props.row && props.row.order_product">{{props.row.order_product.order_id}}</span>
                          </router-link>
                        </div>
                        <span v-if="props.row">{{props.row.name}} {{props.row.last_name}}</span>
                        <span v-if="props.row"><small style="font-size: 90%"><br />{{props.row.cf}}</small></span>
                      </template>
                    </el-table-column>

                    <el-table-column v-else-if="column.custom === 'inserted_at'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <span v-if="props.row">{{dateToItalian(props.row.inserted_at, false)}}</span>
                      </template>
                    </el-table-column>

                    <el-table-column v-else-if="column.custom === 'original_number'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <router-link class="dark-link" :to="{path:'/np/lista/modifica/' + props.row.id}" v-if="props.row && props.row.original_number">
                          <span>Portato: <strong>{{props.row.original_number}}</strong></span>
                        </router-link>
                        <span v-else>---</span>
                      </template>
                    </el-table-column>

                    <el-table-column v-else-if="column.custom === 'attivazione'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <span v-if="props.row && props.row.order_product && props.row.order_product.activation_date">{{dateToItalian(props.row.order_product.activation_date, false)}}</span>
                      </template>
                    </el-table-column>

                    <el-table-column v-else-if="column.custom === 'status_details'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <router-link class="dark-link" :to="{path:'/np/lista/modifica/' + props.row.id}">
                        <span v-if="props.row">{{props.row.status_details.name}}</span>
                        </router-link>
                      </template>
                    </el-table-column>

                    <el-table-column v-else-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                      <template #default="props">
                        <div style="text-align: center" class="table-actions" v-if="props.row">


                          <router-link :to="{path:'/np/lista/modifica/' + props.row.id}"><a class="btn-warning btn-simple btn-link" v-if="canEdit(props.$index, props.row)"><i class="fa fa-edit"></i></a></router-link>

                          <i class="fa fa-clone" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" ></i>

                          <a class="btn-danger btn-simple btn-link"
                             @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>

                          <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)" ></i>
                        </div>
                      </template>
                    </el-table-column>

                  </template>

                </el-table>
              </div>
            </div>

            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
              <div class="">
                <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da <strong>{{currentState.pagination.from}}</strong> a <strong>{{currentState.pagination.to}}</strong> su <strong>{{currentState.pagination.total}}</strong> totali</p>
              </div>
              <l-pagination class="pagination-no-border"
                            v-model="currentState.pagination.currentPage"
                            :per-page="currentState.pagination.perPage"
                            :total="currentState.pagination.total"
                            @input="handlePageChange">
              </l-pagination>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </template>
  <script>
    import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'

    export default {
      components: {
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
      },
      watch: {
      },
      computed: {},
      data () {
        return {
          componentSettings: {
            singleFieldSearch: true,
            searchTypeOption: 0,
            tableSort: {prop: 'id', order: 'descending'},
            myComponentName: 'NPList',
            minimumPermissionLevel: 200,
            pagination: {
              perPageOptions: [5, 10, 25, 50, 100]
            },
            searchFieldsDefault: ['id']
          },
          currentState: {
            currentSorting: {prop: 'id', order: 'descending'},
            searchFields: {
              selected: ['id'],
              lastSelection: ['id']
            },
            pagination: {
              perPage: 25,
              currentPage: 1,
              lastLoadedPage: 1,
              total: 0,
              from: 0,
              to: 0,
              showedItems: 0
            },
            searchQuery: '',
            searchTypeSelected: 0,
            tableMultipleSelect: null,
            currentStatusID: 7,
            currentStatusName: 'In Attesa'
          },
          searchFields: [
            {searchFields: 'id', label: 'ID'},
            {searchFields: 'order_id', label: 'ID Ordine'},
            {searchFields: 'full_name', label: 'Nome e cognome'},
            {searchFields: 'original_number', label: 'Numero portato'}
          ],
          searchTypeOptions: [
            {searchType: '0', label: 'Contiene'},
            {searchType: '2', label: 'Inizia per'},
            {searchType: '1', label: 'È uguale a'}
          ],
          tableColumns: [
            {
              prop: 'id',
              label: 'ID',
              percentWidth: 10,
              sortable: 'custom',
              showTooltip: true,
              fixed: true,
              type: 'default',
              first: true,
              custom: 'id'
            },
            {
              label: 'Ordine',
              percentWidth: 23,
              sortable: false,
              showTooltip: true,
              fixed: false,
              type: 'default',
              custom: 'ordine'
            },
            {
              prop: 'original_number',
              label: 'Numero',
              sortable: false,
              showTooltip: true,
              fixed: false,
              type: 'default',
              custom: 'original_number'
            },
            {
              label: 'Inserimento',
              percentWidth: 14,
              sortable: 'custom',
              showTooltip: true,
              fixed: false,
              type: 'default',
              custom: 'inserted_at'
            },
            {
              label: 'Attivazione',
              percentWidth: 14,
              sortable: false,
              showTooltip: true,
              fixed: false,
              type: 'default',
              custom: 'attivazione'
            },
            {
              prop: 'status_details',
              label: 'Stato',
              percentWidth: 14,
              sortable: false,
              showTooltip: true,
              fixed: false,
              type: 'default',
              custom: 'status_details'
            },
            {
              label: 'Azioni',
              percentWidth: 10,
              sortable: false,
              showTooltip: false,
              fixed: false,
              type: 'default',
              custom: 'azioni'
            }
          ],
          loading: false,
          tableData: [],
          canShowView: false,
          mnp_statuses: null,
          statuses_count: null
        }
      },
      methods: {
        canEdit (index, row) {
          let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
          if (indexToFind >= 0) {
            let elementToCheck = this.tableData[indexToFind]
            return this.$store.getters['mnp/canEdit'](elementToCheck)
          }
        },
        canDelete (index, row) {
          let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
          if (indexToFind >= 0) {
            let elementToCheck = this.tableData[indexToFind]
            return this.$store.getters['mnp/canDelete'](elementToCheck)
          }
        },
        handleReload () {
          this.updateData()
          this.getCountMNPStatuses()
        },
        getCountMNPStatuses () {
          this.loading = true
          this.$store.dispatch('mnp/getCountMNPStatuses').then((response) => {
            this.statuses_count = response.data.statuses_count
            this.loading = false
          }).catch(e => {
            this.loading = false
            this.showLoadingErrorWithoutRetry(e, 'il caricamento del conteggio dei valori per ogni stato mnp')
          })
        },
        getStatusNameFromID (pID) {
          let index = 0
          while (index < this.statuses_count.length) {
            if (this.statuses_count[index].status === pID) {
              this.currentState.currentStatusName = this.statuses_count[index].name
            }
            index++
          }
        },
        getSaveStateConfig () {
          return {
            'cacheKey': this.componentSettings.myComponentName,
            'saveProperties': ['currentState']
          }
        },
        handleDelete (index, row) {
          let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
          if (indexToDelete >= 0) {
            let elementToDelete = this.tableData[indexToDelete]
            this.askDeleteMessage('la MNP ' + elementToDelete.id).then((result) => {
              if (!result.value) return
              this.$store.dispatch('mnp/delete', elementToDelete).then(() => {
                this.showGenericSuccess('MNP', 'eliminata con successo').then(() => {
                  if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                    this.currentState.pagination.currentPage--
                  }
                  this.updateData()
                })
              })
                .catch(e => {
                  this.loading = false
                  this.showLoadingError(e)
                })
            })
          }
        },
        handlePageChange (page) {
          if (page === this.currentState.pagination.lastLoadedPage) return
          this.updateData()
        },
        handlePerPageChange (perPage) {
          this.updateData({page: 1, limit: perPage})
        },
        handleSearchChange () {
          this.updateData()
        },
        handleSearchFieldsChange (pIndex) {
          if (this.currentState.searchFields.selected.length < 1) {
            this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
            return
          }
          if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
            this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
          }
          this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
          this.updateData()
        },
        handleSearchTypeChange (pIndex) {
          if (this.currentState.searchQuery === '') return
          this.updateData()
        },
        handleSelectionChange (pSelection) {
          this.currentState.tableMultipleSelect = pSelection
        },
        handleSortChange (pSortType) {
          let cSort = this.findColumnSort(pSortType)
          if (pSortType.prop === null) {
            this.currentState.currentSorting = this.componentSettings.tableSort
          } else {
            if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
              return
            }
            this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
          }
          this.updateData()
        },
        handleStatusChange () {
          this.updateData()
          this.getStatusNameFromID(this.currentState.currentStatusID)
        },
        resetFilters (pReload = true) {
          this.currentState.searchQuery = ''
          this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
          this.currentState.tableMultipleSelect = null
          this.currentState.searchTypeSelected = 0
          this.currentState.pagination.perPage = 25
          this.currentState.pagination.currentPage = 1
          this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
          this.currentState.currentStatusID = 7
          this.currentState.currentStatusName = 'In Attesa'
          this.clearSavedState()
          this.getCountMNPStatuses()
          if (pReload) this.updateData()
        },
        updateData (params) {
          this.loading = true
          if (!params) params = {}
          params = Object.assign(params, this.$store.getters.apiCommon, {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage, company: true})
          if (this.currentState.searchQuery !== '') {
            params = Object.assign(params, {search: this.currentState.searchQuery, search_fields: this.currentState.searchFields.selected, search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType})
          } else if (this.currentState.currentStatusID) {
            params = Object.assign(params, {status_filter: this.currentState.currentStatusID})
          }
          if (this.currentState.currentSorting !== null) {
            params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
          }
          this.$store.dispatch('mnp/list', params).then((response) => {
            this.loading = false
            this.tableData = response.data.mnp.data
            this.currentState.pagination.total = response.data.mnp.total
            this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
            this.currentState.pagination.showedItems = response.data.count
            this.currentState.pagination.from = response.data.mnp.from
            this.currentState.pagination.to = response.data.mnp.to
          }).catch(e => {
            this.loading = false
            this.showLoadingError(e)
          })
        }
      },
      created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.getCountMNPStatuses()
        this.canShowView = true
        this.updateData()
      },
      destroyed: function () {
        this.$off()
      }
    }
  </script>
  <style>
  </style>
