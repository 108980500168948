import Config from '../../globalConfig'
import axios from 'axios'

const medias = {
  namespaced: true,
  state: {
    mediaTypes: []
  },
  getters: {
    mediaTypes: state => state.mediaTypes
  },
  mutations: {
    updateMediaTypes: (state, pValue) => {
      state.mediaTypes = pValue
    }
  },
  actions: {
    reset ({commit}) {
      commit('updateMediaTypes', [])
    },
    async delete ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var pId = pElement.id
        if (pElement.media_id) pId = pElement.media_id
        axios.post(Config.endpoints.medias.base + pId + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getMediaTypes ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        if (getters.mediaTypes.length > 0) {
          resolve(getters.mediaTypes)
          return
        }

        var params = Object.assign({prefix: 'order'}, rootGetters.apiCommon)
        axios.post(Config.endpoints.medias.base + 'types', params)
          .then((response) => {
            commit('updateMediaTypes', response.data.data)
            resolve(response.data.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default medias
