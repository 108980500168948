<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="d-flex">
            <el-button v-if="!offerChanging" icon="edit" type="info" @click="offerChanging = true" size="small">
              Cambia offerta
            </el-button>
            <el-button v-else icon="close" type="info" @click="offerChanging = false; newOffer = null"
                       size="small" style="margin-right: 5px;">Annulla
            </el-button>
            <OfferTypeSelect v-if="offerChanging" v-model="newOfferType" />
            <OffersSelect v-if="offerChanging && newOfferType" class="ml-1 w-100" v-bind:offertype="offertype" :show_type="false" :show_customer_name="false" v-model="newOffer"/>
          </div>

          <div class="row mt-4" v-if="newOffer && offerChanging">
            <OfferDetails v-bind:offer="newOffer" />
          </div>

          <el-button class="mt-2" v-if="newOffer && offerChanging" type="primary"
                     @click="changeOffer">Salva e cambia offerta
          </el-button>
        </div>
      </div>
      <div v-if="!offerChanging" class="row py-3">
        <div class="col-sm-12 col-md-8">
          <h5 class="mb-0"><strong>{{ order_product.offer.internal_name }}</strong> ({{ order_product.offer.id }})
            <router-link v-if="false" :to="{path:'/offerte/lista/modifica/' + order_product.offer.id}"
                         data-tooltip="'Modifica offerta'" class="a-link" target="_blank"><i
              class="el-icon-edit"></i></router-link>
          </h5>
          <small class="d-flex" v-if="order_product.offer.created_at">Offerta creata il:
            {{ dateToItalian(order_product.offer.created_at, true) }}</small>
          <small class="d-flex">Aggiunta all'ordine il: {{ dateToItalian(order_product.created_at, true) }}</small>
          <small class="d-flex">ID Order Product: {{ order_product.id }}</small>
          <div v-if="order_product.activation_date">
            <small>Data attivazione: {{ dateToItalian(order_product.activation_date, true) }}</small>
            <a href="#" class="a-link btn-danger btn-link" v-if="canResetDate"
               @click.prevent="handleResetActivationDate(order_product)" data-tooltip="'Reset'"><i
              class="fa fa-times btn-danger btn-link"></i></a>
          </div>
          <div v-if="!order_product.activation_date">
            <small>Prodotto ancora non attivato</small>
            <a href="#" class="a-link btn-danger btn-link" v-if="canResetDate"
               @click.prevent="handleSetActivationDate(order_product)" data-tooltip="'Segna come attivato'"><i
              class="fa fa-calendar btn-danger btn-link" style="font-size: 0.7em;"></i></a>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <small v-if="order_product.offer.order_type_details.name" class="d-flex">Tipo offerta:
            <strong>&nbsp;{{ order_product.offer.order_type_details.name }}</strong></small>
          <small v-if="order_product.offer.anticipo" class="d-flex">Anticipo: {{
              order_product.offer.anticipo
            }}€</small>
          <small v-if="order_product.offer.costo_attivazione" class="d-flex">Costo attivazione: {{
              order_product.offer.costo_attivazione
            }}€</small>
          <small
            v-if="order_product.offer.costo_attivazione_scontato && order_product.offer.costo_attivazione_scontato != order_product.offer.costo_attivazione"
            class="d-flex">Costo attivazione scontato: {{
              order_product.offer.costo_attivazione_scontato
            }}€</small>
          <small v-if="order_product.offer.costo_attivazione_nuova_linea" class="d-flex">Costo attivazione nuova
            linea:
            {{ order_product.offer.costo_attivazione_nuova_linea }}€</small>
          <small
            v-if="order_product.offer.costo_attivazione_passaggio && order_product.offer.costo_attivazione_passaggio != order_product.offer.costo_attivazione_nuova_linea"
            class="d-flex">Costo attivazione passaggio:
            {{ order_product.offer.costo_attivazione_passaggio }}€</small>
          <small v-if="order_product.offer.canone_mensile" class="d-flex">Canone mensile:
            {{ order_product.offer.canone_mensile }}€</small>
          <small
            v-if="order_product.offer.canone_mensile_scontato && order_product.offer.canone_mensile_scontato != order_product.offer.canone_mensile"
            class="d-flex">Canone mensile scontato:
            {{ order_product.offer.canone_mensile_scontato }}€</small>
          <small
            v-if="order_product.offer.periodo_sconto && order_product.offer.canone_mensile_scontato && order_product.offer.canone_mensile_scontato != order_product.offer.canone_mensile"
            class="d-flex">Periodo sconto:
            {{ order_product.offer.periodo_sconto }} mesi</small>
          <small v-if="order_product.offer.obbligo_contrattuale" class="d-flex">Obbligo contrattuale:
            {{ order_product.offer.obbligo_contrattuale }} mesi</small>
          <small v-if="order_product.offer.installation_amount" class="d-flex">Costo installazione:
            {{ order_product.offer.installation_amount }}€</small>
          <small class="d-flex">IVA Inclusa:
            &nbsp;<strong><span v-if="order_product.offer.vat_included">Sì</span>
              <span v-else>No</span></strong>
          </small>
        </div>
      </div>
    </div>

    <div class="row py-3">
      <div class="col-12">
        <order-product-options-section v-bind:order_product.sync="order_product"/>
      </div>
    </div>

    <div class="row py-3">
      <div class="col-12">
        <order-product-activation-section v-bind:order_product.sync="order_product" v-bind:customer.sync="customer" v-bind:order.sync="order" @refresh="$emit('refresh')"/>
      </div>
    </div>

    <el-form label-position="top">

      <div class="row my-2">
        <div class="col-12 col-md-4">
          <el-form-item label="Numero linea fissa presente">
            <el-input v-model="order_product.internet_old_phone_number"/>
          </el-form-item>
        </div>

        <div class="col-12 col-md-4">
          <el-form-item label="Codice sessione">
            <el-input v-model="order_product.codice_sessione"/>
          </el-form-item>
        </div>

        <div class="col-12 col-md-4">
          <el-form-item>
            <label class="text-left el-form-item__label w-100">Codice ordine OLO</label>
            <div class="d-flex w-100 align-items-center">
              <el-input v-model="order_product.codice_ordine_olo"/>
              <el-button v-if="!order_product.codice_ordine_olo" type="primary" class="ml-1"
                         circle @click="generateOloOrderCode" size="small">
                  <i class="fa-regular fa-arrows-rotate"></i>
              </el-button>
            </div>
          </el-form-item>
        </div>

        <div class="col-12 col-md-4">
          <el-form-item label="Codice ordine Wholesale">
            <el-input v-model="order_product.codice_ordine_wholesale"/>
          </el-form-item>
        </div>

        <div class="col-12 col-md-4">
          <el-form-item label="Identificativo utente radius">
            <el-input v-model="order_product.user_identifier"/>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Router mac address">
            <el-input v-model="order_product.router_mac_address"/>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Canone parziale mese in corso">
            <el-input-number class="w-100" controls-position="right" v-model="order_product.period_fee"
                             :precision="2"></el-input-number>
          </el-form-item>
        </div>
      </div>

      <div class="row my-2" v-if="order_product.offer.order_type !== 1">
        <div class="col-6">
          <el-form-item label="Colore router (variante)">
            <el-input class="w-100" controls-position="right" v-model="order_product.variant"/>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item label="ID Egon Civico">
            <el-input class="w-100" controls-position="right" v-model="order_product.id_egon_civico"/>
          </el-form-item>
        </div>
      </div>

      <div class="row my-2" v-if="order_product.offer.order_type !== 1">
        <div class="col-12 col-md-4 col-lg-4 col-xl-4">
          <el-form-item label="ID Building OF">
            <el-input class="w-100" controls-position="right" v-model="order_product.building_id"/>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4 col-lg-4 col-xl-4">
          <el-form-item label="Cluster">
            <el-select value-key="key" v-model="order_product.cluster" placeholder="Cluster OF" label="Cluster OF"
                       class="w-100">
              <el-option
                v-for="cluster in clusterTypes"
                :key="cluster.key"
                :label="cluster.label"
                :value="cluster.key">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4 col-lg-4 col-xl-4">
          <el-form-item label="POP OF">
            <el-input class="w-100" controls-position="right" v-model="order_product.pop"/>
          </el-form-item>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-3">
          <el-form-item label="Protocollo Voucher Infratel">
            <el-input class="w-100" controls-position="right" v-model="order_product.protocollo_voucher_infratel"/>
          </el-form-item>
        </div>
        <div class="col-3">
          <el-form-item label="Costi di rilegamento Voucher">
            <el-input class="w-100" controls-position="right" v-model="order_product.costi_rilegamento_voucher_infratel"/>
          </el-form-item>
        </div>
        <div class="col-3">
          <el-form-item label="Stato Operativo Voucher">
            <el-input class="w-100" controls-position="right" v-model="order_product.stato_voucher_infratel" disabled readonly/>
          </el-form-item>
        </div>
        <div class="col-3">
          <el-form-item label="Data prenotazione voucher">
            <el-input class="w-100" controls-position="right" v-model="data_prenotazione_voucher" disabled readonly/>
          </el-form-item>
        </div>
      </div>

    </el-form>
  </div>
</template>

<script>
import OrderProductOptionsSection from "../../Orders/OrderOfferDetails/OrderProductOptionsSection";
import OrderProductActivationSection from "../../Orders/OrderOfferDetails/OrderProductActivationSection";
import FastwebWholesaleDesuspendModal from "@/pages/Dashboard/Orders/Components/FastwebWholesaleDesuspendModal";
import OffersSelect from "@/components/Inputs/OffersSelect";
import OfferTypeSelect from "@/components/Inputs/OfferTypeSelect";
import OfferDetails from "@/components/Offer/OfferDetails";

export default {
  name: "OrderProductDetailsSection",
  components: {OffersSelect, OfferDetails, OfferTypeSelect, FastwebWholesaleDesuspendModal, OrderProductActivationSection, OrderProductOptionsSection},
  props: {
    order_product: {},
    customer: {},
    order: {}
  },
  data() {
    return {
      clusterTypes: [
        {key: 'A_B', label: 'A_B'},
        {key: 'C_D', label: 'C_D'}
      ],
      offerChanging: false,
      newOffer: null,
      newOfferType: null,
    }
  },
  computed: {
    offertype() {
      if (!this.newOfferType) return '1';
      return this.newOfferType.code.toString();
    },
    data_prenotazione_voucher() {
      if (this.order_product && this.order_product.data_prenotazione_voucher_infratel) {
        return this.dateToItalian(this.order_product.data_prenotazione_voucher_infratel);
      }
      return '-';
    }
  },
  methods: {
    activateOrderProduct(pElement) {
      this.loading = true
      this.$store.dispatch('orders/orderProducts/activate', pElement).then((response) => {
        this.loading = false
        this.showGenericSuccess('Prodotto')
        this.refreshOrderProduct(pElement.id)
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'l\'impostazione della data di attivazione')
      })
    },
    deactivateOrderProduct(pElement) {
      this.loading = true
      this.$store.dispatch('orders/orderProducts/deactivate', pElement).then((response) => {
        this.loading = false
        this.showGenericSuccess('Prodotto')
        this.refreshOrderProduct(pElement.id)
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'il reset della data di attivazione')
      })
    },
    updateOrderProduct(pElement, pFromDialog = false) {
      this.loading = true
      this.$store.dispatch('orders/orderProducts/edit', pElement).then((response) => {
        this.loading = false
        this.showGenericSuccess('Prodotto')
        if (pFromDialog) {
          this.dialogChangeOffer = false
          this.dialogChangeColor = false
          this.dialogAddOrderProduct = false
        }
        this.refreshOrderProduct(pElement.id)
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'la modifica dell\'offerta per questo prodotto')
      })
    },
    refreshOrderProduct(pId) {
      this.loading = true;
      this.$emit('refresh')
    },
    generateOloOrderCode() {
      this.loading = true;
      this.$store.dispatch('orders/orderProducts/generateOloOrderCode', this.order_product.id).then((response) => {
        this.loading = false;
        this.refreshOrderProduct(this.order_product.id)
      }).catch(e => {
        this.loading = false
        this.showLoadingErrorWithoutRetry(e, 'l\'aggiornamento di questo prodotto nell\'ordine')
      })
    },
    canResetDate() {
      return this.$store.getters['orders/orderProducts/canResetDate'](this.order_product)
    },
    handleResetActivationDate(pCurrentOrderProduct) {
      this.askGenericConfirm('Vuoi resettare la data di attivazione?', 'Premendo Sì confermerai l\'operazione e non sarà più possibile recuperare la data di attivazione precedente.', 'Sì, resetta!', 'No, ho sbagliato!').then((result) => {
        if (!result.value) {
          return
        }
        this.deactivateOrderProduct(pCurrentOrderProduct);
      })
    },
    handleSetActivationDate(pCurrentOrderProduct) {
      this.askGenericConfirm('Vuoi impostare questa offerta come attivata?', 'Premendo Sì confermerai l\'offerta come attivata. L\'intero ordine sarà impostato come attivato.', 'Sì, imposta!', 'No, ho sbagliato!').then((result) => {
        if (!result.value) {
          return
        }
        this.activateOrderProduct(pCurrentOrderProduct);
      })
    },
    changeOffer() {
      this.loading = true;
      let data = {
        order_product_id: this.order_product.id,
        offer_id: this.newOffer.id
      }

      this.$store.dispatch('orders/orderProducts/editOffer', data).then((response) => {
        this.loading = false;
        this.$emit('refresh')
        this.offerChanging = false
        this.newOffer = null
      }).catch(e => {
        this.loading = false
        this.showResponseErrorMessage(e)
      })
    }
  }
}
</script>

<style scoped>

</style>
