<template>
  <div>
    <div slot="header" v-if="order.id && order.customer" v-loading=loading>
      <div class="row">
        <div class="col-12">

          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">Creato il: {{ dateToItalian(order.created_at, true) }}</li>
            <li class="list-group-item px-0">Ultima modifica: {{ dateToItalian(order.updated_at, true) }}</li>
            <li class="list-group-item px-0" v-if="order.customer.ts_code">Codice TS: <a
              :href="'https://www.dimensione.com/private_tools/clienti.php?codicecliente=' + order.customer.ts_code"
              target="_blank">{{ order.customer.ts_code }}</a> | <a
              :href="'https://www.dimensione.com/private_tools/crea_utente_openfiber.php?codicecliente=' + order.customer.ts_code"
              target="_blank">Crea utenza PPPoE</a> </li>
          </ul>

        </div>


        <!--
            <div class="col-12">
              <h4 class="float-right mr-2 mt-1 mb-1" style="font-size: 110%">Ordine completo
                <l-switch class="float-right ml-2" style="margin-top: 2px;" v-model="order.complete"
                          @input="changeCommonProperties" type="primary" on-text="SÌ" off-text="NO"></l-switch>
              </h4>
            </div>
            <h4 class="col-12 mt-0" style="font-size: 110%">
                  <span
                    v-if="order.id && order.total_to_pay && order.total_to_pay.total > 0 && order.type === 1 && !order.paid"
                    class="float-right mt-0 mr-2" style="color: #E74155">Non pagato <i
                    class="el-icon-warning"></i></span>
              <span v-if="order.id && order.paid" class="float-right mt-0 mr-2" style="color: #4F96E4">Pagato <i
                class="el-icon-success"></i></span>
            </h4>
            -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderEditPageHeader",
  props: {
    order: null,
    loading: false,
  },
  methods: {
    refreshData() {
      this.$emit('refresh-data')
    }
  }
}
</script>

<style scoped>

</style>
