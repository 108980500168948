<template>

  <div>
    <form>
      <div class="row mt-4">
        <div class="col-12 col-sm-4 col-md-3">
          <label>Stato</label>
          <el-select value-key="code" style="width: 100%" class="select-default mb-3" v-model="product_alert_form.status" placeholder="Seleziona..." size="large">

            <el-option
              v-for="item in statuses"
              class="select-default"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Nome"
                    v-model="product_alert_form.name"
                    name="name"
                    v-validate="modelValidations.name"
                    :error="getError('name')">
          </el-input>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Cognome"
                    v-model="product_alert_form.last_name"
                    name="last_name"
                    v-validate="modelValidations.last_name"
                    :error="getError('last_name')">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="E-mail"
                    v-model="product_alert_form.email"
                    name="email"
                    v-validate="modelValidations.email"
                    :error="getError('email')">
          </el-input>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Numero di telefono"
                    v-model="product_alert_form.phone_number"
                    name="phone_number">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
        <label>Tipo cliente</label>

        <el-select id="selectCustomerType" v-model="product_alert_form.customer_type" class="col-12 select-default py-0 px-0" placeholder="Seleziona un tipo cliente..." size="large">

          <el-option
            v-for="item in customer_types"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>

        </el-select>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Indirizzo IP"
                    v-model="product_alert_form.ip_address"
                    name="ip_address"
                    disabled>
          </el-input>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Prodotto"
                    v-model="product_alert_form.product"
                    name="product">
          </el-input>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Tariffa"
                    v-model="product_alert_form.rate"
                    name="rate">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Colore"
                    v-model="product_alert_form.color"
                    name="color">
          </el-input>
        </div>

        <div class="col-md-6 col-sm-12">
          <el-input type="text"
                    label="Landing tag"
                    v-model="product_alert_form.landing_tag"
                    name="landing_tag">
          </el-input>
        </div>


      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 pb-0">

          <div class="row" style="margin-top: 20px">
            <div class="col-12 text-center">

              <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement" v-if="product_alert_form.id">
                Elimina
              </button>

              <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
                Salva
              </button>

            </div>
          </div>

          <div class="clearfix"></div>
        </div>
      </div>
    </form>
  </div>
</template>
<style>
  .el-transfer-panel {
    width: 300px;
  }

  .el-transfer-panel__body {
    width: 300px;
  }
  .el-transfer-panel__list.is-filterable {
    width: 100% !important;
  }
</style>
<script>
  import { Switch as LSwitch, Checkbox as LCheckbox } from '../../../components'
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      LSwitch,
      LCheckbox,
      'editor': Editor
    },
    props: {
      product_alert_form: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/offerte/opzioni/lista'
        },
        content: '',
        loading: false,
        statuses: [],
        customer_types: null,
        modelValidations: {
          name: {
            required: true
          },
          last_name: {
            required: true
          },
          email: {
            required: true,
            email: true
          },
          customer_type: {
            required: true
          }
        },
        currentCustomerType: null,
        currentStatus: 0,
        currentStatusLabel: 'Creata'
      }
    },
    watch: {},
    computed: {},
    methods: {
      deleteElement () {
        this.$emit('delete', this.product_alert_form)
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      loadCustomerTypes () {
        this.loading = true
        this.$store.dispatch('productAlerts/loadCustomerTypes').then((response) => {
          this.customer_types = response.data.customer_types
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei tipi cliente')
        })
      },
      loadStatuses () {
        this.loading = true
        this.$store.dispatch('productAlerts/getStatuses', {}).then((response) => {
          this.loading = false
          this.statuses = response.data.statuses
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento degli stati')
        })
      },
      validateForm () {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.$emit('update', this.product_alert_form)
          }
        })
      }
    },
    created: function () {
      this.loadCustomerTypes()
      this.loadStatuses()
    },
    mounted: function () {
    },
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
