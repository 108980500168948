<template>
    <div>
        <el-dialog
            v-loading="loading"
            title="Invia riprogrammazione"
            v-model="showModal">
            <div class="row">
                <div class="col-12">
                    <label>Fascia oraria preferita</label>
                    <el-time-select
                        v-model="appointmentTime"/>
                </div>
                <div class="col-12 mt-2">
                    <label>Note desospensione (facoltativo)</label>
                    <el-input
                        :rows="3"
                        placeholder="Note"
                        v-model="note">
                    </el-input>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closeModal" type="danger">Chiudi</el-button>
                <el-button @click="send" type="primary" :disabled="!isValid">Invia</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import momentBusinessDays from "moment-business-days-it";

export default {
    name: "OpenFiberWholesaleRescheduleModal",
    props: {
        orderId: {
            required: true
        },
        visible: {
            required: true,
            type: Boolean
        }
    },
    created() {
    },
    data: function () {
        return {
            loading: false,
            appointmentTime: null,
            note: null
        }
    },
    computed: {
        isValid() {
            return this.appointmentTime;
        },
        showModal() {
            return this.visible
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        send() {
            this.loading = true
            let data = {
                order_id: this.orderId,
                appointment_time: this.appointmentTime,
                note: this.note
            }
            this.$store.dispatch('orders/openfiberWholesaleReschedule', data).then((response) => {
                this.closeModal()
                this.showSuccessMessage("Richiesta di riprogrammazione inviata correttamente.")
            }).catch(e => {
                this.closeModal()
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
