<template>
  <div class="py-1">
    <div class="d-flex">
      <div class="photo float-left">
        <!-- <img :src="orderNote.user.profile_pic ? orderNote.user.profile_pic : '/static/img/avatar.jpg'" class="img-fluid" alt="avatar"/> -->
        <div v-bind:style="avatarCSS(orderNote.user)"></div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-baseline justify-content-between mb-1">
          <span class="font-weight-bold">{{ orderNote.user.name }}</span>
          <small>{{ dateToItalian(orderNote.created_at) }}</small>
        </div>
        <p class="mb-0" v-html="orderNote.content"/>
        <div class="clearfix"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OrderNote",
  props: {
    orderNote: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      loading: false
    }
  },
  methods: {
    avatarCSS(pUser) {
      if (pUser) {
        let urlAvatar = pUser.profile_pic ? pUser.profile_pic : '/static/img/avatar.jpg'
        return 'background-image: url(\'' + urlAvatar + '\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 45px; width: 45px; margin-right: 10px;'
      } else {
        return 'background-image: url(\'/static/img/avatar.jpg\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 45px; width: 45px; margin-right: 10px;'
      }
    },
    canDeleteNote(item) {
      return this.$store.getters['orders/canDeleteNote'](item)
    },
    handleDeleteNote(item) {
      this.askDeleteMessage('Sei sicuro di voler eliminare la nota?').then((result) => {
        if (!result.value) return
        this.loading = true
        this.$store.dispatch('orders/deleteNotes', item).then((response) => {
          this.showSuccessMessage("Nota eliminata con successo.")
          this.$emit('deleted')
        })
      }).catch(e => {
        if (e === 'cancel') {
          return
        }
        this.showErrorMessage("Errore durante l'eliminazione di questa nota.")
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
