<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Lista ricevute">

        <div class="row">
          <div class="col-12 col-md-6 col-lg-7">
            <div class="row">

              <div class="col-12 mb-2" v-if="receipts">
                <p class="mb-2" v-if="totalReceipts > 0">Con il filtro inserito ci sono <span style="font-weight: bold;">{{totalReceipts}}</span> ricevute.</p>
                <p class="mb-2" v-else>Nel sistema <span style="font-weight: bold;">non ci sono ricevute</span>.</p>
              </div>

              <div class="col-12 mb-2" v-if="receiptsAmounts">
                <p class="mb-2">Importo totale: <span style="font-weight:bold;">{{receiptsAmounts.total_amount}}€</span></p>
                <p class="mb-2">Di cui da offerte: <span style="font-weight:bold;">{{receiptsAmounts.offers_amount}}€</span></p>
                <p class="mb-2">Di cui da opzioni: <span style="font-weight:bold;">{{receiptsAmounts.options_amount}}€</span></p>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-5 ml-auto" style="min-width: 350px; max-width: 350px;">
            <div class="row">
              <div class="col-12 col-md-12 mb-3">
                <label>Esporta ricevute</label>
                <el-date-picker
                        v-model="exportDate"
                        type="month"
                        format="MM-yyyy"
                        placeholder="Seleziona il mese"
                        value-format="YYYY-MM-DD"
                        language="it">
                </el-date-picker>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button class="btn btn-default btn-outline col-12" @click.prevent="exportCSV()">
                      <span class="btn-label">
                         <i class="fa fa-download"></i> Esporta CSV
                      </span>
                </button>
              </div>

              <div class="col-6">
                <button class="btn btn-default btn-outline col-12" @click.prevent="exportPDF()">
                      <span class="btn-label">
                         <i class="fa fa-download"></i> Esporta PDF
                      </span>
                </button>
              </div>
            </div>
          </div>
        </div><!-- end of row -->


        <div class="table-layout-container">

          <div class="row mt-5">
            <div class="mb-3 col-12 col-md-3" style="max-width: 140px;">
              <el-select style="width: 100%; max-width: 110px;" v-model="currentState.pagination.perPage" placeholder="Per pagina" @change="handlePerPageChange">
                <el-option v-for="item in componentSettings.pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>

            <div class="col">
              <div></div>
              <l-pagination class="pagination-no-border float-right" v-model="currentState.pagination.currentPage" :per-page="currentState.pagination.perPage" :total="currentState.pagination.total" @input="handlePageChange"></l-pagination>
            </div>
          </div>

          <div class="row mt-2 mb-5 justify-content-center">
            <div class="col-8">
              <label>Filtra per data
              </label>
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                start-placeholder="Dal giorno"
                end-placeholder="al giorno"
                format="DD-MM-YYYY"
                value-format="YYYY-MM-DD HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                @change="changeDateRange(dateRange)"
                language="it">
              </el-date-picker>
            </div>
          </div>

          <h4 class="mb-4" style="font-weight: 400 !important; text-align: center" v-if="dateRange">Stai visualizzando le ricevute dal <strong v-html="dateToItalian(dateRange[0], false, 'DD/MM/YYYY')"></strong> al <strong v-html="dateToItalian(dateRange[1], false, 'DD/MM/YYYY')"></strong></h4>

          <div class="row mt-3">

            <div class="col-12" v-loading="loading">
              <el-table class="table-striped td-strong" :data="receipts" border style="width: 100%" :default-sort="currentState.currentSorting" @sort-change="handleSortChange" @selection-change="handleSelectionChange">

                <template v-for="column in tableColumns">

                  <el-table-column v-if="column.custom === 'id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row"><strong>{{props.row.id}}</strong></div>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="column.custom === 'order_id'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div v-if="props.row">
                        <router-link class="a-link" :to="{path:'/ordini/lista/modifica/' + props.row.order_id}">
                          <strong>{{props.row.order_id}}</strong>
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="column.custom === 'created_at'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div class="table-actions" v-if="props.row">

                        <span v-if="props.row.created_at">{{dateToItalian(props.row.created_at, true)}}</span>

                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="column.custom === 'option_amount'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <span v-if="props.row.option_amount">{{props.row.option_amount}}€</span>
                        <span v-else-if="!props.row.option_amount">0€</span>

                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="column.custom === 'offer_amount'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <span v-if="props.row.offer_amount">{{props.row.offer_amount}}€</span>
                        <span v-else-if="!props.row.offer_amount">0€</span>

                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="column.custom === 'total_amount'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">
                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <span v-if="props.row.total_amount">{{props.row.total_amount}}€</span>
                        <span v-else-if="!props.row.total_amount">0€</span>

                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column v-if="column.custom === 'azioni'" :type="column.type" :fixed="column.fixed" :key="column.label" :width="calculateTableWidth(column)" :prop="column.prop" :sortable="column.sortable" :label="column.label">
                    <template #default="props">

                      <div style="text-align: center" class="table-actions" v-if="props.row">

                        <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                           @click="handleDelete(props.$index, props.row)" v-if="canDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>

                        <i class="fa fa-ban" v-if="!canDelete(props.$index, props.row)" data-tooltip="'Nessun permesso'"></i>
                      </div>
                    </template>
                  </el-table-column>

                </template>

              </el-table>
            </div>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da <strong>{{currentState.pagination.from}}</strong> a <strong>{{currentState.pagination.to}}</strong> su <strong>{{currentState.pagination.total}}</strong> totali</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="currentState.pagination.currentPage"
                          :per-page="currentState.pagination.perPage"
                          :total="currentState.pagination.total"
                          @input="handlePageChange">
            </l-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { Pagination as LPagination } from '../../../components'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination
    },
    computed: {},
    /* watch: {
      '$dateRange': function () {
        this.changeDateRange(this.dateRange)
      }
    }, */
    data () {
      return {
        componentSettings: {
          tableSort: {prop: 'id', order: 'descending'},
          myComponentName: 'Receipts',
          minimumPermissionLevel: 200,
          pagination: {
            perPageOptions: [5, 10, 25, 50, 100]
          }
        },
        currentState: {
          currentSorting: {prop: 'id', order: 'descending'},
          pagination: {
            perPage: 25,
            currentPage: 1,
            lastLoadedPage: 1,
            total: 0,
            from: 0,
            to: 0,
            showedItems: 0
          }
        },
        tableColumns: [
          {
            prop: 'id',
            label: 'ID',
            percentWidth: 9,
            sortable: 'custom',
            showTooltip: true,
            fixed: true,
            type: 'default',
            custom: 'id'
          },
          {
            prop: 'order_id',
            label: 'ID ordine',
            percentWidth: 14,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'order_id'
          },
          {
            prop: 'created_at',
            label: 'Creata il',
            percentWidth: 18,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'created_at'
          },
          {
            prop: 'offer_amount',
            label: 'Costo offerta',
            percentWidth: 16,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'offer_amount'
          },
          {
            prop: 'option_amount',
            label: 'Costo opzione',
            percentWidth: 16,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'option_amount'
          },
          {
            prop: 'total_amount',
            label: 'Costo totale',
            percentWidth: 16,
            sortable: false,
            showTooltip: true,
            fixed: false,
            type: 'default',
            custom: 'total_amount'
          },
          {
            prop: 'azioni',
            label: 'Azioni',
            percentWidth: 11,
            sortable: false,
            showTooltip: false,
            fixed: false,
            type: 'default',
            custom: 'azioni'
          }
        ],
        pickerOptions: {
          shortcuts: [{
            text: 'Ultime 24 ore',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: 'Ultimi 7 gg',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: 'Ultimo mese',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: 'Ultimi 2 mesi',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: 'Ultimi 6 mesi',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        loading: false,
        tableData: [],
        canShowView: false,
        tableMultipleSelect: null,
        dateRange: null,
        receipts: [],
        totalReceipts: null,
        receiptsAmounts: null,
        exportDate: null
      }
    },
    methods: {
      getSaveStateConfig () {
        return {
          'cacheKey': this.componentSettings.myComponentName,
          'saveProperties': ['currentState']
        }
      },
      canDelete (index, row) {
        let indexToFind = this.receipts.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToFind >= 0) {
          let elementToCheck = this.receipts[indexToFind]
          return this.$store.getters['receipts/canDelete'](elementToCheck)
        }
      },
      changeDateRange (pDate) {
        if (this.dateRange) {
          let interval = this.daysBetweenDates(pDate[0], pDate[1])
          if (interval > 180) {
            this.showGenericError('Errore!', 'Hai selezionato un intervallo troppo lungo.<br />Puoi selezionare massimo 180 giorni.')
            this.dateRange = []
          }
        }
        this.updateData()
      },
      exportCSV () {
        this.loading = true
        if (this.exportDate) {
          let params = {export_date: this.exportDate}
          this.$store.dispatch('receipts/exportCSV', params).then((response) => {
            this.loading = false
            let fileDownload = require('js-file-download')
            fileDownload(response.data, 'Ricevute_' + this.exportDate + '.csv', 'application/csv')
          }).catch(e => {
            this.loading = false
            this.showLoadingErrorWithoutRetry(e, 'il download del CSV')
          })
        } else {
          this.loading = false
          this.showGenericError('Errore', 'devi selezionare il mese.')
        }
      },
      exportPDF () {
        this.loading = true
        if (this.exportDate) {
          let params = {export_date: this.exportDate}
          this.$store.dispatch('receipts/exportPDF', params).then((response) => {
            this.loading = false
            var fileDownload = require('js-file-download')
            fileDownload(response.data, 'Ricevute_' + this.exportDate + '.pdf', 'application/pdf')
          }).catch(e => {
            this.loading = false
            this.showLoadingErrorWithoutRetry(e, 'il download del PDF')
          })
        } else {
          this.loading = false
          this.showGenericError('Errore', 'devi selezionare il mese.')
        }
      },
      handlePerPageChange (perPage) {
        this.updateData({page: 1, limit: perPage})
      },
      handlePageChange (page) {
        if (page === this.currentState.pagination.lastLoadedPage) return
        this.updateData()
      },
      handleSelectionChange (pSelection) {
        this.currentState.tableMultipleSelect = pSelection
      },
      handleSortChange (pSortType) {
        let cSort = this.findColumnSort(pSortType)
        if (pSortType.prop === null) {
          this.currentState.currentSorting = this.componentSettings.tableSort
        } else {
          if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
            return
          }
          this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
        }
        this.updateData()
      },
      handleDelete (index, row) {
        let indexToDelete = this.receipts.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          let elementToDelete = this.receipts[indexToDelete]
          this.askDeleteMessage('la ricevuta ' + elementToDelete.id + " per l'ordine " + elementToDelete.order_id).then((result) => {
            if (!result.value) return
            this.$store.dispatch('shippings/removeOrderReceipt', elementToDelete.order_id).then(() => {
              Swal.fire('Eliminata!',
                'La ricevuta è stata eliminata.',
                'success'
              ).then(() => {
                if (this.receipts.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                  this.currentState.pagination.currentPage--
                }
                this.updateData()
              })
            })
              .catch(e => {
                this.loading = false
                this.showLoadingError(e)
              })
          })
        }
      },
      updateData (params) {
        if (!params) params = {}
        this.loading = true
        params = Object.assign(params, this.$store.getters.apiCommon, {page: this.currentState.pagination.currentPage, limit: this.currentState.pagination.perPage, company: true})
        if (this.currentState.currentSorting !== null) {
          params = Object.assign(params, {orderby: this.currentState.currentSorting.prop, order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)})
        }
        if (this.dateRange) {
          params = Object.assign(params, {date_from: this.dateRange[0], date_to: this.dateRange[1]})
        }
        params = Object.assign(params, this.$store.getters.apiCommon)
        this.$store.dispatch('receipts/getReceipts', params).then((response) => {
          this.loading = false
          this.receipts = response.data.receipts.data
          this.totalReceipts = response.data.receipts.total
          this.receiptsAmounts = response.data.receipts.amounts
          this.currentState.pagination.total = response.data.receipts.total
          this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
          this.currentState.pagination.showedItems = response.data.count
          this.currentState.pagination.from = response.data.receipts.from
          this.currentState.pagination.to = response.data.receipts.to
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
</style>
