<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi avviso prodotto">
        <product-alert-form v-bind:product_alert_form="product_alert_form" v-loading="loading" @update="addProductAlert"></product-alert-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ProductAlertForm from './ProductAlertForm.vue'

  export default {
    components: {
      ProductAlertForm: ProductAlertForm
    },
    computed: {},
    data () {
      return {
        componentSettings: {
          myComponentName: 'AddProductAlert',
          minimumPermissionLevel: 200,
          listPath: '/productAlerts/lista/'
        },
        loading: false,
        canShowView: false,
        product_alert_form: {
          status: 0,
          customer_type: 3,
          content: ''
        }
      }
    },
    methods: {
      addProductAlert (pElement) {
        this.loading = true
        this.$store.dispatch('productAlerts/add', pElement).then((response) => {
          this.loading = false
          this.$router.push('/productAlerts/lista/')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questo avviso prodotto', '', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {},
    destroyed: function () {
      this.$off()
    }
  }
</script>

<style></style>
