<template>
  <div v-loading="loading">

    <div class="chronology-section mt-5" v-loading="notesLoading">

      <div class="d-flex align-items-center mb-3">
        <h4 class="m-0 mr-2">Cronologia</h4>
        <el-button circle size="small" icon="refresh" @click="refreshNotesChronology"
                   data-tooltip="'Ricarica cronologia'"/>
      </div>
      <h5 class="n-thin" v-if="chronology.length < 1">Nessun elemento da mostrare</h5>

      <ul v-if="chronology.length > 0" class="lista pl-0">
        <li v-for="(item) in chronology">
          <div class="d-flex align-items-center">
            <div class="photo float-left">
              <div v-bind:style="avatarCSS(item.user)"></div>
            </div>
            <div>
              <span class="font-weight-bold"
                    v-if="item.user && item.user.name">{{ item.user.name }} <small>{{
                  dateToItalian(item.happened_at)
                }}</small>
                              <el-button style="padding-top: 0px; padding-bottom: 0px;" class="ml-3" icon="view"
                                         v-if="item.notification_content"
                                         @click="notificationContent = item.notification_content; notificationContentVisible = true">Vedi contentuto notifica</el-button>

              </span>
              <span class="font-weight-bold" v-else>Nota di sistema <small>{{ dateToItalian(item.happened_at) }}</small></span>
              <p class="mb-0">{{ item.text }}</p>
              <p class="mb-0 mt-0" v-if="item.description"><i>{{ item.description }}</i></p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <el-dialog
      title="Contenuto notifica"
      v-model="notificationContentVisible"
      width="90%">
      <span v-html="notificationContent"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="notificationContentVisible = false">Cancel</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<style>
.notes-section .lista li, .chronology-section .lista li {
  list-style-type: none;
  margin-bottom: 18px;
  min-height: 45px;
}

.notes-section .info, .chronology-section .info {
  padding-top: 0px;
  width: calc(100% - 55px);
}

.notes-section .info div.n-thin, .chronology-section .info div.n-thin {
  font-size: 80%;
}

.notes-section .info span, .chronology-section .info span {
  font-size: 95%;
}
</style>
<script>
import {Switch as LSwitch, Checkbox as LCheckbox} from 'src/components/index'
import AddOrderNoteForm from "@/pages/Dashboard/Orders/Components/AddOrderNoteForm";
import OrderNote from "@/pages/Dashboard/Orders/Components/OrderNote";
import OrderNotesList from "@/pages/Dashboard/Orders/Components/OrderNotesList";
import OrderNoteSection from "@/pages/Dashboard/Orders/Components/OrderNoteSection";

export default {
  mixins: [],
  components: {
    OrderNoteSection,
    OrderNotesList,
    OrderNote,
    AddOrderNoteForm,
    LSwitch,
    LCheckbox
  },
  props: {
    order: {},
    orderid: null
  },
  data() {
    return {
      componentSettings: {
        myComponentName: 'OrderNotesChronologyDetails'
      },
      loading: false,
      notesLoading: false,
      loadedNotes: false,
      showForm: false,
      notes: [],
      noteToAdd: '',
      chronology: [],
      modelValidations: {
        type: {
          required: true
        }
      },
      cssAvatar: '',
      notificationContentVisible: false,
      notificationContent: null,
      uploadsFileList: []
    }
  },
  watch: {
    order() {
    },
    'order.id'() {
      this.refreshNotesChronology()
    },
    chronologyUpdate() {
      this.loadChronology()
    }
  },
  computed: {
    chronologyUpdate: function () {
      return this.$store.state.orders.chronologyUpdate
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      if (isVisible && !this.loadedNotes) {
        this.loadedNotes = true
        this.loadChronology()
      }
    },
    avatarCSS(pUser) {
      if (pUser) {
        let urlAvatar = pUser.profile_pic ? pUser.profile_pic : '/static/img/avatar.jpg'
        return 'background-image: url(\'' + urlAvatar + '\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 45px; width: 45px; margin-right: 10px;'
      } else {
        return 'background-image: url(\'/static/img/avatar.jpg\'); background-size: cover; background-position: top center; background-repeat: no-repeat; border-radius: 50% !important; height: 45px; width: 45px; margin-right: 10px;'
      }
    },
    refreshNotesChronology() {
      this.$store.commit('orders/updateChronologyEvents')
    },
    loadChronology() {
      this.notesLoading = true
      this.$store.dispatch('orders/getChronology', {id: this.orderid}).then((response) => {
        this.notesLoading = false
        this.chronology = response.data.data
      }).catch(e => {
        this.notesLoading = false
        this.showResponseErrorMessage(e)
      })
    }
  },
  created: function () {
    this.loadChronology();
  },
  mounted: function () {
  },
  destroyed: function () {
    this.$off()
  }
}

</script>
<style></style>
