import Config from '../../globalConfig'
import axios from 'axios'

const receipts = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 200) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 200) return false
      return true
    },
    canDuplicate: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 200) return false
      return true
    }
  },
  actions: {
    async exportCSV ({commit, state, rootGetters}, params) {
      console.log(params)
      return new Promise((resolve, reject) => {
        params = Object.assign({}, params, {}, rootGetters.apiCommon)
        console.log(params)
        axios.post(Config.endpoints.receipts.exportCSV, params)
            .then((response) => {
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    },
    async exportPDF ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        let parameters = Object.assign({}, params, rootGetters.apiCommon)
        axios({ url: Config.endpoints.receipts.exportPDF, method: 'post', responseType: 'arraybuffer', data: parameters })
            .then((response) => {
              resolve(response)
            })
            .catch((e) => {
              reject(e)
            })
      })
    },
    async getReceipts ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.receipts.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default receipts
