<template>
    <div>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h4 class="m-0 mr-2">Note &amp; Cronologia</h4>
            <el-button-group>
                <el-button size="small" type="primary" icon="refresh" @click="refreshList"/>
                <el-button size="small" type="primary" :icon="showForm ? 'minus' : 'plus'"
                           @click="showForm = !showForm"/>
            </el-button-group>
        </div>

        <add-order-note-form :order-id="orderId" @added="refreshList(); showForm = false" v-if="showForm"/>
        <div class="mt-4">
            <order-timeline :order-id="orderId" ref="orderTimeline"/>
        </div>
    </div>
</template>

<script>
import AddOrderNoteForm from "@/pages/Dashboard/Orders/Components/AddOrderNoteForm";
import OrderNotesList from "@/pages/Dashboard/Orders/Components/OrderNotesList";
import OrderTimeline from "@/pages/Dashboard/Orders/Components/OrderTimeline";

export default {
    name: "OrderTimelineSection",
    components: {OrderTimeline, OrderNotesList, AddOrderNoteForm},
    props: {
        orderId: {
            required: true
        }
    },
    watch: {
        'orderId': function (val) {
            console.log(val)
            if (!val) {
                return
            }
            this.refreshList()
        }
    },
    data: function () {
        return {
            showForm: false
        }
    },
    methods: {
        refreshList() {
            if (!this.orderId) {
                return
            }
            this.$refs.orderTimeline.fetchTimeline(this.orderId)
        }
    }
}
</script>

<style scoped>

</style>
