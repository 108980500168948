<template>
  <div>
    <el-dialog
      v-loading="loading"
      title="Imposta flag wholesale"
      v-model="showModal">
      <div class="row mb-4">
        <div class="col-12">
          <p>Stai per impostare il flag interno di invio a OpenFiber, senza inviare l'ordine.</p>
          <p class="mb-0">Il sistema proverà a recuperare la data di attivazione dalla cronologia di open fiber.</p>
          <label>Imposta una data per forzare la data di attivazione.</label>
          <el-date-picker
            v-model="activationDate"
            type="date"
            format="DD-MM-YYYY"
            value-format="YYYY-MM-DD"
            language="it"/>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModal" type="danger">Chiudi</el-button>
        <el-button @click="send" type="primary">Invia</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import momentBusinessDays from "moment-business-days-it";

export default {
  name: "OpenFiberForceWholesaleModal",
  props: {
    orderId: {
      required: true
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  created() {
  },
  data: function () {
    return {
      loading: false,
      activationDate: null,
      note: null
    }
  },
  computed: {
      showModal() {
          return this.visible
      }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    send() {
      this.loading = true
      let data = {
        order_id: this.orderId,
        activation_date: this.activationDate
      }
      this.$store.dispatch('orders/forceWholesale', data).then((response) => {
        this.showSuccessMessage("Fatto.")
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false
        this.closeModal()
      })
    }
  }
}
</script>

<style scoped>

</style>
