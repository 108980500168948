import Config from '../../globalConfig'
import axios from 'axios'

const activationAddress = {
  namespaced: true,
  actions: {
    async edit ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.activationAddress.base + pElement.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    async add ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(params, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.activationAddress.add, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default activationAddress
