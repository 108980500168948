<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Riepilogo flusso">

        <div class="row" v-loading="loading">
          <div class="col-12 mt-2 pl-4 pr-4">
            <div class="row">

              <h6 class="col-12 flowHeading">Cambi stato automatici lato cliente</h6>

              <p class="col-12 flowParag">
                Se il cliente registra un video (procedura applicata sul bot e sui carrelli):
              </p>
              <ul class="flowUl pl-5 mb-3">
                <li>
                  se l'ordine è con riconoscimento 2 o 5 (SMS o Video+SMS) e NON si trova KO Video (69), finisce in In Attesa SMS (61);
                </li>
                <li>
                  se l'ordine si trova in KO Video (69), finisce in Da Gestire Completo (67);
                </li>
                <li>
                  in tutti gli altri casi finisce in In Attesa PDA (17).
                </li>
              </ul>

              <p class="col-12 flowParag">
                Se il cliente carica un documento (procedura applicata sul bot e sui carrelli):
              </p>
              <ul class="flowUl pl-5 mb-3">
                <li>
                  indipendentemente dal tipo ordine lo stato finisce in Da Gestire Completo (67).
                </li>
              </ul>

              <p class="col-12 flowParag">
                Se il cliente carica il contratto (procedura applicata sul bot e sui carrelli):
              </p>
              <ul class="flowUl pl-5">
                <li>
                  indipendentemente dal tipo ordine lo stato finisce in Da Gestire Completo (67).
                </li>
              </ul>


              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">Filtri sui cambi stato</h6>

              <p class="col-12 flowParag">
                SOLO PER IL TYPE RIC SENZA VINCOLO, La ricevuta deve essere presente per poter cambiare stato in:
              </p>
              <ul class="flowUl pl-5">
                <li>Da Attivare e Spedire BRT (ID 71)</li>
                <li>Da Attivare e Spedire TNT (ID 82)</li>
              </ul>
              <p class="col-12 flowParag pl-5">
                In caso di mancanza della ricevuta (solo per type ric senza vincolo), deve essere mostrato l'errore:
                "La ricevuta è obbligatoria per poter inserire un ordine in questo stato."
              </p>

              <p class="col-12 flowParag mt-3">
              Se il punto di ritiro è presente ed è di tipo TNT e viene spostato lo stato in:
              </p>
              <ul class="flowUl pl-5">
                <li>Da Attivare e Spedire BRT (ID 71)</li>
              </ul>
              <p class="col-12 flowParag pl-5">
                Deve essere mostrato l'errore:
                "Non puoi spedire un ordine con BRT ad un punto di ritiro TNT".
              </p>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">Azioni automatiche al cambio stato</h6>

              <p class="col-12 flowParag">
                Ogni cambio stato in Da Attivare e Spedire BRT (ID 71) deve creare in automatico la spedizione con BRT (se tutte le condizioni sopra sono soddisfatte).<br /><br />

                Quando l'ordine va in:
              </p>
                <ul class="flowUl">
                  <li>Da Ricaricare (ID 48)</li>
                  <li>Da Ricaricare Spedite (ID 72)</li>
                  <li>Da Spedire (ID 76)</li>
                </ul>
              <p class="col-12 flowParag">
                Impostare la data di attivazione a quel momento per tutti i prodotti dell'ordine e all'ordine stesso.
              </p>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">TNT e azioni automatiche</h6>

              <p class="col-12 flowParag">
                Nel momento in cui viene effettuata una spedizione con TNT, l'ordine viene spostato in:
              </p>
              <ul class="flowUl">
                <li>Spedito con TNT (ID 47) se è attivato</li>
                <li>Da attivare spedita (ID 73) se non è attivato</li>
              </ul>
              <p class="col-12 flowParag">
                E viene aggiunta in shipping log la spedizione da controllare.
              </p>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">BRT e azioni automatiche</h6>

              <p class="col-12 flowParag">
                Nel momento in cui viene effettuata una spedizione con BRT (intesa come aggiunta nella coda di quelle spedire), viene aggiunta in shipping log la spedizione da controllare, ma non devono essere eseguiti cambi stato automatici all'atto della spedizione.<br /><br />

                Nel momento in cui una spedizione viene LAVORATA (download da BRT):
              </p>

              <ul class="flowUl">
                <li>Lasciare la nota in cronologia</li>
                <li>Inserire il tracking code identificativo</li>
                <li>Se si trova in stato Da Attivare e Spedire BRT (ID 71) deve passare in Da attivare spedita (ID 73), in caso contrario nessuno spostamento</li>
              </ul>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">PeopleSocci</h6>

              <p class="col-12 flowParag">
                PeopleSocci, nella missione RICARICABILI SENZA VINCOLO CA3/DEALER, deve prendere tutti gli ordini in:
              </p>

              <ul class="flowUl">
                <li>Ricevuto - In attivazione (ID 49) - Type Ric senza vincolo</li>
                <li>Da attivare spedita (ID 73) - Type Ric senza vincolo</li>
              </ul>

              <p class="col-12 flowParag">
                <br />PeopleSocci, nella missione RICARICABILI CON VINCOLO CA3/DEALER, deve prendere tutti gli ordini in:
              </p>

              <ul class="flowUl">
                <li>Da attivare PeopleSocci (ID 50) - Type Ric con vincolo</li>
              </ul>

              <p class="col-12 flowParag">
                <br />PeopleSocci, in caso di successo dell'attivazione (NB.: Attivazione), deve andare in:
              </p>

              <p class="col-12 flowParag">
                Se l'ordine è Type = <strong>Ric senza vincolo</strong>
              </p>
              <ul class="flowUl">
                <li>Da Ricaricare (ID 48) se l'ordine si trova in Da attivare PeopleSocci (ID 50) ----> DEVE FINIRE IN DA RICARICARE SOLO SE NELL'ORDINE E' PRESENTE UNA RICARICA, ALTRIMENTI PASSA IN DA SPEDIRE</li>
                <li>Da Ricaricare Spedite (ID 72) se l'ordine si trova in Da attivare spedita (ID 73)</li>
                <li>Da Ricaricare Spedite (ID 72) se l'ordine si trova in Ricevuto - In attivazione (ID 49)</li>
              </ul>

              <p class="col-12 flowParag">
                Se l'ordine è Type = <strong>Ric con vincolo</strong>
              </p>
              <ul class="flowUl">
                <li>Da Ricaricare (ID 48) se l'ordine si trova in Da attivare PeopleSocci (ID 50) ----> DEVE FINIRE IN DA RICARICARE SOLO SE NELL'ORDINE E' PRESENTE UNA RICARICA, ALTRIMENTI PASSA IN DA SPEDIRE</li>
                <li>Da Spedire (ID 76) se l'ordine si trova in Da attivare PeopleSocci (ID 50)</li>
              </ul>

              <p class="col-12 flowParag">
                Se l'ordine è Type = <strong>Abbonamento</strong>
              </p>
              <ul class="flowUl">
                <li>Da Ricaricare (ID 48) se l'ordine si trova in Da attivare PeopleSocci (ID 50) ----> DEVE FINIRE IN DA RICARICARE SOLO SE NELL'ORDINE E' PRESENTE UNA RICARICA, ALTRIMENTI PASSA IN DA SPEDIRE</li>
              </ul>

              <p class="col-12 flowParag">
                <br />PeopleSocci, in caso di fallimento dell'attivazione (NB.: Attivazione), qualsiasi sia il tipo dell'ordine, deve andare in:
              </p>

              <ul class="flowUl">
                <li>Attivazione in errore con PeopleSocci (ID 58)</li>
              </ul>


              <p class="col-12 flowParag">
                Inoltre, deve inviare una email ad elisa.magni@trestoreweb.it con il tipo di errore riscontrato e salvarlo anche nella cronologia dell'ordine.<br /><br />

                PeopleSocci, nella missione in cui fa le ricariche, deve prendere tutte le ricariche in coda (stato Da eseguire - ID 0).<br />
                Per ogni ordine, quando ha completato tutte le ricariche di tutti gli order product di quell'ordine, deve spostare l'ordine secondo la seguente logica:

              </p>

              <ul class="flowUl">
                <li>
                  Se l'ordine ha in cronologia l'avvenuta ricezione da parte del cliente, allora deve andare in Ricevuto - Completato (ID 29) e le MNP collegate devono andare in Da Inserire PeopleSocci (ID 2 MNP). Questa cosa ha la priorità su tutto. Se eseguita non esegue il resto della logica.
                </li>
                <li>
                  Se l'ordine è in Da Ricaricare (ID 48) e ha track_id_corriere = TNT (Tipo spedizione TNT) deve andare in Da spedire (ID 76). Se invece NON ha track_id_corriere = TNT deve rimanere in quello stato e non deve succedere niente.
                </li>
                <li>
                  Se l'ordine è in Da Ricaricare Spedite (ID 72) e ha track_id_corriere = TNT (Tipo spedizione TNT) deve andare in Spedito con TNT (ID 46). Se invece ha track_id_corriere = BRT (Tipo spedizione BRT) deve andare in Spedito con BRT (ID 47).
                </li>
              </ul>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">Shipping log e azioni automatiche</h6>

              <p class="col-12 flowParag">
                Nel momento in cui viene cambiato lo stato in CONSEGNATA (ID 3) alla spedizione di un ordine in SHIPPING LOG:
              </p>

              <ul class="flowUl">
                <li>
                  In ogni caso deve essere inserita nell'ordine una code note che identifica l'ordine come ricevuto dal cliente
                </li>
                <li>
                  Solo se l'ordine è Type = Ric senza vincolo e NON è stato attivato (vedi data attivazione negli order product o nell'ordine) allora deve andare in Ricevuto - In attivazione (ID 49).
                </li>
                <li>
                  Se l'ordine è stato attivato (ha numero di telefono attivo nell'order product - vedi azioni automatiche) allora deve andare in Ricevuto - Completato (ID 29) e le MNP collegate devono andare in Da Inserire PeopleSocci (ID 2 MNP).
                </li>
              </ul>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">Azioni automatiche (cron)</h6>

              <p class="col-12 flowParag">
                Deve essere creata un'azione automatica (cron - schedule) che deve verificare se il flusso BRT è abilitato nelle impostazioni.<br />
                Se il flusso è abilitato, l'invio viene effettuato due volte al giorno, alle 12:00 e alle 17:00 (ex "brt_integration/01_create_files.php"), e deve essere un task lanciato solo a quegli orari, mai in altri, tutti i giorni.
              </p>

              <div class="col-12 mb-3 mt-3"><hr /></div>
              <h6 class="col-12 flowHeading">MNP azioni automatiche</h6>

              <ul class="flowUl">
                <li>
                  Se la MNP viene inserita durante l'ordine va in stato "In attesa" (ID 7)
                </li>
                <li>
                  Se la MNP viene inserita in un secondo momento rispetto all'ordine, tramite la notifica apposita, va in stato "Da inserire PeopleSocci" (ID 2)
                </li>
              </ul>
            </div><!-- end of row -->
          </div>
        </div>

      </el-card>
    </div>
  </div>
</template>

<script>
  import { Breadcrumb, BreadcrumbItem, Switch as LSwitch } from 'src/components/index'

  export default {
    components: {
      LSwitch,
      Breadcrumb,
      BreadcrumbItem
    },
    computed: {},
    data () {
      return {
        componentSettings: {
          minimumPermissionLevel: 100
        },
        currentState: {},
        loading: false,
        canShowView: false,
        logs: null
      }
    },
    methods: {
      loadLogs () {
        this.loading = true
        this.$store.dispatch('settings/loadLogs', {lines: 100}).then((response) => {
          this.logs = response.data.log
          this.loading = false
        }).catch((e) => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei log')
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.loadLogs()
    },
    mounted: function () {},
    destroyed: function () {}
  }
</script>
<style>
  p.flowParag {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 5px;
  }
  h6.flowHeading {
    font-size: 17px;
    margin-bottom: 10px;
    text-transform: none;
  }
  ul.flowUl {
    font-size: 14px;
    margin-bottom: 5px;
  }
</style>
