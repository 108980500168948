<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi Campaga Email">
        <div slot="header">
          <campaign-email-form v-bind:emailCampaign="emailCampaign" @update="addElement"></campaign-email-form>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import CampaignEmailForm from './CampaignEmailForm'

  export default {
    components: {
      CampaignEmailForm
    },
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'CampaignEmailEdit',
          minimumPermissionLevel: 100,
          listPath: '/campagne-email/lista'
        },
        loading: false,
        canShowView: false,
        emailCampaign: {
          query_conditions: []
        }
      }
    },
    methods: {
      addElement (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('emailCampaigns/add', pElement).then((response) => {
          this.loading = false
          this.$router.push('/campagne-email/lista/modifica/' + response.data.email_campaign.id)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questa campagna email', '', false)
        })
      }
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
