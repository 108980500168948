<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card>
                <div slot="header">
                    <h4 class="title mt-0">Modifica di <strong>{{ mail_template.name }}</strong> <small>(ID:
                        {{ mail_template.id }})</small></h4>

                    <h5 v-if="!loading">Creato il: {{ dateToItalian(mail_template.created_at, true) }}</h5>
                    <p class="text-muted" v-if="!loading">Ultima modifica:
                        {{ dateToItalian(mail_template.updated_at, true) }}</p>


                    <div class="row">
                        <div class="col-12 col-md-3">
                            <router-link :to="{path:'/notifications/email/lista'}">
                                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista Email
                </span>
                                </button>
                            </router-link>
                        </div>
                    </div>

                </div>
                <email-form v-bind:mail_template="mail_template" v-loading="loading" @update="updateForm"
                            @delete="deleteElement"></email-form>


                <div>
                    <h4>Stati che usano questa notifica</h4>
                    <p v-if="matchedNotifications.length < 1">Non ci sono stati che usano questa notifica</p>
                    <el-table :data="matchedNotifications" v-loading="loading" style="width: 100%" stripe>
                        <el-table-column prop="order_type" label="Tipo ordine" width="180">
                            <template #default="scope">
                                <p v-if="scope.row.order_type_details">
                                    {{ scope.row.order_type_details.name }}
                                </p>
                                <p v-else>Qualsiasi tipologia</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="exit_status_id" label="Stato in uscita">
                            <template #default="scope">
                                <router-link v-if="scope.row.exit_status"
                                             :to="{path:'/stati/lista/modifica/' + scope.row.exit_status.id}">
                                    {{ scope.row.exit_status.internal_name }}
                                    ({{ scope.row.exit_status.name }})
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="entry_status_id" label="Stato in entrata">
                            <template #default="scope">
                                <router-link v-if="scope.row.entry_status"
                                             :to="{path:'/stati/lista/modifica/' + scope.row.entry_status.id}">
                                    {{ scope.row.entry_status.internal_name }}
                                    ({{ scope.row.entry_status.name }})
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column width="60" fixed="right">
                            <template #default="scope">
                                <a class="btn-danger btn-simple btn-link"
                                   @click="deleteNotification(scope.row)"><i class="fa fa-times"></i></a>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="row mt-4">
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-outline btn-fill"
                                @click.prevent="showAddNotification()">
                            Aggiungi una notifica <i class="el-icon-plus"></i>
                        </button>
                    </div>
                </div>

                <h4 v-if="isDeveloper">Variabili</h4>
                <div v-if="isDeveloper">
                    <ul>
                        <li v-for="(item) in variablesList">
                            <label v-if="item">{{ item }}</label>
                        </li>
                    </ul>
                </div>

                <!-- SOF DIALOG NOTIFICHE !-->
                <el-dialog title="Aggiungi notifica" v-model="displayAddNotification"
                           v-if="displayAddNotification"
                           class="dialog-options">


                    <p>Questa notifica verrà inviata ogni volta che un ordine effetta un cambio di stato e rispetta
                        tutte le
                        caratteristiche indicate</p>

                    <div class="row">
                        <div class="col-12">
                            <label>Tipologia</label>
                            <el-select v-loading="loading" style="width: 100%" class="select-default mb-3"
                                       v-model="selectedNotificationOrderType"
                                       placeholder="Tutti" size="large">

                                <el-option
                                    v-for="item in types"
                                    class="select-default"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Se lo stato di uscita è</label>
                            <el-select filterable v-loading="loading" style="width: 100%" class="select-default mb-3"
                                       v-model="exit_status_id"
                                       placeholder="..." size="large">
                                <el-option-group v-for="mother in statuses" :key="mother.id + 'group'"
                                                 :label="mother.internal_name">
                                    <el-option
                                        class="select-default"
                                        :key="mother.id"
                                        :label="mother.internal_name"
                                        :value="mother.id">
                                    </el-option>
                                    <el-option
                                        v-for="item in mother.child_statuses"
                                        class="select-default"
                                        :key="item.id"
                                        :label="item.internal_name"
                                        :value="item.id">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Se lo stato in ingresso è</label>
                            <el-select filterable v-loading="loading" style="width: 100%" class="select-default mb-3"
                                       v-model="entry_status_id"
                                       placeholder="..." size="large">
                                <el-option-group v-for="mother in statuses" :key="mother.id + 'group'"
                                                 :label="mother.internal_name">
                                    <el-option
                                        class="select-default"
                                        :key="mother.id"
                                        :label="mother.internal_name"
                                        :value="mother.id">
                                    </el-option>
                                    <el-option
                                        v-for="item in mother.child_statuses"
                                        class="select-default"
                                        :key="item.id"
                                        :label="item.internal_name"
                                        :value="item.id">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>

                    <div slot="footer" class="dialog-footer">

                        <el-button class="mr-2" @click="displayAddNotification = false">
                            Annulla
                        </el-button>

                        <el-button type="primary"
                                @click.prevent="addNotificationStatus()">
                            Aggiungi
                        </el-button>
                    </div>
                </el-dialog>

            </el-card>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from '../../../../components'
import EmailForm from './EmailForm'
import Swal from "sweetalert2";

export default {
    components: {
        EmailForm: EmailForm,
        LPagination,
        Breadcrumb,
        BreadcrumbItem
    },
    computed: {},
    watch: {
        '$route.params.id': function (val) {
            if (!val) {
                return
            }
            this.updateData()
            this.loadNotifications()
        }
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'EditEmail',
                minimumPermissionLevel: 100,
                listPath: '/notifications/email/lista'
            },
            loading: false,
            canShowView: false,
            mail_template: {
                default_attachments: []
            },
            variablesList: [],
            matchedNotifications: [],
            exit_status_id: null,
            entry_status_id: null,
            selectedNotificationOrderType: null,
            displayAddNotification: false,
            statuses: [],
            types: []
        }
    },
    methods: {
        addNotificationStatus() {
            if (!this.exit_status_id && !this.entry_status_id) {
                return this.$message.error("Almeno uno tra stato di uscita o stato d'ingresso devono essere impostati.");
            }

            this.loading = true

            let params = {
                notification_type: 1,
                template_id: this.$route.params.id,
                entry_status_id: this.entry_status_id,
                exit_status_id: this.exit_status_id
            }
            if (this.selectedNotificationOrderType) {
                params = Object.assign({}, params, {order_type: this.selectedNotificationOrderType})
            }
            this.$store.dispatch('orders/statuses/notifications/add', params).then(() => {
                this.loading = false
                this.displayAddNotification = false
                this.showSuccessMessage("Notifica aggiunta al sistema.")
                this.loadNotifications()
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.loading = false
            })
        },
        showAddNotification() {
            this.displayAddNotification = true
            this.selectedNotificationStatus = null
            this.selectedNotificationOrderType = null
        },
        updateData() {
            this.loading = true
            this.$store.dispatch('notifications/getEmailById', this.$route.params.id).then((response) => {
                console.log(response.data)
                this.mail_template = response.data.data
                this.variablesList = response.data.data.content_variables
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il recupero dei dati di questo template')
            })
        },
        updateForm(pElement, pRedirect = true) {
            this.loading = true
            this.$store.dispatch('notifications/emails/edit', pElement).then((response) => {
                this.loading = false
                this.mail_template = response.data.data
                this.showGenericSuccess('Template')
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'aggiornamento dei dati di questo template', '', false)
            })
        },
        deleteElement(pElement) {
            if (this.mail_template.locked) return
            Swal.fire(this.deleteMessage(this.mail_template.name)).then((result) => {
                if (!result.value) return
                this.$store.dispatch('notifications/emails/delete', pElement).then(() => {
                    Swal.fire('Eliminato!',
                        'Stato eliminato!',
                        'success'
                    ).then(() => {
                        this.$router.push(this.componentSettings.listPath)
                    })
                }).catch((e) => {
                    this.showLoadingError(e, 'l\'eliminazione del template', '', false)
                })
            })
        },
        loadNotifications() {
            this.$store.dispatch('orders/statuses/notifications/list', {
                template_ids: [parseInt(this.$route.params.id)],
                notification_type: 1,
                company_id: 1,
                limit: -1,
                status: true
            }).then((response) => {
                this.matchedNotifications = response.data.notifications
            }).catch(e => {
                this.showResponseErrorMessage(e)
            })
        },
        deleteNotification(pNotification) {
            this.askDeleteMessage('questa notifica').then((result) => {
                if (!result.value) return
                this.$store.dispatch('orders/statuses/notifications/delete', pNotification).then(() => {
                    this.loadNotifications()
                    this.showSuccessMessage("Notifica rimossa con successo.")
                }).catch((e) => {
                    this.showResponseErrorMessage(e)
                })
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.statuses = this.$store.getters['orders/statuses/statuses']
        this.types = this.$store.getters['orders/types']
        this.updateData()
        this.loadNotifications()
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
