<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">

      <el-card title="">
        <div class="row" v-loading="loading">
          <div class="col-12 mt-2 pl-4 pr-4">
            <div class="row">
            <h4 class="col-12 mt-0">Log
              <a href="#" data-tooltip="'Aggiorna tutti i log'" @click.prevent="refreshAllLogs" class="btn-simple btn-link a-link"><i class="el-icon-refresh" style="font-size: 19px;"></i></a>
              <a href="#" data-tooltip="'Cancella tutti i log'" @click.prevent="clearAllLogs" class="btn-simple btn-link a-link"><i class="el-icon-delete" style="font-size: 19px;"></i></a>
            </h4>
            </div>
            <div style="font-size: 13px;">
            <el-collapse>
              <el-collapse-item title="General log" name="generalLog">

                <p class="pt-1 mt-1" style="text-align:center;">Aggiorna i general log: <a href="#" data-tooltip="'Aggiorna i general log'" @click.prevent="loadGeneralLog" class="btn-simple btn-link a-link"><i class="el-icon-refresh" style="font-size: 19px;"></i></a></p>

                <pre v-if="generalLog" style="white-space: pre-wrap; background-color: black; color: #00ff00;">{{generalLog}}</pre>

                <p class="pt-1 mt-1" style="text-align:center;" v-if="generalLog">Aggiorna i general log: <a href="#" data-tooltip="'Aggiorna i general log'" @click.prevent="loadGeneralLog" class="btn-simple btn-link a-link"><i class="el-icon-refresh" style="font-size: 19px;"></i></a></p>
              </el-collapse-item>
            </el-collapse>
              <el-collapse>
                <el-collapse-item title="Scheduler log" name="schedulerLog">
                  <p class="pt-1 mt-1" style="text-align:center;">Aggiorna gli scheduler log: <a href="#" data-tooltip="'Aggiorna gli scheduler log'" @click.prevent="loadSchedulerLog" class="btn-simple btn-link a-link"><i class="el-icon-refresh" style="font-size: 19px;"></i></a></p>

                  <pre v-if="schedulerLog" style="white-space: pre-wrap; background-color: black; color: #00ff00;">{{schedulerLog}}</pre>

                  <p class="pt-1 mt-1" style="text-align:center;" v-if="schedulerLog">Aggiorna gli scheduler log: <a href="#" data-tooltip="'Aggiorna gli scheduler log'" @click.prevent="loadSchedulerLog" class="btn-simple btn-link a-link"><i class="el-icon-refresh" style="font-size: 19px;"></i></a></p>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </el-card>

    </div>
  </div>
</template>
<script>
  import { Breadcrumb, BreadcrumbItem, Switch as LSwitch } from 'src/components'

  export default {
    components: {
      LSwitch,
      Breadcrumb,
      BreadcrumbItem
    },
    computed: {},
    data () {
      return {
        componentSettings: {
          minimumPermissionLevel: 100
        },
        currentState: {},
        loading: false,
        canShowView: false,
        generalLog: null,
        schedulerLog: null,
        activeName: 'generalLog'
      }
    },
    methods: {
      clearGeneralLog () {
        this.loading = true
        this.$store.dispatch('settings/clearGeneralLog').then((response) => {
          this.loadGeneralLog()
          this.loading = false
        }).catch((e) => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il clear dei general log')
        })
      },
      clearSchedulerLog () {
        this.loading = true
        this.$store.dispatch('settings/clearSchedulerLog').then((response) => {
          this.loadSchedulerLog()
          this.loading = false
        }).catch((e) => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il clear dei general log')
        })
      },
      clearAllLogs () {
        this.askGenericConfirm('Vuoi eliminare tutti i log?', 'Premendo Sì confermerai la cancellazione', 'Sì, cancella!', 'No, ho sbagliato!').then((result) => {
          if (!result.value) {
            return
          }
          this.clearGeneralLog()
          this.clearSchedulerLog()
        })
      },
      loadGeneralLog () {
        this.loading = true
        this.$store.dispatch('settings/loadGeneralLog', {lines: 300}).then((response) => {
          this.generalLog = response.data.log
          this.loading = false
        }).catch((e) => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento dei general log')
        })
      },
      loadSchedulerLog () {
        this.loading = true
        this.$store.dispatch('settings/loadSchedulerLog', {lines: 300}).then((response) => {
          this.schedulerLog = response.data.log
          this.loading = false
        }).catch((e) => {
          this.loading = false
          this.showLoadingErrorWithoutRetry(e, 'il caricamento degli scheduler log')
        })
      },
      refreshAllLogs () {
        this.loadGeneralLog()
        this.loadSchedulerLog()
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.refreshAllLogs()
    },
    mounted: function () {},
    destroyed: function () {}
  }
</script>
<style></style>
