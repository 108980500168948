import Config from '../../globalConfig'
import axios from 'axios'

const utility = {
  namespaced: true,
  actions: {
    async cfLookUp ({commit, state, rootGetters}, cf) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, {cf: cf}, rootGetters.apiCommon)
        axios.post(Config.endpoints.utility.cfLookUp.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async checkUserIdentifier ({commit, state, rootGetters}, user_identifier) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, {user_identifier: user_identifier}, rootGetters.apiCommon)
        axios.post(Config.endpoints.utility.checkUserIdentifier.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getFasceVelocitaAgcom ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, {}, rootGetters.apiCommon)
        axios.post(Config.endpoints.infratel.getFasceVelocitaAgcom, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default utility
