<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Modifica prodotto">
        <div slot="header">
          <h4 class="title mt-0">Modifica di <strong>{{product.internal_name}}</strong> <small>(ID: {{product.id}})</small></h4>

          <h5 v-if="!loading">Creato il: {{dateToItalian(product.created_at, true)}}</h5>
          <p class="text-muted" v-if="!loading">Ultima modifica: {{dateToItalian(product.updated_at, true)}}</p>


          <div class="row">
            <div class="col-12 col-md-3">
              <router-link :to="{path:'/prodotti/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista prodotti
                </span>
                </button>
              </router-link>
            </div>
          </div>

        </div>
        <product-form v-bind:product="product" v-loading="loading" @update="updateForm" @updateColors="updateColors" @delete="deleteElement"></product-form>

        <div v-if="product.offerte && product.offerte.length > 0">
          <h4 class="title">Offerte associate a <strong>{{product.internal_name}}</strong> <small>(ID: {{product.id}})</small></h4>
          <ul>
            <li v-for="(item) in product.offerte">
              <router-link data-tooltip="'Modifica offerta'" :to="{path:'/offerte/lista/modifica/' + item.id}" style="color: #616161;">{{item.name}} <i class="el-icon-edit"></i></router-link>
              <small v-if="item.internal_name" class="n-thin"> {{item.internal_name}}</small>
              <small class="n-thin"> (ID: {{item.id}})</small>
              <small><a :href="item.checkout_url" target="_blank" data-tooltip="'Vai al carrello'"><span class="a-link btn-link"><i class="fa fa-shopping-cart mt-1"></i></span></a></small>
            </li>
          </ul>
        </div>

      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  import ProductForm from './ProductForm.vue'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
      ProductForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'ProductEdit',
          minimumPermissionLevel: 100,
          listPath: '/prodotti/lista'
        },
        loading: false,
        canShowView: false,
        product: {}
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('products/getById', this.$route.params.id).then((response) => {
          this.product = response.data.product
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questo prodotto')
        })
      },
      updateForm (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('products/edit', pElement).then((response) => {
          this.loading = false
          // this.product = response.data.product
          this.updateData() // Ricarico i dati per non perdere relazione media/offers
          this.showGenericSuccess('Prodotto')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questo prodotto', '', false)
        })
      },
      updateColors (pColors) {
        this.product.colors = pColors
      },
      deleteElement (pElement) {
        Swal.fire(this.deleteMessage('questo prodotto')).then((result) => {
          if (!result.value) return
          this.$store.dispatch('products/delete', pElement).then(() => {
            Swal.fire('Eliminato!',
              'Il prodotto è stato eliminato.',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione del prodotto', '', false)
          })
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
