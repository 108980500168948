<template>

  <div id="orderStatisticsResultRow" class="row pb-0">
    <div class="col-12">

      <div  class="row">
        <div class="col-12">
          <h4 class="mt-0" style="font-weight: 400 !important;">Totali: <strong style="font-weight: 800 !important;">{{statistics.totals.count}}</strong> - Completati: <strong style="font-weight: 800 !important;">{{statistics.totals.completed}}</strong></h4>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-collapse v-if="orderActivatedFromTo && orderActivatedFromTo.length === 0" @change="handleHasChanged">
            <el-collapse-item title="Statistiche giornaliere" name="totalStatistics">

              <div class="col-12 mt-2 px-0">
                <el-card title="Statistiche giornaliere" subTitle="Ordini totali suddivisi per giorno">
                  <div id="dailyOrdersChart" class="ct-chart"></div>
                </el-card>
              </div>

              <div class="col-12 px-0 mt-2">
                <el-table class="table-striped" :data="statistics.daily" border>
                  <el-table-column
                    label="Data">
                    <template #default="scope">
                      {{ dateToItalian(scope.row.date, false, 'DD/MM/YYYY') }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="total"
                    label="Tutti">
                  </el-table-column>
                  <el-table-column
                    prop="completed"
                    label="Completi">
                  </el-table-column>
                  <el-table-column
                    prop="incomplete"
                    label="Incompleti">
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-collapse @change="handleHasChanged">
            <el-collapse-item title="Statistiche per stato" name="statusStatistics">
              <el-card class="col-12 mt-2 px-0" title="Statistiche per stato" subTitle="Ordini suddivisi per stato">

                <div id="statusChart" class="ct-chart col-md-6" style="float:right; display: flex;"></div>

                <div class="col-12 col-md-6 px-0 px-0">
                  <el-table class="table-striped" :data="statistics.statuses" border style="float:left;">
                    <el-table-column
                      prop="name"
                      label="Nome stato">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="Totale"
                      width="90">
                    </el-table-column>
                    <el-table-column
                      prop="completed"
                      label="Completi"
                      width="90">
                    </el-table-column>
                  </el-table>
                </div>

              </el-card>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="row">
        <div class="col-12" v-if="statistics.customers && statistics.customers.length !== 0">
          <el-collapse>
            <el-collapse-item title="Statistiche per tipo cliente" name="customerStatistics">
              <el-card class="col-12 mt-2 px-0" title="Statistiche per tipo cliente" subTitle="Ordini suddivisi per tipo cliente">
                <h5 style="font-size: 15px;" class="mb-1"><i>Sesso</i></h5>

                <div class="col-12 col-md-12 col-lg-8 col-xl-6 px-0 mb-2">
                  <el-table class="table-striped" :data="statistics.customers.sex" border>
                    <el-table-column
                      prop="name"
                      label="Genere">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="Totale"
                      width="90">
                    </el-table-column>
                  </el-table>
                </div>

                <div class="col-12 col-md-12 col-lg-8 col-xl-6 px-0">
                  <el-table class="table-striped" :data="statistics.customers.types" border>
                    <el-table-column
                      prop="name"
                      label="Tipologia cliente">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="Totale"
                      width="90">
                    </el-table-column>
                  </el-table>
                </div>

              </el-card>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-collapse>
            <el-collapse-item title="Statistiche per offerta" name="offerStatistics">
              <el-card class="col-12 mt-2 px-0" title="Statistiche per offerta" subTitle="Ordini suddivisi per offerta">

                <div class="col-12 col-md-12 col-lg-9 col-xl-7 px-0">
                  <el-table class="table-striped" :data="statistics.offers" border>
                    <el-table-column
                      prop="name"
                      label="Nome offerta">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="Totale"
                      width="90">
                    </el-table-column>
                    <el-table-column
                      prop="completed"
                      label="Completi"
                      width="90">
                    </el-table-column>
                  </el-table>
                </div>

              </el-card>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-collapse class="pb-0">
            <el-collapse-item class="pb-0" title="Statistiche per provenienza" name="originStatistics" style="padding-bottom: 0;">
              <el-card class="col-12 mt-2 px-0 pb-0" title="Statistiche per provenienza" subTitle="Ordini suddivisi per provenienza">

                <div class="col-12 col-md-12 col-lg-8 px-0">
                  <el-table class="table-striped" :data="statistics.origins" border>
                    <el-table-column
                      prop="name"
                      label="Provenienza">
                      <template v-slot="props">
                        <div v-if="props.row">
                          <p class="m-0">{{props.row.name}}</p>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="Totale"/>
                    <el-table-column width="70">
                      <template v-slot="props">
                        <p v-if="props.row.name !== 'Non specificato'" class="m-0" @click="filterOrigin(props.row.name)"><i class="fa-solid fa-magnifying-glass ml-2 text-primary"></i></p>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

              </el-card>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

    </div><!-- end of col -->
  </div><!-- end of row -->
</template>

<style>
  .badge-pill {
    padding-left: 1em;
    padding-right: 1em;
  }

  .badge-grey {
    background-color: #e6e6e6;
  }

  .chartist-tooltip {
    position: absolute;
    background: rgba(0,0,0,0.6);
    color: white;
    padding: 0px 10px;
    border-radius: 5px;
  }
</style>

<script>
  import Chartist from 'chartist'
  // eslint-disable-next-line no-unused-vars
  import ChartistTooltip from 'chartist-plugin-tooltips-updated'
  import OrderStatistics from './OrderStatistics'

  export default {
    components: {
      OrderStatistics
    },
    props: ['statistics', 'orderActivatedFromTo'],
    data () {
      return {
        dataStock: null,
        optionsStock: null,
        dates: [],
        ordersNumber: [],
        ordersForStatuses: [],
        isOpened: false
      }
    },
    watch: {},
    computed: {},
    methods: {
      initOrdersForDayChart () {
        for (let i = 0; i < this.statistics.daily.length; i++) {
          let dateToIta = this.dateToItalian(this.statistics.daily[i].date, false, 'DD/MM/YYYY')
          this.dates[i] = dateToIta
          this.ordersNumber[i] = {meta: dateToIta, value: this.statistics.daily[i].total}
        }
        this.data = {
          labels: this.dates,
          series: [this.ordersNumber]
        }
        this.options = {
          lineSmooth: true,
          axisY: {
            offset: 40,
            labelInterpolationFnc (value) {
              return `${value}`
            }
          },
          axisX: {
            showGrid: true,
            showLabel: false
          },
          low: 0,
          high: Math.max(this.ordersNumber),
          height: '250px',
          fullWidth: true,
          classNames: {
            point: 'ct-point ct-blue',
            line: 'ct-line ct-blue'
          },
          plugins: [
            ChartistTooltip({
              anchorToPoint: true,
              appendToBody: false
            })
          ]
        }
        Chartist.Line('#dailyOrdersChart', this.data, this.options)
      },
      initStatusesChart () {
        for (let k = 1; k < this.statistics.statuses.length; k++) {
          this.ordersForStatuses[k] = this.statistics.statuses[k].count
        }
        let data = {
          series: this.ordersForStatuses
        }

        Chartist.Pie('#statusChart', data, {
          donut: true,
          donutWidth: 50,
          donutSolid: false,
          startAngle: 0,
          showLabel: true
        })
      },
      handleHasChanged () {
        if (!this.isOpened) {
          this.isOpened = true
        } else {
          this.isOpened = false
        }
        if (this.isOpened) {
          this.initOrdersForDayChart()
          this.initStatusesChart()
        }
      },
      filterOrigin(value) {
        this.$emit('filterOrigin', value)
      }
    },
    created: function () {
    },
    mounted () {
    },
    destroyed: function () {
    }
  }
</script>
