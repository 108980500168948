<template>
    <div class="row" v-if="canShowView">

        <div v-if="isLoading"
             style="position: fixed; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.9); z-index: 1000; top: 0; left: 0;">
            <h3 style="text-align: center; font-weight: 200 !important;">Esporto CSV attivazioni...</h3>
        </div>

        <div class="col-12">
            <el-card title="Ottieni statistiche attivazioni">
                <el-form v-loading="loading" label-position="top">
                    <div class="row">
                        <div class="col-12">
                            <el-form-item label="Attivate dal">
                                <el-date-picker
                                    v-model="activationDateFromTo"
                                    type="daterange"
                                    range-separator="al"
                                    format="DD-MM-YYYY"
                                    value-format="YYYY-MM-DD HH:mm:ss"
                                    :picker-options="pickerOptions"
                                    :shortcuts="pickerOptions.shortcuts"
                                    @change="changedActivationDate"
                                    language="it">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <el-form-item label="Filtra per">
                                <el-select v-loading="loading" style="width: 100%" class="select-default"
                                           v-model="currentState.sortBy" placeholder="Seleziona...">
                                    <el-option
                                        v-for="item in componentSettings.sortByItems"
                                        class="select-default"
                                        :key="item.key"
                                        :label="item.value"
                                        :value="item.key"
                                        name="sortBy">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="col-12 col-md-6" v-if="currentState.sortBy">
                            <div v-if="currentState.sortBy === 'offers'">
                                <el-form-item label="Offerte">
                                    <offers-select v-model="formData.offers" :multiple="true" :only_id="true"/>
                                </el-form-item>
                            </div>
                            <div v-if="currentState.sortBy === 'types'">
                                <el-form-item label="Offerte">
                                    <offer-type-select v-model="formData.types" :multiple="true"/>
                                </el-form-item>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <el-form-item label="La provenienza è uguale a">
                                <partner-select v-model="formData.origin"/>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row mb-3 mt-4">
                        <div class="col-12 text-right">

                            <el-button type="text" class="mr-2" @click.prevent="exportStats"
                                       :disabled="!activationDateFromTo || activationDateFromTo.length === 0 ">Esporta
                                CSV attivazioni
                            </el-button>

                            <el-button type="primary" @click.prevent="sendForm"
                                       :disabled="!activationDateFromTo || activationDateFromTo.length === 0 ">Calcola
                            </el-button>
                        </div>
                    </div>
                </el-form>
            </el-card>
            <el-card title="Risultato statistiche attivazioni" v-if="statisticsResult" class="mt-2">
                <h5 v-if="statisticsResult.count == 0">Non sono disponibili statistiche basate su questi dati. Prova a
                    fare una
                    ricerca diversa.</h5>
                <activations-statistics-result v-bind:statistics="statisticsResult"/>
            </el-card>
        </div>

    </div>
</template>
<script>
import {Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
import ActivationsStatisticsResult from './ActivationsStatisticsResult'
import OffersSelect from "@/components/Inputs/OffersSelect";
import OfferTypeSelect from "@/components/Inputs/OfferTypeSelect";
import PartnerSelect from "@/components/Inputs/PartnerSelect";

export default {
    components: {
        PartnerSelect,
        OfferTypeSelect,
        OffersSelect,
        ActivationsStatisticsResult,
        LSwitch,
        Breadcrumb,
        BreadcrumbItem
    },
    computed: {},
    data() {
        return {
            componentSettings: {
                minimumPermissionLevel: 200,
                sortByItems: [
                    {
                        key: 'offers',
                        value: 'Offerte'
                    },
                    {
                        key: 'types',
                        value: 'Tipo'
                    },
                ]
            },
            datePickerOptions: {},
            currentState: {
                dateDisabled: false,
                sortBy: null
            },
            loading: false,
            isLoading: false,
            canShowView: false,
            statisticsResult: null,
            formData: {
                origin: null,
                offers: []
            },
            offers: [],
            orderTypes: {},
            activationDateFromTo: [],
            pickerOptions: {
                shortcuts: [{
                    text: 'Ultime 24 ore',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimi 7 gg',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimo mese',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimi 2 mesi',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
                        return [start, end]
                    }
                }, {
                    text: 'Ultimi 6 mesi',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
                        return [start, end]
                    }
                }]
            }
        }
    },
    watch: {
        'currentState.sortBy': function (val) {
            this.formData.offers = []
            if (val === 'offers' && this.offers.length <= 0) {
                this.loadOffers()
            }
        }
    },
    methods: {
        changedActivationDate(pDate) {
            console.log(pDate)
            if (pDate) {
                let interval = this.daysBetweenDates(pDate[0], pDate[1])
                if (interval > 180) {
                    this.showGenericError('Errore!', 'Hai selezionato un intervallo troppo lungo.<br />Puoi selezionare massimo 180 giorni.')
                    this.activationDateFromTo = []
                }
            }
        },
        exportStats() {
            this.statisticsResult = null
            this.isLoading = true
            if (!this.activationDateFromTo || this.activationDateFromTo.length < 2) {
                this.formData.date_from = null
                this.formData.date_to = null
            } else {
                this.formData.date_from = this.activationDateFromTo[0]
                this.formData.date_to = this.activationDateFromTo[1]
            }
            this.$store.dispatch('statistics/activationStatsExport', this.formData).then((response) => {
                this.isLoading = false
                let today = new Date()
                let dd = String(today.getDate()).padStart(2, '0')
                let mm = String(today.getMonth() + 1).padStart(2, '0')
                let yyyy = today.getFullYear()
                let fileDownload = require('js-file-download')
                fileDownload(response.data, 'Activations_Export_' + dd + '_' + mm + '_' + yyyy + '.csv', 'application/csv')
            }).catch((e) => {
                this.isLoading = false
                this.showLoadingErrorWithoutRetry(e, 'il download delle statistiche')
            })
        },
        loadOffers() {
            this.loading = true
            this.$store.dispatch('offers/list', {
                limit: -1,
                order_direction: 'asc',
                orderby: 'id',
                enabled: true
            }).then((response) => {
                this.offers = response.data.offers
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingError(e)
            })
        },
        loadOrderTypes() {
            this.loading = true
            this.$store.dispatch('orders/getTypes').then((response) => {
                this.orderTypes = response.data.order_types
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingError(e)
            })
        },
        sendForm() {
            this.statisticsResult = null
            this.loading = true
            if (!this.activationDateFromTo || this.activationDateFromTo.length < 2) {
                this.formData.date_from = null
                this.formData.date_to = null
            } else {
                this.formData.date_from = this.activationDateFromTo[0]
                this.formData.date_to = this.activationDateFromTo[1]
            }

            if (this.currentState.sortBy === 'types') {
                let types = [];
                this.formData.types.forEach(element => {
                    types.push(element.code)
                })

                this.formData.offer_types = types;
            }

            this.$store.dispatch('statistics/activationStats', this.formData).then((response) => {
                this.statisticsResult = response.data.activations
                this.loading = false
            }).catch((e) => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle statistiche')
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.loadOrderTypes()
        this.canShowView = true
        this.loading = false
    },
    mounted: function () {
    },
    destroyed: function () {
    }
}
</script>
<style></style>
