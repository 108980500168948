import Config from '../../globalConfig'
import axios from 'axios'

const olo = {
  namespaced: true,
  actions: {
    async paginatedList ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)

        axios.post(Config.endpoints.olo.paginatedList, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async createXMLRequest ({commit, state, rootGetters}, pElement){
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.olo.getOloCodeDonating, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getOloCodeDonating ({commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.olo.getOloCodeDonating, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default olo;
