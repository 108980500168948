<template>

  <div>
    <form>
      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="Nome"
                    v-model="peoplesoft.internal_name"
                    name="internal_name"
                    v-validate="modelValidations.internal_name"
                    :error="getError('internal_name')">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="Bundle in attivazione"
                    v-model="peoplesoft.bundle"
                    name="bundle">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="ID offerta dedicata su PS"
                    v-model="peoplesoft.offertadedicata"
                    name="offertadedicata">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="ID offerta dedicata su PS (nome per CA3)"
                    v-model="peoplesoft.offertadedicata_ca3"
                    name="offertadedicata_ca3">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="ID offerta terminale"
                    v-model="peoplesoft.offerta_terminale"
                    name="offerta_terminale">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="Categoria offerta"
                    v-model="peoplesoft.categoria_offerta"
                    name="categoria_offerta">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="Terminale abbinato"
                    v-model="peoplesoft.terminaleabbinato"
                    name="terminaleabbinato">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="Nome opzione su PS (usato per CA3)"
                    v-model="peoplesoft.offerta_magica_attivazione"
                    name="offerta_magica_attivazione">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="ID opzione su PS (usato per Dealer)"
                    v-model="peoplesoft.opzione_attivare"
                    name="opzione_attivare">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4">
          <label>Data sottoscrizione <small>(gg-mm-aaaa)</small></label>
          <br/>
          <el-date-picker
            v-model="peoplesoft.subscription_date"
            type="date"
            placeholder="Seleziona..."
            label="Data sottoscrizione"
            :picker-options="datePickerOptions"
            format="DD-MM-YYYY"
            value-format="YYYY-MM-DD"
            language="it">
          </el-date-picker>
          <div class="mb-2 n-lightgrey"><small>(Se non definito prende la data dell'ordine)</small></div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="Modalità acquisizione (vuoto default)"
                    v-model="peoplesoft.modalita_acquisizione"
                    name="modalita_acquisizione">
          </el-input>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <el-input type="text"
                    label="POS (vuoto default)"
                    v-model="peoplesoft.pos"
                    name="pos">
          </el-input>
        </div>

      </div>


      <div class="row">
        <div class="col-12">
          <hr />
          <h4>Opzione abbinata</h4>
          <p>Permette di selezionare automaticamente su PeopleSoft una determinata opzione abbinata a questa offerta a prescindere da eventuali selezioni di opzioni fatte dal cliente.<br />Lasciare vuoto se non necessario.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-3">
          <el-input type="text"
                    label="Offerta (es.: STND)"
                    v-model="peoplesoft.additional_option_offer"
                    name="additional_option_offer">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <el-input type="text"
                    label="Categoria (es.: PS000740_PRD)"
                    v-model="peoplesoft.additional_option_category"
                    name="additional_option_category">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <el-input type="text"
                    label="ID Opzione (numerico)"
                    v-model="peoplesoft.additional_option_code"
                    name="additional_option_code">
          </el-input>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <el-input type="text"
                    label="Nome Opzione PS (testuale)"
                    v-model="peoplesoft.additional_option_ca3"
                    name="additional_option_ca3">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>


      <div class="row">
        <div class="col-md-3 col-12">
          <label>Dealer</label>
          <br />
          <l-switch v-model="peoplesoft.dealer" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
        <div class="col-md-3 col-12">
          <label>Accesso portale</label>
          <br />
          <l-switch v-model="peoplesoft.accesso_portale" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
        <div class="col-md-3 col-12">
          <label>Promocode richiesto</label>
          <br />
          <l-switch v-model="peoplesoft.promocode_richiesto" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
        <div class="col-md-3 col-12">
          <label>MNP fittizia</label>
          <br />
          <l-switch v-model="peoplesoft.mnp_fittizia" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <label>Checkbox attivazione CA3</label>
          <br />
          <el-transfer class="el-transfer-ca3" filterable :filter-method="filterMethod" filter-placeholder="Operatore" v-model="peoplesoft.checkbox_attivazione_ca3" :data="ca3CheckboxList" v-loading="loading" :titles="['Disponibili', 'Selezionate qui']">
            <div slot-scope="{option}" style="line-height: 1.1; margin-bottom: 6px;">
              <span>{{option.label}}</span><br /><span><small>({{option.key}})</small></span>
            </div>
          </el-transfer>
        </div>
      </div>

      <div class="row" style="margin-top: 60px">
        <div class="col-12 text-center">

          <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement" v-if="peoplesoft.id">
            Elimina
          </button>

          <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
            Salva
          </button>

        </div>
      </div>

      <div class="clearfix"></div>
    </form>
  </div>
</template>
<style>
  .el-transfer-panel {
    width: 340px;
  }

  .el-transfer-panel__body {
    width: 340px;
  }
  .el-transfer-panel__list.is-filterable {
    width: 100% !important;
  }
  .el-transfer-ca3 .el-transfer-panel__item.el-checkbox {
    margin-bottom: 13px;
  }
</style>
<script>
  import {Switch as LSwitch} from 'src/components/index'

  export default {
    components: {
      [Option.name]: Option,
      [Button.name]: Button,
      LSwitch
    },
    props: {
      peoplesoft: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/offerte/peoplesoft/lista'
        },
        content: '',
        loading: false,
        modelValidations: {
          internal_name: {
            required: true
          }
        },
        ca3CheckboxList: [
          {key: 'C-PS000761_PRD', label: 'Acquisizione: ALL-IN Smart VR 30 mesi'},
          {key: 'C-PS000781_PRD', label: 'Acquisizione: FREE Vendita a rate'},
          {key: 'C-PS000188_SPKG1', label: 'Acquisizione: Vendita a Rate Pre'},
          {key: 'C-PS000791_PRD', label: 'ALL-IN Plus 12 mesi'},
          {key: 'C-PS000797_PRD', label: 'ALL-IN Plus 24 mesi'},
          {key: 'C-PS000128_PKG', label: 'Power29'},
          {key: 'C-PS000127_PKG', label: 'Power15'},
          {key: 'C-PS000045_SPKG1', label: 'Acquisto Prepagata Consumer'},
          {key: 'C-PS000084_SPKG1', label: 'Profilazione Pianeta3'},
          {key: 'C-PS000304_PRD', label: 'Accesso Portale'},
          {key: 'C-PS000202_SPKG1', label: 'Richiesta MNP (1)'},
          {key: 'C-PS000634_PRD', label: 'Richiesta MNP (2)'},
          {key: 'C-PS000263_SPKG1', label: 'Template SO pre cons voce'},
          {key: 'C-PS000264_SPKG1', label: 'Template SO pre cons dati'},
          {key: 'C-PS000298_SPKG1', label: 'Ricarica automatica (1)'},
          {key: 'C-PS000776_PRD', label: 'Ricarica automatica (2)'},
          {key: 'C-PS000738_PRD', label: 'Ricaricabile con terminale'},
          {key: 'C-PS000739_PRD', label: 'Offerta internazionale'},
          {key: 'C-PS000573_PRD', label: 'USIM Sciolta Ric - Offerta'},
          {key: 'C-PS000737_PRD', label: 'Ricaricabile sciolta'},
          {key: 'C-PS000063_PRD', label: 'USIM Sciolta - lista prodotti'},
          {key: 'C-PS000117_PKG', label: 'Super Web New'},
          {key: 'C-PS000076_PKG', label: 'Super Web Time'},
          {key: 'C-PS000030_PKG', label: 'Offerta 3 Cinquanta'},
          {key: 'C-PS000740_PRD', label: 'Altre opzioni'}
        ],
        datePickerOptions: {}
      }
    },
    watch: {},
    computed: {},
    methods: {
      deleteElement () {
        this.$emit('delete', this.peoplesoft)
      },
      validateForm () {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.$emit('update', this.peoplesoft)
          }
        })
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      filterMethod (query, item) {
        if (item.key.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.label.toLowerCase().indexOf(query.toLowerCase()) > -1) return true
        return false
      }
    },
    created: function () {},
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
