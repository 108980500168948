<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi template e-mail">
        <email-form v-bind:mail_template="mail_template" v-loading="loading" @update="updateForm"></email-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import EmailForm from './EmailForm.vue'

  export default {
    components: {
      EmailForm: EmailForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'EmailAdd',
          minimumPermissionLevel: 100,
          listPath: '/notifications/email/lista'
        },
        loading: false,
        canShowView: false,
        mail_template: {
          content: '',
          locked: false,
          is_new: true,
          empty_template: false
        },
        show_flag_html_code: true
      }
    },
    methods: {
      updateForm (pElement) {
        this.loading = true
        console.log(pElement)
        this.$store.dispatch('notifications/emails/add', pElement).then((response) => {
          this.loading = false
          this.$router.push('/notifications/email/lista/modifica/' + response.data.mail_template.id)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questo template email', '', '', false)
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
    },
    mounted: function () {
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
