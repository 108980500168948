import Config from '../../globalConfig'
import axios from 'axios'

const terms = {
  namespaced: true,
  modules: {},
  state: {
    flows: [],
    statuses: []
  },
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pStatus) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pStatus) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async list ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(rootGetters.apiCommon)
        axios.post(Config.endpoints.terms.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, params) {
      return new Promise((resolve, reject) => {
        params = Object.assign(rootGetters.apiCommon, params)
        axios.post(Config.endpoints.terms.delete, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default terms
