<template>
  <div>
    <form>
      <div class="row">
        <div class="col-12 col-md-6">
          <el-input type="text"
                    label="Nome al pubblico"
                    v-model="promo_code_type.public_name"
                    name="public_name"
                    v-validate="modelValidations.public_name"
                    :error="getError('public_name')">
          </el-input>
        </div>
        <div class="col-12 col-md-6">
          <el-input type="text"
                    label="Nome interno"
                    v-model="promo_code_type.internal_name"
                    name="internal_name"
                    v-validate="modelValidations.internal_name"
                    :error="getError('internal_name')">
          </el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12">
          <label>Descrizione interna</label>
          <textarea class="form-control" rows="3"
                    label="Descrizione interna"
                    name="internal_description"
                    v-model="promo_code_type.internal_description"
                    v-validate="modelValidations.internal_description"
                    :error="getError('internal_description')">
          </textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3 col-12 mb-3" style="max-width: 130px;">
          <label>Abilita</label>
          <br />
          <l-switch v-model="promo_code_type.enabled" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
        <div class="col-md-4 col-12">
          <label>Mostra nelle statistiche</label>
          <br />
          <l-switch v-model="promo_code_type.show_stats" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
      </div>


      <div class="row" style="margin-top: 30px">
        <div class="col-12 text-center">

          <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement" v-if="promo_code_type.id">
            Elimina
          </button>

          <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
            Salva
          </button>

        </div>
      </div>

    </form>
  </div>
</template>
<style>
</style>
<script>
  import {Switch as LSwitch} from 'src/components/index'
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      LSwitch,
      'editor': Editor
    },
    props: {
      promo_code_type: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/promocode/lista'
        },
        content: '',
        loading: false,
        modelValidations: {
          public_name: {
            required: true
          },
          internal_name: {
            required: true
          }
        }
      }
    },
    watch: {},
    computed: {},
    methods: {
      deleteElement () {
        this.$emit('delete', this.promo_code_type)
      },
      validateForm () {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.$emit('update', this.promo_code_type)
          }
        })
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      loadResources () {}
    },
    created: function () {
      this.loadResources()
    },
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
