import Config from '../../../globalConfig'
import axios from 'axios'

const medias = {
  namespaced: true,
  state: {},
  getters: {
    canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    },
    canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
      if (rootGetters.role > 100) return false
      return true
    }
  },
  mutations: {},
  actions: {
    async check ({commit, state, rootGetters}, pAttachment) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.medias.base + pAttachment.id + '/check/', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async compress ({commit, state, rootGetters}, pAttachment) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.medias.base + pAttachment.id + '/compress/', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.orders.base + pElement.order_id + '/medias/' + pElement.id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async edit ({commit, state, rootGetters}, pAttachment) {
      return new Promise((resolve, reject) => {
        var parameters = Object.assign({}, {
          original_name: pAttachment.name,
          type: pAttachment.type
        }, rootGetters.apiCommon)
        axios.post(Config.endpoints.medias.base + pAttachment.id + '/edit', parameters)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, pOrder) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + pOrder.id + '/medias/list', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async download ({commit, state, rootGetters}, mediaId) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.medias.base + mediaId + '/download', params, {
          responseType: 'arraybuffer'
        })
          .then((response) => {
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getIdentityDocument ({commit, state, rootGetters}, peyload) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + peyload.orderId + '/medias/get-identity-documents', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async getOldBillDocuments ({commit, state, rootGetters}, peyload) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, rootGetters.apiCommon)
        axios.post(Config.endpoints.orders.base + peyload.orderId + '/medias/get-old-operator-bills', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async optimoMergeAndSign ({commit, state, rootGetters}, pParams) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({files: pParams.files}, rootGetters.apiCommon)
        const axiosClient = axios.create()
        axiosClient.defaults.timeout = 120000 // Custom timeout for Optimizer
        axiosClient.post(Config.endpoints.orders.base + pParams.order_id + '/optimo/merge-and-sign', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}

export default medias
