<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Modifica peoplesoft">
        <div slot="header">
          <h4 class="title mt-0">Modifica di <strong>{{peoplesoft.internal_name}}</strong> <small>(ID: {{peoplesoft.id}})</small></h4>

          <h5 v-if="!loading">Creato il: {{dateToItalian(peoplesoft.created_at, true)}}</h5>
          <p class="text-muted" v-if="!loading">Ultima modifica: {{dateToItalian(peoplesoft.updated_at, true)}}</p>


          <div class="row">
            <div class="col-12 col-md-3">
              <router-link :to="{path:'/offerte/peoplesoft/lista'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista Peoplesoft
                </span>
                </button>
              </router-link>
            </div>
          </div>


        </div>
        <peoplesoft-form v-bind:peoplesoft="peoplesoft" v-loading="loading" @update="updateForm" @delete="deleteElement"></peoplesoft-form>

        <div v-if="offersList.length > 0">
          <h4 class="title">Offerte associate a <strong>{{peoplesoft.internal_name}}</strong> <small>(ID: {{peoplesoft.id}})</small></h4>
          <ul>
            <li v-for="(item) in offersList">
              <router-link :to="{path:'/offerte/lista/modifica/' + item.id}" style="color: #616161;">{{item.name}} <i class="edit" ></i></router-link>
              <small v-if="item.internal_name" class="n-thin">- {{item.internal_name}}</small><small class="n-lightgrey"> (ID: {{item.id}})</small>
            </li>
          </ul>
        </div>


      </el-card>
    </div>
  </div>
</template>
<script>
  import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
  import PeoplesoftForm from './PeoplesoftForm.vue'
  import Swal from "sweetalert2";

  export default {
    components: {
      LPagination,
      Breadcrumb,
      BreadcrumbItem,
      PeoplesoftForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        this.updateData()
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'PeoplesoftEdit',
          minimumPermissionLevel: 100,
          listPath: '/offerte/peoplesoft/lista'
        },
        loading: false,
        canShowView: false,
        peoplesoft: {},
        offersList: []
      }
    },
    methods: {
      updateData () {
        this.loading = true
        this.$store.dispatch('peoplesoft/getById', this.$route.params.id).then((response) => {
          this.peoplesoft = response.data.peoplesoft
          this.offersList = response.data.peoplesoft.offers
          this.loading = false
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il recupero dei dati di questo elemento Peoplesoft')
        })
      },
      updateForm (pElement, pRedirect = true) {
        this.loading = true
        this.$store.dispatch('peoplesoft/edit', pElement).then((response) => {
          this.loading = false
          this.peoplesoft = response.data.peoplesoft
          this.showGenericSuccess('Offerta')
          this.$router.push(this.componentSettings.listPath)
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dei dati di questo elemento Peoplesoft', '', false)
        })
      },
      deleteElement (pElement) {
        Swal.fire(this.deleteMessage(this.peoplesoft.internal_name)).then((result) => {
          if (!result.value) return
          this.$store.dispatch('peoplesoft/delete', pElement).then(() => {
            Swal.fire('Eliminato!',
              'Stato eliminato!',
              'success'
            ).then(() => {
              this.$router.push(this.componentSettings.listPath)
            })
          }).catch((e) => {
            this.showLoadingError(e, 'l\'eliminazione dell\'elemento Peoplesoft', '', false)
          })
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style></style>
