<template>
    <div id="order-step-recap">
        <div class="row mt-2">
            <div class="col-12">
                <h4 class="m-0 text-center">Riepilogo Offerta</h4>
            </div>
            <div class="col-12 mt-2" v-for="orderProduct in this.modelValue.order_products">
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Nome offerta</div>
                    <div class="col-6">{{ orderProduct.offer.name }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Tipologia offerta</div>
                    <div class="col-6">{{ orderProduct.offer.order_type_details.name }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Canone mensile</div>
                    <div class="col-6">€ {{ orderProduct.offer.canone_mensile }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Obbligo contrattuale</div>
                    <div class="col-6">{{ orderProduct.offer.obbligo_contrattuale }} MESI</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Metodo di pagamento</div>
                    <div class="col-6">{{ modelValue.payment_method.type_details.name }}</div>
                </div>
                <div class="row pt-1 pb-1 d-flex align-items-center py-2">
                    <div class="col-4">Periodo di fatturazione</div>
                    <billing-period-select v-model="orderProduct.billing_period"
                                           @change="billingPeriodChanged = true"></billing-period-select>
                    <div class="ml-2" v-if="billingPeriodChanged">
                        <el-button icon="el-icon-check" circle type="success" @click="saveBillingPeriod(orderProduct)"
                                   v-loading="billingPeriodChanging"></el-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h4 class="m-0 text-center">Riepilogo Cliente</h4>
            </div>
            <div class="col-12 mt-2">
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Nome</div>
                    <div class="col-6">{{ modelValue.customer.first_name }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Cognome</div>
                    <div class="col-6">{{ modelValue.customer.last_name }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Codice fiscale</div>
                    <div class="col-6">{{ modelValue.customer.codice_fiscale }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Email</div>
                    <div class="col-6">{{ modelValue.customer.email }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Tipo</div>
                    <div class="col-6">{{ modelValue.customer.type_details.name }}</div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h4 class="m-0 text-center">Indirizzo di attivazione</h4>
            </div>
            <div class="col-12 mt-2">
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Indirizzo</div>
                    <div class="col-6">{{ modelValue.activation_address.address }}
                        {{ modelValue.activation_address.address_number }}
                    </div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Città</div>
                    <div class="col-6">{{ modelValue.activation_address.city }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">Provincia</div>
                    <div class="col-6">{{ modelValue.activation_address.province }}</div>
                </div>
                <div class="row pt-1 pb-1 border-bottom">
                    <div class="col-4">CAP</div>
                    <div class="col-6">{{ modelValue.activation_address.postal_code }}</div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex w-100 justify-content-between">
                    <el-button drag type="primary" @click="back()"><i
                        class="mr-2 fa-regular fa-backward-step"></i>Indietro
                    </el-button>
                    <el-button drag type="primary" @click="confirm()">Contratto<i
                        class="ml-2 fa-regular fa-forward-step"></i></el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import BillingPeriodSelect from "@/components/Inputs/BillingPeriodSelect";

export default {
    name: "step-recap",
    components: {
        BillingPeriodSelect
    },
    data() {
        return {
            order: {},
            billingPeriodChanged: false,
            billingPeriodChanging: false
        }
    },
    mounted() {
        this.order = this.modelValue
    },
    props: {
        modelValue: {
            type: Object
        },
    },
    methods: {
        back() {
            this.$emit('back')
        },
        confirm() {
            this.$emit('next')
        },
        saveBillingPeriod(orderProduct) {
            this.billingPeriodChanging = true;
            this.$store.dispatch('orders/orderProducts/editBillingPeriod', orderProduct).then((response) => {
                this.billingPeriodChanged = false
                this.showSuccessMessage("Periodo di fatturazione aggiornato correttamente.")
            }).catch(e => {
                this.showResponseErrorMessage(e)
            }).finally(() => {
                this.billingPeriodChanging = false;
            })
        }
    }
}
</script>

<style scoped>

</style>
