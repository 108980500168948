<template>
  <div class="row" v-if="canShowView">
    <div v-if="isLoading" style="position: fixed; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.9); z-index: 1000; top: 0; left: 0;">
      <h3 style="text-align: center; font-weight: 200 !important;">Esporto...</h3>
    </div>

    <div class="col-12">
      <el-card>

        <div class="row" v-loading="loading">

          <form class="col-12">

            <div class="row">
              <h4 class="col-12 mt-0">
                Inserimento orario {{this.selectedUserName}} <div style="float: right;">Totale ore inserite: <span>{{hoursCount}}</span></div>
              </h4>
            </div>
            <div class="row" v-if="canEditOthers">
              <div class="col-12 col-md-4 pb-0">
                <label>Nome</label>
                <br />
                <el-select v-loading="loading" style="width: 100%" class="select-default mb-0" v-model="timePanel.attendance_user_id" @change="changeUser" filterable placeholder="Seleziona..." size="large">
                  <el-option
                    v-for="item in usersList"
                    class="select-default"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    name="name">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row" v-if="canEditOthers">
              <div class="col-12 pt-0 mb-2">
                <small class="mb-3" style="color:#888; line-height: 1.1;" v-if="timePanel.working_hours > 0">{{this.selectedUserName}} ha <strong>{{timePanel.contract_hours}} ore</strong> da contratto. Puoi modificarlo nel tuo profilo utente.</small>
                <small v-else style="color:#888; line-height: 1.1">{{this.selectedUserName}} non ha un numero di ore impostate nel profilo.</small>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <label>Giorno
                  <small>(gg-mm-aaaa)</small>
                </label>
                <br/>
                <el-date-picker
                  v-model="timePanel.working_day"
                  type="date"
                  placeholder="Seleziona..."
                  label="Giorno"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                  language="it"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Quante ore hai lavorato?"
                          v-model="timePanel.working_hours"
                          name="working_hours"
                          v-validate="modelValidations.working_hours"
                          :error="getError('working_hours')"
                          @change="fieldChange">
                </el-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-8">
                <label>Note</label>
                <textarea class="form-control" rows="1"
                          label="Note"
                          name="notes"
                          v-model="timePanel.notes">
                </textarea>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 col-12">
                <label>Modifiche all'orario?</label>
                <br />
                <l-switch v-model="enableExtraFields" type="primary" on-text="SÌ" off-text="NO" @input="extraHours"></l-switch>
              </div>
            </div>

            <div class="row" v-if="enableExtraFields">
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Ore di straordinari"
                          v-model="timePanel.overtime"
                          name="overtime"
                          @change="fieldChange">
                </el-input>
              </div>
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Ore di permesso"
                          v-model="timePanel.off_hours"
                          name="off_hours"
                          @change="fieldChange">
                </el-input>
              </div>
            </div>

            <div class="row" v-if="enableExtraFields">
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Ore di ferie"
                          v-model="timePanel.holiday_hours"
                          name="holiday_hours"
                          @change="fieldChange">
                </el-input>
              </div>
              <div class="col-12 col-md-4">
                <el-input type="text"
                          label="Ore di festività"
                          v-model="timePanel.festivity_hours"
                          name="festivity_hours"
                          @change="fieldChange">
                </el-input>
              </div>
            </div>


            <div class="row" style="margin-top: 20px; margin-bottom: 10px;">
              <div class="col-12">

                <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="validateForm">
                  Inserisci
                </button>

              </div>
            </div>

            <div class="clearfix"></div>
          </form>

        </div>

        <div>
          <hr />
          <div class="row">
            <h4 class="col-12 mt-3 mb-4">
              Esporta orario <span v-if="!canEditOthers">di {{this.selectedUserName}}</span>
            </h4>
          </div>

          <div class="row" v-if="canEditOthers">
            <div class="col-12 col-md-4 mb-3">
              <label>Utenti da esportare</label>
              <el-select multiple filterable style="width: 100%" v-model="usersToExport" placeholder="Seleziona utenti...">
                <el-option
                        v-for="item in usersList"
                        class="select-default"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        name="name">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
              <label>Seleziona il mese</label>
              <el-date-picker
                      v-model="exportDate"
                      type="month"
                      format="MM-yyyy"
                      placeholder="Seleziona il mese"
                      value-format="YYYY-MM-DD"
                      language="it">
              </el-date-picker>
            </div>
          </div>

          <div class="row mt-3" v-if="canEditOthers">
            <div class="col-md-4 col-12">
              <label>Contratto con azienda</label>
              <div class="row">
                <el-select class="select-default col-12 mb-3" v-model="timePanel.employment_company" filterable placeholder="..." size="large">
                  <el-option
                          v-for="item in employment_companies"
                          class="select-default"
                          :key="item.key"
                          :label="item.name"
                          :value="item.key">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 30px; margin-bottom: 20px;">
            <div class="col-12">
              <button type="submit" class="btn btn-dark btn-fill btn-wd mr-3" @click.prevent="exportTimePdf">
                Esporta PDF
              </button>
              <button type="submit" class="btn btn-danger btn-fill btn-wd" @click.prevent="exportTimeCsv">
                Esporta CSV
              </button>
            </div>
          </div>
        </div>

      </el-card>

    </div>
  </div>
</template>

<script>
    import { Breadcrumb, BreadcrumbItem, Switch as LSwitch } from 'src/components'

    export default {
      components: {
        LSwitch,
        Breadcrumb,
        BreadcrumbItem,
      },
      computed: {
        canEditOthers () {
          return this.$store.getters['timePanel/canEditOthers']
        }
      },
      data () {
        return {
          componentSettings: {
            minimumPermissionLevel: 200
          },
          currentState: {},
          loading: false,
          isLoading: false,
          canShowView: false,
          timePanel: {
            contract_hours: null,
            working_hours: null,
            name: null,
            working_day: null,
            attendance_user_id: null,
            overtime: null,
            off_hours: null,
            holiday_hours: null,
            festivity_hours: null
          },
          pickerOptions: {
            firstDayOfWeek: 1
          },
          usersList: [],
          selectedUserName: null,
          enableExtraFields: false,
          modelValidations: {
            working_hours: {
              required: true
            }
          },
          hoursCount: null,
          exportDate: null,
          usersToExport: [],
          employment_companies: []
        }
      },
      methods: {
        fieldChange () {
          let sum = {
            working_hours: 0,
            overtime: 0,
            off_hours: 0,
            holiday_hours: 0,
            festivity_hours: 0
          }
          if (parseFloat(this.timePanel.working_hours)) {
            sum.working_hours = parseFloat(this.timePanel.working_hours)
          }
          if (parseFloat(this.timePanel.overtime)) {
            sum.overtime = parseFloat(this.timePanel.overtime)
          }
          if (parseFloat(this.timePanel.off_hours)) {
            sum.off_hours = parseFloat(this.timePanel.off_hours)
          }
          if (parseFloat(this.timePanel.holiday_hours)) {
            sum.holiday_hours = parseFloat(this.timePanel.holiday_hours)
          }
          if (parseFloat(this.timePanel.festivity_hours)) {
            sum.festivity_hours = parseFloat(this.timePanel.festivity_hours)
          }
          this.hoursCount = (sum.working_hours + sum.overtime + sum.off_hours + sum.holiday_hours + sum.festivity_hours)
        },
        findUserFromId (pUserId) {
          for (let index = 0; index < this.usersList.length; ++index) {
            if (this.usersList[index].id === pUserId) {
              return this.usersList[index]
            }
          }
          return null
        },
        getError (fieldName) {
          return this.errors.first(fieldName)
        },
        changeUser () {
          let user = this.findUserFromId(this.timePanel.attendance_user_id)
          if (user) {
            this.timePanel.working_hours = JSON.parse(JSON.stringify(user.working_hours))
            this.timePanel.contract_hours = JSON.parse(JSON.stringify(user.working_hours))
            this.selectedUserName = JSON.parse(JSON.stringify(user.name))
          } else {
            this.timePanel.working_hours = null
          }
        },
        extraHours () {
          if (!this.enableExtraFields) {
            this.timePanel.overtime = null
            this.timePanel.off_hours = null
            this.timePanel.holiday_hours = null
            this.timePanel.festivity_hours = null
          }
        },
        loadUsers () {
          this.loading = true
          this.$store.dispatch('users/list', {search_fields: ['company_id'], search_type: 1, search: this.$store.getters.user.company_id.toString(), limit: -1, orderby: 'name', order_direction: 'asc', no_service_users: true}).then((response) => {
            this.usersList = response.data.users
            this.loading = false
          }).catch((e) => {
            this.loading = false
            this.showLoadingErrorWithoutRetry(e, 'il caricamento degli utenti')
          })
        },
        validateForm () {
          if (this.timePanel.working_hours % 0.5 === 0) {
            this.$validator.validateAll().then(isValid => {
              if (isValid) {
                this.addTime()
              }
            })
          } else {
            this.showGenericError('Errore', 'Il numero di ore non è valido.')
          }
        },
        addTime () {
          this.loading = true
          this.$store.dispatch('timePanel/attendanceLogsRegister', this.timePanel).then((response) => {
            this.loading = false
            this.showGenericSuccess('Fatto', 'orario salvato con successo')
            this.timePanel.working_hours = this.$store.getters.user.working_hours
            this.timePanel.overtime = null
            this.timePanel.off_hours = null
            this.timePanel.holiday_hours = null
            this.timePanel.festivity_hours = null
          }).catch((e) => {
            this.loading = false
            this.showLoadingErrorWithoutRetry(e, 'l\'inseriento dell\'orario')
          })
        },
        exportTimePdf () {
          this.isLoading = true
          if (this.exportDate) {
            let params = {export_date: this.exportDate, employed_company: this.timePanel.employment_company, attendance_user_ids: this.usersToExport}
            this.$store.dispatch('timePanel/attendanceLogsExportPdf', params).then((file) => {
              this.isLoading = false
              var fileDownload = require('js-file-download')
              fileDownload(file.data, 'Orario_' + this.exportDate + '.pdf', 'application/pdf')
            }).catch((e) => {
              this.isLoading = false
              this.showLoadingErrorWithoutRetry(e, 'il download dell\'orario in PDF')
            })
          } else {
            this.isLoading = false
            this.showGenericError('Errore', 'devi selezionare il mese.')
          }
        },
        exportTimeCsv () {
          this.isLoading = true
          if (this.exportDate) {
            let params = {export_date: this.exportDate, employed_company: this.timePanel.employment_company}
            if (this.usersToExport && this.usersToExport.length > 0) {
              params = Object.assign(params, {attendance_user_ids: this.usersToExport})
            }
            this.$store.dispatch('timePanel/attendanceLogsExportCsv', params).then((file) => {
              this.isLoading = false
              let fileDownload = require('js-file-download')
              fileDownload(file.data, 'Orario_' + this.exportDate + '.csv', 'application/csv')
            }).catch((e) => {
              this.isLoading = false
              this.showLoadingErrorWithoutRetry(e, 'il download dell\'orario in CSV')
            })
          } else {
            this.isLoading = false
            this.showGenericError('Errore', 'devi selezionare il mese.')
          }
        },
        loadEmploymentCompanies () {
          this.loading = true
          this.$store.dispatch('users/getEmploymentCompanies').then((response) => {
            this.loading = false
            this.employment_companies = response
          }).catch(e => {
            this.loading = false
            this.showLoadingError(e, 'il caricamento delle aziende')
          })
        }
      },
      created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        if (this.$store.getters.user.role <= 100) {
          this.loadUsers()
        }
        if (!this.canEditOthers) {
          this.usersToExport = [this.$store.getters.user.id]
        }
        this.loadEmploymentCompanies()
        let date = new Date().toISOString().slice(0, 10) // https://stackoverflow.com/questions/23593052/format-javascript-date-to-yyyy-mm-dd NON FUNZIONA LA PRESELEZIONE DELLA DATA ODIERNA
        this.timePanel.working_day = date
        this.timePanel.name = this.$store.getters.user.name
        this.timePanel.attendance_user_id = this.$store.getters.user.id
        this.timePanel.working_hours = this.$store.getters.user.working_hours
        this.timePanel.contract_hours = this.$store.getters.user.working_hours
        this.selectedUserName = this.$store.getters.user.name
        this.hoursCount = this.$store.getters.user.working_hours
      },
      mounted: function () {},
      destroyed: function () {}
    }
</script>
<style></style>
