<template>

  <div>
    <form>

      <div class="row">
        <div class="col-12 pt-0">
          <h4 class="mb-3 mt-0">Note</h4>
          <editor style="height: 180px;"  :init="$config.tinyMCEConfig"></editor>
        </div>
      </div>


      <div class="row" style="margin-top: 40px">
        <div class="col-12 text-center">

          <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="deleteElement" v-if="shipping_log.id && canDelete">
            Elimina
          </button>

          <button type="submit" class="btn btn-primary btn-fill btn-wd" @click.prevent="updateForm">
            Salva
          </button>

        </div>
      </div>

      <div class="clearfix"></div>
    </form>
  </div>
</template>
<style></style>
<script>
  import { Switch as LSwitch, Checkbox as LCheckbox } from 'src/components/index'
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      LSwitch,
      LCheckbox,
      'editor': Editor
    },
    props: {
      shipping_log: {}
    },
    data () {
      return {
        componentSettings: {
          listPath: '/spedizioni/lista'
        },
        content: '',
        loading: false,
        tipoTemplate: [],
        currentVariable: '',
        copyVariable: true,
        template_variables: {}
      }
    },
    watch: {},
    computed: {
      canDelete () {
        if (!this.shipping_log.id) return false
        return this.$store.getters['shippingLogs/canDelete'](this.shipping_log)
      }
    },
    methods: {
      deleteElement () {
        this.$emit('delete', this.shipping_log)
      },
      updateForm () {
        this.$emit('update', this.shipping_log)
      }
    },
    created: function () {},
    destroyed: function () {
      this.$off()
    }
  }

</script>
<style></style>
