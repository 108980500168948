<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h4>Indirizzo di attivazione</h4>
            </div>
        </div>

        <el-form ref="activationAddressForm" v-loading="loading" :model="activationAddress" :rules="rules"
                 label-position="top">
            <div class="row">
                <div class="col-9">
                    <el-form-item label="Indirizzo" prop="address">
                        <el-input v-model="activationAddress.address"/>
                    </el-form-item>
                </div>
                <div class="col-3">
                    <el-form-item label="Numero civico" prop="address_number">
                        <el-input v-model="activationAddress.address_number"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <el-form-item label="Città" prop="city">
                        <el-input v-model="activationAddress.city"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <el-form-item label="CAP" prop="postal_code">
                        <el-input v-model="activationAddress.postal_code"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Provincia" prop="province">
                        <province-select v-model="activationAddress.province"/>
                    </el-form-item>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col-12 col-md-6">
                    <el-form-item label="Codice Univoco Cella AGCOM" prop="agcom_codice_univoco_cella">
                        <el-input v-model="activationAddress.agcom_codice_univoco_cella"/>
                    </el-form-item>
                    <span style="font-size: 13px;">Se assente: 100MN0000000E0000000</span>
                </div>
                <div class="col-12 col-md-6">
                    <el-form-item label="Fascia Velocità Cella AGCOM" prop="agcom_fascia_velocita_cella">
                        <fascia-velocita-agcom-select v-model="activationAddress.agcom_fascia_velocita_cella"/>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-right">
                    <el-button type="primary" icon="check" @click.prevent="validateForm">Salva</el-button>
                </div>
            </div>
        </el-form>

    </div>
</template>

<script>
import TelephoneOperatorSelect from "@/components/Inputs/TelephoneOperatorSelect";
import NewNpForm from "@/components/Np/NewNpForm";
import EditNpForm from "@/components/Np/EditNpForm";
import ProvinceSelect from "@/components/Inputs/ProvinceSelect";
import FasciaVelocitaAgcomSelect from "@/components/Inputs/FasciaVelocitaAgcomSelect";

export default {
    name: "OrderProductActivationAddressSection",
    components: {
        ProvinceSelect,
        FasciaVelocitaAgcomSelect,
        EditNpForm,
        NewNpForm,
        TelephoneOperatorSelect
    },
    props: {
        order: {
            required: true
        }
    },
    watch: {
        'order.id'() {
            this.updateAddressInfo()
        }
    },
    created() {
        this.updateAddressInfo();
    },
    data() {
        return {
            loading: false,
            activationAddress: {
                order_product_id: null,
                order_id: null,
                customer_id: null,
                address: null,
                address_number: null,
                city: null,
                postal_code: null,
                province: null
            },
            rules: {
                address: [
                    {required: true, message: "L'indirizzo è obbligatorio", trigger: 'blur'}
                ],
                address_number: [
                    {required: true, message: 'Il numero civico è obbligatorio', trigger: 'blur'}
                ],
                city: [
                    {required: true, message: 'La città è obbligatoria', trigger: 'blur'}
                ],
                postal_code: [
                    {required: true, message: 'Il CAP è obbligatorio.', trigger: 'blur'}
                ],
                province: [
                    {required: true, message: 'La provincia è obbligatoria', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        updateAddressInfo() {
            if (this.order.activation_address) {
                this.activationAddress = this.order.activation_address;
            } else {
                this.activationAddress.customer_id = this.order.customer.id
                this.activationAddress.order_product_id = this.order.order_product.id
                this.activationAddress.order_id = this.order.id
            }
        },
        validateForm() {
            this.$refs['activationAddressForm'].validate((valid) => {
                if (valid) {
                    if (this.activationAddress.id) {
                        this.update();
                    } else {
                        this.create();
                    }
                } else {
                    this.$message.error("Il form dell'indirizzo di attivazione non è compilato correttamente.")
                }
            });
        },
        update() {
            this.loading = true
            this.$store.dispatch('activationAddress/edit', this.activationAddress).then((response) => {
                this.activationAddress = response.data.data;
                this.$emit('input', this.activationAddress)
                this.loading = false
                this.showSuccessMessage('Indirizzo di attivazione salvato correttamente.')
            }).catch(e => {
                this.loading = false
                this.showErrorMessage("Errore durante il salvataggio dell'indirizzo di attivazione")
            })
        },
        create() {
            this.loading = true
            this.$store.dispatch('activationAddress/add', this.activationAddress).then((response) => {
                this.activationAddress = response.data.data;
                this.$emit('input', this.activationAddress)
                this.loading = false
                this.showSuccessMessage('Indirizzo di attivazione creato correttamente.')
            }).catch(e => {
                this.loading = false
                this.showErrorMessage("Errore durante la creazione dell'indirizzo di attivazione")
            })
        }
    }
}
</script>

<style scoped>

</style>
