<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Lista offerte">

                <div class="table-layout-container">

                    <div class="row mb-4">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-3 mx-0 mb-3">
                            <router-link :to="{path:'/offerte/aggiungi'}">
                                <button class="btn btn-default btn-outline col-12" style="max-width: 300px;">
                <span class="btn-label">
                   <i class="fa fa-plus"></i> Aggiungi offerta
                </span>
                                </button>
                            </router-link>
                        </div>

                        <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
                            <el-select style="width: 100%; max-width: 300px;" v-model="currentState.typeFilter"
                                       placeholder="Tipologia" @change="handleTypeChange">
                                <el-option :key=0 label="Tipo: Tutti" :value=0></el-option>
                                <el-option v-for="item in componentSettings.typeOptions" :key="item.code"
                                           :label="item.name" :value="item.code"></el-option>
                            </el-select>
                        </div>

                        <div class="col-12 col-md-12 col-lg-12 col-xl-5 ml-0 mt-1 mt-md-0 d-none">
                            <div class="row float-right">
                                <div class="float-right mr-3 ml-3">
                                    <button class="btn btn-default btn-outline col-12" @click.prevent="exportCSV()">
                    <span class="btn-label">
                       <i class="fa fa-download"></i> Esporta CSV
                    </span>
                                    </button>
                                </div>

                                <div class="float-right mr-3 ml-3">
                                    <router-link :to="{path:'/offerte/aggiungi'}"></router-link>

                                    <el-upload
                                        ref="csvUpload"
                                        :on-success="handleCsvSuccess"
                                        :on-error="handleCsvError"
                                        :before-upload="handleCsvBefore"
                                        :action="csvImportAction"
                                        :show-file-list="false"
                                        accept="application/csv"
                                        name="file"
                                        :limit=1
                                        :data="csvImportData"
                                        :auto-upload="true">
                                        <button class="btn btn-default btn-outline col-12" slot="trigger">
                      <span class="btn-label">
                         <i class="fa fa-plus"></i> Importa CSV
                      </span>
                                        </button>
                                    </el-upload>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div></div>
                            <l-pagination class="pagination-no-border float-right"
                                          v-model="currentState.pagination.currentPage"
                                          :per-page="currentState.pagination.perPage"
                                          :total="currentState.pagination.total"
                                          @input="handlePageChange"></l-pagination>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2 col-12">
                            <div class="row">
                                <div class="mb-3 col-12 col-sm-12">
                                    <el-select style="width: 100%" v-model="currentState.pagination.perPage"
                                               placeholder="Per pagina" @change="handlePerPageChange">
                                        <el-option v-for="item in componentSettings.pagination.perPageOptions"
                                                   :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-10 col-12">
                            <div class="row">
                                <div class="col-sm-3 col-12 mb-3">
                                    <el-select multiple style="width: 100%"
                                               v-model="currentState.searchFields.selected" placeholder="Dove cerco?"
                                               @change="handleSearchFieldsChange">
                                        <el-option v-for="(option, index) in searchFields" class="select-default"
                                                   :value="option.searchFields" :label="option.label" :key="index">
                                        </el-option>
                                    </el-select>
                                </div>

                                <div class="col-sm-3 col-12 mb-3">
                                    <el-select style="width: 100%" v-model="currentState.searchTypeSelected"
                                               placeholder="Come cercare?" @change="handleSearchTypeChange">
                                        <el-option v-for="(item, index) in searchTypeOptions" :key="index"
                                                   :label="item.label" :value="index"></el-option>
                                    </el-select>
                                </div>

                                <div class="col-12 col-sm-4">
                                    <el-input style="width: 100%" type="search" placeholder="Cerca"
                                              v-model="currentState.searchQuery"
                                              @change="handleSearchChange" addon-right-icon="fa fa-search"/>
                                </div>

                                <div class="col-sm-2 col-12 mb-3 no-padding-left-desktop">
                                    <button class="btn btn-outline btn-danger btn-outline col-12"
                                            style="padding: 8px 0px; width: 100%"
                                            @click="resetFilters()" data-tooltip="'Reset dei filtri'">
                          <span class="btn-label">
                             <i class="fa fa-times"></i>
                          </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-12" v-loading="loading">
                            <el-table class="table-striped" :data="tableData" border style="width: 100%"
                                      :default-sort="currentState.currentSorting" @sort-change="handleSortChange"
                                      @selection-change="handleSelectionChange">


                                <template v-for="column in tableColumns">
                                    <el-table-column v-if="!column.custom && column.type !== 'selection'"
                                                     :type="column.type"
                                                     :fixed="column.fixed" :key="column.label"
                                                     :width="calculateTableWidth(column)"
                                                     :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label"></el-table-column>

                                    <el-table-column v-else-if="column.type === 'selection'"
                                                     type="selection"></el-table-column>

                                    <el-table-column v-else-if="column.custom === 'id'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label" :width="100">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <span v-if="props.row.id">{{ props.row.id }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'order_type'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                                                <div class="d-flex flex-column">
                                                    <span>{{ props.row.order_type_formatted }}</span>
                                                    <span class="td-small">{{
                                                            props.row.customer_type_formatted
                                                        }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>


                                    <el-table-column v-else-if="column.custom === 'name'" :type="column.type"
                                                     :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div v-if="props.row">
                        <span class="td-strong"><router-link class="dark-link"
                                                             :to="{path:'/offerte/lista/modifica/' + props.row.id}"><strong>{{
                                props.row.internal_name
                            }}</strong></router-link></span>
                                                <strong v-if="props.row.offerta_integrata" style="color: #E74155;"><br/>Offerta
                                                    integrata</strong>
                                                <!-- <span class="td-small" v-if="props.row.internal_name"><br />{{props.row.internal_name}}</span> -->
                                                <small class="td-small" v-if="props.row.canone_mensile"><br/>Canone:
                                                    <strong>{{ props.row.canone_mensile }}€</strong></small>
                                                <small class="td-small" v-if="props.row.anticipo">- Anticipo:
                                                    <strong>{{ props.row.anticipo }}€</strong></small>
                                                <small class="td-small" v-if="props.row.rata_finale"><br/>Rata finale:
                                                    <strong>{{ props.row.rata_finale }}€</strong></small>
                                                <small class="td-small" v-if="props.row.costo_tieni">- Costo tieni:
                                                    <strong>{{ props.row.costo_tieni }}€</strong></small>
                                                <small class="td-small" v-if="props.row.rata_mensile"><br/>Rata mensile:
                                                    <strong>{{ props.row.rata_mensile }}€</strong></small>
                                                <small class="td-small" v-if="props.row.importo_rateizzato"><br/>Importo
                                                    rateizzato:
                                                    <strong>{{ props.row.importo_rateizzato }}€</strong></small>
                                            </div>
                                        </template>
                                    </el-table-column>


                                    <el-table-column v-else-if="column.custom === 'enabled'" :width="150"
                                                     :type="column.type" :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">

                                            <div class="products-enable" style="text-align: center" v-if="props.row">

                                                <a data-tooltip="'Generale'"
                                                   @click="toggleProperty('enabled', props.row)"
                                                   class="btn-simple btn-link"
                                                   v-bind:class="{'btn-success': props.row.enabled, 'btn-grey btn-icon': !props.row.enabled}"><i
                                                    class="fa fa-shopping-cart mt-1" style="font-size: 23px"></i></a>

                                                <div
                                                    style="height: 1px; width: 110px; background-color: rgba(204, 204, 204, 0.4); margin-left: auto; margin-right: auto; margin-top: 4px; margin-bottom: 4px;"></div>


                                                <a data-tooltip="'Partner'"
                                                   @click="toggleProperty('enabled_partner', props.row)"
                                                   class="btn-simple btn-link"
                                                   v-bind:class="{'btn-success': props.row.enabled_partner, 'btn-grey btn-icon': !props.row.enabled_partner, }"><i
                                                    class="fa fa-users mr-1 mt-1"></i></a>

                                                <a data-tooltip="'Sito Dimensione'"
                                                   @click="toggleProperty('enabled_for_customers', props.row)"
                                                   class="btn-simple btn-link"
                                                   v-bind:class="{'btn-success': props.row.enabled_for_customers, 'btn-grey btn-icon': !props.row.enabled_for_customers}"><i
                                                    class="fa fa-home mr-1 mt-1"></i></a>

                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column v-else-if="column.custom === 'azioni'" :width="150"
                                                     :type="column.type" :fixed="column.fixed"
                                                     :key="column.label" :prop="column.prop" :sortable="column.sortable"
                                                     :label="column.label">
                                        <template #default="props">
                                            <div style="text-align: center" class="table-actions" v-if="props.row">
                                                <router-link :to="{path:'/offerte/lista/modifica/' + props.row.id}">
                                                    <a data-tooltip="'Modifica'"
                                                       class="btn-warning btn-simple btn-link mb-3"
                                                       v-if="canEdit(props.$index, props.row)"><i
                                                        class="fa fa-edit"></i></a>
                                                </router-link>

                                                <a data-tooltip="'Duplica'" class="btn-success btn-simple btn-link mb-3"
                                                   @click="handleDuplicate(props.$index, props.row)"
                                                   v-if="canDelete(props.$index, props.row)"><i class="fa fa-copy"></i></a>

                                                <i class="fa fa-clone"
                                                   v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)"
                                                   data-tooltip="'Nessun permesso'"></i>

                                                <a data-tooltip="'Nascondi'"
                                                   class="btn-primary btn-simple btn-link mb-3"
                                                   style="color: #000 !important;"
                                                   @click="handleHide(props.$index, props.row)"
                                                   v-if="canDelete(props.$index, props.row)"><i
                                                    class="fa fa-eye-slash"></i></a>

                                                <i class="fa fa-ban"
                                                   v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)"
                                                   data-tooltip="'Nessun permesso'"></i>

                                                <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link mb-3"
                                                   @click="handleDelete(props.$index, props.row)"
                                                   v-if="canDelete(props.$index, props.row)"><i
                                                    class="fa fa-times"></i></a>

                                                <i class="fa fa-ban"
                                                   v-if="!canDelete(props.$index, props.row) && !canEdit(props.$index, props.row)"
                                                   data-tooltip="'Nessun permesso'"></i>

                                            </div>
                                        </template>
                                    </el-table-column>
                                </template>

                            </el-table>
                        </div>
                    </div>

                    <div slot="footer"
                         class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
                        <div class="">
                            <p class="card-category" v-if="!loading && currentState.pagination.total > 0">Mostro da
                                <strong>{{ currentState.pagination.from }}</strong> a
                                <strong>{{ currentState.pagination.to }}</strong>
                                su <strong>{{ currentState.pagination.total }}</strong> totali</p>
                        </div>
                        <l-pagination class="pagination-no-border"
                                      v-model="currentState.pagination.currentPage"
                                      :per-page="currentState.pagination.perPage"
                                      :total="currentState.pagination.total"
                                      @input="handlePageChange">
                        </l-pagination>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'

export default {
    components: {
        LPagination,
        Breadcrumb,
        BreadcrumbItem
    },
    computed: {
        csvImportData: function () {
            return this.$store.getters.apiCommon
        },
        csvImportAction: function () {
            return this.$config.endpoints.offers.base + 'import'
        }
    },
    data() {
        return {
            componentSettings: {
                singleFieldSearch: true,
                searchTypeOption: 0,
                tableSort: {prop: 'id', order: 'descending'},
                myComponentName: 'OffersList',
                minimumPermissionLevel: 100,
                pagination: {
                    perPageOptions: [5, 10, 25, 50, 100]
                },
                searchFieldsDefault: ['internal_name'],
                typeOptions: []
            },
            currentState: {
                currentSorting: {prop: 'id', order: 'descending'},
                searchFields: {
                    selected: ['internal_name'],
                    lastSelection: ['internal_name']
                },
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    lastLoadedPage: 1,
                    total: 0,
                    from: 0,
                    to: 0,
                    showedItems: 0
                },
                typeFilter: 0,
                searchQuery: '',
                searchTypeSelected: 0,
                tableMultipleSelect: null
            },
            searchFields: [
                {searchFields: 'internal_name', label: 'Nome'},
                {searchFields: 'id', label: 'ID'}
            ],
            searchTypeOptions: [
                {searchType: '0', label: 'Contiene'},
                {searchType: '2', label: 'Inizia per'},
                {searchType: '1', label: 'È uguale a'}
            ],
            tableColumns: [
                {
                    prop: 'id',
                    label: 'ID',
                    percentWidth: 8,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: true,
                    type: 'default',
                    first: true,
                    custom: 'id'
                },
                {
                    prop: 'name',
                    label: 'Nome',
                    percentWidth: 30,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'name'
                },
                {
                    prop: 'product_name',
                    label: 'Prodotto',
                    percentWidth: 18,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'prodotto'
                },
                {
                    prop: 'order_type',
                    label: 'Tipo Offerta',
                    percentWidth: 16,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'order_type'
                },
                {
                    prop: 'enabled',
                    label: 'Abilitato',
                    percentWidth: 15,
                    sortable: 'custom',
                    showTooltip: true,
                    fixed: false,
                    type: 'default',
                    custom: 'enabled'
                },
                {
                    label: 'Azioni',
                    percentWidth: 13,
                    sortable: false,
                    showTooltip: false,
                    fixed: false,
                    type: 'default',
                    custom: 'azioni'
                }
            ],
            loading: false,
            tableData: [],
            canShowView: false
        }
    },
    methods: {
        getSaveStateConfig() {
            return {
                'cacheKey': this.componentSettings.myComponentName,
                'saveProperties': ['currentState']
            }
        },
        canDelete(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['offers/canDelete'](elementToCheck)
            }
        },
        canDuplicate(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['offers/canDuplicate'](elementToCheck)
            }
        },
        canEdit(index, row) {
            let indexToFind = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToFind >= 0) {
                let elementToCheck = this.tableData[indexToFind]
                return this.$store.getters['offers/canEdit'](elementToCheck)
            }
        },
        exportCSV() {
            this.loading = true
            this.$store.dispatch('offers/export').then((response) => {
                this.loading = false
                let today = new Date()
                let dd = String(today.getDate()).padStart(2, '0')
                let mm = String(today.getMonth() + 1).padStart(2, '0')
                let yyyy = today.getFullYear()
                let fileDownload = require('js-file-download')
                fileDownload(response.data, 'Offers_dump_' + dd + '_' + mm + '_' + yyyy + '.csv', 'application/csv')
            }).catch(e => {
                this.loading = false
                this.showLoadingErrorWithoutRetry(e, 'il recupero dei dati del CSV')
            })
        },
        handleCsvBefore() {
            return new Promise((resolve, reject) => {
                this.askGenericConfirm('Lo vuoi davvero?', '<strong>Premendo Sì, autorizzerai il caricamento del file CSV contenente le offerte modificate</strong>.<br /><br />Tutte le offerte presenti nel CSV con <strong>anche solo un parametro modificato</strong> verranno ricreate, creando un duplicato a partire dall\'offerta originale.<br /><br />L\'offerta duplicata avrà le proprietà modificate.<br />L\'offerta originale verrà <strong>disabilitata</strong>.<br /><br /><strong>Verifica con attenzione la validità del file che stai caricando prima di procedere.<br /><br /> Caricando un file sbagliato potresti crearti <u>del lavoro inutile</u> e <u>perdere tanto tempo.</u></strong>').then((result) => {
                    if (!result.value) {
                        reject(false)
                        return false
                    }
                    resolve(true)
                    this.loading = true
                    return true
                })
            })
        },
        handleCsvError(err, file, fileList) {
            this.loading = false
            this.$refs.csvUpload.clearFiles()
            this.showLoadingErrorWithoutRetry(err, 'il caricamento del file')
        },
        handleCsvSuccess(response, file, fileList) {
            this.$refs.csvUpload.clearFiles()
            this.loading = false
            if (!response.result) {
                this.showLoadingErrorWithoutRetry(response)
            } else {
                let offersCreated = 0
                if (response.new_offers) offersCreated = response.new_offers.length
                this.askGenericSuccess('Grande!', 'Ti ho fatto risparmiare tempo e ho creato <strong>' + offersCreated + '</strong> offerte nuove!').then(() => {
                    this.updateData()
                })
            }
        },
        handleDelete(index, row) {
            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToDelete >= 0) {
                let elementToDelete = this.tableData[indexToDelete]
                this.askDeleteMessage('questa offerta ' + elementToDelete.internal_name).then((result) => {
                    if (!result.value) return
                    this.$store.dispatch('offers/delete', elementToDelete).then(() => {
                        this.showGenericSuccess('Offerta', 'eliminata con successo').then(() => {
                            if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                                this.currentState.pagination.currentPage--
                            }
                            this.updateData()
                        })
                    })
                        .catch(e => {
                            this.loading = false
                            this.showLoadingError(e)
                        })
                }).catch(() => {
                    //todo if not confirm
                })
            }
        },
        handleDuplicate(index, row) {
            let indexToDuplicate = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToDuplicate >= 0) {
                let elementToDuplicate = this.tableData[indexToDuplicate]

                this.$confirm("Stai per duplicare l'offerta. Sei sicuro?", "Attenzione!", {
                    confirmButtonText: 'Si, duplica!',
                    cancelButtonText: 'No, ho sbagliato.',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$store.dispatch('offers/duplicate', elementToDuplicate).then(() => {
                        this.$message.success("Offerta duplicata.");
                        if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                            this.currentState.pagination.currentPage--
                        }
                        this.updateData()
                    }).catch(e => {
                        this.showResponseErrorMessage(e)
                    })
                })
            }
        },
        handleHide(index, row) {
            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
            if (indexToDelete >= 0) {
                let elementToDelete = this.tableData[indexToDelete]
                this.$confirm("Stai per nascondere l'offerta. Sei sicuro?", "Attenzine!", {
                    confirmButtonText: 'Si, nascondi!',
                    cancelButtonText: 'No, ho sbagliato.',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$store.dispatch('offers/toggleProperty', {
                        property: 'hidden',
                        element: elementToDelete
                    }).then(() => {
                        this.$message.success("Offerta nascosta.")
                        if (this.tableData.length === 1 && this.currentState.pagination.currentPage > 1) { // Se l'elemento è l'ultimo della pagina, torno indietro
                            this.currentState.pagination.currentPage--
                        }
                        this.updateData()
                    })
                })
            }
        },
        handlePageChange(page) {
            if (page === this.currentState.pagination.lastLoadedPage) return
            this.currentState.pagination.currentPage = page
            this.updateData()
        },
        handlePerPageChange(perPage) {
            this.updateData({page: 1, limit: perPage})
        },
        handleSearchChange() {
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSearchFieldsChange(pIndex) {
            if (this.currentState.searchFields.selected.length < 1) {
                this.currentState.searchFields.selected = this.currentState.searchFields.lastSelection
                return
            }
            if (this.currentState.searchFields.selected.length > 1 && this.componentSettings.singleFieldSearch) {
                this.currentState.searchFields.selected = [this.currentState.searchFields.selected[this.currentState.searchFields.selected.length - 1]]
            }
            this.currentState.searchFields.lastSelection = this.currentState.searchFields.selected
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSearchTypeChange(pIndex) {
            if (this.currentState.searchQuery === '') return
            this.currentState.pagination.currentPage = 1
            this.updateData()
        },
        handleSelectionChange(pSelection) {
            this.currentState.tableMultipleSelect = pSelection
        },
        handleSortChange(pSortType) {
            let cSort = this.findColumnSort(pSortType)
            if (pSortType.prop === null) {
                this.currentState.currentSorting = this.componentSettings.tableSort
            } else {
                if (this.currentState.currentSorting.prop === cSort && this.currentState.currentSorting.order === pSortType.order) {
                    return
                }
                this.currentState.currentSorting = {prop: cSort, order: pSortType.order}
            }
            this.updateData()
        },
        handleTypeChange(newType) {
            this.resetFilters(false)
            this.currentState.typeFilter = newType
            this.updateData()
        },
        removeUser(pUser) {
            return this.axios.post(this.$config.endpoints.users.base + pUser.id + '/delete', this.$store.getters.apiCommon)
        },
        resetFilters(pReload = true) {
            this.currentState.searchQuery = ''
            this.currentState.currentSorting = JSON.parse(JSON.stringify(this.componentSettings.tableSort))
            this.currentState.tableMultipleSelect = null
            this.currentState.searchTypeSelected = 0
            this.currentState.pagination.perPage = 25
            this.currentState.pagination.currentPage = 1
            this.currentState.searchFields.selected = this.componentSettings.searchFieldsDefault
            this.clearSavedState()
            if (pReload) this.updateData()
        },
        toggleProperty(propertyName, pElement) {
            this.askToggleMessage(pElement.internal_name).then((result) => {
                if (!result.value) return
                this.loading = true
                this.$store.dispatch('offers/toggleProperty', {
                    property: propertyName,
                    element: pElement
                }).then((response) => {
                    this.loading = false
                    this.updateData()
                }).catch(e => {
                    this.loading = false
                    this.showLoadingError(e, '', '', false)
                })
            })
        },
        updateData(params) {
            this.loading = true
            if (!params) params = {}
            params = Object.assign(params, this.$store.getters.apiCommon, {
                page: this.currentState.pagination.currentPage,
                limit: this.currentState.pagination.perPage,
                company: true
            })

            if (this.currentState.searchQuery !== '') {
                params = Object.assign(params, {
                    search: this.currentState.searchQuery,
                    search_fields: this.currentState.searchFields.selected,
                    search_type: this.searchTypeOptions[this.currentState.searchTypeSelected].searchType
                })
            }
            if (this.currentState.currentSorting !== null) {
                params = Object.assign(params, {
                    orderby: this.currentState.currentSorting.prop,
                    order_direction: this.convertOrderDirection(this.currentState.currentSorting.order)
                })
            }
            if (this.currentState.typeFilter > 0) {
                params = Object.assign(params, {order_type: this.currentState.typeFilter})
            }
            this.$store.dispatch('offers/listPaginated', params).then((response) => {
                this.loading = false
                this.tableData = response.data.data.data
                this.currentState.pagination.total = response.data.data.total
                this.currentState.pagination.lastLoadedPage = this.currentState.pagination.currentPage
                this.currentState.pagination.showedItems = response.data.data.data.length
                this.currentState.pagination.from = response.data.data.from
                this.currentState.pagination.to = response.data.data.to
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e)
            })
        }
    },
    created: function () {
        this.componentSettings.typeOptions = this.$store.getters['orders/types']
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.updateData()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style>
</style>
