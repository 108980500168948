import Config from '../../globalConfig'
import axios from 'axios'

const offers = {
    namespaced: true,
    state: {
        offersList: [],
        offersTypes: [],
        userTypes: []
    },
    getters: {
        canDelete: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        canEdit: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        canDuplicate: (state, getters, rootState, rootGetters) => (pElement) => {
            if (rootGetters.role > 100) return false
            return true
        },
        offersList: state => state.offersList,
        offersTypes: state => state.offersTypes,
        userTypes: state => state.userTypes
    },
    mutations: {
        updateOffersList: (state, pValue) => {
            state.offersList = pValue
        },
        updateOffersTypes: (state, pValue) => {
            state.offersTypes = pValue
        },
        updateUserTypes: (state, pValue) => {
            state.userTypes = pValue
        }
    },
    actions: {
        reset({commit}) {
            commit('updateOffersList', [])
            commit('updateOffersTypes', [])
            commit('updateUserTypes', [])
        },
        async toggleProperty({commit, state, rootGetters}, {property, element}) {
            return new Promise((resolve, reject) => {
                var newValue = true
                if (element[property]) {
                    newValue = false
                }
                var params = Object.assign({[property]: newValue}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + element.id + '/edit', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async edit({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({}, pElement, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pElement.id + '/edit', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async delete({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.base + pElement.id + '/delete', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async duplicate({commit, state, rootGetters}, pElement) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.base + pElement.id + '/duplicate', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async add({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign(params, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.add, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async list({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign(params, {rate: true, product: true}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.list, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async listPaginated({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign(params, {rate: true, product: true}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.list_paginated, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async listCached({commit, state, getters, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                if (getters.offersList.length > 0) {
                    let offersFiltered = []
                    for (let i = 0; i < getters.offersList.length; ++i) {
                        if (getters.offersList[i].order_type !== params.order_type) continue
                        offersFiltered.push(getters.offersList[i])
                    }
                    resolve(offersFiltered)
                    return
                }
                let paramsApi = Object.assign({}, {
                    rate: true,
                    product: true,
                    enabled: true,
                    limit: -1
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base, paramsApi)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        commit('updateOffersList', response.data.offers)
                        if (!params.order_type) {
                            resolve(response.data.offers)
                            return
                        }
                        let offersFiltered = []
                        for (let i = 0; i < response.data.offers.length; ++i) {
                            if (response.data.offers[i].order_type !== params.order_type) continue
                            offersFiltered.push(response.data.offers[i])
                        }
                        resolve(offersFiltered)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getTypes({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                if (getters.offersTypes.length > 0) {
                    resolve(getters.offersTypes)
                    return
                }
                axios.post(Config.endpoints.offers.types, rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        commit('updateOffersTypes', response.data.offer_types)
                        resolve(response.data.offer_types)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getUserTypes({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                if (getters.userTypes.length > 0) {
                    resolve(getters.userTypes)
                    return
                }
                axios.post(Config.endpoints.offers.userTypes, rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.data) {
                            reject(response)
                            return
                        }
                        commit('updateUserTypes', response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getShippingTypes({commit, state, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.shippingTypes, rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getIntegratedOfferTypes({commit, state, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.integratedOffersTypes, rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getById({commit, state, rootGetters}, pId) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({options: true, integrated_offers: true}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pId, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async addOfferOption({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({
                    id: pObject.id,
                    offer_option_id: pObject.offerOptionId
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.id + '/options/add', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async deleteOfferOption({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({
                    id: pObject.id,
                    offer_option_id: pObject.offerOptionId
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.id + '/options/remove', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async loadIntegratedOffers({commit, state, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                params = Object.assign(params, {}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.integratedOffers, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async addIntegratedOffer({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({
                    id: pObject.id,
                    integrated_offer_id: pObject.integratedOfferId
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.id + '/integrated-offers/add', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async deleteIntegratedOffer({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({
                    id: pObject.id,
                    integrated_offer_id: pObject.integratedOfferId
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.id + '/integrated-offers/remove', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async export({commit, state, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.export, rootGetters.apiCommon)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getEmailAttachments({commit, state, rootGetters}, pOfferId) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pOfferId + '/email-attachments', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async addEmailAttachments({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({
                    attachment_template_path: pObject.attachment_template_path,
                    template_id: pObject.template_id
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.offerId + '/email-attachments/add', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async deleteEmailAttachments({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({attachment_id: pObject.attachment.id}, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.offerId + '/email-attachments/delete', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getAttachmentTemplatePaths({commit, state, rootGetters}, pOfferId) {
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, rootGetters.apiCommon)
                axios.post(Config.endpoints.preorderForms.templatePaths, params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getPaymentMethods({commit, state, rootGetters},) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.base + 'payment-methods', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async getBullingPeriods({commit, state, rootGetters},) {
            return new Promise((resolve, reject) => {
                axios.post(Config.endpoints.offers.base + 'billing-periods', rootGetters.apiCommon)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async setOfferOptionRelationIncluded({commit, state, rootGetters}, pObject) {
            return new Promise((resolve, reject) => {
                var params = Object.assign({
                    option_id: pObject.option_id,
                    included: pObject.included
                }, rootGetters.apiCommon)
                axios.post(Config.endpoints.offers.base + pObject.offer_id + '/options/set-included', params)
                    .then((response) => {
                        if (!response.data.result) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
    }
}

export default offers
