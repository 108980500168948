import Config from '../../globalConfig'
import axios from 'axios'

const operators = {
  namespaced: true,
  state: {
    operators: []
  },
  getters: {
    operators: state => state.operators
  },
  mutations: {
    updateOperators: (state, pOperators) => {
      state.operators = pOperators
    }
  },
  actions: {
    async list ({commit, state, getters, rootGetters}) {
      return new Promise((resolve, reject) => {
        let params = Object.assign({limit: -1}, rootGetters.apiCommon)
        axios.post(Config.endpoints.operators.base, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            commit('updateOperators', response.data.operators)
            resolve(response.data.operators)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    reset ({commit}) {
      commit('updateOperators', [])
    }
  }
}

export default operators
