import Config from '../../globalConfig'
import axios from 'axios'

const partner = {
  namespaced: true,
  actions: {
    async get ({commit, state, rootGetters}, id) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.partner.base + id, rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async create ({commit, state, rootGetters}, data) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, data, rootGetters.apiCommon)
        axios.post(Config.endpoints.partner.create, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async edit ({commit, state, rootGetters}, data) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, data, rootGetters.apiCommon)
        axios.post(Config.endpoints.partner.base + data.id + '/edit', params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async paginatedList ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.partner.paginatedList, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async list ({commit, state, rootGetters}, pElement) {
      return new Promise((resolve, reject) => {
        var params = Object.assign({}, pElement, rootGetters.apiCommon)
        axios.post(Config.endpoints.partner.list, params)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    async delete ({commit, state, rootGetters}, id) {
      return new Promise((resolve, reject) => {
        axios.post(Config.endpoints.partner.base + id + '/delete', rootGetters.apiCommon)
          .then((response) => {
            if (!response.data.result) {
              reject(response)
              return
            }
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
  }
}

export default partner
